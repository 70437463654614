import React, { Component } from 'react';
import $ from 'jquery';
import Conf from '../../../utils/Conf';
import { getUserId } from '../../../utils/AuthServices';
import { getRequest, postRequest } from '../../../utils/WebServicesManager';
import {Modal, ModalBody, FormGroup, Input, Label, Row, Col, ModalHeader } from 'reactstrap';
import AddUserModal from '../../../components/Modals/AddUserModal';
import { translate } from '../../../utils/ReactMultiLang';
import { check } from '../../../utils/Can';
import EditGroupACLModal from '../../../components/Modals/EditGroupACLModal/EditGroupACLModal';
import EventTable from '../../../components/EventTable/EventTable';

class ManageUsers extends Component {
  constructor(props) {
    super(props);
    this.t = props.t;
    this.state = {
      UserList: [],
      UserGroups: [],
      GroupACL: {},
      MainUser: {},
      Modal: false,
      ApiKey: undefined,
      ModalAddUser: false,
      ModalEditACL: false,
      UserToEdit: undefined,
      GroupPermission: undefined,
      CanRemove: check('user:remove'),
      CanCreate: check('user:create')
    };
    this.getUserDetails();
    this.getUserList();
    this.getUserGroups();
    this.toggle = this.toggle.bind(this);
    this.toggleACL = this.toggleACL.bind(this);
  }

  getUserGroups() {
    var url = Conf.BaseApi + 'groups/get';
    return getRequest(url, (data) => {
      if (data.success) {
        this.setState({ UserGroups: data.result });
      }
    });
  }

  getUserList(group) {
    var url = Conf.BaseApi + 'users/subuser/getList';
    return postRequest(url, { groups: group !== '' ? group : undefined }, (data) => {
      if (data.success) {
        this.setState({ UserList: data.result });
      }
    });
  }
  getUserDetails(id) {
    var url = Conf.BaseApi + 'user/get/' + id;
    getRequest(url, (data) => {
      if (data.success) {
        this.setState({ MainUser: data.result });
      }
    });
  }

  quitGroup(groupId, userId) {
    if (this.state.MainUser.groups && this.state.MainUser.groups.length > 1) {
      var r = window.confirm(this.t('Popup.Quit.Group'));
      if (r == true) {
        var url = Conf.BaseApi + "groups/quit/" + groupId + "/" + userId;
        getRequest(url, (data) => {
          if (data.success && data.success == true) {
            this.getUserDetails(this.state.MainUser._id);
          }
        });
      }
    } else {
      alert(this.t('Popup.Quit.NoGroup'));
    }
  }

  renderUsersList() {
    var toRender = [];
    const currentUserId = getUserId();
    for (var i = 0; i < this.state.UserList.length; i++) {
          toRender.push(
            <li key={i + this.state.UserList[i]._id}>
            <Row><div className="col-lg-12 col-md-12 col-xs-12 sensorListItem" onClick={this.getUserDetails.bind(this, this.state.UserList[i]._id)}>
              <i className="icon-user bg-success"></i>
              <div className="desc">
                <div className="title">{this.state.UserList[i].firstName} {this.state.UserList[i].name}</div>
                <small>{this.state.UserList[i].email }</small>
              </div>
                <div className="value">
                  <button type="button" onClick={this.toggleAddUser.bind(this, this.state.UserList[i])} className="btn btn-link text-success">
                    <i className="icon-pencil" ></i>
                  </button>
                  { this.state.CanRemove ?
                  <button type="button" onClick={this.removeUser.bind(this, this.state.UserList[i]._id)} className="btn btn-link text-danger"><i className="icon-close" ></i></button>
                    : ''}
                  </div>
              </div>
              </Row>
            </li>
          );
    }
    return toRender;
  }

  renderUserDetails(user) {
    var toRender = [];
    if (user != undefined && user._id) {
      toRender.push(<Row key={this.state.MainUser._id}>
        <Col lg="6">
          <div className="descCustom">
            <div className="title">{this.t('Surname')}</div>
            <small>{user.name}</small>
          </div>
          <div className="descCustom">
            <div className="title">{this.t('Email')}</div>
            <small>{user.email}</small>
          </div>
          <div className="descCustom">
            <div className="title">{this.t('Role')}</div>
            <small>{user.role}</small>
          </div>
        </Col>
        <Col lg="6">
          <div className="descCustom">
            <div className="title">{this.t('Firstname')}</div>
            <small>{user.firstName}</small>
          </div>
          <div className="descCustom">
            <div className="title">{this.t('Phone')}</div>
            <small>+{user.phone}</small>
          </div>
        </Col>
        { user.accessRight ?
        <Col lg="12">
          <hr/>
          <h4>{this.t('AccessRights')}</h4>
          { user.accessRight.sensors && user.accessRight.sensors.length > 0 ?
          <div className="descCustom">
            <div className="title">{this.t('Sensors')}</div>
            { user.accessRight.sensors.map(element => <small>{element.name}<br/></small>)}
          </div> : '' }
          { user.accessRight.infras && user.accessRight.infras.length > 0 ?
          <div className="descCustom">
            <div className="title">{this.t('Infras')}</div>
            { user.accessRight.infras.map(element => <small>{element.name}<br/></small>)}
          </div> : '' }
          { user.accessRight.floors && user.accessRight.floors.length > 0 ?
          <div className="descCustom">
            <div className="title">{this.t('Floors')}</div>
            { user.accessRight.floors.map(element => <small>{element.name}<br/></small>)}
          </div> : '' }
          { user.accessRight.rooms && user.accessRight.rooms.length > 0 ?
          <div className="descCustom">
            <div className="title">{this.t('Rooms')}</div>
            { user.accessRight.rooms.map(element => <small>{element.name}<br/></small>)}
          </div> : '' }
          { user.accessRight.alarms && user.accessRight.alarms.length > 0 ?
          <div className="descCustom">
            <div className="title">{this.t('Alarms')}</div>
            { user.accessRight.alarms.map(element => <small>{element.name}<br/></small>)}
          </div> : '' }
          <hr/>
        </Col> : ''}
      </Row>);
      if (user.groups)
        toRender.push(this.renderDetailGroups(user));
      toRender.push(<br/>);
      toRender.push(
        <button type="button" onClick={this.toggle.bind(this)} className="btn btn-link text-primary big-center">
          <i className="icon-plus" ></i>
        </button>);
    }
    return toRender;
  }

  renderDetailGroups(user) {
    const toRender = [];
    for (let i = 0; i < user.groups.length; i += 1) {
      const group = user.groups[i];
      toRender.push(<Row>
        <Col>
          <h5>{group.name}</h5>
        </Col>
        <button type="button" onClick={this.toggleACL.bind(this, group)} className="btn btn-link text-success">
          <i className="icon-pencil" ></i>
        </button>
        <button type="button" onClick={this.quitGroup.bind(this, group._id, user._id)} className="btn btn-link text-danger">
          <i className="icon-close" ></i>
        </button>
      </Row>);
      toRender.push(<hr/>);
    }
    return toRender;
  }

  toggleACL(group) {
    this.setState({
      GroupACL: group,
      ModalEditACL: !this.state.ModalEditACL
    });
  }

  toggle(actPerm) {
    if (actPerm && actPerm._id) {
      this.setState({
        GroupPermission: actPerm,
        ModalAddUserToGroup: !this.state.ModalAddUserToGroup
      });
    } else {
      this.setState({
        GroupPermission: undefined,
        ModalAddUserToGroup: !this.state.ModalAddUserToGroup
      });
    }
  }
  toggleAddUser(user, self) {
    this.setState({
      UserToEdit: self ? user : undefined,
      ModalAddUser: !this.state.ModalAddUser
    });
    this.getUserDetails();
  }

  removeUser(id) {
    const pwd = window.prompt(`You will not be able to undo this action.
    \nDo you still want to remove this account ?
    \nPlease confirm your choice by entering your password.`);
    if (pwd) {
      var url = Conf.BaseApi + "users/remove";
      const opt = {
        userId: id,
        password: pwd
      }
      return postRequest(url, opt, (result) => {
        if (result.success === true) {
          this.getUserList();
        } else {
          alert(result.message);
        }
      });
    }
  }

  assignGroup() {
    let url = Conf.BaseApi + 'users/permission/edit';
    
    return postRequest(url, {
      usersId: [this.state.MainUser._id],
      userGroup:$("#assignUserGroup").val()
    }, (data) => {
      if (data.success || data.success == true) {
        this.toggle();
        this.getUserDetails(this.state.MainUser._id);
      } else {
        $("#error-window").text(data.message);
      }
    });
  }

  renderUserGroup() {
    var toRender = [];
    toRender.push(
      <option key={999} value=''>{this.t('AllGroups')}</option>
    );
    for (var i = 0; i < this.state.UserGroups.length; i++) {
        toRender.push(
          <option key={i} value={this.state.UserGroups[i]._id}>{this.state.UserGroups[i].name}</option>
        );
    }
    return toRender;
  }

  editGroupPermission(group, userId) {
    const actPerm = group.users.find(u => u.id === userId);
    if (actPerm) {
      actPerm.group = group._id;
      this.toggle(actPerm);
    }
  }

  toggleHiddable(hiddable) {
    $('#' + hiddable).toggleClass('div-visible');
  }

  render() {
    const divStyle = {
      width: '60%'
    };
    
    return (
      <div className="row">
        <div className="col-lg-6 col-md-12">
          <div className="card">
            <div className="card-header">
                      <div className="form-group mb-3">
                         <label htmlFor="sensorType">{this.t('UserGroup')}</label>
                         <select className="form-control" onChange={(evt) => {
                              this.setState({SelectedGroup: evt.target.value}); 
                              this.getUserList(evt.target.value);
                            } }>
                           {this.renderUserGroup()}
                         </select>
                     </div>
            </div>
            <div className="card-block">
              <ul className="icons-list">
                {this.renderUsersList()}
              </ul>
              <br />
              {this.state.CanCreate ? <button type="button" onClick={this.toggleAddUser.bind(this)} className="btn btn-primary btn-lg" >{this.t('Add')}</button> : "" }
            </div>
          </div>
          
        { this.state.MainUser._id && check("user:event") ? 
          <div className="card">
            <div className="card-header hiddableHeader" onClick={() => this.toggleHiddable('hiddableEvent')}>
              <i className="fa fa-envelope-o"></i> {this.t('Events')}
            </div>
            <div className="card-block hiddable" id='hiddableEvent'>
                <EventTable UserId={this.state.MainUser._id} key={this.state.MainUser._id}/>
            </div>
          </div> : '' }
          </div>
        <div className="col-lg-6 col-md-12">
          <div className="card">
            <div className="card-header">
                      <div className="form-group mb-3">
                        <i className="fa fa-align-justify"></i> {this.t('Sidebar.UserManagment')}
                     </div>
            </div>
            <div className="card-block">
                {this.renderUserDetails(this.state.MainUser)}
            </div>
          </div>
        </div>
        {this.state.UserToEdit !== undefined ?
        <AddUserModal isOpen={this.state.ModalAddUser} toggle={this.toggleAddUser.bind(this)} userGroups={this.state.UserGroups} userToEdit={this.state.UserToEdit}/>
        :
        <AddUserModal isOpen={this.state.ModalAddUser} toggle={this.toggleAddUser.bind(this)} userGroups={this.state.UserGroups}/>
        }

        <EditGroupACLModal isOpen={this.state.ModalEditACL} toggle={this.toggleACL.bind(this, {})} userGroup={this.state.GroupACL} user={this.state.MainUser}/>

        <Modal isOpen={this.state.ModalAddUserToGroup} className={'modal-md ' + this.props.className}>
                    <ModalHeader>
                        <Row style={{paddingLeft:'15px', paddingRight:'15px'}}>
                            <h1>{this.t('Account.AddUser')}</h1>
                            <i className="icon-close color-danger" onClick={this.toggle.bind(this)}
                                style={{position:'absolute', textAlign: 'right', right:'15px', cursor:'pointer'}}></i>
                        </Row>
                    </ModalHeader>
          <ModalBody>
            <div className="flex-row align-items-center">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-md-12">
                    <div className="card mx-12">
                      <div className="card-block p-12">
                        <div className="input-group mb-3" style={{ color: "red" }} id="error-window">

                        </div>

                      <div className="form-group mb-3">
                         <label htmlFor="sensorType">{this.t('UserGroup')}</label>
                         <select className="form-control" id="assignUserGroup" >
                           {this.renderUserGroup()}
                         </select>
                     </div>
                        <button type="button" className="btn btn-block btn-success" onClick={this.assignGroup.bind(this)}>{this.t('Add')}</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </div>

    )
  }

}

export default translate(ManageUsers);
