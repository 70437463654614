import React, { Component } from 'react';
import Conf from '../../utils/Conf';
import { getRequest, postRequest } from '../../utils/WebServicesManager';
import { getDateStr } from '../../utils/Utils';
import { translate, exists } from '../../utils/ReactMultiLang';
import ReactTable from "react-table";
import 'react-table/react-table.css';
import diff from 'deep-diff';

class EventTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      pages: 10,
      loading: false,
      selection: [],
      selectAll: false,
      SensorId: props.SensorId,
      UserId: props.UserId,
    };
    this.fetchData = this.fetchData.bind(this);
    this.t = props.t;
  }
  
  requestData(pageSize, page, sorted, filtered) {
    return new Promise((resolve, reject) => {
      var url = Conf.BaseApi + 'events/getSorted';

      if (sorted.length === 0) {
        sorted = [{id: "date", desc: false}];
      }

      postRequest(url, {pageSize, page, sorted, filtered, sensorId: this.state.SensorId, userId: this.state.UserId}, (data) => {
        if (data.success) {
          const res = {
            rows: data.result.rows,
            pages: data.result.pages
          };
          resolve(res);
        } else {
          reject(new Error(data.message))
        }
      });
    });
  }

  fetchData(state, instance) {
    // Whenever the table model changes, or the user sorts or changes pages, this method gets called and passed the current table model.
    // You can set the `loading` prop of the table to true to use the built-in one or show you're own loading bar if you want.
    this.setState({ loading: true });
    // Request the data however you want.  Here, we'll use our mocked service we created earlier
    if (!state) state = this.state.dataParam;

    this.requestData(
      state.pageSize,
      state.page,
      state.sorted,
      state.filtered
    ).then(res => {
      // Now just get the rows of data to your React Table (and update anything else like total pages or loading)
      this.setState({
        data: res.rows,
        pages: res.pages,
        loading: false,
        dataParam: state
      });
    });
  }

  render() {
    const { pages, loading } = this.state;
    const columns = this.getColumns(this.state.dataList);
    
    return (
        <div className="col-lg-12 col-md-12">
            <ReactTable key={this.state.buildingId}
              filterable={true}
              defaultFilterMethod={(filter, row) =>
                String(row[filter.id]).includes(filter.value)}
              data={this.state.data}
              manual // Forces table not to paginate or sort automatically, so we can handle it server-side
              pages={pages} // Display the total number of pages
              loading={loading}
              ref={r => (this.checkboxTable = r)}
              onFetchData={this.fetchData}
              columns={columns}
              building={this.state.buildingId}
              defaultPageSize={10}
              className="-striped -highlight"
            />
        </div>
    );
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (diff(nextState, this.state) ? true : false);
  }


  /** React-table */
  
  getColumns(dataList) {
    const columns = [
      { id: 'date', accessor: d => getDateStr(new Date(d.date).getTime()), Header: this.t('Date') },
      { id: 'type', accessor: d => d.type, Header: this.t('Type') }
    ];
    if (this.state.SensorId) {
      columns.push({ id: 'description', accessor: d => d.description, Header: this.t('Description') });
      columns.push({ id: 'user', accessor: d => d.user ? d.user.name + ' ' + d.user.firstName : '', Header: this.t('User') });
    } else {
      columns.push({ id: 'description', accessor: d => d.description, Header: this.t('Description') });
      columns.push({ id: 'sensor', accessor: d => d.sensor ? d.sensor.name : '', Header: this.t('Sensor') });
    }
    columns.push({ id: 'building', accessor: d => d.building ? d.building.name : '', Header: this.t('Infra') });
    return columns;
  }

}

export default translate(EventTable);