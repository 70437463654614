import $ from 'jquery';
import { getAccessToken, getDashToken, getReportToken } from './AuthServices';
import { cacheTokenizer } from './Utils';

/*
** Function that send get request and cache the result
** url - url of the request
** callback to call when the request end
*/
export function heavyGetRequest(url) {
  const token = cacheTokenizer(url);
  const cacheData = sessionStorage.getItem(token);
  const jsonData = cacheData ? JSON.parse(cacheData) : null;
  return [
    jsonData,
    new Promise((resolve, reject) => {
      $.ajax({
        url: url,
        type: 'GET',
        dataType: 'json',
        beforeSend: function (xhr) {
          xhr.setRequestHeader('x-access-token', getAccessToken());
        }
      }).then((data) => {
        try {
          sessionStorage.setItem(token, JSON.stringify(data));
        } catch (e) { console.log(e) }
        resolve(data);
      }).catch(e => {
        reject(e);
      });
    })
  ];
}
/*
** Function that send posst request
** url - url of the request
** options - option to send in json format
** callback to call when the request end
*/
export function heavyPostRequest(url, options) {
  const token = cacheTokenizer(url, options);

  const cacheData = sessionStorage.getItem(token);
  const jsonData = cacheData ? JSON.parse(cacheData) : null;

  return [
    jsonData,
    new Promise((resolve, reject) => {
      $.ajax({
        url: url,
        type: 'POST',
        data: options,
        dataType: 'json',
        beforeSend: function (xhr) {
          xhr.setRequestHeader('x-access-token', getAccessToken());
        }
      }).then((data) => {
        try {
          sessionStorage.setItem(token, JSON.stringify(data));
        } catch (e) { console.log(e) }
        resolve(data);
      }).catch(e => {
        reject(e);
      });
    })
  ];
}

/*
** Function that send get request
** url - url of the request
** callback to call when the request end
*/
export function getRequest(url, callback) {
  $.ajax({
    url: url,
    type: 'GET',
    dataType: 'json',
    beforeSend: function (xhr) {
      xhr.setRequestHeader('x-access-token', getAccessToken());
    }
  }).then((data) => {
    callback(data);
  });
}

/*
** Function that send get request no cache
** url - url of the request
** callback to call when the request end
*/
export function getRequestNoCache(url, callback) {
  $.ajax({
    cache: false,
    url: url,
    type: 'GET',
    dataType: 'json',
    beforeSend: function (xhr) {
      xhr.setRequestHeader('x-access-token', getAccessToken());
    }
  }).then((data) => {
    callback(data);
  });
}
/*
** Function that send posst request
** url - url of the request
** options - option to send in json format
** callback to call when the request end
*/
export function postRequest(url, options, callback) {
  $.ajax({
    url: url,
    type: 'POST',
    data: options,
    beforeSend: function (xhr) {
      xhr.setRequestHeader('x-access-token', getAccessToken());
    }
  }).then((data) => {
    callback(data);
  });
}

export function postRequestJSON(url, options, callback) {
  $.ajax({
    url: url,
    type: 'POST',
    dataType: 'json',
    contentType: "application/json; charset=utf-8",
    data: JSON.stringify(options),
  }).then((data) => {
    callback(data);
  });
}



/*
** Log in funcition
*/
export function notLoggedPostRequest(url, options, callback) {
  $.ajax({
    url: url,
    type: 'POST',
    dataType: 'json',
    data: options
  }).then((data) => {
    callback(data);
  });
}
export function simpleGetRequest(url, callback) {
  $.ajax({
    url: url,
    type: 'Get'
  }).then((data) => {
    callback(data);
  });
}
export function promiseGetRequest(url) {
  return new Promise((resolve, reject) => {
    $.ajax({
      url: url,
      type: 'Get',
      dataType: 'json',
      beforeSend: function (xhr) {
        xhr.setRequestHeader('x-access-token', getAccessToken());
      }
    }).then((data) => {
      resolve(data);
    }).catch((error) => {
      reject(error);
    });
  });
}

/*** Dashboard requests */

/*
** Function that send get request
** url - url of the request
** callback to call when the request end
*/
export function dashGetRequest(url, callback) {
  $.ajax({
    url: url,
    type: 'GET',
    dataType: 'json',
    beforeSend: function (xhr) {
      xhr.setRequestHeader('x-dash-token', getDashToken());
    }
  }).then((data) => {
    callback(data);
  });
}

/*** Report requests */

/*
** Function that send get request
** url - url of the request
** callback to call when the request end
*/
export function reportGetRequest(url, callback) {
  $.ajax({
    url: url,
    type: 'GET',
    dataType: 'json',
    beforeSend: function (xhr) {
      xhr.setRequestHeader('x-report-token', getReportToken());
    }
  }).then((data) => {
    callback(data);
  });
}
/*
** Function that send post request
** url - url of the request
** callback to call when the request end
*/
export function reportPostRequest(url, options, callback) {
  $.ajax({
    url: url,
    type: 'POST',
    data: options,
    beforeSend: function (xhr) {
      xhr.setRequestHeader('x-report-token', getReportToken());
    }
  }).then((data) => {
    callback(data);
  });
}