import React, { Component } from 'react';
import $ from 'jquery';
import Conf from '../../../../utils/Conf';
import { getRequest, postRequest } from '../../../../utils/WebServicesManager';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import SelectGroup from '../../../../components/Selects/SelectGroup';
import SelectSensors from '../../../../components/Selects/SelectSensors';
import { translate } from '../../../../utils/ReactMultiLang';


class AddFloor extends Component {
  constructor(props) {
    super(props);
    this.t = props.t;
  }


  addFloor(builid) {
    var url = Conf.BaseApi + 'floors/add';

    let sensors = [];
    const inputsSensors = $("input[name=sensorsName]");
    for (let i = 0; i < inputsSensors.length; i += 1) {
      sensors.push(inputsSensors[i].value);
    }

    return postRequest(url, {
      buildId: builid,
      sensorIds: sensors,
      floorName: $("#floorName").val(),
      floorNumber: $("#floorNumber").val()
    }, (data) => {
      if (data.success || data.success == true) {
        if (this.props.callback) {
          this.props.callback();
        } else {
          window.location.reload();
        }
      } else {
        $("#error-window-url").text(data.message);
      }
    });
  }
  editFloor(floorId) {
    var url = Conf.BaseApi + 'floors/edit';

    let sensors = [];
    const inputsSensors = $("input[name=sensorsName]");
    for (let i = 0; i < inputsSensors.length; i += 1) {
      sensors.push(inputsSensors[i].value);
    }
    
    return postRequest(url, {
      floorId: floorId,
      sensorIds: sensors,
      floorName: $("#floorName").val(),
      floorNumber: $("#floorNumber").val()
    }, (data) => {
      if (data.success || data.success == true) {
        if (this.props.callback) {
          this.props.callback();
        } else {
          window.location.reload();
        }
      } else {
        $("#error-window-url").text(data.message);
      }
    });
  }

  
  render() {
    const { building, actFloor } = this.props;

    return (
    <ModalBody>
      <div className="flex-row align-items-center">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12">
              <div className="card mx-12">
                <div className="card-block p-12">
                  <div className="input-group mb-3" style={{ color: "red" }} id="error-window-url">

                  </div>
                  <div className="input-group mb-3">
                    <span className="input-group-addon"><i className="icon-usergroup"></i></span>
                    <input type="text" className="form-control" placeholder={this.t('Name')} id="floorName" defaultValue={actFloor !== undefined ? actFloor.name : '' }/>
                  </div>
                  <div className="input-group mb-3">
                    <span className="input-group-addon"><i className="icon-usergroup"></i></span>
                    <input type="number" className="form-control" placeholder={this.t('Number')} id="floorNumber" defaultValue={actFloor !== undefined ? actFloor.number : '' }/>
                  </div>
                <label htmlFor="userGroup">{this.t('Sensors')}</label>
                      <SelectSensors entity='devices' group={building.group}
                        values={actFloor !== undefined && actFloor !== [] && actFloor.sensors !== undefined  ? actFloor.sensors.map((sens) => sens._id) : ""}
                        deviceRoute='true'/>
                  <button type="button" className="btn btn-block btn-success" 
                    onClick={actFloor !== undefined ? this.editFloor.bind(this, actFloor._id) : this.addFloor.bind(this, building._id)}>
                      {actFloor !== undefined ? this.t('Update') : this.t('Add')}
                    </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ModalBody>
    )
  }

}

export default translate(AddFloor);