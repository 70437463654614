import React, { Component } from "react";
import $ from 'jquery';
import { DiagramEngine, NodeModel, Toolkit, BaseWidget, BaseWidgetProps, DefaultPortLabel } from "storm-react-diagrams";


class AlarmNodeWidget extends BaseWidget {
	constructor(props) {
		super("srd-node", props);
		this.state = {};
	}

	generatePort(port) {
		return <DefaultPortLabel model={port} key={port.id} />;
	}

	render() {
		const {picto} = this.props.node;
		return (
			<div {...this.getProps()}  className={"complex-node node-value " + this.props.node.color}>
				<div className={this.bem("__title")}>
					<img src={`./img/picto/${picto}.png`} />
					<div className={this.bem("__name")}>{this.props.node.name}</div>
				</div>
				<div className={this.bem("__ports")}>
					<div className={this.bem("__in")}>
						{this.props.node.getPortsIn().map((port) => this.generatePort(port))}
					</div>
				</div>
			</div>
		);
	}
}

export default AlarmNodeWidget;