import React, { Component } from 'react';
import $ from 'jquery';
import Conf from '../../../utils/Conf';
import { arrayContainId } from '../../../utils/Utils';
import { getRequest, postRequest } from '../../../utils/WebServicesManager';
import { Modal, ModalHeader, ModalBody, Col, Row } from 'reactstrap';
import SelectGroup from '../../Selects/SelectGroup/SelectGroup';
import { translate } from '../../../utils/ReactMultiLang';
import { getUserId } from '../../../utils/AuthServices';
import CreateImage from '../../../components/ReportModals/CreateImage';
import SelectAlarmModels from '../../Selects/SelectAlarmModel';
import SelectSensors from '../../Selects/SelectSensors/SelectSensors';


class EditSensor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Sensor: props.sensor,
      group: props.sensor.group,
      UserGroups: [],
      FieldList: [],
      ModalPic: false,
      Picture: '',
      Customs: props.sensor.customFields ? props.sensor.customFields : [],
      ModalAddCustom: false
    }
    this.getAllUserGroups();
    this.getFieldList(props.sensor.DevEUI);
    this.t = props.t;
  }

  toggleAddCustom = () => {
    this.setState({ModalAddCustom: !this.state.ModalAddCustom});
  }

  togglePic() {
    this.setState({ModalPic: !this.state.ModalPic});
  }

  getAllUserGroups() {
    var url = Conf.BaseApi + 'groups/getAll';
    return getRequest(url, (data) => {
      if (data.success) {
        this.setState({ UserGroups: data.result });
      }
    });
  }
  
  getFieldList(DevEUI) {
      var url = Conf.BaseApi + `helpers/fields/${DevEUI}`;
      return getRequest(url, (result) => {
        if (result && result.success) {
          this.setState({ FieldList: result.result });
        }
      });
  }
  
  componentWillReceiveProps(nextProps) {
    if (nextProps.sensor !== this.props.sensor) {
      this.setState({Sensor: nextProps.sensor, Customs: nextProps.sensor ? nextProps.sensor.customFields : []});
    }
  }

  editSensor(callback, oldSensor) {
    var url = Conf.BaseApi + Conf.EditSensor;
    let groups = [];
    const inputs = $("input[name=groupsName]");
    for (let i = 0; i < inputs.length; i += 1) {
      groups.push(inputs[i].value);
    }
    let models = [];
    const inputsModels = $("input[name=alarmModels]");
    for (let i = 0; i < inputsModels.length; i += 1) {
      models.push(inputsModels[i].value);
    }
    const sensor = {};
    sensor.DevEUI = oldSensor.DevEUI;
    sensor.groups = groups;
    sensor.name = $("#sensorName").val();
    sensor.usage = $("#sensorUsage").val();
    sensor.group = $("#userGroup").val();
    //sensor.building = $("[name='InfraName']").val();
    sensor.pic = this.state.Picture;
    sensor.customFields = this.state.Customs;
    sensor.models = models;

    return postRequest(url, {
      sensor
    }, (data) => {
      if (data.success || data.success == true) {
        callback();
      } else {
        $("#error-window").text(data.message);
      }
    });
  }

  renderUserGroup(sensorGroup) {
    var toRender = [];

    for (var i = 0; i < this.state.UserGroups.length; i++) {
      const uGroup = this.state.UserGroups[i];
      if (arrayContainId(uGroup.providers, this.state.Sensor.provider)) {
        if (uGroup._id === this.state.Sensor.group._id) {
          toRender.push(
              <option key={i} value={uGroup._id} selected="selected">{uGroup.name}</option>
          );
        } else {
          toRender.push(
              <option key={i} value={uGroup._id}>{uGroup.name}</option>
          );
        }
      }
    }
    return toRender;
  }

  renderSensorGroups(SensorGroups) {
    var toRender = [];
    for (var i = 0; i < SensorGroups.length; i++) {
      toRender.push(
        <option key={i} value={SensorGroups[i]}>{SensorGroups[i]}</option>
      );
    }
    return toRender;
  }

  userGroupChange(updateActGroup, UserGroups, event) {
    let grp = {};
    for (let i = 0; i < UserGroups.length; i += 1) {
        if (UserGroups[i]._id === event.target.value) {
            grp = UserGroups[i];
            break ;
        }
    }
    updateActGroup(grp);
  }

  addPic(compo) {
    this.setState({ModalPic: false, Picture: compo.image});
  }

  render() {
    const { sensor, callback } = this.props;
    if (sensor === undefined) return <div></div>;
    return (
        <ModalBody>
        <div className="flex-row align-items-center">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-12">
                <div className="card mx-12">
                  <div className="card-block p-12">
                    <div className="input-group mb-3" style={{ color: "red" }} id="error-window">

                    </div>
                    <div className="input-group mb-3">
                      <span className="input-group-addon"><i className="icon-tag"></i></span>
                      <input type="text" className="form-control" placeholder={this.t('Name')} id="sensorName" defaultValue={sensor.name}/>
                    </div>
                    <div className="input-group mb-3">
                      <span className="input-group-addon"><i className="icon-lock"></i></span>
                      <input type="text" className="form-control" placeholder={this.t('Usage')} id="sensorUsage" defaultValue={sensor.usage}/>
                    </div>
                    
                    <div className="form-group mb-3">
                      <label htmlFor="sensorType">{this.t('UserGroup')}</label>
                      <select className="form-control" id="userGroup" defaultValue={sensor.group.name}>
                        {this.renderUserGroup(sensor.group)}
                      </select>
                    </div>

                    <label htmlFor="userGroup">{this.t('Tags')}</label>
                    <SelectGroup entity='devices' values={sensor.groups}/>

                    <label htmlFor="userGroup">{this.t('Model.Model')}</label>
                    <SelectAlarmModels model={sensor.model} values={sensor && sensor.alarmModels ? sensor.alarmModels.map(s => s._id) : ""}/>


                    <div className="form-group mb-3">
                      <label htmlFor="sensorCustoms">{this.t('Sensor.Customs')}:</label>
                      {this.getCustoms(this.state.Customs)}
                      <button type="button" className="btn btn-info" onClick={this.toggleAddCustom}>{this.t('Sensor.AddCustom')}</button>
                    </div>
                    
                    <button type="button" className="btn btn-block btn-primary" onClick={this.togglePic.bind(this)}>{this.t('Sensor.AddPicture')}</button>
                    <button type="button" className="btn btn-block btn-success" onClick={this.editSensor.bind(this, callback, sensor)}>{this.t('Update')}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal isOpen={this.state.ModalPic} className={'modal-md ' + this.props.className}>
                    <ModalHeader>
                        <Row style={{paddingLeft:'15px', paddingRight:'15px'}}>
                            <h1>{this.t('Component.Add')}</h1>
                            <i className="icon-close color-danger" onClick={this.togglePic.bind(this)}
                                style={{position:'absolute', textAlign: 'right', right:'15px', cursor:'pointer'}}></i>
                        </Row>
                    </ModalHeader>
          <ModalBody> 
            <CreateImage callback={this.addPic.bind(this)} /> 
          </ModalBody>
        </Modal>
        <Modal isOpen={this.state.ModalAddCustom} className={'modal-md ' + this.props.className}>
                    <ModalHeader>
                        <Row style={{paddingLeft:'15px', paddingRight:'15px'}}>
                            <h1>{this.t('Component.AddBool')}</h1>
                            <i className="icon-close color-danger" onClick={this.toggleAddCustom.bind(this)}
                                style={{position:'absolute', textAlign: 'right', right:'15px', cursor:'pointer'}}></i>
                        </Row>
                    </ModalHeader>
          <ModalBody>
            <div className="form-group mb-3">
              <div><label htmlFor="createCpDataName">{this.t('Component.DataToUse')}:</label></div>

              <select className="form-control" id="customField">
                {this.renderDeviceFields(this.state.FieldList)}
              </select>
            </div>
            <div className="input-group mb-3">
              <span className="input-group-addon"><i className="icon-direction"></i></span>
              <input type="text" className="form-control" placeholder={this.t('Custom.Label')} id="customLabel" />
            </div>
            <div className="input-group mb-3">
              <span className="input-group-addon"><i className="icon-vector"></i></span>
              <input type="text" className="form-control" placeholder={this.t('Custom.Unit')} id="customUnit" />
            </div>
            <div className="input-group mb-3">
              <span className="input-group-addon"><i className="icon-plus"></i></span>
              <input type="number" className="form-control" placeholder={this.t('Custom.Base')} defaultValue={0} id="customBase" />
            </div>
            <div className="input-group mb-3">
              <span className="input-group-addon"><i className="icon-close"></i></span>
              <input type="number" className="form-control" placeholder={this.t('Custom.Increment')} defaultValue={1} id="customIncrement" />
            </div>
            <button type="button" className="btn btn-block btn-success" onClick={this.addCustom.bind(this)}>{this.t('Add')}</button>
          </ModalBody>
        </Modal>
      </ModalBody>
    )
  }
/* 
                    <label htmlFor="userGroup">{this.t('Infrastrucutres')}</label>
                    <SelectSensors multi={false} entity='buildings'
                      value={sensor.building ? {value: sensor.building._id, label: sensor.building.name} : undefined} notMulti
                      propId='InfraName'/>
                      */
  getCustoms = (customs) => {
    if (customs) {
      
    return (
      <ul>
        {customs.map((custom, index) => (
          <li key={`item-${index}`}>
            {custom.fieldId} -> {custom.label}({custom.unit}) - [{custom.initValue} + {custom.increment}]
            <button onClick={this.deleteCustom.bind(this, index)}  type="button" className="btn btn-link text-danger"><i className="icon-close"></i></button>
          </li>
        ))}
      </ul>);
    }
  }


  addCustom() {
    const customs = this.state.Customs;
    const fieldId = $("#customField").val();
    const label = $("#customLabel").val();
    const unit = $("#customUnit").val();
    const initValue = $("#customBase").val();
    const increment = $("#customIncrement").val();
    customs.push({
      fieldId, label, unit, initValue, increment
    });
    this.setState({Customs: customs});
    this.toggleAddCustom();
  }
  renderDeviceFields(fields) {
    const toRender = [];
    if (this.state.Customs) {
      const customs = this.state.Customs.map(c => c.fieldId);
      for (var i = 0; i < fields.length; i++) {
        if (!customs.includes(fields[i])) {
          toRender.push(
            <option value={fields[i]} key={i}>{this.t(`SensorData.${fields[i]}`)}</option>
          );
        }
      }
    }
    return toRender;
  }
  deleteCustom(index) {
    const arr = this.state.Customs;
    arr.splice(index,1);
    this.setState({Customs: arr})
  }
}

export default translate(EditSensor);