import { getRole, getAccessRight, logout } from './AuthServices';

const mainRole = getRole();
const rules = getAccessRight();

export const check = (action, role) => {
    if (!rules || rules === "undefined") {
        logout();
        return false;
    }
    if (!role) role = mainRole;
    if(!rules[role]) {
        return false;
    }
    let $role = rules[role];
    // Check if this role has access
    if($role.can.indexOf(action) !== -1) {
        return true;
    }
    // Check if there are any parents
    if(!$role.inherits || $role.inherits.length < 1) {
        return false;
    }
  
    // Check child roles until one returns true or all return false
    for (let i = 0; i < $role.inherits.length; i += 1) {
        if (check(action, $role.inherits[i])) {
            return true;
        }
    }
    return false;
  };
  
  const Can = props =>
    check(props.perform)
      ? props.yes()
      : props.no();
  
  Can.defaultProps = {
    yes: () => null,
    no: () => null
  };
  
  export default Can;