import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom'
import PublicHeader from '../../components/PublicHeader/';
import Footer from '../../components/Footer/';

import BuildingDashboard from '../../views/Buildings/Dashboard';
/*import Planner from '../../views/Planner/';
<Route path="/planner" name="Planner" component={Planner}/>*/

class PublicDashboard extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="app sidebar-fixed sidebar-hidden">
        <PublicHeader />
        <div className="app-body">
            <div className="container-fluid">
              <Switch>
                <Route path="/public/buildingDashboard" name="BuildingDashboard" render={() => (
                  <BuildingDashboard isPublic />
                )}/>
              </Switch>
            </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default PublicDashboard;
