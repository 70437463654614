import React, { Component } from 'react';
import $ from 'jquery';
import Conf from '../../../utils/Conf';
import { getDateStr } from '../../../utils/Utils';
import { renderMessageData } from '../../../utils/RenderUtils';
import { getRequest, postRequest } from '../../../utils/WebServicesManager';
import { translate } from '../../../utils/ReactMultiLang';
import LineChats from '../../../components/Charts/LineChats/LineChats';
import ReactTable from "react-table";
import {
  Row, Col, Button
} from 'reactstrap';
import MessagesTable from '../../../components/MessagesTable/MessagesTable';
import { check } from '../../../utils/Can';
import FileSaver from 'file-saver';

class RoomDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ChartMessages: [],
      Room: {},
      Days: 1,
      MsgKey: 0,
      CanEdit: check('infra:edit'),
    };
    const params = new URLSearchParams(props.location.search); 
    this.roomId = params.get('roomId');
    if (!this.roomId) {
      return window.location = '#/buildings/manage';
    }
    this.t = props.t;
    this.getRoom();
  }


  getRoom() {
    this.state.ActualPage = 0;
    var url = Conf.BaseApi + 'rooms/getOne/' + this.roomId;
    getRequest(url, (data) => {
      if (data.success) {
        this.setState({ Room: data.result });
        this.getDatas(this.roomId, 1);
      }
    });
  }

  toggleHiddable(hiddable) {
		$('#'+hiddable).toggleClass('div-visible');
  }

  renderSensor(data, customs) {
    const toRender = [];
    if (data) {
      const keys = Object.keys(data);
      if (keys.length > 0) {
        toRender.push(
              <div className="buildingItem" >
                  <ul className="message-list">{renderMessageData(data, customs, this.t)}</ul>
              </div>
        );
      }
    }
    return toRender;
  }
  
  exportDatas() {
    var url = `${Conf.BaseApi}messages/export`;

    var options = {
      entityName: 'room',
      entityId: this.state.Room._id
    };
    return postRequest(url, options, (data) => {
      if (data.success !== false) {
        var blob = new Blob([data], {type: "data:application/csv;charset=utf-8"});
        FileSaver.saveAs(blob, "export.csv");
      }
    });
  }

  renderRoomDetail() {
    const columns = this.getColumns();

    var toRender = [];
    if (this.state.Room != undefined) {
      toRender.push(
      <div className="row" key={this.state.Room._id}>
        <div className="col-lg-6">
          <div className="descCustom">
            <div className="title">{this.t('Name')}</div>
            <small>{this.state.Room.name}</small>
          </div>
        </div>
        <div className="col-lg-12">
            <div className="title">{this.t('Sensors')}</div>
        { this.state.Room.sensors ? 
          <ReactTable key={this.state.roomId}
                filterable={false}
                data={this.state.Room.sensors}
                manual // Forces table not to paginate or sort automatically, so we can handle it server-side
                pages={1} // Display the total number of pages
                ref={r => (this.checkboxTable = r)}
                columns={columns}
                defaultPageSize={this.state.Room.sensors.length}
                className="-striped -highlight"
                SubComponent={row => {
                  let buffer = null;
                  
                  const toRender = [];
                  if (row.original.pic) {
                    buffer = new Buffer(row.original.pic);
                    toRender.push(<Col md='6' lg='2'>
                      <img src={buffer ? buffer.toString('utf8') : 'img/avatars/avatar_picture.png'} width='150px' alt="icon"/>
                    </Col>);
                  }
                  if (row.original.lastMessage) {
                    toRender.push(<Col lg='6'>{this.renderSensor(row.original.lastMessage.data, row.original.customFields)}</Col>);
                  }
                  return (<Row>{ toRender }</Row>);
                }}
              /> : '' }
        </div>
      </div>);
    }
    return toRender;
  }

  getColumns() {
    return [
      { id: 'name', accessor: d => <a href={`#/sensors/detail?deveui=${d.DevEUI}`}>{d.name}</a>, Header: this.t('Name') }, 
      { id: "type", Header: this.t('Type'), accessor: d => {
        const toRender = [];
        d.type.forEach(element => {
          toRender.push(<img src={`img/sensorIcones/${element}.png`} alt='' width={25}/>);
        });
        return (toRender);
      }, maxWidth: 80, filterMethod: (filter, sensor) => {
        const str = sensor._original.type.join();
        if (str.includes(filter.value))
          return (true);
        return false;
      } }, 
      { id: "usage", accessor: d => d.usage ? d.usage : '', Header: this.t('Usage') }, 
      { Header: this.t('Tags'), id: "groups", accessor: d => d.groups ? d.groups.join(', ') : '' }, 
      { id: "model", accessor: d => d.model ? d.model : '',  Header: this.t('DeviceModel') }, 
      { id: "DevEUI", accessor: d => d.DevEUI ? d.DevEUI : '', Header: this.t('DevEUI') }, 
      { id: "lastSeen", 
        maxWidth: 150,
        accessor: d => d.lastSeen ? getDateStr(new Date(d.lastSeen).getTime()) : '',
        Header: this.t('Message.Time') 
      }, { id: 'status', 
        accessor: d => <span className={(d.status === 'ACTIF') ? 'badge badge-success' : (d.status === 'NOSIGNAL') ? 'badge badge-danger' : 'badge badge-default'}>{this.t(d.status)}</span>,
        Header: this.t('Status'), maxWidth: 100 },
        {
            expander: true,
            Header: () => <strong>{this.t('More')}</strong>,
            width: 65,
            Expander: ({ isExpanded, ...rest }) =>
              <div>
                {isExpanded
                  ? <span>&#x2299;</span>
                  : <span>&#x2295;</span>}
              </div>,
            style: {
              cursor: "pointer",
              fontSize: 25,
              padding: "0",
              textAlign: "center",
              userSelect: "none"
            }
          }
    ];
  }

  renderAlarmModels(models) {
    var toRender = [];
    if (models != undefined) {
      for (let i = 0; i < models.length; i += 1) {
        toRender.push(
          <div>
            {models[i].name}<small>{models[i].desc}</small>
          </div>
        );
      }
    }
    return toRender;
  }
  
  changeMsgTableKey() {
    this.setState({MsgKey: this.state.MsgKey + 1})
  }

  removeOneMessage(messageId) {
    var r = window.confirm(this.t('Popup.Delete.Messages'));
    if (r == true) {
      var url = Conf.BaseApi + 'messages/remove/one/' + messageId;
      getRequest(url, (data) => {
        if (data.success && data.success == true) {
          this.changeMsgTableKey();
        }
      });
    }
  }


  render() {
    const divStyle = {
      width: '60%'
    };

    return (
      <div className="row">
        <div className="col-lg-12">
          <div className="row ">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-header hiddableHeader" onClick={() => this.toggleHiddable('hiddableSensor')}>
                  <i className="fa fa-bug"></i> {this.t('Room')}
                </div>
                <div className="card-block hiddable div-visible" id='hiddableSensor'>
                  {this.renderRoomDetail()}
                </div>
              </div>
            </div>
          </div>
        {this.getStatistics(this.state.ChartMessages)}
            <div className="col-lg-12">
              <div className="card">
                <div className="card-header">
                  <i className="fa fa-envelope-o"></i> {this.t('Messages')}
                  { this.state.CanEdit ? <Button onClick={this.exportDatas.bind(this)} style={{float:"right"}} color="success" className="btn btn-success">{this.t('Export')}</Button>: '' }
                </div>
                <div className="card-block hiddable div-visible" >
                <MessagesTable
                                model={'room'} 
                                roomId={this.roomId} 
                                key={this.state.MsgKey} 
                                removeOne={this.removeOneMessage.bind(this)}/>
                </div>
              </div>
            </div>
          </div>
        
      </div>
    );
  }
  /* Graph parts */
  getStatistics(ChartMessages) {
    if (!this.state.Room.roomType)
      return [];
    const render = [];

    render.push(this.renderTemperatureStatistics(ChartMessages));

    return (<div className="col-lg-12">{render}</div>);
  }
  
  renderTemperatureStatistics(ChartMessages) {
    var toRender = [];
    toRender.push(
      <div className="card">
        <div className="card-header">
        <div className="row">
          
        <div className="col-lg-3">
          <select className="form-control" onChange={e => {
            this.getDatas(this.roomId, e.target.value);
            }}>
            <option value={1}>1 {this.t('Days')}</option>
            <option value={7}>7 {this.t('Days')}</option>
            <option value={30}>1 {this.t('Months')}</option>
          </select>
        </div>
      </div>
      </div>
      </div>
    );
    if (!ChartMessages || ChartMessages.length === 0) return toRender;

    
    const { fields } = this.state.Room.roomType;
    if (!fields) return [];
    var colors = ["#09d7c2", "#FF0042", "#20a8d8", "#FF7A00"];
    var j = 0;
    
    for (var i = 0; i < fields.length; i++) {
      if (ChartMessages[fields[i].name].length == 2 && ChartMessages[fields[i].name][0].length > 2) {
        toRender.push(
          <div className="col-lg-12">
            <div className="row">
              <div className="col-lg-12"  key={this.state.Days + i}>
                <LineChats  isLocked={true} isPublic={false} component={{days: this.state.Days}}
                        chartDatas={[{
                          sensor: `${fields[i].name}`,
                          paramName:`${fields[i].name}`,
                          color: colors[j % 4]}]} sortedData={{[fields[i].name]: ChartMessages[fields[i].name]}}
                              subtitle={this.t(`SensorData.${fields[i].name}`)}
                              unit={fields[i].unit}
                              noReport={true}
                              noButton={false}/>
              </div>
            </div>
          </div>
        );
        j++;
      }
    }
    return (toRender);
  }

  getDatas(id, since) {
    const dateDays = since * 24 * 60 * 60; // in seconds
    const dateMinusDays = Math.floor(Date.now() / 1000) - dateDays;
    var url = Conf.BaseApi + `rooms/getDataSince/${id}/${dateMinusDays}`;
    return getRequest(url, (body) => {
        if (body.success) {
          const messages = body.result;
          this.setState({ChartMessages: messages, Days: since});
        }
    });
  }
}

export default translate(RoomDetails);