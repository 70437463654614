import { NodeModel, PortModel, Toolkit, DefaultPortModel } from 'storm-react-diagrams';

class SensorNodeModel extends NodeModel {
    constructor(name, color, picto) {
        super('sensor');
        this.addPort(new DefaultPortModel(false, Toolkit.UID(), 'sortie'));
        this.name = name;
        this.color = color;
        this.sensorId = '';
        this.dataName = '';
        this.mongoId = '';
        this.picto = picto;
    }


    deSerialize(object, engine) {
        let keys = Object.keys(this.getPorts());
        while (keys.length > 0) {
            this.removePort(this.getPorts()[keys[0]]);
            keys = Object.keys(this.getPorts());
        }
        super.deSerialize(object, engine);
        this.name = object.name;
        this.color = object.color;
        this.sensorId = object.sensorId;
        this.dataName = object.dataName;
        this.mongoId = object.mongoId;
        this.picto = object.picto;
    }

    serialize() {
        return Object.assign(super.serialize(), {
            name: this.name,
            color: this.color,
            sensorId: this.sensorId,
            dataName: this.dataName,
            mongoId: this.mongoId,
            picto: this.picto
        });
    }

    getPorts() {
        return this.ports;
    }
    setSensorId(val) {
        this.sensorId = val;
    }

    getSensorId() {
        return this.sensorId;
    }
    setDataName(val) {
        this.dataName = val;
    }

    getDataName() {
        return this.dataName;
    }
    setMongoId(val) {
        this.mongoId = val;
    }

    getMongoId() {
        return this.mongoId;
    }
}

export default SensorNodeModel;