import React from 'react';
import AvatarEditor from 'react-avatar-editor';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import { Button } from 'reactstrap';
import { postRequest } from '../../utils/WebServicesManager';
import Conf from '../../utils/Conf';
import { translate } from '../../utils/ReactMultiLang';
import $ from 'jquery';
import ReactWebsocket from '../ReactWebsocket';
import { getAccessToken } from '../../utils/AuthServices';

class MassProvisionning extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
          csv: undefined,
          message: undefined,
          countSuccess: 0,
          countFail: 0
        }
        this.t = props.t;
    }

  onClickSave = () => {
    if (this.state.csv) {
      this.refWebSocket.sendMessage(this.state.csv);
    }
  }

  handleData(data) {
    let jsonData = JSON.parse(data);
    if (!jsonData.result)
      this.setState({countSuccess: this.state.countSuccess + jsonData.countSuccess, countFail: this.state.countFail + jsonData.countFail});
    else {
      var link = document.createElement('a');
        link.download = 'data.txt';
        const result = jsonData.data;
        let json_string = "Success:\n\r";
        for (let i = 0; i < result.success.length; i += 1) {
          json_string += JSON.stringify(result.success[i]);
          json_string += "\n\r";
        }
        json_string += "Error:\n\r";
        for (let i = 0; i < result.errors.length; i += 1) {
          json_string += JSON.stringify(result.errors[i]);
          json_string += "\n\r";
        }
        var blob = new Blob([json_string], {type: 'text/plain'});
        link.href = window.URL.createObjectURL(blob);
        link.click();
    }
  }

  

  handleDrop = file => {
    const reader = new FileReader();

    reader.onabort = () => console.log('file reading was aborted');
    reader.onerror = () => console.log('file reading has failed');
    reader.onload = () => {
      // Do whatever you want with the file contents
      const binaryStr = reader.result;
      this.setState({ csv: binaryStr });
      $('#dropZoneDivMaster').toggleClass('hidden');
      $('#resultDiv').toggleClass('hidden');
    }
    reader.readAsBinaryString(file[0]);
  }
 
  setEditorRef = (editor) => this.editor = editor
 
  switchDivColor(color) {
    $('#dropZoneDiv').css("background-color", color);
  }

  render () {
    const { width, height } = this.props;

    return (
        <div>
        <Dropzone id='dropZoneDivMaster'
            onDrop={(event) => {
              this.handleDrop(event);
              this.switchDivColor('#f2f2ff');
            }}
            disableClick
            onDragEnter={this.switchDivColor.bind(this, '#f3fff3')}
            onDragLeave={this.switchDivColor.bind(this, '#f2f2ff')}
            style={{ width: `${width}%`, height: `${height}px` }}
        >
          <div id='dropZoneDiv' style={{margin:`10%`, textAlign:'center', width: `80%`, height: `80%`, backgroundColor:'#f2f2ff'}}>
            <p style={{paddingTop:`10%`}}>{this.t('Sensor.DropCSVHere')}</p>
          </div>
        </Dropzone>
        <aside id="resultDiv" className='hidden'>
            
          <p>Sensor successfully registered: <strong>{this.state.countSuccess}</strong></p>
          <p>Sensor fail: <strong>{this.state.countFail}</strong></p>
 

        </aside>
        <Button color="primary" className="btn btn-danger btn-block" onClick={this.onClickSave}>
                    <i className="fa fa-check-square-o"></i>&nbsp; Envoyer
            </Button>
            
          <ReactWebsocket url={Conf.WSURL + 'devices/mass/add'}
              protocol={getAccessToken()}
              onMessage={this.handleData.bind(this)}
                reconnect={true} debug={true}
              ref={Websocket => {
                this.refWebSocket = Websocket;
              }} />
        </div>
    )
  }
}
MassProvisionning.defaultProps = {
    width: 100,
    height: 200,
    borderRadius: 75,
    scale: 1.2
};

export default translate(MassProvisionning);