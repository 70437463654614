import React from 'react';

export function renderMessageData(data, customs, t) {
  const toRender = [];
  const keys = Object.keys(data);

  const iconList = {
    temperature: 'temp.png',
    temperature_externe: 'temp.png',
    humidity: 'drop.png',
    light: 'light.png',
    open_true: 'door_open.png',
    open_false: 'door.png',
    low_battery_true: 'battery-red.png',
    low_battery_false: 'half-battery.png',
  };
  const labelList = {
    temperature: '°C',
    temperature_externe: '°C',
    humidity: '%',
    light: 'lux'
  };
  const iconKeys = Object.keys(iconList);

  for (let i = 0; i < keys.length; i += 1) {
    if (customs) {
      const custom = customs.find(c => c.fieldId === keys[i]);
      if (custom) {
        
      toRender.push(
        <li className="message-item" key={i}>
           {custom.label}: <b>{custom.initValue + custom.increment * (Math.round(data[keys[i]] * 10) / 10)}</b> {custom.unit}
        </li>
      );
        continue ;
      }
    }

    if (keys[i] === 'open' || keys[i] === 'low_battery') {
      if (data[keys[i]]) {
        toRender.push(
          <li className="message-item" key={i}>
            <img src={`img/icon/${iconList[keys[i] + '_' + data[keys[i]]]}`} />
          </li>
        );
      }
    } else if (iconKeys.some(icon => icon === keys[i])){
        toRender.push(
          <li className="message-item" key={i}>
            <img src={`img/icon/${iconList[keys[i]]}`} /> {Math.round(data[keys[i]] * 10) / 10} {labelList[keys[i]]}
          </li>
        );
    }
  }
  return toRender;
}
