import React, { Component } from 'react';
import $ from 'jquery';
import Conf from '../../../utils/Conf';
import moment from 'moment';
import { getDateStr } from '../../../utils/Utils';
import { getRequest, postRequest } from '../../../utils/WebServicesManager';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter,
  Label, Input } from 'reactstrap';
  import { translate } from '../../../utils/ReactMultiLang';
import SelectSensors from '../../../components/Selects/SelectSensors';
import GPSMap from '../../../components/Charts/GPSMap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

class NetworkMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      from: moment().subtract(1, "months"),
      to: moment(),
      Sensors: [],
      Messages: {},
      messageLoaded: false,
      isHeatmap: false
    };
    this.GetDevices();
    this.t = props.t;
  }

  
  GetDevices() {
    let url = Conf.BaseApi + 'devices/type/FIELDTESTER';
    getRequest(url, (data) => {
      if (data.success) {
        const sensors = data.result
        const devList = [];
        for (let i = 0; i < sensors.length; i += 1) {
          devList.push(sensors[i].DevEUI);
        }
        this.setState({
          Sensors: sensors
        });
        this.GetMessages(devList);
      }
    });
  }

  GetMessages(devList) {
    let url = Conf.BaseApi + 'messages/getBetween/devices';
    postRequest(url, {
      devices: devList,
      from: this.state.from.unix(),
      to: this.state.to.unix()
    }, (data) => {
      if (data.success) {
        const messagesLists = data.result;
        this.setState({
          Messages: messagesLists,
          messageLoaded: true
        });
      }
    });
  }

  handleChangeFrom(from) {
    this.setState({from});
    this.GetDevices();
  }
  handleChangeTo(to) {
    this.setState({to});
    this.GetDevices();
  }

  onStatusChange(evt) {
    this.setState({isHeatmap: evt.target.checked});
  }

  render() {
    return (
      <div className="row">
         <div className="col-xl-12">
          <div className="row ">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-header">
                  <i className="fa fa-align-justify"></i> {this.t('Map')}
                  
                  <div style={{display:'inline-block', marginLeft:'25px', zIndex:'9000'}}>
                    <DatePicker
                      selected={this.state.from}
                      onChange={this.handleChangeFrom.bind(this)}
                      dateFormat="DD MMMM YYYY"
                  />
                  </div>
                  <div style={{display:'inline-block', marginLeft:'25px', zIndex:'9000'}}>
                  <DatePicker
                    selected={this.state.to}
                    onChange={this.handleChangeTo.bind(this)}
                    dateFormat="DD MMMM YYYY"
                  />
                  </div>
                  <div style={{display:'inline-block', marginLeft:'25px'}}>Heatmap: {' '}
                  <Label className="switch switch-text switch-pill switch-success">
                    <Input type="checkbox" className="switch-input"
                      defaultChecked={false}
                      onChange={this.onStatusChange.bind(this)}/>
                    <span className="switch-label" data-on="On" data-off="Off"></span>
                    <span className="switch-handle"></span>
                  </Label>
                  </div>
                </div>
                <div className="card-block">
                  <div style={{height:'700px'}}>
                  <GPSMap Messages={this.state.Messages} networks={true} Sensors={this.state.Sensors} isHeatmap={this.state.isHeatmap} readyToRender={this.state.messageLoaded}/>
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    )
  }
}

export default translate(NetworkMap);
