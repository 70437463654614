import React, { Component } from 'react';
import Conf from '../../utils/Conf';
import { getRequest, postRequest } from '../../utils/WebServicesManager';
import { getDateStr } from '../../utils/Utils';
import { translate, exists } from '../../utils/ReactMultiLang';
import ReactTable from "react-table";
import 'react-table/react-table.css';
import diff from 'deep-diff';
import { Button } from 'reactstrap';
import { check } from '../../utils/Can';

class MessagesTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      pages: 10,
      loading: false,
      selection: [],
      selectAll: false,
      DevEUI: props.DevEUI,
      CanRemove: check('sensor:remove'),
      dataList: [],
      isVirtual: props.isVirtual || false,
      VSensorId: props.sensorId,
      RoomId: props.roomId || undefined
    };
    this.removeOne = props.removeOne;
    this.fetchData = this.fetchData.bind(this);
    if (!this.state.isVirtual)
      this.getModelDataList(props.model);
    this.t = props.t;
  }
  
  componentWillReceiveProps(nextProps) {
    if (nextProps.model !== this.props.model) {
        this.getModelDataList(nextProps.model);
    }
  }

  getModelDataList(model) {
    var url = Conf.BaseApi + 'helpers/getModelsData';

    postRequest(url, {models: [model]}, (data) => {
      if (data.success) {
        let dataList = data.result;
        if (dataList && dataList.length > 0) {
          dataList = dataList.filter(d => exists(`SensorData.${d}`));
        }
        this.setState({dataList: dataList});
      } 
    });
  }
  

  requestData(pageSize, page, sorted, filtered) {
    return new Promise((resolve, reject) => {
      var url = Conf.BaseApi + 'messages/getSorted/computed';

      if (sorted.length === 0) {
        sorted = [{id: "time", desc: false}];
      }

      postRequest(url, {
        pageSize,
        page,
        sorted,
        filtered,
        DevEUI: this.state.DevEUI,
        sensorId: this.state.isVirtual ? this.state.VSensorId : "",
        roomId: this.state.RoomId ? this.state.RoomId : ""}, (data) => {
        if (data.success) {
          const res = {
            rows: data.result.rows,
            pages: data.result.pages
          };
          resolve(res);
        } else {
          reject(new Error(data.message))
        }
      });
    });
  }

  fetchData(state, instance) {
    // Whenever the table model changes, or the user sorts or changes pages, this method gets called and passed the current table model.
    // You can set the `loading` prop of the table to true to use the built-in one or show you're own loading bar if you want.
    this.setState({ loading: true });
    // Request the data however you want.  Here, we'll use our mocked service we created earlier
    if (!state) state = this.state.dataParam;

    this.requestData(
      state.pageSize,
      state.page,
      state.sorted,
      state.filtered
    ).then(res => {
      // Now just get the rows of data to your React Table (and update anything else like total pages or loading)
      this.setState({
        data: res.rows,
        pages: res.pages,
        loading: false,
        dataParam: state
      });
    });
  }

  render() {
    const { pages, loading } = this.state;
    const columns = this.getColumns(this.state.dataList);
    
    return (
        <div className="col-lg-12 col-md-12">
            <ReactTable key={this.state.buildingId}
              filterable={false}
              defaultFilterMethod={(filter, row) =>
                String(row[filter.id]).includes(filter.value)}
              data={this.state.data}
              manual // Forces table not to paginate or sort automatically, so we can handle it server-side
              pages={pages} // Display the total number of pages
              loading={loading}
              ref={r => (this.checkboxTable = r)}
              onFetchData={this.fetchData}
              columns={columns}
              building={this.state.buildingId}
              defaultPageSize={10}
              className="-striped -highlight"
            />
        </div>
    );
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (diff(nextState, this.state) ? true : false);
  }

  /** React-table */
  getColumns(dataList) {
    const columns = [
      { id: 'time', accessor: d => getDateStr(d.time * 1000), Header: this.t('Date'), width: 150 }
    ];
    if (dataList && dataList.length > 0) {
      for (let i = 0; i < dataList.length; i += 1) {
          columns.push({ id: dataList[i], accessor: d => d.data ? d.data[dataList[i]] : '', Header: this.t(`SensorData.${dataList[i]}`) });
      }
    } else if (this.state.data && this.state.data.length > 0) {
      const firstMessage = this.state.data[0].data;
      if (firstMessage) {
        const keys = Object.keys(firstMessage);
        for (let i = 0; i < keys.length; i += 1) {
            columns.push({ id: keys[i].toLowerCase(), accessor: d => d.data ? d.data[keys[i]] : '', Header: keys[i] });
        }
      }
    }
    if (this.removeOne && this.state.CanRemove) {
      columns.push({ id: 'remove', accessor: d => <Button onClick={() => this.removeOne(d._id)} style={{float:"right"}} color="danger" className="btn btn-danger" style={{ marginTop: "-5px", marginBottom: "-5px",  fontSize: "0.75em", lineHeight: "0.9em" }}><i className="icon-trash"></i></Button>, width: 50 });
    }
    return columns;
  }

}

export default translate(MessagesTable);