import React, { Component } from 'react';
import { translate } from '../../../utils/ReactMultiLang';
import 'react-cron-generator/dist/cron-builder.css'
import { getRequest } from '../../../utils/WebServicesManager';
import Conf from '../../../utils/Conf';
import { Col, Modal } from 'reactstrap';
import SensorSelector from '../../Modals/SensorSelector';


class GenerateTemplate extends Component {
  constructor(props) {
    super(props);
    this.t = props.t;
    this.state = {
      Templates: [],
      SelectedTemplate: undefined,
      TemplateVars: {},
      SelectedVars: {},
      varToEdit: undefined,
      Modal: false,
      SensorTypes: undefined
   }
   this.getTemplates();
  }

  toggleSelectSensor() {
    this.setState({Modal: !this.state.Modal});
  }

  getTemplates() {
    var url = Conf.BaseApi + 'templateReports/get';
      return getRequest(url, (result) => {
        if (result.success === true) {
          this.setState({ Templates: result.result });
        }
      });
  }

  sendTemplate(callback) {
    if (this.state.SelectedTemplate) {
      const template = {realVar: this.state.TemplateVars, templateId: this.state.SelectedTemplate._id, name: this.state.SelectedTemplate.name};
      return callback(template);
    }
    return callback(undefined);
  }

  generateTemplateSelector(templates) {
    const toRender = [];
    toRender.push(<option value={undefined}>-- {this.t('Template.Select')} --</option>);

    for (let i = 0; i < templates.length; i += 1) {
      toRender.push(<option value={templates[i]._id}>{templates[i].name}</option>);
    }
    return toRender;
  }

  selectTemplate(event) {
    const template = this.state.Templates.find(t => t._id === event.target.value);

    this.setState({SelectedTemplate: template});
  }

  generateFields(template) {
    const toRender = [];

    if (!template) return toRender;

    for (let i = 0; i < template.templateVar.length; i += 1) {
      const { placeHolder, id, type, sensorType} = template.templateVar[i];
      switch(type) {
        case "Sensor":
        toRender.push(
            <Col md='12' sm='12'>
              <label htmlFor="alarmLevelField">{placeHolder}</label>
              <div className="input-group mb-3">
				        
                  <button type="button" className={`btn btn-block ${this.state.SelectedVars[id] ? 'btn-success' : 'btn-warning'}`} onClick={() => {
                    this.setState({SensorTypes: sensorType, varToEdit: id}, () => this.toggleSelectSensor());
                  }}>{this.state.SelectedVars[id] ? this.state.SelectedVars[id] : this.t("Select")}</button>
				        
              </div>
            </Col>);
          break;
        case "Text":
        toRender.push(
            <Col md='12' sm='12'>
              <label htmlFor="alarmLevelField">{placeHolder}</label>
              <div className="input-group mb-3">
                <input type="text" className="form-control" id={id} onChange={(ev) => this.state.TemplateVars[id] = ev.target.value}/>
              </div>
            </Col>);
          break;
      }
    }

    return toRender;
  }

  sensorSelected(sensors) {
    if (sensors.length > 0 && this.state.varToEdit) {
      const { DevEUI, name } = sensors[0];
      this.state.TemplateVars[this.state.varToEdit] = DevEUI;
      this.state.SelectedVars[this.state.varToEdit] = name;
      this.setState({Modal: false});
    }
  }

  render() {
    const { callback } = this.props;

    return (
      <div className="flex-row align-items-center">
      
      
      <div className="input-group mb-3">
                      <select className="form-control" id="sensorModel" onChange={this.selectTemplate.bind(this)}>
        {this.generateTemplateSelector(this.state.Templates)}
                      </select>
                    </div>
        <br />
        {this.generateFields(this.state.SelectedTemplate)}
        <br />
        
        <button type="button" className="btn btn-block btn-info"
                    onClick={() => this.sendTemplate(callback)}>
                    {this.state.SelectedTemplate ? this.t('Next') : this.t('Previous')}
        </button>
        <Modal isOpen={this.state.Modal} className={'modal-lg ' + this.props.className}>
           <SensorSelector callbackAddSensor={this.sensorSelected.bind(this)} types={this.state.SensorTypes}/>
        </Modal>
      </div>
    )
  }

}
/*

                  {this.t('AdminGroups')} :
                  <SelectGroup scopeParam="linked" values={report !== undefined ? report.adminGroups : undefined}/>
                  {this.t('SimpleUsers')} :
                  <SelectUser scopeParam="linked" selectId='userSimple' values={report !== undefined ? report.simpleUsers : undefined}/>
                  {this.t('SimpleGroup')} :
                  <SelectGroup scopeParam="linked" values={report !== undefined ? report.simpleGroups : undefined}/>
*/
export default translate(GenerateTemplate);