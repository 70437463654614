import React, { Component } from 'react';
import Conf from '../../../utils/Conf';
import { renderMessageData } from '../../../utils/RenderUtils';
import { getRequest, postRequest } from '../../../utils/WebServicesManager';
import { getDateStr } from '../../../utils/Utils';
import {
  Button
} from 'reactstrap';
import { translate } from '../../../utils/ReactMultiLang';
import ReactTable from "react-table";
import checkboxHOC from "react-table/lib/hoc/selectTable";
import 'react-table/react-table.css';
import diff from 'deep-diff';
import {
  Row, Col
} from 'reactstrap';

class DetailTable extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
    if (!this.state || this.state === {}) {
      this.state = {
        data: [],
        pages: 10,
        loading: false,
        selection: [],
        selectAll: false,
        dataParam: {},
        buildingId: props.buildingId
      };
    }
    if (!(this.state.data.length > 0)) {
      this.getBuildingDetails(props.buildingId);
    }
    this.fetchData = this.fetchData.bind(this);
    this.CheckboxTable = checkboxHOC(ReactTable);
    this.t = props.t;
  }
  customSetState(state) {
    this.setState(state, () => localStorage.setItem('InfraSensorTable', JSON.stringify(this.state)));
  }
  getInitialState () {
    const state = JSON.parse(localStorage.getItem('InfraSensorTable') || '{}');
    if (state !== {}) {
      if (!state.dataParam) {
        return null;
      }
      if (state.Sensors && this.props.callback) {
        this.props.callback(state.Sensors);
      }
    }
    return state;
  }
  
  componentWillReceiveProps(nextProps) {
    if (nextProps.buildingId !== this.props.buildingId) {
      this.customSetState({dataParam: {}, buildingId: nextProps.buildingId});
        this.getBuildingDetails(nextProps.buildingId);
    }
  }


  getBuildingDetails(id) {
    if (!id && this.state.Building !== undefined) {
      id = this.state.Building._id;
    }
    let url = Conf.BaseApi + 'buildings/get/' + id;
    getRequest(url, (data) => {
      if (data.success) {
        const building = data.result;
        url = Conf.BaseApi + 'devices/building/' + id;
        getRequest(url, (dataS) => {
          if (dataS.success) {
            const sensors = dataS.result;
            for(let i = 0; i < sensors.length; i += 1) {
              let sensor = sensors[i];
              if (building.floors) {
                for (let j = 0; j < building.floors.length; j += 1) {
                    const f = building.floors[j];
                    if (f.sensors) {
                        const index = f.sensors.findIndex(s => s._id === sensor._id);
                        if (index !== -1) {
                            sensor.room = null;
                            sensor.floor = `${f.name} - ${f.number}`;
                            break ;
                        }
                    }
                    if (f.rooms) {
                        const roomIndex = f.rooms.findIndex(r => {
                            if (r.sensors) {
                                return r.sensors.some(s => s._id === sensor._id);
                            }
                            return false;
                        });
                        if (roomIndex !== -1) {
                            sensor.room = `${f.rooms[roomIndex].name}`;
                            sensor.floor = `${f.name} - ${f.number}`;
                            break ;
                        }
                    }
                }
              }
              if (building.rooms) {
                for (let j = 0; j < building.rooms.length; j += 1) {
                    const r = building.rooms[j];
                    if (r.sensors) {
                        const index = r.sensors.findIndex(s => s._id === sensor._id);
                        if (index !== -1) {
                            sensor.room = `${r.name}`;
                            sensor.floor = null;
                            break ;
                        }
                    }
                }
              }
            }
            if (this.props.callback) {
              this.props.callback(sensors);
            }
            this.customSetState({ Sensors: sensors, data: sensors.slice(0, 10), pages: Math.floor(sensors.length / 10) + (sensors.length % 10 ? 1 : 0) });
          } else {
            this.customSetState({ data: [] });
          }
          this.customSetState({ ModalEditBuild: false, ModalAddFloor: false, ModalAddRoom: false });
        });
      }
    });
  }
  /*requestData(pageSize, page, sorted, filtered, buildingId) {
    return new Promise((resolve, reject) => {
      var url = Conf.BaseApi + 'devices/getSorted';
      let jsonFilter = { building: buildingId };

      postRequest(url, {pageSize, page, sorted, filtered, jsonFilter}, (data) => {
        if (data.success) {
          const res = {
            rows: data.result.rows,
            pages: data.result.pages
          };
          resolve(res);
        } else {
          reject(new Error(data.message))
        }
      });
    });
  }*/
  renderSensor(data, customs) {
    const toRender = [];
    if (data) {
      const keys = Object.keys(data);
      if (keys.length > 0) {
        toRender.push(
              <div className="buildingItem" >
                  <ul className="message-list">{renderMessageData(data, customs, this.t)}</ul>
              </div>
        );
      }
    }
    return toRender;
  }

  fetchData(state, instance) {
    if (this.state.Sensors) {
        let data = this.state.Sensors.slice(0);

        for (let i = 0; i < state.filtered.length; i += 1) {
            data = data.filter((d) =>
                ('' + d[state.filtered[i].id]).includes(state.filtered[i].value)
            );
        }

        if (state.sorted.length > 0) {
            data.sort((a, b) =>
                !state.sorted[0].desc ?
                ('' + a[state.sorted[0].id]).localeCompare('' +b[state.sorted[0].id]) :
                ('' + b[state.sorted[0].id]).localeCompare('' +a[state.sorted[0].id])
            );
        }

        this.customSetState({
          dataParam: state,
          data: data.slice(state.page * state.pageSize, (state.page + 1) * state.pageSize),
          pages: Math.floor(data.length / state.pageSize) + (data.length % state.pageSize ? 1 : 0)
        });
    }
  }

  render() {
    const { pages, loading } = this.state;
    const columns = this.getColumns();
    
    return (
      <div className="row">
        <div className="col-lg-12 col-md-12">
            { this.state.buildingId ? 
            <ReactTable key={this.state.buildingId}
              filterable={true}
              defaultFilterMethod={(filter, row) =>
                String(row[filter.id]).includes(filter.value)}
              data={this.state.data}
              manual // Forces table not to paginate or sort automatically, so we can handle it server-side
              pages={pages} // Display the total number of pages
              loading={loading}
              ref={r => (this.checkboxTable = r)}
              onFetchData={this.fetchData}
              columns={columns}
              building={this.state.buildingId}
              defaultPageSize={10}
              className="-striped -highlight"
              onPageChange={(pageIndex) => {
                this.state.dataParam.page = pageIndex;
                this.customSetState({dataParam: this.state.dataParam});
              }} // Called when the page index is changed by the user
              onPageSizeChange={(pageSize, pageIndex) => {
                this.state.dataParam.pageSize = pageSize;
                this.state.dataParam.page = pageIndex;
                this.customSetState({dataParam: this.state.dataParam});
              }} // Called when the pageSize is changed by the user. The resolve page is also sent to maintain approximate position in the data
              onSortedChange={(newSorted, column, shiftKey) => {
                this.state.dataParam.sorted = newSorted;
                this.customSetState({dataParam: this.state.dataParam});
              }} // Called when a sortable column header is clicked with the column itself and if the shiftkey was held. If the column is a pivoted column, `column` will be an array of columns
              
              onFilteredChange={(filtered, column) => {
                this.state.dataParam.filtered = filtered;
                this.customSetState({dataParam: this.state.dataParam});
              }} // Called when a user enters a value into a filter input field or the value passed to the onFiltersChange handler by the Filter option.
              onResizedChange={(newResized, event) => {
                this.state.dataParam.resized = newResized;
                this.customSetState({dataParam: this.state.dataParam});
              }} // Called when a user clicks on a resizing component (the right edge of a column header)            
              page={this.state.dataParam.page ? this.state.dataParam.page : 0}
              defaultSorted={this.state.dataParam.sorted ? this.state.dataParam.sorted : []}
              defaultFiltered={this.state.dataParam.filtered ? this.state.dataParam.filtered : []}
              defaultResized={this.state.dataParam.resized ? this.state.dataParam.resized : []}
              
              SubComponent={row => {
                let buffer = null;
                
                const toRender = [];
                if (row.original.pic) {
                  buffer = new Buffer(row.original.pic);
                  toRender.push(<Col md='6' lg='2'>
                    <img src={buffer ? buffer.toString('utf8') : 'img/avatars/avatar_picture.png'} width='150px' alt="icon"/>
                  </Col>);
                }
                if (row.original.lastMessage) {
                  toRender.push(<Col lg='6'>{this.renderSensor(row.original.lastMessage.data, row.original.customFields)}</Col>);
                }
                return (<Row>{ toRender }</Row>);
              }}
            /> : '' }
        </div>
      </div>
    );
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (diff(nextState, this.state) ? true : false);
  }


  /** React-table */
  
  getColumns() {
    return [
      { id: 'name', accessor: d => <a href={`#/sensors/detail?deveui=${d.DevEUI}`}>{d.name}</a>, Header: this.t('Name') },
      { accessor: 'floor', Header: this.t('Floor') },
      { Header: this.t('Room'), id: "room", accessor: 'room' }, 
      { id: "lastSeen", 
        maxWidth: 150,
        accessor: d => d.lastSeen ? getDateStr(new Date(d.lastSeen).getTime()) : '',
        Header: this.t('Message.Time') 
      },{
        expander: true,
        Header: () => <strong>{this.t('More')}</strong>,
        width: 65,
        Expander: ({ isExpanded, ...rest }) =>
          <div>
            {isExpanded
              ? <span>&#x2299;</span>
              : <span>&#x2295;</span>}
          </div>,
        style: {
          cursor: "pointer",
          fontSize: 25,
          padding: "0",
          textAlign: "center",
          userSelect: "none"
        }
      }
    ];
  }

}

export default translate(DetailTable);