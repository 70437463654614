import React, { Component } from 'react';
import { setIdToken, setAccessToken, setRole, setAccessRight } from '../../utils/AuthServices';
import { promiseGetRequest } from '../../utils/WebServicesManager';
import to from '../../utils/to';
import Conf from '../../utils/Conf';

class SamlCallback extends Component {
  constructor(props) {
    super(props);
    this.startFinishAuth();
  }
  
  async startFinishAuth() {
    const query = new URLSearchParams(this.props.location.search);

    const token = query.get('token');
    const error = query.get('error');
    if (token) {
      setAccessToken(token);
      setIdToken(token);
    } else {
      alert(error);
      return window.location.replace(Conf.AppURL);
    }
    const [err, result] = await to(promiseGetRequest(`${Conf.BaseApi}getACL`));
    if (err)  return window.close();
    setRole(result.role);
    setAccessRight(result.acl);
    window.location.replace(Conf.AppURL);
  }

  render() {
    return (
      <div>If this window does not close itself in few seconds, then your auth failed</div>
    );
  }
}

export default SamlCallback;
