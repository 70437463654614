import React, { Component } from 'react';
import moment from 'moment';
import $ from 'jquery';
import { TimePicker } from 'react-input-moment';
import Conf from '../../../utils/Conf';
import { getRequest } from '../../../utils/WebServicesManager';
import { ButtonToolbar, ToggleButtonGroup, ToggleButton } from 'react-bootstrap';
import { translate } from '../../../utils/ReactMultiLang';
import SelectableChart from '../../Charts/SelectebaleChart';
import { Col, Row, ModalBody } from 'reactstrap';


class DataSelection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Messages: [],
      FieldList: [],
      SelectedField: 'temperature'
    };
    this.t = props.t;
    this.getFieldList(props.DevEUI);
  }

  getFieldList(DevEUI) {
    if (this.state.FieldList.length !== 0)
      return;
    var url = Conf.BaseApi + `helpers/fields/${DevEUI}`;
    return getRequest(url, (result) => {
      if (result && result.success) {
        const event = {};
        event.target = {};
        event.target.value = 2;
        this.setState({FieldList: result.result, SelectedField: result.result[0]}, () => this.getMessages(DevEUI, event));
      }
    });
  }

  selectField(event) {
      this.setState({SelectedField: event.target.value});
  }

  getMessages(DevEUI, event) {
    const month = event.target.value;
    const today = new Date();
    const since = parseInt((new Date().setDate(today.getDate() - (month * 30)) / 1000), 10);
    var url = Conf.BaseApi + `messages/getSince/${DevEUI}/${since}`;
    return getRequest(url, (result) => {
      if (result && result.success) {
        this.setState({Messages: result.result});
      }
    });
  }

  renderDeviceFields(fields) {
    var toRender = [];
    for (var i = 0; i < fields.length; i++) {
        toRender.push(
          <option value={fields[i]}>{this.t(`SensorData.${fields[i]}`)}</option>
        );
    }
    return toRender;
  }

  render() {
    return ( 
    <ModalBody>
      <div className="flex-row align-items-center">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12">
              <div className="card mx-12">
                <div className="card-block p-12">
                  <h1>{this.t('MachineLearning')}</h1>
                  <p className="text-muted">{this.t('AI.Train')}</p>
                  <div className="input-group mb-3" style={{ color: "red" }} id="error-window">

                  </div>
                  <Row>
                      <Col md='12'>
                    <div className="input-group mb-3">
                    <label htmlFor="dataName" style={{marginRight:'10px'}}>{this.t('AI.DataToTrain')}:{' '}</label>
                  <select className="form-control" id="dataName" defaultValue={this.state.FieldList[0]} onChange={this.selectField.bind(this)}>
                      {this.renderDeviceFields(this.state.FieldList)}
                  </select>
                  </div>
                    <div className="input-group mb-3">
                    <label htmlFor="nbMonth" style={{marginRight:'10px'}}>{this.t('Months')}:</label>
                      <input type='number' defaultValue='2' onChange={this.getMessages.bind(this, this.props.DevEUI)} />
                    </div>
                      </Col>
                  </Row>
                  <Row>
                      <Col md='12'>
                  <SelectableChart paramName={this.state.SelectedField} messages={this.state.Messages} DevEUI={this.props.DevEUI} color='#af006f'/>
                      </Col>
                  </Row>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ModalBody>
    )
  }

}

export default translate(DataSelection);