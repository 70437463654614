import React, { Component } from 'react';
import { ModalBody, Row, Col, Modal, Button } from 'reactstrap';
import { translate } from '../../../../utils/ReactMultiLang';
import { getRequest } from '../../../../utils/WebServicesManager';
import Conf from '../../../../utils/Conf';
import SensorSelector from '../../../Modals/SensorSelector';
import $ from 'jquery';


class AddVirtualFields extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Field: props.field,
      ObjectList: props.field ? props.field.items : [],
      userGroup: props.userGroup,
      Key: 0,
      ActIndex: 0,
      Modal: false,
      FieldList: props.fieldList ? props.fieldList : {},
    }
    if (props.field) {
      this.fetchFieldName(props.field.items);
    }
    this.callback = props.callback;
    this.t = props.t;
  }

  toggle(idx) {
    this.setState({Modal: !this.state.Modal, ActIndex: idx});
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.field !== this.props.field) {
      this.setState({ Field: nextProps.field, ObjectList: nextProps.field ? nextProps.field.items : [] });
      if (nextProps.field) {
        this.fetchFieldName(nextProps.field.items);
      }
    }
  }

  fetchFieldName(fields) {
    for (let i = 0; i < fields.length; i += 1) {
      if (fields[i].sensor) {
        var url = Conf.BaseApi + `devices/getById/${fields[i].sensor}`;
         getRequest(url, (resDevice) => {
          if (resDevice && resDevice.success) {
            var url = Conf.BaseApi + `helpers/fields/${resDevice.result.DevEUI}`;
            return getRequest(url, (result) => {
              if (result && result.success) {
                const fList = this.state.FieldList;
                let objList = this.state.ObjectList;
                objList[i].sName = resDevice.result.name;
                objList[i].itemType = 1;
                fList[i] = result.result;
                this.setState({ FieldList: fList, ObjectList: objList });
              }
            });
          }
        });
      }
      else {
        let objList = this.state.ObjectList;
        objList[i].itemType = 0;
        this.setState({ ObjectList: objList });
      }
    }
  }

  editField(callback) {
    const field = {};
    field.name = $('#FieldName').val();
    field.unit = $('#FieldUnit').val();
    field.items = this.state.ObjectList;
    callback(field, this.props.index);
  }

  typeChange(index, itemType) {
    let objList = this.state.ObjectList;

    if (objList && objList[index]) {
      objList[index].itemType = itemType ? 1 : 0;
      this.setState({ ObjectList: objList, Key: this.state.Key + 1 });
    }
  }
  changeOprator(index, operator) {
    let objList = this.state.ObjectList;

    if (objList && objList[index]) {
      objList[index].operator = operator;
      this.setState({ ObjectList: objList });
    }
  }
  changeField(index, field) {
    let objList = this.state.ObjectList;

    if (objList && objList[index]) {
      objList[index].fieldName = field;
      this.setState({ ObjectList: objList });
    }
  }
  sensorChange(index, sensors) {
    let objList = this.state.ObjectList;

    if (sensors.length > 0) {
      if (objList && objList[index]) {
        objList[index].sensor = sensors[0]._id;
        objList[index].sName = sensors[0].name;
        objList[index].value = null;
      }
      const { DevEUI } = sensors[0];
      var url = Conf.BaseApi + `helpers/fields/${DevEUI}`;
      return getRequest(url, (result) => {
        if (result && result.success) {
          const fList = this.state.FieldList;
          fList[index] = result.result;
          objList[index].fieldName = result.result[0];
          this.setState({ FieldList: fList, ObjectList: objList, value: null, Modal: false });
        }
      });
  }
  }
  valueChange(index, value) {
    let objList = this.state.ObjectList;

    if (objList && objList[index]) {
      objList[index].value = value;
      objList[index].sensor = null;
      this.setState({ ObjectList: objList });
    }
  }

  addObject() {
    let objList = this.state.ObjectList;
    objList.push({ itemType: 0, operator: 0, value: null, sensor: null });
    this.setState({ ObjectList: objList, Key: this.state.Key + 1 });
  }

  rmObject(index) {
    let objList = this.state.ObjectList;

    if (objList) {
      objList.splice(index, 1);
      this.setState({ ObjectList: objList, Key: this.state.Key + 1 });
    }
  }

  render() {
    const { callback } = this.props;
    return (
      <ModalBody>
        <div className="flex-row align-items-center">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-12">
                <div className="card mx-12">
                  <div className="card-block p-12">
                    <div className="input-group mb-3" style={{ color: "red" }} id="error-window">

                    </div>
                    <Row>
                      <Col md={12}>
                        <div className="form-group mb-3">
                          <input type="text" className="form-control small"
                            placeholder="Name" id={`FieldName`} defaultValue={this.state.Field ? this.state.Field.name : ''} />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <div className="form-group mb-3">
                          <input type="text" className="form-control small"
                            placeholder="Unit" id={`FieldUnit`} defaultValue={this.state.Field ? this.state.Field.unit : ''} />
                        </div>
                      </Col>
                    </Row>
                    <div key={this.state.Key}>
                      {this.state.ObjectList ?
                        this.state.ObjectList.map((t, idx) => {
                          const toRender = [];
                          toRender.push(<Col md={12} key={idx+'hr'}><hr/></Col>);
                          toRender.push(
                            <Col md={6} key={idx+'1'}>
                              Sensor
                              <span style={{marginLeft:'5px'}}>
                                
                                <label className="switch switch-text switch-pill switch-danger switch-alert">
                                  { this.state.ObjectList[idx].itemType !== 0 ? 
                                  <input type="checkbox" className="switch-input" onChange={(evt) => this.typeChange(idx, !evt.target.checked)} /> :
                                  <input type="checkbox" className="switch-input" onChange={(evt) => this.typeChange(idx, !evt.target.checked)} defaultChecked/>}
                                  <span className="switch-label" data-on="" data-off=""></span>
                                  <span className="switch-handle" ></span>
                                </label></span>
                                <span style={{marginLeft:'55px'}}>Value</span>
                            </Col>);
                          toRender.push(
                            <Col md={6} key={idx+'2'}>
                              <div className="form-group mb-3">
                                <select className="form-control" id="userGroup" onChange={(evt) => this.changeOprator(idx, evt.target.value)} defaultValue={this.state.ObjectList[idx].operator}>
                                  <option value={0}>+ {this.t('Add')}</option>
                                  <option value={1}>- {this.t('Substract')}</option>
                                  <option value={2}>x {this.t('Multiply')}</option>
                                  <option value={3}>/ {this.t('Divide')}</option>
                                  <option value={4}>% {this.t('Modulo')}</option>
                                  <option value={5}>{this.t('Average')}</option>
                                </select>
                              </div>
                            </Col>);
                          if (this.state.ObjectList[idx].itemType === 0) {
                            toRender.push(<Col md={12} key={idx+'3'}>
                              <div className="input-group mb-3">
                                <input type="number" className="form-control small"
                                  onChange={(evt) => this.valueChange(idx, evt.target.value)}
                                  placeholder="Value" id={`Value${idx}`} defaultValue={this.state.ObjectList[idx] ? this.state.ObjectList[idx].value : 0} />
                              </div></Col>);
                          } else {
                            toRender.push(<Col md={12} key={idx+'4'}>
                              <b>{this.state.ObjectList[idx].sensor ? this.state.ObjectList[idx].sName : this.t(`Sensor.NoSensor`)}{' '}</b>
                              <Button className="btn-secondary" onClick={() => this.toggle(idx)}>{this.t(`Sensor.Change`)}</Button>
                              </Col>);
                            toRender.push(<Col md={12} key={idx+'5'}>
                              <div className="form-group mb-3">
                                <div><label htmlFor="createCpDataName">{this.t('Component.DataToUse')}</label></div>

                                <select className="form-control" id="createCpDataName" onChange={(evt) => this.changeField(idx, evt.target.value)}>
                                  {this.renderDeviceFields(this.state.FieldList[idx], this.state.ObjectList[idx].fieldName)}
                                </select>
                              </div>
                              </Col>);
                          }
                          return (<Row key={idx+'6'}>
                            {toRender}
                              <img src='./img/minus.png'
                                width='25px'
                                onClick={this.rmObject.bind(this, idx)} 
                                style={{position: "absolute", marginLeft: '400px', marginTop: '50px'}}/>
                          </Row>);
                        }) : ''}
                    </div>
                    <div className="input-group mb-3">
                      <img src='./img/add.png' width='25px' onClick={this.addObject.bind(this)} />
                    </div>
                    <button type="button" className="btn btn-block btn-success" onClick={this.editField.bind(this, callback)}>{this.t('Update')}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal isOpen={this.state.Modal} className={'modal-lg ' + this.props.className}>
           <SensorSelector callbackAddSensor={this.sensorChange.bind(this, this.state.ActIndex)} />
        </Modal>
      </ModalBody>
    )
  }

  renderDeviceFields(fields, param) {
    var toRender = [];
    if (fields) {
      for (var i = 0; i < fields.length; i++) {
        if (param === fields[i]) {
          toRender.push(
            <option key={fields[i]} value={fields[i]} selected>{this.t(`SensorData.${fields[i]}`)}</option>
          );
        } else {
          toRender.push(
            <option key={fields[i]} value={fields[i]}>{this.t(`SensorData.${fields[i]}`)}</option>
          );
        }
      }
    }
    return toRender;
  }
}

export default translate(AddVirtualFields);