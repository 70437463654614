import React, { Component } from 'react';
import ReactDOM from "react-dom";
import { MapControl, withLeaflet } from "react-leaflet";
import { Control, DomUtil, DomEvent } from "leaflet";

const DumbControl = Control.extend({
  options: {
    className: "",
    onOff: "",
    handleOff: function noop() {}
  },

  onAdd(/* map */) {
    var _controlDiv = DomUtil.create("div", this.options.className);
    DomEvent.disableClickPropagation(_controlDiv);
    return _controlDiv;
  },

  onRemove(map) {
    if (this.options.onOff) {
      map.off(this.options.onOff, this.options.handleOff, this);
    }

    return this;
  }
});

class CustomControl extends Component {

  constructor(someProp){
    super()
    this.state = {children: someProp.children}
  }

      createLeafletElement() {};

    componentWillReceiveProps(someProp) {
      this.setState({children:someProp.children})
    }

    render() { return (
      <div {...this.props}>
        {this.state.children}
      </div>);
    }
}

export default withLeaflet(CustomControl);