import * as SRD from "storm-react-diagrams";
import AlarmNodeWidget from "./AlarmNodeWidget";
import AlarmNodeModel from "./AlarmNodeModel";
import * as React from "react";

export class AlarmNodeFactory extends SRD.AbstractNodeFactory {
	constructor() {
		super("alarm");
	}

	generateReactWidget(diagramEngine, node) {
		return <AlarmNodeWidget node={node} diagramEngine={diagramEngine}/>;
	}

	getNewInstance() {
		return new AlarmNodeModel();
	}
}

export default AlarmNodeFactory;