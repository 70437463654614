import React, { Component } from 'react';
import Conf from '../../../utils/Conf';
import $ from 'jquery';
import { getRequest, postRequest } from '../../../utils/WebServicesManager';
import { Line } from 'react-chartjs-2';
import { Min, Max } from '../../../utils/Utils';
import TemperatureChart from './TemperatureChart';
import PredictionsChart from '../../../components/Charts/PredictionsCharts';
import Messages from './Messages';
import AverageData from './AverageData';
import Power from './Power';
import AverageBoolean from './AverageBoolean';
import {CSVLink} from 'react-csv';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { translate } from '../../../utils/ReactMultiLang';

class Statistics extends Component {
  constructor(props) {
    super(props);
    this.state = { Devices: [], 
      Messages: [],
      SensorType: null,
      downloadCSV: [],
      Modal: false,
      SensorSerial: '',
      Preditcions: undefined
    };
    this.getDevicesList();
    this.t = props.t;
  }

  toggle() {
    this.setState({
      Modal: !this.state.Modal
    });
  }

  getDevicesList() {
    var url = Conf.BaseApi + 'devices/get';
    return getRequest(url, (data) => {
      if (data.success)
        this.setState({ Devices: data.result });
    });
  }

  getMessagesList(event) {
    var sensorData = event.target.value.split("|");
    if (sensorData.length < 2)
      return ;
    var url = Conf.BaseApi + 'messages/get/' + sensorData[0];
    if (sensorData[1].indexOf("TEMPERATURE") > -1)
      url = Conf.BaseApi + 'messages/get/50/' + sensorData[0];
    getRequest(url, (body) => {
      if (body.success) {
        const messages = body.result;
        messages.sort((a, b) => {
          if (a.time > b.time)
            return 1;
          if (a.time < b.time)
            return -1;
          return 0;
        });
        this.setState({ Messages: messages, SensorType: sensorData[1].split(','), SensorSerial:sensorData[0] });
      }
    });
    url = Conf.BaseApi + 'predictions/get/' + sensorData[0];
    return getRequest(url, (body) => {
      if (body.success) {
        const predictions = body.result;
        this.setState({ Preditcions: predictions });
      }
    });
  }

  renderDevicesList() {
    var toRender = [];
    for (var i = 0; i < this.state.Devices.length; i++) {
        toRender.push(
          <option value={this.state.Devices[i].DevEUI + "|" + this.state.Devices[i].type} key={this.state.Devices[i].DevEUI}>{this.state.Devices[i].name} : {this.state.Devices[i].type.join(' - ')}</option>);
    }
    return toRender;
  }

  changeSensor(id) {
    if (id && id != undefined && this.state.SensorSerial !== '') {
      var url = Conf.BaseApi + 'messages/get/' + id + '/' + this.state.SensorSerial;
      return getRequest(url, (body) => {
        if (body.success) {
          const messages = body.result;
          messages.sort((a, b) => {
            if (a.time > b.time)
              return 1;
            if (a.time < b.time)
              return -1;
            return 0;
          });
          this.setState({ Messages: messages });
        }
      });
    }
  }

  renderTemperatureStatistics() {
      
    var toRender = [];
    var allPropertyNames = Object.keys(this.state.Messages[0].data);
    var colors = ["card-primary", "card-success", "card-warning", "card-danger"];
    var j = 0;
    for (var i = 0; i < allPropertyNames.length; i++) {
      if (this.state.Messages[0].data[allPropertyNames[i]] > 1) {
        toRender.push(
          <div className="col-lg-9">
            <div className="row">
              <div className="col-lg-12">
                <TemperatureChart messages={this.state.Messages}
                                  paramName={allPropertyNames[i]} 
                                  subtitle={allPropertyNames[i]} 
                                  color={colors[j % 4]}
                                  changeSensor={this.changeSensor.bind(this)}
                                  sensorList={[{DevEUI:20, name:this.t('Sensor.TwentyLast')}, 
                                        {DevEUI:60, name:this.t('Sensor.SixtyLast')}, 
                                        {DevEUI:200, name:this.t('Sensor.TwoHundredLast')},
                                        {DevEUI:1000, name:this.t('Sensor.ThousandLast')}]}
                                        noButton={true}/>
              </div>
            </div>
          </div>
        );
        j++;
      }
    }
    return toRender;
/*
          <div className="col-lg-8">
            <div className="row">
              <div className="col-lg-6">
                <Messages messages={this.state.Messages} />
              </div>
              <div className="col-lg-6">
              <AverageData messages={this.state.Messages} paramName="temperature" color="card-success" maxData="45" title="Température moyenne" dataSigle="°C" icon="icon-umbrella"/>

              </div>
            </div>
          </div>*/
  }
renderMagneticStatistics() {
    return (
          <div className="col-lg-8">
            <div className="row">
              <div className="col-lg-2">
                <Power messages={this.state.Messages}/>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <AverageBoolean messages={this.state.Messages} paramName="open_count" label={this.t('SensorData.open_count')}/>
              </div>
            </div>
          </div>
    );
  }
renderMotionStatistics() {
    return (
          <div className="col-lg-8">
            <div className="row">
              <div className="col-lg-2">
                <Power messages={this.state.Messages}/>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <AverageBoolean messages={this.state.Messages} paramName="intrusion_count" label={this.t('SensorData.motion')} />
              </div>
            </div>
          </div>
    );
  }
  getStatistics() {
    if (this.state.SensorType == null)
      return [];
    const render = [];

    if (this.state.SensorType.includes("TEMPERATURE")) {
      render.push(this.renderTemperatureStatistics());
    }
    if (this.state.SensorType.includes("MAGNETIC")) {
      render.push(this.renderMagneticStatistics());
    }
    if (this.state.SensorType.includes("INFRARED")) {
      render.push(this.renderMotionStatistics());
    }
    return render;
  }

  exportData() {
    var url = Conf.BaseApi + 'devices/export/datas';
    var selectedSensor = $("#sensorSelect").val();
    var sensorData = selectedSensor.split("|");
    var options = {sensorEUI: sensorData[0]};
    return postRequest(url, options, (data) => {
      let csv = [];
      csv.push(
          <CSVLink data={data}
            filename={sensorData[0] + ".csv"}
            className="btn btn-primary"
            target="_blank">
               {this.t('DownloadData')}
          </CSVLink>);
      this.setState({downloadCSV: csv, Modal: true});
    });
  }

  render() {
    return (
      <div className="animated fadeIn">
        <div className="row">
          <div className="col-lg-4">
            <div className="card">
              <div className="card-header">
                <i className="fa fa-align-justify"></i> {this.t('Sensor.Choose')}
              </div>
              <div className="card-block">
                <select className="form-control" onChange={this.getMessagesList.bind(this)} id="sensorSelect">
                   <option value="">-- {this.t('Sensor.Choose')} --</option>

                  {this.renderDevicesList()}
                </select>
                <br/>
                <button type="button" className="btn btn-block btn-success" onClick={this.exportData.bind(this)}>{this.t('ExportData')}</button>
              </div>
            </div>
          </div>
            {this.getStatistics()}
        </div>
        
        <Modal isOpen={this.state.Modal} toggle={this.toggle.bind(this)} className={'modal-md ' + this.props.className}>
        <ModalHeader>
        <button onClick={this.toggle.bind(this)}  className="btn btn-block btn-danger right" >{this.t('')}</button>
      </ModalHeader>
        <ModalBody>
          {this.state.downloadCSV}
        </ModalBody>
      </Modal>
      </div>
    )
  }
}

export default translate(Statistics);
