import React, { Component } from 'react';
import Conf from '../../../utils/Conf';
import $ from 'jquery';
import { getRequest, postRequest } from '../../../utils/WebServicesManager';
import { Chart, Line } from 'react-chartjs-2';
import { Min, Max } from '../../../utils/Utils';

class LineChats extends Component {
    constructor(props) {
        super(props);
        this.datas = {};
        this.options = {};
        this.selectedData = []; 
        this.state = {
            width:300,
            height:300
        };
        this.ChartKey = 0;
        this.finalData = [];
        this.erroredData = [];
        this.messages = [];
        this.eventDefined = false;
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.messages.length !== this.props.messages.length) {
            this.ChartKey += 1;
            this.messages = nextProps.messages;
            this.eventDefined = false;
            this.getMessagesList(this.messages, nextProps.paramName);
            this.updateDimensions();
            this.erroredData = [];
        }
      }
  

    updateDimensions(){
        if (this.refs.mainDiv.offsetWidth + 10 < this.state.width
            || this.refs.mainDiv.offsetWidth - 10 > this.state.width
            || this.refs.mainDiv.offsetHeight - 50 !== this.state.height) {
            let width = this.refs.mainDiv.offsetWidth;
            let height= this.refs.mainDiv.offsetHeight - 50;
            this.setState({width, height});
            this.ChartKey += 1;
        }
      }
    getMessagesList(messages, paramName) {
            const datas = [];
            let lastTime = 0;
            const labels = [];
            for (var i = 0; i < messages.length; i++) {
                if (!this.erroredData.includes(i)) {
                var d = new Date(messages[i].time * 1000);
                var tmplabel = d.getDate() + '/' + ((d.getMonth() + 1 > 9) ? d.getMonth() + 1 : '0' + (d.getMonth() + 1)) + ' ' + d.getHours() + 'h' + ((d.getMinutes() > 9) ? d.getMinutes() : '0' + d.getMinutes() );
                labels.push(tmplabel);
                datas.push(Math.trunc(parseFloat(messages[i].data[paramName]) * 10) / 10);
                lastTime = messages[i].time;
                }
            }
        this.datas = {
            datasets: [
            ]
        };
            this.datas.labels = labels;
            this.datas.datasets.push({
                    label: paramName,
                    fill: false,
                    lineTension: 0.2,
                    backgroundColor: this.props.color,
                    borderColor: this.props.color,
                    borderCapStyle: 'butt',
                    borderDash: [],
                    borderDashOffset: 0.0,
                    borderJoinStyle: 'miter',
                    pointBorderColor: 'rgba(190,190,190,1)',
                    pointBackgroundColor: '#fff',
                    pointBorderWidth: 0,
                    pointHoverRadius: 5,
                    pointHoverBackgroundColor: this.props.color,
                    pointHoverBorderColor: 'rgba(75,75,75,0)',
                    pointHoverBorderWidth: 1,
                    pointRadius: 1,
                    pointHitRadius: 10,
                    data: datas
            });
            const min = Min(datas);
            const max = Max(datas);
        const step =  (max - min) / 10;
        this.options = {
            layout: {
                padding: {
                    left: 20,
                    right: 20,
                    top: 20,
                    bottom: 20
                }
            },
            animation: {
                duration: 750,
                easing: 'easeInQuad'
            },tooltips: {
                display: false
            },
            legend: {
                display: false
            },
            scales: {
                yAxes: [{
                    ticks: {
                        min: min - step,
                        max: max + step,
                        stepSize: step
                    }
                }]
            },
            maintainAspectRatio: false
        };
    }

    componentDidMount() {
        Chart.pluginService.register({
            afterDraw: this.afterDraw.bind(this)
        });
    }

    afterDraw(chart, easing) {
        var overlay = document.getElementById('overlay');
        if (overlay && overlay !== undefined) {
            this.canvas = $('#overlay').parent().find('canvas:nth-child(3)')[0];
            if (this.canvas) {
                overlay.width = this.canvas.width;
                overlay.height = this.canvas.height;
                this.chart = chart;
                this.startIndex = 0;
                this.selectionContext = overlay.getContext('2d');
                this.selectionRect = {
                    w: 0,
                    startX: 0,
                    startY: 0
                };
                this.drag = false;
                this.isPointerDown = false;
                if (!this.eventDefined) {
                    this.canvas.addEventListener('pointerdown', this.pointerDown.bind(this));
                    this.canvas.addEventListener('pointermove', this.pointerMove.bind(this));
                    this.canvas.addEventListener('pointerup', this.pointerUp.bind(this));
                    this.eventDefined = true;
                }
            }
        }
    }
    pointerDown(evt) {
        const points = this.chart.getElementsAtEventForMode(evt, 'index', {
            intersect: false
        });
        if (points && points.length > 0) {
            this.isPointerDown = true;
            this.startIndex = points[0]._index;
            const rect = this.canvas.getBoundingClientRect();
            this.selectionRect.startX = evt.clientX - rect.left;
            this.selectionRect.startY = this.chart.chartArea.top;
            this.drag = true;
        }
    }

    pointerMove(evt) {
        const rect = this.canvas.getBoundingClientRect();
        if (this.drag) {
          const rect = this.canvas.getBoundingClientRect();
          this.selectionRect.w = (evt.clientX - rect.left) - this.selectionRect.startX;
          this.selectionContext.globalAlpha = 0.5;
          this.selectionContext.clearRect(0, 0, this.canvas.width, this.canvas.height);
          this.selectionContext.fillRect(this.selectionRect.startX,
            this.selectionRect.startY,
            this.selectionRect.w,
            this.chart.chartArea.bottom - this.chart.chartArea.top);
        } else {
            this.selectionContext.clearRect(0, 0, this.canvas.width, this.canvas.height);
          var x = evt.clientX - rect.left;
          if (x > this.chart.chartArea.left) {
            this.selectionContext.fillRect(x,
                this.chart.chartArea.top,
              1,
              this.chart.chartArea.bottom - this.chart.chartArea.top);
          }
        }
    }

    pointerUp(evt) {
        if (this.isPointerDown) {
            const points = this.chart.getElementsAtEventForMode(evt, 'index', {
            intersect: false
          });
          this.drag = false;
          let hasChange = false; 
          const start = this.startIndex;
          let end = points[0]._index;
          const tmpError = [];
          while(end >= start) {
              tmpError.push(this.messages[start]);
            this.messages.splice(start, 1);
            end -= 1;
            hasChange = true;
          }
          if (hasChange) {
              this.erroredData.push(tmpError);
              this.getMessagesList(this.messages, this.props.paramName);
              this.forceUpdate();
          }
          this.isPointerDown = false;
        }
    }

    sendHandPickedDatas() {
        const form = {
            messages: JSON.stringify(this.messages),
            erroredDatas: JSON.stringify(this.erroredData),
            DevEUI: this.props.DevEUI,
            dataName: this.props.paramName
        }
        var url = Conf.BaseApi + 'predictions/trainData';
        return postRequest(url, form, (data) => {
          if (data.success || data.success == true) {
            window.location.reload();
          } else {
            $("#error-window").text(data.message);
          }
        });
    }

render() {

    const chartStyle = {
        width: this.state.width,
        height: this.state.height
      };
    return (
            <div ref='mainDiv' style={{height:'100%', minHeight:'400px'}}>
                <div className="chart-wrapper px-3"
                        style={chartStyle}>
                        <canvas id="overlay" width="600" height="400" style={{position:'absolute', pointerEvents:'none'}}></canvas>
  
                        <Line
                        id='lineChartSlectable'
                        data={this.datas}
	                    key={this.ChartKey}
                        options={this.options}
                        />
                </div>
                <button className="btn btn-primary btn-lg" onClick={this.sendHandPickedDatas.bind(this)}>Send</button>
            </div>
    )
}
}

export default LineChats;
