import React, { Component } from 'react';
import { Map, TileLayer, Marker, Circle } from 'react-leaflet';
import { Row, Col } from 'reactstrap';
import { translate } from '../../../utils/ReactMultiLang';
import { ButtonToolbar, ToggleButtonGroup, ToggleButton } from 'react-bootstrap';
import { sensorMarker, generateSensorCircle } from '../../../views/Buildings/Manage/Map/Marker/Marker';

class MapSelector extends Component {
    constructor(props) {
      super(props);
        this.state = {
            latlng: [46.200074, 6.143137],
            zoom: 12,
            collapsed: false,
            Event: undefined,
            SelectedPos: undefined,
            Range: 0.5
        }
        this.t = props.t;
    }

    componentWillReceiveProps(nextProps) { }

    mapHandleClick = (e) => {
    }

    onClose() {
        this.setState({ collapsed: true });
    }

    onOpen(id) {
        this.setState({
            collapsed: false,
            selected: id,
        })
    }

    onRangeChange(evt) {
      this.setState({Range: evt.target.value});
    }

    selectLocation(evt) {
      const SelectedPos = evt.latlng;
      if (this.props.updateGPS) this.props.updateGPS([SelectedPos.lat, SelectedPos.lng]);
      this.setState({SelectedPos});
    }

    render() {
        const { defaultRange, isExit } = this.props;

        return (
            <Row>
              <Col md='6'>
                <Map
                    style={{height: "300px"}}
                    className="sidebar-map"
                    center={this.state.latlng}
                    zoom={this.state.zoom}
                    onclick={this.mapHandleClick.bind(this)}
                    ondblclick={this.selectLocation.bind(this)}
                    doubleClickZoom={false}
                    ref={this.myMap}>
                    
                    <TileLayer
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    {this.state.SelectedPos ?
                      <Marker zIndexOffset={1302}
                      icon={sensorMarker}
                      position={this.state.SelectedPos} />
                      : ""}
                      {this.state.SelectedPos ?
                      <Circle color={'#FF0042'} center={this.state.SelectedPos} radius={this.state.Range * 1000} opacity='0.50'/>
                    : ""}
                </Map>
              </Col>
              <Col md='6'>
                      <br/>
                      <br/>
                <div className="input-group mb-6">
                        <span className="input-group-addon"><i className="icon-speedometer"></i></span>
                        <input type="number" step="0.5" className="form-control" placeholder={this.t('Range (Km)')} id="alarmValue" onChange={(evt) => this.onRangeChange(evt)}
                          defaultValue={defaultRange ? defaultRange : ''} />
                      </div>
                      <br/>
                      <br/>
                      <ButtonToolbar>
                        <ToggleButtonGroup type="radio" name="isUnder" id="isUnder" defaultValue={isExit ? '0' : '1'}>
                          <ToggleButton value="1" bsStyle="success" onChange={this.props.enterExit.bind(this)}>{this.t('Enter')}</ToggleButton>
                          <ToggleButton value="0" bsStyle="success" onChange={this.props.enterExit.bind(this)}>{this.t('Exit')}</ToggleButton>
                        </ToggleButtonGroup>
                      </ButtonToolbar>
              </Col>
            </Row>
        )
    }
    
}

export default translate(MapSelector);
