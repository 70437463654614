import React, { Component } from 'react';
import { translate } from '../../../../../utils/ReactMultiLang';
import SensorDraggable from '../Sensor';
import Place from '../Place';
import { relative } from 'path';
import sizeOf from 'image-size';
import { check } from '../../../../../utils/Can';
import Conf from '../../../../../utils/Conf';
import { getRequest, } from '../../../../../utils/WebServicesManager';


class Dropzone extends Component {
  constructor(props) {
    super(props); 
    this.state = {
      img: props.img,
      Width: 500,
      Height: 500,
      Sensors: [],
      Assets: [],
      locatedSensors: props.locatedSensors,
      rooms: props.rooms,
      CanEdit: check('infra:edit'),
      floor: props.floor,
      usePlaces: props.usePlaces ? true : false
    }
    this.dragOver = this.dragOver.bind(this);
    this.drop = this.drop.bind(this);
    this.addSensor = props.addSensor;
    this.updatePosition = props.updatePosition;
    this.removeSensor = props.removeFromPlan;
    this.img = new Image();
    const _self = this;
    this.img.onload = function() {
      _self.setState({Width: this.width, Height: this.height});
    }
    this.img.src = this.state.img;
    if (props.usePlaces) {
      this.getPlaces();
    } else {
      this.getDevices();
    }
  }

  removeFromPlan(sensor) {
    this.removeSensor(sensor);
  }

  getDevices() {
    if (this.state.floor) {
      let url = Conf.BaseApi + `devices/floor/${this.state.floor._id}`;
      getRequest(url, (data) => {
        if (data.success) {
          const sensors = data.result;
          this.setState({ Sensors: sensors });
        } else {
          this.setState({ Sensors: [] });
        }
      });
    }
  }
  getPlaces() {
    if (this.state.floor) {
      let url = Conf.BaseApi + `assets/get/floor/${this.state.floor._id}/Places`;
      getRequest(url, (data) => {
        if (data.success) {
          const assets = data.result;
          this.setState({ Assets: assets });
        } else {
          this.setState({ Assets: [] });
        }
      });
    }
  }


  componentWillReceiveProps(nextProps) {
    if (this.props.img !== nextProps.img) {
      this.img = new Image();
      const _self = this;
      this.img.onload = function() {
        _self.setState({img: nextProps.img, Width: this.width, Height: this.height});
      }
      this.img.src = nextProps.img;
    }
    if (this.props.sensors !== nextProps.sensors) {
      this.setState({sensors: nextProps.sensors});
    }
    if (this.props.locatedSensors !== nextProps.locatedSensors) {
      this.setState({locatedSensors: nextProps.locatedSensors});
    }
    if (this.props.rooms !== nextProps.rooms) {
      this.setState({rooms: nextProps.rooms});
    }
    if (this.props.floor !== nextProps.floor) {
      this.setState({floor: nextProps.floor}, this.getDevices.bind(this));
    }
    if (this.props.usePlaces !== nextProps.usePlaces) {
      this.setState({usePlaces: nextProps.usePlaces ? true : false, Sensors: [], Assets: []}, nextProps.usePlaces ? this.getPlaces.bind(this) : this.getDevices.bind(this));
    }
  }

  dragOver(ev) {
    ev.preventDefault();
    if (this.state.CanEdit) ev.dataTransfer.dropEffect = "move" 
    else ev.dataTransfer.dropEffect = "none" 
  }
  drop(ev) {
    ev.preventDefault();
    if (this.state.CanEdit) {
      var assetId = ev.dataTransfer.getData("application/assetId");
      var assetType = ev.dataTransfer.getData("application/assetType");
      let movedSensor = document.getElementById(`BTN_${assetId}`);
      var droppable = document.getElementById('targetDroppable');
      //Gets the position of the two elements relative to the viewport
      var droppableBoundingRect = droppable.getBoundingClientRect();
      //Establishes the percentage using the droppable height and width
      var draggableXPercentage = ((ev.clientX - droppableBoundingRect.left - 18) / droppable.clientWidth) * 100;
      var draggableYPercentage = ((ev.clientY - droppableBoundingRect.top - 18) / droppable.clientHeight) * 100;
      
      this.updatePosition(assetType, assetId, [draggableXPercentage, draggableYPercentage]);

      movedSensor.style.position = "absolute";
      movedSensor.style.top = draggableYPercentage + "%";
      movedSensor.style.left = draggableXPercentage + "%";
    }
  }

  generateSensors(sensors, sensorToPlace) {
    const toRender = [];
    if (sensorToPlace) {
      for (let i = 0; i < sensorToPlace.length; i += 1) {
        const sensor = sensors.find(s => sensorToPlace[i].sensor ? s._id === sensorToPlace[i].sensor._id : false);
        if (sensor) {
          const style = {
            zIndex: 20,
            position: "absolute",
            left: `${parseInt(sensorToPlace[i].position[0])}%`,
            top: `${parseInt(sensorToPlace[i].position[1])}%`
          }
          toRender.push(<SensorDraggable sensor={sensor} key={sensor._id} style={style} removeFromPlan={this.removeFromPlan.bind(this)} CanEdit={this.state.CanEdit}/>);
        }
      }
    }
    return toRender;
  }
  generateRooms(sensors, rooms) {
    const toRender = [];
    if (rooms) {
      for (let i = 0; i < rooms.length; i += 1) {
        const sensor = sensors.filter(s => rooms[i].room && rooms[i].room.sensors ? rooms[i].room.sensors.includes(s._id) : false);
        
        if (sensor && sensor.length > 0) {
          const xy = this.getXY(rooms[i], sensor.length);
          const nbLine = Math.round(Math.sqrt(sensor.length));
          const nbCol = Math.floor(sensor.length / nbLine) + 1;
          for (let j = 0; j < sensor.length; j += 1) {
            let style = {
              zIndex: 20,
              position: "absolute",
              left: `${xy[0] + (40 * (j % nbCol))}px`,
              top: `${xy[1] + (40 * Math.floor(j / nbCol))}px`
            }
            if (sensor[j].planPosition && sensor[j].planPosition.length > 0) {
              style = {
                zIndex: 20,
                position: "absolute",
                left: `${parseInt(sensor[j].planPosition[0])}%`,
                top: `${parseInt(sensor[j].planPosition[1])}%`
              }
            }
            toRender.push(<SensorDraggable sensor={sensor[j]} key={sensor[j]._id + rooms[i]._id} style={style} removeFromPlan={this.removeFromPlan.bind(this)} CanEdit={this.state.CanEdit}/>);
          }
        }
      }
    }
    return toRender;
  }
  generateAssets(assets, rooms) {
    const toRender = [];
    if (rooms) {
      for (let i = 0; i < rooms.length; i += 1) {
        const asset = assets.filter(a => rooms[i].room && rooms[i].room.assets ? rooms[i].room.assets.includes(a._id) : false);
        
        if (asset && asset.length > 0) {
          const xy = this.getXY(rooms[i], asset.length);
          const nbLine = Math.round(Math.sqrt(asset.length));
          const nbCol = Math.floor(asset.length / nbLine) + 1;
          for (let j = 0; j < asset.length; j += 1) {
            let style = {
              zIndex: 20,
              position: "absolute",
              left: `${xy[0] + (40 * (j % nbCol))}px`,
              top: `${xy[1] + (40 * Math.floor(j / nbCol))}px`
            }
            if (asset[j].planPosition && asset[j].planPosition.length > 0) {
              style = {
                zIndex: 20,
                position: "absolute",
                left: `${parseInt(asset[j].planPosition[0])}%`,
                top: `${parseInt(asset[j].planPosition[1])}%`
              }
            }
            toRender.push(<Place asset={asset[j]} key={asset[j]._id + rooms[i]._id} style={style} CanEdit={this.state.CanEdit}/>);
          }
        }
      }
    }
    return toRender;
  }

  getXY(room, nbSensor) {
    const width = room.position[2] - room.position[0];
    const height = room.position[7] - room.position[1];
    const marginX = room.position[0];
    const marginY = room.position[1];
    const centerX = marginX + (width / 2);
    const centerY = marginY + (height / 2);
    const nbLine = Math.round(Math.sqrt(nbSensor));
    const nbCol = Math.floor(nbSensor / nbLine) + 1;
    
    const rectWidth = nbCol * 20;
    const rectHeight = nbLine * 20;

    return [centerX - rectWidth, centerY - rectHeight];
  }

  render() {  //planContainer
    const { img } = this.state;
    const style = {
      width: `${this.state.Width}px`,
      maxWidth: `100%`,
      height: `${this.state.Height}px`,
      position: "relative",
      backgroundImage: `url(${img})`,
      backgroundSize: "contain",
      backgroundRepeat: "no-repeat",
      margin: "auto",
      zIndex: '0'
    }

	return (
		<div id="targetDroppable" onDrop={this.drop} onDragOver={this.dragOver} style={style}>
      {this.generateSensors(this.state.Sensors, this.state.locatedSensors)}
      {this.generateRooms(this.state.Sensors, this.state.rooms)}
      {this.generateAssets(this.state.Assets, this.state.rooms)}
      {this.props.children}
		</div>
    )
  }
}

export default translate(Dropzone);