import React, { Component } from 'react';
import $ from 'jquery';
import Conf from '../../utils/Conf';
import { notLoggedPostRequest } from '../../utils/WebServicesManager';
import { setDashToken, setReportToken } from '../../utils/AuthServices';
import { translate } from '../../utils/ReactMultiLang';

class PublicLogger extends Component {
  constructor(props) {
    super(props);
    this.t = props.t;
  }

  login() {
    if ($('#resourceType').val() == 0) {
      this.loginReport();
    } else {
      this.loginDash();
    }
  }

  loginReport() {
    var url = Conf.PublicReportURL;
    return notLoggedPostRequest(url, { id: $("#idField").val(), password: $("#password").val() }, (data) => {
      if (data.success || data.success == true) {
        setReportToken(data.token);
        window.location.href = "#/public/report";
      } else {
        $("#error-login").text(data.message);
      }
    });
  }

  loginDash() {
    var url = Conf.PublicDashURL;
    return notLoggedPostRequest(url, { id: $("#idField").val(), password: $("#password").val() }, (data) => {
      if (data.success || data.success == true) {
        setDashToken(data.token);
        window.location.href = "#/public/buildingDashboard";
      } else {
        $("#error-login").text(data.message);
      }
    });
  }

  handleKeyPress(event) {
    if (event.key === 'Enter') {
      this.login();
    }
  }

  render() {


    return (<div className="neal-page">

      <div className="app flex-row align-items-center">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-8">
              <div className="card-group mb-0">
                <div className="card p-4">
                  <div className="card-block">
                    <h1>Public Access</h1>
                    <p className="text-muted">Send Id and Password to access to a public ressources</p>
                    <div className="input-group mb-3" style={{ color: "red" }} id="error-login">
                    </div>

                  <div className="form-group mb-3">
                    <div><label htmlFor="resourceType">Resource type:</label></div>
                    <select className="form-control" id="resourceType" defaultValue={0}>
                      <option value={0}>Report</option>
                      <option value={1}>Infrastructure Dashboard</option>
                    </select>
                  </div>
                    <div className="input-group mb-3">
                      <span className="input-group-addon"><i className="icon-speedometer"></i></span>
                      <input type="text" className="form-control" placeholder="Id" id="idField" autocomplete="off"/>
                    </div>
                    <div className="input-group mb-4">
                      <span className="input-group-addon"><i className="icon-lock"></i></span>
                      <input type="password" className="form-control" placeholder="Password" id="password" autocomplete="new-password" onKeyPress={this.handleKeyPress.bind(this)} />
                    </div>
                    <div className="row">
                      <div className="col-6">
                        <button type="button" className="btn btn-primary px-4" onClick={this.login.bind(this)}>Access Resource</button>
                      </div>
                    </div>
                        <a href='#/' >Access to login page</a>
                  </div>
                </div>
                <div className="card card-inverse py-5 d-md-down-none" style={{ width: 44 + '%' }}>
                  <div className="card-block text-center">
                    <div>
                      <img src="img/ebeesafe.png" width="200px" style={{ marginTop: '-20px' }} />
                    </div>
                    <div>
                      <img src="img/ebeewan.png" width="350px" style={{ marginTop: '-25px' }} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    )
  }
}
export default translate(PublicLogger);
