import React, { Component } from 'react';
import createClass from 'create-react-class';
import Conf from '../../../utils/Conf';
import Select from 'react-select';
import { getRequest } from '../../../utils/WebServicesManager';
import fetch from 'isomorphic-fetch';


class SelectAlarmModel extends Component {
	constructor(props) {
		super(props);
		this.displayName = props.entityName ? props.entityName : 'alarmModels';
		const opt = [];
		if (props.options !== undefined && props.options.length > 0 && props.options[0] !== undefined)  {
			for (let i = 0; i < props.options.length; i += 1) {
				if (props.options[i] !== undefined) {
					opt.push({value: props.options[i]._id, label: props.options[i].name});
				}
			}
		}
		this.state = {
			multiValue: null,
			options: opt,
			backspaceRemoves: true,
			multi: props.multi !== undefined ? props.multi : true,
			value: props.value !== undefined ? props.value : undefined,
		};
		this.entity = props.entity;
		this.getAlarmModel(props.model);
	}

	
    componentWillReceiveProps(nextProps) {
        if (nextProps.model !== this.props.model) {
			this.getAlarmModel(nextProps.model);
        }
	  }
	  
	getInitialState () {
		return {
			multiValue: null,
			options: [],
			backspaceRemoves: true,
			multi: true,
			value: undefined
		};
	}
	handleOnChange (value) {
		const { multi } = this.state;
		if (multi) {
			this.setState({ multiValue: value });
		} else {
			this.setState({ value });
		}
	}
	getAlarmModel(model) {
		let url = Conf.BaseApi + this.displayName + '/get'
		if (model !== undefined) url = Conf.BaseApi + this.displayName + '/get/' + model
		return getRequest(url, (body) => {
			if (body.success) {
				const data = body.result;
				const options = [];
				for (let i = 0; i < data.length; i += 1) {
					options.push({value: data[i]._id, label: data[i].name});
				}
				this.setState({ options });
			}
		  });
	}

	render () {
		const { multi, multiValue, value } = this.state;
		const { values } = this.props;
		if (!multiValue && values && values !== undefined)
			this.setState({multiValue: values});
		else if(!multiValue)
			this.setState({multiValue: []});
		return (
			<div className="section">
				<Select 
					multi={this.state.multi}
					value={multi ? multiValue : value}
					options={this.state.options}
					onChange={this.handleOnChange.bind(this)}
					backspaceRemoves={this.state.backspaceRemoves}
					id={this.displayName}
					name={this.displayName}
					/>
			</div>
		);
	}
}

export default SelectAlarmModel;