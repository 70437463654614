import decode from 'jwt-decode';

const ID_TOKEN_KEY = 'id_token';
const ACCESS_TOKEN_KEY = 'access_token';
const DASHBOARD_TOKEN_KEY = 'dashboard_token';
const REPORT_TOKEN_KEY = 'report_token';
const ROLE_KEY = 'role';
const ACCESS_KEY = 'access_right';
const USER_ID = 'user_id';
const USER_LOGIN = 'user_login';
const IS_NECIO = 'is_necio';

export function logout() {
    clearIdToken();
    clearAccessToken();
    clearUserId();
    clearUserLogin();
    clearAccessRight();
    clearRole();
    window.location.href = '/';
}

export function requireAuth(nextState, replace) {
    if (!isLoggedIn()) {
        replace({pathname: '/'});
    }
}


// UserId functions
export function getUserId() {
    return localStorage.getItem(USER_ID);
}

function clearUserId() {
    localStorage.removeItem(USER_ID);
}
export function setUserId(userId) {
    localStorage.setItem(USER_ID, userId);
}
// UserId functions
export function getUserLogin() {
    return localStorage.getItem(USER_LOGIN);
}

function clearUserLogin() {
    localStorage.removeItem(USER_LOGIN);
}

export function setUserLogin(userLogin) {
    localStorage.setItem(USER_LOGIN, userLogin);
}
// End user Id

export function getIdToken() {
    return localStorage.getItem(ID_TOKEN_KEY);
}

export function getAccessToken() {
    return localStorage.getItem(ACCESS_TOKEN_KEY);
}

function clearIdToken() {
    localStorage.removeItem(ID_TOKEN_KEY);
}

function clearAccessToken() {
    localStorage.removeItem(ACCESS_TOKEN_KEY);
}
function clearAccessRight() {
    localStorage.removeItem(ACCESS_KEY);
}
function clearRole() {
    localStorage.removeItem(ROLE_KEY);
}

// Get and store access_token in local storage
export function setAccessToken(accessToken) {
    localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
}

// Get and store id_token in local storage
export function setIdToken(idToken) {
    localStorage.setItem(ID_TOKEN_KEY, idToken);
}
// Get and store id_token in local storage
export function setRole(role) {
    localStorage.setItem(ROLE_KEY, role);
}
// Get and store id_token in local storage
export function setAccessRight(accessRight) {
    localStorage.setItem(ACCESS_KEY, JSON.stringify(accessRight));
}
export function isLoggedIn() {
    try {
        const idToken = getIdToken();
        return !!idToken && !isTokenExpired(idToken);
    } catch (err) {
        return false;
    }
}

function getTokenExpirationDate(encodedToken) {
    const token = decode(encodedToken);
    if (!token.exp) { return null; }

    const date = new Date(0);
    date.setUTCSeconds(token.exp);

    return date;
}

function isTokenExpired(token) {
    const expirationDate = getTokenExpirationDate(token);
    return expirationDate < new Date();
}

/**** Dash Token */
function clearDashToken() {
    localStorage.removeItem(DASHBOARD_TOKEN_KEY);
}

// Get and store access_token in local storage
export function setDashToken(dashToken) {
    localStorage.setItem(DASHBOARD_TOKEN_KEY, dashToken);
}

export function getDashToken() {
    return localStorage.getItem(DASHBOARD_TOKEN_KEY);
}

/**** Report Token */
function clearReportToken() {
    localStorage.removeItem(REPORT_TOKEN_KEY);
}

// Get and store access_token in local storage
export function setReportToken(reportToken) {
    localStorage.setItem(REPORT_TOKEN_KEY, reportToken);
}

export function getReportToken() {
    return localStorage.getItem(REPORT_TOKEN_KEY);
}
export function getRole() {
    return localStorage.getItem(ROLE_KEY);
}
export function getAccessRight() {
    return JSON.parse(localStorage.getItem(ACCESS_KEY));
}

export function setUserIsNecio(isNecio) {
    localStorage.setItem(IS_NECIO, isNecio);
}
export function getUserIsNecio() {
    return localStorage.getItem(IS_NECIO);
}