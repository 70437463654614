import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import { translate } from '../../../utils/ReactMultiLang';
import AvatarEditor from 'react-avatar-editor';


class CreateImage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      realWidth: 350,
      realHeight: 350,
      width: 350,
      height: 350,
    };
    this.t = props.t;
  }
  


  createComponent(callback) {
    if (this.editor) {
      const component = {};
      const canvas = this.editor.getImage();
      canvas.style.width  = this.state.realWidth; // in pixels
      canvas.style.height = this.state.realHeight; // in pixels
      let dataUrl = canvas.toDataURL();
      component.dataSets = [];
      component.dataSets[0] = {};
      component.name = "Image";
      component.type = "IMAGE";
      component.image = dataUrl;
      callback(component);
    }
  }

  handleDrop = dropped => {
    const file = dropped[0];
    const i = new Image()
  
    i.onload = () => {
      let reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        console.log({
          src: file.preview,
          width: i.width,
          height: i.height,
          data: reader.result
        })
        let realWidth = i.width;
        let realHeight = i.height;
        let width = 350;
        let height = 350;
        const ratio = realWidth / realHeight;
        if (ratio > 1) {
          height = height / ratio;
        } else {
          width = width * ratio;
        }
        if (realWidth > 1900) {
          realHeight = Math.round(realHeight * (1900 / realWidth)); 
          realWidth = 1900;
        }
        if (realHeight > 1900) {
          realWidth = Math.round(realWidth * (1900 / realHeight)); 
          realHeight = 1900;
        }
        this.setState({ image: dropped[0], realWidth, realHeight, width, height });
      }
    }
    i.src = file.preview;
  }
 
  setEditorRef = (editor) => this.editor = editor
 
  render() {
    const { callback } = this.props;
    return (
      <div className="flex-row align-items-center">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12">
              <div className="card mx-12">
                {this.renderImageDropper(callback)}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderImageDropper(callback) {
    const { width, height, borderRadius, scale} = this.props;

    return (
      <div className="card-block p-12">
      
        <Dropzone
              onDrop={this.handleDrop}
              disableClick
              style={{ width: `${width}px`, height: `${height*1.5}px` }}
          >
              <AvatarEditor
                ref={this.setEditorRef}
                image={this.state.image}
                width={this.state.width}
                height={this.state.height}
                borderRadius={borderRadius}
                scale={scale}
              />
          </Dropzone>
        <br />
        <button type="button" className="btn btn-block btn-success" onClick={this.createComponent.bind(this, callback)}>{this.t('Add')}</button>
      </div>
    );
  }

}
CreateImage.defaultProps = {
    width: 340,
    height: 300,
    borderRadius: 0,
    scale: 1
};

export default translate(CreateImage);