import React, { Component } from 'react';
import $ from 'jquery';
import Conf from '../../../utils/Conf';
import moment from 'moment';
import { getRequest, postRequest } from '../../../utils/WebServicesManager';
  import { translate } from '../../../utils/ReactMultiLang';
  import { DMStoDegree } from '../../../utils/Utils';
import GPSMap from '../../../components/Charts/GPSMap/GPSMap';
import GPSSensorsTable from './GPSSensorsTable';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import { getDistance } from '../../../utils/Utils';
import Slider from 'rc-slider';
import {Row, Col } from 'reactstrap';
import 'rc-slider/assets/index.css';


class GPSSensors extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sinceDate: null,
      Sensors: [],
      Messages: {},
      messageLoaded: false,
      isHeatmap: false,
      readyToRender: false,
      numberMessages: 1,
      Key: 0,
      ActualDensity: 0,
      DensityMessage: null,
      ShowRange: true
    };
    this.t = props.t;
  }

  getLocation(DevEUI, since) {
    //messages/getComputed/number/:number/:device/:dataName
    var url = Conf.BaseApi + `messages/getComputed/since/${DevEUI}/${since}/lat`;
    if (!since)
      url = Conf.BaseApi + `messages/getComputed/number/1/${DevEUI}/lat`;
    return new Promise((resolve) => {
      getRequest(url, (data) => {
        if (data.success) {
          const messages = [];
          for (let i = 0; i < data.result.length; i += 1) {
            const message = data.result[i];

            if (message.data.lat.includes('°')) {
              message.data.lat = DMStoDegree(message.data.lat, true)  - 0.0015;
              message.data.lng = DMStoDegree(message.data.lng, false) - 0.0035;
            } else {
              message.data.lat = parseFloat(message.data.lat);
              message.data.lng = parseFloat(message.data.lng);
              message.data.estim_error = parseFloat(message.data.estim_error);
            }
            messages.push(message);
          }
          // const stateMessages = this.state.Messages;
          // messages[DevEUI] = stateMessages;
          resolve(messages)
        }
      });
    });
  }

  async updateSensors(sensors) {
    this.setState({ readyToRender: false });
    this.state.Messages = {};
    const messages = {}
    if (sensors) {
      let since = 0;
      if (this.state.sinceDate) since = this.state.sinceDate.unix();
      for (let i = 0; i < sensors.length; i += 1) {
        const devMessages = await this.getLocation(sensors[i].DevEUI, since);
        messages[sensors[i].DevEUI] = devMessages;
      }
    }
    this.setState({ Sensors: sensors, Messages: messages, readyToRender: true, Key: this.state.Key + 1, DensityMessage: null, ActualDensity: 0 });
  }

  onStatusChange(evt) {
    this.setState({isHeatmap: evt.target.checked});
  }
  setNumberMessages(evt) {
    this.setState({numberMessages: evt.target.value}, this.updateSensors.bind(this, this.state.Sensors));
  }
  handleChangeDate(from) {
    this.setState({sinceDate: from}, this.updateSensors.bind(this, this.state.Sensors));
  }

  handleChangeComplete(value) {
    const dMessages = {};
    const keys = Object.keys(this.state.Messages);
    if (value > 0) {
      for (let i = 0; i < keys.length; i += 1) {
        if (!dMessages[keys[i]]) dMessages[keys[i]] = []
        for (let j = 0; j < this.state.Messages[keys[i]].length; j += 1) {
          let toAdd = true;
          const msg = this.state.Messages[keys[i]][j];
          for (let k = 0; k < dMessages[keys[i]].length; k += 1) {
            if (getDistance([msg.data.lat, msg.data.lng], [dMessages[keys[i]][k].data.lat, dMessages[keys[i]][k].data.lng]) < value / 1000.0) {
              toAdd = false;
            }
          }
          if (toAdd) {
            dMessages[keys[i]].push(this.state.Messages[keys[i]][j]);
          }
        }
      }
      this.setState({ActualDensity: value, DensityMessage: dMessages});
    } else {
      this.setState({ActualDensity: value, DensityMessage: null});
    }
  } 

  switchChange(event) {
    this.setState({ShowRange: event.target.checked});
  }


  render() {
    return (
      <div className="row">
         <div className="col-xl-12">
          <div className="row ">
            <div className="col-lg-6">
              <div className="card">
                <div className="card-header">
                  <i className="fa fa-align-justify"></i> {this.t('Sensors')}
                  
                </div>
                <div className="card-block">
                  <GPSSensorsTable updateSensors={this.updateSensors.bind(this)}/>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="card">
                <div className="card-header">
                  <Row>
                    <Col md={6} lg={2}>
                      <i className="fa fa-map"></i> {this.t('Map')}
                    </Col>
                    <Col md={6} lg={3}>
                      {this.t('Since')}{' '} 
                        <DatePicker
                          selected={this.state.sinceDate}
                          onChange={this.handleChangeDate.bind(this)}
                          dateFormat="DD MMMM YYYY"
                        />
                    </Col>
                      { this.state.sinceDate ? 
                    <Col md={7} lg={4}>
                      <Slider className="slider-twiny" min={0} max={500} step={20} style={{marginTop:"5px"}}
                defaultValue={this.state.ActualDensity}
                onAfterChange={this.handleChangeComplete.bind(this)}
                marks={{ 0: '0m', 250: '250m', 500: '500m' }} /></Col> : '' }
                    <Col md={5} lg={3}>
                    {this.t('GPS.Range')}{' '}
                    <label className="switch switch-icon switch-success-outline-alt">
                      <input type="checkbox" className="switch-input" onChange={this.switchChange.bind(this)} defaultChecked/>
                      <span className="switch-label" data-on="&#10003;" data-off="&#10007;"></span>
                      <span className="switch-handle"></span>
                    </label>
                    </Col>
                  </Row>
                </div>
                <div className="card-block">
                  <div style={{height:'700px'}}>
                  <GPSMap Sensors={this.state.Sensors}
                    networks={false}
                    Messages={this.state.DensityMessage ? this.state.DensityMessage : this.state.Messages}
                    isHeatmap={false}
                    showRange={this.state.ShowRange}
                    readyToRender={this.state.readyToRender}
                    Key={this.state.Key}/>
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default translate(GPSSensors);
