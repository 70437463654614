import React, { Component } from 'react';
import { translate } from '../../../../utils/ReactMultiLang';
import { Modal, ModalHeader, ModalBody, ModalFooter, Row } from 'reactstrap';
import AddDataSet from '../../AddDataSet';


class SelectDataSet extends Component {
	constructor(props) {
		super(props);
		this.state = {
			dataSets: props.dataSets,
			ModalAddDataSet: false,
			ModalUpdateDataSet: false,
			actDataSet: undefined
		};
		this.t = props.t;
		this.entity = props.entity;
		this.rmDataSet = props.rmDataSet;
		this.addDataSet = props.addDataSet;
		this.editDataSet = props.editDataSet;
    this.type = props.type;
	}
	
    componentWillReceiveProps(nextProps) {
        if (this.props.dataSets !== nextProps.dataSets ) {
            this.setState({dataSets: nextProps.dataSets});
        }
	  }
	  
	handleOnChange () {
	}

	toggleAddDataSet() {
		this.setState({ModalAddDataSet: !this.state.ModalAddDataSet});
	}
	toggleUpdateDataSet(dataSet) {
		this.setState({ModalUpdateDataSet: !this.state.ModalUpdateDataSet, actDataSet: dataSet });
	}

	render () {
		return (
			<div className="section">
				<div className="form-group mb-3">
                      {this.renderDataSets(this.state.dataSets)}
                      <button type="button" className="btn btn-info" onClick={this.toggleAddDataSet.bind(this)}>{this.t('Add')} {this.t('a')} {this.t('Component.Datasets')}</button>
                </div>
        <Modal isOpen={this.state.ModalAddDataSet} className={'modal-md ' + this.props.className}>
                    <ModalHeader>
                        <Row style={{paddingLeft:'15px', paddingRight:'15px'}}>
                            <h1>{this.t('Component.AddDataSet')}</h1>
                            <i className="icon-close color-danger" onClick={this.toggleAddDataSet.bind(this)}
                                style={{position:'absolute', textAlign: 'right', right:'15px', cursor:'pointer'}}></i>
                        </Row>
                    </ModalHeader>
          <ModalBody>
              <AddDataSet callback={(data) => {
				  this.addDataSet(data);
				  this.toggleAddDataSet();
				  }}
          type={this.props.type}/>
          </ModalBody>
        </Modal>
        <Modal isOpen={this.state.ModalUpdateDataSet} className={'modal-md ' + this.props.className}>
                    <ModalHeader>
                        <Row style={{paddingLeft:'15px', paddingRight:'15px'}}>
                            <h1>{this.t('Component.AddDataSet')}</h1>
                            <i className="icon-close color-danger" onClick={this.toggleUpdateDataSet.bind(this)}
                                style={{position:'absolute', textAlign: 'right', right:'15px', cursor:'pointer'}}></i>
                        </Row>
                    </ModalHeader>
          <ModalBody>
              <AddDataSet callback={(data) => {
				  this.editDataSet(data);
				  this.toggleUpdateDataSet();
				  }} actDataSet={this.state.actDataSet}/>
          </ModalBody>
        </Modal>
			</div>
		);
	}
	renderDataSets = (dataSets) => {
		if (dataSets) {
		  
		return (
		  <ul>
			{dataSets.map((dataSet, index) => (
			  <li key={`item-${index}`}>
				<span style={{backgroundColor:dataSet.color, padding:'5px', color: "white"}}>{dataSet.paramName}</span> -> {dataSet.label}({dataSet.unit})
				<button type="button" className="btn btn-link text-success" onClick={() => this.toggleUpdateDataSet(dataSet)}><i className="icon-pencil"></i></button>
				{dataSets.length > 1 ? <button type="button" className="btn btn-link text-danger" onClick={() => this.rmDataSet(dataSet._id)}><i className="icon-close"></i></button> : '' }
			  </li>
			))}
		  </ul>);
		}
	  }
	
}


export default translate(SelectDataSet);