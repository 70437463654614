import React from 'react';
import { Button, Col, Modal, ModalHeader, Row } from 'reactstrap';
import { getRequest, postRequest } from '../../../utils/WebServicesManager';
import SensorSelector from '../../Modals/SensorSelector';
import Conf from '../../../utils/Conf';
import { translate } from '../../../utils/ReactMultiLang';
import $ from 'jquery';

class AddAsset extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          AssetTypes: [],
          Modal: false,
          Rooms: [],
          TemplateVars: [],
          SelectedVars: [],
          varToEdit: undefined,
          SensorTypes: undefined,
          SelectedType: {}
        };
        this.getAssetTypes();
        this.getRooms(props.floor);
        this.t = props.t;
    }
    componentWillReceiveProps(nextProps) {
      if (this.props.floor !== nextProps.floor) {
        this.getRooms(nextProps.floor);
      } 
    }
  

    toggleSelectSensor() {
      this.setState({Modal: !this.state.Modal});
    }

  getAssetTypes() {
    var url = Conf.BaseApi + 'assetTypes/get';
    const { type } = this.props;
    getRequest(url, (data) => {
      if (data.success) {
        if (type) {
          this.setState({ AssetTypes: data.result, SelectedType: data.result.find(t => t.name === type) });
        } else {
          this.setState({ AssetTypes: data.result });
        }
      }
    });
  }
  getRooms(floor) {
    if (floor) {
      var url = Conf.BaseApi + 'rooms/getByFloor/' + floor._id;
      getRequest(url, (data) => {
        if (data.success) {
          this.setState({ Rooms: data.result });
        }
      });
    }
  }

  onClickSave(building, floor, callback) {
    
    const asset = {};
    asset.name = $("#name").val();
    asset.room = $("#roomSelector").val();
    asset.type = this.state.SelectedType.name;
    asset.sensors = this.state.TemplateVars.filter(e => e != "" || e != undefined);
    asset.building = building;
    asset.floor = floor._id;

    const url = Conf.BaseApi + 'assets/add';
    return postRequest(url, asset, (data) => {
      if (data.success || data.success == true) {
        callback();
      } else {
        $("#error-window").text(data.message);
      }
    });
  }
  

  render () {
    const { type, building, floor, callback} = this.props;


    return (
        <div style={{position: "relative" }}>
          
          <div className="input-group mb-3" style={{ color: "red" }} id="error-window">

</div>
      <div className="input-group mb-3">
        <span className="input-group-addon"><i className="icon-map"></i></span>
        <input type="text" className="form-control" placeholder={this.t('Name')}
          id="name" onChange={(e) => this.setState({name: e.target.value})} />
      </div>
      <select className="form-control" id="roomSelector">
        {this.generateRoomList(this.state.Rooms)}
      </select>
      <select className="form-control" disabled={type ? 'true' : 'false'}>
        {this.generateTypeList(this.state.AssetTypes, type)}
      </select>


      { this.generateFields(this.state.SelectedType)}
        
        <Button color="primary" className="btn btn-danger btn-block" onClick={this.onClickSave.bind(this, building, floor, callback)}>
                    <i className="fa fa-credit-card-alt"></i>&nbsp; Envoyer
            </Button>
        <Modal isOpen={this.state.Modal} className={'modal-lg ' + this.props.className}>
        <ModalHeader>
                        <Row style={{paddingLeft:'15px', paddingRight:'15px'}}>
                            <h1>{this.t('Sensors')}</h1>
                            <i className="icon-close color-danger" onClick={this.toggleSelectSensor.bind(this)}
                                style={{position:'absolute', textAlign: 'right', right:'15px', cursor:'pointer'}}></i>
                        </Row>
                    </ModalHeader>
           <SensorSelector callbackAddSensor={this.sensorSelected.bind(this)} types={this.state.SensorTypes}/>
        </Modal>
        </div>
    )
  }

  generateTypeList(tList, t) {
    const toRender = [];

    for (let i = 0; i < tList.length; i += 1) {
      if (tList[i].name === t) {
        toRender.push(<option value={tList[i].name} selected='selected'>{tList[i].name}</option>);
      } else {
        toRender.push(<option value={tList[i].name}>{tList[i].name}</option>);
      }
    } 
    return toRender;
  }

  generateRoomList(rooms) {
    const toRender = [];

    for (let i = 0; i < rooms.length; i += 1) {
      toRender.push(<option value={rooms[i]._id}>{rooms[i].name}</option>);
    } 

    return toRender;
  }
  sensorSelected(sensors) {
    if (sensors.length > 0 && this.state.varToEdit) {
      const { _id, name } = sensors[0];
      this.state.TemplateVars[this.state.varToEdit] = _id;
      this.state.SelectedVars[this.state.varToEdit] = name;
      this.setState({Modal: false});
    }
  }

  generateFields(typeObj) {
    const toRender = [];

    if (!typeObj || !typeObj.sensors) return toRender;

    for (let i = 0; i < typeObj.sensors.length; i += 1) {
      const type = typeObj.sensors[i];
        toRender.push(
            <Col md='12' sm='12'>
              <label htmlFor="alarmLevelField">{type}</label>
              <div className="input-group mb-3">
				        
                  <button type="button" className={`btn btn-block ${this.state.SelectedVars[i] ? 'btn-success' : 'btn-warning'}`} onClick={() => {
                    this.setState({SensorTypes: [type], varToEdit: i}, () => this.toggleSelectSensor());
                  }}>{this.state.SelectedVars[i] ? this.state.SelectedVars[i] : this.t("Select")}</button>
				        
              </div>
            </Col>);
    }

    return toRender;
  }
}

export default translate(AddAsset)