import React, { Component } from 'react';
import Conf from '../../../utils/Conf';
import { getRequest, postRequest } from '../../../utils/WebServicesManager';
import { translate } from '../../../utils/ReactMultiLang';
import InfraList from '../../../components/Infrastructures/InfraList/InfraList';
import AddPlan from '../../../components/Modals/AddPlan';
import AddAsset from '../../../components/Modals/AddAsset';
import {
  Modal, ModalHeader, Row, Col, Nav, NavItem, NavLink,
  TabContent, TabPane, ModalBody, Label, Input
} from 'reactstrap';
import classnames from 'classnames';
import TabPlan from './TabPlan/TabPlan';
import { check } from '../../../utils/Can';
import { ThemeConsumer } from 'styled-components';

class Plans extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Building: {},
      ActPlan: 0,
      Groups: [],
      Buildings: [],
      Floors: [],
      Rooms: [],
      Filters: [],
      Plan: {},
      SelectedGroup: JSON.parse(localStorage.getItem('Plans-SelectedGroupName') || null),
      SelectedBuildingId: JSON.parse(localStorage.getItem('Plans-SelectedBuildingId') || null),
      SelectedFloorId: JSON.parse(localStorage.getItem('Plans-SelectedFloorId') || null),
      SelectedRoomId: JSON.parse(localStorage.getItem('Plans-SelectedRoomId') || null),
      TableOpen: true,
      MapOpen: true,
      ModalAddPlan: false,
      ModalAsset: false,
      Key: 0,
      CanEdit: check('infra:edit'),
      EditRoom: false,
      SelectedTab: null
    };
    this.tooglePlan = this.tooglePlan.bind(this);
    this.openAssetPopup = this.openAssetPopup.bind(this);
    this.getBuildingDetails = this.getBuildingDetails.bind(this);
    this.t = props.t;

    if (this.state.SelectedGroup) {
      this.getBuildings(this.state.SelectedGroup, true);
    }
    this.getGroups();
  }

  getGroups() {
    var url = Conf.BaseApi + 'groups/get';

    getRequest(url, (data) => {
      if (data.success) {
        this.setState({ Groups: data.result });
      }
    });
  }

  getBuildings(g, init) {
    var url = Conf.BaseApi + 'buildings/getSorted';

    localStorage.setItem('Plans-SelectedGroupName', JSON.stringify(g));

    postRequest(url, { pageSize: 1000, page: 0, sorted: null, filtered: [{ id: "group", value: g }] }, (data) => {
      if (data.success) {
        this.setState({ Buildings: data.result.rows, SelectedGroup: g }, () => {
          if (init && this.state.SelectedBuildingId) {
            this.selectInfra(this.state.SelectedBuildingId, true);
          }
        });
      }
    });
  }

  selectInfra(infraId, init) {
    const index = this.state.Buildings.findIndex(b => b._id == infraId);
    localStorage.setItem('Plans-SelectedBuildingId', JSON.stringify(infraId));
    this.setState({ Building: this.state.Buildings[index], SelectedBuildingId: infraId }, () => {
      if (init && this.state.SelectedFloorId) {
        this.selectFloor(this.state.SelectedFloorId, true);
      }
    });
  }

  selectFloor(floorId) {
    if (this.state.Building && this.state.Building.floors) {
      console.log(this.state.Building.floors);
      const index = this.state.Building.floors.findIndex(b => b._id == floorId);
      if (index !== -1) {
        localStorage.setItem('Plans-SelectedFloorId', JSON.stringify(floorId));
        // Get plan
        if (this.state.Building.floors[index].plan) {
          var url = Conf.BaseApi + 'plans/getOne/' + this.state.Building.floors[index].plan;

          getRequest(url, (data) => {
            if (data.success) {
              this.setState({ Floor: this.state.Building.floors[index], SelectedFloorId: floorId, Plan: data.result });
            }
          });
        }
      }
    }
  }

  tooglePlan() {
    this.setState({ ModalAddPlan: !this.state.ModalAddPlan });
  }
  openAssetPopup() {
    console.log('toto');
    this.setState({ ModalAsset: !this.state.ModalAsset });
  }
  getBuildingDetails(id, isModal) {
    if (!id && this.state.Building !== undefined) {
      id = this.state.Building._id;
    }
    let url = Conf.BaseApi + 'buildings/get/' + id;
    getRequest(url, (data) => {
      if (data.success) {
        this.setState({
          Key: this.state.Key + 1, ActPlan: 0, Building: data.result, SelectedBuildingId: id,
          ModalAddPlan: isModal ? !this.state.ModalAddPlan : this.state.ModalAddPlan
        });
      }
    });
  }

  toggleInfra() {
    this.setState({ InfraOpen: !this.state.InfraOpen }, () => localStorage.setItem('Plans-InfraOpen', JSON.stringify(this.state.InfraOpen)));
  }
  toggleFloor() {
    this.setState({ FloorOpen: !this.state.FloorOpen }, () => localStorage.setItem('Plans-FloorOpen', JSON.stringify(this.state.FloorOpen)));
  }
  toggleRoom() {
    this.setState({ RoomOpen: !this.state.RoomOpen }, () => localStorage.setItem('Plans-RoomOpen', JSON.stringify(this.state.RoomOpen)));
  }

  removePlan(plan) {
    var url = Conf.BaseApi + 'plans/remove';
    const r = window.confirm('Etes vous sur de vouloir supprimer le plan : "' + plan.name + '" ?');
    if (r) {
      return postRequest(url, {
        planId: plan._id,
        groupId: this.state.Building.group,
        buildId: this.state.SelectedBuildingId

      }, (result) => {
        if (result.success === true) {
          this.getBuildingDetails(this.state.SelectedBuildingId);
        }
      });
    }
  }

  render() {
    return (
      <div>
        <div className="card">
          <div className={`card-block`}>
            <Row>
              <Col xs="12" md='4' lg='4'>

                <select onChange={(e) => this.getBuildings(e.target.value)} defaultValue={this.state.SelectedGroup} className="form-control">
                  {this.generateGroupOption()}
                </select>
              </Col>
              <Col xs="12" md='4' lg='4'>
                <select onChange={(e) => this.selectInfra(e.target.value)} defaultValue={this.state.Building ? this.state.Building._id : ""} className="form-control">
                  {this.generateInfraList(this.state.Buildings)}
                </select>
              </Col>
              <Col xs="12" md='4' lg='4'>
                <select onChange={(e) => this.selectFloor(e.target.value)} defaultValue={this.state.Floor ? this.state.Floor._id : ""} className="form-control">
                  {this.generateFloorList(this.state.Building && this.state.Building.floors ? this.state.Building.floors : [])}
                </select>
              </Col>
            </Row>
          </div>
        </div>
        <Col id='planCol' key={this.state.SelectedFloorId}>
          {this.getTabContent(this.state.Floor, this.state.Building)}
        </Col>
        <Modal isOpen={this.state.ModalAddPlan} className={'modal-md ' + this.props.className}>
          <ModalHeader>
            <Row style={{ paddingLeft: '15px', paddingRight: '15px' }}>
              <h1>{this.t('AddPlan')}</h1>
              <i className="icon-close color-danger" onClick={this.tooglePlan}
                style={{ position: 'absolute', textAlign: 'right', right: '15px', cursor: 'pointer' }}></i>
            </Row>
          </ModalHeader>
          <AddPlan floor={this.state.SelectedFloorId} callback={this.getBuildingDetails.bind(this)} />
        </Modal>
        <Modal isOpen={this.state.ModalAsset} className={'modal-md ' + this.props.className}>
          <ModalHeader>
            <Row style={{ paddingLeft: '15px', paddingRight: '15px' }}>
              <h1>{this.t('Asset.Add.Place')}</h1>
              <i className="icon-close color-danger" onClick={this.openAssetPopup}
                style={{ position: 'absolute', textAlign: 'right', right: '15px', cursor: 'pointer' }}></i>
            </Row>
          </ModalHeader>
          {console.log(this.state.Floor)}
          <AddAsset type='Place' floor={this.state.Floor} callback={this.openAssetPopup} />
        </Modal>
      </div>

    )
  }


  genTabHeader(building) {
    const render = [];
    if (building && building.plans != undefined) {
      const plans = building.plans;
      for (let i = 0; i < plans.length; i += 1) {
        render.push(<NavItem key={i}>
          <NavLink
            className={classnames({ active: this.state.ActPlan === (i) })}
            onClick={() => { this.toggle(i); }} style={{ cursor: 'pointer' }}
          >
            <span>{plans[i].name}</span>

          </NavLink>
        </NavItem>);
      }
    }
    if (this.state.CanCreate) {
      render.push(<NavItem key={60000}>
        <NavLink
          onClick={() => { this.toggleModal(); }}
        >
          +
        </NavLink>
      </NavItem>);
    }
    return render;
  }

  getTabContent(floor, building, room) {
    const render = [];
    render.push(<TabPlan building={building}
      selectedTab={this.state.SelectedTab}
      addPlace={this.openAssetPopup}
      addPlan={this.tooglePlan}
      removePlan={this.removePlan.bind(this)}
      floor={floor} room={room} key={this.state.SelectedFloorId * 10 + this.state.SelectedBuildingId * 100 + this.state.SelectedRoomId}
      isVisible={true} EditRoom={this.state.EditRoom} />);
    return render;
  }

  generateGroupOption() {
    const toRender = [];

    toRender.push(<option value="">{this.t("Plan.SelectAGroup")}</option>);

    this.state.Groups.forEach((group) => {
      if (group.name === this.state.SelectedGroup) {
        toRender.push(<option value={group.name} selected="selected">{group.name}</option>);
      } else {
        toRender.push(<option value={group.name}>{group.name}</option>);
      }
    });

    return toRender;
  }

  generateInfraList(infras) {
    const toRender = [];

    toRender.push(<option value="">{this.t("Plan.SelectAnInfra")}</option>);

    infras.forEach((infra) => {
      if (infra._id === this.state.SelectedBuildingId) {
        toRender.push(<option value={infra._id} selected="selected">{infra.name}</option>);
      } else {
        toRender.push(<option value={infra._id}>{infra.name}</option>);
      }
    });

    return toRender;
  }

  generateFloorList(floors) {
    const toRender = [];

    toRender.push(<option value="">{this.t("Plan.SelectAFloor")}</option>);

    floors.forEach((floor) => {
        if (floor._id === this.state.SelectedFloorId) {
          toRender.push(<option value={floor._id} selected="selected">{floor.name}</option>);
        } else {
          toRender.push(<option value={floor._id}>{floor.name}</option>);
        }
    });

    return toRender;
  }
}

export default translate(Plans);
