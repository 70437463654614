import React, { Component } from 'react';
import { Dropdown, DropdownMenu, DropdownItem, 
    DropdownToggle, Progress } from 'reactstrap';
import Conf from '../../../utils/Conf';
import { translate } from '../../../utils/ReactMultiLang';

class Energy extends Component {
    constructor(props) {
        super(props);
        this.datas = {};
        this.options = {};
        this.state = {
            card1: false,
            isPublic: props.isPublic,
            isLocked: props.isLocked
        }
        this.t = props.t;
    }


    render() {
        
        const { component, chartDatas, deleteComponent, editComponent } = this.props;
        
        let aPos = 0;

        let letter = "A";
        if (component && component.dataSets) {

          Conf.EnergyColors.forEach(e => {
            if (component.dataSets[2].value > e.min && component.dataSets[2].value < e.max) {
              letter = e.label;
            }
          });
        }
        return (
                <div className={`grid-element`} ref='mainDiv' style={{height:'100%'}}>
                    <div className="card-block pb-0" style={{position: "relative"}}>
                    
                    {this.state.isPublic || this.state.isLocked ? '' :
                    <div className="btn-group float-right">
                        <Dropdown isOpen={this.state.card1} toggle={() => { this.setState({ card1: !this.state.card1 }); }}>
                            <DropdownToggle onClick={() => { this.setState({ card1: !this.state.card1 }); }} className="btn active dropdown-toggle p-0" data-toggle="dropdown" aria-haspopup={true} aria-expanded={this.state.card1}>
                                <i className="icon-settings"></i>
                            </DropdownToggle>
                            <DropdownMenu right>
                                <DropdownItem onClick={() => {editComponent()} }><i className='icon-pencil' style={{color: "#212121"}}></i>{this.t('Edit')}</DropdownItem>
                                <DropdownItem onClick={() => {deleteComponent()} }><i className='icon-trash' style={{color: "#212121"}}></i>{this.t('Delete')}</DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    </div>}
                      <div className="energyLabels" >
                        <p className="eTitle">{component.name}</p>
                        { component.dataSets.length === 3 ? <div>
                        <p  className="eLine"><span>{component.dataSets[0].label}:</span> <span>{component.dataSets[0].value}</span></p>
                        <p className="eLine"><span>{component.dataSets[1].label}:</span> <span>{component.dataSets[1].value} [m3]</span></p>
                        <p className="eLine"><span>{component.dataSets[2].label}:</span> <span>{component.dataSets[2].value} [kg-eq]</span></p>
                        <p className="eLine"><span>Classe besoin en chauffage:</span> <span>{letter}</span></p>
                        </div> : '' }
                      </div>
                      { Conf.EnergyColors.map((e, i) => {
                        const width = 50 + 30 * i;
                        if (component.dataSets[2].value > e.min && component.dataSets[2].value < e.max) {
                          aPos = (-(7 - i) * 38 - 17) + (30 * ((component.dataSets[2].value - e.min) / (e.max - e.min))); // calcul left arrow displacement
                        }
                        return <p class="energy-bloc" style={{backgroundColor: `#${e.color}`, width: `${width}px` }}>
                          <span class="energy-label" style={{color: `#${e.textColor}`}}>{e.label}</span>
                          <i class="energy-arrow" style={{backgroundColor: `#${e.color}`, borderColor: `#${e.color}`, marginLeft: `${width - 11}px`}}></i>
                          </p>;
                      }
                      )}
                      <p class="energy-bloc" style={{backgroundColor: `#b1b1b1`, width: `${50}px`, position: "absolute", marginLeft: "20px", marginTop: `${aPos}px`}}>
                        <i class="energy-arrow" style={{backgroundColor: `#b1b1b1`, borderColor: `#b1b1b1`, marginLeft: `${50 - 11}px`}}></i>
                      </p>
                    </div>
                </div>
        );
    }
}

export default translate(Energy);
