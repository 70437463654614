import React, { Component } from 'react';
import { ModalBody} from 'reactstrap';
import { translate } from '../../../utils/ReactMultiLang';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import Conf from '../../../utils/Conf';
import { postRequest } from '../../../utils/WebServicesManager';
import GridLoader from "react-spinners/GridLoader";
import { saveAs } from 'file-saver';

function str2bytes (str) {
  var bytes = new Uint8Array(str.length);
  for (var i=0; i<str.length; i++) {
      bytes[i] = str.charCodeAt(i);
  }
  return bytes;
}

class ExportData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      exportDate: moment().subtract('M', 6),
      loading: false
    }
    this.t = props.t;
  }

  handleChangeDate(exportDate) {
    this.setState({exportDate});
  }

  exportDatas() {
    this.setState({loading: true})
    const { callback, devices  } = this.props;

    var url = `${Conf.BaseApi}data/device/export`;

    const sensors = [];
    for (let i = 0; i < devices.length; i += 1) {
        sensors.push(devices[i].DevEUI);
    }
    var options = {sensors, since: this.state.exportDate.unix()};
    postRequest(url, options, (data) => {
      const url = "data:application/zip;base64,"+data;
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = 'download.zip';
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      callback();
    });
  }

  render() {

  return (this.state.loading ? <ModalBody><GridLoader 
    color={'#36d7b7'}
  /></ModalBody> : <ModalBody>
             <div style={{ marginLeft:'25px', zIndex:'9000'}}>
             {this.t('Since')}: <DatePicker
               selected={ this.state.exportDate }
               onChange={this.handleChangeDate.bind(this)}
               dateFormat="DD MMMM YYYY"
             />
             </div>
             <br />
             <br />
             <button type="button" className="btn btn-block btn-success" onClick={() => this.setState({loading: true}, this.exportDatas)}>{this.t('Export')}</button>
            </ModalBody>
             )
    
  }

}

export default translate(ExportData);