import React, { Component } from "react";

class TrayWidget extends Component {

	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		return <div className="tray">{this.props.children}</div>;
	}
}

export default TrayWidget;