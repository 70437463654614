import React, { Component } from 'react';
import $ from 'jquery';
import Conf from '../../../utils/Conf';
import { getRequest, postRequest } from '../../../utils/WebServicesManager';
import { pagination, getDateStr } from '../../../utils/Utils';
import {
  Card, Row, Button,
  Modal, ModalHeader
} from 'reactstrap';
import { translate, exists } from '../../../utils/ReactMultiLang';
import LineChats from '../../../components/Charts/LineChats';
import SendDownlink from '../../../components/Modals/SendDownlink';
import PlotPredictionsCharts from '../../../components/Charts/PlotPredictionsCharts';
import MessagesTable from '../../../components/MessagesTable';
import EventTable from '../../../components/EventTable/EventTable';
import { check } from '../../../utils/Can';
import AlertTable from '../../../components/Tables/AlertTable/AlertTable';

class Details extends Component {
  constructor(props) {
    super(props);
    const params = new URLSearchParams(props.location.search);
    this.DevEUI = params.get('deveui');
    this.sensorId = params.get('sensorId');
    this.isVirtual = this.sensorId ? true : false;
    this.state = {
      DeviceMessages: [],
      ChartMessages: [],
      DeviceDetails: {},
      PlotPredictions: {},
      ModalDownlink: false, 
      CanRemove: check('sensor:remove'),
      ActualPage: 0,
      Days: 1,
      MsgKey: 0
    };
    if (!this.DevEUI && !this.sensorId) {
      window.location = '#/sensors/manage';
    }
    this.t = props.t;
    this.toggleDownlink = this.toggleDownlink.bind(this);
    this.getDevicesDetails();
  }

  toggleDownlink() {
    this.setState({
      ModalDownlink: !this.state.ModalDownlink
    });
  }

  getDevicesDetails() {
    this.state.ActualPage = 0;
    var url = this.isVirtual ? `${Conf.BaseApi}virtualDevices/getById/${this.sensorId}` : `${Conf.BaseApi}${Conf.GetSensor}${this.DevEUI}`;
    getRequest(url, (data) => {
      if (data.success) {
        this.setState({ DeviceDetails: data.result });
        if (this.isVirtual || data.result.type.includes("TEMPERATURE")) {
          this.getMessagesSince(this.isVirtual ? this.sensorId : this.DevEUI, this.state.Days);
        }
        if (data.result.activeML) {
          const url = Conf.BaseApi + 'predictions/getPlot';
          return postRequest(url, { DevEUI: this.DevEUI, dataName: data.result.aiData }, (body) => {
            if (body.success) {
              this.setState({ PlotPredictions: body.result });
            }
          });
        }
      }
    });
    const dateDays = 60 * 24 * 60 * 60; // in seconds
    const dateMinusDays = Math.floor(Date.now() / 1000) - dateDays;
    url = this.isVirtual ? `${Conf.BaseApi}messages/getSince/${this.sensorId}/${dateMinusDays}` : `${Conf.BaseApi}messages/getComputed/since/${this.DevEUI}/${dateMinusDays}`;
    getRequest(url, (data) => {
      if (data.success)
        this.setState({ DeviceMessages: data.result });
      this.sortChartMessages(30);
    });
    if (this.isVirtual)
      url = Conf.BaseApi + `virtualDevices/getFullInfraPath/` + this.sensorId;
    else
      url = Conf.BaseApi + `devices/getFullInfraPath/` + this.DevEUI;
    getRequest(url, (data) => {
      if (data.success)
        this.setState({ Building: data.result.building, Floor: data.result.floor, Room: data.result.room });
    });
  }

  toggleHiddable(hiddable) {
    $('#' + hiddable).toggleClass('div-visible');
  }

  renderJson(json) {
    var toRender = [];
    for (var key in json) {
      var attrName = key;
      var attrValue = json[key];
      toRender.push(<span key={key}><b>{this.t(`SensorData.${attrName}`)}</b>: {attrValue} |</span>);
    }
    return toRender;
  }

  renderDeviceMessages() {
    var toRender = [];
    for (var i = 10 * this.state.ActualPage; i < (10 * this.state.ActualPage + 10); i++) {
      if (i >= this.state.DeviceMessages.length)
        break;
      var d = new Date(this.state.DeviceMessages[i].time * 1000);
      var tmplabel = d.getDate() + '/' + ((d.getMonth() > 9) ? d.getMonth() + 1 : '0' + (d.getMonth() + 1)) + '/' + d.getFullYear() + ' ' + d.getHours() + 'h' + ((d.getMinutes() > 9) ? d.getMinutes() : '0' + d.getMinutes());

      toRender.push(
        <div className="descCustom" key={i}>
          <span className="title">{tmplabel}&nbsp;&nbsp;
              {this.renderJson(this.state.DeviceMessages[i].data)}</span>
        </div>);
    }
    return toRender;
  }

  changeMsgTableKey() {
    this.setState({MsgKey: this.state.MsgKey + 1})
  }

  renderDevicesDetail() {

    let buffer = null;
    if (this.state.DeviceDetails.pic)
      buffer = new Buffer(this.state.DeviceDetails.pic);

    var toRender = [];
    if (this.state.DeviceDetails != undefined) {
      toRender.push(
        <div className="row" key={this.state.DeviceDetails.serial}>
          <div className="col-md-4 col-lg-1">
            <img src={buffer ? buffer.toString('utf8') : `img/sensorTypes/${this.state.DeviceDetails.model ? this.state.DeviceDetails.model.replace('/', '') : ''}.png`} width='90%' alt="icon" />
          </div>
          {this.state.DeviceDetails.usage !== undefined ? <div className="col-lg-6">
            <div className="descCustom">
              <div className="title">
              {this.state.DeviceDetails.alertIfUnseen && this.state.DeviceDetails.usersToMail.length > 0 ? 
                <img src="img/camera.png" width="15px" style={{marginTop: "-10px", marginRight: "5px"}} /> 
              : '' }
                {this.state.DeviceDetails.usage}
              </div>

        {this.state.DeviceDetails.alertIfUnseen && this.state.DeviceDetails.usersToMail.length > 0 ? 
          this.state.DeviceDetails.usersToMail.map(u => <li>{u}</li>)
          : '' }
            </div>
          </div> : ""}
          <div className="col-md-4 col-lg-2">
            <div className="descCustom">
              <div className="title">{this.t('Status')}</div>
              <small><span className={(this.state.DeviceDetails.status === 'ACTIF') ? 'badge badge-success' : 'badge badge-default'}>
                {this.state.DeviceDetails.status !== undefined ? this.t(this.state.DeviceDetails.status) : ''}
              </span></small>
            </div>
          </div>
          {this.state.DeviceDetails.lastSeen ?
            <div className="col-md-4 col-lg-2">
              <div className="descCustom">
                <div className="title">{this.t('Message.Time')}</div>
                <small>{getDateStr(new Date(this.state.DeviceDetails.lastSeen).getTime())}</small>
              </div>
            </div> : ''}

        <div className="col-lg-12">
          <div className="row">
            { this.state.Building ? 
            <div className="col-md-6 col-lg-3">
                <div className="descCustom">
                  <div className="title">{this.t('Infrastructure')}</div>
                  <small>{this.state.Building.name}</small>
                </div>
              </div> : ''}
            { this.state.Floor ? 
            <div className="col-md-6 col-lg-3">
                <div className="descCustom">
                  <div className="title">{this.t('Floor')}</div>
                  <small>{this.state.Floor.name}</small>
                </div>
              </div> : ''}
            { this.state.Room ? 
            <div className="col-md-6 col-lg-3">
                <div className="descCustom">
                  <div className="title">{this.t('Room')}</div>
                  <small>{this.state.Room.name}</small>
                </div>
              </div> : ''}
          </div>
        </div>


        <div className="col-lg-12">
          <div onClick={() => this.toggleHiddable('technicalDetails')} style={{cursor: 'pointer'}}>Details Techniques <b>v</b></div>
          <div id="technicalDetails" className="row hiddable">
            <div className="col-md-6 col-lg-4">
              <div className="descCustom">
                <div className="title">{this.t('Sensor.Type')}</div>

                <small>{this.state.DeviceDetails.type !== undefined ? this.state.DeviceDetails.type.join(' - ') : ""}</small>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="descCustom">
                <div className="title">{this.t('Sensor.Model')}</div>
                <small>{this.state.DeviceDetails.model !== undefined ? this.state.DeviceDetails.model : ""}</small>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="descCustom">
                <div className="title">DevEUI/SigID</div>
                <small>{this.state.DeviceDetails.DevEUI !== undefined ? this.state.DeviceDetails.DevEUI : ""}</small>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="descCustom">
                <div className="title">AppEUI/PAC</div>
                <small>{this.state.DeviceDetails.AppEUI !== undefined ? this.state.DeviceDetails.AppEUI : ""}</small>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="descCustom">
                <div className="title">AppKey</div>
                <small>{this.state.DeviceDetails.AppKey !== undefined ? this.state.DeviceDetails.AppKey : ""}</small>
              </div>
            </div>
            {this.state.DeviceDetails.alarmModels && this.state.DeviceDetails.alarmModels.length > 0 ?
              <div className="col-lg-6">
                <div className="descCustom">
                  <div className="title">{this.t('Model.Model')}</div>
                  <small>{this.renderAlarmModels(this.state.DeviceDetails.alarmModels)}</small>
                </div>
              </div> : ''}
          </div>
        </div>
          {
          this.state.DeviceDetails.customFields && this.state.DeviceDetails.customFields.length > 0 ?
            <div className="col-lg-12">
              <div className="descCustom">
                <div className="title">{this.t('Sensor.Customs')}</div>
                {this.renderCustoms(this.state.DeviceDetails.customFields)}
              </div>
            </div> : ''
        }
        </div >);
    }
    return toRender;
  }

  renderCustoms(customs) {
    var toRender = [];
    if (customs != undefined) {
      for (let i = 0; i < customs.length; i += 1) {
        const custom = customs[i];
        toRender.push(
          <li>
            <small>{custom.fieldId} -> {custom.label}({custom.unit}) - [{custom.initValue} + {custom.increment}]</small>
          </li>
        );
      }
    }
    return toRender;
  }

  renderAlarmModels(models) {
    var toRender = [];
    if (models != undefined) {
      for (let i = 0; i < models.length; i += 1) {
        toRender.push(
          <div>
            {models[i].name}<small>{models[i].desc}</small>
          </div>
        );
      }
    }
    return toRender;
  }

  changePageCount(changePage) {
    var actPage = this.state.ActualPage + changePage;
    if (actPage < 0 || actPage > this.state.DeviceMessages.length / 10)
      return;
    this.setState({ ActualPage: actPage });
  }

  cleanMessage() {
    const pwd = window.prompt(`You will not be able to undo this action.
  \nDo you still want to remove all messages?
  \nPlease confirm your choice by entering your password.`);
  if (pwd) {
      var url = Conf.BaseApi + 'messages/remove/' + this.state.DeviceDetails.DevEUI;
      postRequest(url, {pwd}, (data) => {
        if (data.success && data.success == true) {
          this.changeMsgTableKey();
        }
      });
    }
  }
  removeOneMessage(messageId) {
    var r = window.confirm(this.t('Popup.Delete.Messages'));
    if (r == true) {
      var url = Conf.BaseApi + 'messages/remove/one/' + messageId;
      getRequest(url, (data) => {
        if (data.success && data.success == true) {
          this.changeMsgTableKey();
        }
      });
    }
  }


  render() {

    return (
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header hiddableHeader" onClick={() => this.toggleHiddable('hiddableSensor')}>
              <i className="fa fa-bug"></i> {this.state.DeviceDetails && this.state.DeviceDetails.name ? this.state.DeviceDetails.name : ""}
              {  this.state.CanEdit ? 
                      <span>{' '}
                      <Button onClick={() => this.toggleDownlink()}>{this.t('Downlink')}</Button>
                      </span> : ''
                    }</div>
            <div className="card-block hiddable div-visible" id='hiddableSensor'>
              {this.renderDevicesDetail()}
            </div>
          </div>
        </div>
<div className="col-lg-12 col-xl-6">
        { this.state.DeviceDetails._id ? <Row>
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header hiddableHeader" onClick={() => this.toggleHiddable('hiddableEvent')}>
              <i className="fa fa-envelope-o"></i> {this.t('Events')}
            </div>
            <div className="card-block hiddable div-visible" id='hiddableEvent'>
                <EventTable SensorId={this.state.DeviceDetails._id} />
            </div>
          </div>
        </div></Row> : '' }
        <Row>
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header">
              <i className="fa fa-envelope-o"></i> {this.t('Messages')}
              <Button onClick={this.changeMsgTableKey.bind(this)} style={{float:"right"}}><i className="icon-reload"></i></Button>
              { this.state.CanRemove ? <Button onClick={this.cleanMessage.bind(this)} style={{float:"right"}} color="danger" className="btn btn-danger"><i className="icon-trash"></i></Button>: '' }
            </div>
            <div className="card-block">
              {this.state.DeviceDetails.model || this.isVirtual ?
                <MessagesTable  isVirtual={this.isVirtual}
                                sensorId={this.sensorId} 
                                DevEUI={this.DevEUI} 
                                model={this.state.DeviceDetails.model} 
                                key={this.state.MsgKey} 
                                removeOne={this.removeOneMessage.bind(this)}/> : ''}
            </div>
          </div>
        </div></Row>
        { this.state.DeviceDetails._id ? <Row>
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header">
              <i className="fa fa-envelope-o"></i> {this.t('Alerts')}</div>
            <div className="card-block">
              <AlertTable additionnalFilter={[{id: "sensor", value: this.state.DeviceDetails._id}]}/>
            </div>
          </div>
        </div></Row> : '' }
        {this.state.PlotPredictions && this.state.PlotPredictions !== undefined && this.state.PlotPredictions.original !== undefined ?

<Row>
<div className="col-lg-12">
  <div className="card">
    <div className="card-header hiddableHeader" onClick={() => this.toggleHiddable('hiddableML')}>
      <i className="fa fa-star-o"></i> {this.t('MachineLearning')}
    </div>
    <div className="card-block hiddable div-visible" id="hiddableML">
      <PlotPredictionsCharts predictions={this.state.PlotPredictions} />
    </div>
  </div>
</div></Row>
: ''}
</div>

        {this.getStatistics(this.state.ChartMessages)}

        <Modal isOpen={this.state.ModalDownlink} className={'modal-md ' + this.props.className}>
                    <ModalHeader>
                        <Row style={{paddingLeft:'15px', paddingRight:'15px'}}>
                            <h1>{this.t('Send')}</h1>
                            <i className="icon-close color-danger" onClick={this.toggleDownlink.bind(this)}
                                style={{position:'absolute', textAlign: 'right', right:'15px', cursor:'pointer'}}></i>
                        </Row>
                    </ModalHeader>
           <SendDownlink sensors={[this.state.DeviceDetails.DevEUI]} callback={this.toggleDownlink}/>
        </Modal>
        
      </div>
    );
  }
  /* Graph parts */
  getStatistics(ChartMessages) {
    if (!this.state.DeviceDetails.type && !this.isVirtual)
      return [];
    const render = [];

    if (this.isVirtual || this.state.DeviceDetails.type.includes("TEMPERATURE")) {
      render.push(this.renderTemperatureStatistics(ChartMessages));
    }
    return (<div className="col-lg-12 col-xl-6">{render}</div>);
  }

  renderTemperatureStatistics(ChartMessages) {
    var toRender = [];
    toRender.push(
      <div className="card">
        <div className="card-header">
          <div className="row">

            <div className="col-lg-3">
              <select className="form-control" onChange={e => {
                this.getMessagesSince(this.isVirtual ? this.sensorId : this.DevEUI, e.target.value);
              }}>
                <option value={1}>1 {this.t('Days')}</option>
                <option value={7}>7 {this.t('Days')}</option>
                <option value={30}>1 {this.t('Months')}</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    );
    if (!ChartMessages || ChartMessages.length === 0) return toRender;

    let messageIndex = ChartMessages.findIndex(m => m.data && m.data['temperature']);
    if (this.isVirtual) messageIndex = 0;
    if (messageIndex === -1) return [];

    var allPropertyNames = Object.keys(ChartMessages[messageIndex].data);
    if (!allPropertyNames) return [];
    if (!this.isVirtual)
      allPropertyNames = allPropertyNames.filter(d => exists(`SensorData.${d}`));
    var colors = ["#09d7c2", "#FF0042", "#20a8d8", "#FF7A00"];
    var j = 0;

    for (var i = 0; i < allPropertyNames.length; i++) {
      if (ChartMessages[0].data[allPropertyNames[i]] > 1 || ChartMessages[0].data[allPropertyNames[i]] < 0) {
        toRender.push(
          <div className="col-lg-12">
            <div className="row">
              <div className="col-lg-12" key={this.state.Days + i}>
                <LineChats isLocked={true} isPublic={false} component={{ days: this.state.Days }}
                  chartDatas={[{
                    sensor: `${this.isVirtual ? this.sensorId : this.state.DeviceDetails.DevEUI}`,
                    paramName: `${allPropertyNames[i]}`,
                    color: colors[j % 4]
                  }]} messages={{ [this.isVirtual ? this.sensorId : this.DevEUI]: ChartMessages }}
                  subtitle={this.t(`SensorData.${allPropertyNames[i]}`)}
                  unit={""}
                  noReport={true}
                  noButton={false} />
              </div>
            </div>
          </div>
        );
        j++;
      }
    }
    return (toRender);
  }

  getMessagesSince(id, since) {
    const dateDays = since * 24 * 60 * 60; // in seconds
    const dateMinusDays = Math.floor(Date.now() / 1000) - dateDays;
    var url = Conf.BaseApi + `messages/getComputed/since/${id}/${dateMinusDays}`;
    return getRequest(url, (body) => {
      if (body.success) {
        const messages = body.result;
        messages.sort((a, b) => {
          if (a.time > b.time)
            return 1;
          if (a.time < b.time)
            return -1;
          return 0;
        });
        this.setState({ ChartMessages: messages, Days: since });
      }
    });
  }
}

export default translate(Details);