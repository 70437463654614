import React, { Component } from 'react';
import $ from 'jquery';
import { getRequest, postRequest } from '../../../utils/WebServicesManager';
import SelectUser from '../../../components/Selects/SelectUser';
import Conf from '../../../utils/Conf';
import { translate } from '../../../utils/ReactMultiLang';
import { Row, Col, Modal, Button } from 'reactstrap';
import SelectAlarmModels from '../../../components/Selects/SelectAlarmModel';
import SensorSelector from '../../../components/Modals/SensorSelector';
import Select from 'react-select';

class delivryForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      UserGroups: [],
      SelectedSensor: props.delivry ? props.delivry.sensor : {},
      groupKey: 0,
      Modal: false,
      usersToSend: props.delivry !== undefined && props.delivry.toSend && props.delivry.toSend.length > 0
        ? props.delivry.toSend.map(t => ({ label: t, value: t }))
        : undefined,
      sendReport: props.delivry !== undefined && props.delivry.reportTemplate && props.delivry.reportTemplate !== undefined ? true : false,
      selectedAlarm: undefined,
      selectedGroup: props.delivry !== undefined ? props.delivry.group : undefined
    };
    this.t = props.t;
    this.getUserGroups();
  }
  reportTemplateChange() {
    this.setState({ sendReport: $("#reportTemplate").is(':checked') });
  }

  getUserGroups() {
    var url = Conf.BaseApi + 'groups/getAll';
    return getRequest(url, (data) => {
      if (data.success) {
        this.setState({
          UserGroups: data.result,
          selectedGroup: data.result[0] && !this.state.selectedGroup ? data.result[0]._id : this.state.selectedGroup,
          groupKey: this.state.groupKey + 1
        });
      }
    });
  }

  updateUserToSend(users) {
    this.setState({ usersToSend: users });
  }

  toggle() {
    this.setState({ Modal: !this.state.Modal });
  }

  sensorSelected(sensors) {
    if (sensors.length > 0) {
      this.setState({ SelectedSensor: sensors[0] });
      this.toggle();
    }
  }

  createdelivry(callback) {
    var url = Conf.BaseApi + 'deliveryRounds/add';

    let user = [];
    let inputs = $("input[name=manager]");
    for (let i = 0; i < inputs.length; i += 1) {
      user.push(inputs[i].value);
    }
    let alarm = [];
    let inputsAlarms = $("input[name=alarms]");
    for (let i = 0; i < inputsAlarms.length; i += 1) {
      alarm.push(inputsAlarms[i].value);
    }

    let usersToSend = [];
    let inputsUsers = $("input[name=usersToSend]");
    for (let i = 0; i < inputsUsers.length; i += 1) {
      usersToSend.push(inputsUsers[i].value);
    }

    const delivry = {
      name: $("#delivryName").val(),
      reportTemplate: $('#reportTemplate').is(':checked') ? '62b58e475797fbf26a5a7a63' : undefined,
      toSend: usersToSend,
      toDelete: $('#deleteReport').is(':checked'),
      group: $("#userGroup").val(),
      alarm: alarm[0],
      sensor: this.state.SelectedSensor._id,
      manager: user[0],
    };
    return postRequest(url, delivry, (data) => {
      if (data.success || data.success == true) {
        callback();
      } else {
        $("#error-window").text(data.message);
      }
    });
  }

  editdelivry(delivry, callback) {
    var url = Conf.BaseApi + 'deliveryRounds/edit';

    let user = [];
    let inputs = $("input[name=manager]");
    for (let i = 0; i < inputs.length; i += 1) {
      user.push(inputs[i].value);
    }
    let alarm = [];
    let inputsAlarms = $("input[name=alarms]");
    for (let i = 0; i < inputsAlarms.length; i += 1) {
      alarm.push(inputsAlarms[i].value);
    }

    let usersToSend = [];
    let inputsUsers = $("input[name=usersToSend]");
    for (let i = 0; i < inputsUsers.length; i += 1) {
      usersToSend.push(inputsUsers[i].value);
    }

    const editedDelivry = {
      _id: delivry._id,
      name: $("#delivryName").val(),
      reportTemplate: $('#reportTemplate').is(':checked') ? '62b58e475797fbf26a5a7a63' : undefined,
      toSend: usersToSend,
      toDelete: $('#deleteReport').is(':checked'),
      group: $("#userGroup").val(),
      alarm: alarm[0],
      sensor: this.state.SelectedSensor._id,
      manager: user[0],
    };
    return postRequest(url, editedDelivry, (data) => {
      if (data.success || data.success == true) {
        callback();
      } else {
        $("#error-window").text(data.message);
      }
    });
  }


  renderUserGroup(UserGroups, delivry) {
    var toRender = [];

    for (var i = 0; i < UserGroups.length; i++) {
      if (i === 0 && !delivry) {
        toRender.push(
          <option key={i} value={UserGroups[i]._id} selected="selected">{UserGroups[i].name}({UserGroups[i].provider})</option>
        );
      } else {
        toRender.push(
          <option key={i} value={UserGroups[i]._id} selected={delivry !== undefined && delivry.group !== undefined && delivry.group._id == UserGroups[i]._id ? "selected" : ""}>{UserGroups[i].name}({UserGroups[i].provider})</option>
        );
      }
    }
    return toRender;
  }
  render() {
    const { delivry, callback } = this.props;

    return (
      <div className="flex-row align-items-center">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12">
              <div className="card mx-12">
                <div className="card-block p-12">
                  <div className="input-group mb-3" style={{ color: "red" }} id="error-window">

                  </div>
                  <div className="input-group mb-3">
                    <span className="input-group-addon"><i className="icon-user"></i></span>
                    <input type="text" className="form-control" placeholder={this.t('Delivry.Name')} id="delivryName" defaultValue={delivry !== undefined ? delivry.name : ''} />
                  </div>
                  <div className="form-group mb-3">
                    <label htmlFor="userGroup">{this.t('UserGroup')}</label>
                    <select className="form-control" key={this.state.groupKey} onChange={e => this.setState({ selectedGroup: e.target.value })} id="userGroup" >
                      {this.renderUserGroup(this.state.UserGroups, delivry)}
                    </select>
                  </div>
                  {this.t('Delivry.Manager')} :
                  <SelectUser scopeParam="linked" selectId='manager'
                    values={delivry !== undefined && delivry.manager ? [delivry.manager] : undefined} />

                  <div className="input-group mb-3">
                    <div className="form-check form-check-inline">
                      <label className="form-check-label">
                        <input className="form-check-input" type="checkbox" id="reportTemplate" value="true" onChange={() => this.reportTemplateChange()}
                          defaultChecked={delivry !== undefined && delivry.reportTemplate && delivry.reportTemplate !== undefined ? true : false} />
                        {' '}{this.t('Delivry.linkTemplate')}
                      </label>
                    </div>
                  </div>
                  {
                    this.state.sendReport ?
                      <Row>
                        <Col md='12'>
                          {this.t('Delivry.toSend')} :
                          <Select.Creatable
                            multi={true}
                            value={this.state.usersToSend}
                            onChange={this.updateUserToSend.bind(this)}
                            backspaceRemoves={this.state.backspaceRemoves}
                            id="usersToSend"
                            name="usersToSend"
                          />
                        </Col>
                      </Row> : ''}

                  <label htmlFor="alarmModel">Alarme</label>
                  <SelectAlarmModels
                    multi={false}
                    entityName='alarms'
                    value={delivry !== undefined ? delivry.alarm._id : ""} />


                  <div className="form-group mb-3">
                    <div><label htmlFor="createCpSensor">{this.t('Sensor.Sensor')}</label></div>
                    <b>{this.state.SelectedSensor.name ? this.state.SelectedSensor.name : this.t(`Sensor.NoSensor`)}{' '}</b>
                    <Button className="btn-secondary" onClick={() => this.toggle()}>{this.t(`Sensor.Change`)}</Button>
                  </div>
                  <div className="input-group mb-3">
                    <div className="form-check form-check-inline">
                      <label className="form-check-label">
                        <input className="form-check-input" type="checkbox" id="deleteReport" value="true"
                          defaultChecked={delivry !== undefined && delivry.toDelete ? true : false} />
                        {' '}{this.t('Delivry.toDelete')}
                      </label>
                    </div>
                  </div>

                  <button type="button" className="btn btn-block btn-success"
                    onClick={delivry === undefined ? this.createdelivry.bind(this, callback) : this.editdelivry.bind(this, delivry, callback)}>
                    {delivry !== undefined ? this.t('Edit') : this.t('Add')}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <Modal isOpen={this.state.Modal} className={'modal-lg ' + this.props.className}>
            <SensorSelector callbackAddSensor={this.sensorSelected.bind(this)} />
          </Modal>
        </div>
      </div>)
  }
}

export default translate(delivryForm);