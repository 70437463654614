import React, { Component } from 'react'
import { Map, TileLayer, Tooltip, Circle, Polyline } from 'react-leaflet';
import Conf from '../../../utils/Conf';
import { getRequest, postRequest } from '../../../utils/WebServicesManager';
import { DMStoDegree, getDistance } from '../../../utils/Utils';
import Control from './CustomControl';
import { translate } from '../../../utils/ReactMultiLang';
import { Button } from 'reactstrap';
import HeatmapLayer from 'react-leaflet-heatmap-layer';
import SensorMarker from './SensorMarker';

class GPSMap extends Component {
    constructor(props) {
      super(props);
      this.sensors = [];
        this.state = {
            readyToRender: props.readyToRender || false,
            hiddenSensors: [],
            latlng: [46.16883, 6.11083],
            zoom: 12,
            DataSets: props.DataSets,
            Messages: props.Messages,
            Sensors: props.Sensors,
            Messages: {},
            networks: props.networks,
            Key: 0,
            ShowRange: props.showRange
        }
        this.colorList = [
            '#f00',
            '#2B2',
            '#00f',
            '#880',
            '#088'
        ];
        this.txtColorList = [
            'red',
            'green',
            'blue',
            'orange',
            'yellow',
            'purple'
        ];
        if (props.DataSets !== undefined) {
            this.createPlotLine(props.DataSets);
        }
        if (props.Messages !== undefined) {
            this.createPlotLineFromMessages(props.Messages, props.Sensors);
        }
        this.t = props.t;
    }
    componentWillReceiveProps(nextProps) {
        if (this.props.DataSets !== nextProps.DataSets) {
            if (nextProps.DataSets) {
                this.createPlotLine(nextProps.DataSets);
                this.setState({
                    DataSets: nextProps.DataSets
                });
            }
        }
        if (this.props.Messages !== nextProps.Messages) {
            if (nextProps.Messages) {
                this.createPlotLineFromMessages(nextProps.Messages, nextProps.Sensors);
                if (!this.state.networks) {
                    const [latArr, lngArr] = this.getLatLngArrays(nextProps.Messages);
                    const minLat = Math.min(...latArr);
                    const maxLat = Math.max(...latArr);
                    const minLng = Math.min(...lngArr);
                    const maxLng = Math.max(...lngArr);
                    const centerLat = (minLat + maxLat) / 2;
                    const centerLng = (minLng + maxLng) / 2;
                    let distance = getDistance([minLat, minLng], [maxLat,maxLng]); 
                    let zoom = 12;
                    if (distance < 1) {
                        zoom = 15;
                    } else if (distance < 10) {
                        zoom = 13;
                    } else if (distance < 50) {
                        zoom = 11;
                    } else if (distance < 100) {
                        zoom = 9;
                    } else if (distance < 250) {
                        zoom = 7;
                    } else if (distance < 500) {
                        zoom = 5;
                    } else if (distance < 1000) {
                        zoom = 3;
                    } else {
                        zoom = 1;
                    }
                    this.setState({
                        Messages: nextProps.Messages,
                        Sensors: nextProps.Sensors,
                        latlng: [centerLat ? centerLat : 46.16883, centerLng ? centerLng : 6.11083],
                        zoom,
                        Key: nextProps.Key
                    });
                } else {
                    this.setState({
                        Messages: nextProps.Messages,
                        Sensors: nextProps.Sensors,
                        Key: nextProps.Key
                    });
                }
            }
        }
        if (this.props.Sensors !== nextProps.Sensors) {
                this.setState({
                    Sensors: nextProps.Sensors
                });
        }
        if (this.props.showRange !== nextProps.showRange) {
                this.setState({
                    ShowRange: nextProps.showRange
                });
        }
        if (this.props.readyToRender !== nextProps.readyToRender) {
            if (nextProps.readyToRender) {
                this.setState({
                    readyToRender: nextProps.readyToRender
                });
            }
        }
      } 
    
    getLatLngArrays(msgList) {
        const latArray = [];
        const lngArray = [];
        const DevEUIList = Object.keys(msgList);

        for (let i = 0; i < DevEUIList.length; i += 1) {
            for (let j = 0; j < msgList[DevEUIList[i]].length; j += 1) {
                const msg = msgList[DevEUIList[i]][j];
                if (msg.data.lat && msg.data.lng) {
                    latArray.push(parseFloat(msg.data.lat));
                    lngArray.push(parseFloat(msg.data.lng));
                }
            }
        }
        return [latArray, lngArray]
    }

    createPlotLine(dataSets) {
        this.plotLines = [];
        for (let i = 0; i < dataSets.length; i += 1) {
            const dataSet = dataSets[i].datas;
            const line = [];
            if (dataSet) {
                for (let j = 0; j < dataSet.length; j += 1) {
                    if (dataSet[j].data.lat !== undefined) {
                        const lat = DMStoDegree(dataSet[j].data.lat, true);
                        const lng = DMStoDegree(dataSet[j].data.lng, false);
                        if (lat !== 0 && lng !== 0) line.push([lat, lng]);
                    }
                }
                this.plotLines.push(line);
            }
        }
    }

    createPlotLineFromMessages(Messages, Sensors) {
      if (Messages && this.state.networks) {
        this.plotLines = [];
        this.heatPoints = [];
        this.sensors = [];
        const keys = Object.keys(Messages);
        for (let i = 0; i < keys.length; i += 1) {
            const dataSet = Messages[keys[i]];
            const line = [];
            const heatPoint = [];
            for (let j = 0; j < dataSet.length; j += 1) {
                if (dataSet[j].data.lat !== undefined) {
                    const lat = DMStoDegree(dataSet[j].data.lat, true)  - 0.0015;
                    const lng = DMStoDegree(dataSet[j].data.lng, false) - 0.0035;
                    if (lat !== 0 && lng !== 0) {
                        if (line.find((elem) => {
                            return (elem[0] > lat - 0.001 && elem[0] < lat + 0.001) && (elem[1] > lng - 0.001 && elem[1] < lng + 0.001)
                        }) === undefined) {
                            line.push([lat, lng]);
                            if (dataSet[j].data.snr) {
                                heatPoint.push([lat, lng, dataSet[j].data.snr]);
                            } else {
                                heatPoint.push([lat, lng, 150]);
                            }
                        }
                    }
                }
            }
            this.plotLines.push(line);
            this.heatPoints.push(heatPoint);
            this.sensors.push(Sensors.find(sensor => sensor.DevEUI === keys[i]));
        }
      }
    }
    getRandomArbitrary(min, max) {
        return Math.random() * (max - min) + min;
      }
    componentDidMount() {
        setTimeout(() => {
            if (this.refs.leafletMap) {
                this.refs.leafletMap.leafletElement.invalidateSize();
            }
        }, 100);
    }
   
    switchPath(DevEUI) {
        const hidden = this.state.hiddenSensors;
        const idx = hidden.indexOf(DevEUI);
        if (idx === -1) {
            hidden.push(DevEUI);
        } else {
            hidden.splice(idx, 1);
        }
        this.setState({hiddenSensors: hidden});
    }

    render() {
        const { isHeatmap } = this.props;

        const gradient = {
            0.1: '#89BDE0', 0.2: '#96E3E6', 0.4: '#82CEB6',
            0.6: '#f2e54e', 0.8: '#eabe43', '1.0': '#ea4036'
      };
        return (
            <div className="report-map" key={this.state.Key}>
                {this.state.readyToRender &&
                <Map
                    className="report-map"
                    center={this.state.latlng}
                    zoom={this.state.zoom}
                    zoomControl={true}
                    doubleClickZoom={false}
                    ref='leafletMap'>
                    
                    <TileLayer
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    { this.sensors.length !== 0 ?
                        isHeatmap && this.state.DataSets === undefined ? 
                            this.sensors.map((sensor, idx) => {
                                if (!this.state.hiddenSensors.some(sen => sen === sensor.DevEUI)) {
                                    return (<HeatmapLayer
                                        points={this.heatPoints[idx]}
                                        longitudeExtractor={m => m[1]}
                                        latitudeExtractor={m => m[0]}
                                        gradient={gradient}
                                        intensityExtractor={m => -1 * parseFloat(m[2]) + 200}
                                        radius={Number(10)}
                                        blur={Number(8)}
                                        max={Number.parseFloat(2)}
                                    />)
                                }
                            })
                        :
                        this.sensors.map((sensor, idx) => {
                            if (!this.state.hiddenSensors.some(sen => sen === sensor.DevEUI)) {
                                return (
                                    <div>
                                    {this.plotLines[idx].map((plot, idx2) => {
                                    return (<Circle color={this.colorList[idx]} center={plot} radius={10} key={sensor.DevEUI + idx2 } />)
                                })}</div>)
                            }
                        }) : this.state.Sensors.map((sensor, idx) => {
                        return <SensorMarker idx={idx} color={this.txtColorList[idx % 6]} showRange={this.state.ShowRange} sensor={sensor} messages={this.state.Messages[sensor.DevEUI] ? this.state.Messages[sensor.DevEUI] : {}} />
                        })
                    }
                <Control position={"topright"} style={{backgroundColor: 'white', padding:'10px', zIndex: 99999,  right: '0',
position: "absolute" }} >
                    { this.sensors !== undefined && this.sensors.length > 1 &&
                      this.sensors.map((sensor, idx) => {
                        
                        if (!this.state.hiddenSensors.some(sen => sen === sensor.DevEUI)) {
                            return (<Button onClick={this.switchPath.bind(this, sensor.DevEUI)} key={idx}>
                            <div style={{display: 'inline-block', marginRight:'20px'}}>
                                <div style={{display: 'inline-block', height: '20px', width: '50px', backgroundColor: this.colorList[idx]}}></div> {sensor.name}</div>
                                </Button>
                            );
                        } else {
                            return (<Button onClick={this.switchPath.bind(this, sensor.DevEUI)} key={idx}>
                                <div style={{display: 'inline-block', marginRight:'20px'}}>
                                <div style={{display: 'inline-block', height: '20px', width: '50px', backgroundColor: '#9f9f9f'}}></div> <s>{sensor.name}</s></div>
                            </Button>
                            );
                        }
                      })
                    }
                </Control>
                </Map>}
            </div>
        )
    }
}

export default translate(GPSMap);
/*
                    
                    {
                        this.state.DataSets === undefined ? 
                            this.sensors.map((sensor, idx) => {
                                if (!this.state.hiddenSensors.some(sen => sen === sensor.DevEUI)) {
                                    return (
                                        <div>
                                        <Polyline color={this.colorList[idx]} positions={this.plotLines[idx]} key={sensor.DevEUI} />
                                        {this.plotLines[idx].map((plot, idx2) => {
                                        return (<Circle color={this.colorList[idx]} center={plot} radius={20} key={sensor.DevEUI + idx2 } />)
                                    })}</div>)
                                }
                            })
                        :
                        this.state.DataSets.map((dataset, idx) => {
                            return (this.plotLines[idx].map((plot) => {
                                return (<Circle color={dataset.color} center={plot} radius={20} key={dataset._id + plot[0]}/>)
                            }))
                        })
                    } */
// "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
// "https://maps.wikimedia.org/osm-intl/{z}/{x}/{y}.png"