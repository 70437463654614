import React, { Component } from 'react';
import Conf from '../../../utils/Conf';
import { Radar } from 'react-chartjs-2';
import { SortPredictions, Accuracy, Precision, Recall, F1Score, Confidence } from '../../../utils/Utils';

class PredictionsRadar extends Component {
    constructor(props) {
        super(props);
        this.datas = {};
        this.options = {};
    }

    getPredictionList(predictions) {
        const sortedPrec = SortPredictions(predictions, 2, 0.5);
        const accPrec = Accuracy(sortedPrec);
        const precPrec = Precision(sortedPrec);
        const recallPrec = Recall(sortedPrec);
        const f1ScorePrec = F1Score(precPrec, recallPrec);
        const confiPrec = Confidence(predictions, 2, 0.5);

        const sortedLarge = SortPredictions(predictions, 4, 5);
        const accLarge = Accuracy(sortedLarge);
        const precLarge = Precision(sortedLarge);
        const recallLarge = Recall(sortedLarge);
        const f1ScoreLarge = F1Score(precLarge, recallLarge);
        const confiLarge = Confidence(predictions, 4, 5);
        this.datas = {
            labels: ['Accuracy', 'Precision', 'Recall', 'F1-Score', 'Confidence'],
            datasets: [
              {
                label:"Extrem",
                backgroundColor: 'rgba(179,181,198,0.2)',
                borderColor: 'rgba(179,181,198,1)',
                pointBackgroundColor: 'rgba(179,181,198,1)',
                pointBorderColor: '#fff',
                pointHoverBackgroundColor: '#fff',
                pointHoverBorderColor: 'rgba(179,181,198,1)',
                data: [accLarge, precLarge, recallLarge, f1ScoreLarge, confiLarge]
              },
              {
                label:"Close",
                backgroundColor: 'rgba(240,0,0,0.2)',
                borderColor: 'rgba(240,0,0,1)',
                pointBackgroundColor: 'rgba(240,0,0,1)',
                pointBorderColor: '#fff',
                pointHoverBackgroundColor: '#fff',
                pointHoverBorderColor: 'rgba(179,181,198,1)',
                data: [accPrec, precPrec, recallPrec, f1ScorePrec, confiPrec]
              }
            ]
          };
        this.options = {
            legend: {
                display: false
            },
            scale: {
                ticks: {
                    beginAtZero: true,
                    max: 10
                },
                pointLabels: {
                    fontSize: 15
                  }
            }
        };
    }

    render() {
        
        const { predictions, subtitle } = this.props;
        this.getPredictionList(predictions);
        return (
                <div className={"card animated fadeIn "}>
                    <div className="card-block pb-0">
                        <h4>{subtitle}</h4>
                    </div>
                    <div className="chart-wrapper px-3" style={{ height: "350px"}}>
                        <Radar data={this.datas} options={this.options}
                        />
                    </div>
                <ul>
                  <li><b>Accuracy:</b> Accuracy answers the following question: How many students did we correctly label out of all the students?</li>
                  <li><b>Precision:</b> Precision answers the following: How many of those who we labeled as diabetic are actually diabetic?</li>
                  <li><b>Recall:</b> Recall answers the following question: Of all the people who are diabetic, how many of those we correctly predict?</li>
                  <li><b>F1-Score:</b> It is the harmonic mean(average) of the precision and recall.</li>
                  <li><b>Confidence:</b> Probability to get a good prediction for the next value.</li>
                </ul>
                </div>
        );
    }
}

export default PredictionsRadar;
