function DataHelpers() {}


/*
** Sort message by hours
** return an array of message [time, message]
** time : string : YYYYmmddhh
*/
DataHelpers.sortMessagesByHours = function (messages) {
    var finaleList = [];
    var tmpList = [];
    for (var i = 0; i < messages.length; i++) {
        var d = new Date(messages[i].time * 1000);
        tmpList.push([d.getFullYear() + (d.getMonth() < 9 ? '0' : '') + (d.getMonth() + 1) + (d.getDate() < 10 ? '0' : '') + d.getDate() + (d.getHours() < 10 ? '0' : '') +  d.getHours(), messages[i]]);
    }
    tmpList.sort(DataHelpers.compareTime);
    return tmpList;
};

DataHelpers.compareTime = function(a, b) {
    if (a[0] < b[0])
        return -1;
    if (a[0] > b[0])
        return 1;
    // a doit être égal à b
    return 0;
};

/*
** Get data and agregate by hours
** take the min vand the max value return the difference by hours
*/
DataHelpers.getNumberOfDataByHours = function(messages, paramName) {
    var myMap = new Map();
    for (var i = 0; i < messages.length; i++) {
        if (messages[i][1].data[paramName] == undefined)
            continue;
        var time = parseInt(messages[i][0]);
        if (myMap.get(time) != undefined) {
            var minMax = myMap.get(time);
            if (messages[i][1].data[paramName] < minMax[0])
                minMax[0] = messages[i][1].data[paramName];
            else if (messages[i][1].data[paramName] > minMax[1])
                minMax[1] = messages[i][1].data[paramName];
            myMap.set(time, minMax);
        } else {
            myMap.set(time, [messages[i][1].data[paramName], messages[i][1].data[paramName]]);
        }
    }
    var retArray = [];
    for (var [key, value] of myMap.entries()) {
        var val = value[1] - value[0];
        retArray.push([key, val]);
    }
    return retArray;
};

/*
** Agregate message by hoursand return an average of data by hours of a day
** need array [time, data]
** time : int : YYYYmmddhh
*/
DataHelpers.averageHoursDataNumber = function(messageSorted) {
    var averageByHour = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    var nbMessagesByHour = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    for (var i = 0; i < messageSorted.length; i++) {
        var hour = messageSorted[i][0] % 100;
        averageByHour[hour] += messageSorted[i][1];
        nbMessagesByHour[hour]++;
    }
    for (var i = 0; i < averageByHour.length; i++) {
        if (nbMessagesByHour[i] != 0)
            averageByHour[i] /=  nbMessagesByHour[i];
        averageByHour[i] = Math.round(averageByHour[i] * 10) / 10;
    }
    return (averageByHour);
};

export default DataHelpers;