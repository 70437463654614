import React, { Component } from 'react';
import moment from 'moment';
import $ from 'jquery';
import { TimePicker } from 'react-input-moment';
import Conf from '../../../utils/Conf';
import { getRequest, postRequest } from '../../../utils/WebServicesManager';
import { Button, Modal, ModalHeader, ModalBody, Row, Col } from 'reactstrap';
import SelectGroup from '../../Selects/SelectGroup/SelectGroup';
import SelectSensors from '../../Selects/SelectSensors/SelectSensors';
import SelectAlarmModels from '../../Selects/SelectAlarmModel/SelectAlarmModel';
import { translate } from '../../../utils/ReactMultiLang';
import CreateImage from '../../ReportModals/CreateImage';
import AddVirtualField from './AddVirtualFields';


class AddVirtual extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedModel: undefined,
      ModalPic: false,
      ModalField: false,
      selectedGroup: undefined,
      Key: 0,
      vFields: props.vsensor ? props.vsensor.fields : [],
      SelectedField: undefined,
      SelectedIndex: undefined
    };
    this.t = props.t;
  }

  toggleField() {
    this.setState({SelectedField: undefined, SelectedIndex: undefined, ModalField: !this.state.ModalField});
  }


  addPic(compo) {
    this.setState({ModalPic: false, Picture: compo.image});
  }

  addField(field, index) {
    const fields = this.state.vFields;
    if (index != undefined) {
      fields.splice(index, 1, field);
    } else {
      fields.push(field);
    }
    this.setState({vFields: fields, Key: this.state.Key + 1, ModalField: false});
  }

  addSensor(callback) {
    var url = Conf.BaseApi + 'virtualDevices/add';
    if (this.props.vsensor) {
      url = Conf.BaseApi + 'virtualDevices/edit';
    }
    let groups = [];
    const inputs = $("input[name=groupsName]");
    for (let i = 0; i < inputs.length; i += 1) {
      groups.push(inputs[i].value);
    }

    // Building but comment is named sensors
    let building = [];
    const inputsSensors = $("input[name=sensorsName]");
    for (let i = 0; i < inputsSensors.length; i += 1) {
      building.push(inputsSensors[i].value);
    }
    let models = [];
    const inputsModels = $("input[name=alarmModels]");
    for (let i = 0; i < inputsModels.length; i += 1) {
      models.push(inputsModels[i].value);
    }
    return postRequest(url, {
      sensorId: this.props.vsensor ? this.props.vsensor._id: undefined,
      sensorName: $("#sensorName").val(),
      sensorUsage: $("#sensorUsage").val(),
      fields: this.state.vFields,
      sensorModel: $("#sensorModel").val(),
      userGroup: $("#userGroup").val(),
      providerId: $("#provider").val(),
      sensorAppKey: $("#sensorAppKey").val(),
      sensorGroups: groups,
      pic: this.state.Picture,
      building: building[0],
      alarmModels: models
    }, (data) => {
      if (data.success || data.success == true) {
        callback();
      } else {
        $("#error-window").text(data.message);
      }
    });
  }

  renderUserGroup(UserGroups) {
    var toRender = [];
    for (var i = 0; i < UserGroups.length; i++) {
            toRender.push(
                <option key={i} value={UserGroups[i]._id}>{UserGroups[i].name}</option>
            );
    }
    return toRender;
  }

  renderSensorGroups(SensorGroups) {
    var toRender = [];
    for (var i = 0; i < SensorGroups.length; i++) {
      toRender.push(
        <option key={i} value={SensorGroups[i]}>{SensorGroups[i]}</option>
      );
    }
    return toRender;
  }

  userGroupChange(updateActGroup, UserGroups, event) {
    let grp = {};
    for (let i = 0; i < UserGroups.length; i += 1) {
        if (UserGroups[i]._id === event.target.value) {
            grp = UserGroups[i];
            break ;
        }
    }
    updateActGroup(grp);
  }

  selectModel(event) {
    this.setState({selectedModel: event.target.value});
  }
  togglePic() {
    this.setState({ModalPic: !this.state.ModalPic});
  }


  render() {
    const { UserGroups, vsensor, callback, updateActGroup, selectedGroup } = this.props;
    return (
        <ModalBody>
        <div className="flex-row align-items-center">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-12">
                <div className="card mx-12">
                  <div className="card-block p-12">
                    <div className="input-group mb-3" style={{ color: "red" }} id="error-window">

                    </div>
                    <div className="form-group mb-3">
                      <label htmlFor="sensorType">{this.t('UserGroup')}</label>
                      <select className="form-control" id="userGroup" onChange={this.userGroupChange.bind(this, updateActGroup, UserGroups)}>
                        {this.renderUserGroup(UserGroups, selectedGroup)}
                      </select>
                    </div>
                    <div className="input-group mb-3">
                      <span className="input-group-addon"><i className="icon-tag"></i></span>
                      <input type="text" className="form-control" placeholder={this.t('Name')} id="sensorName"
                      defaultValue={vsensor ? vsensor.name : ""}/>
                    </div>
                    { this.props.vsensor ? '' : <label htmlFor="userGroup">{this.t('Model.Model')}</label>}
   { this.props.vsensor ? '' : <SelectAlarmModels model={this.state.selectedModel}/> }
                    <div className="input-group mb-3">
                      <span className="input-group-addon"><i className="icon-lock"></i></span>
                      <input type="text" className="form-control" placeholder={this.t('Usage')} id="sensorUsage"
                      defaultValue={vsensor ? vsensor.usage : ""}/>
                    </div>
                    
                    <label htmlFor="userGroup">{this.t('Tags')}</label>
                            <SelectGroup entity='devices' values={vsensor && vsensor.groups ? vsensor.groups : []}/>
                        { this.props.vsensor ? '' : <label htmlFor="userGroup">{this.t('Infrastructures')}</label>}
                          { this.props.vsensor ? '' :    <SelectSensors multi={false} entity='buildings' values={vsensor && vsensor.building ? vsensor.building : []}/> }
                      {this.renderFields(this.state.vFields)}
                    <button type="button" className="btn btn-block btn-secondary" onClick={this.toggleField.bind(this)}>{this.t('Sensor.AddField')}</button>
                    <button type="button" className="btn btn-block btn-primary" onClick={this.togglePic.bind(this)}>{this.t('Sensor.AddPicture')}</button>
                    <button type="button" className="btn btn-block btn-success" onClick={this.addSensor.bind(this, callback)}>{vsensor ? this.t('Edit') : this.t('Add')}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal isOpen={this.state.ModalPic} className={'modal-md ' + this.props.className}>
                    <ModalHeader>
                        <Row style={{paddingLeft:'15px', paddingRight:'15px'}}>
                            <h1>{this.t('Component.Add')}</h1>
                            <i className="icon-close color-danger" onClick={this.togglePic.bind(this)}
                                style={{position:'absolute', textAlign: 'right', right:'15px', cursor:'pointer'}}></i>
                        </Row>
                    </ModalHeader>
          <ModalBody> 
            <CreateImage callback={this.addPic.bind(this)} /> 
          </ModalBody>
        </Modal>
        <Modal isOpen={this.state.ModalField} className={'modal-md ' + this.props.className}>
                    <ModalHeader>
                        <Row style={{paddingLeft:'15px', paddingRight:'15px'}}>
                            <h1>{this.t('Component.Add')}</h1>
                            <i className="icon-close color-danger" onClick={this.toggleField.bind(this)}
                                style={{position:'absolute', textAlign: 'right', right:'15px', cursor:'pointer'}}></i>
                        </Row>
                    </ModalHeader>
          <ModalBody> 
            <AddVirtualField callback={this.addField.bind(this)}
              userGroup={this.state.selectedGroup}
              field={this.state.SelectedField}
              index={this.state.SelectedIndex}/> 
          </ModalBody>
        </Modal>
      </ModalBody>
    )
  }


  renderFields(fields) {
    const toRender = [];

    if (!fields) return toRender;
    return (
      <ul>
        {fields.map((f, index) => (
          <li key={`item-${index}`}>
            {f.name}({f.unit})
            <button onClick={this.editField.bind(this, f, index)}  type="button" className="btn btn-link text-success"><i className="icon-pencil"></i></button>
            <button onClick={this.deleteField.bind(this, index)}  type="button" className="btn btn-link text-danger"><i className="icon-close"></i></button>
          </li>
        ))}
      </ul>);
  }

  editField(f, index) {
    this.setState({SelectedField: f, ModalField: !this.state.ModalField, SelectedIndex: index});
  }
  
  deleteField(index) {
    const arr = this.state.vFields;
    arr.splice(index,1);
    this.setState({vFields: arr})
  }
}

export default translate(AddVirtual);