import React, { Component } from 'react';
import { Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody } from 'reactstrap';
import { logout } from '../../utils/AuthServices';
import Conf from '../../utils/Conf';
import { getRequest } from '../../utils/WebServicesManager';
import { translate, setLanguage, getLanguage } from '../../utils/ReactMultiLang';
import AvatarEdition from '../AvatarEdition';
import {check} from '../../utils/Can';

class HeaderDashboard extends Component {

  constructor(props) {
    super(props);
    this.t = props.t;

    this.toggle = this.toggle.bind(this);
    this.toggleLang = this.toggleLang.bind(this);
    this.state = {
      nbAlerts: 0,
      user: {},
      ModalAvatar: false,
      imgURL: null
    };
    this.toggleAvatar = this.toggleAvatar.bind(this);
    this.getUser();
  }

  toggleAvatar() {
    this.setState({ModalAvatar: !this.state.ModalAvatar});
  }

  getUser() {
    var url = Conf.BaseApi + 'user';
      return getRequest(url, (result) => {
        if (result.success === true) {
          const user = result.result;
          if (user.blobAvatar && user.blobAvatar !== undefined) {
            let blobAvatar = user.blobAvatar;
                this.setState({
                  imgURL: blobAvatar,
                  user
                });
          } else {
            this.setState({ user });
          }
        }
      });
    }

  changeLang (lang) {
    setLanguage(lang);
    
    var url = Conf.BaseApi + 'users/set/lang/' + lang;
    return getRequest(url);
  }

  getActiveAlerts() {
    var url = Conf.BaseApi + 'alerts/get/actives';
    return getRequest(url, (data) => {
      if (data.success)
        this.setState({ nbAlerts: data.result.length });
    });
  }

  toggle() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  }
  toggleLang() {
    this.setState({
      langOpen: !this.state.langOpen
    });
  }

  sidebarToggle(e) {
    e.preventDefault();
    document.body.classList.toggle('sidebar-hidden');
  }

  sidebarMinimize(e) {
    e.preventDefault();
    document.body.classList.toggle('sidebar-minimized');
  }

  mobileSidebarToggle(e) {
    e.preventDefault();
    document.body.classList.toggle('sidebar-mobile-show');
  }

  asideToggle(e) {
    e.preventDefault();
    document.body.classList.toggle('aside-menu-hidden');
  }


  render() {
    const { alerts } = this.props;
    
    let buffer = null;
    if (this.state.imgURL)
      buffer = new Buffer(this.state.imgURL.data);
    return (
      <header className="app-header navbar">
        <button className="navbar-toggler mobile-sidebar-toggler d-lg-none" type="button" onClick={this.mobileSidebarToggle}>&#9776;</button>
        <a className="navbar-brand" href="#/dashboard">{' '}</a>
        <ul className="nav navbar-nav d-md-down-none">
          <li className="nav-item">
            <button className="nav-link navbar-toggler sidebar-toggler" type="button" onClick={this.sidebarToggle}>&#9776;</button>
          </li>
        </ul>
        <ul className="nav navbar-nav ml-auto">
          <li className="nav-item">
            <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
              <DropdownToggle onClick={this.toggle} className="nav-link dropdown-toggle" data-toggle="dropdown" type="button" aria-haspopup={true} aria-expanded={this.state.dropdownOpen}>
                <img src={buffer ? buffer.toString('utf8') : 'img/avatars/avatar_picture.png'} className="img-avatar" alt="icon"/>
                <span className="d-md-down-none" id="logUserName">{this.state.user.firstName}{' '}{this.state.user.name}</span>
              </DropdownToggle>

              <DropdownMenu className="dropdown-menu-right">
                <a href="#/users/manage"><DropdownItem><i className="fa fa-user"></i> {this.t('Sidebar.Account')}</DropdownItem></a>
                <DropdownItem onClick={this.toggleAvatar}><i className="fa fa-lock"></i> {this.t('Topbar.EditAvatar')}</DropdownItem>
                <DropdownItem onClick={logout}><i className="fa fa-lock"></i> {this.t('Topbar.Logout')}</DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </li>
          <li className="nav-item">
            <Dropdown isOpen={this.state.langOpen} toggle={this.toggleLang}>
              <DropdownToggle onClick={this.toggleLang} className="nav-link dropdown-toggle" data-toggle="dropdown" type="button" aria-haspopup={true} aria-expanded={this.state.langOpen}>
                <img src={`img/flags/${this.t('Topbar.Flag')}.png`} className="img-avatar" alt="Langue" style={{borderRadius: '0px', height:'25px'}}/>
                <span className="d-md-down-none" id="language"></span>
              </DropdownToggle>

              <DropdownMenu className="dropdown-menu-right">
                <DropdownItem onClick={() => this.changeLang('en')}>
                  <img src={'img/flags/United-Kingdom.png'} className="img-avatar" alt="Langue" style={{borderRadius: '0px', height:'25px'}}/>{'  '}
                  {this.t('Languages.English')}
                </DropdownItem>
                <DropdownItem onClick={() => this.changeLang('fr')}>
                <img src={'img/flags/France.png'} className="img-avatar" alt="Langue" style={{borderRadius: '0px', height:'25px'}}/>{'  '}
                {this.t('Languages.French')}
                </DropdownItem>

              </DropdownMenu>
            </Dropdown>
          </li>
          {check("alarm:read") ? <li className="nav-item">
            <a className="nav-link" onClick={this.asideToggle}><i className="icon-bell"></i>
              {alerts > 0 ? <span className="badge badge-pill badge-danger">{alerts}</span> : ""}
            </a>
          </li> : ''}
          
        </ul>
        <Modal isOpen={this.state.ModalAvatar} toggle={this.toggleAvatar} className={'modal-sm ' + this.props.className}>
          <ModalHeader>
          {this.t('Topbar.EditAvatar')}
          </ModalHeader>
          <ModalBody>
            <AvatarEdition />
          </ModalBody>
        </Modal>
      </header>
    )
  }
}

export default translate(HeaderDashboard);

/*
<li className="nav-item">
            <Dropdown isOpen={this.state.langOpen} toggle={this.toggleLang}>
              <button onClick={this.toggleLang} className="nav-link dropdown-toggle" data-toggle="dropdown" type="button" aria-haspopup="true" aria-expanded={this.state.langOpen}>
                <img src={'img/flags/France.png'} className="img-avatar" alt="Langue"/>
                <span className="d-md-down-none" id="language">{this.t('Topbar.Language')}</span>
              </button>

              <DropdownMenu className="dropdown-menu-right">
                <DropdownItem onClick={() => this.changeLang('en')}>
                  <img src={'img/flags/United-Kingdom.png'} className="img-avatar" alt="Langue"/> {this.t('Languages.English')}
                </DropdownItem>
                <DropdownItem onClick={() => this.changeLang('fr')}>
                <img src={'img/flags/France.png'} className="img-avatar" alt="Langue"/>
                {this.t('Languages.French')}
                </DropdownItem>

              </DropdownMenu>
            </Dropdown>
          </li>
          

              <DropdownMenu className="dropdown-menu-right">
                <DropdownItem header className="text-center"><strong>Account</strong></DropdownItem>

                <DropdownItem><i className="fa fa-bell-o"></i> Updates<span className="badge badge-info">42</span></DropdownItem>
                <DropdownItem><i className="fa fa-envelope-o"></i> Messages<span className="badge badge-success">42</span></DropdownItem>
                <DropdownItem><i className="fa fa-tasks"></i> Tasks<span className="badge badge-danger">42</span></DropdownItem>
                <DropdownItem><i className="fa fa-comments"></i> Comments<span className="badge badge-warning">42</span></DropdownItem>

                <DropdownItem header className="text-center"><strong>Settings</strong></DropdownItem>

                <DropdownItem><i className="fa fa-user"></i> Profile</DropdownItem>
                <DropdownItem><i className="fa fa-wrench"></i> Settings</DropdownItem>
                <DropdownItem><i className="fa fa-usd"></i> Payments<span className="badge badge-default">42</span></DropdownItem>
                <DropdownItem><i className="fa fa-file"></i> Projects<span className="badge badge-primary">42</span></DropdownItem>
                <DropdownItem divider />
                <DropdownItem><i className="fa fa-shield"></i> Lock Account</DropdownItem>
                <DropdownItem onClick={logout}><i className="fa fa-lock"></i> Logout</DropdownItem>

              </DropdownMenu>
*/
