import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom'
import PublicHeader from '../../components/PublicHeader/PublicHeader';
import Footer from '../../components/Footer/Footer';

import Conf from '../../utils/Conf';
import { reportGetRequest } from '../../utils/WebServicesManager';

import Report from '../../views/Reports/Report';
/*import Planner from '../../views/Planner/';
<Route path="/planner" name="Planner" component={Planner}/>*/

class PublicReport extends Component {
  constructor(props) {
    super(props);

    this.state = {
      report: {}
    }
    this.getReport();
  }

  getReport() {
    const url = Conf.BaseURL + 'reports/get';

    return reportGetRequest(url, (data) => {
      if (data.success) {
        const report = data.result;
        this.setState({ report });
      }
    });
  }

  render() {
    return (
      <div className="app sidebar-fixed sidebar-hidden">
        <PublicHeader />
        <div style={{backgroundColor:'white', marginTop:'55px', paddingBottom:'25px'}}>
            <div className="container-fluid">
              <Report  report={this.state.report} id={this.state.report._id} key={1}
                    isVisible isPublic />
            </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default PublicReport;
