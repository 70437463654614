import React, { Component } from 'react';
import $ from 'jquery';
import Conf from '../../../utils/Conf';
import { getRequest } from '../../../utils/WebServicesManager';
import ColorPicker from '../ColorPicker';
import CreatLevel from './CreateLevel';
import { translate } from '../../../utils/ReactMultiLang';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Modal, Button, Label, Input, Col, Row } from 'reactstrap';
import moment from 'moment';
import SensorSelector from '../../Modals/SensorSelector';
import TimeField from 'react-simple-timefield';


class CreatComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Sensors: [],
      SelectedSensor: {},
      DevEUI: '',
      FieldList: [],
      lineColor: '#f00',
      from: moment(),
      to: moment(),
      StaticDate: false,
      Modal: false,
      useMultipleColors: false,
      mColors: [],
      StaticDate: false,
      useTime: false,
      timeStart: '00:00',
      timeEnd: '00:00',
    };
    //this.getSensors();
    this.t = props.t;
  }

  toggle() {
    this.setState({ Modal: !this.state.Modal });
  }

  useStatic() {
    this.setState({ StaticDate: $("#useStatic").is(':checked') });
  }

  useTimeChange() {
    this.setState({ useTime: $("#useTime").is(':checked') });
  }


  handleChangeFrom(from) {
    if (this.state.to) {
      this.setState({ from });
    } else {
      this.setState({ from, to: from });
    }
  }
  handleChangeTo(to) {
    this.setState({ to });
  }

  /*getSensors() {
    if (this.state.FieldList.length !== 0)
      return;
    var url = Conf.BaseApi + `devices/get`;
    return getRequest(url, (body) => {
      if (body && body.success) {
        let result = body.result;
        this.setState({ Sensors: result });
        if (result.length > 0) {
          const event = {};
          event.target = {}
          event.target.value = result[0].DevEUI;
          this.getFieldList(event);
        }
      }
    });
  }*/

  updateColors(i, min, max) {
    let colors = this.state.mColors;
    if (colors.length > i) {
      if (min)
        colors[i].min = min;
      else
        colors[i].max = max;
    }
    this.setState({ mColors: colors });
  }

  addColor() {
    let colors = this.state.mColors;
    colors.push({ min: 0, max: 0, strokeStyle: "#ff0000" });
    this.setState({ mColors: colors });
  }
  rmColor(i) {
    let colors = this.state.mColors;
    colors.splice(i, 1);
    this.setState({ mColors: colors });
  }

  handleColorsChange(i, color) {
    let colors = this.state.mColors;
    if (colors.length > i) {
      colors[i].strokeStyle = color;
    }
    this.setState({ mColors: colors });
  };

  handleColorChange(color) {
    this.setState({ lineColor: color });
  };

  getFieldList(event) {
    if (event.target.value !== "") {
      const DevEUI = event.target.value;
      let url = Conf.BaseApi + `devices/get/${DevEUI}`;
      getRequest(url, (body) => {
        if (body && body.success) {
          let result = body.result;
          this.setState({ SelectedSensor: result });
        }
      });
      url = Conf.BaseApi + `helpers/fields/${DevEUI}`;
      return getRequest(url, (result) => {
        if (result && result.success) {
          this.setState({ FieldList: result.result, DevEUI });
        }
      });
    }
  }

  getCustom(event) {
    const field = event.target.value;
    const sensor = this.state.Sensors.find(s => s.DevEUI === this.state.DevEUI);
    if (sensor && sensor.customFields && field !== "") {
      const custom = sensor.customFields.find(c => c.fieldId === field);
      if (custom) {
        if ($("#createCpLabel").val() === '')
          $("#createCpLabel").val(custom.label);
        else if ($("#createCpName").val() === '')
          $("#createCpName").val(custom.label);
        if ($("#createCpUnit") && $("#createCpUnit").val() === '')
          $('#createCpUnit').val(custom.unit);
      }
    }
  }

  renderSensors(sensors) {
    var toRender = [];
    for (var i = 0; i < sensors.length; i++) {
      toRender.push(
        <option value={sensors[i].DevEUI}>{sensors[i].name}</option>
      );
    }
    return toRender;
  }

  renderDeviceFields(fields) {
    var toRender = [];
    for (var i = 0; i < fields.length; i++) {
      toRender.push(
        <option value={fields[i]}>{this.t(`SensorData.${fields[i]}`)}</option>
      );
    }
    return toRender;
  }

  createComponent(callback, type) {
    const component = {};
    component.dataSets = [];
    component.dataSets[0] = {};
    component.name = $("#createCpName").val();
    component.color = this.state.lineColor;
    component.type = type;
    if (this.state.useMultipleColors) component.colors = this.state.mColors;
    component.dataSets[0].sensor = this.state.SelectedSensor ? this.state.SelectedSensor.DevEUI : '';
    if (type === 'ALERTS') {
      component.dataSets[0].sensor = this.state.SelectedSensor ? this.state.SelectedSensor._id : '';
    }
    component.dataSets[0].label = $("#createCpLabel").val();
    component.dataSets[0].unit = $('#createCpUnit').val();
    component.dataSets[0].paramName = $("#createCpDataName").val();
    component.dataSets[0].color = this.state.lineColor;
    if (type === "INT-CHART") {
      component.dataSets[0].showAlerts = $("#showAlerts").is(':checked');

      if (this.state.useTime) {
        const froms = this.state.timeStart.split(':');
        const tos = this.state.timeEnd.split(':');
        component.timeStart = parseInt(froms[0]) * 100 + parseInt(froms[1]);
        component.timeEnd = parseInt(tos[0]) * 100 + parseInt(tos[1]);
      }
      // Min max line for line graph
      component.showMin = false;
      component.showMax = false;
      if ($("#showMin").is(':checked')) {
        component.showMin = true;
      }
      if ($("#showMax").is(':checked')) {
        component.showMax = true;
      }
      // End Min max line
    }
    component.min = $('#createMin').val();
    component.max = $('#createMax').val();
    component.granularity = $('#granularity').val();
    component.printBreaker = $("#breakLine").is(':checked');
    component.level = $('#createLevel').val();
    component.barMode = $('#createBarMode').val();
    if (this.state.StaticDate) {
      component.from = this.state.from.hour(0).minute(0).valueOf();
      component.to = this.state.to.hour(23).minute(59).valueOf();
    } else {
      component.days = $('#createCpDays').val();
    }

    callback(component);
  }

  createEnergy(callback) {
    const component = {};
    component.name = $("#createCpName").val();
    component.type = "ENERGY";

    component.dataSets = [{
      label: "Combust",
      value: $("#createCompustible").val()
    }, {
      label: "Quantity",
      value: $("#createQty").val()
    }, {
      label: "CO2",
      value: $("#createCO2").val()
    }];
    callback(component);
  }

  sensorSelected(sensors) {
    if (sensors.length > 0) {
      const { DevEUI, _id } = sensors[0];
      var url = Conf.BaseApi + `helpers/fields/${DevEUI}`;
      return getRequest(url, (result) => {
        if (result && result.success) {
          this.setState({ SelectedSensor: sensors[0], FieldList: result.result, SelectedField: result.result[0] });
          this.toggle();
        }
      });
    }
  }

  render() {
    const { callback, type } = this.props;

    let toRender = {};
    switch (type) {
      case "TITLE":
        toRender = this.getTitle(callback, type);
        break;
      case "GAUGE":
        toRender = this.getGauge(callback, type);
        break;
      case "BOOLEAN":
        toRender = this.getOpenClose(callback, type);
        break;
      case "BOOL-LEVEL":
        toRender = <CreatLevel callback={callback} type={type} />
        break;
      case "ALERTS":
        toRender = this.getAlert(callback, type);
        break;
      case "ENERGY":
        toRender = this.getEnergy(callback, type);
        break;
      default:
        toRender = this.getChartOrAverage(callback, type);
        break;

    }
    return (
      <div className="flex-row align-items-center">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12">
              <div className="card mx-12">
                {toRender}
              </div>
            </div>
          </div>
        </div>
        <Modal isOpen={this.state.Modal} className={'modal-lg ' + this.props.className}>
          <SensorSelector callbackAddSensor={this.sensorSelected.bind(this)} />
        </Modal>
      </div>
    )
  }

  getAlert(component, callback) {
    return (
      <div className="card-block p-12">
        <div className="input-group mb-3" style={{ color: "red" }} id="error-window">

        </div>
        <div className="form-group mb-3">
          <div><label htmlFor="createCpSensor">{this.t('Sensor')}:</label></div>
          <b>{this.state.SelectedSensor.name ? this.state.SelectedSensor.name : this.t(`Sensor.NoSensor`)}{' '}</b>
          <Button className="btn-secondary" onClick={() => this.toggle()}>{this.t(`Sensor.Change`)}</Button>
        </div>
        <div className="input-group mb-3">
          <span className="input-group-addon">{this.t('Numbers')}</span>
          <input type="number" className="form-control" id="granularity" defaultValue={component.granularity} />
        </div>
        <div className="input-group mb-1">
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input className="form-check-input" type="checkbox" id="useStatic" value="true" onChange={() => this.useStatic()} />
              {this.t('Component.UseStatic')}
            </label>
          </div>
        </div>
        {
          this.state.StaticDate ?

            <div className="input-group mb-3">
              <div style={{ display: 'inline-block', marginLeft: '25px', zIndex: '9000' }}>
                {this.t('Begin')}: <DatePicker
                  selected={this.state.from}
                  onChange={this.handleChangeFrom.bind(this)}
                  dateFormat="DD MMMM YYYY"
                />
              </div>
              <div style={{ display: 'inline-block', marginLeft: '25px', zIndex: '9000' }}>
                {this.t('End')}: <DatePicker
                  selected={this.state.to}
                  onChange={this.handleChangeTo.bind(this)}
                  dateFormat="DD MMMM YYYY"
                />
              </div>
            </div>
            :
            <div className="input-group mb-3">
              <span className="input-group-addon"><i className="icon-clock"></i></span>
              <input type="number" className="form-control" placeholder={this.t('Component.DaysNum')} defaultValue={2} id="createCpDays" />
            </div>
        }
        <button type="button" className="btn btn-block btn-success" onClick={this.createComponent.bind(this, component, callback)}>{this.t('Update')}</button>
      </div >
    );
  }

  getTitle(callback, type) {
    return (
      <div className="card-block p-12">
        <div className="input-group mb-3" style={{ color: "red" }} id="error-window">
        </div>
        <div className="input-group mb-3">
          <span className="input-group-addon"><i className="icon-user"></i></span>
          <input type="text" className="form-control" placeholder={this.t('Component.Name')} id="createCpName" />
        </div>
        <div className="input-group mb-3">
          <span className="input-group-addon"><i className="icon-clock"></i></span>
          <input type="number" className="form-control" placeholder={this.t('Component.DaysNum')} defaultValue={20} id="createCpDays" />
        </div>

        <div className="input-group mb-1">
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input className="form-check-input" type="checkbox" id="breakLine" value="false" />
              {this.t('Component.BreakLine')}
            </label>
          </div>
        </div>
        <div className="form-group mb-3">
          <label htmlFor="createCpColor">{this.t('Color')}:</label>
          <ColorPicker callback={this.handleColorChange.bind(this)} />
        </div>
        <br />
        <button type="button" className="btn btn-block btn-success" onClick={this.createComponent.bind(this, callback, type)}>{this.t('Add')}</button>
      </div>
    );
  }

  getGauge(callback, type) {
    return (
      <div className="card-block p-12">
        <div className="input-group mb-3" style={{ color: "red" }} id="error-window">
        </div>
        <div className="input-group mb-3">
          <span className="input-group-addon"><i className="icon-user"></i></span>
          <input type="text" className="form-control" placeholder={this.t('Component.Name')} id="createCpName" />
        </div>
        <div className="input-group mb-3">
          <span className="input-group-addon"><i className="icon-user"></i></span>
          <input type="text" className="form-control" placeholder={this.t('Unit')} id="createCpUnit" />
        </div>
        <div className="input-group mb-3">
          <span className="input-group-addon"><i className="icon-clock"></i></span>
          <input type="number" className="form-control" placeholder={this.t('Component.Min')} defaultValue={0} id="createMin" />
        </div>
        <div className="input-group mb-3">
          <span className="input-group-addon"><i className="icon-clock"></i></span>
          <input type="number" className="form-control" placeholder={this.t('Component.Max')} defaultValue={100} id="createMax" />
        </div>
        <div className="input-group mb-3">
          {this.t('Component.UseMultipleColors')}
          <Label className="switch switch-sm switch-text switch-success switch-label float-right mb-0" style={{ marginLeft: '5px' }}>
            <Input type="checkbox" className="switch-input" onChange={(e) => this.setState({ useMultipleColors: e.target.checked })} />
            <span className="switch-label" ></span>
            <span className="switch-handle"></span>
          </Label>
        </div>
        {
          this.state.useMultipleColors ?
            <table>
              <tbody>
                {this.state.mColors.length > 0 ? this.state.mColors.map((c, i) =>
                  <tr>
                    <td>
                      {this.t('Min')}{' '}<input type="number" className="form-control small-control" placeholder={this.t('Component.Min')} defaultValue={c.min} min={$('#createMin').val()} id={`createMin${i}`} onChange={(e) => this.updateColors(i, e.target.value)} />
                    </td>
                    <td>
                      {this.t('Max')}{' '}<input type="number" className="form-control small-control" placeholder={this.t('Component.Min')} defaultValue={c.max} max={$('#createMax').val()} id={`createMax${i}`} onChange={(e) => this.updateColors(i, undefined, e.target.value)} />
                    </td>
                    <td>
                      <label htmlFor="createCpColor">{this.t('Color')}:</label>
                      <ColorPicker callback={this.handleColorsChange.bind(this, i)} color={c.strokeStyle} />
                    </td>
                    <td>
                      <button type="button" className="btn btn-block btn-danger" onClick={() => this.rmColor(i)}>{this.t('Remove')}</button>
                    </td>
                  </tr>) : ''}
                <button type="button" className="btn btn-block btn-warning" onClick={() => this.addColor()}>{this.t('AddColor')}</button>
              </tbody>
            </table>
            :
            <div className="form-group mb-3">
              <label htmlFor="createCpColor">{this.t('Color')}:</label>
              <ColorPicker callback={this.handleColorChange.bind(this)} />
            </div>
        }
        <div className="form-group mb-3">
          <div><label htmlFor="createCpSensor">{this.t('Sensor')}:</label></div>
          <b>{this.state.SelectedSensor.name ? this.state.SelectedSensor.name : this.t(`Sensor.NoSensor`)}{' '}</b>
          <Button className="btn-secondary" onClick={() => this.toggle()}>{this.t(`Sensor.Change`)}</Button>
        </div>
        <div className="form-group mb-3">
          <div><label htmlFor="createCpDataName">{this.t('Component.DataToUse')}:</label></div>

          <select className="form-control" id="createCpDataName" onChange={this.getCustom.bind(this)}>
            {this.renderDeviceFields(this.state.FieldList)}
          </select>
        </div>
        <br />
        <button type="button" className="btn btn-block btn-success" onClick={this.createComponent.bind(this, callback, type)}>{this.t('Add')}</button>
      </div>
    );
  }

  getOpenClose(callback, type) {
    return (
      <div className="card-block p-12">
        <div className="input-group mb-3" style={{ color: "red" }} id="error-window">
        </div>
        <div className="input-group mb-3">
          <span className="input-group-addon"><i className="icon-user"></i></span>
          <input type="text" className="form-control" placeholder={this.t('Component.Name')} id="createCpName" />
        </div>
        <div className="form-group mb-3">
          <div><label htmlFor="createCpSensor">{this.t('Sensor')}:</label></div>
          <b>{this.state.SelectedSensor.name ? this.state.SelectedSensor.name : this.t(`Sensor.NoSensor`)}{' '}</b>
          <Button className="btn-secondary" onClick={() => this.toggle()}>{this.t(`Sensor.Change`)}</Button>
        </div>
        <div className="form-group mb-3">
          <div><label htmlFor="createCpDataName">{this.t('Component.DataToUse')}:</label></div>

          <select className="form-control" id="createCpDataName" onChange={this.getCustom.bind(this)}>
            {this.renderDeviceFields(this.state.FieldList)}
          </select>
        </div>
        <br />
        <button type="button" className="btn btn-block btn-success" onClick={this.createComponent.bind(this, callback, type)}>{this.t('Add')}</button>
      </div>
    );
  }

  getChartOrAverage(callback, type) {
    return (
      <div className="card-block p-12">
        <div className="input-group mb-3" style={{ color: "red" }} id="error-window">
        </div>
        {type !== "INT-AVERAGE" ?
          <div className="input-group mb-3">
            <span className="input-group-addon"><i className="icon-user"></i></span>
            <input type="text" className="form-control" placeholder={this.t('Component.Name')} id="createCpName" />
          </div>
          : ''}
        <div className="input-group mb-3">
          <span className="input-group-addon"><i className="icon-user"></i></span>
          <input type="text" className="form-control" placeholder={this.t('Component.DataSetName')} id="createCpLabel" />
        </div>
        <div className="input-group mb-3">
          <span className="input-group-addon"><i className="icon-user"></i></span>
          <input type="text" className="form-control" placeholder={this.t('Unit')} id="createCpUnit" />
        </div>
        {type === "INT-AVERAGE" ?

          <div className="form-group mb-3">
            <div><label htmlFor="createBarMode">{this.t('Component.BarMode')}:</label></div>

            <select className="form-control" id="createBarMode">
              <option value={1}>{this.t('Component.ExactValue')}</option>
              <option value={0}>{this.t('Component.Average')}</option>
              <option value={2}>{this.t('Component.Difference')}</option>
            </select>
          </div>
          : ''}
        {type === "BAR-CHART" ?

          <div className="form-group mb-3">
            <div><label htmlFor="createBarMode">{this.t('Component.BarMode')}:</label></div>

            <select className="form-control" id="createBarMode">
              <option value={1}>{this.t('Component.Difference')}</option>
              <option value={2}>{this.t('Component.Average')}</option>
            </select>
          </div>
          : ''}
        {type === "BAR-CHART" ?
          <div className="form-group mb-3">
            <div><label htmlFor="createLevel">{this.t('Component.Level')}:</label></div>

            <select className="form-control" id="createLevel" defaultValue={2}>
              <option value={3}>{this.t('Hourly')}</option>
              <option value={0}>{this.t('Daily')}</option>
              <option value={1}>{this.t('Weekly')}</option>
              <option value={2}>{this.t('Monthly')}</option>
            </select>
          </div>
          : ''}
        {type === "INT-CHART" ?
          <div className="input-group mb-3">
            <span className="input-group-addon">{this.t('Granularity')}(minutes)</span>
            <input type="number" className="form-control" id="granularity" />
          </div>
          : ''}

        <div className="input-group mb-1">
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input className="form-check-input" type="checkbox" id="useStatic" value="true" onChange={() => this.useStatic()} />
              {this.t('Component.UseStatic')}
            </label>
          </div>
        </div>
        {
          this.state.StaticDate ?

            <div className="input-group mb-3">
              <div style={{ display: 'inline-block', marginLeft: '25px', zIndex: '9000' }}>
                {this.t('Begin')}: <DatePicker
                  selected={this.state.from}
                  onChange={this.handleChangeFrom.bind(this)}
                  dateFormat="DD MMMM YYYY"
                />
              </div>
              <div style={{ display: 'inline-block', marginLeft: '25px', zIndex: '9000' }}>
                {this.t('End')}: <DatePicker
                  selected={this.state.to}
                  onChange={this.handleChangeTo.bind(this)}
                  dateFormat="DD MMMM YYYY"
                />
              </div>
            </div>
            :
            <div className="input-group mb-3">
              <span className="input-group-addon"><i className="icon-clock"></i></span>
              <input type="number" className="form-control" placeholder={this.t('Component.DaysNum')} defaultValue={2} id="createCpDays" />
            </div>
        }

        {type === "INT-CHART" && this.state.StaticDate ?

          <Col md='12'>
            <div className="input-group mb-3">
              <div className="form-check form-check-inline">
                <label className="form-check-label">
                  <input className="form-check-input" type="checkbox" id="useTime" value="true" onChange={() => this.useTimeChange()}
                    defaultChecked={this.state.useTime} />
                  {" "}{this.t('Component.UseTime')}
                </label>
              </div>
            </div>
          </Col>
          : ''}
        {type === "INT-CHART" ?
          this.state.useTime ?
            <Row>
              <Col md='6'>
                {this.t('From')}: {' '}
                <TimeField
                  style={{
                    border: '1px solid #666',
                    fontSize: 18,
                    width: 200,
                    padding: '5px 8px',
                    color: '#333',
                    borderRadius: 3
                  }}
                  value={this.state.timeStart}
                  onChange={(event, value) => { this.setState({ timeStart: value }) }}
                />
              </Col>
              <Col md='6'>
                {this.t('To')}: {' '}
                <TimeField

                  style={{
                    border: '1px solid #666',
                    fontSize: 18,
                    width: 200,
                    padding: '5px 8px',
                    color: '#333',
                    borderRadius: 3
                  }}
                  value={this.state.timeEnd}
                  onChange={(event, value) => { this.setState({ timeEnd: value }) }}
                />
              </Col>
            </Row>
            : ''
          : ''}
        {type === "INT-CHART" && this.state.useTime ? <br /> : ''}
        {type === "INT-CHART" ?

          <Row>
            <Col md='6'>
              <div className="input-group mb-3">
                <div className="form-check form-check-inline">
                  <label className="form-check-label">
                    <input className="form-check-input" type="checkbox" id="showMin" value="true" onChange={() => this.setState({ showMin: $("#showMin").is(':checked') })}
                      defaultChecked={this.state.showMin} />
                    {" "}{this.t('Component.showMin')}
                  </label>
                </div>
              </div>
              {
                this.state.showMin ?

                  <div className="input-group mb-3">
                    <span className="input-group-addon"><i className="icon-clock"></i></span>
                    <input type="number" className="form-control" placeholder={this.t('Component.Min')} defaultValue={0} id="createMin" defaultValue={0} />
                  </div>
                  : ''
              }
            </Col>
            <Col md='6'>
              <div className="input-group mb-3">
                <div className="form-check form-check-inline">
                  <label className="form-check-label">
                    <input className="form-check-input" type="checkbox" id="showMax" value="true" onChange={() => this.setState({ showMax: $("#showMax").is(':checked') })}
                      defaultChecked={this.state.showMax} />
                    {" "}{this.t('Component.showMax')}
                  </label>
                </div>
              </div>
              {
                this.state.showMax ?

                  <div className="input-group mb-3">
                    <span className="input-group-addon"><i className="icon-clock"></i></span>
                    <input type="number" className="form-control" placeholder={this.t('Component.Max')} defaultValue={100} id="createMax" defaultValue={30} />
                  </div>
                  : ''
              }
            </Col>
          </Row>
          : ''}
        <div className="form-group mb-3">
          <label htmlFor="createCpColor">{this.t('Color')}:</label>
          <ColorPicker callback={this.handleColorChange.bind(this)} />
        </div>
        <div className="form-group mb-3">
          <div><label htmlFor="createCpSensor">{this.t('Sensor')}:</label></div>
          <b>{this.state.SelectedSensor.name ? this.state.SelectedSensor.name : this.t(`Sensor.NoSensor`)}{' '}</b>
          <Button className="btn-secondary" onClick={() => this.toggle()}>{this.t(`Sensor.Change`)}</Button>
        </div>
        <div className="form-group mb-3">
          <div><label htmlFor="createCpDataName">{this.t('Component.DataToUse')}:</label></div>

          <select className="form-control" id="createCpDataName" onChange={this.getCustom.bind(this)}>
            {this.renderDeviceFields(this.state.FieldList)}
          </select>
        </div>
        {type === "INT-CHART" ?
          <div className="input-group mb-1">
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input className="form-check-input" type="checkbox" id="showAlerts" value="true" />
                {this.t('ShowAlerts')}
              </label>
            </div>
          </div>
          : ''}
        <br />
        <button type="button" className="btn btn-block btn-success" onClick={this.createComponent.bind(this, callback, type)}>{this.t('Add')}</button>
      </div>
    );
  }


  getEnergy(callback, type) {
    return (
      <div className="card-block p-12">
        <div className="input-group mb-3" style={{ color: "red" }} id="error-window">
        </div>
        <div className="input-group mb-3">
          <span className="input-group-addon"><i className="icon-user"></i></span>
          <input type="text" className="form-control" placeholder={this.t('Component.Name')} id="createCpName" />
        </div>

        <div className="input-group mb-3">
          <span className="input-group-addon">Combustible</span>
          <input type="text" className="form-control" id="createCompustible" />
        </div>
        <div className="input-group mb-3">
          <span className="input-group-addon">Quantité</span>
          <input type="text" className="form-control" id="createQty" />
        </div>
        <div className="input-group mb-3">
          <span className="input-group-addon">Emission CO2</span>
          <input type="text" className="form-control" id="createCO2" />
        </div>
        <br />
        <button type="button" className="btn btn-block btn-success" onClick={this.createEnergy.bind(this, callback)}>{this.t('Add')}</button>
      </div>
    );
  }

}

export default translate(CreatComponent);