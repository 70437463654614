import React, { Component } from "react";
import $ from 'jquery';
import { DiagramEngine, NodeModel, Toolkit, BaseWidget, BaseWidgetProps, DefaultPortLabel } from "storm-react-diagrams";


class ComparatorNodeWidget extends BaseWidget {
	constructor(props) {
		super("srd-node", props);
		this.state = {};
	}

	generatePort(port) {
		return <DefaultPortLabel model={port} key={port.id} />;
	}


	updateComparator(props) {
		props.node.setComparator($(`#${props.node.id}comparator`).val());
	}
	render() {
		const {picto} = this.props.node;
		return (
			<div {...this.getProps()} className={"complex-node node-comparator " + this.props.node.color}>
				<div className={this.bem("__title")}>
					<img src={`./img/picto/${picto}.png`} />
					<div className={this.bem("__name")}>{this.props.node.name}:{' '}
					<select id={this.props.node.id + 'comparator'}
						onChange={this.updateComparator.bind(this, this.props)} defaultValue={this.props.node.getComparator()}>
						<option value="gt">{'>'}</option>
						<option value="gte">{'>='}</option>
						<option value="lt">{'<'}</option>
						<option value="lte">{'<='}</option>
						<option value="eq">{'='}</option>
						<option value="ne">{'!='}</option>
					</select>
					</div>
				</div>
				<div className={this.bem("__ports")}>
					<div className={this.bem("__in")}>
						{this.props.node.getPortsIn().map((port) => this.generatePort(port))}
					</div>
					<div className={this.bem("__out")}>
						{this.props.node.getPortsOut().map((port) => this.generatePort(port))}
					</div>
				</div>
			</div>
		);
	}
}

export default ComparatorNodeWidget;