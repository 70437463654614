import React from 'react';
import AvatarEditor from 'react-avatar-editor';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import { Button } from 'reactstrap';
import { postRequest } from '../../../utils/WebServicesManager';
import Conf from '../../../utils/Conf';
import { translate } from '../../../utils/ReactMultiLang';
import $ from 'jquery';

class AddPlan extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          position: { x: 0.5, y: 0.5 },
          scale: 1,
          rotate: 0,
          borderRadius: 0,
          preview: null,
          realWidth: 350,
          realHeight: 350,
          width: 350,
          height: 350,
        };
        this.t = props.t;
    }

  onClickSave = () => {
    if (this.editor) {
      // This returns a HTMLCanvasElement, it can be made into a data URL or a blob,
      // drawn on another canvas, or added to the DOM.
      const canvas = this.editor.getImage();
      if (canvas && canvas.style) {
      canvas.style.width  = this.state.realWidth; // in pixels
      canvas.style.height = this.state.realHeight; // in pixels
      let dataUrl = canvas.toDataURL();
                var url = Conf.BaseApi + 'plans/add';
                  return postRequest(url, {
                    blobPlan: dataUrl,
                    width: this.state.realWidth,
                    height: this.state.realHeight,
                    floorId: this.props.floor,
                    name: this.state.name
                  }, (result) => {
                    if (result.success === true) {
                      window.location.reload();
                    } else {
                      $("#error-window").text(result.message);
                    }
                  });
    }  
  }
  }
  
  handleDrop = dropped => {
    const file = dropped[0];
    const i = new Image()
  
    i.onload = () => {
      let reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        let realWidth = i.width;
        let realHeight = i.height;
        let width = 350;
        let height = 350;
        const ratio = realWidth / realHeight;
        if (ratio > 1) {
          height = height / ratio;
        } else {
          width = width * ratio;
        }
        if (realWidth > 1900) {
          realHeight = Math.round(realHeight * (1900 / realWidth)); 
          realWidth = 1900;
        }
        if (realHeight > 1900) {
          realWidth = Math.round(realWidth * (1900 / realHeight)); 
          realHeight = 1900;
        }
        this.setState({ image: dropped[0], realWidth, realHeight, width, height });
      }
    }
    i.src = file.preview;
  }
 
  setEditorRef = (editor) => this.editor = editor;
 
  handleScale = e => {
    const scale = parseFloat(e.target.value);
    this.setState({ scale });
  }
  handlePositionChange = position => {
    this.setState({ position });
  }

  render () {

    return (
        <div style={{position: "relative" }}>
          
          <div className="input-group mb-3" style={{ color: "red" }} id="error-window">

</div>
          <div className="input-group mb-3">
                                                <span className="input-group-addon"><i className="icon-map"></i></span>
                                                <input type="text" className="form-control" placeholder={this.t('Name')}
                                                id="name" onChange={(e) => this.setState({name: e.target.value})} />
                                            </div>
        <Dropzone
            onDrop={this.handleDrop}
            disableClick
            style={{ width: `100%`, height: `420px`, position: "relative" }} >
            <AvatarEditor
            style={{ 
              display: "table",
              margin: "0 auto" }}
            ref={this.setEditorRef}
            image={this.state.image}
            scale={parseFloat(this.state.scale)}
            width={this.state.width}
            height={this.state.height}
            position={this.state.position}
            onPositionChange={this.handlePositionChange}
            rotate={parseFloat(this.state.rotate)}
            borderRadius={this.state.width / (100 / this.state.borderRadius)}
            />
        </Dropzone>
        Zoom:
        <input name="scale" type="range"
          onChange={this.handleScale}
          min={this.state.allowZoomOut ? '0.1' : '1'} max="2"
          step="0.01" defaultValue="1" />
          <br />
        <Button color="primary" className="btn btn-danger btn-block" onClick={this.onClickSave}>
                    <i className="fa fa-credit-card-alt"></i>&nbsp; Envoyer
            </Button>
        </div>
    )
  }
}

export default translate(AddPlan)