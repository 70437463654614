import React, { Component } from 'react';
import moment from 'moment';
import $ from 'jquery';
import { TimePicker } from 'react-input-moment';
import Conf from '../../../utils/Conf';
import { getRequest } from '../../../utils/WebServicesManager';
import { ButtonToolbar, ToggleButtonGroup, ToggleButton } from 'react-bootstrap';
import { translate } from '../../../utils/ReactMultiLang';

class CreatComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Sensors: [],
      FieldList: []
    };
    this.getSensors();
    this.t = props.t;
  }

  getSensors() {
    if (this.state.FieldList.length !== 0)
      return;
    var url = Conf.BaseApi + `devices/get`;
    return getRequest(url, (body) => {
      if (body && body.success) {
        let result = body.result;
        this.setState({ Sensors: result });
        if (result.length > 0) {
          const event = {};
          event.target = {}
          event.target.value = result[0].DevEUI;
          this.getFieldList(event);
        }
      }
    });
  }
  getFieldList(event) {
    if (event.target.value !== "") {
      var url = Conf.BaseApi + `helpers/fields/${event.target.value}`;
      return getRequest(url, (result) => {
        if (result && result.success) {
          this.setState({ FieldList: result.result });
        }
      });
    }
  }
  
  renderSensors(sensors) {
    var toRender = [];
    for (var i = 0; i < sensors.length; i++) {
      toRender.push(
        <option value={sensors[i].DevEUI}>{sensors[i].name}</option>
      );
    }
    return toRender;
  }
  

  renderDeviceFields(fields) {
    var toRender = [];
    for (var i = 0; i < fields.length; i++) {
      toRender.push(
        <option value={fields[i]}>{this.t(`SensorData.${fields[i]}`)}</option>
      );
    }
    return toRender;
  }

  createComponent(callback, type) {
    const component = {};
    component.type = type;
    component.sensor = $("#createCpSensor").val();
    component.label = $("#createCpLabel").val();
    component.unit = $('#createCpUnit').val();
    component.paramName = $("#createCpDataName").val();
    component.color = $('#createCpColor input:radio:checked').val();
    component.size = $('#createCpSize input:radio:checked').val();
    component.days = 2;

    callback(component);
  }

  render() {
    const { callback, type } = this.props;

    return (
      <div className="flex-row align-items-center">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12">
              <div className="card mx-12">
                <div className="card-block p-12">
                  <div className="input-group mb-3" style={{ color: "red" }} id="error-window">

                  </div>
                  <div className="input-group mb-3">
                    <span className="input-group-addon"><i className="icon-user"></i></span>
                    <input type="text" className="form-control" placeholder={this.t('Label')} id="createCpLabel" />
                  </div>
                  <div className="input-group mb-3">
                    <span className="input-group-addon"><i className="icon-user"></i></span>
                    <input type="text" className="form-control" placeholder={this.t('Unit')} id="createCpUnit" />
                  </div>
                  
                  <div className="form-group mb-3">
                    <label htmlFor="createCpColor">{this.t('Color')}</label>
                    <ButtonToolbar>
                      <ToggleButtonGroup type="radio" name="createCpColor" id="createCpColor" defaultValue="card-success">
                      <ToggleButton value="card-primary" bsStyle="primary">{this.t('Blue')}</ToggleButton>
                      <ToggleButton value="card-success" bsStyle="success">{this.t('Green')}</ToggleButton>
                      <ToggleButton value="card-warning" bsStyle="warning">{this.t('Yellow')}</ToggleButton>
                      <ToggleButton value="card-danger" bsStyle="danger">{this.t('Red')}</ToggleButton>
                      </ToggleButtonGroup>
                    </ButtonToolbar>
                  </div>

                  <div className="form-group mb-3">
                    <label htmlFor="createCpSize">Taille</label>
                    <ButtonToolbar>
                      <ToggleButtonGroup type="radio" name="createCpSize" id="createCpSize" defaultValue="6">
                        <ToggleButton value="3" bsStyle="success">3</ToggleButton>
                        <ToggleButton value="6" bsStyle="success">6</ToggleButton>
                        <ToggleButton value="9" bsStyle="success">9</ToggleButton>
                        <ToggleButton value="12" bsStyle="success">12</ToggleButton>
                      </ToggleButtonGroup>
                    </ButtonToolbar>
                  </div>
                  <div className="form-group mb-3">
                    <div><label htmlFor="createCpSensor">{this.t('Sensor.Sensor')}</label></div>

                    <select className="form-control" id="createCpSensor" onChange={this.getFieldList.bind(this)}>
                      {this.renderSensors(this.state.Sensors)}
                    </select>
                  </div>
                  <div className="form-group mb-3">
                    <div><label htmlFor="createCpDataName">{this.t('Component.Datasets')} :</label></div>

                    <select className="form-control" id="createCpDataName">
                      {this.renderDeviceFields(this.state.FieldList)}
                    </select>
                  </div>
                  <br />
                  <button type="button" className="btn btn-block btn-success" onClick={this.createComponent.bind(this, callback, type)}>{this.t('Add')}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

}

export default translate(CreatComponent);