import React, { Component } from 'react';
import Conf from '../../../utils/Conf';
import { getRequest, postRequest } from '../../../utils/WebServicesManager';
import { translate } from '../../../utils/ReactMultiLang';
import { Card, Row, Col, ButtonToolbar, CardBody } from 'reactstrap';
import { ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import TimeField from 'react-simple-timefield';
import $ from 'jquery';
import SvgHeatmap from './SvgHeatmap/SvgHeatmap';
import GradientPicker from '../../../components/GradientPicker';


class Heatmap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Building: {},
      ActPlan: 0,
      Buildings: [],
      Floors: [],
      Groups: [],
      Filters: [],
      showValues: false,
      Plan: undefined,
      SelectedGroup: JSON.parse(localStorage.getItem('Heatmap-SelectedGroupName') || null),
      SelectedBuildingId: JSON.parse(localStorage.getItem('Heatmap-SelectedBuildingId') || null),
      SelectedFloorId: JSON.parse(localStorage.getItem('Heatmap-SelectedFloorId') || null),
      Key: 0,
      from: JSON.parse(localStorage.getItem('Heatmap-from')) ? moment(JSON.parse(localStorage.getItem('Heatmap-from'))) : moment().subtract(1, "month"),
      to: JSON.parse(localStorage.getItem('Heatmap-to')) ? moment(JSON.parse(localStorage.getItem('Heatmap-to'))) : moment(),
      hourFrom: '0:0',
      hourTo: '0:0',
      useTime: false,
      Palette: [
        { offset: '0.30', color: 'rgb(50, 70, 244)' },
        { offset: '0.60', color: 'rgb(50, 240, 70)' },
        { offset: '0.80', color: 'rgb(255, 170, 0)' },
        { offset: '1.00', color: 'rgb(240, 50, 70)' }
      ],
      days: [ true, true, true, true, true, true, true ],
      min: JSON.parse(localStorage.getItem('Heatmap-min') || 0),
      max: JSON.parse(localStorage.getItem('Heatmap-max') || 30),
      dataToUse: JSON.parse(localStorage.getItem('Heatmap-dataToUse')) || "temperature"
    };
    this.t = props.t;
    if (this.state.SelectedGroup) {
      this.getBuildings(this.state.SelectedGroup, true);
    }
    this.getGroups();
  }

  showValuesChange(e) {
    const showValues = e === 2 ? true : false;
    this.setState({ showValues });
  }

  getGroups() {
    var url = Conf.BaseApi + 'groups/get';

    getRequest(url, (data) => {
      if (data.success) {
        this.setState({ Groups: data.result });
      }
    });
  }

  getBuildings(g, init) {
    var url = Conf.BaseApi + 'buildings/getSorted';

    localStorage.setItem('Plans-SelectedGroupName', JSON.stringify(g));

    postRequest(url, { pageSize: 1000, page: 0, sorted: null, filtered: [{ id: "group", value: g }] }, (data) => {
      if (data.success) {
        this.setState({ Buildings: data.result.rows, SelectedGroup: g }, () => {
          if (init && this.state.SelectedBuildingId) {
            this.selectInfra(this.state.SelectedBuildingId, true);
          }
        });
      }
    });
  }

  selectInfra(infraId, init) {
    const index = this.state.Buildings.findIndex(b => b._id == infraId);
    localStorage.setItem('Plans-SelectedBuildingId', JSON.stringify(infraId));
    this.setState({ Building: this.state.Buildings[index], SelectedBuildingId: infraId }, () => {
      if (init && this.state.SelectedFloorId) {
        this.selectFloor(this.state.SelectedFloorId, true);
      }
    });
  }

  selectFloor(floorId) {
    if (this.state.Building && this.state.Building.floors) {
      const index = this.state.Building.floors.findIndex(b => b._id == floorId);
      if (index !== -1) {
        localStorage.setItem('Plans-SelectedFloorId', JSON.stringify(floorId));
        // Get plan
        var url = Conf.BaseApi + 'plans/getOne/' + this.state.Building.floors[index].plan;

        getRequest(url, (data) => {
          if (data.success) {
            this.setState({ Floor: this.state.Building.floors[index], SelectedFloorId: floorId, Plan: data.result });
          }
        });
      }
    }
  }

  updateDay(event, day) {
    const { days } = this.state;
    days[day] = event.target.checked;

    this.setState({ days });
  }

  render() {
    const { days, Plan } = this.state;


    let buffer = undefined;
    if (Plan) {
      buffer = Buffer.from(Plan.blobPlan.data);
    }
    return (
      <div>
        <div className="card">
          <div className={`card-block`}>
            <Row>
              <Col xs="12" md='4' lg='4'>

                <select onChange={(e) => this.getBuildings(e.target.value)} defaultValue={this.state.SelectedGroup} className="form-control">
                  {this.generateGroupOption()}
                </select>
              </Col>
              <Col xs="12" md='4' lg='4'>
                <select onChange={(e) => this.selectInfra(e.target.value)} defaultValue={this.state.Building ? this.state.Building._id : ""} className="form-control">
                  {this.generateInfraList(this.state.Buildings)}
                </select>
              </Col>
              <Col xs="12" md='4' lg='4'>
                <select onChange={(e) => this.selectFloor(e.target.value)} defaultValue={this.state.Floor ? this.state.Floor._id : ""} className="form-control">
                  {this.generateFloorList(this.state.Building && this.state.Building.floors ? this.state.Building.floors : [])}
                </select>
              </Col>
            </Row>
          </div>
        </div>
        {this.state.Plan ?
          <Row>
            <Col md="3" className="param-column">
              <Card>
                <CardBody>
                  <div className="heatmap line">
                    <ButtonToolbar>
                      <ToggleButtonGroup type="radio" name="typeSelector" id="levelSelector" defaultValue={1} onChange={(e) => this.showValuesChange(e)}>
                        <ToggleButton value={1} bsStyle="primary">{this.t('Average')}</ToggleButton>
                        <ToggleButton value={2} bsStyle="primary">{this.t('Values')}</ToggleButton>
                      </ToggleButtonGroup>
                    </ButtonToolbar>
                  </div>
                  {!this.state.showValues ?
                  <div className="heatmap line">
                    <div className="title">Date</div>
                    <div className="half-part" >
                      <DatePicker
                        selected={this.state.from}
                        onChange={this.handleChangeFrom.bind(this)}
                        dateFormat="DD MMMM YYYY"
                        maxDate={this.state.to ? this.state.to.toDate() : new Date()}
                      />
                    </div>
                    <div className="half-part">
                      <DatePicker
                        selected={this.state.to}
                        onChange={this.handleChangeTo.bind(this)}
                        dateFormat="DD MMMM YYYY"
                        minDate={this.state.from ? this.state.from : new Date(0)}
                        maxDate={new Date()}
                      />
                    </div>
                  </div> : ''}
                  {!this.state.showValues ?
                  <div className="heatmap line">

                    <div className="title">{this.t("Days")}</div>

                    <div className="input-group mb-3">
                      <div className="form-check form-check-inline">
                        <label className="form-check-label">
                          <input className="form-check-input" type="checkbox" id="useTime" value="true" onChange={() => this.useTimeChange()}
                            defaultChecked={this.state.useTime} />
                          {" "}Filtrer les données par jours et heures
                        </label>
                      </div>
                    </div>
                    {this.state.useTime ?
                      <Row>
                        <Col md='6'>
                          <div className="input-group mb-3">
                            <span className="input-group-addon">{this.t('From')}</span>
                            <TimeField
                              style={{
                                border: '1px solid #666',
                                fontSize: 15,
                                width: '80%',
                                padding: '5px 8px',
                                color: '#333',
                                borderRadius: 3
                              }}
                              value={this.state.timeFrom}
                              onChange={(event, value) => { this.setState({ hourFrom: value }) }}
                            />
                          </div>
                        </Col>
                        <Col md='6'>
                          <div className="input-group mb-3">
                            <span className="input-group-addon">{this.t('To')}</span>
                            <TimeField

                              style={{
                                border: '1px solid #666',
                                fontSize: 15,
                                width: '80%',
                                padding: '5px 8px',
                                color: '#333',
                                borderRadius: 3
                              }}
                              value={this.state.timeTo}
                              onChange={(event, value) => { this.setState({ hourTo: value }) }}
                            />
                          </div>
                        </Col>
                      </Row>
                      : ''}
                    {this.state.useTime ? <br /> : '' }
                    {this.state.useTime ?
                      <div className="days-checkbox">
                        <input class="btn-check" type="checkbox" autocomplete="off" id="monday" value="true" defaultChecked={days[1]}
                        onChange={(e) => this.updateDay(e, 1)}/>
                        <label for="monday" role="button" tabindex="0" class={`mb-2 btn btn-outline-primary ${days[1] ? 'checked' : ''}`}>{this.t('Monday')}</label>
                      </div> : ''}
                    {this.state.useTime ?
                      <div className="days-checkbox">
                        <input class="btn-check" type="checkbox" autocomplete="off" id="tuesday" value="true" defaultChecked={days[2]}
                        onChange={(e) => this.updateDay(e, 2)}/>
                        <label for="tuesday" role="button" tabindex="0" class={`mb-2 btn btn-outline-primary ${days[2] ? 'checked' : ''}`}>{this.t('Tuesday')}</label>
                      </div> : ''}
                    {this.state.useTime ?
                      <div className="days-checkbox">
                        <input class="btn-check" type="checkbox" autocomplete="off" id="wednesday" value="true" defaultChecked={days[3]}
                        onChange={(e) => this.updateDay(e, 3)}/>
                        <label for="wednesday" role="button" tabindex="0" class={`mb-2 btn btn-outline-primary ${days[3] ? 'checked' : ''}`}>{this.t('Wednesday')}</label>
                      </div> : ''}
                      {this.state.useTime ? <br /> : ''}
                    {this.state.useTime ?
                      <div className="days-checkbox">
                        <input class="btn-check" type="checkbox" autocomplete="off" id="thursday" value="true" defaultChecked={days[4]}
                        onChange={(e) => this.updateDay(e, 4)}/>
                        <label for="thursday" role="button" tabindex="0" class={`mb-2 btn btn-outline-primary ${days[4] ? 'checked' : ''}`}>{this.t('Thursday')}</label>
                      </div> : ''}
                    {this.state.useTime ?
                      <div className="days-checkbox">
                        <input class="btn-check" type="checkbox" autocomplete="off" id="friday" value="true" defaultChecked={days[5]}
                        onChange={(e) => this.updateDay(e, 5)}/>
                        <label for="friday" role="button" tabindex="0" class={`mb-2 btn btn-outline-primary ${days[5] ? 'checked' : ''}`}>{this.t('Friday')}</label>
                      </div> : ''}
                    {this.state.useTime ?
                      <div className="days-checkbox">
                        <input class="btn-check" type="checkbox" autocomplete="off" id="saturday" value="true" defaultChecked={days[6]}
                        onChange={(e) => this.updateDay(e, 6)}/>
                        <label for="saturday" role="button" tabindex="0" class={`mb-2 btn btn-outline-primary ${days[6] ? 'checked' : ''}`}>{this.t('Saturday')}</label>
                      </div> : ''}
                      {this.state.useTime ? <br /> : '' }
                    {this.state.useTime ?
                      <div className="days-checkbox">
                        <input class="btn-check" type="checkbox" autocomplete="off" id="sunday" value="true" defaultChecked={days[0]}
                        onChange={(e) => this.updateDay(e, 0)}/>
                        <label for="sunday" role="button" tabindex="0" class={`mb-2 btn btn-outline-primary ${days[0] ? 'checked' : ''}`}>{this.t('Sunday')}</label>
                      </div> : ''}
                  </div> : ''}
                  <div className="heatmap line">
                    <div className="title">Mesure</div>
                    <select className="form-control" id="dataName" onChange={this.selectData.bind(this)}>
                      <option value="temperature">{this.t("SensorData.temperature")}</option>
                      <option value="humidity">{this.t("SensorData.humidity")}</option>
                      <option value="co2">{this.t("SensorData.co2")}</option>
                      <option value="occupancy">{this.t("SensorData.occupancy")}</option>
                      <option value="sound">{this.t("SensorData.sound")}</option>
                    </select>
                  </div>
                  <div className="heatmap line">
                    <div className="title">Heatmap values</div>
                    <br />
                    <Row className="heat-row">
                      <Col md="12" lg="6">
                        <div className="input-group mb-3">
                          <span className="input-group-addon">Min</span>
                          <input type="number" className="form-control" placeholder={this.t('Component.Min')} defaultValue={this.state.min} id="createMin"
                            onChange={(e) => {
                              localStorage.setItem('Heatmap-min', JSON.stringify(e.target.value));
                              this.setState({ min: e.target.value })
                            }} />
                        </div>
                      </Col>
                      <Col md="12" lg="6">
                        <div className="input-group mb-3">
                          <input type="number" className="form-control" placeholder={this.t('Component.Max')} defaultValue={this.state.max} id="createMax"
                            onChange={(e) => {
                              localStorage.setItem('Heatmap-max', JSON.stringify(e.target.value));
                              this.setState({ max: e.target.value })
                            }
                            } />
                          <span className="input-group-addon">Max</span>
                        </div>
                      </Col>
                    </Row>
                    <Row className="heat-row">
                      <Col md="12">
                        <GradientPicker onColorChange={(palette) => this.setState({ Palette: palette })} palette={this.state.Palette} />
                      </Col>
                    </Row>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col md="9">
              <Card style={{ width: "100%", height: "1000px" }}>
                <SvgHeatmap
                  rooms={Plan.rooms}
                  buffer={buffer}
                  min={this.state.min}
                  max={this.state.max}
                  showValues={this.state.showValues}
                  dataName={this.state.dataToUse}
                  days={this.state.days}
                  dateFrom={this.state.from} dateTo={this.state.to}
                  useHours={this.state.useTime}
                  hourFrom={this.state.hourFrom} hourTo={this.state.hourTo}
                  palette={this.state.Palette} />
              </Card>
            </Col>
          </Row> : ""}
      </div>

    )
  }

  generateGroupOption() {
    const toRender = [];

    toRender.push(<option value="">{this.t("Plan.SelectAGroup")}</option>);

    this.state.Groups.forEach((group) => {
      if (group.name === this.state.SelectedGroup) {
        toRender.push(<option value={group.name} selected="selected">{group.name}</option>);
      } else {
        toRender.push(<option value={group.name}>{group.name}</option>);
      }
    });

    return toRender;
  }

  generateInfraList(infras) {
    const toRender = [];

    toRender.push(<option value="">{this.t("Plan.SelectAnInfra")}</option>);

    infras.forEach((infra) => {
      if (infra._id === this.state.SelectedBuildingId) {
        toRender.push(<option value={infra._id} selected="selected">{infra.name}</option>);
      } else {
        toRender.push(<option value={infra._id}>{infra.name}</option>);
      }
    });

    return toRender;
  }

  generateFloorList(floors) {
    const toRender = [];

    toRender.push(<option value="">{this.t("Plan.SelectAFloor")}</option>);

    floors.forEach((floor) => {
      if (floor.plan) {
        if (floor._id === this.state.SelectedFloorId) {
          toRender.push(<option value={floor._id} selected="selected">{floor.name}</option>);
        } else {
          toRender.push(<option value={floor._id}>{floor.name}</option>);
        }
      }
    });

    return toRender;
  }

  handleChangeFrom(from) {
    this.setState({ from });
  }
  handleChangeTo(to) {
    this.setState({ to });
  }

  useTimeChange() {
    this.setState({ useTime: $("#useTime").is(':checked') });
  }


  selectData(evt) {
    const field = evt.target.value;
    localStorage.setItem('Heatmap-dataToUse', JSON.stringify(field));
    this.setState({ dataToUse: field });
  }
}

export default translate(Heatmap);
