/** *
*  Helper for asynch functions
*
*  @author: Alexandre Haulote
*  Date:   24th Aout 2018
*/

'use strict';

/**
 * try catch a promise return an array
 * @param {*} promise - the promise to catch
 * @return {Promise} - to return
 */
function to(promise) {
  return promise.then(data => [null, data])
    .catch(err => [err]);
}

module.exports = to;
