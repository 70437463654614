import React, { Component } from 'react';
import Conf from '../../../utils/Conf';
import { getRequest, postRequest } from '../../../utils/WebServicesManager';
import { getDateStr } from '../../../utils/Utils';
import { translate } from '../../../utils/ReactMultiLang';
import ReactTable from "react-table";
import checkboxHOC from "react-table/lib/hoc/selectTable";
import { Button } from 'reactstrap';
import 'react-table/react-table.css';
import diff from 'deep-diff';

class InfraList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      pages: 20,
      loading: false,
      selection: [],
      selectAll: false,
      pageSize: 10
    };
    this.fetchData = this.fetchData.bind(this);
    this.setBuildings = props.setBuildings;
    this.selectBuilding = props.selectBuilding;
    this.CheckboxTable = checkboxHOC(ReactTable);
    this.t = props.t;
  }
  

  requestData(pageSize, page, sorted, filtered) {
    return new Promise((resolve, reject) => {
      var url = Conf.BaseApi + 'buildings/getSorted';

      postRequest(url, {pageSize, page, sorted, filtered}, (data) => {
        if (data.success) {
          const res = {
            rows: data.result.rows,
            pages: data.result.pages
          };
          resolve(res);
        } else {
          reject(new Error(data.message))
        }
      });
    });
  }

  fetchData(state, instance) {
    // Whenever the table model changes, or the user sorts or changes pages, this method gets called and passed the current table model.
    // You can set the `loading` prop of the table to true to use the built-in one or show you're own loading bar if you want.
    this.setState({ loading: true });
    // Request the data however you want.  Here, we'll use our mocked service we created earlier
    if (!state) state = this.state.dataParam;

    this.setBuildings(state.sorted, state.filtered);

    this.requestData(
      state.pageSize,
      state.page,
      state.sorted,
      state.filtered
    ).then(res => {
      // Now just get the rows of data to your React Table (and update anything else like total pages or loading)
      this.setState({
        data: res.rows,
        pages: res.pages,
        loading: false,
        dataParam: state
      });
    });
  }

  render() {
    const { pages, loading } = this.state;
    const { isPlan, addPlan, CanEdit } = this.props;
    let columns = this.getColumns();
    if (isPlan && addPlan) columns = this.getColumnsPlan(addPlan, CanEdit);
    
    return (
      <div className="row">
        <div className="col-lg-12 col-md-12">
            <ReactTable key={this.state.buildingId}
              filterable={true}
              defaultFilterMethod={(filter, row) =>
                String(row[filter.id]).includes(filter.value)}
              data={this.state.data}
              manual // Forces table not to paginate or sort automatically, so we can handle it server-side
              pages={pages} // Display the total number of pages
              loading={loading}
              ref={r => (this.checkboxTable = r)}
              onFetchData={this.fetchData}
              columns={columns}
              building={this.state.buildingId}
              defaultPageSize={this.state.pageSize}
              className="-striped -highlight"
            />
        </div>
      </div>
    );
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (diff(nextState, this.state) ? true : false);
  }


  /** React-table */
  
  getColumns() {
    return [
      { id: 'name', accessor: d => <span className='fake-link' onClick={this.selectBuilding.bind(this, d._id)}>{d.name}</span>, Header: this.t('Name') }, 
      { Header: this.t('Group'), id: "group", accessor: d => d.group ? d.group.name : '' }, 
      { Header: this.t('City'), id: "address.city", accessor: d => d.address ? d.address.city : '' }, 
      { Header: this.t('Street'), id: "address.street", accessor: d => d.address ? d.address.street : '' }, 
      { Header: this.t('Tags'), id: "tags", accessor: d => d.tags ? d.tags.join(', ') : '' }
    ];
  }
  getColumnsPlan(addPlan, CanEdit) {
    if (CanEdit) {
      return [
        { id: 'name', accessor: d => <span className='fake-link' onClick={() => this.selectBuilding(d._id, false)}>{d.name}</span>, Header: this.t('Name') }, 
        { Header: this.t('Group'), id: "group", accessor: d => d.group ? d.group.name : '' }, 
        { Header: this.t('City'), id: "address.city", accessor: d => d.address ? d.address.city : '' }, 
        { Header: this.t('Street'), id: "address.street", accessor: d => d.address ? d.address.street : '' }, 
        { Header: this.t('Tags'), id: "tags", accessor: d => d.tags ? d.tags.join(', ') : '' }, 
        { Header: this.t('Plans'), id: "plans", accessor: d => d.plans ? d.plans.length : 0 }, 
        { Header: this.t('AddPlan'), id: "icon", accessor: d => <Button onClick={() => {addPlan(d)}}>{this.t('AddPlan')}</Button> }
      ];
    } else {
      
    return [
      { id: 'name', accessor: d => <span className='fake-link' onClick={() => this.selectBuilding(d._id, false)}>{d.name}</span>, Header: this.t('Name') }, 
      { Header: this.t('Group'), id: "group", accessor: d => d.group ? d.group.name : '' }, 
      { Header: this.t('City'), id: "address.city", accessor: d => d.address ? d.address.city : '' }, 
      { Header: this.t('Street'), id: "address.street", accessor: d => d.address ? d.address.street : '' }, 
      { Header: this.t('Tags'), id: "tags", accessor: d => d.tags ? d.tags.join(', ') : '' }, 
      { Header: this.t('Plans'), id: "plans", accessor: d => d.plans ? d.plans.length : 0 }
    ];
    }
  }
}

export default translate(InfraList);