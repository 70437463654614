import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';

class AlarmModel extends Component {
  render() {
    const { alarmModel } = this.props;
    return (
      <li>
      <i className={alarmModel.level === 2 ? 'icon-puzzle bg-danger' : 'icon-puzzle bg-warning'}></i>
      <div className="desc sensorListItem">
        <div className="title">{alarmModel.name}</div>
        <small>{alarmModel.dataName}
        {alarmModel.comparator === 0 ? '>' : alarmModel.comparator === 1 ? '<' : '='}
        {alarmModel.value}</small>
      </div>
      </li>
    );
  }
}

export default AlarmModel;
