import React, { Component } from 'react';
import Conf from '../../../utils/Conf';
import { Doughnut } from 'react-chartjs-2';
import { Dropdown, DropdownMenu, DropdownItem, 
    DropdownToggle, Progress } from 'reactstrap';
import { getRequest } from '../../../utils/WebServicesManager';
import { translate } from '../../../utils/ReactMultiLang';

class Image extends Component {
    constructor(props) {
        super(props);
        this.datas = {};
        this.options = {};
        this.state = {
            card1: false,
            isPublic: props.isPublic,
            isLocked: props.isLocked
        }
        this.t = props.t;
    }


    render() {
        
        const { buffer, deleteComponent } = this.props;

        return (
                <div className={"grid-element"}  ref='mainDiv' style={{ height:'100%'}}>
                    
                    {this.state.isPublic || this.state.isLocked ? '' : <div className="btn-group float-right">
                    <Dropdown isOpen={this.state.card1} toggle={() => { this.setState({ card1: !this.state.card1 }); }}>
                    <DropdownToggle onClick={() => { this.setState({ card1: !this.state.card1 }); }} className="btn active dropdown-toggle p-0" data-toggle="dropdown" aria-haspopup={true} aria-expanded={this.state.card1}>
                      <i className="icon-settings"></i>
                    </DropdownToggle>
                    <DropdownMenu right>
                        <DropdownItem onClick={() => {deleteComponent()} }><i className='icon-trash' style={{color: "#212121"}}></i>{this.t('Delete')}</DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                    </div>}
                    <img src={buffer.toString('utf8')} alt="icon" style={{width:"100%", height:"100%", marginTop: this.state.isPublic || this.state.isLocked ? '0px' : '-25px' }} className='gauge-class'/>
                </div>
        );
    }
}

export default translate(Image);
