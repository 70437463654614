import React, { Component } from 'react';
import { Dropdown, DropdownMenu, DropdownItem, 
    DropdownToggle } from 'reactstrap';
import { getRequest, reportGetRequest } from '../../../utils/WebServicesManager';
import { translate } from '../../../utils/ReactMultiLang';
import Conf from '../../../utils/Conf';

class OpenClose extends Component {
    constructor(props) {
        super(props);
        this.datas = {};
        this.options = {};
        this.state = {
            card1: false,
            isPublic: props.isPublic,
            isLocked: props.isLocked
        }
        this.getRequest = getRequest;
        if (props.isPublic) {
            this.getRequest = reportGetRequest;
        }
        this.t = props.t;
        this.sensor = props.sensor;
        this.getMessage(this.sensor);
    }

    getMessage(devEUI) {
        let url = Conf.BaseApi + 'messages/get/1/' + devEUI;
        this.getRequest(url, (dataDevice) => {
          if (dataDevice.success) {
            this.setState({ Message: dataDevice.result[0] });
          }
        });
    }

    render() {
        
        const { editComponent, deleteComponent, dataName, title } = this.props;

        let isOpen = false;
        if (this.state.Message) {
            isOpen = this.state.Message.data[dataName] != 0;
        }

        return (
                <div className={"grid-element"}  ref='mainDiv' style={{ height:'100%', backgroundColor: isOpen ? '#FF0042': '#09d7c2'}}>
                    <div className='bool-div'>
                        
                    {this.state.isPublic || this.state.isLocked ? '' :<div className="btn-group float-right">
                        <Dropdown isOpen={this.state.card1} toggle={() => { this.setState({ card1: !this.state.card1 }); }}>
                        <DropdownToggle onClick={() => { this.setState({ card1: !this.state.card1 }); }} className="btn active dropdown-toggle p-0" data-toggle="dropdown" aria-haspopup={true} aria-expanded={this.state.card1}>
                        <i className="icon-settings"></i>
                        </DropdownToggle>
                        <DropdownMenu right>
                            <DropdownItem onClick={() => {editComponent()} }><i className='icon-pencil' style={{color: "#212121"}}></i>{this.t('Edit')}</DropdownItem>
                            <DropdownItem onClick={() => {deleteComponent()} }><i className='icon-trash' style={{color: "#212121"}}></i>{this.t('Delete')}</DropdownItem>
                        </DropdownMenu>
                    </Dropdown> 
                        </div>}
                        <div>{title}</div>
                        <img className="img-bool" src={`img/icon/${isOpen ? 'isOpen': 'isClose'}.png`} />
                        <div>{isOpen ? this.t('Open'): this.t('Close')}</div>
                    </div>
                </div>
        );
    }
}

export default translate(OpenClose);
