import React, { Component } from 'react';
import Conf from '../../utils/Conf';
import { getDateStr } from '../../utils/Utils';
import { getRequest } from '../../utils/WebServicesManager';
import { translate } from '../../utils/ReactMultiLang';
import { Row,
  Modal, ModalHeader
} from 'reactstrap';
import DeliveryTable from './components/DeliveryTable';
import { check } from '../../utils/Can';
import DeliveryForm from './components/DeliveryForm';
import SendDeliveryForm from './components/SendDeliveryForm';

class Manage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Rounds: [],
      CanEdit: check('round:edit'),
      CanCreate: check('round:create'),
      CanRemove: check('round:remove'),
      ModalOpen: false,
      SendModalOpen: false,
      delivery: undefined,
      keyTable: 0
    };
    this.t = props.t;
  }

  selectSendDelivery(delivery) {
    this.setState({ delivery })
    this.toggleSendModal(true)
  }

  unselectSendDelivery() {
    console.log('toto')
    this.setState({ delivery: undefined })
    this.toggleSendModal(false)
  }
 
  selectDelivery(delivery) {
    this.setState({ delivery })
    this.toggleModal(true)
  }

  unselectDelivery() {
    this.setState({ delivery: undefined, keyTable: this.state.keyTable + 1 })
    this.toggleModal(false)
  }


  toggleModal(isOpen) {
    this.setState({ ModalOpen: isOpen })
  }
  toggleSendModal(isOpen) {
    this.setState({ SendModalOpen: isOpen })
  }

  getActDelivry(delivries) {
    const toRender = [];

    const date = new Date();
    date.setMonth(date.getMonth() - 1);
    for (let i = 0; i < delivries.length; i += 1) {
      if (!delivries[i].dateTo || new Date(delivries[i].dateTo) > date) {
        toRender.push(<li>{delivries[i].deliveryMan.name} {delivries[i].deliveryMan.firstName} <small>{getDateStr(delivries[i].dateFrom)} - {delivries[i].dateTo ? getDateStr(delivries[i].dateTo) : ''} </small></li>);
      }
    }

    return toRender;
  }

  renderRoundsList() { // TODO
    var toRender = [];
    for (var i = 0; i < this.state.Rounds.length; i++) {
      toRender.push(
        <li key={i + this.state.Rounds[i]._id}>
          <h3>{this.state.Rounds[i].name}</h3>
          <p><b>{this.t('Delivry.Manager')}: </b>{this.state.Rounds[i].manager.name} {this.state.Rounds[i].manager.firstName}</p>
          <p><b>{this.t('Delivry.Man')}: </b><ul>{this.getActDelivry(this.state.Rounds[i].delivries)}</ul></p>
          <p><b>{this.t('Delivry.Alarm')}: </b>{this.state.Rounds[i].alarm.name}</p>
          <p><b>{this.t('Alarm.UserToPrevent')}: </b>{this.state.Rounds[i].toSend.join(', ')}</p>
        </li>
      );
    }
    return toRender;
  }

  render() {
    return (
      <div className="row">
        <div className="col-lg-12 col-md-12">
          <div className="card">
            <div className="card-header">
              <i className="fa fa-align-justify"></i> {this.t('Delivry.List')}
              {/*<Button className="block" onClick={() => getRequest(`${Conf.BackenBase}/delivery/generate`, () => {})}>Test generation</Button>*/}
            </div>
            <div className="card-block" key={this.state.keyTable}>
              <DeliveryTable selectDelivery={(d) => this.selectDelivery(d)} selectSendDelivery={(d) => this.selectSendDelivery(d)} />
              <br />
              {this.state.CanCreate ? <button type="button" onClick={() => this.toggleModal(true)} className="btn btn-primary btn-block" >{this.t('Delivery.Add')}</button> : ""}
            </div>
          </div>
        </div>
        <Modal isOpen={this.state.ModalOpen} className={'modal-lg ' + this.props.className}>
          <ModalHeader>
            <Row style={{ paddingLeft: '15px', paddingRight: '15px' }}>
              <h1>{this.state.delivery !== undefined ? this.t('Edit') : this.t('Add')}</h1>
              <i className="icon-close color-danger" onClick={() => this.unselectDelivery()}
                style={{ position: 'absolute', textAlign: 'right', right: '15px', cursor: 'pointer' }}></i>
            </Row>
          </ModalHeader>
          <DeliveryForm delivry={this.state.delivery} callback={() => this.unselectDelivery()} />
        </Modal>
        <Modal isOpen={this.state.SendModalOpen} className={'modal-lg ' + this.props.className}>
          <ModalHeader>
            <Row style={{ paddingLeft: '15px', paddingRight: '15px' }}>
              <h3>{this.t('Send')}: {this.state.delivery ? this.state.delivery.name : ''}<br />
              <small style={{fontSize:'0.5em'}}>L'email va mettre quelques minutes à être envoyé</small></h3>
              <i className="icon-close color-danger" onClick={() => this.unselectSendDelivery()}
                style={{ position: 'absolute', textAlign: 'right', right: '15px', cursor: 'pointer' }}></i>
            </Row>
          </ModalHeader>
          <SendDeliveryForm delivry={this.state.delivery} callback={() => this.unselectSendDelivery()} />
        </Modal>
      </div >
    )
  }
}

export default translate(Manage);
