import React, { Component } from 'react';
import Conf from '../../../utils/Conf';
import $ from 'jquery';
import { getRequest, postRequest } from '../../../utils/WebServicesManager';
import { Modal, Button } from 'reactstrap';
import { translate } from '../../../utils/ReactMultiLang';
import Map from './Map/Map.js';
import BuildingDetails from './BuildingDetails';
import AddBuilding from './AddBuilding/AddBuilding.js';
import 'react-accessible-accordion/dist/fancy-example.css';
import InfraList from '../../../components/Infrastructures/InfraList/InfraList';

class Manage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      User: {},
      Building: {},
      Buildings: [],
      Filters: [],
      Sensors: [],
      Messages: [],
      floor: undefined,
      ModalAddFloor: false,
      ModalEditBuild: false,
      ModalAddRoom: false,
      Key: 0,
      TableOpen: true,
      MapOpen: true,
      CreateInfra: false
    };
    const state = this.getInitialState();
    if (state !== {}) {
      this.state = state;
    }
    this.getUser();
    this.getBuildingDetails = this.getBuildingDetails.bind(this);
    this.t = props.t;
  }
  getInitialState () {
    const state = JSON.parse(localStorage.getItem('ManageBuilding') || '{}');
    if (this.state !== {}) {
      if (!state.Building) {
        state.Building = {};
      }
    }
    return state;
  }

  getUser() {
    let url = Conf.BaseApi + 'user';
    return getRequest(url, (data) => {
      if (data.success)
        this.setState({ User: data.result });
    });
  }

  setBuildings(sorted, filters) {
    this.getBuildings(sorted, filters);
  }

  getBuildings(sorted, filtered) {
      var url = Conf.BaseApi + 'buildings/getSorted';

      postRequest(url, {pageSize: 1000, page: 0, sorted, filtered}, (data) => {
        if (data.success) {
          this.setState({Buildings: data.result.rows});
        }
      });
  }

  toggleEdit() {
    this.setState({ ModalEditBuild: !this.state.ModalEditBuild });
  }

  getBuildingDetails(id, sensors, fromMap) {
    if (!id && this.state.Building !== undefined) {
      id = this.state.Building._id;
    }
    let url = Conf.BaseApi + 'buildings/get/' + id;
    getRequest(url, (data) => {
      if (data.success) {
        url = Conf.BaseApi + 'devices/building/' + id;
        getRequest(url, (dataS) => {
          if (dataS.success) {
            this.customSetState({ Building: data.result, Sensors: dataS.result });
          } else {
            this.customSetState({ Building: data.result });
          }
          this.customSetState({ ModalEditBuild: false, ModalAddFloor: false, ModalAddRoom: false, MapOpen: fromMap === true ? true : false, TableOpen: false });
        });
      }
    });
  }

  removeBuilding(building) {
      var r = window.confirm(`${this.t('Popup.Delete.Building')} ${building.name} ?`);
      if (r == true) {
        var url = Conf.BaseApi + Conf.RemoveBuilding;
        postRequest(url, {
          buildId: building._id,
          groupId: building.group
        }, (data) => {
          if (data.success && data.success == true) {
            this.customSetState({Key: this.state.Key + 1, Building: {}});
          }
        });
      }
  }
  toggleTable() {
    this.customSetState({TableOpen: !this.state.TableOpen});
  }

  toggleMap() {
    this.customSetState({MapOpen: !this.state.MapOpen});
  }

  render() {
    return (
      <div className="row">
        <div className="col-xl-6">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-header hiddableHeader" onClick={() => this.toggleTable()}>
                  <i className="fa fa-building-o"></i> {this.t('Infrastructures')}
                </div>
                <div className={`card-block ${this.state.TableOpen ? 'div-visible' : ''} hiddable`} id="hiddableInfra">
                  <InfraList
                    key={this.state.Key}
                    setBuildings={this.setBuildings.bind(this)}
                    selectBuilding={this.getBuildingDetails.bind(this)}/>
                </div>
              </div>
            </div>
        </div>
        <div className="col-xl-6">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-header hiddableHeader" onClick={() => this.toggleMap()} >
                  
              <div className="row">
                  <div className="col-lg-8"><i className="fa fa-map-o"></i> {this.t('Map')}</div>
                  <div className="col-lg-4">
                        <button onClick={(event) => {
                          event.stopPropagation();
                          this.setState({CreateInfra: true});
                          }} className="btn btn-block btn-success" style={{top:"0px", right: "10px", position:"absolute"}}>{this.t('Infra.Create')}</button>
                  </div>
                  </div>
                </div>
                <div className={`card-block ${this.state.MapOpen ? 'div-visible' : ''} hiddable map`} id='hiddable'>
                  <Map
                    selectBuilding={this.getBuildingDetails.bind(this)}
                    buildings={this.state.Buildings}
                    createInfra={this.state.CreateInfra}
                    closePopup={() => this.setState({CreateInfra: false})}/>
                </div>
              </div>
          </div>
        </div>
        <div className="col-xl-12">
            <BuildingDetails building={this.state.Building}
              getBuildingDetails={this.getBuildingDetails.bind(this)}
              removeBuilding={this.removeBuilding.bind(this)}/>
        </div>
      </div>

    )
  }

  mapFilter() {

  }
  
  customSetState(state) {
    this.setState(state, () => localStorage.setItem('ManageBuilding', JSON.stringify(this.state)));
  }
}

export default translate(Manage);
