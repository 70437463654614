import React, { useState, Component } from 'react';
import { SketchPicker } from 'react-color';
import { GradientPickerPopover } from 'react-linear-gradient-picker';
import 'react-linear-gradient-picker/dist/index.css';

class GradientPicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      palette: props.palette,
      displayColorPicker: false,
      open: false,
      angle: 90
    };
    this.WrappedColorPicker = ({ onSelect, ...rest }) => (
      <SketchPicker {...rest} onChange={c => {
        onSelect(`rgb(${c.rgb.r}, ${c.rgb.g}, ${c.rgb.b})`, c.rgb.a);
      }}/>
    );
  }

  setOpen(open) {
    this.setState({ open });
  }

  setAngle(angle) {
    this.setState({ angle });
  }

  setPalette(palette) {
    this.setState({ palette });
    this.props.onColorChange(palette);
  }

  render() {
    return (
      <GradientPickerPopover {...{
          open: this.state.open,
          setOpen: this.setOpen.bind(this),
          angle: this.state.angle,
          setAngle: this.setAngle.bind(this),
          showAnglePicker: false,
          width: 320,
          paletteHeight: 32,
          palette: this.state.palette,
          onPaletteChange: this.setPalette.bind(this)
      }}>
          <this.WrappedColorPicker />
      </GradientPickerPopover>
    )
  }
}

export default GradientPicker;
