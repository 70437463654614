import React, { Component } from 'react';
import $ from 'jquery';
import Conf from '../../../utils/Conf';
import { arrayContainId } from '../../../utils/Utils';
import { getRequest, postRequest } from '../../../utils/WebServicesManager';
import { Modal, ModalHeader, ModalBody, Col, Row } from 'reactstrap';
import SelectGroup from '../../Selects/SelectGroup/SelectGroup';
import { translate } from '../../../utils/ReactMultiLang';
import { getUserId } from '../../../utils/AuthServices';
import CreateImage from '../../ReportModals/CreateImage/CreateImage';
import SelectAlarmModels from '../../Selects/SelectAlarmModel/SelectAlarmModel';


class EditOpeningTime extends Component {
  constructor(props) {
    super(props);
    this.state = {
      buildingId: props.building,
      OpeningTime: props.OpeningTime,
      Key: 0
    }
    this.t = props.t;
  }

  
  componentWillReceiveProps(nextProps) {
    if (nextProps.OpeningTime !== this.props.OpeningTime) {
      this.setState({OpeningTime: nextProps.OpeningTime});
    }
    if (nextProps.building !== this.props.building) {
      this.setState({buildingId: nextProps.building});
    }
  }

  editOpTime(callback) {
    var url = Conf.BaseApi + 'buildings/manageOpen';

    return postRequest(url, {
      openingTime: this.state.OpeningTime, buildingId: this.state.buildingId
    }, (data) => {
      if (data.success || data.success == true) {
        callback(data.result);
      } else {
        $("#error-window").text(data.message);
      }
    });
  }

  timeChange(dIndex, pos, isFrom, isH, evt) {
    let opTime = this.state.OpeningTime;
    const day = Conf.WeekDay[dIndex];
    const val = parseInt(evt.target.value, 10);

    if (opTime && opTime[day] && opTime[day][pos]) {
        if (isFrom) {
          opTime[day][pos].from = isH ? opTime[day][pos].from % 60 + val * 60 : Math.floor(opTime[day][pos].from / 60) * 60 + val;
        } else {
          opTime[day][pos].to = isH ? opTime[day][pos].to % 60 + val * 60 : Math.floor(opTime[day][pos].to / 60) * 60 + val;
        }
        this.setState({OpeningTime: opTime});
    }
  }

  addTime(index) {
    let opTime = this.state.OpeningTime;
    const day = Conf.WeekDay[index];

    if (opTime) {
      if (opTime[day]) {
        opTime[day].push({from: 0, to: 0});
      } else {
        opTime[day] = [{from: 0, to: 0}];
      }
    } else {
      opTime = {[day]: [{from: 0, to: 0}]};
    }
    this.setState({OpeningTime: opTime});
  }
  rmTime(dIndex, pos) {
    let opTime = this.state.OpeningTime;
    const day = Conf.WeekDay[dIndex];

    if (opTime) {
      if (opTime[day]) {
        opTime[day].splice(pos, 1);
        this.setState({OpeningTime: opTime});
      } else {
      }
    }
  }

  render() {
    const { callback } = this.props;
    const { OpeningTime } = this.state;
    return (
        <ModalBody>
        <div className="flex-row align-items-center">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-12">
                <div className="card mx-12">
                  <div className="card-block p-12">
                    <div className="input-group mb-3" style={{ color: "red" }} id="error-window">

                    </div>
                    <table key={this.state.Key}>
                      {Conf.WeekDay.map((d, dIndex) => {
                        return (
                          <tr>
                            <td style={{verticalAlign:'top', paddingTop: "5px"}}>{this.t(d)}</td>
                            { OpeningTime && OpeningTime[d] ?
                              OpeningTime[d].map((t, idx) => {
                                const toRender = [];
                                if (idx > 0) {
                                toRender.push(<td><div className="input-group mb-3" style={{textAlign:'center', width:'30px'}}>
                                </div></td>);
                                }
                                toRender.push(<td>
                                <div className="input-group mb-3">
                                  <input type="number" className="form-control small"
                                  onChange={this.timeChange.bind(this, dIndex, idx, true, true)}
                                  placeholder="Hours" id={`${d}Hours${idx}`} defaultValue={parseInt(t.from / 60, 10)}/>
                                </div>
                              </td>);
                                toRender.push(<td>
                                <div className="input-group mb-3">
                                  <input type="number" className="form-control small"
                                  onChange={this.timeChange.bind(this, dIndex, idx, true, false)}
                                  placeholder="Minutes" id={`${d}Minutes${idx}`} defaultValue={t.from % 60}/>
                                </div>
                              </td>);
                                toRender.push(<td>
                                  
                                <div className="input-group mb-3">
                                  <img src='./img/minus.png' width='15px' onClick={this.rmTime.bind(this, dIndex, idx)}/>
                                </div></td>);
                                toRender.push(<td>
                                <div className="input-group mb-3">
                                  <input type="number" className="form-control small"
                                  onChange={this.timeChange.bind(this, dIndex, idx, false, true)}
                                  placeholder="Hours" id={`${d}Hours${idx}`} defaultValue={parseInt(t.to / 60, 10)}/>
                                </div>
                              </td>);
                                toRender.push(<td>
                                <div className="input-group mb-3">
                                  <input type="number" className="form-control small"
                                  onChange={this.timeChange.bind(this, dIndex, idx, false, false)}
                                  placeholder="Minutes" id={`${d}Minutes${idx}`} defaultValue={t.to % 60}/>
                                </div>
                              </td>);
                                return toRender;
                               }) : ''}
                               <td>
                                <div className="input-group mb-3">
                                  <img src='./img/add.png' width='25px' onClick={this.addTime.bind(this, dIndex)}/>
                                </div>
                              </td>
                          </tr>
                        );
                      })
                    }
                    </table>
                    <button type="button" className="btn btn-block btn-success" onClick={this.editOpTime.bind(this, callback)}>{this.t('Update')}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ModalBody>
    )
  }
}

export default translate(EditOpeningTime);