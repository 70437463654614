import React, { Component } from 'react';
import { Dropdown, DropdownMenu, DropdownItem, 
    DropdownToggle, Progress } from 'reactstrap';
import { translate } from '../../../utils/ReactMultiLang';

class Title extends Component {
    constructor(props) {
        super(props);
        this.datas = {};
        this.options = {};
        this.state = {
            card1: false,
            isPublic: props.isPublic,
            isLocked: props.isLocked
        }
        this.t = props.t;
    }


    render() {
        
        const { title, size, color, deleteComponent, editComponent, printBreaker } = this.props;
        
        return (
                <div className={`grid-element ${printBreaker ? 'page-break' : ''}`} ref='mainDiv' style={{height:'100%'}}>
                    <div className="card-block pb-0">
                    
                    {this.state.isPublic || this.state.isLocked ? '' :
                    <div className="btn-group float-right">
                        <Dropdown isOpen={this.state.card1} toggle={() => { this.setState({ card1: !this.state.card1 }); }}>
                            <DropdownToggle onClick={() => { this.setState({ card1: !this.state.card1 }); }} className="btn active dropdown-toggle p-0" data-toggle="dropdown" aria-haspopup={true} aria-expanded={this.state.card1}>
                                <i className="icon-settings"></i>
                            </DropdownToggle>
                            <DropdownMenu right>
                                <DropdownItem onClick={() => {editComponent()} }><i className='icon-pencil' style={{color: "#212121"}}></i>{this.t('Edit')}</DropdownItem>
                                <DropdownItem onClick={() => {deleteComponent()} }><i className='icon-trash' style={{color: "#212121"}}></i>{this.t('Delete')}</DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    </div>}
                        <p style={{fontSize: size, color: color}}>{title}</p>
                    </div>
                </div>
        );
    }
}

export default translate(Title);
