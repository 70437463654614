import React, { Component } from 'react';
import { Dropdown, DropdownMenu, DropdownItem, Progress, DropdownToggle } from 'reactstrap';
import { translate } from '../../../../utils/ReactMultiLang';

class AverageData extends Component {
  constructor(props) {
    super(props);
    this.state = {
        dropdownOpen: false
    };
    this.t = props.t;
  }

  render() {
    const { title, messages, paramName, color, icon, dataSigle, maxData, data, editComponent, deleteComponent } = this.props;
    var tempAverage = 0;
    if (paramName != undefined) {
      for (var i = 0; i < this.props.messages.length; i++) {
        var actData = parseFloat(this.props.messages[i].data[paramName]);
        tempAverage = tempAverage + actData;
      }
      if (tempAverage != 0)
        tempAverage = tempAverage / this.props.messages.length;
      tempAverage = Math.round(tempAverage * 10) / 10;
    } else {
      tempAverage = data;
    }
    if (isNaN(tempAverage))
      tempAverage = 0;
    return (
          <div className={"card card-inverse " + color + " animated fadeIn"}>
              <div className="card-block">
                
                <div className="btn-group float-right">
                <Dropdown isOpen={this.state.card1} toggle={() => { this.setState({ card1: !this.state.card1 }); }}>
                <DropdownToggle onClick={() => { this.setState({ card1: !this.state.card1 }); }} className="btn btn-transparent active dropdown-toggle p-0" data-toggle="dropdown" aria-haspopup="true" aria-expanded={this.state.card1}>
                  <i className="icon-settings"></i>
                </DropdownToggle>
                <DropdownMenu right>
                    <DropdownItem onClick={() => {editComponent()} }><i className='icon-pencil' style={{color: "#212121"}}></i>{this.t('Edit')}</DropdownItem>
                    <DropdownItem onClick={() => {deleteComponent()} }><i className='icon-trash' style={{color: "#212121"}}></i>{this.t('Delete')}</DropdownItem>
                </DropdownMenu>
              </Dropdown>
                </div>
                <div className="h4 mb-0">
                {tempAverage}
                  {dataSigle}</div>
                <small className="text-muted text-uppercase font-weight-bold">{this.props.title}</small>
                <Progress className="progress progress-white progress-xs mt-3 mb-0" value={tempAverage} max={maxData} />
              </div>
            </div>
    )
  }
}

export default translate(AverageData);
