import React, { Component } from "react";
import $ from 'jquery';
import { DiagramEngine, NodeModel, Toolkit, BaseWidget, BaseWidgetProps, DefaultPortLabel } from "storm-react-diagrams";


class ValueNodeWidget extends BaseWidget {
	constructor(props) {
		super("srd-node", props);
		this.state = {};
	}

	generatePort(port) {
		return <DefaultPortLabel model={port} key={port.id} />;
	}

	updateValue() {
		this.props.node.setValue($(`#${this.props.node.id}value`).val());
	}

	render() {
		const {picto} = this.props.node;
		return (
			<div {...this.getProps()}  className={"complex-node node-value " + this.props.node.color}>
				<div className={this.bem("__title")}>
					<div className={this.bem("__name")}>
					<img src={`./img/picto/${picto}.png`} />{" "}{this.props.node.name}<br/>
					<input type='number' name="inputValue" id={this.props.node.id + 'value'} 
						defaultValue={this.props.node.getValue()}
						onChange={this.updateValue.bind(this)}/></div>
				</div>
				<div className={this.bem("__ports")}>
					<div className={this.bem("__out")}>
						{Object.keys(this.props.node.getPorts()).map((key) => this.generatePort(this.props.node.getPorts()[key]))}
					</div>
				</div>
			</div>
		);
	}
}

export default ValueNodeWidget;