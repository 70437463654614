import React, { Component } from 'react';
import ReactTable from "react-table";
import Conf from '../../../utils/Conf';
import { postRequest, getRequest } from '../../../utils/WebServicesManager';
import { translate } from '../../../utils/ReactMultiLang';
import { getDateStr } from '../../../utils/Utils';
import { Row, Col, Button } from 'reactstrap';
import { check } from '../../../utils/Can';
import { getUserIsNecio } from '../../../utils/AuthServices';

class DeliveryTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      pages: 1,
      loading: false,
      dataParam: {},
      MsgKey: 0,
      CanEdit: check('round:edit'),
      CanRemove: check('round:remove'),
    };
    this.t = props.t;
    this.fetchData = this.fetchData.bind(this);
  }
  changeMsgTableKey() {
    this.setState({MsgKey: this.state.MsgKey + 1})
  }

  requestData(pageSize, page, sorted, filtered) {
    return new Promise((resolve, reject) => {
      var url = Conf.BaseApi + 'deliveryRounds/getSorted';

      postRequest(url, {
        pageSize,
        page,
        sorted,
        filtered
        }, (data) => {
        if (data.success) {
          const res = {
            rows: data.result.rows,
            pages: data.result.pages
          };
          resolve(res);
        } else {
          const res = {
            rows: [],
            pages: 0
          };
          resolve(res);
          //reject(new Error(data.message))
        }
      });
    });
  }
  getActDelivry(delivries) {
    const toRender = [];

    const date = new Date();
    date.setMonth(date.getMonth() - 1);
    for (let i = 0; i < delivries.length; i += 1) {
      if (!delivries[i].dateTo || new Date(delivries[i].dateTo) > date) {
        toRender.push(<li>{delivries[i].deliveryMan.name} {delivries[i].deliveryMan.firstName} <small>{getDateStr(delivries[i].dateFrom)} - {delivries[i].dateTo ? getDateStr(delivries[i].dateTo) : ''} </small></li>);
      }
    }

    return toRender;
  }

  fetchData(state) {
    // Whenever the table model changes, or the user sorts or changes pages, this method gets called and passed the current table model.
    // You can set the `loading` prop of the table to true to use the built-in one or show you're own loading bar if you want.
    this.setState({ loading: true });
    // Request the data however you want.  Here, we'll use our mocked service we created earlier
    if (!state) state = this.state.dataParam;

    this.requestData(
      state.pageSize,
      state.page,
      state.sorted,
      state.filtered
    ).then(res => {
      // Now just get the rows of data to your React Table (and update anything else like total pages or loading)
      this.setState({
        data: res.rows,
        pages: res.pages,
        loading: false,
        dataParam: state
      });
    });
  }

  render() {
    const { pages, loading } = this.state;
    const columns = this.getColumns();
    
    return (
        <div className="col-lg-12 col-md-12"
        key={this.state.MsgKey} >
            <ReactTable
              filterable={true}
              defaultFilterMethod={(filter, row) =>
                String(row[filter.id]).includes(filter.value)}
              data={this.state.data}
              manual // Forces table not to paginate or sort automatically, so we can handle it server-side
              pages={pages} // Display the total number of pages
              loading={loading}
              onFetchData={this.fetchData}
              columns={columns}
              defaultPageSize={10}
              className="-striped -highlight"
              SubComponent={({ row }) => {
                const toRender = (<Col><b>{this.t('Delivry.Man')}: </b><ul>{this.getActDelivry(row._original.delivries)}</ul></Col>)
                return (<Row>{ toRender }</Row>);
              }}
            />
        </div>
    );
  }

  getColumns() {
    const columns = [
      { id: 'name', accessor: d => d.name, Header: this.t('Name') },
      { id: 'group', accessor: d => d.group ? d.group.name : '', Header: this.t('Groups') },
      { id: 'manager', accessor: d => d.manager ? d.manager.name : '', Header: this.t('Delivry.Manager')},
      { id: 'alarm', accessor: d => d.alarm ? d.alarm.name : '', Header: this.t('Delivry.Alarm') },
      { id: 'reportTemplate', accessor: d => d.reportTemplate ? this.t('Yes') : this.t('No'), Header: this.t('Delivry.reportTemplate'), filterable: false },
      { id: 'sensor', accessor: d => d.sensor ? d.sensor.name : '', Header: this.t('Sensor.Sensor') },
      { id: 'toSend', accessor: d => d.toSend ? d.toSend.join(',') : '', Header: this.t('Delivry.toSend') },
      { id: 'toDelete', accessor: d => d.toDelete ? this.t('Yes') : this.t('No'), Header: this.t('Delivry.toDelete'), filterable: false }
    ];
    if (this.state.CanEdit) {
      columns.push({
        id: 'edit',
        accessor: d => <Button onClick={() => this.props.selectDelivery(d)} color="success" className="btn btn-success" style={{ marginTop: "-5px", marginBottom: "-5px",  fontSize: "0.75em", lineHeight: "0.9em" }}><i className="icon-pencil"></i></Button>,
        width: 50,
        filterable: false });
    }
    if (this.state.CanRemove) {
      columns.push({
        id: 'remove',
        accessor: d => <Button onClick={() => this.removeOneDelivery(d)} color="danger" className="btn btn-danger" style={{ marginTop: "-5px", marginBottom: "-5px",  fontSize: "0.75em", lineHeight: "0.9em" }}><i className="icon-trash"></i></Button>,
        width: 50,
        filterable: false });
    }
    if (this.state.CanRemove && getUserIsNecio() == 1) {
      columns.push({
        id: 'send',
        accessor: d => <Button onClick={() => this.props.selectSendDelivery(d)} color="info" className="btn btn-info" style={{ marginTop: "-5px", marginBottom: "-5px",  fontSize: "0.75em", lineHeight: "0.9em" }}><i className="icon-paper-plane"></i></Button>,
        width: 50,
        filterable: false });
    }
    columns.push({
        expander: true,
        Header: () => <strong>{this.t('More')}</strong>,
        width: 65,
        Expander: ({ isExpanded, ...rest }) =>
          <div>
            {isExpanded
              ? <span>&#x2299;</span>
              : <span>&#x2295;</span>}
          </div>,
        style: {
          cursor: "pointer",
          fontSize: 25,
          padding: "0",
          textAlign: "center",
          userSelect: "none"
        }
      })
    return columns;
  }

  removeOneDelivery(delivery) {
    var r = window.confirm(`${this.t(`Popup.Delete.DeliveryRound`)} \n${delivery.name}`);
    if (r == true) {
      var url = Conf.BaseApi + 'deliveryRounds/remove/' + delivery._id;
      getRequest(url, (data) => {
        if (data.success && data.success == true) {
          this.changeMsgTableKey();
        }
      });
    }
  }

}

export default translate(DeliveryTable);