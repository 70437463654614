import * as SRD from 'storm-react-diagrams';
import ValueNodeFactory from './ValueNode/ValueNodeFactory';
import SensorNodeFactory from './SensorNode/SensorNodeFactory';
import ComparatorNodeFactory from './ComparatorNode/ComparatorNodeFactory';
import OperatorNodeFactory from './OperatorNode/OperatorNodeFactory';
import AlarmNodeFactory from './AlarmNode/AlarmNodeFactory';

class Manager {
    constructor(diagram) {
        this.diagramEngine = new SRD.DiagramEngine();
        this.diagramEngine.installDefaultFactories();
        this.diagramEngine.registerNodeFactory(new ValueNodeFactory());
        this.diagramEngine.registerNodeFactory(new SensorNodeFactory());
        this.diagramEngine.registerNodeFactory(new ComparatorNodeFactory());
        this.diagramEngine.registerNodeFactory(new OperatorNodeFactory());
        this.diagramEngine.registerNodeFactory(new AlarmNodeFactory());

        if (diagram && diagram !== undefined) {
            this.setDiagramModel(diagram);
        } else {
            this.newModel();
        }
    }

    newModel() {
        this.activeModel = new SRD.DiagramModel();
        this.diagramEngine.setDiagramModel(this.activeModel);
    }

    getActiveDiagram() {
        return this.activeModel;
    }

    getDiagramEngine() {
        return this.diagramEngine;
    }
	
    SerializeModel() {
        return this.activeModel.serializeDiagram();
    }

    setDiagramModel(diagram) {
        this.activeModel = new SRD.DiagramModel();
        this.activeModel.deSerializeDiagram(JSON.parse(diagram), this.diagramEngine);
        this.diagramEngine.setDiagramModel(this.activeModel);
    }
}
export default Manager;