import React, { Component } from 'react';
import $ from 'jquery';
import Conf from '../../../../utils/Conf';
import { postRequest } from '../../../../utils/WebServicesManager';
import { translate } from '../../../../utils/ReactMultiLang';


class DownlinkBuilder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Sensors: props.sensors,
      Downlinks: props.downlinks,
      DownlinkValues: {},
      Callback: props.callback,
      SelectedDown: props.downlinks ? 0 : -1
    }
    this.t = props.t;
  }


  componentWillReceiveProps(nextProps) {
    if (nextProps.sensors !== this.props.sensors) {
      this.setState({ Sensors: nextProps.sensors });
    }
    if (nextProps.downlinks !== this.props.downlinks) {
      this.setState({
        Downlinks: nextProps.downlinks,
        DownlinkValues: [],
        SelectedDown: nextProps.downlinks ? 0 : -1
      });
    }
  }

  postSimpleDownlink(payload, deviceIds, port, index, callback) {
    if (index >= deviceIds.length) {
      return callback(null);
    }
    var url = Conf.BaseApi + 'downlinks/send';
    return postRequest(url, {
      payload,
      deviceId: deviceIds[index],
      port
    }, (data) => {
      if (data.success) {
        callback({ deviceId: deviceIds[index], result: 'Success' });
      } else {
        callback({ deviceId: deviceIds[index], result: 'Fail' });
      }
      if (index < deviceIds.length) {
        this.postSimpleDownlink(payload, deviceIds, port, index + 1, callback);
      }
    });
  }

  postDownlink(downlink, datas, deviceIds, index, callback) {
    if (index >= deviceIds.length) {
      return callback(null);
    }
    var url = Conf.BaseApi + 'downlinks/computed/send';
    return postRequest(url, {
      downlink,
      deviceId: deviceIds[index],
      datas
    }, (data) => {
      if (data.success) {
        callback({ deviceId: deviceIds[index], result: 'Success' });
      } else {
        callback({ deviceId: deviceIds[index], result: 'Fail' });
      }
      if (index < deviceIds.length) {
        this.postDownlink(downlink, datas, deviceIds, index + 1, callback);
      }
    });
  }
  sendDownlink(sensors, callback) {
    const result = [];
    const deviceIds = sensors;

    if (this.state.SelectedDown < 0 || !this.state.Downlinks || this.state.SelectedDown >= this.state.Downlinks.length) {
      // Here if not Built downlink
      const payload = $("#payloadField").val();
      const port = $("#portField").val();

      this.postSimpleDownlink(payload, deviceIds, port, 0, (oneResult) => {
        if (oneResult) {
          result.push(oneResult)
        } else {
          let strResult = '';
          result.forEach(r => strResult += `${r.deviceId}: ${r.result}\n`);
          alert(strResult);
          callback();
        }
      });
    } else {
      // Here to send built downlinks
      const downlink = this.state.Downlinks[this.state.SelectedDown];
      const datas = this.state.DownlinkValues;

      this.postDownlink(downlink, datas, deviceIds, 0, (oneResult) => {
        if (oneResult) {
          result.push(oneResult)
        } else {
          let strResult = '';
          result.forEach(r => strResult += `${r.deviceId}: ${r.result}\n`);
          alert(strResult);
          callback();
        }
      });
    }
  }

  downlinkChange(evt) {
    this.setState({SelectedDown: evt.target.value});
  }

  renderDownlist(downlinks) {
    const toRender = [];

    toRender.push(<option value={-1}>{this.t('Downlinks.NoSelect')}</option>);
    for (let i = 0; i < downlinks.length; i += 1) {
      toRender.push(<option value={i} alt={downlinks[i].help} title={downlinks[i].help}>{downlinks[i].name}</option>);
    }
    return toRender;
  }

  renderForm(downlinkIndex) {
    const toRender = [];
    if (downlinkIndex >= 0 && downlinkIndex < this.state.Downlinks.length) {
      const downlink = this.state.Downlinks[downlinkIndex];
      if (downlink.params) {
        const { params } = downlink;
        for (let i = 0; i < params.length; i += 1) {
          if (params[i].type !== "ACK") {
            toRender.push(params[i].listVal && params[i].listVal.length > 0 ?  this.renderSelect(params[i], i) : this.renderNumber(params[i], i))
          }
        }
      }
    } else {
        toRender.push(<div className="input-group mb-3">
          <span className="input-group-addon"><i className="icon-lock"></i></span>
          <input type="text" className="form-control" placeholder={this.t('Payload')} id="payloadField"/>
        </div>);
        toRender.push(<div className="input-group mb-3">
          <span className="input-group-addon"><i className="icon-lock"></i></span>
          <input type="number" className="form-control" placeholder={this.t('Port')} id="portField"/>
        </div>);
    }
    return toRender;
  }

  renderSelect(param, index) {
    const toRender = [];
    const optRender = [];

    if (param.listVal) {
      optRender.push(<option value={undefined}>-- {this.t('NoSelect')} --</option>);
      for (let i = 0; i < param.listVal.length; i += 1) {
        optRender.push(<option value={param.listVal[i].value}>{param.listVal[i].label}</option>);
      }
    }

    toRender.push(
      <div className="form-group mb-3">
        <select className="form-control" id={param.base + param.order} alt={param.help} title={param.help}
          onChange={(evt) => this.updateDownField(index, evt.target.value)}>{optRender}</select>
      </div>);
    return toRender;
  }

  updateDownField(index, value) {
    const downlinkValues = this.state.DownlinkValues;

    downlinkValues[`val${index}`] = value;
    this.setState({DownlinkValues: downlinkValues});
  }

  renderNumber(param, index) {
    const toRender = [];

    toRender.push(
      <div className="form-group mb-3">
        <input type="number"
         alt={param.help} title={param.help}
          onChange={(evt) => this.updateDownField(index, evt.target.value)}
          className="form-control"
          placeholder={param.name}
          max={param.maxVal ? param.maxVal : 9999999}
          min={param.minVal ? param.minVal : -9999999}
          id={param.base + param.order}/>
      </div>);
    return toRender;
  }

  render() {
    const { callback } = this.props;

    return (
      <div className="card-block p-12">
        <div className="input-group mb-3" style={{ color: "red" }} id="error-window">

        </div>
                    <div className="form-group mb-3">
                      <select className="form-control" id="provider" onChange={this.downlinkChange.bind(this)} defaultValue={this.state.SelectedDown}>
                        {this.renderDownlist(this.state.Downlinks)}
                      </select>
                    </div>
        {this.renderForm(this.state.SelectedDown)}

        <button type="button" className="btn btn-block btn-success"
          onClick={this.sendDownlink.bind(this, this.state.Sensors, callback)}>{this.t('Send')}</button>
      </div>
    )
  }
}

export default translate(DownlinkBuilder);