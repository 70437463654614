import React, { Component } from 'react';
import {DiagramWidget} from "storm-react-diagrams";
import $ from 'jquery';
import TrayWidget from './Components/TrayWidget';
import TrayItemWidget from './Components/TrayItemWidget';
import Manager from './Components/Manager';
import ValueNodeModel from './Components/ValueNode/ValueNodeModel';
import SensorNodeModel from './Components/SensorNode/SensorNodeModel';
import ComparatorNodeModel from './Components/ComparatorNode/ComparatorNodeModel';
import OperatorNodeModel from './Components/OperatorNode/OperatorNodeModel';
import AlarmNodeModel from './Components/AlarmNode/AlarmNodeModel';
import { translate } from '../../utils/ReactMultiLang';
import { postRequest } from '../../utils/WebServicesManager';
import Conf from '../../utils/Conf';

class ComplexAlarm extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
        this.alarm = {};
        this.Manager = new Manager(this.props.actualDiagram);
		this.t = props.t;
    }

	Serialize() {
		this.Manager.SerializeModel();
	}
	
	nextPhase(nextCallback) {
		$('#complex-container').removeClass('fullscreen');
		$('.modal-dialog').removeClass('fullscreen');
		$('.modal-body').removeClass('fullscreen');
		let diagramAlarm = null;
		try {
		  diagramAlarm = JSON.stringify(this.Manager.SerializeModel());
		} catch (error) {
			console.log(error);
		}
		try {
		  nextCallback(diagramAlarm);
		} catch (error) {
		  console.log(error);
		}
	}
		
	switchFullscreen() {
		$('#complex-container').toggleClass('fullscreen');
		$('.modal-dialog').toggleClass('fullscreen');
		$('.modal-body').toggleClass('fullscreen');
	}

	validateDiagram() {
			var url = Conf.BaseApi + 'alarms/complex/validate';
			postRequest(url, { diagramAlarm: JSON.stringify(this.Manager.SerializeModel())},(data) => {
			  if (data.success) {
				alert(this.t('Alarm.DiagramValid'));
			  } else {
				alert(data.message);
			  }
			});
	}

  render() {
	const { callback } = this.props;

    return (
			<div className="complex-container" id="complex-container">
        <div className="diagram-body">
				<div className="header">
					<div className="title">{this.t('Alarm.Complex')}</div>
					<div className="float-right">
              		<button type="button" className="btn btn-success" onClick={() => this.validateDiagram()} >{this.t('Validate')}</button>{' '}
              		<button type="button" className="btn btn-info" onClick={() => this.switchFullscreen()} >{this.t('SwitchFullscreen')}</button>{' '}
              		<button type="button" className="btn btn-success" onClick={() => this.nextPhase(callback)} >{this.t('Next')}</button>
					  </div>
				</div>
				<div className="content">
					<TrayWidget>
						<h4>{this.t('Alarm.Variables')}</h4>
						<TrayItemWidget model={{ type: "sensor" }} name={this.t('Sensor.Sensor')} color="blue" picto='sensor'/>
						<TrayItemWidget model={{ type: "value" }} name={this.t('Value')} color="blue" picto='value'/>
						<hr/>
						<h4>{this.t('Alarm.Logics')}</h4>
						<TrayItemWidget model={{ type: "comparator" }} name={this.t('Alarm.Comparator')} color="green" picto='comparator'/>
						<TrayItemWidget model={{ type: "operator" }} name={this.t('Alarm.Operator')} color="green" picto='link'/>
						<hr/>
						<h4>{this.t('Alarm.Actions')}</h4>
						<TrayItemWidget model={{ type: "alarm" }} name={this.t('Alarm.Alarm')} color="red" picto='bell'/>
					</TrayWidget>
					<div
						className="diagram-layer"
						onDrop={event => {
							try {
								var data = JSON.parse(event.dataTransfer.getData("storm-diagram-node"));
								var nodesCount = 
								Object.keys(this.Manager
									.getDiagramEngine()
									.getDiagramModel()
									.getNodes()).length;
								var node = null;
								switch (data.type){
									case "comparator": 
									node = new ComparatorNodeModel(this.t('Alarm.Comparator'), "green", 'comparator');
									break;
									case "operator": 
									node = new OperatorNodeModel(this.t('Alarm.Operator'), "green", 'link');
									break;
									case "sensor":
									node = new SensorNodeModel(this.t('Sensor.Sensor'), "blue", 'sensor');
									break;
									case "alarm":
									node = new AlarmNodeModel(this.t('Alarm.Alarm'), "red", 'bell');
									break;
									case "value":
									node = new ValueNodeModel(this.t('Value'), "blue", 'value');
									break;
								}
								var points = this.Manager.getDiagramEngine().getRelativeMousePoint(event);
								node.x = points.x;
								node.y = points.y;
								this.Manager
									.getDiagramEngine()
									.getDiagramModel()
									.addNode(node);
								this.forceUpdate();
							} catch (error) {
								console.log(error);
							}
						}}
						onDragOver={event => {
							event.preventDefault();
						}}
					>
						<DiagramWidget className="srd-demo-canvas" diagramEngine={this.Manager.getDiagramEngine()} />
					</div>
				</div>
			</div>
			</div>
    );
  }
}

export default translate(ComplexAlarm);
