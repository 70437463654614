import React, { Component } from 'react';
import { NavLink } from 'react-router-dom'
import Dashboard from '../../views/Dashboard/';
import $ from 'jquery';
import Conf from '../../utils/Conf';
import { notLoggedPostRequest, getRequest } from '../../utils/WebServicesManager';
import { setIdToken, setAccessToken, setRole, setAccessRight } from '../../utils/AuthServices';
import { getWithExpiry, setWithExpiry } from '../../utils/Utils';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { translate } from '../../utils/ReactMultiLang';

class FrontPage extends Component {

  constructor(props) {
   super(props);

   this.SAML_KEY = "SAML_TRIED";

   this.state = {
      Modal: false,
      ModalRetreive: false
    };
    this.toggle = this.toggle.bind(this);
    const params = new URLSearchParams(props.location.search); 
    this.doSAML = params.get('dontSAML') ? false : true;
    console.log(this.doSAML);
    this.t = props.t;
  }

  retreiveMdp() {
    var url = Conf.RetreiveURL;
    return notLoggedPostRequest(url, {
      email: $("#emailRetreive").val()
    }, (data) => {
        if (data.success || data.success == true) {
            $("#formRetreive").html('<i class="icon-check icons font-2xl d-block mt-4">Vous allez recevoir un mail</i>');
        } else {
            $("#error-window").text(data.message);
        }
    });
  }

      login() {
        var url = Conf.LoginURL;
        return notLoggedPostRequest(url, {login: $("#login").val(), password:$("#password").val()}, (data) => {
            if (data.success || data.success == true) {
                setAccessToken(data.token);
                setIdToken(data.token);
                setRole(data.role);
                setAccessRight(data.accessRight);
                window.location.href = "/";
            } else {
              $("#error-login").text(data.message);
            }
        });
    }

    signin() {
        var url = Conf.SigninURL;
        return notLoggedPostRequest(url, {
          login: $("#loginForm").val(),
          mail:$("#mailForm").val(),
          password:$("#passForm").val(),
          firstName:$("#firstName").val(),
          name:$("#name").val(),
          phone:$("#phoneForm").val(),
          verifPwd:$("#passConfForm").val()
        }, (data) => {
            if (data.success || data.success == true) {
                setAccessToken(data.token);
                setIdToken(data.token);
                setRole(data.role);
                setAccessRight(data.accessRight);
                this.toggle();
                window.location.href = "/";
            } else {
                $("#error-window").text(data.message);
            }
        });
    }
  
    toggleRetreive() {
      this.setState({
        ModalRetreive: !this.state.ModalRetreive
      });
    }

  toggle() {
    this.setState({
      Modal: !this.state.Modal
    });
  }

  handleKeyPress(event) {
    if (event.key === 'Enter') {
      this.login();
    }
  }

  getLog() {
    return getRequest(`${Conf.BaseURL}saml/login`,  (data) => {
      if (data.url) {
        this.getRequestToken(data.url);
      } else {
        alert("No SAML profile");
      }
    });
  }
  getRequestToken(location) {
    /*const popup = this.openPopup(location);
    this.polling(popup);*/
    
    window.location.replace(location);
  }

  polling(popup) {
    setInterval(() => {
      if (!popup || popup.closed || popup.closed === undefined) {
        window.location.reload();
      } 
    }, 500);
  }

  openPopup(location) {
    const w = 800;
    const h = 600;
    const left = 200;
    const top = 200;

    return window.open(location, '', 
    'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, ' +
     'copyhistory=no, width='+w+', height='+h+', top='+top+', left='+left);
  }

  render() {

    const samlUsed = getWithExpiry(this.SAML_KEY);

    if (Conf.DirectSAML && (!samlUsed || samlUsed === null) && this.doSAML) {
      setWithExpiry(this.SAML_KEY, true, 600000);
      this.getLog();
    }

    return (<div className="neal-page">

<div className="app flex-row align-items-center">
<div className="container">
  <div className="row justify-content-center">
    <div className="col-md-8">
      <div className="card-group mb-0">
        <div className="card p-4">
          <div className="card-block">
            <h1>Login</h1>
            <p className="text-muted">Sign In to your account</p>
            <div className="input-group mb-3" style={{color: "red"}} id="error-login">
              
            </div>
            <div className="input-group mb-3">
              <span className="input-group-addon"><i className="icon-user"></i></span>
              <input type="text" className="form-control" placeholder="Username" id="login"/>
            </div>
            <div className="input-group mb-4">
              <span className="input-group-addon"><i className="icon-lock"></i></span>
              <input type="password" className="form-control" placeholder="Password" id="password"  onKeyPress={this.handleKeyPress.bind(this)}/>
            </div>
            <div className="row">
              <div className="col-6">
                <button type="button" className="btn btn-primary px-4" onClick={this.login.bind(this)} id='submit'>Login</button>
              </div>
          </div>
            <a href='#' onClick={this.toggleRetreive.bind(this)}>Lost password ?</a><br/>
            <a href='#/public' >Access to public ressources</a><br/>
            <a href='#' onClick={this.getLog.bind(this)}>SAML Login</a>
          </div>
        </div>
        <div className="card card-inverse py-5 d-md-down-none" style={{ width: 44 + '%' }}>
          <div className="card-block text-center">
            <div>
              <img src="img/ebeesafe.png" width="50%" style={{marginTop:'-20px'}} />
            </div>
            <div>
              <img src="img/ebeewan.png" width="100%" style={{marginTop:'-25px'}}/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</div>
      <Modal isOpen={this.state.Modal} toggle={this.toggle} className='modal-md'>
                  <ModalBody>
                    <div className="flex-row align-items-center">
                      <div className="container">
                        <div className="row justify-content-center">
                          <div className="col-md-12">
                            <div className="card col-md-12">
                              <div className="card-block p-12">
                                <h1>Register</h1>
                                <p className="text-muted">Create your account</p>
                                <div className="input-group mb-3" style={{color: "red"}} id="error-window">
                                  
                                </div>
                              <div className="input-group mb-3">
                                <span className="input-group-addon"><i className="icon-user"></i></span>
                                <input type="text" className="form-control" placeholder="Firstname" id="firstName"/>
                              </div>
                                <div className="input-group mb-3">
                                <span className="input-group-addon"><i className="icon-user"></i></span>
                                <input type="text" className="form-control" placeholder="Name" id="name"/>
                              </div>
                                <div className="input-group mb-3">
                                <span className="input-group-addon"><i className="icon-user"></i></span>
                                <input type="text" className="form-control" placeholder="Login" id="loginForm"/>
                              </div>
                              <div className="input-group mb-3">
                                <span className="input-group-addon"><i className="icon-user"></i></span>
                                <input type="text" className="form-control" placeholder="Téléphone" id="phoneForm"/>
                              </div>
                              <div className="input-group mb-3">
                                <span className="input-group-addon">@</span>
                                <input type="text" className="form-control" placeholder="Email" id="mailForm"/>
                              </div>
                              <div className="input-group mb-3">
                                <span className="input-group-addon"><i className="icon-lock"></i></span>
                                <input type="password" className="form-control" placeholder="Password" id="passForm"/>
                              </div>
                              <div className="input-group mb-4">
                                <span className="input-group-addon"><i className="icon-lock"></i></span>
                                <input type="password" className="form-control" placeholder="Repeat password" id="passConfForm"/>
                              </div>
                                <button type="button" className="btn btn-block btn-success" onClick={this.signin.bind(this)}>Create Account</button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ModalBody>
                </Modal>

                <Modal isOpen={this.state.ModalRetreive} toggle={this.toggleRetreive.bind(this)} className='modal-md'>
                  <ModalBody>
                    <div className="flex-row align-items-center">
                      <div className="container">
                        <div className="row justify-content-center">
                          <div className="col-md-12">
                            <div className="card col-md-12">
                              <div className="card-block p-12">
                                <h3>Mot de passe perdu</h3>
                                <p className="text-muted">Vous allez recevoir un mail</p>
                                <div className="input-group mb-3" style={{color: "red"}} id="error-window">
                                  
                                </div>
                              <div className="input-group mb-3" id='formRetreive'>
                                <span className="input-group-addon">@</span>
                                <input type="text" className="form-control" placeholder="Email" id="emailRetreive"/>
                              </div>
                                <button type="button" className="btn btn-block btn-success" onClick={this.retreiveMdp.bind(this)}>Récupérer mon mot de passe</button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ModalBody>
                </Modal>
    </div>
    )
  }
}
export default translate(FrontPage);
