import React, { Component } from 'react';
import { translate } from '../../utils/ReactMultiLang';

class SignalQuality extends Component {
    constructor(props) {
        super(props);

        this.t = props.t;
      }

    render() {
        const { qualityLevel } = this.props;
        const imgs = [
            'img/picto/bars_gray.png',
            'img/picto/bars_red.png',
            'img/picto/bars_orange.png',
            'img/picto/bars_green.png'
        ];
        return (
            <span> 
                <img src={imgs[qualityLevel]} alt={imgs[qualityLevel]} width='30px'/>
            </span>
        )
    }
}

export default translate(SignalQuality);


