import React, { Component } from 'react';
import $ from 'jquery';
import Conf from '../../../utils/Conf';
import { postRequest } from '../../../utils/WebServicesManager';
import { ModalBody } from 'reactstrap';
import { translate } from '../../../utils/ReactMultiLang';

class Providers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      groupId: props.groupId,
      providerType: props.provider ? props.provider.operator : 'ACTILITY', 
      Provider: props.provider
    }
    this.callback = props.callback;
    this.t = props.t;
  }
  
  componentWillReceiveProps(nextProps) {
    if (nextProps.groupId !== this.props.groupId) {
      this.setState({groupId: nextProps.groupId});
    }
    if (nextProps.provider !== this.props.provider) {
      if (nextProps.provider) {
        this.setState({Provider: nextProps.provider, providerType: nextProps.provider.operator });
      } else {
        this.setState({Provider: nextProps.provider, providerType: 'ACTILITY' });
      }
    }
  }

  createProvider() {
    var url = Conf.BaseApi + "providers/add";
    
    const provider = {
      name:  $('#providerName').val(),
      operator: this.state.providerType,
      url: $('#providerUrl').val(),
      login: $('#providerLogin').val(),
      password: $('#providerPassword').val(),
      groupId: this.state.groupId,
      bAuthPwd: $('#bAuthPwd').val(),
      bAuthLogin: $('#bAuthLogin').val()
    };

    if (Conf.ProvidersParams[this.state.providerType].length > 0) {
      provider.params = {};
      for (let i = 0; i < Conf.ProvidersParams[this.state.providerType].length; i += 1) {
        const data = Conf.ProvidersParams[this.state.providerType][i];
        provider.params[data] = $(`#${data}_id`).val();
      }
    }

    return postRequest(url, provider, (data) => {
      if (data.success || data.success == true) {
        this.callback(null, this.state.groupId);
      } else {
        $("#error-window-provider").text(data.message);
      }
    });
  }

  editProvider() {
    var url = Conf.BaseApi + "providers/edit";
    
    const provider = {
      name:  $('#providerName').val(),
      operator: this.state.providerType,
      url: $('#providerUrl').val(),
      login: $('#providerLogin').val(),
      password: $('#providerPassword').val(),
      providerId: this.state.Provider._id,
      bAuthPwd: $('#bAuthPwd').val(),
      bAuthLogin: $('#bAuthLogin').val()
    };

    if (Conf.ProvidersParams[this.state.providerType].length > 0) {
      provider.params = {};
      for (let i = 0; i < Conf.ProvidersParams[this.state.providerType].length; i += 1) {
        const data = Conf.ProvidersParams[this.state.providerType][i];
        provider.params[data] = $(`#${data}_id`).val();
      }
    }


    return postRequest(url, provider, (data) => {
      if (data.success || data.success == true) {
        this.callback(null, this.state.groupId);
      } else {
        $("#error-window-provider").text(data.message);
      }
    });
  }

  changeProvider(evt) {
    this.setState({ providerType: evt.target.value });
  }

  render() {

    return (
      <ModalBody>
      <div className="flex-row align-items-center">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12">
              <div className="card mx-12">
                <div className="card-block p-12">
                  <div className="input-group mb-3" style={{ color: "red" }} id="error-window-provider">

                  </div>
                  <div className="input-group mb-3">
                    <span className="input-group-addon"><i className="icon-usergroup"></i></span>
                    <input type="text" className="form-control" placeholder={this.t('Name')} id="providerName"
                      defaultValue={this.state.Provider ? this.state.Provider.name : ''}/>
                  </div>
                  <div className="input-group mb-3">
                    <span className="input-group-addon"><i className="icon-usergroup"></i></span>
                    <select className="form-control" id="opertaor" onChange={this.changeProvider.bind(this)} defaultValue={this.state.Provider ? this.state.Provider.operator : 'Actility'}>
                      {Conf.Providers.map(p => <option value={p}>{p}</option>)}
                    </select>
                  </div>
                  <div className="input-group mb-3">
                    <span className="input-group-addon"><i className="icon-usergroup"></i></span>
                    <input type="text" className="form-control" placeholder="URL" id="providerUrl"
                      defaultValue={this.state.Provider ? this.state.Provider.url : ''}/>
                  </div>
                  <div className="input-group mb-3">
                    <span className="input-group-addon"><i className="icon-usergroup"></i></span>
                    <input type="text" className="form-control" placeholder={this.t('Login')} id="providerLogin"
                      defaultValue={this.state.Provider ? this.state.Provider.login : ''}/>
                  </div>
                  <div className="input-group mb-3">
                    <span className="input-group-addon"><i className="icon-usergroup"></i></span>
                    <input type="text" className="form-control" placeholder={this.t('Password')} id="providerPassword"
                      defaultValue={this.state.Provider ? this.state.Provider.password : ''}/>
                  </div>
                  <div className="input-group mb-3">
                    <span className="input-group-addon"><i className="icon-usergroup"></i></span>
                    <input type="text" className="form-control" placeholder={this.t('bAuthLogin')} id="bAuthLogin"
                      defaultValue={this.state.Provider ? this.state.Provider.bAuthLogin : ''}/>
                  </div>
                  <div className="input-group mb-3">
                    <span className="input-group-addon"><i className="icon-usergroup"></i></span>
                    <input type="text" className="form-control" placeholder={this.t('bAuthPwd')} id="bAuthPwd"
                      defaultValue={this.state.Provider ? this.state.Provider.bAuthPwd : ''}/>
                  </div>
                  {
                    Conf.ProvidersParams[this.state.providerType].map(p => {
                      return (<div className="input-group mb-3">
                        <span className="input-group-addon"><i className="icon-settings"></i></span>
                        <input type="text" className="form-control" placeholder={this.t(p)} id={`${p}_id`}
                          defaultValue={this.state.Provider && this.state.Provider.params ? this.state.Provider.params[p] : ''}/>
                      </div>);
                    })
                  }
                  <button type="button" className="btn btn-block btn-success" onClick={this.state.Provider ? this.editProvider.bind(this) : this.createProvider.bind(this)}>{this.state.Provider ? this.t('Edit') : this.t('Add')}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ModalBody>
    )
  }

}

export default translate(Providers);