import React, { Component } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink, Progress } from 'reactstrap';
import classnames from 'classnames';
import Conf from '../../utils/Conf';
import { getRequest } from '../../utils/WebServicesManager';
import { translate } from '../../utils/ReactMultiLang';
import { check } from '../../utils/Can';
import { Button } from 'react-bootstrap';


class Aside extends Component {
  constructor(props) {
    super(props);

    this.state = {
      Alerts: [],
      CanEdit: check('alarm:edit'),
      ViewAll: check('alarm:admin')
    };
    this.getAlerts();
    this.updateAlerts = undefined;
    this.t = props.t;
  }

  getAlerts() {
    var url = `${Conf.BaseApi}alerts/get${this.state.ViewAll ? 'All' : ''}`;
    return getRequest(url, (data) => {
      if (data.success) {
        this.setState({ Alerts: data.result });
        if (this.updateAlerts !== undefined) {
          let cpt = 0;
          for (let i = 0; i < data.result.length; i++) {
            if (data.result[i].open)
              cpt++;
          }
          this.updateAlerts(cpt);
        }
      }
    });
  }

  Close(id) {
    var url = Conf.BaseApi + 'alerts/close/' + id;
    return getRequest(url, (data) => {
        this.getAlerts();
    });
  }

  renderActiveAlerts(Alerts) { // TODO
    var toRender = [];
    for (var i = 0; i < Alerts.length; i++) {
      const alert = Alerts[i];
      if (alert.open) {
        var date = new Date(alert.time);
        toRender.push(
          <div className="callout callout-danger m-0 py-3" key={alert._id}>
          { this.state.CanEdit ? 
          <div style={{marginBottom: '7px'}}>
            <label className="switch switch-text switch-pill switch-danger switch-alert">
            <input type="checkbox" className="switch-input"  onChange={this.Close.bind(this, alert._id)} defaultChecked/>
              <span className="switch-label" data-on="On" data-off="Off"></span>
              <span className="switch-handle" ></span>
            </label>
          </div> : '' }
            <div style={{textIndent: '50px'}}>{alert.name}{' '}
            </div>
            <small className="text-muted mr-3"><i className="icon-calendar"></i>&nbsp;{date.getDate() + "/" + (date.getMonth() + 1) + " " + date.getHours() + "h" + date.getMinutes()}</small>
            <br/>
            <small className="text-muted mr-3"><i className="icon-home"></i>&nbsp;{alert.desc}</small>
          </div>
        );
      }
    }
    return toRender;
  }

  renderClosedAlerts(Alerts) { // TODO
    var toRender = [];
    for (var i = 0; i < Alerts.length; i++) {
      const alert = Alerts[i];
      if (!alert.open) {
        var date = new Date(alert.time);
        toRender.push(
          <div key={i} className="callout callout-success m-0 py-3">
            <div>{alert.name}</div>
            <small className="text-muted mr-3"><i className="icon-calendar"></i>&nbsp;{date.getDate() + "/" + (date.getMonth() + 1) + " " + date.getHours() + "h" + date.getMinutes()}</small>
            <br/>
            <small className="text-muted mr-3"><i className="icon-home"></i>&nbsp;{alert.desc}</small>
            <br/>
          </div>
        );
      }
    }
    return toRender;
  }
  render() {
    const { updateAlerts } = this.props;
    this.updateAlerts = updateAlerts;
    return (
      <aside className="aside-menu">
        <div className="callout m-0 py-2 text-center bg-faded text-uppercase">
          
          <Button onClick={this.getAlerts.bind(this)} style={{position:"absolute", marginTop: '-0.5rem', marginLeft: '-25%'}}><i className="icon-reload"></i></Button>
          <small><b>{this.t('Alert.Active')}</b></small>
        </div>

        {this.renderActiveAlerts(this.state.Alerts)}

        <hr className="transparent mx-3 my-0" />
        <div className="callout m-0 py-2 text-center bg-faded text-uppercase">
          <small><b>{this.t('Alert.Solved')}</b></small>
        </div>
        {this.renderClosedAlerts(this.state.Alerts)}
      </aside>
    )
  }
}

export default translate(Aside);
