import React, { Component } from 'react';
import $ from 'jquery';
import Conf from '../../../utils/Conf';
import { simpleGetRequest } from '../../../utils/WebServicesManager';
import { marker } from '../../Buildings/Manage/Map/Marker/Marker.js'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter,
  Label, Input } from 'reactstrap';
  import { translate } from '../../../utils/ReactMultiLang';
import { Map, TileLayer, Marker, Popup } from 'react-leaflet';
import SignalQuality from '../../../components/SignalQuality';

class CoverageMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
        latlng: [46.16883, 6.11083],
        zoom: 6,
        sigfoxCoverage: null,
        objeniousCoverage: null
    }
    this.t = props.t;
  }


  GetCoverage(latlng) {
    this.setState({clickLat: latlng.lat, clickLng: latlng.lng});
    let url = `${Conf.BackenBase}connection/get/sigfox/${latlng.lat}/${latlng.lng}`;
    simpleGetRequest(url, (data) => {
      if (data.success) {
        this.setState({sigfoxCoverage: JSON.parse(data.result)});
      }
    });
    url = `${Conf.BackenBase}connection/get/objenious/${latlng.lat}/${latlng.lng}`;
    simpleGetRequest(url, (data) => {
      if (data.success) {
        this.setState({objeniousCoverage: data.result});
      }
    });
  }

  handleDbClick(e) {
    this.GetCoverage(e.latlng);
  }

  openPopup(ref) {
    if (ref && ref.leafletElement && ref.leafletElement.openPopup) {
      ref.leafletElement.openPopup();
    }
  }

  render() {
    return (
      <div className="row">
         <div className="col-xl-12">
          <div className="row ">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-header">
                  <i className="fa fa-align-justify"></i> {this.t('Map')}
                </div>
                <div className="card-block">
                  <div style={{height:'700px'}}>
                  <Map
                    className="report-map"
                    center={this.state.latlng}
                    zoom={this.state.zoom}
                    zoomControl={true}
                    doubleClickZoom={false}
                    ondblclick={this.handleDbClick.bind(this)}
                    ref='leafletMap'>
                    
                      <TileLayer
                          url="https://maps.wikimedia.org/osm-intl/{z}/{x}/{y}.png"
                      />

                      {
                        this.state.clickLat &&
                        <Marker
                                icon={marker}
                                position={[ this.state.clickLat,  this.state.clickLng]}
                                ref={ref => setTimeout(() => this.openPopup(ref))}>
                                <Popup className='map-popup'>
                                <span>
                                  Sigfox: <SignalQuality 
                                    qualityLevel={this.state.sigfoxCoverage && this.state.sigfoxCoverage.locationCovered ? 
                                      this.state.sigfoxCoverage.margins[0] > 40 ? 3 : this.state.sigfoxCoverage.margins[0] > 25 ? 2 : 1 : 0} />
                                  <br/>
                                  Objenious Outdoor: <SignalQuality 
                                    qualityLevel={this.state.objeniousCoverage && this.state.objeniousCoverage.couvActuelle.outdoor} />{' - '}
                                  Indoor: <SignalQuality 
                                    qualityLevel={this.state.objeniousCoverage && this.state.objeniousCoverage.couvActuelle.indoor}/>
                                </span>
                            </Popup>
                                </Marker>
                      }
                  </Map>
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    )
  }
}

export default translate(CoverageMap);
