import React, { Component } from 'react';
import $ from 'jquery';
import Conf from '../../../utils/Conf';
import { getDateStr } from '../../../utils/Utils';
import { getRequest, postRequest } from '../../../utils/WebServicesManager';
import { Card, Row, Col, ButtonToolbar, Modal } from 'reactstrap';
import { ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import { translate } from '../../../utils/ReactMultiLang';
import Map from '../Manage/Map/Map.js';
import InfraDetailTable from '../../../components/Infrastructures/DetailTable';
import AddDashboard from '../../../components/Infrastructures/AddDashboard';
import { check } from '../../../utils/Can';
import Report from '../../Reports/Report/Report';

class QuickView extends Component {
  constructor(props) {
    super(props);
    //this.state = this.getInitialState();
      this.state = {
        User: {},
        Building: {},
        Buildings: [],
        InfraTags: [],
        Alerts: [],
        Tags: [],
        Messages: {},
        Alert: undefined,
        Groups: [],
        SelectedGroup: '',
        SelectedTag: '',
        MapData: '',
        ModalAddDash: false,
        SensorList: [],
        LinkedDashboard: [],
        Dashboard:undefined,
        CanCreate: check('infra:create'),
        CanEdit: check('infra:edit'),
        CanRemove: check('infra:remove'),
        screen: 1
      };
    this.messages = {};
    this.getUser();
    this.getBuildings();
    this.getGroups();

    const building = JSON.parse(localStorage.getItem('InfraQuickView-Building') || '{}');
    if (building !== {}) {
      this.state.Building = building;
    }
    const linkedDash = JSON.parse(localStorage.getItem('InfraQuickView-LinkedDashboard') || '{}');
    if (linkedDash !== {}) {
      this.state.LinkedDashboard = linkedDash;
    }

    const tag = localStorage.getItem('InfraQuickView-SelectedTag');
    if (tag && tag !== '') {
      this.state.SelectedTag = tag;
    }
    const group = JSON.parse(localStorage.getItem('InfraQuickView-SelectedGroup') || '{}');
    if (group._id) {
      this.state.SelectedGroup = group;
    }
    this.t = props.t;
  }

  switchArray(e) {
    this.setState({screen: e});
  }

  customSetState(state) {
    //this.setState(state, () => localStorage.setItem('InfraQuickView', JSON.stringify(this.state)));
  }
  getInitialState () {
    const state = JSON.parse(localStorage.getItem('InfraQuickView') || '{}');
    if (this.state !== {}) {
      if (!state.SensorList) {
        state.SensorList = [];
      } if (!state.LinkedDashboard) {
        state.LinkedDashboard = [];
      } if (!state.Messages) {
        state.Messages = {};
      } if (!state.Building) {
        state.Building = {};
      } if (!state.Buildings) {
        state.Buildings = [];
      } if (!state.User) {
        state.User = {};
      } if (!state.Groups) {
        state.Groups = [];
      }if (!state.Tags) {
        state.Tags = [];
      }if (!state.InfraTags) {
        state.InfraTags = [];
      }if (!state.SelectedGroup) {
        state.SelectedGroup = '';
      }if (!state.SelectedTag) {
        state.SelectedTag = '';
      }if (!state.MapData) {
        state.MapData = '';
      }
      state.CanCreate = check('infra:create');
      state.CanEdit =check('infra:edit');
      state.CanRemove = check('infra:remove');
    }
    return state;
  }
	getGroups () {
		var url = Conf.BaseApi + 'groups/getAll'
		return getRequest(url, (body) => {
			if (body.success) {
				this.setState({ Groups: body.result });
			}
		});
  }
  
  toggleAddDash() {
    this.setState({ ModalAddDash: !this.state.ModalAddDash, Dashboard: undefined });
    if (this.state.Building && this.state.Building._id) {
      this.getBuildingDashboard(this.state.Building._id);
    }
  }

  getBuildings() {
    let url = Conf.BaseApi + 'buildings/get';
    return getRequest(url, (data) => {
      if (data.success && data.result.length > 0) {
        const tags = [];
        const buildings = data.result;
        if (buildings) {
          for (let i = 0; i < buildings.length; i += 1) {
            if (buildings[i] && buildings[i].tags) {
              for (let j = 0; j < buildings[i].tags.length; j += 1) {
                if (!tags.some(t => t === buildings[i].tags[j])) {
                  tags.push(buildings[i].tags[j]);
                }
              }
            }
          }
        }
        this.setState({ Buildings: data.result, InfraTags: tags });
      }
    });
  }

  getUser() {
    let url = Conf.BaseApi + 'user';
    return getRequest(url, (data) => {
      if (data.success) 
        this.setState({ User: data.result });
    });
  }
  
  renderUserGroup(UserGroups) {
    var toRender = [];
    toRender.push(
        <option key={-1} value='' selected>- Tous les groupes -</option>
    );
    for (var i = 0; i < UserGroups.length; i++) {
        if (this.state.SelectedGroup._id === UserGroups[i]._id) {
            toRender.push(
                <option key={i} value={UserGroups[i]._id} selected>{UserGroups[i].name}({UserGroups[i].provider})</option>
            );
        } else {
            toRender.push(
                <option key={i} value={UserGroups[i]._id}>{UserGroups[i].name}({UserGroups[i].provider})</option>
            );
        }
    }
    return toRender;
  }
  renderInfraTags(infraTags) {
    var toRender = [];
    toRender.push(
        <option key={-1} value='' selected>- Tous les Tags -</option>
    );
    for (var i = 0; i < infraTags.length; i++) {
        if (this.state.SelectedTag === infraTags[i]) {
            toRender.push(
                <option key={i} value={infraTags[i]} selected>{infraTags[i]}</option>
            );
        } else {
            toRender.push(
                <option key={i} value={infraTags[i]}>{infraTags[i]}</option>
            );
        }
    }
    return toRender;
  }
  getBuildingDetails(building) {
    const id = building;
    let url = Conf.BaseApi + 'buildings/get/' + id;
    getRequest(url, (data) => {
      if (data.success) {
        this.setState({ Building: data.result }, () => localStorage.setItem('InfraQuickView-Building', JSON.stringify(data.result)));
        this.getBuildingDashboard(id);
      }
    });
  } 
  getBuildingDashboard(id) {
    let url = Conf.BaseApi + 'building/dashboard/getList/' + id;
    getRequest(url, (data) => {
      if (data.success) {
        this.setState({ LinkedDashboard: data.result }, () => localStorage.setItem('InfraQuickView-LinkedDashboard', JSON.stringify(data.result)));
      }
    });
  }

  userGroupChange(UserGroups, event) {
    for (let i = 0; i < UserGroups.length; i += 1) {
        if (UserGroups[i]._id === event.target.value) {
          this.setState({SelectedGroup: UserGroups[i]}, () => localStorage.setItem('InfraQuickView-SelectedGroup', JSON.stringify(UserGroups[i])));
            return ;
        }
    }
    this.setState({SelectedGroup: ''}, () => localStorage.setItem('InfraQuickView-SelectedGroup', JSON.stringify({})));
  }
  tagChange(event) {
    const value = event.target.value;
    this.setState({SelectedTag: value}, () => localStorage.setItem('InfraQuickView-SelectedTag', value));
  }

  changeMapData(event) {
      this.setState({MapData: event.target.value});
  }

  setSensorList(list) {
    this.setState({SensorList: list});
  }

  removeDashboard(dashboardId, groupId) {
    var r = window.confirm(this.t('Popup.Delete.BuilDash'));
    if (r == true) {
      var url = Conf.BaseApi + 'building/dashboard/remove';
      postRequest(url, {
          dashboardId, groupId
        }, (data) => {
        if (data.success && data.success == true) {
          this.getBuildingDashboard(this.state.Building._id);
        }
      });
    }
  }

  renderDashboard(dashs) {
    const toReturn = [];

    if (dashs && dashs.length > 0) {
      for (let i = 0; i < dashs.length; i += 1) {
        toReturn.push(
          <li>
            <a href={`#/building/dashboard?dashboard=${dashs[i]._id}`}>{dashs[i].name} ({this.t(dashs[i].data)})</a>
            {this.state.CanEdit ?
              <button type="button" onClick={this.editDashboard.bind(this, dashs[i])} className="btn btn-link text-success"><i className="icon-pencil"></i></button>
            : ""}
            {this.state.CanEdit ?
              <button type="button" onClick={this.removeDashboard.bind(this, dashs[i]._id, this.state.Building.group)} className="btn btn-link text-danger"><i className="icon-close"></i></button>
            : ""}
          </li>
        );
      }
    }

    return toReturn;
  }

  editDashboard(dashboard) {
    this.setState({ ModalAddDash: !this.state.ModalAddDash, Dashboard: dashboard });
  }

  render() {
    return (
      <div className="row">
         <div className="col-xxl-6 col-xl-12">
          <div className="row ">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-header">
              <div className="row">
                <div className="col-lg-6 vertical-center">
                  <i className="fa fa-map-o"></i> {this.t('Map')}
                </div>
                  
                <div className="col-lg-3">
                      <select className="form-control" id="userGroup" onChange={this.userGroupChange.bind(this, this.state.Groups)}>
                        {this.renderUserGroup(this.state.Groups)}
                      </select>
                </div>
                <div className="col-lg-3">
                      <select className="form-control" id="infraTags" onChange={this.tagChange.bind(this)}>
                        {this.renderInfraTags(this.state.InfraTags)}
                      </select>
                </div>
                </div>
                </div>
                <div className="card-block">
                  <Map selectBuilding={this.getBuildingDetails.bind(this)}
                    readOnly={true} buildings={
                      this.state.Buildings.filter((b) => (
                        (this.state.SelectedGroup === '' || b.group === this.state.SelectedGroup._id) &&
                        (this.state.SelectedTag === '' || (b.tags && b.tags.includes(this.state.SelectedTag)))
                        ) ? true : false)
                    }
                    mapData={this.state.MapData}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      <div className="col-xxl-6 col-xl-12">
       <div className="row ">
         <div className="col-lg-12">
           <div className="card">
           { this.state.Building.icon ?
             <div className="card-block">
              <Row>
                <div className="col-md-12 col-lg-6">
                  <h4><img src={`img/pins/c_${this.state.Building.icon}`} width="50px" /> {'  '}<span style={{marginTop:'-10px'}}>{this.state.Building.name}</span></h4>
                  <p style={{marginLeft:'60px', marginTop:'-20px'}}>{this.state.Building.comment}</p>
                  {this.state.Building.address ? <p style={{marginLeft:'10%'}}><i>
                    {this.state.Building.address.street}, {this.state.Building.address.number}<br />
                    {this.state.Building.address.postcode}, {this.state.Building.address.city}</i>
                  </p> : '' }
                </div>
                <div className="col-md-12 col-lg-6">
                  <ul className='dashboard'>
                    {this.renderDashboard(this.state.LinkedDashboard)}
                  </ul>
                  { this.state.CanEdit ? <button type="button" className="btn btn-block btn-success" onClick={this.toggleAddDash.bind(this)}>{this.t('BuilDash.Add')}</button> : ''}
                </div>
              </Row>
              <br />
              <Row>
                <Col md="6" className="param-column">
                  <div className="heatmap line" style={{width: "100%"}}>
                    <ButtonToolbar>
                      <ToggleButtonGroup type="radio" name="typeSelector" id="levelSelector" defaultValue={1} onChange={(e) => this.switchArray(e)}>
                        <ToggleButton value={1} bsStyle="primary">{this.t('Graphiques')}</ToggleButton>
                        <ToggleButton value={2} bsStyle="primary">{this.t('Capteurs')}</ToggleButton>
                      </ToggleButtonGroup>
                    </ButtonToolbar>
                  </div>
                </Col>
                {this.state.screen === 1 && 
                this.state.Building.report ? 
                <Col md="6" className="param-column">
                <h4 style={{marginTop: "15px"}}>{this.state.Building.report.name}</h4>
                </Col>
                : ''}
              </Row>
              {this.state.screen === 1 ?
                this.state.Building.report ?
                <Report report={this.state.Building.report} isLocked={true}/> : ''
              :
                <ul key={22} className="building-list">
                  <InfraDetailTable buildingId={this.state.Building._id} callback={this.setSensorList.bind(this)}/>
                </ul>
              }
             </div> : '' }
           </div>
         </div>
       </div>
     </div>
        <Modal isOpen={this.state.ModalAddDash} toggle={this.toggleAddDash.bind(this)} className={'modal-md ' + this.props.className}>
            <AddDashboard
              key={this.state.Building ? this.state.Building._id : '0'}
              dashboard={this.state.Dashboard}
              buildingId={this.state.Building ? this.state.Building._id : ''}
              callback={this.toggleAddDash.bind(this)}
              sensorList={this.state.SensorList}/>
        </Modal>
      </div>

    )
  }
}

export default translate(QuickView);
// onChange={this.getDevicesListOfType.bind(this)}
/*
                  <select className="form-control" id='selectSensorType' onChange={this.changeMapData.bind(this)}>
                    <option value=''>{this.t('AllTypes')}</option>
                       <option value='temperature'>{this.t('Sensor.Temperature')}</option>
                  </select>*/