import React, { Component } from 'react';
import { translate } from '../../../../../utils/ReactMultiLang';
import $ from 'jquery';
import { Popover, PopoverHeader, PopoverBody } from 'reactstrap';


class Sensor extends Component {
  constructor(props) {
		super(props);

		let img = `img/sensorIcones/INFRARED.png`;
		switch (props.sensor.type[0]) {
			case "TEMPERATURE":
				img = `img/sensorIcones/TEMPERATURE.png`;
				break;
			case "WATER":
				img = `img/sensorIcones/WATER.png`;
				break;
			case "MAGNETIC":
				img = `img/sensorIcones/MAGNETIC.png`;
				break;
			default:
				img = `img/sensorIcones/INFRARED.png`;
				break;

		} 
		this.state = {
			sensor: props.sensor,
			position: props.position,
			img,
			popoverOpen: false
		};
		this.removeFromPlan = () => {};
		if (props.removeFromPlan)
			this.removeFromPlan = props.removeFromPlan;
		
		this.t = props.t;
	}

	toggle = () => this.setState({popoverOpen: !this.state.popoverOpen});
	
	dragStart(ev) {
		ev.dataTransfer.setData("application/assetId", ev.currentTarget.id.substring(4));
		ev.dataTransfer.setData("application/assetType",'devices');
		var img = document.createElement('img');
		img.src = this.state.img;
		img.width = '50';
		var div = document.createElement('div');
		div.appendChild(img);
		document.querySelector('body').appendChild(div);
		ev.dataTransfer.setDragImage(div, 25, 25);
		ev.dataTransfer.dropEffect = "move";
	}

	render() {
		let { style, className, notAssigned } = this.props;

		if (!style) {
			style = { }
		}


		return (
			<div class={`sensorBox ${className}`} id={`BTN_${this.state.sensor._id}`} draggable={true} onDragStart={this.dragStart.bind(this)} style={style}>
				<img src={this.state.img} onClick={this.toggle}/>
				
				<Popover placement="top" isOpen={this.state.popoverOpen} target={`BTN_${this.state.sensor._id}`} toggle={this.toggle}>
					<PopoverHeader>
						<a href={`#/sensors/detail?deveui=${this.state.sensor.DevEUI}`}>{this.state.sensor.name}</a>
					</PopoverHeader>
					
						{this.state.sensor && this.state.sensor.lastMessage ? <PopoverBody>{this.renderData(this.state.sensor.lastMessage.data, this.state.sensor.customs)}</PopoverBody> : ''}
					
																
					
				</Popover>

			</div>
		)
	}
	/*  
	{notAssigned || !this.props.CanEdit ? '' : <i className="icon-close color-danger" onClick={() => this.removeFromPlan(this.state.sensor._id)}
	style={{zIndex: 1000, position:'absolute', textAlign: 'right', right:'5px', cursor:'pointer', bottom: '5px'}}></i>}
	*/
	toggleOpen() {
		$('#'+this.state.sensor._id).toggleClass('open');
  	}

	renderData(data, customs) {
		const toRender = [];
		const keys = Object.keys(data);
	
		for (let i = 0; i < keys.length; i += 1) {
		  if (customs) {
			const custom = customs.find(c => c.fieldId === keys[i]);
			if (custom) {
			  
			toRender.push(
			  <li className="sensor-box-item" key={i}>
				 {custom.label}: <b>{custom.initValue + custom.increment * (Math.round(data[keys[i]] * 10) / 10)}</b> {custom.unit}
			  </li>
			);
			  continue ;
			}
		  }
		  if (keys[i].includes('temperature')) {
			toRender.push(
			  <li className="sensor-box-item" key={i}>
				<img src='img/icon/temp.png' width='25px'/> {Math.round(data[keys[i]] * 10) / 10} °C
			  </li>
			);
		  } else if (keys[i].includes('humidity')) {
			toRender.push(
			  <li className="sensor-box-item" key={i}>
				<img src='img/icon/drop.png' width='25px'/> {Math.round(data[keys[i]] * 10) / 10} %
			  </li>
			);
		  } else if (keys[i].includes('light')) {
			toRender.push(
			  <li className="sensor-box-item" key={i}>
				<img src='img/icon/light.png' width='25px'/> {data[keys[i]]} lux
			  </li>
			);
		  } else if (keys[i] === 'open') {
			if (data[keys[i]]) {
			  toRender.push(
				<li className="sensor-box-item" key={i}>
				  <img src='img/icon/door_open.png' width='25px'/> {this.t('Open')}
				</li>
			  );
			} else {
			  toRender.push(
				<li className="sensor-box-item" key={i}>
				  <img src='img/icon/door.png' width='25px'/> {this.t('Close')}
				</li>
			  );
			}
		  }
		}
	return <ul style={{listStyleType: "none", paddingInlineStart: "5px"}}>{toRender}</ul>;
	  }
}

export default translate(Sensor);