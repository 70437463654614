import * as SRD from "storm-react-diagrams";
import ComparatorNodeWidget from "./ComparatorNodeWidget";
import ComparatorNodeModel from "./ComparatorNodeModel";
import * as React from "react";

export class ComparatorNodeFactory extends SRD.AbstractNodeFactory {
	constructor() {
		super("comparator");
	}

	generateReactWidget(diagramEngine, node) {
		return <ComparatorNodeWidget node={node} diagramEngine={diagramEngine}/>;
	}

	getNewInstance() {
		return new ComparatorNodeModel();
	}
}

export default ComparatorNodeFactory;