import React, { Component } from 'react';
import { Progress } from 'reactstrap';

class Messages extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { messages } = this.props;
    return (
          <div className="card animated fadeIn">
            <div className="card-block">
              <div className="h1 text-muted text-right mb-2">
                <i className="icon-speedometer"></i>
              </div>
              <div className="h4 mb-0">{this.props.messages.length}</div>
              <small className="text-muted text-uppercase font-weight-bold">Message received last 24h</small>
              <Progress className="progress progress-xs mt-3 mb-0" color="danger" value={this.props.messages.length} max={this.props.messages.length + 10}/>
            </div>
          </div>
    )
  }
}

export default Messages;
