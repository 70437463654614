import React, { Component } from 'react';
import Conf from '../../../utils/Conf';
import { getDateStr } from '../../../utils/Utils';
import { renderMessageData } from '../../../utils/RenderUtils';
import { getRequest, postRequest } from '../../../utils/WebServicesManager';
import {
  Button, Row, Col,
  Modal, ModalHeader, ModalBody
} from 'reactstrap';
import AlarmModel from '../../../components/ManageSensor/AlarmModel';
import Infrastructure from '../../../components/ManageSensor/Infrastructure';
import AddLoRa from '../../../components/AddSensor/AddLoRa';
import AddSigFox from '../../../components/AddSensor/AddSigFox';
import AddVirtual from '../../../components/AddSensor/AddVirtual';
import EditSensor from '../../../components/Modals/EditSensor';
import SendDownlink from '../../../components/Modals/SendDownlink';
import EditAlertSensor from '../../../components/Modals/EditAlertSensor';
import MassProvisionning from '../../../components/MassProvisionning';
import { translate } from '../../../utils/ReactMultiLang';
import ReactTable from "react-table";
import checkboxHOC from "react-table/lib/hoc/selectTable";
import 'react-table/react-table.css';
import _ from "lodash";
import { check } from '../../../utils/Can';
import ExportData from '../../../components/Modals/ExportData/ExportData';

class Manage extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
    if (!this.state || this.state === {}) {
      this.state = {
        Devices: [],
        data: [],
        pages: 20,
        loading: false,
        selection: [],
        selectAll: false,
        UserGroups: [],
        Providers: [],
        SensorGroups: [],
        Modal: false,
        ModalMass: false,
        ModalDownlink: false,
        ModalAlert: false,
        ModalExport: false,
        ActualProvider: {},
        ActualGroup: {},
        isFilterable: true,
        Messages: [],
        dataParam: {},
        CanEdit: check('sensor:edit'),
        CanCreate: check('sensor:create'),
        CanRemove: check('sensor:remove'),
        UseVirtuals: false,
        BaseApi: 'devices',
        TableKey: 0
      };
    }
    this.getUserGroups();
    this.toggle = this.toggle.bind(this);
    this.toggleEdit = this.toggleEdit.bind(this);
    this.toggleModalAlert = this.toggleModalAlert.bind(this);
    this.toggleMass = this.toggleMass.bind(this);
    this.toggleDownlink = this.toggleDownlink.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.CheckboxTable = checkboxHOC(ReactTable);
    this.t = props.t;
  }

  getDevicesList() {
    this.setState({Modal: false, ModalEdit: false});
  }

  toggleVirtuals(evt) {
    const checked = evt.target.checked;
    this.customSetState({
      UseVirtuals: checked,
      CanEdit: checked ? check('vsensor:edit') : check('sensor:edit'),
      CanCreate: checked ? check('vsensor:create') : check('sensor:create'),
      CanRemove: checked ? check('vsensor:remove') : check('sensor:create'),
      BaseApi: checked ? 'virtualDevices' : 'devices',
      ActualProvider: checked ? undefined : this.state.Providers[0],
      data: [],
    }, () => this.fetchData(this.checkboxTable.state));
  }

  getInitialState () {
    const state = JSON.parse(localStorage.getItem('SensorDetail') || '{}');
    if (this.state !== {}) {
      if (!state.selection) {
        state.selection = [];
      }if (!state.Messages) {
        state.Messages = [];
      }if (!state.ActualProvider) {
        state.ActualProvider = {};
      }if (!state.ActualGroup) {
        state.ActualGroup = {};
      }if (!state.dataParam) {
        state.dataParam = {};
      }if (!state.data) {
        state.data = [];
      }if (!state.Devices) {
        state.Devices = [];
      }if (!state.BaseApi) {
        state.BaseApi = 'devices';
      }
      if (state.UseVirtuals) {
        state.CanCreate = check('vsensor:create');
        state.CanEdit = check('vsensor:edit');
        state.CanRemove = check('vsensor:remove');
        state.ActualProvider = undefined;
      } else {
        state.CanCreate = check('sensor:create');
        state.CanEdit = check('sensor:edit');
        state.CanRemove = check('sensor:remove');
      }
      state.isFilterable = true;
      state.ModalExport = false;
      state.Modal = false;
      state.ModalMass = false;
      state.ModalDownlink = false;
      state.ModalAlert = false;
    }
    return state;
  }

  requestData(pageSize, page, sorted, filtered) {
    return new Promise((resolve, reject) => {
      var url = Conf.BaseApi + this.state.BaseApi + '/getSorted';

      postRequest(url, {pageSize, page, sorted, filtered}, (data) => {
        if (data.success) {
          const res = {
            rows: data.result.rows,
            pages: data.result.pages
          };
          resolve(res);
        } else {
          reject(new Error("" + data.message));
        }
      });
    });
  }

  fetchData(state, instance) {
    // Whenever the table model changes, or the user sorts or changes pages, this method gets called and passed the current table model.
    // You can set the `loading` prop of the table to true to use the built-in one or show you're own loading bar if you want.
    this.setState({ loading: true });
    // Request the data however you want.  Here, we'll use our mocked service we created earlier
    state = this.state.dataParam;

    this.requestData(
      state.pageSize,
      state.page,
      state.sorted,
      state.filtered
    ).then(res => {
      // Now just get the rows of data to your React Table (and update anything else like total pages or loading)
      this.customSetState({
        data: res.rows,
        selection: [],
        selectAll: false,
        pages: res.pages,
        loading: false,
        dataParam: state
      });
    }).catch((err) => {
      this.customSetState({
        data: [],
        selection: [],
        selectAll: false,
        pages: 0,
        loading: false,
        dataParam: state
      });
    });
  }

  getPic(sensor) {
    var url = Conf.BaseApi + this.state.BaseApi + '/get/picture/' + sensor._id;
    return getRequest(url, (data) => {
      if (data.success) {
        const rows = this.state.data;
        const sensorIndex = rows.findIndex((d) => d.DevEUI === sensor.DevEUI);
        if (sensorIndex !== -1) {
          rows[sensorIndex].pic = data.result.pic;
          this.customSetState({ data: rows });
        }
      }
    });
  }

  getUserGroups() {
    var url = Conf.BaseApi + 'groups/get';
    return getRequest(url, (data) => {
      if (data.success) {
        this.customSetState({ UserGroups: data.result, ActualGroup: this.state.UseVirtuals ? undefined : data.result[0] }, this.getProviders.bind(this));
      }
    });
  }
  getProviders() {
    var url = this.state.ActualGroup ? `${Conf.BaseApi}providers/getFromGroup/${this.state.ActualGroup._id}` : `${Conf.BaseApi}providers/get`;
    return getRequest(url, (data) => {
      if (data.success) {
        this.customSetState({ Providers: data.result, ActualProvider: data.result[0] ? data.result[0] : undefined });
      }
    });
  }
  toggle() {
    this.setState({
      Modal: !this.state.Modal
    });
  }
  toggleDownlink() {
    this.setState({
      ModalDownlink: !this.state.ModalDownlink
    });
  }
  toggleMass() {
    this.setState({
      ModalMass: !this.state.ModalMass
    });
  }
  toggleModalAlert() {
    this.setState({
      ModalAlert: !this.state.ModalAlert
    });
  }
  toggleModalExport(isOpen) {
    console.log('toggleModalExport')
    this.setState({
      ModalExport: isOpen
    });
  }
  toggleEdit() {
    if (this.state.ModalEdit) {
      this.fetchData();
    }
    /*if (!this.state.ActualProvider) {
      this.setState({
        Modal: !this.state.Modal
      });
    } else {*/
      this.setState({
        ModalEdit: !this.state.ModalEdit
      });
    //}
  }

  cleanInfra() {
    var r = window.confirm(this.t('Popup.Clean.Sensor'));
    if (r == true) {
      var url = `${Conf.BaseApi}devices/cleanInfra`;
      postRequest(url, {devices: this.getSelectedSensor(this.state.selection)},(data) => {
        if (data.success && data.success == true) {
          setTimeout(this.fetchData, 2000);
        }
      });
    }
  }

  removeSensor() {
    const sensor = this.state.data.find(s => s._id === this.state.selection[0]);
    if (sensor) {
      const pwd = window.prompt(`You will not be able to undo this action.
      \nDo you still want to remove the sensor ?
      \nPlease confirm your choice by entering your password.`);
      if (pwd) {
        let url = Conf.BaseApi + Conf.RemoveSensor;
        let opt = {
          DevEUI: sensor.DevEUI,
          password: pwd
        }
        if (this.state.UseVirtuals) {
          url = Conf.BaseApi + Conf.RemoveVSensor;
          opt = {
            sensorId: sensor._id,
            password: pwd
          }
        }
        return postRequest(url, opt, (result) => {
          if (result.success === true) {
            this.fetchData();
          } else {
            alert(result.message);
          }
        });
      }
    }
  }

  renderData(data, customs) {
    const toRender = [];
    const keys = Object.keys(data);

    for (let i = 0; i < keys.length; i += 1) {
      if (customs) {
        const custom = customs.find(c => c.fieldId === keys[i]);
        if (custom) {
          
        toRender.push(
          <li className="message-item" key={i}>
             {custom.label}: <b>{custom.initValue + custom.increment * (Math.round(data[keys[i]] * 10) / 10)}</b> {custom.unit}
          </li>
        );
          continue ;
        }
      }
      if (keys[i].includes('temperature')) {
        toRender.push(
          <li className="message-item" key={i}>
            <img src='img/icon/temp.png' /> {Math.round(data[keys[i]] * 10) / 10} °C
          </li>
        );
      } else if (keys[i].includes('humidity')) {
        toRender.push(
          <li className="message-item" key={i}>
            <img src='img/icon/drop.png' /> {Math.round(data[keys[i]] * 10) / 10} %
          </li>
        );
      } else if (keys[i].includes('light')) {
        toRender.push(
          <li className="message-item" key={i}>
            <img src='img/icon/light.png' /> {data[keys[i]]} lux
          </li>
        );
      } else if (keys[i] === 'open') {
        if (data[keys[i]]) {
          toRender.push(
            <li className="message-item" key={i}>
              <img src='img/icon/door_open.png' /> {this.t('Open')}
            </li>
          );
        } else {
          toRender.push(
            <li className="message-item" key={i}>
              <img src='img/icon/door.png' /> {this.t('Close')}
            </li>
          );
        }
      }
    }
    return toRender;
  }

  renderSensor(data, customs) {
    const toRender = [];
    if (data) {
      const keys = Object.keys(data);
      if (keys.length > 0) {
        toRender.push(
              <div className="buildingItem" >
                  <ul className="message-list">{renderMessageData(data, customs, this.t)}</ul>
              </div>
        );
      }
    }
    return toRender;
  }

  render() {
    const { data, selectAll, pages, loading } = this.state;
    const checkboxProps = {
      selectAll: selectAll,
      isSelected: this.isSelected.bind(this),
      toggleSelection: this.toggleSelection.bind(this),
      toggleAll: this.toggleAll.bind(this),
      selectType: "checkbox",
      getTrProps: (s, r) => {
        // someone asked for an example of a background color change
        // here it is...
        if (r) {
          const selected = this.isSelected(r.original._id);
          return {
            style: {
              backgroundColor: selected ? "#0CF2B3" : "inherit"
              // color: selected ? 'white' : 'inherit',
            }
          };
        }
        return { style: {backgroundColor: "inherit"} };
      }
    };
    const columns = this.getColumns();

    return (
      <div className="row">
        <div className="col-lg-12 col-md-12">
          <div className="card">
            <div className="card-header">
              <div className="row">
                <div className="col-lg-12 vertical-center" style={{paddingBottom: '0px'}}>
                  {this.state.downloadCSV}
                    { this.state.selection.length > 0 ? 
                      <span>{' '}
                      <Button onClick={() => this.toggleModalExport(true)}>{this.t('Export')}</Button>
                      </span> : ''
                    }{ this.state.selection.length > 0 ? 
                      <span>{' '}
                      <Button onClick={() => this.generateQRCode()}>{this.t('GetQRCode')}</Button>
                      </span> : ''
                    }{ this.state.selection.length > 0 && this.state.CanEdit ? 
                      <span>{' '}
                      <Button onClick={() => this.toggleDownlink()}>{this.t('Downlink')}</Button>
                      </span> : ''
                    }{ this.state.selection.length === 1 && this.state.CanEdit ? 
                      <span>
                      {' '}
                      <Button onClick={() => this.toggleEdit()}>{this.t('Edit')}</Button>
                      </span> : ''
                    }{ this.state.selection.length === 1 && this.state.CanRemove ? 
                      <span>
                      {' '}
                      <Button onClick={() => this.removeSensor()}>{this.t('Delete')}</Button>
                      </span> : ''
                    }{ this.state.selection.length > 0 && this.state.CanEdit ? 
                      <span>
                      {' '}
                      <Button onClick={() => this.toggleModalAlert()}>{this.t('Alert')}</Button>
                      </span> : ''
                    }{ this.state.selection.length > 0 && this.state.CanEdit ? 
                      <span>
                      {' '}
                      <Button onClick={() => this.cleanInfra()}>{this.t('Sensor.CleanInfra')}</Button>
                      </span> : ''
                    }
                    <span className="float-right" style={{marginRight:'50px'}}>{this.t('Physical')}{' '}
            <label className="switch switch-text switch-pill switch-danger switch-alert">
            { this.state.UseVirtuals ? <input type="checkbox" className="switch-input"  onChange={this.toggleVirtuals.bind(this)} defaultChecked /> :
              <input type="checkbox" className="switch-input"  onChange={this.toggleVirtuals.bind(this)}  /> }
              <span className="switch-label" data-on="" data-off=""></span>
              <span className="switch-handle" ></span>
            </label>
            {' '}<span style={{marginLeft:'50px'}}>{this.t('Virtual')}</span>
            </span>
                </div>
              </div>
            </div>
            <div className="card-block">
            <this.CheckboxTable
              key={this.state.TableKey}
              filterable={this.state.isFilterable}
              defaultFilterMethod={(filter, row) =>
                String(row[filter.id]).includes(filter.value)}
              ref={r => (this.checkboxTable = r)}
              data={data}
              manual // Forces table not to paginate or sort automatically, so we can handle it server-side
              pages={pages} // Display the total number of pages
              loading={loading}
              onFetchData={this.fetchData}
              columns={columns}
              defaultPageSize={this.state.dataParam.pageSize ? this.state.dataParam.pageSize : 20}
              className="-striped -highlight"
              onPageChange={(pageIndex) => {
                this.state.dataParam.page = pageIndex;
                this.customSetState({dataParam: this.state.dataParam});
              }} // Called when the page index is changed by the user
              onPageSizeChange={(pageSize, pageIndex) => {
                this.state.dataParam.pageSize = pageSize;
                this.state.dataParam.page = pageIndex;
                this.customSetState({dataParam: this.state.dataParam});
              }} // Called when the pageSize is changed by the user. The resolve page is also sent to maintain approximate position in the data
              onSortedChange={(newSorted, column, shiftKey) => {
                this.state.dataParam.sorted = newSorted;
                this.customSetState({dataParam: this.state.dataParam});
              }} // Called when a sortable column header is clicked with the column itself and if the shiftkey was held. If the column is a pivoted column, `column` will be an array of columns
              
              onFilteredChange={(filtered, column) => {
                this.state.dataParam.filtered = filtered;
                this.customSetState({dataParam: this.state.dataParam});
              }} // Called when a user enters a value into a filter input field or the value passed to the onFiltersChange handler by the Filter option.
              onResizedChange={(newResized, event) => {
                this.state.dataParam.resized = newResized;
                this.customSetState({dataParam: this.state.dataParam});
              }} // Called when a user clicks on a resizing component (the right edge of a column header)            
              page={this.state.dataParam.page ? this.state.dataParam.page : 0}
              defaultSorted={this.state.dataParam.sorted ? this.state.dataParam.sorted : []}
              defaultFiltered={this.state.dataParam.filtered ? this.state.dataParam.filtered : []}
              defaultResized={this.state.dataParam.resized ? this.state.dataParam.resized : []}
              
              SubComponent={row => {
                let buffer = null;
                
                const toRender = [];
                if (row.original.pic) {
                  buffer = new Buffer(row.original.pic);
                  toRender.push(<Col md='6' lg='2'>
                    <img src={buffer ? buffer.toString('utf8') : `img/sensorTypes/${row.original.model.replace('/', '')}.png`} width='150px' alt="icon"/>
                  </Col>);
                }
                if (row.original.lastMessage) {
                  toRender.push(<Col lg='6'>{this.renderSensor(row.original.lastMessage.data, row.original.customFields)}</Col>);
                }
                if (row.original.building) {
                  toRender.push(<Col lg='6'><ul className="icons-list"><Infrastructure infra={row.original.building} /></ul></Col>);
                }
                if (row.original.alarmModels) {
                  const tmpRender = []
                  for(let i = 0; i < row.original.alarmModels.length; i += 1) {
                    tmpRender.push(<AlarmModel alarmModel={row.original.alarmModels[i]} />);
                  }
                  toRender.push(<Col lg='6'><ul className="icons-list">{tmpRender}</ul></Col>);
                }
                return (<Row>{ toRender }</Row>);
              }}
              {...checkboxProps}
            />
            <br/>
            {this.state.CanCreate && this.state.selection.length === 0 ? <button type="button" onClick={this.toggle} className="btn btn-primary btn-lg" >{this.t('Sensor.Add')}</button> : ""}
              {' '}
              {this.state.CanCreate && !this.state.UseVirtuals ? <button type="button" onClick={this.toggleMass} className="btn btn-info btn-lg" >{this.t('Sensor.MassProv')}</button> : ""}
            </div>
          </div>
        </div>
        <Modal isOpen={this.state.Modal} className={'modal-md ' + this.props.className}>
                    <ModalHeader>
                        <Row style={{paddingLeft:'15px', paddingRight:'15px'}}>
                            <h1>{this.t('Sensor.Add')}</h1>
                            <i className="icon-close color-danger" onClick={this.toggle}
                                style={{position:'absolute', textAlign: 'right', right:'15px', cursor:'pointer'}}></i>
                        </Row>
                    </ModalHeader>
           {this.getAddDeviceContent(this.state.ActualProvider, this.state.ActualGroup)}
        </Modal>
        <Modal isOpen={this.state.ModalMass} className={'modal-md ' + this.props.className}>
                    <ModalHeader>
                        <Row style={{paddingLeft:'15px', paddingRight:'15px'}}>
                            
                            <i className="icon-close color-danger" onClick={this.toggleMass}
                                style={{position:'absolute', textAlign: 'right', right:'15px', cursor:'pointer'}}></i>
                        </Row>
                    </ModalHeader>
           <MassProvisionning />
        </Modal>
        <Modal isOpen={this.state.ModalEdit} className={'modal-md ' + this.props.className}>
                    <ModalHeader>
                        <Row style={{paddingLeft:'15px', paddingRight:'15px'}}>
                            <h1>{this.t('Edit')}</h1>
                            <i className="icon-close color-danger" onClick={this.toggleEdit}
                                style={{position:'absolute', textAlign: 'right', right:'15px', cursor:'pointer'}}></i>
                        </Row>
                    </ModalHeader>
                    {this.state.UseVirtuals ? this.getAddDeviceContent(this.state.ActualProvider, this.state.ActualGroup) :
           <EditSensor sensor={this.state.data.find(s => s._id === this.state.selection[0])} callback={this.toggleEdit}/> }
        </Modal>
        <Modal isOpen={this.state.ModalDownlink} className={'modal-md ' + this.props.className}>
                    <ModalHeader>
                        <Row style={{paddingLeft:'15px', paddingRight:'15px'}}>
                            <h1>{this.t('Send')}</h1>
                            <i className="icon-close color-danger" onClick={this.toggleDownlink.bind(this)}
                                style={{position:'absolute', textAlign: 'right', right:'15px', cursor:'pointer'}}></i>
                        </Row>
                    </ModalHeader>
           <SendDownlink sensors={this.getSelectedSensor(this.state.selection)} callback={this.toggleDownlink}/>
        </Modal>
        <Modal isOpen={this.state.ModalAlert} className={'modal-md ' + this.props.className}>
                    <ModalHeader>
                        <Row style={{paddingLeft:'15px', paddingRight:'15px'}}>
                            <h1>{this.t('Edit')}</h1>
                            <i className="icon-close color-danger" onClick={this.toggleModalAlert}
                                style={{position:'absolute', textAlign: 'right', right:'15px', cursor:'pointer'}}></i>
                        </Row>
                    </ModalHeader>
           <EditAlertSensor sensors={this.state.selection} callback={this.toggleModalAlert}/>
        </Modal>
        <Modal isOpen={this.state.ModalExport} className={'modal-md ' + this.props.className}>
                    <ModalHeader>
                        <Row style={{paddingLeft:'15px', paddingRight:'15px'}}>
                            <h1>{this.t('Export')}</h1>
                            <i className="icon-close color-danger" onClick={() => this.toggleModalExport(false)}
                                style={{position:'absolute', textAlign: 'right', right:'15px', cursor:'pointer'}}></i>
                        </Row>
                    </ModalHeader>
           <ExportData callback={() => this.toggleModalExport(false)} devices={this.state.data.filter((d) => this.state.selection.some(s => s === d._id))} />
        </Modal>
      </div>

    )
  }

  // Only used for real sensors
  getSelectedSensor(selection) {
    const sensors = [];
    for (let i = 0; i < selection.length; i += 1) {
      const devices = this.state.data.filter((d) => d._id === selection[i]);
      if (devices && devices.length > 0) {
        sensors.push(devices[0].DevEUI);
      }
    }
    return sensors;
  }

  updateActProvider(provider) {
    this.customSetState({ActualProvider: provider});
  }
  updateActGroup(group) {
    this.customSetState({ActualGroup: group}, this.getProviders.bind(this));
  }


  getAddDeviceContent(ActualProvider, ActualGroup) {
    let toRender = [];
    if (ActualProvider != undefined && ActualProvider.operator === 'SIGFOX') {
        toRender.push(<AddSigFox key={'popupAddSenspr'} updateActProvider={this.updateActProvider.bind(this)}
                                  updateActGroup={this.updateActGroup.bind(this)}
                                  UserGroups={this.state.UserGroups}
                                  Providers={this.state.Providers}
                                  SensorGroups={this.state.SensorGroups}
                                  callback={this.getDevicesList.bind(this)}
                                  selectedProvider={ActualProvider}
                                  selectedGroup={ActualGroup}/>);
    }  else if (this.state.UseVirtuals)  {
      toRender.push(<AddVirtual key={'popupAddSenspr'} updateActGroup={this.updateActGroup.bind(this)}
                              vsensor={this.state.data.find(s => s._id === this.state.selection[0])}
                              UserGroups={this.state.UserGroups}
                              SensorGroups={this.state.SensorGroups}
                              callback={this.getDevicesList.bind(this)}
                              selectedGroup={ActualGroup}/>);
  } else {
    toRender.push(<AddLoRa key={'popupAddSenspr'} updateActProvider={this.updateActProvider.bind(this)}
                            updateActGroup={this.updateActGroup.bind(this)}
                            UserGroups={this.state.UserGroups}
                            Providers={this.state.Providers}
                            SensorGroups={this.state.SensorGroups}
                            callback={this.getDevicesList.bind(this)}
                            selectedProvider={this.state.ActualProvider}
                            selectedGroup={ActualGroup}/>);
  }
    return toRender;
  }

  /** React-table */
  
  getColumns() {
    if (this.state.UseVirtuals) {
      return [
        { id: 'name', accessor: d => <div>
          {d.alertIfUnseen && d.usersToMail.length > 0 ? <img src="img/camera.png" width="15px" style={{marginTop: "-10px", marginRight: "5px"}} /> : '' }
          <a href={`#/sensors/detail?sensorId=${d._id}`}>{d.name}</a>
          </div>, Header: this.t('Name') }, 
        { id: "type", Header: this.t('Type'), accessor: d => {
          const toRender = [];
          d.type.forEach(element => {
            toRender.push(<img src={`img/sensorIcones/${element}.png`} alt='' width={25}/>);
          });
          return (toRender);
        }, maxWidth: 80, filterMethod: (filter, sensor) => {
          const str = sensor._original.type.join();
          if (str.includes(filter.value))
            return (true);
          return false;
        } }, 
        { id: "usage", accessor: d => d.usage ? d.usage : '', Header: this.t('Usage') }, 
        { Header: this.t('Groups'), id: "group", accessor: d => d.group ? d.group.name : '' }, 
        { Header: this.t('Tags'), id: "groups", accessor: d => d.groups ? d.groups.join(', ') : '' }, 
        { id: "lastSeen", 
          maxWidth: 150,
          accessor: d => d.lastSeen ? getDateStr(new Date(d.lastSeen).getTime()) : '',
          Header: this.t('Message.Time') 
        }, { id: 'active', 
          accessor: d => {
            return (<div>
                <span className={(d.active) ? 'badge badge-success' : 'badge badge-danger'}>{d.active ? this.t('Active') : this.t('Inactive')}</span>
                
            </div>) 
            },
          Header: this.t('Status'), maxWidth: 100 },
        {
            expander: true,
            Header: () => <strong>{this.t('More')}</strong>,
            width: 65,
            Expander: ({ isExpanded, ...rest }) =>
              <div>
                {isExpanded
                  ? <span>&#x2299;</span>
                  : <span>&#x2295;</span>}
              </div>,
            style: {
              cursor: "pointer",
              fontSize: 25,
              padding: "0",
              textAlign: "center",
              userSelect: "none"
            }
          }
      ];
    } else {
      return [
        { id: 'name', accessor: d => <div>
          {d.alertIfUnseen && d.usersToMail.length > 0 ? <img src="img/camera.png" width="15px" style={{marginTop: "-10px", marginRight: "5px"}} /> : '' }
          <a href={`#/sensors/detail?deveui=${d.DevEUI}`}>{d.name}</a>
          </div>, Header: this.t('Name') }, 
        { id: "type", Header: this.t('Type'), accessor: d => {
          const toRender = [];
          d.type.forEach(element => {
            toRender.push(<img src={`img/sensorIcones/${element}.png`} alt='' width={25}/>);
          });
          return (toRender);
        }, maxWidth: 80, filterMethod: (filter, sensor) => {
          const str = sensor._original.type.join();
          if (str.includes(filter.value))
            return (true);
          return false;
        } }, 
        { id: "usage", accessor: d => d.usage ? d.usage : '', Header: this.t('Usage') }, 
        { Header: this.t('Groups'), id: "group", accessor: d => d.group ? d.group.name : '' }, 
        { Header: this.t('Tags'), id: "groups", accessor: d => d.groups ? d.groups.join(', ') : '' }, 
        { id: "model", accessor: d => d.model ? d.model : '',  Header: this.t('DeviceModel') }, 
        { id: "DevEUI", accessor: d => d.DevEUI ? d.DevEUI : '', Header: this.t('DevEUI') }, 
        { id: "lastSeen", 
          maxWidth: 150,
          accessor: d => d.lastSeen ? getDateStr(new Date(d.lastSeen).getTime()) : '',
          Header: this.t('Message.Time') 
        }, { id: 'status', 
          accessor: d => {
            if (d.status) {
            return (<div>
                <span className={(d.status === 'ACTIF') ? 'badge badge-success' : (d.status === 'NOSIGNAL') ? 'badge badge-danger' : 'badge badge-default'}>{this.t(d.status)}</span>
                
            </div>) 
            }
            },
          Header: this.t('Status'), maxWidth: 100 },
        {
            expander: true,
            Header: () => <strong>{this.t('More')}</strong>,
            width: 65,
            Expander: ({ isExpanded, ...rest }) =>
              <div>
                {isExpanded
                  ? <span>&#x2299;</span>
                  : <span>&#x2295;</span>}
              </div>,
            style: {
              cursor: "pointer",
              fontSize: 25,
              padding: "0",
              textAlign: "center",
              userSelect: "none"
            }
          }
      ];

    }
  }

  toggleSelection(key, shift, row) {
    /*
      Implementation of how to manage the selection state is up to the developer.
      This implementation uses an array stored in the component state.
      Other implementations could use object keys, a Javascript Set, or Redux... etc.
    */
    // start off with the existing state
    let selection = [...this.state.selection];
    const keyIndex = selection.indexOf(row._id);
    // check to see if the key exists
    if (keyIndex >= 0) {
      // it does exist so we will remove it using destructing
      selection = [
        ...selection.slice(0, keyIndex),
        ...selection.slice(keyIndex + 1)
      ];
    } else {
      // it does not exist so add it
      selection.push(row._id);
    }
    // update the state
    this.customSetState({ selection });
  };

  toggleAll() {
    const selectAll = this.state.selectAll ? false : true;
    const selection = [];
    if (selectAll) {
      // we need to get at the internals of ReactTable
      const wrappedInstance = this.checkboxTable.getWrappedInstance();
      // the 'sortedData' property contains the currently accessible records based on the filter and sort
      const currentRecords = wrappedInstance.getResolvedState().sortedData;
      // we just push all the IDs onto the selection array
      currentRecords.forEach(item => {
        selection.push(item._original._id);
      });
    }
    this.customSetState({ selectAll, selection });
  };

  customSetState(state, callback) {
    this.setState(state, () => {
      localStorage.setItem('SensorDetail', JSON.stringify(this.state));
      if (callback) callback();
    });
  }

  isSelected(key) {
    /*
      Instead of passing our external selection state we provide an 'isSelected'
      callback and detect the selection state ourselves. This allows any implementation
      for selection (either an array, object keys, or even a Javascript Set object).
    */
    return this.state.selection.includes(key);
  };

  
  logSelection() {
    console.log("selection:", this.state.selection);
  };

  generateQRCode() {
    var url = `${Conf.BaseApi}devices/generateQRCode`;

    const sensors = [];
    for (let i = 0; i < this.state.selection.length; i += 1) {
      const devices = this.state.data.filter((d) => d._id === this.state.selection[i]);
      if (devices && devices.length > 0) {
        sensors.push(devices[0].DevEUI);
      }
    }

    var options = {sensors};
    return postRequest(url, options, (data) => {
      window.location.href="data:application/zip;base64,"+data;
    });
  }

  //services/api/devices/generateQRCode
}

export default translate(Manage);
 /*

        <div className="col-lg-12">
          <div className="descCustom">
            {this.state.Devices[this.SelectedDevice].isAdmin ? <button type="button" className="btn btn-primary btn-lg" >Editer le capteur</button> : "" }
          </div>
        </div>
                      {' '}<Button onClick={() => {}}>{this.t('Delete')}</Button>
 */