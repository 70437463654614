import React, { Component } from 'react';
import Conf from '../../../utils/Conf';
import { renderMessageData } from '../../../utils/RenderUtils';
import { getRequest, postRequest } from '../../../utils/WebServicesManager';
import { getDateStr } from '../../../utils/Utils';
import {
  Button
} from 'reactstrap';
import { translate } from '../../../utils/ReactMultiLang';
import ReactTable from "react-table";
import 'react-table/react-table.css';
import diff from 'deep-diff';
import {
  Row, Col
} from 'reactstrap';

class PlaceTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
        Key: 0,
        data: [],
        pages: 10,
        loading: false,
        selection: [],
        selectAll: false,
        dataParam: {},
        colList: props.colList,
        assetsUrl: props.assetsUrl,
    };
    this.getAssets(props.assetsUrl);
    this.fetchData = this.fetchData.bind(this);
    this.t = props.t;
    this.cols = [
      { id: 'name', accessor: d => d.name , Header: this.t('Name') }, 
        { id: "type", Header: this.t('Type'), accessor: d => d.type}, 
        { id: "occupancy", Header: this.t('Occupancy.Occupancy'), accessor: d => {
          if (d.sensors) {
            let occ = d.sensors.find(s => s.type.includes('OCCUPATION'));
            if (occ && occ.lastMessage && occ.lastMessage.data['occupancy']) {
              return (<div>
              <span className={(occ.lastMessage.data['occupancy'] == 0 || occ.lastMessage.data['occupancy'] == "0") ? 'badge badge-success' : 'badge badge-danger'}>{occ.lastMessage.data['occupancy'] == 0 || occ.lastMessage.data['occupancy'] == "0" ? this.t('Free') : this.t('Occuped')}</span>
              
          </div>);
              
            }
          }
          return this.t('Unknown');
        }},
        { id: "status", Header: this.t('Status'), accessor: d => d.status === "CLEAN" ?
        <span className='badge badge-success'>{this.t("Disinfected")}</span>
          :
          <span className='badge badge-danger'>{this.t("NotDisinfected")}</span> }, 
        { id: "lastOcc", Header: this.t('Occupancy.Last'), accessor: d => d.lastOcc ? getDateStr(new Date(d.lastOcc).getTime()) : this.t('Never')}, 
    ];
  }
  customSetState(state) {
    this.setState(state, () => localStorage.setItem('InfraSensorTable', JSON.stringify(this.state)));
  }
  
  componentWillReceiveProps(nextProps) {
    if (nextProps.deviceUrl !== this.props.deviceUrl) {
      this.customSetState({dataParam: {}});
      this.getDevices(nextProps.deviceUrl);
    }
  }


  getAssets(assetUrl) {
    if (!assetUrl) {
      return [];
    }
    let url = Conf.BaseApi + assetUrl;
    getRequest(url, (data) => {
      if (data.success) {
        const places = data.result;
        this.customSetState({ Places: places, data: places.slice(0, 10), pages: Math.floor(places.length / 10) + (places.length % 10 ? 1 : 0) });
      } else {
        this.customSetState({ data: [] });
      }
    });
  }


  fetchData(state, instance) {
    if (this.state.Places) {
        let data = this.state.Places.slice(0);

        for (let i = 0; i < state.filtered.length; i += 1) {
            data = data.filter((d) =>
                ('' + d[state.filtered[i].id]).includes(state.filtered[i].value)
            );
        }

        if (state.sorted.length > 0) {
            data.sort((a, b) =>
                !state.sorted[0].desc ?
                ('' + a[state.sorted[0].id]).localeCompare('' +b[state.sorted[0].id]) :
                ('' + b[state.sorted[0].id]).localeCompare('' +a[state.sorted[0].id])
            );
        }

        this.customSetState({
          dataParam: state,
          data: data.slice(state.page * state.pageSize, (state.page + 1) * state.pageSize),
          pages: Math.floor(data.length / state.pageSize) + (data.length % state.pageSize ? 1 : 0)
        });
    }
  }

  render() {
    const { pages, loading } = this.state;
    const columns = this.getColumns(this.state.colList);
    
    return (
      <div className="row" key={this.state.Key}>
        <div className="col-lg-12 col-md-12">
            { this.state.data ? 
            <ReactTable key={this.state.buildingId}
              filterable={true}
              defaultFilterMethod={(filter, row) =>
                String(row[filter.id]).includes(filter.value)}
              data={this.state.data}
              manual // Forces table not to paginate or sort automatically, so we can handle it server-side
              pages={pages} // Display the total number of pages
              loading={loading}
              ref={r => (this.checkboxTable = r)}
              onFetchData={this.fetchData}
              columns={columns}
              building={this.state.buildingId}
              defaultPageSize={10}
              className="-striped -highlight"
              onPageChange={(pageIndex) => {
                this.state.dataParam.page = pageIndex;
                this.customSetState({dataParam: this.state.dataParam});
              }} // Called when the page index is changed by the user
              onPageSizeChange={(pageSize, pageIndex) => {
                this.state.dataParam.pageSize = pageSize;
                this.state.dataParam.page = pageIndex;
                this.customSetState({dataParam: this.state.dataParam});
              }} // Called when the pageSize is changed by the user. The resolve page is also sent to maintain approximate position in the data
              onSortedChange={(newSorted, column, shiftKey) => {
                this.state.dataParam.sorted = newSorted;
                this.customSetState({dataParam: this.state.dataParam});
              }} // Called when a sortable column header is clicked with the column itself and if the shiftkey was held. If the column is a pivoted column, `column` will be an array of columns
              
              onFilteredChange={(filtered, column) => {
                this.state.dataParam.filtered = filtered;
                this.customSetState({dataParam: this.state.dataParam});
              }} // Called when a user enters a value into a filter input field or the value passed to the onFiltersChange handler by the Filter option.
              onResizedChange={(newResized, event) => {
                this.state.dataParam.resized = newResized;
                this.customSetState({dataParam: this.state.dataParam});
              }} // Called when a user clicks on a resizing component (the right edge of a column header)            
              page={this.state.dataParam.page ? this.state.dataParam.page : 0}
              defaultSorted={this.state.dataParam.sorted ? this.state.dataParam.sorted : []}
              defaultFiltered={this.state.dataParam.filtered ? this.state.dataParam.filtered : []}
              defaultResized={this.state.dataParam.resized ? this.state.dataParam.resized : []}
              
            /> : '' }
        </div>
      </div>
    );
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (diff(nextState, this.state) ? true : false);
  }


  /** React-table */
  
  getColumns(colList) {
    return colList.map(c => this.cols[c]);
  }

}

export default translate(PlaceTable);