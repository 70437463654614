import React, { Component } from 'react';
import { Progress } from 'reactstrap';
import ReactSVG from 'react-svg';

class Power extends Component {
  constructor(props) {
    super(props);
  }

  GetLastPower(messages) {
    var tmpTime = 0;
    var tmpPower = undefined;
    for (var i = 0; i < messages.length; i++) {
        if (messages[i].data.battery_level != undefined) {
          if (messages[i].time > tmpTime) {
            tmpTime = messages[i].time;
            tmpPower = messages[i].data.battery_level;
          }
        }
    }
      return tmpPower;
  }

  render() {
    const { messages } = this.props;
    var power = this.GetLastPower(messages);
    var color = "#43A047";
    var imgName = "battery-100";
    if (power == undefined) {
      color = "#d2d2d2";
      imgName = "battery-0";
    } else if (power < 85 && power > 50) {
      imgName = "battery-75";
      color = "#43A047";
    } else if (power < 51 && power > 25) {
      imgName = "battery-50";
      color = "#FFB74D";
    } else if (power < 26 && power > 4) {
      imgName = "battery-25";
      color = "#FF9800";
    } else if (power < 5) {
      imgName = "battery-0";
      color = "#E53935";
    }
    return (
          <div className="card animated fadeIn">
            <div className="card-block" style={{fill:color}}>
              <br/>
              <ReactSVG path={"img/" + imgName + ".svg"} />
              <h4 style={{textAlign:"center"}}>{power}%</h4>
            </div>
          </div>
    )
  }
}

export default Power;
