import React, { Component } from 'react';
import {
    Dropdown, DropdownMenu, DropdownItem,
    DropdownToggle
} from 'reactstrap';
import { getRequest, reportGetRequest } from '../../../utils/WebServicesManager';
import { translate } from '../../../utils/ReactMultiLang';
import Conf from '../../../utils/Conf';
import { Gauge as GaugeJS } from 'gaugeJS';

class Gauge extends Component {
    constructor(props) {
        super(props);
        this.datas = {};
        this.options = {};
        this.state = {
            card1: false,
            isPublic: props.isPublic,
            isLocked: props.isLocked
        }
        this.getRequest = getRequest;
        if (props.isPublic) {
            this.getRequest = reportGetRequest;
        }
        this.t = props.t;
        this.sensor = props.sensor;
        this.getMessage(this.sensor);
    }

    getMessage(devEUI) {
        const { dataName } = this.props;
        let url = `${Conf.BaseApi}messages/getComputed/number/1/${devEUI}/${dataName}`;
        this.getRequest(url, (dataDevice) => {
            if (dataDevice.success) {
                this.setState({ Message: dataDevice.result[0] });
                this.generateGauge(this.props);
            }
        });
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.dataName !== this.props.dataName) {
            this.getMessage(nextProps);
        }
        else if (nextProps.layout !== this.props.layout) {
            this.generateGauge(nextProps);
        }
        if (this.props.isPublic !== nextProps.isPublic) {
            this.setState({
                isPublic: nextProps.isPublic
            });
            if (nextProps.isPublic) {
                this.getRequest = reportGetRequest;
            }
        }
    }

    componentDidMount() {
        this.generateGauge(this.props);
    }

    generateGauge(props) {
        const { min, max, color, gId, dataName, colors } = props;

        var opts = {
            angle: 0.01, // The span of the gauge arc
            lineWidth: 0.26, // The line thickness
            radiusScale: 1, // Relative radius
            pointer: {
                length: 0.65, // // Relative to gauge radius
                strokeWidth: 0.038, // The thickness
                color: '#000000' // Fill color
            },
            limitMax: false,     // If false, max value increases automatically if value > maxValue
            limitMin: false,     // If true, the min value of the gauge will be fixed
            colorStart: '#6FADCF',   // Colors
            colorStop: color,    // just experiment with them
            strokeColor: '#E0E0E0',  // to see which ones work best for you
            generateGradient: true,
            highDpiSupport: true,     // High resolution support
            // renderTicks is Optional
            renderTicks: {
                divisions: 10,
                divWidth: 1.2,
                divLength: 0.58,
                divColor: '#333333',
                subDivisions: 3,
                subLength: 0.23,
                subWidth: 0.7,
                subColor: '#666666'
            },
            staticLabels: {
                font: "10px sans-serif",  // Specifies font
                labels: [min, max],  // Print labels at these values
                color: "#000000",  // Optional: Label text color
                fractionDigits: 0  // Optional: Numerical precision. 0=round off.
            },
        };
        if (colors && colors.length > 1) {
          opts.staticZones = colors;
        }
        var target = document.getElementById(`gauge_${gId}`);
        if (target) {
            var gauge = new GaugeJS(target).setOptions(opts); // create sexy gauge!
            gauge.maxValue = max; // set max gauge value
            gauge.setMinValue(min);  // set min value
            gauge.set(this.state.Message ? this.state.Message.data[dataName] : min); //
        }
    }

    render() {

        const { editComponent, deleteComponent, gId, dataName, unit, title, min } = this.props;
        let val = 0;
        if (this.state.Message) {
            val = this.state.Message.data[dataName];
            if (this.state.Message.data[dataName]) {
                val = parseInt(val * 100 , 10) / 100;
            }
        }

        return (
            <div className={"grid-element"} ref='mainDiv' style={{ height: '100%' }}>
                <div className='gauge-div' style={{ height: '100%', width: '100%' }}>
                    {this.state.isPublic || this.state.isLocked ? '' : <div className="btn-group float-right">
                        <Dropdown isOpen={this.state.card1} toggle={() => { this.setState({ card1: !this.state.card1 }); }}>
                            <DropdownToggle onClick={() => { this.setState({ card1: !this.state.card1 }); }} className="btn active dropdown-toggle p-0" data-toggle="dropdown" aria-haspopup={true} aria-expanded={this.state.card1}>
                                <i className="icon-settings"></i>
                            </DropdownToggle>
                            <DropdownMenu right>
                                <DropdownItem onClick={() => { editComponent() }}><i className='icon-pencil' style={{ color: "#212121" }}></i>{this.t('Edit')}</DropdownItem>
                                <DropdownItem onClick={() => { deleteComponent() }}><i className='icon-trash' style={{ color: "#212121" }}></i>{this.t('Delete')}</DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    </div>}
                    <canvas id={`gauge_${gId}`} style={{ marginTop: this.state.isPublic || this.state.isLocked ? '0px' : '-25px', width: '100%' }} className='gauge-class'></canvas>
                    <div className="gauge-value">{this.state.Message ? parseInt(val * 10) / 10 : min}{unit}</div>
                    <div className="gauge-label">{title}</div>
                </div>
            </div>
        );
    }
}

export default translate(Gauge);
