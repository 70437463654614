import React, { Component } from 'react';
import { NavLink } from 'react-router-dom'
import { translate, setLanguage } from '../../utils/ReactMultiLang';
import { check } from '../../utils/Can';

class Sidebar extends Component {
  constructor(props) {
    super(props);

    this.t = props.t;
  }

  handleClick(e) {
    e.preventDefault();
    e.target.parentElement.classList.toggle('open');
  }

  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? 'nav-item nav-dropdown open' : 'nav-item nav-dropdown';
  }

  // secondLevelActive(routeName) {
  //   return this.props.location.pathname.indexOf(routeName) > -1 ? "nav nav-second-level collapse in" : "nav nav-second-level collapse";
  // }

  getMenu(MainUser) {
    const toRender = [];
    let counter = 0;
    if (check("infra:read")) {
      toRender.push(
        <li key={counter++} className="nav-title"><i className="icon-location-pin nav-title-icon color-logo1"></i>
          {this.t('Buildings')}
        </li>
      );
      toRender.push(
        <li key={counter++} className="nav-item nav-dropdown">
          <NavLink to={'/buildings/manage'} className="nav-link brand-logo1" activeClassName="active"><i className="icon-equalizer"></i> {this.t('Sidebar.Management')}</NavLink>
        </li>
      );
      toRender.push(
        <li key={counter++} className="nav-item nav-dropdown">
          <NavLink to={'/buildings/view'} className="nav-link brand-logo1" activeClassName="active"><i className="icon-target"></i> {this.t('Sidebar.QuickView')}</NavLink>
        </li>
      );
      if (check("plan:read")) {
        toRender.push(
          <li key={counter++} className="nav-item nav-dropdown">
            <NavLink to={'/buildings/plan'} className="nav-link brand-logo1" activeClassName="active"><i className="icon-map"></i> {this.t('Sidebar.Plans')}</NavLink>
          </li>
        );
      }
      if (check("heatmap:read")) {
        toRender.push(
          <li key={counter++} className="nav-item nav-dropdown">
            <NavLink to={'/buildings/heatmap'} className="nav-link brand-logo1" activeClassName="active"><i className="icon-map"></i> {this.t('Sidebar.Heatmap')}</NavLink>
          </li>
        );
      }
    }
    if (check("sensor:read")) {
      toRender.push(
        <li key={counter++} className="nav-title"><i className="icon-compass nav-title-icon color-logo2"></i>
          {this.t('Sensors')}
        </li>
      );
      /*toRender.push(
        <li key={counter++} className="nav-item nav-dropdown">
          <NavLink to={'/sensors/quickview'} className="nav-link brand-logo2" activeClassName="active"><i className="icon-eye"></i> {this.t('Sidebar.QuickView')}</NavLink>
        </li>
      );
      toRender.push(
        <li key={counter++} className="nav-item nav-dropdown">
          <NavLink to={'/sensors/statistics'} className="nav-link brand-logo2" activeClassName="active"><i className="icon-speedometer"></i> {this.t('Sidebar.Statistics')}</NavLink>
        </li>
      );*/
      toRender.push(
        <li key={counter++} className="nav-item nav-dropdown">
          <NavLink to={'/sensors/manage'} className="nav-link brand-logo2" activeClassName="active"><i className="icon-equalizer"></i> {this.t('Sidebar.Management')}</NavLink>
        </li>
      );
      toRender.push(
        <li key={counter++} className="nav-item nav-dropdown">
          <NavLink to={'/sensors/gps'} className="nav-link brand-logo2" activeClassName="active"><i className="icon-map"></i> {this.t('Sidebar.GPSSensors')}</NavLink>
        </li>
      );
      if (check("ai:read")) {
        toRender.push(
          <li key={counter++} className="nav-item nav-dropdown">
            <NavLink to={'/sensors/ai'} className="nav-link brand-logo2" activeClassName="active"><i className="icon-social-reddit"></i> AI - Stats</NavLink>
          </li>
        );
      }
    }
    if (check("alarm:read")) {
      toRender.push(
        <li key={counter++} className="nav-title"><i className="icon-speedometer nav-title-icon color-logo3"></i>
          {this.t('Alarms')}
        </li>
      );
      toRender.push(
        <li key={counter++} className="nav-item nav-dropdown">
          <NavLink to={'/alarms/manage'} className="nav-link brand-logo3" activeClassName="active"><i className="icon-equalizer"></i> {this.t('Sidebar.Management')}</NavLink>
        </li>
      );
      toRender.push(
        <li key={counter++} className="nav-item nav-dropdown">
          <NavLink to={'/alarms/models'} className="nav-link brand-logo3" activeClassName="active"><i className="icon-puzzle"></i> {this.t('Sidebar.Models')}</NavLink>
        </li>
      );
    }
    if (check("network:read")) {
      toRender.push(
        <li key={counter++} className="nav-title"><i className="icon-speedometer nav-title-icon color-logo3"></i>
          {this.t('Networks')}
        </li>
      );
    }
    if (check("network:read")) {
      toRender.push(
        <li key={counter++} className="nav-item nav-dropdown">
          <NavLink to={'/networks/map'} className="nav-link brand-logo3" activeClassName="active"><i className="icon-map"></i> {this.t('Sidebar.NetworkMap')}</NavLink>
        </li>
      );
    }
    if (check("network:edit")) {
      toRender.push(
        <li key={counter++} className="nav-item nav-dropdown">
          <NavLink to={'/networks/coverage'} className="nav-link brand-logo3" activeClassName="active"><i className="icon-location-pin"></i> {this.t('Sidebar.Coverage')}</NavLink>
        </li>
      );
    }
    if (check("round:read")) {
    toRender.push(
      <li key={counter++} className="nav-title"><i className="icon-settings nav-title-icon color-logo4"></i>
        {this.t('Sidebar.Delivery')}
      </li>
    );
      toRender.push(
        <li key={counter++} className="nav-item nav-dropdown">
          <NavLink to={'/rounds'} className="nav-link  brand-logo4" activeClassName="active"><i className="icon-notebook"></i> {this.t('Sidebar.Management')}</NavLink>
        </li>
      );
    }
    toRender.push(
      <li key={counter++} className="nav-title"><i className="icon-settings nav-title-icon color-logo4"></i>
        {this.t('Sidebar.Settings')}
      </li>
    );
    if (check("report:read")) {
      toRender.push(
        <li key={counter++} className="nav-item nav-dropdown">
          <NavLink to={'/reports'} className="nav-link  brand-logo4" activeClassName="active"><i className="icon-notebook"></i> {this.t('Reports')}</NavLink>
        </li>
      );
    }
    if (check("user:read")) {
      toRender.push(
        <li key={counter++} className="nav-item nav-dropdown">
          <NavLink to={'/users/manage'} className="nav-link  brand-logo4" activeClassName="active"><i className="icon-user"></i> {this.t('Sidebar.Account')}</NavLink>
        </li>
      );
    }
    if (check("group:edit")) {
      toRender.push(
        <li key={counter++} className="nav-item nav-dropdown">
          <NavLink to={'/groups/manage'} className="nav-link brand-logo4" activeClassName="active"><i className="icon-people"></i> {this.t('Groups')}</NavLink>
        </li>
      );
    }
    if (check("user:create")) {
      toRender.push(
        <li key={counter++} className="nav-item nav-dropdown">
          <NavLink to={'/users/manageAdm'} className="nav-link brand-logo4" activeClassName="active"><i className="icon-people"></i> {this.t('Sidebar.UserManagment')}</NavLink>
        </li>
      );
    }
    return toRender;
  }

  render() {
    const { MainUser } = this.props;

    return (

      <div className="sidebar">
        <nav className="sidebar-nav">
          <ul className="nav">
            <li className="nav-item">
              <NavLink to={'/dashboard'} className="nav-link nav-dashboard" activeClassName="active">Dashboard</NavLink>
            </li>
            {this.getMenu(MainUser)}
          </ul>
        </nav>
      </div >
    )
  }
}
/*

            
            <li className="nav-title"><i className="icon-wrench nav-title-icon orange"></i>
              Outils
            </li>
            <li className="nav-item nav-dropdown">
              <NavLink to={'/planner'} className="nav-link brand-orange" activeClassName="active"><i className="icon-grid"></i> Planner</NavLink>
            </li>
*/
export default translate(Sidebar);
