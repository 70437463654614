import React, { Component } from 'react';
import Conf from '../../../../utils/Conf';
import { minuteToTime } from '../../../../utils/Utils';
import { getRequest, postRequest } from '../../../../utils/WebServicesManager';
import { Modal, Col, Card, Row, ModalHeader } from 'reactstrap';
import { translate } from '../../../../utils/ReactMultiLang';
import AddFloor from '../AddFloor/AddFloor';
import AddRoom from '../AddRoom/AddRoom';
import EditOpeningTime from '../../../../components/Modals/EditOpeningTime';
import Room from './Room';
import AddBuilding from '../AddBuilding/AddBuilding.js';
import RoomContainer from './RoomContainer/RoomContainer';
import { check } from '../../../../utils/Can';

class BuildingDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Building: props.building,
      floor: undefined,
      selectedFloor: undefined,
      room: undefined,
      ModalAddFloor: false,
      ModalEditBuild: false,
      ModalAddRoom: false,
      ModalOpenTime: false,
      CanCreate: check('infra:edit')
    };
    const state = this.getInitialState();
    if (state !== {}) {
      this.state = state;
    }
    this.state.Building = props.building;
    this.getBuildingDetails = props.getBuildingDetails;
    this.removeBuilding = props.removeBuilding;
    this.toggleOpenTime = this.toggleOpenTime.bind(this);
    this.t = props.t;
  }

  customSetState(state) {
    this.setState(state, () => localStorage.setItem('BuildingDetails', JSON.stringify(this.state)));
  }

  getInitialState () {
    const state = JSON.parse(localStorage.getItem('BuildingDetails') || '{}');
    if (this.state !== {}) {
      if (!state.CanCreate) {
        state.CanCreate = check('infra:edit');
      }
      if (!state.Building) {
        state.Building = {};
      }
    }
    return state;
  }


  selectFloor(floor) {
    this.customSetState({selectedFloor: floor});
  }

  getBuildDetails() {
    this.setState({ModalEditBuild: false, ModalAddFloor: false, ModalAddRoom: false});
    this.getBuildingDetails();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.building !== this.props.building) {
      if (nextProps.building && this.props.building
        && this.props.building._id === nextProps.building._id && this.state.selectedFloor) {
          const selectedFloor = nextProps.building.floors.find(f => f._id === this.state.selectedFloor._id)
        this.customSetState({Building: nextProps.building, selectedFloor: selectedFloor});
      } else {
        this.customSetState({Building: nextProps.building, selectedFloor: undefined});
      }
    }
  }

  editBuildingCallBack() {
    if (this.state.Building)
      this.getBuildingDetails(this.state.Building._id);
    this.toggleEdit();
  }

  toggleEdit() {
    this.setState({ ModalEditBuild: !this.state.ModalEditBuild });
  }

  toggleAddFloor(floor) {
    this.setState({ ModalAddFloor: !this.state.ModalAddFloor, floor });
  }

  toggleOpenTime(building) {
    if (building && building.name) {
      this.getBuildingDetails(this.state.Building._id);
    }
    this.setState({ ModalOpenTime: !this.state.ModalOpenTime });
  }


  toggleAddRoom(room) {
    this.setState({ ModalAddRoom: !this.state.ModalAddRoom, room });
  }

  editRoom(room) {
    this.toggleAddRoom(room);
  }
  editFloor(floor) {
    this.toggleAddFloor(floor);
  }

  addToRow(toAdd) {
    return (<Row>{toAdd}</Row>);
  }

  renderRooms(rooms, building) {
    let toRender = [];

    toRender.push(
      <RoomContainer entity={this.state.selectedFloor ? this.state.selectedFloor : building}
        entityName={this.state.selectedFloor ? 'floors' : 'buildings'}
        editEntity={this.state.selectedFloor ? this.editFloor.bind(this, this.state.selectedFloor) : this.toggleEdit.bind(this)}
        removeEntity={this.state.selectedFloor ? this.removeFloor.bind(this, this.state.selectedFloor): this.removeBuilding.bind(this, building)}
        isAdmin={this.state.CanCreate}/>
    );
    if (this.state.selectedFloor) {
      rooms = this.state.selectedFloor.rooms;
    }
    let tmpAdd = [];
    if (rooms && rooms.length > 0) {
      rooms = rooms.sort((a, b) => {
        if (a.name < b.name) return -1;
        else return 1;
      });
      for (let i = 0; i < rooms.length; i += 1) {
        tmpAdd.push(<Room room={rooms[i]}
            editRoom={this.editRoom.bind(this, rooms[i])}
            removeRoom={this.removeRoom.bind(this, rooms[i])}
            isAdmin={this.state.CanCreate}/>);
            if (tmpAdd.length === 3) {
              toRender.push(this.addToRow(tmpAdd));
              tmpAdd = [];
            }
      }
    }
    if (this.state.CanCreate) {
      tmpAdd.push(<Col md='4'><Col md='12' className={`room-cell add-room${this.state.CanCreate ? '' : ' disabled'}`}
        onClick={this.state.CanCreate ? this.toggleAddRoom.bind(this, undefined) : ''}>
        <p>+</p>
      </Col></Col>);
    }
    toRender.push(this.addToRow(tmpAdd));
    return toRender;
  }

  renderOpen(openTime) {
    let toRender = [];

    for (let i = 0; i < 7; i += 1) {
      const time = openTime[Conf.WeekDay[i]];
      
      if (time.length > 0) {
        toRender.push(<tr>
          <td>{this.t(Conf.WeekDay[i])}: </td>
          {time.map(t => <td colSpan={12/time.length} style={{textAlign: 'center'}}>{`${minuteToTime(t.from)} - ${minuteToTime(t.to)}`}</td>)}
        </tr>);
      } else {
        toRender.push(<tr>
          <td>{this.t(Conf.WeekDay[i])}</td>
          <td colSpan={12} style={{textAlign: 'center'}}>{this.t('Closed')}</td>
        </tr>);
      }
    }
    return toRender;
  }

  renderFloor(floors, building) {
    let toRender = [];
    toRender.push(<div className={`building-part top${this.state.CanCreate ? '' : ' disabled'}`}
      onClick={this.state.CanCreate ? this.toggleAddFloor.bind(this, undefined) : ''}><img src="img/building/top.png" className="building-img"/></div>);
    if (floors && floors.length > 0) {
      floors = floors.sort((a, b) => {
        if (a.number > b.number) return -1;
        else return 1;
      });
      for (let i = 0; i < floors.length; i += 1) {
        toRender.push(
          <div className={`building-part ${this.state.selectedFloor && this.state.selectedFloor._id === floors[i]._id ? 'selected' : ''}`}
          onClick={this.selectFloor.bind(this, floors[i])}>
          <img src="img/building/floor.png" className="building-img" />
          <div className="building-floor-card">
          <p>{floors[i].number} - {floors[i].name}
            </p>
            <p><small>{floors[i].rooms ? floors[i].rooms.length : '0'} Pièces</small></p>
          </div>
        </div>);
      }
    }
    toRender.push(<div className={`building-part bottom ${!this.state.selectedFloor ? 'selected' : ''}`}
      onClick={this.selectFloor.bind(this, undefined)}>
      <img src="img/building/base.png" className="building-img" />
      <div className="main building-floor-card">
        <p>{building.name}{'      '}</p>
        <p><small>{building.rooms ? building.rooms.length : '0'} Pièces</small></p>
        
        {!this.isOpening(building.openingTime) && this.state.CanCreate ?
            <div><button type="button" onClick={this.toggleOpenTime}
              className="btn btn-lg btn-link text-success"><img src='./img/icon/addcalendar.png' width='30px'/>
            </button></div> : ""}
      </div>
    </div>);
    if (this.isOpening(building.openingTime)) {
      toRender.push(<div className={`building-part bottom`}>
        <div className="opening building-floor-card">
          <table>
            {this.isOpening(building.openingTime) ? this.renderOpen(building.openingTime) : ''}
          </table>
        {this.isOpening(building.openingTime) && this.state.CanCreate ?
            <div><button type="button" onClick={this.toggleOpenTime}
              className="btn btn-lg btn-link text-success"><i className="icon-pencil"></i>
            </button></div> : ""}
        </div>
      </div>);
    }
    return toRender;
  }

  isOpening(opTime) {
    if (opTime) {
      for (let i = 0; i < 7; i += 1) {
        if (opTime[Conf.WeekDay[i]] && opTime[Conf.WeekDay[i]].length) return true;
      }
    }
    return false;
  }

  renderBuilding(building) {
    const toRender = [];
    if (building && building.address) {
      toRender.push(<Row>
        <Col xl='6' lg='12' className="building-nav">{this.renderFloor(building.floors, building)}</Col>
        <Col xl='6' lg='12' className="room-table">{this.renderRooms(building.rooms, building)}</Col>
      </Row>);
    }
    return toRender;
  }

  removeFloor(floor) {
    var r = window.confirm(`${this.t('Popup.Delete.Floor')} ${floor.name} ?`);
    if (r == true) {
      var url = Conf.BaseApi + 'floors/remove';
      postRequest(url, {
        floorId: floor._id,
        buildId: this.state.Building._id,
        groupId: this.state.Building.group
      }, (data) => {
        if (data.success && data.success == true) {
          this.getBuildingDetails(this.state.Building._id);
        }
      });
    }
  }

  removeRoom(room) {
    var r = window.confirm(`${this.t('Popup.Delete.Room')} ${room.name} ?`);
    if (r == true) {
      var url = Conf.BaseApi + 'rooms/remove';
      postRequest(url, {
        roomId: room._id,
        buildId: this.state.Building._id,
        groupId: this.state.Building.group
      }, (data) => {
        if (data.success && data.success == true) {
          this.getBuildingDetails(this.state.Building._id);
        }
      });
    }
  }
  render() {
    return (
            <div className="col-lg-12">
              <div className="card">
                <div className="card-header">
                  <i className="fa fa-align-justify"></i> {this.state.Building.name}
                </div>
                <div className="card-block">
                  {this.renderBuilding(this.state.Building)}
                </div>
          </div>
        <Modal isOpen={this.state.ModalOpenTime} className={'modal-lg ' + this.props.className}>
                    <ModalHeader>
                        <Row style={{paddingLeft:'15px', paddingRight:'15px'}}>
                            <h1>{this.t('OpenTime')}</h1>
                            <i className="icon-close color-danger" onClick={this.toggleOpenTime}
                                style={{position:'absolute', textAlign: 'right', right:'15px', cursor:'pointer'}}></i>
                        </Row>
                    </ModalHeader>
          <EditOpeningTime OpeningTime={this.state.Building.openingTime} building={this.state.Building._id} callback={this.toggleOpenTime} />
        </Modal>
        <Modal isOpen={this.state.ModalAddFloor} className={'modal-md ' + this.props.className}>
                    <ModalHeader>
                        <Row style={{paddingLeft:'15px', paddingRight:'15px'}}>
                            <h1>{this.state.floor ? this.t('Infra.Floor.Edit') : this.t('Infra.Floor.Add')}</h1>
                            <i className="icon-close color-danger" onClick={this.toggleAddFloor.bind(this)}
                                style={{position:'absolute', textAlign: 'right', right:'15px', cursor:'pointer'}}></i>
                        </Row>
                    </ModalHeader>
          <AddFloor building={this.state.Building} actFloor={this.state.floor} callback={this.getBuildDetails.bind(this)} />
        </Modal>
        <Modal isOpen={this.state.ModalAddRoom} className={'modal-md ' + this.props.className}>
                    <ModalHeader>
                        <Row style={{paddingLeft:'15px', paddingRight:'15px'}}>
                            <h1>{this.state.room ? this.t('Infra.Room.Edit') : this.t('Infra.Room.Add')}</h1>
                            <i className="icon-close color-danger" onClick={this.toggleAddRoom.bind(this)}
                                style={{position:'absolute', textAlign: 'right', right:'15px', cursor:'pointer'}}></i>
                        </Row>
                    </ModalHeader>
          <AddRoom building={this.state.Building} actRoom={this.state.room} floor={this.state.selectedFloor} callback={this.getBuildDetails.bind(this)} />
        </Modal>

            <Modal isOpen={this.state.ModalEditBuild} className={'modal-md ' + this.props.className}>
                    <ModalHeader>
                        <Row style={{paddingLeft:'15px', paddingRight:'15px'}}>
                            <h1>{this.t('Edit')}</h1>
                            <i className="icon-close color-danger" onClick={this.toggleEdit.bind(this)}
                                style={{position:'absolute', textAlign: 'right', right:'15px', cursor:'pointer'}}></i>
                        </Row>
                    </ModalHeader>
              <AddBuilding building={this.state.Building}
                latlng={this.state.Building !== undefined && this.state.Building.address !== undefined && this.state.Building.address.gps !== undefined ?
                  { lat: this.state.Building.address.gps[1], lng: this.state.Building.address.gps[0] }
                  : { lat: 0, lng: 0 }} callback={this.state.Building !== undefined ? this.editBuildingCallBack.bind(this) : this.toggleEdit.bind(this)} />
            </Modal>
      </div>

    )
  }
}

export default translate(BuildingDetails);