import React, { Component } from 'react';
import Conf from '../../../utils/Conf';
import { getDateStr } from '../../../utils/Utils';
import { getRequest, postRequest } from '../../../utils/WebServicesManager';
import PredictionsChart from '../../../components/Charts/PredictionsCharts';
import PlotPredictionsCharts from '../../../components/Charts/PlotPredictionsCharts';
import PredictionsRadar from '../../../components/Charts/PredictionsRadar';
import Widget from '../../../components/Widget';
import { Modal, Row, Button, ModalFooter } from 'reactstrap';
import { translate } from '../../../utils/ReactMultiLang';
import DataSelection from '../../../components/Modals/DataSelection';
import ReactTable from "react-table";

class AIStats extends Component {
  constructor(props) {
    super(props);
    const tmpState = this.getInitialState();
    this.state = { 
        Devices: [], 
        FieldList: [], 
        DevEUI: '',
        pages: 10,
        loading: false,
        Predictions: undefined,
        PlotPredictions: undefined,
        Modal: false,
        SensorOpen: tmpState ? tmpState.SensorOpen : true,
        SelectedDevice: tmpState ? tmpState.SelectedDevice : {}
    };
    this.toggle = this.toggle.bind(this);
    this.t = props.t;
  }

  getInitialState () {
    const state = JSON.parse(localStorage.getItem('AIStat') || '{}');
    if (this.state !== {}) {
      if (!state.SelectedDevice) {
        state.SelectedDevice = {};
      } else {
        this.getMessagesList(state.SelectedDevice);
        state.SensorOpen = false;
      }
    }
    return state;
  }
  toggle() {
    this.setState({Modal: !this.state.Modal});
  }

  selectDevice(sensor) {
    this.customSetState({SelectedDevice: sensor, SensorOpen: !sensor.activeML});
    this.getMessagesList(sensor);
  }

 
  requestData(pageSize, page, sorted, filtered) {
    return new Promise((resolve, reject) => {
      var url = Conf.BaseApi + 'devices/getSorted';

      postRequest(url, {pageSize, page, sorted, filtered}, (data) => {
        if (data.success) {
          const res = {
            rows: data.result.rows,
            pages: data.result.pages
          };
          const devices = res.rows.map(device => device.DevEUI);
          resolve(res);
        } else {
          reject(new Error(data.message));
        }
      });
    });
  }

  fetchData(state, instance) {
    // Whenever the table model changes, or the user sorts or changes pages, this method gets called and passed the current table model.
    // You can set the `loading` prop of the table to true to use the built-in one or show you're own loading bar if you want.
    this.setState({ loading: true });

    const filter = state.filtered.slice(0);
    filter.push({id: "activeML", value: true});
    this.requestData(
      state.pageSize,
      state.page,
      state.sorted,
      filter
    ).then(res => {
      // Now just get the rows of data to your React Table (and update anything else like total pages or loading)
      this.setState({
        data: res.rows,
        pages: res.pages,
        loading: false,
        dataParam: state
      });
    });
  }


  getMessagesList(sensor) {
    if (!sensor)
      return ;
    var url = Conf.BaseApi + 'predictions/get/' + sensor.DevEUI;
    getRequest(url, (body) => {
      if (body.success) {
        const predictions = body.result;
        this.setState({ PlotPredictions: undefined, Predictions: predictions, DevEUI: sensor.DevEUI });
      }
    });
    url = Conf.BaseApi + 'helpers/getModelsData';
    return postRequest(url, {models: [sensor.model]}, (body) => {
      if (body.success) {
        this.setState({ PlotPredictions: undefined, FieldList: body.result });
      }
    });
  }

  renderDevicesList() {
    var toRender = [];
    for (var i = 0; i < this.state.Devices.length; i++) {
        toRender.push(
          <option value={this.state.Devices[i].DevEUI + "|" + this.state.Devices[i].model} key={this.state.Devices[i].DevEUI}>{this.state.Devices[i].name} : {this.state.Devices[i].type.join(' - ')}</option>);
    }
    return toRender;
  }
  renderDeviceFields(fieldsList) {
    var toRender = [];
    for (var i = 0; i < fieldsList.length; i++) {
      const field = fieldsList[i];
        toRender.push(
          <option value={field} key={i}>
            {this.t(`SensorData.${field}`)}
          </option>
        );
    }
    return toRender;
  }

  getPlotData(event) {
    if (this.state.DevEUI !== '') {
      const url = Conf.BaseApi + 'predictions/getPlot';
      return postRequest(url, {DevEUI : this.state.DevEUI, dataName:event.target.value}, (body) => {
        if (body.success) {
          this.setState({ PlotPredictions: body.result });
        }
      });
    }
  }

  getStatistics() {
    const render = [];

    if (this.state.Predictions !== undefined) {
      const date = new Date(this.state.Predictions.nextTickPred);
      var datetime = date.getDate() + "/"
      + (date.getMonth()+1)  + "/" 
      + date.getFullYear() + " - "  
      + date.getHours() + ":"  
      + date.getMinutes();
      render.push(
      <div className="card">
          <div className="card-header">
            <i className="fa fa-building-o"></i> {this.t('K-Nearest Neighbor')}
          </div>
          <div className={`card-block`}>
            <div className="row">
              <div className="col-md-12 col-lg-8">
                <PredictionsChart predictions={this.state.Predictions}
                            subtitle='Predictions'
                            unit=''
                            color='card-primary'/>
              </div>
              <div className="col-md-12 col-lg-4">
                <Widget header={(parseInt(this.state.Predictions.nextPred * 100) / 100) + ''} mainText={datetime} icon="fa fa-balance-scale" color="info" variant="2"/>
                <PredictionsRadar predictions={this.state.Predictions}
                            subtitle='Statistics' />
              </div>
            </div>
        </div>
      </div>);
        
      render.push(
        <div className="card">
            <div className="card-header">
              <i className="fa fa-building-o"></i> {this.t('Pattern Matching')} 
            </div>
            <div className={`card-block`}>
              <div className="card-block">
              <Row>
          <div className="col-md-4 col-sm-12">
                <select className="form-control" onChange={this.getPlotData.bind(this)} id="plotData">
                   <option value="">-- Choisissez une donnée --</option>
                    {this.renderDeviceFields(this.state.FieldList)}
                </select>
          </div>
          <div className="col-md-2 col-sm-12">
                <button onClick={this.toggle}>Train Machine Learning</button>
          </div></Row>
              </div>
            </div>
          {this.state.PlotPredictions && this.state.PlotPredictions !== undefined && this.state.PlotPredictions.original !== undefined ?
        <div className="col-md-12 col-lg-12">
          <PlotPredictionsCharts predictions={this.state.PlotPredictions} />
          </div>
        : ''}
    </div>);
    }
    return render;
  }

  toggleTable() {
    this.setState({SensorOpen: !this.state.SensorOpen});
  }

  render() {
    const { pages, loading } = this.state;
    const columns = this.getColumns();
    return (
      <div className="animated fadeIn">
        <div className="row">
          <div className="col-md-12 col-lg-12">
            <div className="card">
                <div className="card-header hiddableHeader" onClick={() => this.toggleTable()}>
                  <i className="fa fa-building-o"></i> {this.t('Sensors')} {this.state.SelectedDevice ? <b> - {this.state.SelectedDevice.name}</b> : ''}
                </div>
                <div className={`card-block ${this.state.SensorOpen ? 'div-visible' : ''} hiddable`} id="hiddable">
            <ReactTable key={this.state.Devices}
              filterable={true}
              defaultFilterMethod={(filter, row) =>
                String(row[filter.id]).includes(filter.value)}
              data={this.state.data}
              manual // Forces table not to paginate or sort automatically, so we can handle it server-side
              pages={pages} // Display the total number of pages
              loading={loading}
              ref={r => (this.checkboxTable = r)}
              onFetchData={this.fetchData.bind(this)}
              columns={columns}
              building={this.state.buildingId}
              defaultPageSize={10}
              className="-striped -highlight"
            />
              </div>
            </div>
            {this.state.SelectedDevice && this.state.SelectedDevice.activeML ? this.getStatistics() : ''}
          </div>
        </div>
        <Modal isOpen={this.state.Modal} toggle={this.toggle} className={'modal-lg ' + this.props.className}>
          <DataSelection DevEUI={this.state.DevEUI}/>
        </Modal>
      </div>
    )
  }
  getColumns() {
    return [
      { id: 'name', accessor: d => <Button className='truncate' onClick={this.selectDevice.bind(this, d)} style={{width: "100%"}}>{d.name}</Button>, Header: this.t('Name') }, 
      { id: "type", Header: this.t('Type'), accessor: d => {
        const toRender = [];
        d.type.forEach(element => {
          toRender.push(<img src={`img/sensorIcones/${element}.png`} alt='' width={25}/>);
        });
        return (toRender);
      }, maxWidth: 80, filterMethod: (filter, sensor) => {
        const str = sensor._original.type.join();
        if (str.includes(filter.value))
          return (true);
        return false;
      } }, 
      { id: "usage", accessor: d => d.usage ? d.usage : '', Header: this.t('Usage') }, 
      { Header: this.t('Groups'), id: "group", accessor: d => d.group ? d.group.name : '' }, 
      { Header: this.t('Tags'), id: "groups", accessor: d => d.groups ? d.groups.join(', ') : '' }, 
      { id: "model", accessor: d => d.model ? d.model : '',  Header: this.t('DeviceModel') }, 
      { id: "DevEUI", accessor: d => d.DevEUI ? d.DevEUI : '', Header: this.t('DevEUI') }, 
      { id: "lastSeen", 
        maxWidth: 150,
        accessor: d => d.lastSeen ? getDateStr(new Date(d.lastSeen).getTime()) : '',
        Header: this.t('Message.Time') 
      }, { id: 'status', 
        accessor: d => <span className={(d.status === 'ACTIF') ? 'badge badge-success' : (d.status === 'NOSIGNAL') ? 'badge badge-danger' : 'badge badge-default'}>{this.t(d.status)}</span>,
        Header: this.t('Status'), maxWidth: 100 }
    ];
  }


  customSetState(state) {
    this.setState(state, () => localStorage.setItem('AIStat', JSON.stringify(state)));
  }
}

export default translate(AIStats);
//K-Means + Nearest Vector + Square Euclidian