import React, { Component } from 'react';
import { translate } from '../../../utils/ReactMultiLang';
import { Modal, ModalBody, FormGroup, Input, Label, Row, Col, ModalHeader } from 'reactstrap';
import $ from 'jquery';
import Conf from '../../../utils/Conf';
import { getAccessRight } from '../../../utils/AuthServices';
import { postRequest } from '../../../utils/WebServicesManager';
import SelectSensors from '../../Selects/SelectSensors/SelectSensors';


class EditGroupACLModal extends Component {
    constructor(props) {
        super(props);
        this.t = props.t;
        this.toggle = props.toggle;
    }

    updateACL(user) {
        
    let sensors = [];
    const inputsSensors = $("input[name=sensorId]");
    for (let i = 0; i < inputsSensors.length; i += 1) {
      sensors.push(inputsSensors[i].value);
    }
    
    let infras = [];
    const inputsInfras = $("input[name=buildingId]");
    for (let i = 0; i < inputsInfras.length; i += 1) {
        infras.push(inputsInfras[i].value);
    }
    var url = Conf.BaseApi + Conf.EditACL;
            return postRequest(url, {
                userId: user._id,
                sensors: sensors,
                infras: infras,
                floors: [],
                rooms: [],
                alarms: []
            }, (data) => {
                if (data.success || data.success == true) {
                    this.toggle();
                    //window.location.reload();
                } else {
                    $("#error-window").text(data.message);
                }
            });
    }

    render() {
        const { userGroup, user } = this.props;
        return (
            <Modal isOpen={this.props.isOpen} className={'modal-md ' + this.props.className}>
                <ModalHeader>
                    <Row style={{ paddingLeft: '15px', paddingRight: '15px' }}>
                        <h2>{userGroup.name}</h2>
                        <p>Empty fields mean all objects avaiable.</p>
                        <i className="icon-close color-danger" onClick={this.props.toggle}
                            style={{ position: 'absolute', textAlign: 'right', right: '15px', cursor: 'pointer' }}></i>
                    </Row>
                </ModalHeader>
                <ModalBody>
                    <div className="flex-row align-items-center">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-md-12">
                                    <div className="input-group mb-3" style={{ color: "red" }} id="error-window">
                                    </div>

                                    <label htmlFor="userGroup">{this.t('Sensors')}</label>
                                    <SelectSensors key={userGroup._id} entity='devices' values={user.accessRight !== undefined ? user.accessRight.sensors : ""}
                                        group={userGroup._id} propId='sensorId'/>

                                    <label htmlFor="userGroup">{this.t('Infrastructures')}</label>
                                    <SelectSensors key={userGroup._id} entity='buildings' values={user.accessRight !== undefined ? user.accessRight.infras : ""}
                                        group={userGroup._id} propId='buildingId'/>

                                    <button type="button" className="btn btn-block btn-success" onClick={this.updateACL.bind(this, user)}>{this.t('Update')}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        )
    }

}

export default translate(EditGroupACLModal);