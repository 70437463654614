import React, { Component } from 'react';
import $ from 'jquery';
import SelectUser from '../../Selects/SelectUser';
import GenerateTemplate from '../GenerateTemplate';
import { translate } from '../../../utils/ReactMultiLang';
import { Col, Row, Modal, ModalHeader, ModalBody } from 'reactstrap';
import 'react-cron-generator/dist/cron-builder.css'
import Cron from 'react-cron-generator'
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Select from 'react-select';
import moment from 'moment';
import { getUserId, getUserLogin } from '../../../utils/AuthServices';


class CreateReport extends Component {
  constructor(props) {
    super(props);
    this.t = props.t;
    this.state = {
      Template: undefined,
      modalSelectTemplate: false,
      sendReport: props.report ? props.report.sendReport : false,
      sendSchedule: props.report && props.report.sendSchedule ? props.report.sendSchedule : '0 0 00 1 1/1 ? *',
      isShared: props.report ? props.report.shared : false,
      useGlobal: props.report ? props.report.useGlobal : false,
      adminSelected: props.report ? props.report.adminUsers : [{_id: getUserId(), login: getUserLogin()}],
      simpleSelected: props.report ? props.report.simpleUsers : [],
      from: props.report && props.report.from ? moment(new Date(props.report.from)) : moment(),
      to: props.report && props.report.to ? moment(new Date(props.report.to)) : moment(),
      StaticDate: props.report && props.report.from ? true : false,
      usersToSend: props.report && props.report.usersToSend && props.report.usersToSend.length > 0 ? props.report.usersToSend.map((u) => {return {value: u, label: u};}) : []
    }
  }

  sendReportChange() {
    this.setState({ sendReport: $("#sendReport").is(':checked') });
  }

  updateAdminSelected(users) {
    this.setState({ adminSelected: users });
  }
  updateSimpleSelected(users) {
    this.setState({ simpleSelected: users });
  }
  updateUserToSend(users) {
    this.setState({ usersToSend: users });
  }
  useGlobalChange() {
    this.setState({ useGlobal: $("#useGlobalTimer").is(':checked') });
  }
  useStatic() {
    this.setState({StaticDate: $("#useStatic").is(':checked')});
  }

  SharedChange() {
    this.setState({ isShared: $("#sharedField").is(':checked') });
  }

  setTemplate(template) {
    this.setState({Template: template, modalSelectTemplate: false});
  }

  toggleSelectTemplate() {
    this.setState({modalSelectTemplate: !this.state.modalSelectTemplate});
  }

  createReport(callback, type) {
    let adminIds = [];
    let inputs = $("input[name=userAdmin]");
    for (let i = 0; i < inputs.length; i += 1) {
      adminIds.push(inputs[i].value);
    }
    let simpleIds = [];
    let inputsSimple = $("input[name=userSimple]");
    for (let i = 0; i < inputsSimple.length; i += 1) {
      simpleIds.push(inputsSimple[i].value);
    }
    let usersToSend = [];
    let inputsAlert = $("input[name=usersToSend]");
    for (let i = 0; i < inputsAlert.length; i += 1) {
      usersToSend.push(inputsAlert[i].value);
    }


    const report = {
      name: $("#reportName").val(),
      adminUsers: adminIds,
      simpleUsers: simpleIds,
      shared: $('#sharedField').is(':checked'),
      id: $("#idField").val(),
      password: $("#passwordField").val(),
      usersToSend: usersToSend,
      sendReport: $('#sendReport').is(':checked'),
      useGlobal: $('#useGlobalTimer').is(':checked'),
      sendSchedule: this.state.sendSchedule,
      adminGroups: [],
      simpleGroups: [],
      components: {
        rows: []
      }
    };
    if (this.state.StaticDate) {
      report.from = this.state.from.hour(0).minute(0).valueOf();
      report.to = this.state.to.hour(23).minute(59).valueOf();
    } else {
      report.days = $('#createCpDays').val();
    }

    callback(report, this.state.Template);
  }
  editReport(callback, report) {
    let adminIds = [];
    let inputs = $("input[name=userAdmin]");
    for (let i = 0; i < inputs.length; i += 1) {
      adminIds.push(inputs[i].value);
    }
    let simpleIds = [];
    let inputsSimple = $("input[name=userSimple]");
    for (let i = 0; i < inputsSimple.length; i += 1) {
      simpleIds.push(inputsSimple[i].value);
    }
    let usersToSend = [];
    let inputsAlert = $("input[name=usersToSend]");
    for (let i = 0; i < inputsAlert.length; i += 1) {
      usersToSend.push(inputsAlert[i].value);
    }

    report.name = $("#reportName").val();
    report.adminUsers = adminIds;
    report.simpleUsers = simpleIds;
    report.shared = $('#sharedField').is(':checked');
    report.id = $("#idField").val();
    report.password = $("#passwordField").val();
    report.usersToSend = usersToSend;
    report.sendReport = $('#sendReport').is(':checked');
    report.sendSchedule = this.state.sendSchedule;
    report.useGlobal = $('#useGlobalTimer').is(':checked');
    if (this.state.StaticDate) {
      report.from = this.state.from.hour(0).minute(0).valueOf();
      report.to = this.state.to.hour(23).minute(59).valueOf();
    } else {
      report.days = $('#createCpDays').val();
      report.from = null;
      report.to = null;
    }

    callback(report);
  }

  handleChangeFrom(from) {
    if (this.state.to) {
      this.setState({from});
    } else {
      this.setState({from, to: from});
    }
  }
  handleChangeTo(to) {
    this.setState({to});
  }
  render() {
    const { callback, type, report } = this.props;

    return (
      <div className="flex-row align-items-center">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12">
              <div className="card mx-12">
                <div className="card-block p-12">
                  <div className="input-group mb-3" style={{ color: "red" }} id="error-window">

                  </div>
                  <div className="input-group mb-3">
                    <span className="input-group-addon"><i className="icon-user"></i></span>
                    <input type="text" className="form-control" placeholder="Label" id="reportName" defaultValue={report !== undefined ? report.name : ''} />
                  </div>
                  {this.t('Users')} :
                  <SelectUser scopeParam="linked" selectId='userAdmin'
                    doNotUse={this.state.simpleSelected} updateSelection={this.updateAdminSelected.bind(this)}
                    values={report !== undefined ? report.adminUsers : [{_id: getUserId(), login: getUserLogin()}]} />
                  {this.t('SimpleUsers')} :
                  <SelectUser scopeParam="linked" selectId='userSimple'
                    doNotUse={this.state.adminSelected} updateSelection={this.updateSimpleSelected.bind(this)}
                    values={report !== undefined ? report.simpleUsers : undefined} />
                  <br />
                  <div className="input-group mb-3">
                    <div className="form-check form-check-inline">
                      <label className="form-check-label">
                        <input className="form-check-input" type="checkbox" id="sharedField" value="true" onChange={() => this.SharedChange()}
                          defaultChecked={report !== undefined ? report.shared : false} />
                        {' '}{this.t('Dashboard.Shared')}
                      </label>
                    </div>
                  </div>
                  {this.state.isShared ?
                    <Row>
                      <Col md='6' sm='12'>
                        <label htmlFor="alarmLevelField">{this.t('Dashboard.Id')}</label>
                        <div className="input-group mb-3">
                          <input type="text" className="form-control" placeholder={this.t('Dashboard.Id')} id="idField" defaultValue={report !== undefined ? report.id : ''} />
                        </div>
                      </Col>
                      <Col md='6' sm='12'>
                        <label htmlFor="alarmLevelField">{this.t('Password')}</label>
                        <div className="input-group mb-3">
                          <input type="text" className="form-control" placeholder={this.t('Password')} id="passwordField" defaultValue={report !== undefined ? report.password : ''} />
                        </div>
                      </Col>
                    </Row>
                    : ''}

                  <div className="input-group mb-3">
                  <div className="form-check form-check-inline">
                    <label className="form-check-label">
                      <input className="form-check-input" type="checkbox" id="useGlobalTimer" value="true" onChange={() => this.useGlobalChange()}
                        defaultChecked={report !== undefined ? report.useGlobal : false} />
                      {' '}{this.t('Report.UseGlobal')}
                    </label>
                  </div>
                </div>
                {
                  this.state.useGlobal ?
                    <div>
                      
                  <div className="input-group mb-1">
                    <div className="form-check form-check-inline">
                      <label className="form-check-label">
                        <input className="form-check-input" type="checkbox" id="useStatic" value="true" defaultChecked={this.state.StaticDate} onChange={() => this.useStatic()}/> 
                        {this.t('Component.UseStatic')}
                      </label>
                    </div>
                  </div>
          {
            this.state.StaticDate ? 
            
            <div className="input-group mb-3">
              <div style={{display:'inline-block', marginLeft:'25px', zIndex:'9000'}}>
              {this.t('Begin')}: <DatePicker
                selected={this.state.from}
                onChange={this.handleChangeFrom.bind(this)}
                dateFormat="DD MMMM YYYY"
            />
            </div>
            <div style={{display:'inline-block', marginLeft:'25px', zIndex:'9000'}}>
            {this.t('End')}: <DatePicker
              selected={this.state.to}
              onChange={this.handleChangeTo.bind(this)}
              dateFormat="DD MMMM YYYY"
            />
            </div>
          </div>
            : 
            <div className="input-group mb-3">
              <span className="input-group-addon"><i className="icon-clock"></i></span>
              <input type="number" className="form-control" placeholder={this.t('Component.DaysNum')} defaultValue={2} id="createCpDays" />
            </div>
          }
                    </div>
                    : ''
                }
                  {
                    this.state.useGlobal ?
                  <br/> : ''
                  }
                  <div className="input-group mb-3">
                    <div className="form-check form-check-inline">
                      <label className="form-check-label">
                        <input className="form-check-input" type="checkbox" id="sendReport" value="true" onChange={() => this.sendReportChange()}
                          defaultChecked={report !== undefined ? report.sendReport : false} />
                        {' '}{this.t('Report.Send')}
                      </label>
                    </div>
                  </div>
                  {
                    this.state.sendReport ?
                      <Row>
                        <Col md='12'>
                          {this.t('Users')} :
                            <Select.Creatable 
                              multi={true}
                              value={this.state.usersToSend}
                              onChange={this.updateUserToSend.bind(this)}
                              backspaceRemoves={this.state.backspaceRemoves}
                              id="usersToSend"
                              name="usersToSend"
                              />
                        </Col>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <Col md='12'>
                          <Cron id="sendSchedule"
                            onChange={(e) => { this.setState({ sendSchedule: e }); }}
                            tabs={['Monthly']}
                            value={this.state.sendSchedule ? this.state.sendSchedule : report !== undefined ? report.sendSchedule : undefined}
                            showResultText={true}
                            showResultCron={false}
                            hours={2}
                            minutes={15}
                          />
                        </Col></Row>
                      : ''
                  }
                  {
                    this.state.sendReport ?
                  <br/> : ''
                  }
                  {
                    report === undefined ?
                    
                  <button type="button" className={`btn btn-block ${this.state.Template ? 'btn-info' : 'btn-warning'}`}
                    onClick={() => this.toggleSelectTemplate()}>
                    {this.state.Template ? this.state.Template.name : this.t('Template.From')}
                  </button>
                    : ''
                  }<br/>
                  <button type="button" className="btn btn-block btn-success"
                    onClick={report === undefined ? this.createReport.bind(this, callback, type) : this.editReport.bind(this, callback, report)}>
                      {report !== undefined ? this.t('Edit') : this.t('Add')}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal isOpen={this.state.modalSelectTemplate} className={'modal-md ' + this.props.className}>
                    <ModalHeader>
                        <Row style={{paddingLeft:'15px', paddingRight:'15px'}}>
                            <h1>{this.t('Template.From')}</h1>
                            <i className="icon-close color-danger" onClick={this.toggleSelectTemplate.bind(this)}
                                style={{position:'absolute', textAlign: 'right', right:'15px', cursor:'pointer'}}></i>
                        </Row>
                    </ModalHeader>
          <ModalBody>
            <GenerateTemplate callback={this.setTemplate.bind(this)} />
          </ModalBody>
        </Modal>
      </div>
    )
  }

}
export default translate(CreateReport);