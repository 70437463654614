import React, { Component } from 'react';
import moment from 'moment';
import $ from 'jquery';
import { TimePicker } from 'react-input-moment';
import Conf from '../../../utils/Conf';
import { getRequest } from '../../../utils/WebServicesManager';
import { ButtonToolbar, ToggleButtonGroup, ToggleButton } from 'react-bootstrap';
import { translate } from '../../../utils/ReactMultiLang';


class EditComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      FieldList: []
    };
    this.t = props.t;
  }

  getFieldList(component) {
    if (this.state.FieldList.length !== 0)
      return;
    var url = Conf.BaseApi + `helpers/fields/${component.sensor}`;
    return getRequest(url, (result) => {
      if (result && result.success) {
        this.setState({FieldList: result.result});
      }
    });
  }

  renderDeviceFields(fields) {
    var toRender = [];
    for (var i = 0; i < fields.length; i++) {
        toRender.push(
          <option value={fields[i]}>{this.t(`SensorData.${fields[i]}`)}</option>
        );
    }
    return toRender;
  }

  updateComponent(component, callback) {
    component.label = $("#cpLabel").val();
    component.unit = $("#cpUnit").val();
    component.paramName = $("#dataName").val();
    component.color = $('#cpColor input:radio:checked').val();

    callback(component);
  }

  render() {
    const { component, callback } = this.props;
    if (component) {
      this.getFieldList(component);
      return (
        <div className="flex-row align-items-center">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-12">
                <div className="card mx-12">
                  <div className="card-block p-12">
                    <div className="input-group mb-3" style={{ color: "red" }} id="error-window">

                    </div>
                    <div className="input-group mb-3">
                      <span className="input-group-addon"><i className="icon-user"></i></span>
                      <input type="text" className="form-control" placeholder={this.t('Label')} id="cpLabel" defaultValue={component.label}/>
                    </div>
                    <div className="input-group mb-3">
                      <span className="input-group-addon"><i className="icon-user"></i></span>
                      <input type="text" className="form-control" placeholder={this.t('Unit')} id="cpUnit" defaultValue={component.unit}/>
                    </div>
                    <div className="input-group mb-3">
                    <ButtonToolbar>
                      <ToggleButtonGroup type="radio" name="options" id="cpColor" defaultValue={component.color}>
                        <ToggleButton value="card-primary" bsStyle="primary">{this.t('Blue')}</ToggleButton>
                        <ToggleButton value="card-success" bsStyle="success">{this.t('Green')}</ToggleButton>
                        <ToggleButton value="card-warning" bsStyle="warning">{this.t('Yellow')}</ToggleButton>
                        <ToggleButton value="card-danger" bsStyle="danger">{this.t('Red')}</ToggleButton>
                      </ToggleButtonGroup>
                    </ButtonToolbar>
                    </div>
                    <div className="form-group mb-3">
                    <div><label htmlFor="dataName">{this.t('Component.Datasets')} :</label></div>
                                      
                                      <select className="form-control" id="dataName" defaultValue={component.paramName}>
                                        {this.renderDeviceFields(this.state.FieldList)}
                                      </select>
                    </div>
                    <br/>
                    <button type="button" className="btn btn-block btn-success" onClick={this.updateComponent.bind(this, component, callback)}>Mettre à jour</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return [];
  }

}

export default translate(EditComponent);