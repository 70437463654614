import React, { Component } from 'react';
import reactCSS from 'reactcss';
import moment from 'moment';
import $ from 'jquery';
import { TimePicker } from 'react-input-moment';
import Conf from '../../../utils/Conf';
import { getRequest } from '../../../utils/WebServicesManager';
import { ButtonToolbar, ToggleButtonGroup, ToggleButton } from 'react-bootstrap';
import { SketchPicker } from 'react-color';


class ColorPicker extends Component {
  constructor(props) {
    super(props);
    this.returnColor = props.callback;
    this.returnColor(props.color ? props.color : '#f00');
    this.state = {
      displayColorPicker: false,
      lineColor: props.color ? props.color : '#f00'
    };
  }

  handleOpenColor = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker })
  };

  handleCloseColor = () => {
    this.setState({ displayColorPicker: false })
  };
  handleColorChange = (color) => {
    this.returnColor(color.hex);
    this.setState({ lineColor: color.hex });
  };


  render() {
    const styles = reactCSS({
      'default': {
        color: {
          width: '36px',
          height: '14px',
          borderRadius: '2px',
          background: `${ this.state.lineColor }`,
        },
        swatch: {
          padding: '5px',
          background: '#fff',
          borderRadius: '1px',
          boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
          display: 'inline-block',
          cursor: 'pointer',
        },
        popover: {
          position: 'absolute',
          zIndex: '2',
        },
        cover: {
          position: 'fixed',
          top: '0px',
          right: '0px',
          bottom: '0px',
          left: '0px',
        },
      },
    });
    return (
        <div>
        <div style={ styles.swatch } onClick={ this.handleOpenColor }>
            <div style={ styles.color } />
            </div>
            
            { this.state.displayColorPicker ? <div style={ styles.popover }>
            <div style={ styles.cover } onClick={ this.handleCloseColor }/>
            <SketchPicker color={ this.state.lineColor } onChange={ this.handleColorChange } />
            </div> : null }
        </div>

    )
  }

}

export default ColorPicker;