import React, { Component } from 'react';
import Conf from '../../../utils/Conf';
import { renderMessageData } from '../../../utils/RenderUtils';
import { getRequest, postRequest } from '../../../utils/WebServicesManager';
import { getDateStr } from '../../../utils/Utils';
import {
  Button
} from 'reactstrap';
import { translate } from '../../../utils/ReactMultiLang';
import ReactTable from "react-table";
import 'react-table/react-table.css';
import diff from 'deep-diff';
import {
  Row, Col
} from 'reactstrap';

class AlertTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
        Key: 0,
        data: [],
        pages: 10,
        loading: false,
        selection: [],
        selectAll: false,
        dataParam: {},
        colList: props.colList,
        assetsUrl: props.assetsUrl,
        additionnalFilter: props.additionnalFilter ? props.additionnalFilter : []
    };
    this.fetchData = this.fetchData.bind(this);
    this.t = props.t;
    this.cols = [
      { id: 'name', accessor: d => d.name , Header: this.t('Name') }, 
        { id: "desc", Header: this.t('Type'), accessor: d => d.desc}, 
        { id: "open", Header: this.t('Status'), accessor: d => !d.open ?
        <span className='badge badge-success'>{this.t("Closed")}</span>
          :
          <span className='badge badge-danger'>{this.t("Open")}</span> }, 
        { id: "time", Header: this.t('Time'), accessor: d => d.time ? getDateStr(new Date(d.time).getTime()) : this.t('Never')}, 
        { id: "closedAt", Header: this.t('Closed'), accessor: d => d.closedAt ? getDateStr(new Date(d.closedAt).getTime()) : '' }
    ];
  }

  

  fetchData(state, instance) {
    // Whenever the table model changes, or the user sorts or changes pages, this method gets called and passed the current table model.
    // You can set the `loading` prop of the table to true to use the built-in one or show you're own loading bar if you want.
    this.setState({ loading: true });
    // Request the data however you want.  Here, we'll use our mocked service we created earlier
    state = this.state.dataParam;

    if (!state.page) {
      state.page = 0;
      state.pageSize = 10;
    }

    if (state.filtered) {
      state.filtered.join(this.state.additionnalFilter);
    } else {
      state.filtered = this.state.additionnalFilter;
    }

    this.requestData(
      state.pageSize,
      state.page,
      state.sorted,
      state.filtered
    ).then(res => {
      // Now just get the rows of data to your React Table (and update anything else like total pages or loading)
      this.setState({
        data: res.rows,
        pages: res.pages,
        loading: false,
        dataParam: state
      });
    }).catch((err) => {
      this.setState({
        data: [],
        pages: 0,
        loading: false,
        dataParam: state
      });
    });
  }

  requestData(pageSize, page, sorted, filtered) {
    return new Promise((resolve, reject) => {
      var url = Conf.BaseApi + 'alerts/getSorted';

      postRequest(url, {pageSize, page, sorted, filtered}, (data) => {
        if (data.success) {
          const res = {
            rows: data.result.rows,
            pages: data.result.pages
          };
          resolve(res);
        } else {
          reject(new Error("" + data.message));
        }
      });
    });
  }

  render() {
    const { pages, loading } = this.state;
    
    return (
      <div className="row" key={this.state.Key}>
        <div className="col-lg-12 col-md-12">
            { this.state.data ? 
            <ReactTable key={this.state.buildingId}
              filterable={true}
              defaultFilterMethod={(filter, row) =>
                String(row[filter.id]).includes(filter.value)}
              data={this.state.data}
              manual // Forces table not to paginate or sort automatically, so we can handle it server-side
              pages={pages} // Display the total number of pages
              loading={loading}
              ref={r => (this.checkboxTable = r)}
              defaultPageSize={10}
              onFetchData={this.fetchData}
              columns={this.cols}
              building={this.state.buildingId}
              className="-striped -highlight"
              onPageChange={(pageIndex) => {
                this.state.dataParam.page = pageIndex;
                this.setState({dataParam: this.state.dataParam});
              }} // Called when the page index is changed by the user
              onPageSizeChange={(pageSize, pageIndex) => {
                this.state.dataParam.pageSize = pageSize;
                this.state.dataParam.page = pageIndex;
                this.setState({dataParam: this.state.dataParam});
              }} // Called when the pageSize is changed by the user. The resolve page is also sent to maintain approximate position in the data
              onSortedChange={(newSorted, column, shiftKey) => {
                this.state.dataParam.sorted = newSorted;
                this.setState({dataParam: this.state.dataParam});
              }} // Called when a sortable column header is clicked with the column itself and if the shiftkey was held. If the column is a pivoted column, `column` will be an array of columns
              
              onFilteredChange={(filtered, column) => {
                this.state.dataParam.filtered = filtered;
                this.setState({dataParam: this.state.dataParam});
              }} // Called when a user enters a value into a filter input field or the value passed to the onFiltersChange handler by the Filter option.
              onResizedChange={(newResized, event) => {
                this.state.dataParam.resized = newResized;
                this.setState({dataParam: this.state.dataParam});
              }} // Called when a user clicks on a resizing component (the right edge of a column header)            
              page={this.state.dataParam.page ? this.state.dataParam.page : 0}
              defaultSorted={this.state.dataParam.sorted ? this.state.dataParam.sorted : []}
              defaultFiltered={this.state.dataParam.filtered ? this.state.dataParam.filtered : []}
              defaultResized={this.state.dataParam.resized ? this.state.dataParam.resized : []}
              
            /> : '' }
        </div>
      </div>
    );
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (diff(nextState, this.state) ? true : false);
  }

}

export default translate(AlertTable);