import React, { Component } from 'react';
import $ from 'jquery';
import Conf from '../../../../utils/Conf';
import { getRequest } from '../../../../utils/WebServicesManager';
import { postRequest } from '../../../../utils/WebServicesManager';
import { ModalBody } from 'reactstrap';
import SelectSensors from '../../../../components/Selects/SelectSensors/SelectSensors';
import { translate } from '../../../../utils/ReactMultiLang';


class AddRoom extends Component {
  constructor(props) {
    super(props);
    this.t = props.t;
    this.state = {
      RoomTypes: []
    }
    this.getRoomTypes();
  }

  getRoomTypes() {
    const url = Conf.BaseApi + 'roomTypes/get';

    getRequest(url, (data) => {
      if (data.success) {
        this.setState({RoomTypes: data.result});
      }
    });
  }

  addRoom(builid) {
    var url = Conf.BaseApi + 'rooms/add';

    let sensors = [];
    const inputsSensors = $("input[name=sensorsName]");
    for (let i = 0; i < inputsSensors.length; i += 1) {
      sensors.push(inputsSensors[i].value);
    }

    return postRequest(url, {
      buildId: builid,
      sensorIds: sensors,
      roomName: $("#roomName").val(),
      size: $("#roomSize").val(),
      nbPlaces: $("#roomPlaces").val(),
      floorId: $("#floorId").val() != 0 ? $("#floorId").val() : undefined,
      roomType: $("#roomType").val() != 0 ? $("#roomType").val() : undefined
    }, (data) => {
      if (data.success || data.success == true) {
        if (this.props.callback) {
          this.props.callback();
        } else {
          window.location.reload();
        }
      } else {
        $("#error-window-url").text(data.message);
      }
    });
  }
  editRoom(roomId) {
    var url = Conf.BaseApi + 'rooms/edit';

    let sensors = [];
    const inputsSensors = $("input[name=sensorsName]");
    for (let i = 0; i < inputsSensors.length; i += 1) {
      sensors.push(inputsSensors[i].value);
    }
    
    return postRequest(url, {
      roomId: roomId,
      sensorIds: sensors,
      roomName: $("#roomName").val(),
      size: $("#roomSize").val(),
      nbPlaces: $("#roomPlaces").val(),
      floorId: $("#floorId").val() != 0 ? $("#floorId").val() : undefined,
      roomType: $("#roomType").val() != 0 ? $("#roomType").val() : undefined
    }, (data) => {
      if (data.success || data.success == true) {
        if (this.props.callback) {
          this.props.callback();
        } else {
          window.location.reload();
        }
      } else {
        $("#error-window-url").text(data.message);
      }
    });
  }


  sizeChange(e) {
    const size = e.target.value;

    $("#roomPlaces").val(Math.floor(size / 10));
  }
  
  render() {
    const { building, actRoom, floor } = this.props;

    return (
    <ModalBody>
      <div className="flex-row align-items-center">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12">
              <div className="card mx-12">
                <div className="card-block p-12">
                  <div className="input-group mb-3" style={{ color: "red" }} id="error-window-url">

                  </div>
                  <div className="input-group mb-3">
                    <span className="input-group-addon"><i className="icon-usergroup"></i></span>
                    <input type="text" className="form-control" placeholder={this.t('Name')} id="roomName" defaultValue={actRoom !== undefined ? actRoom.name : '' }/>
                  </div>
                  <div className="input-group mb-3">
                    <span className="input-group-addon"><i className="icon-usergroup"></i></span>
                    <input type="text" className="form-control"
                    onChange={(e) => this.sizeChange(e)}
                    placeholder={`${this.t('Size')}(m²)`} id="roomSize" defaultValue={actRoom !== undefined ? actRoom.size : '' }/>
                  </div>
                  <div className="input-group mb-3">
                    <span className="input-group-addon"><i className="icon-usergroup"></i></span>
                    <input type="text" className="form-control" placeholder={this.t('Places')} id="roomPlaces" defaultValue={actRoom !== undefined ? actRoom.nbPlaces : '' }/>
                  </div>
                    <div className="form-group mb-3">
                      <label htmlFor="sensorType">{this.t('Floor')}</label>
                      <select className="form-control" id="floorId" defaultValue={actRoom ? actRoom.floor : floor ? floor._id : '' }>
                        {this.renderFloors(building.floors)}
                      </select>
                    </div>
                    <div className="form-group mb-3">
                      <label htmlFor="roomType">{this.t('Type')}</label>
                      <select className="form-control" id="roomType" defaultValue={actRoom ? actRoom.roomType : '' }>
                        {this.renderRoomType(this.state.RoomTypes)}
                      </select>
                    </div>
                <label htmlFor="userGroup">{this.t('Sensors')}</label>
                      <SelectSensors entity='devices' group={building.group}
                        values={actRoom !== undefined && actRoom !== [] && actRoom.sensors !== undefined  ? actRoom.sensors.map((sens) => sens._id) : ""}
                        deviceRoute='true'/>
                  <button type="button" className="btn btn-block btn-success" 
                    onClick={actRoom !== undefined ? this.editRoom.bind(this, actRoom._id) : this.addRoom.bind(this, building._id)}>
                      {actRoom !== undefined ? this.t('Update') : this.t('Add')}
                    </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ModalBody>
    )
  }


  renderRoomType(roomTypes) {
    var toRender = [];
    
    for (var i = 0; i < roomTypes.length; i++) {
            toRender.push(
                <option key={i} value={roomTypes[i]._id}>{roomTypes[i].name}</option>
            );
    }
    return toRender;
  }

  renderFloors(floors) {
    var toRender = [];
    
    toRender.push(
      <option key={999} value={0}>{this.t('Infra.Room.NoFloor')}</option>
    );
    for (var i = 0; i < floors.length; i++) {
            toRender.push(
                <option key={i} value={floors[i]._id}>{floors[i].name}({floors[i].number})</option>
            );
    }
    return toRender;
  }
}

export default translate(AddRoom);