import * as SRD from "storm-react-diagrams";
import OperatorNodeWidget from "./OperatorNodeWidget";
import OperatorNodeModel from "./OperatorNodeModel";
import * as React from "react";

export class OperatorNodeFactory extends SRD.AbstractNodeFactory {
	constructor() {
		super("operator");
	}

	generateReactWidget(diagramEngine, node) {
		return <OperatorNodeWidget node={node} diagramEngine={diagramEngine}/>;
	}

	getNewInstance() {
		return new OperatorNodeModel();
	}
}

export default OperatorNodeFactory;