import React, { Component } from 'react';
import { GeoSearchControl, OpenStreetMapProvider } from 'leaflet-geosearch';
import { MapControl, withLeaflet } from 'react-leaflet';
import L from 'leaflet';

class Search extends MapControl {

    createLeafletElement() {
        const MapInfo = L.Control.extend({
            onAdd: (map) => {
              this.panelDiv = (<div className="leaflet-control-geosearch bar"></div>);
              return this.panelDiv;
            }
          });
          return new MapInfo({ position: 'center' });
      }
}

export default withLeaflet(Search);