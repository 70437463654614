import React, { Component } from 'react';
import Conf from '../../../utils/Conf';
import { getRequest, postRequest } from '../../../utils/WebServicesManager';
import { getDateStr } from '../../../utils/Utils';
import {
  Button
} from 'reactstrap';
import { translate } from '../../../utils/ReactMultiLang';
import ReactTable from "react-table";
import checkboxHOC from "react-table/lib/hoc/selectTable";
import 'react-table/react-table.css';
import diff from 'deep-diff';
import { type } from 'os';

class SensorSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      pages: 10,
      loading: false,
      selection: [],
      selectAll: false,
      types: props.types
    };
    this.fetchData = this.fetchData.bind(this);
    this.CheckboxTable = checkboxHOC(ReactTable);
    this.t = props.t;
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.types !== this.props.types) {
      this.setState({types: nextProps.types});
    }
  }
  
  requestData(pageSize, page, sorted, filtered) {
    return new Promise((resolve, reject) => {
      var url = Conf.BaseApi + 'devices/getSorted';

      postRequest(url, {pageSize, page, sorted, filtered}, (data) => {
        if (data.success) {
          const res = {
            rows: data.result.rows,
            pages: data.result.pages
          };
          resolve(res);
        } else {
          reject(new Error(data.message))
        }
      });
    });
  }

  fetchData(state, instance) {
    // Whenever the table model changes, or the user sorts or changes pages, this method gets called and passed the current table model.
    // You can set the `loading` prop of the table to true to use the built-in one or show you're own loading bar if you want.
    this.setState({ loading: true });
    if (this.state.types) {
      for (let i = 0; i < state.filtered.length; i += 1) {
        if (state.filtered[i].id === "type") {
          state.filtered.splice(i, 1);
          break;
        }
      }
      state.filtered.push({id: "type", value: this.state.types.join('&&')})
    }

    // Request the data however you want.  Here, we'll use our mocked service we created earlier
    this.requestData(
      state.pageSize,
      state.page,
      state.sorted,
      state.filtered
    ).then(res => {
      // Now just get the rows of data to your React Table (and update anything else like total pages or loading)
      this.setState({
        data: res.rows,
        pages: res.pages,
        loading: false
      });
    });
  }

  render() {
    const { data, selectAll, pages, loading } = this.state;
    const checkboxProps = {
      selectAll: selectAll,
      isSelected: this.isSelected.bind(this),
      toggleSelection: this.toggleSelection.bind(this),
      toggleAll: this.toggleAll.bind(this),
      selectType: "checkbox",
      getTrProps: (s, r) => {
        // someone asked for an example of a background color change
        // here it is...
        if (r) {
          const selected = this.isSelected(r.original._id);
          return {
            style: {
              backgroundColor: selected ? "#0CF2B3" : "inherit"
            }
          };
        }
        return { style: {backgroundColor: "inherit"} };
      }
    };
    const columns = this.getColumns();
    
    return (
      <div className="row">
        <div className="col-lg-12 col-md-12">
          <div className="card">
            <div className="card-header">
              <div className="row">
              </div>
            </div>
            <div className="card-block">
            <this.CheckboxTable
              filterable={true}
              defaultFilterMethod={(filter, row) =>
                String(row[filter.id]).includes(filter.value)}
              ref={r => (this.checkboxTable = r)}
              data={data}
              manual // Forces table not to paginate or sort automatically, so we can handle it server-side
              pages={pages} // Display the total number of pages
              loading={loading}
              onFetchData={this.fetchData}
              columns={columns}
              defaultPageSize={10}
              className="-striped -highlight"
              {...checkboxProps}
            /> 
            </div>
            <br/>
            <Button onClick={this.selectSensor}>{this.t('Select')}</Button>
          </div>
        </div>
      </div>
    );
  }

  selectSensor = () => {
    this.props.callbackAddSensor(this.state.selection);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (diff(nextState, this.state) ? true : false);
  }


  /** React-table */
  
  getColumns() {
    return [
      { id: 'name', accessor: d => d.name, Header: this.t('Name') },
      { id: "usage", accessor: d => d.usage ? d.usage : '', Header: this.t('Usage') }, 
      { Header: this.t('Groups'), id: "group", accessor: d => d.group ? d.group.name : '' }, 
      { Header: this.t('Tags'), id: "groups", accessor: d => d.groups ? d.groups.join(', ') : '' }, 
      { id: "model", accessor: d => d.model ? d.model : '', Header: this.t('DeviceModel') }, 
      { id: "DevEUI", accessor: d => d.DevEUI ? d.DevEUI : '', Header: this.t('DevEUI') }, 
      { id: "lastSeen", 
        maxWidth: 150,
        accessor: d => d.lastSeen ? getDateStr(new Date(d.lastSeen).getTime()) : '',
        Header: this.t('Message.Time') 
      }
    ];
  }

  toggleSelection(key, shift, row) {
    /*
      Implementation of how to manage the selection state is up to the developer.
      This implementation uses an array stored in the component state.
      Other implementations could use object keys, a Javascript Set, or Redux... etc.
    */
    // start off with the existing state
    let selection = [];
    selection.push(row);

    // update the state
    this.setState({ selection });
  };

  toggleAll() {
  };

  isSelected(key) {
    /*
      Instead of passing our external selection state we provide an 'isSelected'
      callback and detect the selection state ourselves. This allows any implementation
      for selection (either an array, object keys, or even a Javascript Set object).
    */
    return this.state.selection.some(elem => elem._id === key);
  };

}

export default translate(SensorSelector);