import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';

class Infrastructure extends Component {
  render() {
    const { infra } = this.props;
    return (
      <li>
      <i className='icon-home bg-info'></i>
      <div className="desc sensorListItem">
        <div className="title">{infra.name}</div>
        <small>{infra.comment}
        {`${infra.address.street} ${infra.address.number}, ${infra.address.postcode} ${infra.address.city}`}
        </small>
      </div>
      </li>
    );
  }
}

export default Infrastructure;
