import React from 'react';
import PropTypes from 'prop-types';

class ReactWebsocket extends React.Component {

    constructor(props) {
      super(props);
      this.state = {
        attempts: 1
      };
      this.ws = new WebSocket(this.props.url, this.props.protocol);
      this.sendMessage = this.sendMessage.bind(this);
      this.setupWebsocket = this.setupWebsocket.bind(this);
    }

    logging(logline) {
      if (this.props.debug === true) {
          console.log(logline);
      }
    }

    generateInterval (k) {
        if(this.props.reconnectIntervalInMilliSeconds > 0) {
            return this.props.reconnectIntervalInMilliSeconds;
        }
        return Math.min(30, (Math.pow(2, k) - 1)) * 1000;
    }

    setupWebsocket() {

      this.ws.onopen = () => {
        this.logging('Websocket connected at : ' + this.ws.url);
        if (typeof this.props.onOpen === 'function') this.props.onOpen();
      };

      this.ws.onerror = (error) => {
        this.logging('Websocket error : ' + error.message);
      };

      this.ws.onmessage = (evt) => {
        this.props.onMessage(evt.data);
      };

      this.shouldReconnect = this.props.reconnect;
      this.ws.onclose = () => {
        this.logging('Websocket disconnected');
        if (typeof this.props.onClose === 'function') this.props.onClose();
        if (this.shouldReconnect) {
          let time = this.generateInterval(this.state.attempts);
          this.timeoutID = setTimeout(() => {
            this.setState({attempts: this.state.attempts+1});
            this.ws = new WebSocket(this.props.url, this.props.protocol);
            this.setupWebsocket();
          }, time);
        }
      }
    }

    componentDidMount() {
      console.log('componentDidMount');
      this.setupWebsocket();
    }

    componentWillUnmount() {
      console.log('componentWillUnmount');
      this.shouldReconnect = false;
      clearTimeout(this.timeoutID);
      let websocket = this.ws;
      websocket.close();
    }

    sendMessage(message){
      let websocket = this.ws;
      websocket.send(message);
    }

    render() {
      return (
        <div></div>
      );
    }
}

ReactWebsocket.defaultProps = {
    debug: false,
    reconnect: true
};

ReactWebsocket.propTypes = {
    url: PropTypes.string.isRequired,
    onMessage: PropTypes.func.isRequired,
    onOpen: PropTypes.func,
    onClose: PropTypes.func,
    debug: PropTypes.bool,
    reconnect: PropTypes.bool,
    protocol: PropTypes.string,
    reconnectIntervalInMilliSeconds : PropTypes.number
};

export default ReactWebsocket;
