import React, { Component } from 'react';
import $ from 'jquery';
import Conf from '../../../utils/Conf';
import { getRequest } from '../../../utils/WebServicesManager';
import ColorPicker from '../ColorPicker';
import CreateLevel from '../CreatComponent/CreateLevel';
import SelectDataSet from './SelectDataSet';
import { translate } from '../../../utils/ReactMultiLang';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import { Input, Label, Col, Row } from 'reactstrap';
import TimeField from 'react-simple-timefield';


class EditComponent extends Component {
  constructor(props) {
    super(props);
    console.log(props.component.timeStart, props.component.timeEnd);
    this.state = {
      Sensors: [],
      FieldList: [],
      lineColor: '#f00',
      from: props.component && props.component.from ? moment(props.component.from) : moment(),
      to: props.component && props.component.to ? moment(props.component.to) : moment(),
      StaticDate: props.component && props.component.from ? true : false,
      dataSets: props.component ? props.component.dataSets : undefined,
      sensor: undefined,
      useMultipleColors: props.component.colors && props.component.colors.length > 0 ? true : false,
      useTime: props.component && props.component.timeStart !== undefined && (props.component.timeStart !== 0 || props.component.timeEnd !== 0) ? true : false,
      timeStart: props.component && props.component.timeStart ?
        (parseInt(props.component.timeStart / 100, 10) < 10 ? '0' : '') + parseInt(props.component.timeStart / 100, 10) + ':' +
        (props.component.timeStart % 100 < 10 ? '0' : '') + props.component.timeStart % 100 : '00:00',
      timeEnd: props.component && props.component.timeEnd ?
        (parseInt(props.component.timeEnd / 100, 10) < 10 ? '0' : '') + parseInt(props.component.timeEnd / 100, 10) + ':' +
        (props.component.timeEnd % 100 < 10 ? '0' : '') + props.component.timeEnd % 100 : '00:00',
      mColors: props.component && props.component.colors && props.component.colors.length > 0 ? props.component.colors : [],
      showMin: props.component ? props.component.showMin || false : false,
      showMax: props.component ? props.component.showMax || false : false,
    };
    console.log(this.state.timeStart, this.state.timeEnd);
    this.t = props.t;
    this.getFieldList(props.component);
  }


  useStatic() {
    this.setState({ StaticDate: $("#useStatic").is(':checked') });
  }

  useTimeChange() {
    this.setState({ useTime: $("#useTime").is(':checked') });
  }


  handleChangeFrom(from) {
    if (this.state.to) {
      this.setState({ from });
    } else {
      this.setState({ from, to: from });
    }
  }
  handleChangeTo(to) {
    this.setState({ to });
  }


  componentWillReceiveProps(nextProps) {
    const { component } = nextProps;

    if (component !== this.props.component) {
      this.getFieldList(component);
      this.setState({
        from: component && component.from ? moment(component.from) : moment(),
        to: component && component.to ? moment(component.to) : moment(),
        StaticDate: component && component.from ? true : false
      });
    }
  }

  getFieldList(component) {
    if (this.state.FieldList.length !== 0 || (!component || !component.dataSets))
      return;
    var url = Conf.BaseApi + `helpers/fields/${component.dataSets[0].sensor}`;
    getRequest(url, (result) => {
      if (result && result.success) {
        this.setState({ FieldList: result.result });
      }
    });
    var deviceurl = Conf.BaseApi + `devices/get/${component.dataSets[0].sensor}`;
    return getRequest(deviceurl, (result) => {
      if (result && result.success) {
        this.setState({ sensor: result.result });
      }
    });
  }

  updateColors(i, min, max) {
    let colors = this.state.mColors;
    if (colors.length > i) {
      if (min)
        colors[i].min = min;
      else
        colors[i].max = max;
    }
    this.setState({ mColors: colors });
  }

  addColor() {
    let colors = this.state.mColors;
    colors.push({ min: 0, max: 0, strokeStyle: "#ff0000" });
    this.setState({ mColors: colors });
  }

  rmColor(i) {
    let colors = this.state.mColors;
    colors.splice(i, 1);
    this.setState({ mColors: colors });
  }

  handleColorsChange(i, color) {
    let colors = this.state.mColors;
    if (colors.length > i) {
      colors[i].strokeStyle = color;
    }
    this.setState({ mColors: colors });
  };
  handleColorChange = (color) => {
    this.setState({ lineColor: color });
  };


  renderDeviceFields(fields, dataSet) {
    var toRender = [];
    for (var i = 0; i < fields.length; i++) {
      if (dataSet && fields[i] === dataSet.paramName) {
        toRender.push(
          <option value={fields[i]} selected="selected">{this.t(`SensorData.${fields[i]}`)}</option>
        );
      } else {
        toRender.push(
          <option value={fields[i]}>{this.t(`SensorData.${fields[i]}`)}</option>
        );
      }
    }
    return toRender;
  }

  updateComponent(component, callback) {
    let ids = [];
    const inputs = $("input[name=dataSetsSelect]");
    for (let i = 0; i < inputs.length; i += 1) {
      ids.push(inputs[i].value);
    }
    if (component.dataSets) {
      component.dataSets = this.state.dataSets;
      component.name = $("#editCpName").val();
      component.min = $('#createMin').val();
      component.max = $('#createMax').val();
      component.level = $('#createLevel').val();
      component.barMode = $('#createBarMode').val();
      component.granularity = $('#granularity').val();
      if (this.state.useMultipleColors) component.colors = this.state.mColors;
      component.printBreaker = $("#breakLine").is(':checked');
      if ($("#editCpLabel").val())
        component.dataSets[0].label = $("#editCpLabel").val();
      if ($("#createCpDataName").val())
        component.dataSets[0].paramName = $("#createCpDataName").val();
      if (this.state.StaticDate) {
        component.from = this.state.from.hour(0).minute(0).valueOf();
        component.to = this.state.to.hour(23).minute(59).valueOf();
      } else {
        component.days = $('#createCpDays').val();
        component.from = null;
        component.to = null;
      }
      // Min max line for line graph
      component.showMin = false;
      component.showMax = false;
      if ($("#showMin").is(':checked')) {
        component.showMin = true;
      }
      if ($("#showMax").is(':checked')) {
        component.showMax = true;
      }
      // End Min max line
      if (this.state.useTime) {
        const froms = this.state.timeStart.split(':');
        const tos = this.state.timeEnd.split(':');
        component.timeStart = parseInt(froms[0]) * 100 + parseInt(froms[1]);
        component.timeEnd = parseInt(tos[0]) * 100 + parseInt(tos[1]);
      }
      component.color = this.state.lineColor;
      if (component.type === "INT-AVERAGE") {
        component.dataSets[0].color = this.state.lineColor;
      }
    }

    callback(component);
  }

  editEnergy(component, callback) {
    component.name = $("#createCpName").val();
    component.type = "ENERGY";

    component.dataSets = [{
      label: "Combust",
      value: $("#createCompustible").val()
    }, {
      label: "Quantity",
      value: $("#createQty").val()
    }, {
      label: "CO2",
      value: $("#createCO2").val()
    }];
    callback(component);
  }


  render() {
    const { component, callback } = this.props;
    let toRender = {};
    switch (component.type) {
      case "TITLE":
        toRender = this.getTitle(component, callback);
        break;
      case "GAUGE":
        toRender = this.getGauge(component, callback);
        break;
      case "BOOLEAN":
        toRender = this.getOpenClose(component, callback);
        break;
      case "BOOL-LEVEL":
        toRender = <CreateLevel callback={callback} component={component} />
        break;
      case "ALERTS":
        toRender = this.getAlert(component, callback);
        break;
      case "ENERGY":
        toRender = this.getEnergy(component, callback);
        break;
      default:
        toRender = this.getAverageLine(component, callback);
        break;

    }
    return (
      <div className="flex-row align-items-center">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12">
              <div className="card mx-12">
                {toRender}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  getAlert(component, callback) {
    return (
      <div className="card-block p-12">
        <div className="input-group mb-3" style={{ color: "red" }} id="error-window">

        </div>
        <div className="input-group mb-3">
          <span className="input-group-addon">{this.t('Numbers')}</span>
          <input type="number" className="form-control" id="granularity" defaultValue={component.granularity} />
        </div>

        <div className="input-group mb-1">
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input className="form-check-input" type="checkbox" id="useStatic" value="true" onChange={() => this.useStatic()} defaultChecked={this.state.StaticDate} />
              {this.t('Component.UseStatic')}
            </label>
          </div>
        </div>
        {
          this.state.StaticDate ?
            <div className="input-group mb-3">
              <div style={{ display: 'inline-block', marginLeft: '25px', zIndex: '9000' }}>
                {this.t('Begin')}: <DatePicker
                  selected={this.state.from}
                  onChange={this.handleChangeFrom.bind(this)}
                  dateFormat="DD MMMM YYYY"
                />
              </div>
              <div style={{ display: 'inline-block', marginLeft: '25px', zIndex: '9000' }}>
                {this.t('End')}: <DatePicker
                  selected={this.state.to}
                  onChange={this.handleChangeTo.bind(this)}
                  dateFormat="DD MMMM YYYY"
                />
              </div>
            </div>
            :
            <div className="input-group mb-3">
              <span className="input-group-addon"><i className="icon-clock"></i></span>
              <input type="number" className="form-control" placeholder={this.t('Component.DaysNum')} defaultValue={component.days} id="createCpDays" />
            </div>
        }
        <button type="button" className="btn btn-block btn-success" onClick={this.updateComponent.bind(this, component, callback)}>{this.t('Update')}</button>
      </div>
    );
  }

  getTitle(component, callback) {
    return (
      <div className="card-block p-12">
        <div className="input-group mb-3" style={{ color: "red" }} id="error-window">

        </div>
        <div className="input-group mb-3">
          <span className="input-group-addon"><i className="icon-user"></i></span>
          <input type="text" className="form-control" placeholder={this.t('Component.Name')} id="editCpName" defaultValue={component.name} />
        </div>
        <div className="input-group mb-3">
          <span className="input-group-addon"><i className="icon-clock"></i></span>
          <input type="number" className="form-control" placeholder={this.t('Component.DaysNum')} defaultValue={component.days} id="createCpDays" />
        </div>
        <div className="input-group mb-1">
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input className="form-check-input" type="checkbox" id="breakLine" defaultChecked={component.printBreaker} />
              {this.t('Component.BreakLine')}
            </label>
          </div>
        </div>
        <div className="form-group mb-3">
          <label htmlFor="createCpColor">{this.t('Color')}:</label>
          <ColorPicker callback={this.handleColorChange} color={component.color} />
        </div>
        <br />
        <button type="button" className="btn btn-block btn-success" onClick={this.updateComponent.bind(this, component, callback)}>{this.t('Update')}</button>
      </div>
    );
  }

  getOpenClose(component, callback) {
    return (
      <div className="card-block p-12">
        <div className="input-group mb-3" style={{ color: "red" }} id="error-window">
        </div>
        <div className="input-group mb-3">
          <span className="input-group-addon"><i className="icon-user"></i></span>
          <input type="text" className="form-control" placeholder={this.t('Component.Name')} id="editCpName" defaultValue={component.name} />
        </div>
        <div className="form-group mb-3">
          <div><label htmlFor="createCpDataName">{this.t('Component.DataToUse')}:</label></div>

          <select className="form-control" id="createCpDataName">
            {component ? this.renderDeviceFields(this.state.FieldList, component.dataSets[0]) : ''}
          </select>
        </div>
        <br />
        <button type="button" className="btn btn-block btn-success" onClick={this.updateComponent.bind(this, component, callback)}>{this.t('Add')}</button>
      </div>
    );
  }

  getGauge(component, callback) {
    return (
      <div className="card-block p-12">
        {this.state.sensor ? <div><b>{this.state.sensor.name}</b> ({this.state.sensor.usage})</div> : ''}
        <div className="input-group mb-3" style={{ color: "red" }} id="error-window">

        </div>
        <div className="input-group mb-3">
          <span className="input-group-addon"><i className="icon-user"></i></span>
          <input type="text" className="form-control" placeholder={this.t('Component.Name')} id="editCpName" defaultValue={component.name} />
        </div>
        <div className="input-group mb-3">
          <span className="input-group-addon"><i className="icon-clock"></i></span>
          <input type="number" className="form-control" placeholder={this.t('Component.Min')} defaultValue={0} id="createMin" defaultValue={component.min} />
        </div>
        <div className="input-group mb-3">
          <span className="input-group-addon"><i className="icon-clock"></i></span>
          <input type="number" className="form-control" placeholder={this.t('Component.Max')} defaultValue={100} id="createMax" defaultValue={component.max} />
        </div>
        <div className="input-group mb-3">
          {this.t('Component.UseMultipleColors')}
          <Label className="switch switch-sm switch-text switch-success switch-label float-right mb-0" style={{ marginLeft: '5px' }}>
            <Input type="checkbox" className="switch-input" onChange={(e) => this.setState({ useMultipleColors: e.target.checked })} />
            <span className="switch-label" ></span>
            <span className="switch-handle"></span>
          </Label>
        </div>
        {
          this.state.useMultipleColors ?
            <table>
              <tbody>
                {this.state.mColors.length > 0 ? this.state.mColors.map((c, i) =>
                  <tr>
                    <td>
                      {this.t('Min')}{' '}<input type="number" className="form-control small-control" placeholder={this.t('Component.Min')} defaultValue={c.min} min={$('#createMin').val()} id={`createMin${i}`} onChange={(e) => this.updateColors(i, e.target.value)} />
                    </td>
                    <td>
                      {this.t('Max')}{' '}<input type="number" className="form-control small-control" placeholder={this.t('Component.Min')} defaultValue={c.max} max={$('#createMax').val()} id={`createMax${i}`} onChange={(e) => this.updateColors(i, undefined, e.target.value)} />
                    </td>
                    <td>
                      <label htmlFor="createCpColor">{this.t('Color')}:</label>
                      <ColorPicker callback={this.handleColorsChange.bind(this, i)} color={c.strokeStyle} />
                    </td>
                    <td>
                      <button type="button" className="btn btn-block btn-danger" onClick={() => this.rmColor(i)}>{this.t('Remove')}</button>
                    </td>
                  </tr>) : ''}
                <button type="button" className="btn btn-block btn-warning" onClick={() => this.addColor()}>{this.t('AddColor')}</button>
              </tbody>
            </table>
            :
            <div className="form-group mb-3">
              <label htmlFor="createCpColor">{this.t('Color')}:</label>
              <ColorPicker callback={this.handleColorChange.bind(this)} color={component.color} />
            </div>
        }
        <div className="form-group mb-3">
          <div><label htmlFor="createCpDataName">{this.t('Component.DataToUse')}:</label></div>

          <select className="form-control" id="createCpDataName">
            {component ? this.renderDeviceFields(this.state.FieldList, component.dataSets[0]) : ''}
          </select>
        </div>
        <button type="button" className="btn btn-block btn-success" onClick={this.updateComponent.bind(this, component, callback)}>{this.t('Update')}</button>
      </div>
    );
  }

  getAverageLine(component, callback) {
    return (
      <div className="card-block p-12">
        {component.type === "INT-AVERAGE" && this.state.sensor ?
          <div><b>{this.state.sensor.name}</b> ({this.state.sensor.usage})</div> : ''}
        <div className="input-group mb-3" style={{ color: "red" }} id="error-window">

        </div>
        {component.type !== "INT-AVERAGE" ?
          <div className="input-group mb-3">
            <span className="input-group-addon"><i className="icon-user"></i></span>
            <input type="text" className="form-control" placeholder={this.t('Component.Name')} id="editCpName" defaultValue={component.name} />
          </div> : ''}
        {component.type === "INT-AVERAGE" ?
          <div className="input-group mb-3">
            <span className="input-group-addon"><i className="icon-user"></i></span>
            <input type="text" className="form-control" placeholder={this.t('Component.DataSetName')} id="editCpLabel" defaultValue={component.dataSets ? component.dataSets[0].label : ''} />
          </div> : ''}
        {component.type === "INT-AVERAGE" ?
          <div className="form-group mb-3">
            <label htmlFor="createCpColor">{this.t('Color')}:</label>
            <ColorPicker callback={this.handleColorChange} color={component.color} />
          </div>
          : ''}

        {component.type === "INT-AVERAGE" ?
          <div className="form-group mb-3">
            <div><label htmlFor="createCpDataName">{this.t('Component.DataToUse')}:</label></div>

            <select className="form-control" id="createCpDataName">
              {component ? this.renderDeviceFields(this.state.FieldList, component.dataSets[0]) : ''}
            </select>
          </div>
          : ''}
        {component.type === "INT-AVERAGE" ?

          <div className="form-group mb-3">
            <div><label htmlFor="createBarMode">{this.t('Component.BarMode')}:</label></div>

            <select className="form-control" id="createBarMode" defaultValue={component.barMode}>
              <option value={1}>{this.t('Component.ExactValue')}</option>
              <option value={0}>{this.t('Component.Average')}</option>
              <option value={2}>{this.t('Component.Difference')}</option>
            </select>
          </div>
          : ''}
        {component.type === "BAR-CHART" ?
          <div className="form-group mb-3">
            <div><label htmlFor="createBarMode">{this.t('Component.BarMode')}:</label></div>

            <select className="form-control" id="createBarMode" defaultValue={component.barMode}>
              <option value={1}>{this.t('Component.Difference')}</option>
              <option value={2}>{this.t('Component.Average')}</option>
            </select>
          </div>
          : ''}
        {component.type === "BAR-CHART" ?
          <div className="form-group mb-3">
            <div><label htmlFor="createLevel">{this.t('Component.Level')}:</label></div>

            <select className="form-control" id="createLevel" defaultValue={component.level}>
              <option value={3}>{this.t('Hourly')}</option>
              <option value={0}>{this.t('Daily')}</option>
              <option value={1}>{this.t('Weekly')}</option>
              <option value={2}>{this.t('Monthly')}</option>
            </select>
          </div>
          : ''}

        {component.type === "INT-CHART" ?
          <div className="input-group mb-3">
            <span className="input-group-addon">{this.t('Granularity')}(minutes)</span>
            <input type="number" className="form-control" id="granularity" defaultValue={component.granularity} />
          </div>
          : ''}

        <div className="input-group mb-1">
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input className="form-check-input" type="checkbox" id="useStatic" value="true" onChange={() => this.useStatic()} defaultChecked={this.state.StaticDate} />
              {this.t('Component.UseStatic')}
            </label>
          </div>
        </div>
        {
          this.state.StaticDate ?
            <div className="input-group mb-3">
              <div style={{ display: 'inline-block', marginLeft: '25px', zIndex: '9000' }}>
                {this.t('Begin')}: <DatePicker
                  selected={this.state.from}
                  onChange={this.handleChangeFrom.bind(this)}
                  dateFormat="DD MMMM YYYY"
                />
              </div>
              <div style={{ display: 'inline-block', marginLeft: '25px', zIndex: '9000' }}>
                {this.t('End')}: <DatePicker
                  selected={this.state.to}
                  onChange={this.handleChangeTo.bind(this)}
                  dateFormat="DD MMMM YYYY"
                />
              </div>
            </div>
            :
            <div className="input-group mb-3">
              <span className="input-group-addon"><i className="icon-clock"></i></span>
              <input type="number" className="form-control" placeholder={this.t('Component.DaysNum')} defaultValue={component.days} id="createCpDays" />
            </div>
        }
        {component.type === "INT-CHART" && this.state.StaticDate ?

          <div className="input-group">
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input className="form-check-input" type="checkbox" id="useTime" value="true" onChange={() => this.useTimeChange()}
                  defaultChecked={this.state.useTime} />
                {" "}{this.t('Component.UseTime')}
              </label>
            </div>
          </div>
          : ''}
        {component.type === "INT-CHART" && this.state.useTime ?
          <Row>
            <Col md='6'>
              {this.t('From')}: {' '}
              <TimeField
                style={{
                  border: '1px solid #666',
                  fontSize: 18,
                  width: 200,
                  padding: '5px 8px',
                  color: '#333',
                  borderRadius: 3
                }}
                value={this.state.timeStart}
                onChange={(event, value) => { this.setState({ timeStart: value }) }}
              />
            </Col>
            <Col md='6'>
              {this.t('To')}: {' '}
              <TimeField

                style={{
                  border: '1px solid #666',
                  fontSize: 18,
                  width: 200,
                  padding: '5px 8px',
                  color: '#333',
                  borderRadius: 3
                }}
                value={this.state.timeEnd}
                onChange={(event, value) => { this.setState({ timeEnd: value }) }}
              />
            </Col>
          </Row>
          : ''}
        {component.type === "INT-CHART" && this.state.useTime ? <br /> : ''}
        {component.type === "INT-CHART" ?

          <Row>
            <Col md='6'>
              <div className="input-group mb-3">
                <div className="form-check form-check-inline">
                  <label className="form-check-label">
                    <input className="form-check-input" type="checkbox" id="showMin" value="true" onChange={() => this.setState({ showMin: $("#showMin").is(':checked') })}
                      defaultChecked={this.state.showMin} />
                    {" "}{this.t('Component.showMin')}
                  </label>
                </div>
              </div>
              {
                this.state.showMin ?

                  <div className="input-group mb-3">
                    <span className="input-group-addon"><i className="icon-clock"></i></span>
                    <input type="number" className="form-control" placeholder={this.t('Component.Min')} defaultValue={0} id="createMin" defaultValue={component.min || 0} />
                  </div>
                  : ''
              }
            </Col>
            <Col md='6'>
              <div className="input-group mb-3">
                <div className="form-check form-check-inline">
                  <label className="form-check-label">
                    <input className="form-check-input" type="checkbox" id="showMax" value="true" onChange={() => this.setState({ showMax: $("#showMax").is(':checked') })}
                      defaultChecked={this.state.showMax} />
                    {" "}{this.t('Component.showMax')}
                  </label>
                </div>
              </div>
              {
                this.state.showMax ?

                  <div className="input-group mb-3">
                    <span className="input-group-addon"><i className="icon-clock"></i></span>
                    <input type="number" className="form-control" placeholder={this.t('Component.Max')} defaultValue={100} id="createMax" defaultValue={component.max || 30} />
                  </div>
                  : ''
              }
            </Col>
          </Row>
          : ''}
        {component.type != "INT-AVERAGE" ?
          <div>{this.t('Component.Datasets')} :
            <SelectDataSet dataSets={this.state.dataSets}
              rmDataSet={this.rmDataSet.bind(this)}
              addDataSet={this.addDataSet.bind(this)}
              editDataSet={this.editDataSet.bind(this)}
              type={component.type} /></div> : ''}
        <br />
        <button type="button" className="btn btn-block btn-success" onClick={this.updateComponent.bind(this, component, callback)}>{this.t('Update')}</button>
      </div>
    );
  }

  getEnergy(component, callback) {
    return (
      <div className="card-block p-12">
        <div className="input-group mb-3" style={{ color: "red" }} id="error-window">
        </div>
        <div className="input-group mb-3">
          <span className="input-group-addon"><i className="icon-user"></i></span>
          <input type="text" className="form-control" placeholder={this.t('Component.Name')} id="createCpName" defaultValue={component.name} />
        </div>

        <div className="input-group mb-3">
          <span className="input-group-addon">Combustible</span>
          <input type="text" className="form-control" id="createCompustible" defaultValue={component.dataSets[0].value} />
        </div>
        <div className="input-group mb-3">
          <span className="input-group-addon">Quantité</span>
          <input type="text" className="form-control" id="createQty" defaultValue={component.dataSets[1].value} />
        </div>
        <div className="input-group mb-3">
          <span className="input-group-addon">Emission CO2</span>
          <input type="text" className="form-control" id="createCO2" defaultValue={component.dataSets[2].value} />
        </div>
        <br />
        <button type="button" className="btn btn-block btn-success" onClick={this.editEnergy.bind(this, component, callback)}>{this.t('Edit')}</button>
      </div>
    );
  }

  rmDataSet(id) {
    if (this.state.dataSets) {
      for (let i = 0; i < this.state.dataSets.length; i += 1) {
        if (this.state.dataSets[i]._id === id) {
          const tmpDatas = this.state.dataSets;
          tmpDatas.splice(i, 1);
          return this.setState({ dataSets: tmpDatas });
        }
      }
    }
  }
  addDataSet(dataSet) {
    const tmpDatas = this.state.dataSets;
    tmpDatas.push(dataSet);
    this.setState({ dataSets: tmpDatas });
  }

  editDataSet(dataSet) {
    if (this.state.dataSets) {
      for (let i = 0; i < this.state.dataSets.length; i += 1) {
        if (this.state.dataSets[i]._id === dataSet._id) {
          const tmpDatas = this.state.dataSets;
          tmpDatas[i] = dataSet;
          return this.setState({ dataSets: tmpDatas });
        }
      }
    }
  }
}

export default translate(EditComponent);