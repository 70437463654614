import Conf from './Conf'

export function Max(arr) {
    if (arr.length === 0) {
        return -1;
    }

    var max = arr[0];

    for (var i = 1; i < arr.length; i++) {
        if (arr[i] > max) {
            max = arr[i];
        }
    }

    return max;
}

export function Min(arr) {
    if (arr.length === 0) {
        return -1;
    }

    var min = arr[0];

    for (var i = 1; i < arr.length; i++) {
        if (arr[i] < min) {
            min = arr[i];
        }
    }

    return min;
}

export function SortPredictions(predictions, multi, add) {
    const stat = [0, 0, 0, 0];
    for (let i = 0; i < predictions.values.length; i++) {
        const averageDist = ((predictions.highAverage[i].value - predictions.predictions[i].value) - 5) / 4 * multi + add;
        const dist = predictions.values[i].value - predictions.predictions[i].value;
        if (dist > 0 && dist > averageDist)
            stat[2] += 1;
        else if (dist < 0 && dist < -averageDist)
            stat[3] += 1;
        else if (dist > 0)
            stat[0] += 1;
        else
            stat[1] += 1;
    }
    return stat;
}

export function Accuracy(stat) {
    return ((stat[0] + stat[1]) / (stat[0] + stat[1] + stat[2] + stat[3])) * 10;
}

export function Precision(stat) {
    return (stat[0] / (stat[0] + stat[2])) * 10;
}
export function Recall(stat) {
    return (stat[0] / (stat[0] + stat[3])) * 10;
}
export function F1Score(precision, recall) {
    return (2 * (precision * recall) / (recall + precision));
}
export function Confidence(predictions, multi, add) {
    let confidence = 0;
    if (predictions.values.length > 10) {
        for (let i = predictions.values.length - 10; i < predictions.values.length; i++) {
            const averageDist = ((predictions.highAverage[i].value - predictions.predictions[i].value) - 5) / 4 * multi + add;
            const dist = predictions.values[i].value - predictions.predictions[i].value;
            if ((dist > 0 && dist < averageDist) || (dist < 0 && dist > -averageDist) || dist === 0)
                confidence++;
        }
    }
    return confidence;
}

export function getDateStr(timestamp, noHours) {
    const date = new Date(timestamp);

    const mm = date.getMonth() + 1; // getMonth() is zero-based
    const dd = date.getDate();

    let dateStr = [date.getFullYear(),
    (mm > 9 ? '' : '0') + mm,
    (dd > 9 ? '' : '0') + dd
    ].join('-');

    if (!noHours) {
        dateStr += ` ${date.getHours()}h${date.getMinutes() > 9 ? date.getMinutes() : '0' + date.getMinutes()}`;
    }

    return dateStr;
}
export function pagination(c, m) {
    var current = c,
        last = m,
        delta = 2,
        left = current - delta,
        right = current + delta + 1,
        range = [],
        rangeWithDots = [],
        l;

    for (let i = 1; i <= last; i++) {
        if (i == 1 || i == last || i >= left && i < right) {
            range.push(i);
        }
    }

    for (let i of range) {
        if (l) {
            if (i - l === 2) {
                rangeWithDots.push(l + 1);
            } else if (i - l !== 1) {
                rangeWithDots.push('...');
            }
        }
        rangeWithDots.push(i);
        l = i;
    }

    return rangeWithDots;
}

export function DMStoDegree(DMSString, isLat) {
    if (DMSString) {
        const splitDegrees = DMSString.split('°');
        if (splitDegrees.length === 2) {
            let degrees = splitDegrees[0];
            let seconds = splitDegrees[1];
            let multiplicator = 1;
            if (seconds.includes('S') || seconds.includes('W')) {
                multiplicator = -1;
            }
            seconds = seconds.substring(0, seconds.length - 1);
            seconds = seconds.split('.');
            if (seconds.length === 2) {
                try {
                    degrees = parseInt(degrees, 10);
                    const divider = isLat ? 36000 : 3600;
                    seconds = (parseInt(seconds[0], 10) / 60) + (parseInt(seconds[1], 10) / divider);
                    return ((degrees + seconds) * multiplicator);
                } catch (err) {
                    return 0;
                }
            }
        }
    }
    return 0;
}

export function getMonthName(monthNumber) {
    switch (monthNumber) {
        case 0:
            return "January";
        case 1:
            return "February";
        case 2:
            return "March";
        case 3:
            return "April";
        case 4:
            return "May";
        case 5:
            return "June";
        case 6:
            return "July";
        case 7:
            return "August";
        case 8:
            return "September";
        case 9:
            return "October";
        case 10:
            return "November";
        case 11:
            return "December";
    }
}

// function use to create cache keys for request
export function cacheTokenizer(url, options) {
  let toReturn = url.replace(Conf.BaseApi, '');
  if (options) {
    const keys = Object.keys(options);
    keys.forEach(k => {
      toReturn += `${k}${options[k]}`
    })
  } else {
    toReturn = `get${toReturn}`;
  }
  return toReturn;
}

export function getWeek() {
    var onejan = new Date(this.getFullYear(),0,1);
    var today = new Date(this.getFullYear(),this.getMonth(),this.getDate());
    var dayOfYear = ((today - onejan + 86400000)/86400000);
    return Math.ceil(dayOfYear/7)
  };

  export function getDistance(from, to) {
    var RADIUS = 6371;
  
    var toRad = function(n) {
      return n * Math.PI / 180;
    };

    var fromLat = from[0];
    var fromLon = from[1];
    var toLat = to[0];
    var toLon = to[1];
  
    var dLat = toRad(toLat - fromLat);
    var dLon = toRad(toLon - fromLon);
    var fromLat = toRad(fromLat);
    var toLat = toRad(toLat);
  
    var a = Math.pow(Math.sin(dLat / 2), 2) +
            (Math.pow(Math.sin(dLon / 2), 2) * Math.cos(fromLat) * Math.cos(toLat));
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  
    return RADIUS * c;
  };

  export function arrayContainId(arr, value) {
    for(let i = 0; i < arr.length; i++) {
        if (arr[i]._id == value) {
            return true;
        }
    }
    return false;
  };

  export function minuteToTime(minutes) {
      const h = parseInt(minutes / 60, 10);
      const m = minutes % 60;

      return `${h}h${m < 10 ? `0${m}` : m}`
  }
  export function timeToMinute(h, m) {
      return h * 60 + m;
  }

  export function setWithExpiry(key, value, ttl) {
    const now = new Date()
  
    // `item` is an object which contains the original value
    // as well as the time when it's supposed to expire
    const item = {
      value: value,
      expiry: now.getTime() + ttl,
    }
    localStorage.setItem(key, JSON.stringify(item))
  }

  export function getWithExpiry(key) {
    const itemStr = localStorage.getItem(key)
    // if the item doesn't exist, return null
    if (!itemStr) {
      return null
    }
    const item = JSON.parse(itemStr)
    const now = new Date()
    // compare the expiry time of the item with the current time
    if (now.getTime() > item.expiry) {
      // If the item is expired, delete the item from storage
      // and return null
      localStorage.removeItem(key)
      return null
    }
    return item.value
  }