import React, { Component } from 'react';
import moment from 'moment';
import $ from 'jquery';
import { TimePicker } from 'react-input-moment';
import Conf from '../../utils/Conf';
import { postRequest } from '../../utils/WebServicesManager';
import { ButtonToolbar, ToggleButtonGroup, ToggleButton } from 'react-bootstrap';
import { translate } from '../../utils/ReactMultiLang';


class EditUser extends Component {
  constructor(props) {
    super(props);
    this.t = props.t;
  }


  sendUser(callback) {
    const userToSend = {};
    userToSend.name = $("#userName").val();
    userToSend.firstName = $("#userFirstName").val();
    userToSend.email = $("#userMail").val();
    userToSend.phone = $("#userPhone").val();

    var url = Conf.BaseApi + Conf.EditUser;
    return postRequest(url, {
        user: userToSend
    }, (data) => {
      if (data.success || data.success == true) {
        if (callback != undefined) {
          callback();
        }
      } else {
        $("#error-window").text(data.message);
      }
    });
  }

  render() {
    const { user, callback } = this.props;

    return (
      <div className="flex-row align-items-center">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12">
              <div className="card mx-12">
                <div className="card-block p-12">
                  <div className="input-group mb-3" style={{ color: "red" }} id="error-window">

                  </div>
                  <div className="input-group mb-3">
                    <span className="input-group-addon"><i className="icon-user"></i></span>
                    <input type="text" className="form-control" placeholder={this.t('Surname')} id="userName" defaultValue={user.name}/>
                  </div>
                  <div className="input-group mb-3">
                    <span className="input-group-addon"><i className="icon-user"></i></span>
                    <input type="text" className="form-control" placeholder={this.t('Firstname')} id="userFirstName"  defaultValue={user.firstName}/>
                  </div>
                  <div className="input-group mb-3">
                    <span className="input-group-addon"><i className="icon-envelope-letter"></i></span>
                    <input type="text" className="form-control" placeholder={this.t('Email')} id="userMail"  defaultValue={user.email}/>
                  </div>
                  {this.t('Account.PhoneFormat')}
                  <div className="input-group mb-3">
                    <span className="input-group-addon"><i className="icon-phone"></i></span>
                    <input type="text" className="form-control" placeholder={this.t('Phone')} id="userPhone"  defaultValue={user.phone}/>
                  </div>

                  <br/>
                  <button type="button" className="btn btn-block btn-success" onClick={this.sendUser.bind(this, callback)}>
                  {this.t('Update')}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default translate(EditUser);