import React, { Component } from 'react';

class Footer extends Component {
  render() {
    return (
      <footer className="app-footer">
        <span className="float-right">powered by <a href="https://ebeewan.com">ebeewan</a> - ©<a href="https://necio.ch">necio sàrl</a> 2016 - 2020</span>
      </footer>
    )
  }
}

export default Footer;
