import React, { Component } from 'react';
import { Dropdown, DropdownMenu, DropdownItem, 
    DropdownToggle } from 'reactstrap';
import { getRequest, reportGetRequest } from '../../../utils/WebServicesManager';
import { translate } from '../../../utils/ReactMultiLang';
import Conf from '../../../utils/Conf';

class BoolLevel extends Component {
    constructor(props) {
        super(props);
        this.datas = {};
        this.options = {};
        this.state = {
            card1: false,
            isPublic: props.isPublic,
            isLocked: props.isLocked
        }
        this.getRequest = getRequest;
        if (props.isPublic) {
            this.getRequest = reportGetRequest;
        }
        this.t = props.t;
        this.sensor = props.sensor;
        this.getMessage(this.sensor);
    }

    getMessage(devEUI) {
        let url = Conf.BaseApi + 'messages/get/1/' + devEUI;
        this.getRequest(url, (dataDevice) => {
          if (dataDevice.success) {
            this.setState({ Message: dataDevice.result[0] });
          }
        });
    }

    render() {
        
        const { editComponent, deleteComponent, dataSets, title, color } = this.props;

        let boolSet = [];
        if (this.state.Message) {
            for (let i = 0; i < dataSets.length; i += 1) {
                boolSet.push(this.state.Message.data[dataSets[i].paramName]);
            }
        }

        return (
                <div className={"grid-element"}  ref='mainDiv' style={{ height:'100%', backgroundColor: color}} >
                    <div className='bool-div' height="100%">
                    {this.state.isPublic || this.state.isLocked ? '' :<div className="btn-group float-right">
                        <Dropdown isOpen={this.state.card1} toggle={() => { this.setState({ card1: !this.state.card1 }); }}>
                        <DropdownToggle onClick={() => { this.setState({ card1: !this.state.card1 }); }} className="btn active dropdown-toggle p-0" data-toggle="dropdown" aria-haspopup={true} aria-expanded={this.state.card1}>
                        <i className="icon-settings"></i>
                        </DropdownToggle>
                        <DropdownMenu right>
                            <DropdownItem onClick={() => {editComponent()} }><i className='icon-pencil' style={{color: "#212121"}}></i>{this.t('Edit')}</DropdownItem>
                            <DropdownItem onClick={() => {deleteComponent()} }><i className='icon-trash' style={{color: "#212121"}}></i>{this.t('Delete')}</DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
                        </div>}
                        <div style={{margin: "10% 25%", height:"60%"}} >{
                            boolSet.map((value, index) => {
                                if (value === 'true') {
                                    return (
                                        <div style={{backgroundColor:dataSets[index].color, color:"white", width:'100%', marginBottom: "1%",
                                            height:`${(100 - boolSet.length) / boolSet.length}%`, verticalAlign:'middle'}}>{dataSets[index].label}</div>
                                    );
                                } else {
                                    return (
                                        <div style={{backgroundColor:"white", color:"gray",  width:'100%', marginBottom: "1%", border: 'solid 2px black',
                                            height:`${(100 - boolSet.length) / boolSet.length}%`}}>{dataSets[index].label}</div>
                                    );
                                }
                            })
                        }</div>
                        <div style={{color: "black"}}>{title}</div>
                    </div>
                </div>
        );
    }
}

export default translate(BoolLevel);
