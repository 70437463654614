import React, { Component } from 'react';
import { translate } from '../../../../utils/ReactMultiLang';
import Conf from '../../../../utils/Conf';
import { getRequest, postRequest } from '../../../../utils/WebServicesManager';
import { Row, Col, Modal, ModalBody, ModalHeader, Button } from 'reactstrap';
import SensorDraggable from './Sensor';
import Dropzone from './Dropzone';
import PlaceStat from './PlaceStat';
import GenericSelect from '../../../../components/Selects/GenericSelect/GenericSelect';
import $ from 'jquery';
import { check } from '../../../../utils/Can';
import SensorTable from '../../../../components/Tables/SensorTable';
import PlaceTable from '../../../../components/Tables/PlaceTable';

class TabPlan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      plan: {},
      Key: 0,
      SelectedRoom: -1,
      Width: 750,
      Height: 750,
      EditRoom: props.EditRoom,
      Modal: false,
      building: props.building,
      floor: props.floor,
      room: props.room,
      CanEdit: check('infra:edit'),
      SelectedTab: props.selectedTab !== null || !props.floor || !props.floor.plan ? 0 : JSON.parse(localStorage.getItem('Plan-SelectedTab') || 0),
      SelectedSubTab: JSON.parse(localStorage.getItem('Plan-SelectedSubTab') || 0),
    };

    this.addPlace = props.addPlace;

    this.getData(this.state.SelectedTab);
    this.t = props.t;
    this.offsetX = 0;
    this.offsetY = 0;
    this.Index = 0;
    this.Position = 0;
    this.posX = 0;
    this.posY = 0;
    this.mouseMove = this.mouseMove.bind(this);
    this.canUpdatePoint = true;
    this.toggle = this.toggle.bind(this);
    this.timeout = null;
    this.handleRigthClick();
  }

  getPlan(plan) {
    let url = Conf.BaseApi + 'plans/getOne/' + plan;
    return getRequest(url, (data) => {
      if (data.success)
        this.setState({ plan: data.result, Key: this.state.Key + 1, Image: data.result.blobPlan });
    });
  }

  componentDidMount() {
    this.setState({ key: this.state.key + 1 })
    document.addEventListener('keydown', this.handleKeyPress.bind(this), false);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyPress.bind(this), false);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.key !== nextProps.key) {
      this.setState({
        plan: nextProps.plan, Key: this.state.Key + 1
      });
    } if (this.props.EditRoom !== nextProps.EditRoom) {
      this.setState({
        EditRoom: nextProps.EditRoom,
        SelectedRoom: -1
      });
    } if (this.props.floor !== nextProps.floor) {
      this.setState({
        floor: nextProps.floor
      }, this.getData(this.state.SelectedTab));
    } if (this.props.room !== nextProps.room) {
      this.setState({
        room: nextProps.room
      }, this.getData(this.state.SelectedTab));
    } if (this.props.building !== nextProps.building) {
      this.setState({
        building: nextProps.building
      }, this.getData(this.state.SelectedTab));
    } if (this.props.selectedTab !== nextProps.selectedTab) {
      this.setState({
        SelectedTab: nextProps.selectedTab !== null ? nextProps.selectedTab : this.state.SelectedTab
      });
    }
  }

  toggle() {
    this.setState({
      Modal: !this.state.Modal
    });
  }

  generateSensors(sensors) {
    const toRender = [];
    const planSensor = this.state.plan.locatedSensors ? this.state.plan.locatedSensors.map(s => s.sensor && s.sensor._id ? s.sensor._id : s.sensor) : [];
    const rooms = this.state.plan.rooms;
    for (let i = 0; i < sensors.length; i += 1) {
      let sfound = -1;
      for (let j = 0; j < rooms.length; j += 1) {
        if (rooms[j].room && rooms[j].room.sensors) {
          sfound = rooms[j].room.sensors.findIndex(s => s === sensors[i]._id);
          if (sfound !== -1)
            break;
        }
      }
      if (!planSensor.some(p => p === sensors[i]._id) && sfound === -1)
        toRender.push(<SensorDraggable sensor={sensors[i]} notAssigned={true} />);
    }
    return (toRender);
  }

  addSensor(sensor) {
    /*let url = Conf.BaseApi + 'plans/pushSensor';
    if (!this.state.plan.locatedSensors)
      this.state.plan.locatedSensors = [];

    const sensorFull = this.state.Sensors.find(s => s._id === sensor.sensor);
    if (sensorFull) {
      sensor.sensor = sensorFull;
      this.state.plan.locatedSensors.push(sensor);
    }

    sensor.planId = this.state.plan._id;
    return postRequest(url, sensor, (data) => {
      if (data.success)
        this.setState({ Key: this.state.Key + 1 });
    });*/
  }

  updatePosition(objType, id, position) {
    let url = Conf.BaseApi + `${objType}/editPos`;

    return postRequest(url, { assetId: id, planPos: position }, (data) => {
      if (data.success)
        this.setState({ Key: this.state.Key + 1 });
    });
  }
  updateRooms(rooms) {
    let url = Conf.BaseApi + 'plans/editRooms';

    const r = Object.create(rooms);
    for (let i = 0; r < r.length; i += 1) {
      if (r[i].room) {
        r[i].room = r[i].room._id;
      }
    }

    return postRequest(url, { rooms: r, planId: this.state.plan._id }, (data) => {
      if (data.success)
        this.setState({ Key: this.state.Key + 1, plan: data.result });
    });
  }

  removeFromPlan(sensorId) {
    let url = Conf.BaseApi + 'plans/removeSensor';

    const options = {
      planId: this.state.plan._id,
      sensorId: sensorId
    }
    if (this.state.plan.locatedSensors) {
      const lSensors = this.state.plan.locatedSensors;
      for (var i = 0; i < lSensors.length; i++) {
        if ((lSensors[i].sensor._id && lSensors[i].sensor._id === sensorId) ||
          lSensors[i].sensor === sensorId) {
          this.state.plan.locatedSensors.splice(i, 1);
          break;
        }
      }
    }
    return postRequest(url, options, (data) => {
      if (data.success)
        this.setState({ Key: this.state.Key + 1 });
    });
  }

  selectRoom(index) {
    this.setState({ SelectedRoom: index });
  }

  refreshMotion() {
    this.canUpdatePoint = true;
    this.timeout = setTimeout(() => this.refreshMotion(), 250);
  }

  mouseStart(index, pos, e) {
    this.Index = index;
    this.Position = pos;
    const el = document.getElementById("planContainer");
    const rooms = this.state.plan.rooms;
    if (rooms[this.Index] && rooms[this.Index].position && rooms[this.Index].position[this.Position]) {
      this.offsetX = e.clientX;
      this.offsetY = e.clientY;
      this.posX = rooms[this.Index].position[this.Position];
      this.posY = rooms[this.Index].position[this.Position + 1];
      this.canUpdatePoint = true;
      //this.timeout = setTimeout(() => this.refreshMotion(), 250);
      el.addEventListener('mousemove', this.mouseMove);
    }
  }
  mouseMove(e) {
    const mX = e.clientX - this.offsetX;
    const mY = e.clientY - this.offsetY;
    if ((mX > 3 || mX < -3 || mY > 3 || mY < -3)) {
      const el = e.target;
      const plan = this.state.plan;
      const { rooms } = plan;
      if (rooms[this.Index] && rooms[this.Index].position && rooms[this.Index].position[this.Position]) {
        rooms[this.Index].position[this.Position] = e.clientX - this.offsetX + this.posX;
        rooms[this.Index].position[this.Position + 1] = e.clientY - this.offsetY + this.posY;
        plan.rooms = rooms;
        this.canUpdatePoint = false;
        this.setState({ plan });
      }
    }
  }
  mouseEnd(e) {
    const el = document.getElementById("planContainer");
    el.removeEventListener('mousemove', this.mouseMove);
    this.updateRooms(this.state.plan.rooms);
  }

  handleKeyPress(event) {
    if ((event.key === 'Delete' || event.key === 'Backspace') && this.state.SelectedRoom !== -1) {
      const plan = this.state.plan;
      const { rooms } = plan;
      rooms.splice(this.state.SelectedRoom, 1);
      plan.rooms = rooms;
      this.setState({ plan });
      this.updateRooms(this.state.plan.rooms);
    }
  }

  addRoomToPlan() {
    const plan = this.state.plan;
    let { rooms } = plan;
    if (!rooms) rooms = [];
    let room = [];
    const inputRoom = $("input[name=roomSelector]");
    for (let i = 0; i < inputRoom.length; i += 1) {
      room.push(inputRoom[i].value);
    }
    rooms[this.state.SelectedRoom].room = room[0];
    this.setState({ Modal: false });
    this.updateRooms(rooms);
  }

  addRectToPlan(evt) {
    const plan = this.state.plan;
    const { rooms } = plan;

    let bounds = document.getElementById('targetDroppable').getBoundingClientRect();
    let x = evt.clientX - bounds.left;
    let y = evt.clientY - bounds.top;
    rooms.push({ position: [x - 50, y - 50, x + 50, y - 50, x + 50, y + 50, x - 50, y + 50] });
    this.updateRooms(rooms);
  }

  render() {
    let url = `rooms/get`;
    if (this.state.floor) {
      url = `rooms/getByFloor/${this.state.floor._id}`;
    } else if (this.state.building) {
      url = `rooms/get/${this.state.building._id}`;
    }
    return (
      <Row >
        <Col md={12}>
          <div className="card" style={{ width: '100%' }}>
            <div style={{ marginTop: "10px", marginBottom: "5px", marginLeft: "auto", marginRight: "auto" }} className='plan-menu'>
              <h4>
                <span style={{ margin: "15px", lineHeight: '1.8em' }}
                  className={this.state.SelectedTab === 0 ? 'active-tab' : 'inactive-tab'}
                  onClick={this.selesctTab.bind(this, 0)}>{this.t('Sensors')}</span>{' - '}
                <span style={{ margin: "15px", lineHeight: '1.8em' }}
                  className={this.state.SelectedTab === 1 ? 'active-tab' : 'inactive-tab'}
                  onClick={this.selesctTab.bind(this, 1)}>{this.t('Places')}</span>{' - '}
                <span><span style={{ margin: "15px", lineHeight: '1.8em' }}
                  className={this.state.SelectedTab === 2 ? 'active-tab' : 'inactive-tab'}
                  onClick={this.selesctTab.bind(this, 2)}>{this.t('Plan')}</span>{' - '}</span>
                <span style={{ margin: "15px", lineHeight: '1.8em' }}
                  className={this.state.SelectedTab === 3 ? 'active-tab' : 'inactive-tab'}
                  onClick={this.selesctTab.bind(this, 3)}>{this.t('Statistics')}</span>
              </h4>
            </div>
          </div>
        </Col>
        <Col md={12} id="planContainer" style={{ position: 'relative' }}>
          <div className="card" style={{ width: '100%' }}>
            {this.getTab(this.state.SelectedTab)}
          </div>
        </Col>
        <Modal isOpen={this.state.Modal} className={'modal-md ' + this.props.className}>
          <ModalHeader>
            <Row style={{ paddingLeft: '15px', paddingRight: '15px' }}>
              <h1>{this.t('Room.Select')}</h1>
              <i className="icon-close color-danger" onClick={this.toggle}
                style={{ position: 'absolute', textAlign: 'right', right: '15px', cursor: 'pointer' }}></i>
            </Row>
          </ModalHeader>
          <ModalBody>
            <div style={{ position: "relative" }}>

              <div className="input-group mb-3" style={{ color: "red" }} id="error-window">
              </div>

              <GenericSelect url={url} propId='roomSelector' />
            </div>
            <button type="button" className="btn btn-block btn-success" onClick={this.addRoomToPlan.bind(this)}>{this.t('Add')}</button>
          </ModalBody>
        </Modal>
      </Row>
    )
  }

  handleRigthClick() {
    document.addEventListener("contextmenu", (event) => {
      if (this.state.plan.rooms && this.state.EditRoom) {
        event.preventDefault();
        let bounds = document.getElementById('targetDroppable').getBoundingClientRect();
        const xPos = event.clientX - bounds.left;
        const yPos = event.clientY - bounds.top;
        let room = this.state.plan.rooms[this.state.SelectedRoom];
        if (room) {
          let closer = 0;
          let second = room.position.length - 2;
          let min1 = 999;
          let min2 = 999;
          room.position.forEach((p, i) => {
            if (i % 2 === 0) { // x
              const diff = Math.abs(p - xPos) + Math.abs(room.position[i + 1] - yPos);
              if (diff < min1) {
                min1 = diff;
                closer = i;
              } else if (diff < min2) {
                min2 = diff;
                second = i;
              }
            }
          });
          let index = closer + 1;
          if (second == closer - 2) {
            index = second + 1;
          }
          room.position.splice(index + 1, 0, xPos);
          room.position.splice(index + 2, 0, yPos);
          this.state.plan.rooms[this.state.SelectedRoom] = room;
          this.updateRooms(this.state.plan.rooms);

        }
      }
    });
  }

  renderPlan(cubeSize, buffer) {
    return (
      <div>
        <Dropzone img={buffer ? buffer.toString('utf8') : ''}
          usePlaces={this.state.UsePlaces}
          addSensor={this.addSensor.bind(this)}
          updatePosition={this.updatePosition.bind(this)}
          floor={this.state.floor}
          image={this.state.Image}
          removeFromPlan={this.removeFromPlan.bind(this)}
          locatedSensors={this.state.plan ? this.state.plan.locatedSensors : []}
          rooms={this.state.plan ? this.state.plan.rooms : []}>
          {
            this.state.plan.rooms && this.state.EditRoom ?
              <div style={{ position: 'absolute', top: '0', width: "100%", height: "100%" }}>
                <svg width="100%" height="100%" onDoubleClick={this.state.EditRoom ? this.addRectToPlan.bind(this) : ''}>
                  {this.state.plan.rooms.map((r, index) => {
                    if (index === this.state.SelectedRoom) {
                      const toRender = [];
                      toRender.push(<polygon
                        key={r._id}
                        points={r.position ? r.position.join(', ') : ''}
                        style={{ fill: `#${Conf.Colors[index % 7]}50` }} onClick={() => this.selectRoom(index)}
                        onDoubleClick={(evt) => this.openRoom(index, evt)} />);
                      if (r.position) {
                        for (let i = 0; i < r.position.length; i += 1) {
                          if (i % 2 === 0) {
                            toRender.push(
                              <rect
                                key={r._id + i}
                                width={cubeSize}
                                height={cubeSize}
                                x={r.position[i] - (cubeSize / 2)}
                                y={r.position[i + 1] - (cubeSize / 2)}
                                fill={`#ffffff`} stroke="#000000a0"
                                onMouseDown={(e) => this.mouseStart(index, i, e)} onMouseUp={(e) => this.mouseEnd(e)} />);
                          }
                        }
                      }
                      return toRender;
                    } else {
                      return (
                        <polygon
                          key={r._id}
                          points={r.position ? r.position.join(', ') : ''}
                          style={{ fill: `#${Conf.Colors[index % 7]}50` }} onClick={() => this.selectRoom(index)}
                          onDoubleClick={(evt) => this.openRoom(index, evt)} />
                      );
                    }
                  })}
                </svg>
              </div>
              : ''
          }
        </Dropzone>

        {this.state.CanEdit ?

          !buffer ?
            <button type="button"
              className="btn btn-block btn-success"
              style={{ top: '10px', zIndex: '10', position: 'absolute' }}
              onClick={this.props.addPlan}>{this.t('AddPlan')}</button>
            :
            <div className="float-right" style={{ right: '10px', top: '10px', zIndex: '10', position: 'absolute' }} className='plan-menu small'>
              <h4>
                <span style={{ margin: "15px", lineHeight: '1.8em' }}
                  className={this.state.SelectedSubTab === 0 ? 'active-tab' : 'inactive-tab'}
                  onClick={this.selesctSubTab.bind(this, 0)}>{this.t('Sensors')}</span>{' - '}
                <span style={{ margin: "15px", lineHeight: '1.8em' }}
                  className={this.state.SelectedSubTab === 1 ? 'active-tab' : 'inactive-tab'}
                  onClick={this.selesctSubTab.bind(this, 1)}>{this.t('Places')}</span>{' - '}
                <span style={{ margin: "15px", lineHeight: '1.8em' }}
                  className={this.state.SelectedSubTab === 2 ? 'active-tab' : 'inactive-tab'}
                  onClick={this.selesctSubTab.bind(this, 2)}>{this.t('Edit')}</span>
              </h4>
            </div>
          : ''}
          {buffer ? 
            <button type="button"
              className="btn btn-block btn-danger"
              onClick={() => this.props.removePlan(this.state.plan)}>{this.t('RemovePlan')}</button> : ''}
      </div>);
  }

  selesctSubTab(selectedTab) {
    switch (selectedTab) {
      case 0:
        this.setState({ EditRoom: false, UsePlaces: false, SelectedSubTab: selectedTab });
        break;
      case 1:
        this.setState({ EditRoom: false, UsePlaces: true, SelectedSubTab: selectedTab });
        break;
      case 2:
        this.setState({ EditRoom: true, UsePlaces: false, SelectedSubTab: selectedTab });
        break;
      default:
        this.setState({ EditRoom: false, UsePlaces: false, SelectedSubTab: 0 });
        break;
    }
  }

  getTab(selectedTab) {
    switch (selectedTab) {
      case 0:
        let deviceUrl = `devices/building/${this.state.building ? this.state.building._id : ''}`;
        if (this.state.floor) {
          deviceUrl = `devices/floor/${this.state.floor._id}`;
        }
        if (this.state.room) {
          deviceUrl = `devices/room/${this.state.room._id}`;
        }
        return (<SensorTable deviceUrl={deviceUrl} colList={[0, 1, 2, 3, 4, 5]} />);
      case 1:
        let assetsUrl = `assets/get/building/${this.state.building ? this.state.building._id : ''}/Place`;
        if (this.state.floor) {
          assetsUrl = `assets/get/floor/${this.state.floor._id}/Place`;
        }
        if (this.state.room) {
          assetsUrl = `assets/get/room/${this.state.room._id}/Place`;
        }
        return (
          <div>
            <PlaceTable assetsUrl={assetsUrl} colList={[0, 2, 3, 4]} />
            <Button color="primary" className="btn btn-success btn-block" onClick={this.addPlace}>
              {this.t('Asset.Add.Place')}
            </Button>
          </div>);
      case 2:
        const { Image } = this.state;
        const cubeSize = 10;
        let buffer = undefined;
        if (Image) {
          buffer = new Buffer(Image.data);
        }
        return this.renderPlan(cubeSize, buffer);
      case 3:
        let assetsUrl2 = `assets/get/building/${this.state.building ? this.state.building._id : ''}/Place`;
        if (this.state.floor) {
          assetsUrl2 = `assets/get/floor/${this.state.floor._id}/Place`;
        }
        if (this.state.room) {
          assetsUrl2 = `assets/get/room/${this.state.room._id}/Place`;
        }
        return (<PlaceStat assetsUrl={assetsUrl2}></PlaceStat>);
    }
  }

  getData(selectedTab) {
    switch (selectedTab) {
      case 0:
        break;
      case 1:
        break;
      case 2:
        if (this.state.floor && this.state.floor.plan)
          this.getPlan(this.state.floor.plan);
        break;
      case 3:
        break;
    }
  }

  selesctTab(index) {
    this.setState({ SelectedTab: index }, () => {
      localStorage.setItem('Plan-SelectedTab', index)
    });
    this.getData(index);
  }

  openRoom(index, evt) {
    evt.stopPropagation();
    this.setState({ SelectedRoom: index, Modal: true });
  }
}
// <rect width={r[2]} height={r[3]} x={r[0]} y={r[1]} fill={`#${Conf.Colors[index % 7]}50`} />

export default translate(TabPlan);
