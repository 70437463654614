import React, { Component } from 'react';
import { translate } from '../../utils/ReactMultiLang';

class PublicHeader extends Component {

  constructor(props) {
    super(props);
    this.t = props.t;
  }

  render() {
    return (
      <header className="app-header navbar center">
        <a className="navbar-brand" href="#/dashboard">{' '}</a>
        <ul className="nav navbar-nav ml-auto">
          <li className="nav-item">
            <h5><a href='/#/public'><i className="icon-logout"></i> {this.t('Topbar.Exit')}</a></h5>
          </li>
        </ul>
      </header>
    )
  }
}

export default translate(PublicHeader);