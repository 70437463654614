import React, { Component } from 'react';
import Conf from '../../../utils/Conf';
import { Dropdown, DropdownMenu, DropdownItem, DropdownToggle, Col } from 'reactstrap';
import $ from 'jquery';
import { getRequest, postRequest } from '../../../utils/WebServicesManager';
import { Bar } from 'react-chartjs-2';
import { Min, Max, getMonthName, getWeek } from '../../../utils/Utils';
import { translate } from '../../../utils/ReactMultiLang';

class PlaceOccupancy extends Component {
    constructor(props) {
        super(props);
        this.datas = {};
        this.options = {};
        this.actualData = 0; 
        this.state = {
            Stats: [],
            asset: props.asset,
            dayOfWeek: props.day,
            from: props.from,
            to: props.to
        };
		if (props.asset) {
            this.getMessagesStatic(props.asset._id, this.state.from, this.state.to);
		}
        this.ChartKey = 0;
        this.t = props.t;
    }
    componentWillReceiveProps(nextProps) {
        if (this.props.from !== nextProps.from) {
          this.setState({from: nextProps.from}, this.getMessagesStatic(this.state.asset._id, this.state.from, this.state.to));
        }if (this.props.to !== nextProps.to) {
          this.setState({to: nextProps.to}, this.getMessagesStatic(this.state.asset._id, this.state.from, this.state.to));
        }
      }
    getMessagesSince(id) {
        var date = new Date();
        date.setDate(date.getDate() - (30 * 6));
        var url = Conf.BaseApi + `messages/getStat/assets/${id}/occupancy`;
        return getRequest(url, (body) => {
            if (body.success) {
              const messages = body.result;
              this.setState({Stats: messages});
            }
        });
      }
    
      getMessagesStatic(id, from, to) {
        var url = Conf.BaseApi + `messages/geStattBetween/assets`;
        return postRequest(url, {
            assetId: id,
            fromDate: parseInt(from.toDate().getTime() / 1000),
            toDate: parseInt(to.toDate().getTime() / 1000),
            paramName: 'occupancy'
        },(body) => {
            if (body.success) {
                const messages = body.result;
                this.setState({Stats: messages});
            }
        });
      }
    
    componentWillReceiveProps(nextProps) {
        if (this.props.asset !== nextProps.asset) {
          this.setState({
            asset: nextProps.asset
          });
        }if (this.props.day !== nextProps.day) {
            this.setState({
                dayOfWeek: nextProps.day
            }, () => this.getMessagesList(this.state.Stats, nextProps.day));
          }
      }

      checkDay(day, selectedDay) {
          if (selectedDay === 7) return true;
        if (selectedDay === day)
          return true;
        if (day < 6 && day > 0 && selectedDay === 8) return true;
        return false;
      }

    getMessagesList(messagesArray, dayOfWeek) {

        let labels = ['6h', '', '', '9h', '', '', '12h', '', '', '15h', '', '', '18h', '', '', '21h', ''];
        let datas = [];
        if (dayOfWeek > 6) {
            let computedDate = [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0];
            for (let i = 0; i <= 6; i += 1) {
                if ((i === 0 || i == 6) && dayOfWeek === 8) {
                    for (let j = 0; j < messagesArray[i].length; i += 1) {
                        computedDate[j] += messagesArray[i][j];
                    }
                } else if (i !== 0 && i != 6) {
                    for (let j = 0; j < messagesArray[i].length; j += 1) {
                        computedDate[j] += messagesArray[i][j];
                    }
                }
            }
            for (let i = 0; i < computedDate.length; i += 1) {
                computedDate[i] /= dayOfWeek === 8 ? 7 : 5;
            }
            datas = computedDate;
        } else {
            datas = messagesArray[dayOfWeek];
        }
        if (!datas || datas.length == 0) {
            datas = [[0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]];
            labels = [[0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]];
        }
            this.datas = {
                labels: labels,
                datasets: [{
                    barThickness: 50,
                    label: `(${new Date().getDay()})`,
                    fill: false,
                    lineTension: 0.2,
                    backgroundColor: '#0083F9',
                    borderColor: '#0083F9',
                    borderCapStyle: 'butt',
                    borderDash: [],
                    borderDashOffset: 0.0,
                    borderJoinStyle: 'miter',
                    pointBorderColor: 'rgba(190,190,190,1)',
                    pointBackgroundColor: '#fff',
                    pointBorderWidth: 0,
                    pointHoverRadius: 5,
                    pointHoverBackgroundColor: '#ffffff',
                    pointHoverBorderColor: '#ffffff',
                    pointHoverBorderWidth: 1,
                    pointRadius: 1,
                    pointHitRadius: 10,
                    data: datas
            }]
        };
        this.options = {
            layout: {
                padding: {
                    left: 20,
                    right: 20,
                    top: 20,
                    bottom: 20
                }
            },
            hover: {
                animationDuration: 0,
                mode: 'point'
            },
            animation: {
                duration: 750,
                easing: 'easeInQuad'
            },tooltips: {
                mode: 'point',
                callbacks: {
                    label: function(tooltipItem, data) {
                      return tooltipItem.yLabel + '%';
                    },
                    title: function(tooltipItem, data) {
                      return `${tooltipItem[0].index + 6 > 23 ? 24 - tooltipItem[0].index + 6 : tooltipItem[0].index + 6} h`;
                    }
                  },
                  backgroundColor: '#FFF',
                  borderColor: '#000',
                  titleFontSize: 12,
                  titleFontColor: '#0066ff',
                  bodyFontColor: '#000',
                  bodyFontSize: 14
            },
            legend: {
                display: false
            },
            scales: {
                xAxes: [{
                    gridLines: {
                        display:false
                    },
                    ticks: {
                        scaleFontSize: 40,
                        autoSkip: true,
                        maxTicksLimit: 20
                    }
                }],
                yAxes: [{
                    gridLines: {
                        drawBorder: false,
                        display:false
                    },
                    ticks: {
                        display: false,
                        min: 0,
                        max: 100
                    }
                }]
            },
            maintainAspectRatio: false
        };
    }
    
render() {
      
    const { asset } = this.props;

    this.getMessagesList(this.state.Stats, this.state.dayOfWeek);

    const chartStyle = {
        width: '100%',
        height: '200px'
      };

    return (
        <Col md='4'>
            <div className={"grid-element"} ref='mainDiv' style={{height:'100%'}}>
                <div className="card-block pb-0">
                    <h4 className="mb-0" style={{textAlign: "center"}}>{asset.name}</h4>
                </div>
                <div className="chart-wrapper px-3"
                        style={chartStyle}>
                        <Bar data={this.datas}
	                    key={this.ChartKey}
                        options={this.options}
                         />
                </div>
            </div>
        </Col>
    )
}
}

export default translate(PlaceOccupancy);
