import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom'
import HeaderDashboard from '../../components/HeaderDashboard/';
import Sidebar from '../../components/Sidebar/';
import Breadcrumb from '../../components/Breadcrumb/';
import Aside from '../../components/Aside/';
import Footer from '../../components/Footer/';
import Conf from '../../utils/Conf';
import {check} from '../../utils/Can';

import Dashboard from '../../views/Dashboard/';
import Reports from '../../views/Reports/';
import SensorsStatistics from '../../views/Sensors/Statistics/';
import AIStats from '../../views/Sensors/AIStats/';
import ManageSensors from '../../views/Sensors/Manage/';
import DetailSensor from '../../views/Sensors/Details';
import QuickViewSensors from '../../views/Sensors/QuickView/';
import GPSSensors from '../../views/Sensors/GPSSensors';
import ManageAlarms from '../../views/Alarms/Manage/';
import ModelsAlarms from '../../views/Alarms/Models/';
import ManageUsers from '../../views/Users/Manage/';
import ManageUsersAdm from '../../views/Users/ManageUsers/';
import NetworkMap from '../../views/Networks/NetworkMap';
import CoverageMap from '../../views/Networks/CoverageMap';
import ManageGroups from '../../views/Groups/Manage/';
import ManageBuildings from '../../views/Buildings/Manage/';
import ViewBuildings from '../../views/Buildings/QuickView/QuickView';
import BuildingDashboard from '../../views/Buildings/Dashboard';
import Plans from '../../views/Buildings/Plans';
import RoomDetails from '../../views/Buildings/RoomDetails';
import ReportDetails from '../../views/Reports/Details/Details';
import { getRequest } from '../../utils/WebServicesManager';
import { setLanguage } from '../../utils/ReactMultiLang';
import { setUserId, setUserLogin, setUserIsNecio } from '../../utils/AuthServices';
import Heatmap from '../../views/Buildings/Heatmap/Heatmap';
import ManageRounds from '../../views/Rounds/Manage';

class Full extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Alerts: 0,
      MainUser: {}
    };
    this.getUserDetails();
  }

  updateAlerts(alerts) {
    this.setState({Alerts: alerts});
  }

  getUserDetails() {
    var url = Conf.BaseApi + 'user';
    getRequest(url, (data) => {
      if (data.success) {
        setLanguage(data.result.lang !== undefined ? data.result.lang : 'en');
        this.setState({ MainUser: data.result });
        setUserId(data.result._id);
        setUserIsNecio(data.result.necioUser | false);
        setUserLogin(data.result.login);
      }
    });
  }

  render() {
    return (
      <div className="app">
        <HeaderDashboard alerts={this.state.Alerts}/>
        <div className="app-body">
          <Sidebar MainUser={this.state.MainUser} {...this.props}/>
          <main className="main">
            <Breadcrumb />
            <div className="container-fluid">
              <Switch>
                <Route path="/dashboard" name="Dashboard" component={Dashboard}/>
                
                {check("sensor:read") ? <Route path="/sensors/statistics" name="SensorsStatistics" component={SensorsStatistics}/> : ''}
                {check("sensor:read") ? <Route path="/sensors/manage" name="ManageSensors" component={ManageSensors}/> : ''}
                {check("sensor:read") ? <Route path="/sensors/detail" name="DetailSensor" component={DetailSensor}/> : ''}
                {check("sensor:read") ? <Route path="/sensors/quickview" name="QuickViewSensors" component={QuickViewSensors}/> : ''}
                {check("sensor:read") ? <Route path="/sensors/gps" name="GPSSensors" component={GPSSensors}/> : ''}
                {check("ai:read") ? <Route path="/sensors/ai" name="AISensors" component={AIStats}/> : ''}
                {check("alarm:read") ? <Route path="/alarms/manage" name="ManageAlarms" component={ManageAlarms}/> : ''}
                {check("alarm:read") ? <Route path="/alarms/models" name="ModelsAlarms" component={ModelsAlarms}/> : ''}
                {check("user:read") ? <Route path="/users/manage" name="ManageAccount" component={ManageUsers}/> : ''}
                {check("group:edit") ? <Route path="/groups/manage" name="ManageGroups" component={ManageGroups}/> : ''}
                {check("infra:read") ? <Route path="/buildings/manage" name="ManageBuildings" component={ManageBuildings}/> : ''}
                {check("infra:read") ? <Route path="/buildings/view" name="ViewBuildings" component={ViewBuildings}/> : ''}
                {check("infra:read") ? <Route path="/building/dashboard" name="BuildingDashboard" component={BuildingDashboard}/> : ''}
                {check("plan:read") ? <Route path="/buildings/plan" name="BuildingPlan" component={Plans}/> : ''}
                {check("heatmap:read") ? <Route path="/buildings/heatmap" name="BuildingHeatmap" component={Heatmap}/> : ''}
                {check("infra:read") ? <Route path="/room/detail" name="RoomDetails" component={RoomDetails}/> : ''}
                {check("network:read") ? <Route path="/networks/map" name="NetworkMap" component={NetworkMap}/> : ''}
                {check("network:edit") ? <Route path="/networks/coverage" name="CoverageMap" component={CoverageMap}/> : ''}
                {check("report:read") ? <Route path="/report/detail" name="ReportDetails" component={ReportDetails}/> : ''}
                {check("report:read") ? <Route path="/reports" name="Reports" component={Reports}/> : ''}
                {check("user:create") ? <Route path="/users/manageAdm" name="ManageUsers" component={ManageUsersAdm}/> : ''}
                {check("user:create") ? <Route path="/rounds" name="ViewRounds" component={ManageRounds}/> : ''}
                
                <Redirect from="/" to="/dashboard"/>
              </Switch>
            </div>
          </main>
          {check("alarm:read") ? <Aside updateAlerts={this.updateAlerts.bind(this)}/> : '' }
        </div>
        <Footer />
      </div>
    );
  }
}

export default Full;
