import React, { Component } from 'react';
import Conf from '../../../../utils/Conf';
import { Dropdown, DropdownMenu, DropdownItem, Progress, DropdownToggle } from 'reactstrap';
import $ from 'jquery';
import { getRequest, postRequest } from '../../../../utils/WebServicesManager';
import { Bar } from 'react-chartjs-2';
import { Min, Max } from '../../../../utils/Utils';
import DataHelpers from '../../../../utils/DataHelpers';

class AverageBoolean extends Component {
    constructor(props) {
        super(props);
        this.datas = {};
        this.options = {};
        this.actualData = 0;    
        this.state = {
        };
    }


    getMessagesList(messages, paramName) {
        var datas = [];
        var labels = [];
        var lastTime = 0;
        var messageSorted = DataHelpers.sortMessagesByHours(messages);
        messageSorted = DataHelpers.getNumberOfDataByHours(messageSorted, paramName);
        messageSorted = DataHelpers.averageHoursDataNumber(messageSorted);
        if (datas.length == 0) {
            datas = messageSorted;
            labels = ["00h", "01h", "02h", "03h", "04h", "05h", "06h", "07h", "08h", "09h", "10h", "11h", "12h", "13h", "14h", "15h", "16h", "17h", "18h", "19h", "20h", "21h", "22h", "23h"];
        }
        this.actualData = Math.round(datas.reduce((a, b) => a + b, 0) / datas.length * 10) / 10;
        this.datas = {
            labels: labels,
            datasets: [
                {
                    label: 'Ouverture(s)',
                    fill: false,
                    lineTension: 0.1,
                    backgroundColor: 'rgba(240,240,240,0.9)',
                    borderColor: 'rgba(240,240,240,1)',
                    borderCapStyle: 'butt',
                    borderDash: [],
                    borderDashOffset: 0.0,
                    borderJoinStyle: 'miter',
                    pointBorderColor: 'rgba(192,102,102,1)',
                    pointBackgroundColor: '#fff',
                    pointBorderWidth: 1,
                    pointHoverRadius: 5,
                    pointHoverBackgroundColor: 'rgba(75,192,192,1)',
                    pointHoverBorderColor: 'rgba(220,220,220,1)',
                    pointHoverBorderWidth: 2,
                    pointRadius: 5,
                    pointHitRadius: 10,
                    data: datas
                }
            ]
        };
        this.options = {
            layout: {
                padding: {
                    left: 20,
                    right: 20,
                    top: 20,
                    bottom: 20
                }
            },
            hover: {
                animationDuration: 0
            },
            animation: {
                duration: 500,
                easing: 'easeInQuad',
                onComplete: function () {
                    var chartInstance = this.chart,
                        ctx = chartInstance.ctx;

                    /*ctx.textAlign = 'center';
                    ctx.textBaseline = 'bottom';

                    this.data.datasets.forEach(function (dataset, i) {
                        var meta = chartInstance.controller.getDatasetMeta(i);
                        meta.data.forEach(function (bar, index) {
                            var data = dataset.data[index];
                            ctx.fillText(data, bar._model.x, bar._model.y - 5);
                        });
                    });*/
                }
            },
            legend: {
                display: false
            },
            scales: {
                yAxes: [{
                    display: false,
                    ticks: {
                        min: Min(datas),
                        max: Max(datas)
                    }
                }],
                xAxes: [{
                    display: false
                }]
            },
            maintainAspectRatio: false
        }
    }

render() {
      
    const { messages, paramName, label } = this.props;
    this.getMessagesList(messages, paramName);
    return (
            <div className="card card-inverse card-success animated fadeIn">
                <div className="card-block pb-0">
                    <div className="btn-group float-right">
                        <Dropdown isOpen={this.state.card1} toggle={() => { this.setState({ card1: !this.state.card1 }); }}>
                            <DropdownToggle type="button"  onClick={() => { this.setState({ card1: !this.state.card1 }); }} className="btn btn-transparent active dropdown-toggle p-0" data-toggle="dropdown" aria-haspopup="true" aria-expanded={this.state.card1}>
                                <i className="icon-settings"></i>
                            </DropdownToggle>
                            <DropdownMenu right>
                                <DropdownItem>Porte d'entrée</DropdownItem>
                                <DropdownItem>Fenêtre Antoine</DropdownItem>
                                <DropdownItem>Porte du garage</DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    </div>
                    <h4 className="mb-0">{this.actualData}  {label}</h4>
                    <p>Moyennes des {label} par heure de la journée</p>
                </div>
                <div className="chart-wrapper px-3" style={{ height: "140px" }}>
                    <Bar data={this.datas}
                        options={this.options}
                    />
                </div>
            </div>
    )
}
}

export default AverageBoolean;
