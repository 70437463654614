import { NodeModel, PortModel, Toolkit, DefaultPortModel } from 'storm-react-diagrams';

class AlarmNodeModel extends NodeModel {
    constructor(name, color, picto) {
        super('alarm');
        this.addPort(new DefaultPortModel(true, Toolkit.UID(), 'Sonne'));
        this.name = name;
        this.color = color;
        this.picto = picto;
    }


    deSerialize(object, engine) {
        let keys = Object.keys(this.getPorts());
        while (keys.length > 0) {
            this.removePort(this.getPorts()[keys[0]]);
            keys = Object.keys(this.getPorts());
        }
        super.deSerialize(object, engine);
        this.name = object.name;
        this.color = object.color;
        this.picto = object.picto;
    }

    serialize() {
        return Object.assign(super.serialize(), {
            name: this.name,
            color: this.color,
            picto: this.picto
        });
    }

    getPorts() {
        return this.ports;
    }

    getPortsIn() {
        const keys = Object.keys(this.ports);
        const toReturn = [];
        for (let i = 0; i < keys.length; i += 1) {
            if (this.ports[keys[i]].in) {
                toReturn.push(this.ports[keys[i]]);
            }
        }
        return toReturn;
    }
}

export default AlarmNodeModel;