import React, { Component } from 'react';
import createClass from 'create-react-class';
import Conf from '../../../utils/Conf';
import Select from 'react-select';
import { getRequest } from '../../../utils/WebServicesManager';
import fetch from 'isomorphic-fetch';


class GenericSelect extends Component {
	constructor(props) {
		super(props);
		this.displayName = 'GenericSelect';
		this.state = {
			options: [],
			backspaceRemoves: true,
			multi: false,
			value: undefined,
			group: props.group,
			url: props.url,
			fieldLabel: props.fieldLabel || 'name',
			fieldValue: props.fieldValue || '_id',
			changeBySelection: false
		};
		this.getEntity();
		this.returnSelection = props.updateSelection;
		
	}

  componentWillReceiveProps(nextProps) {
    if (nextProps.values !== this.props.values && !this.state.changeBySelection) {
			this.setState({multiValue: nextProps.values});
		}
	}
	getInitialState () {
		return {
			multiValue: [],
			options: [],
			backspaceRemoves: true,
			multi: false,
			value: undefined
		};
	}
	handleOnChange (value) {
		const { multi } = this.state;
		if (multi) {
			this.setState({ multiValue: value });
		} else {
			this.setState({ value });
		}
		if (this.returnSelection) {
			this.setState({changeBySelection: true}, () => this.returnSelection(value, this.state.models));
			
		}
	}
	getEntity() {
		const url = Conf.BaseApi + this.state.url;
		return getRequest(url, (body) => {
			if (body.success) {
				const data = body.result;
				const options = [];
				const models = {};
				for (let i = 0; i < data.length; i += 1) {
						options.push({value: data[i][this.state.fieldValue], label: data[i][this.state.fieldLabel]});
						models[data[i][this.state.fieldValue]] = data[i];
				}
				this.setState({ options, models });
			}
		  });
	}

	toggleBackspaceRemoves () {
		this.setState({
			backspaceRemoves: !this.state.backspaceRemoves
		});
	}
	render () {
		const { multi, multiValue, value } = this.state;
		const { values, propId } = this.props;
		return (
			<div className="section">
				<Select 
					multi={this.state.multi}
					value={multi ? this.state.multiValue : value}
					options={this.state.options}
					onChange={this.handleOnChange.bind(this)}
					backspaceRemoves={this.state.backspaceRemoves}
					id={propId ? propId : "sensorsName"}
					name={propId ? propId : "sensorsName"}
					/>
			</div>
		);
	}
}

export default GenericSelect;