export const VerticalLine = {
  id: 'VerticalLine',
  beforeDraw: function (chart) {
    var ctx = chart.chart.ctx;

    var alerts = chart.config.data.lineAtIndex;

    if (alerts) {
      for (let i = 0; i < alerts.length; i += 1) {
        const index = alerts[i].index;
        const label = alerts[i].label;
        var xaxis = chart.scales['x-axis-0'];
        var yaxis = chart.scales['y-axis-0'];

        ctx.save();
        ctx.beginPath();
        ctx.moveTo(xaxis.getPixelForValue(index), yaxis.top);
        ctx.strokeStyle = '#ff0000';
        ctx.lineTo(xaxis.getPixelForValue(index), yaxis.bottom);
        ctx.stroke();
        ctx.restore();


        var width = chart.chart.width,
          height = chart.chart.height,
          ctx = chart.chart.ctx,
          xScale = chart.scales['x-axis-0'],
          yScale = chart.scales['y-axis-0'];

        ctx.restore();
        ctx.font = "1em sans-serif";
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";
        // ctx.fillText("s(A)", width * .28, height * .70);
        ctx.fillText(
          label,
          xScale.getPixelForValue(index),
          10
        );
        ctx.save();

      }
    }
  }
}