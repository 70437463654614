import React, { Component } from 'react';
import {Map} from 'immutable';
import { Progress } from 'reactstrap';
import Conf from '../../utils/Conf';
import $ from 'jquery';
import { getRequest, postRequest } from '../../utils/WebServicesManager';
import classnames from 'classnames';
import { Col, Row, Nav, NavItem, NavLink,
  TabContent, TabPane,
  Modal, ModalHeader, ModalBody, Label, Input } from 'reactstrap';
import Report from './Report';
import CreateReport from '../../components/ReportModals/CreateReport';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { translate } from '../../utils/ReactMultiLang';
import { getUserId } from '../../utils/AuthServices';
import { check } from '../../utils/Can';


class Reports extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Reports: [],
      ActReport: this.getInitialState(),
      ReportToEdit: undefined,
      Modal: false,
      CanCreate: false,
      CanCreate: check('report:create'),
      CanEdit: check('report:edit'),
      CanRemove: check('report:remove')
    };
    this.getReports();
    this.toggle = this.toggle.bind(this);
    this.t = props.t;
  }

  getInitialState () {
    const ActReport = localStorage.getItem('Reports') || 0;
    return parseInt(ActReport);
  }

  toggleModal(report) {
    this.setState({
      Modal: !this.state.Modal,
      ReportToEdit: report
    });
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.customSetState({
        ActReport: tab
      });
    }
  }
  getReports() {
    var url = Conf.BaseApi + 'reports/get';
      return getRequest(url, (result) => {
        if (result.success === true) {
          this.setState({ Reports: result.result });
        }
      });
  }

  lockReport(report) {
    var url = Conf.BaseApi + 'reports/lock';
      return postRequest(url, {
        reportId: report._id,
        lock: !report.lock
      }, (result) => {
        if (result.success === true && result.result) {
          const reports = this.state.Reports;
          let idx = reports.findIndex(r => r._id === result.result._id);
          if (idx > -1)
            reports[idx].lock = result.result.lock;
          this.setState({Reports: reports});
        }
      });
  }

  genTabHeader(reports) {
    const render = [];
    const userId = getUserId();
    if (reports != undefined) {
      for (let i = 0; i < reports.length; i += 1) {
        let isAdmin = false;
        if (reports[i].adminUsers.some(u => u._id === userId)) isAdmin = true;
        render.push(<NavItem key={i}>
                  <NavLink
                    className={classnames({ active: this.state.ActReport === (i) })}
                    onClick={() => { this.toggle(i); }} style={{cursor:'pointer'}}
                  >
                    <span style={{color: isAdmin ? "black" : "#A9A9A9"}}>
                     {reports[i].lock ? <i className='icon-lock'></i> : ''} {reports[i].name}</span>
                     
            {this.state.CanEdit ?
            <Label className="switch switch-sm switch-text switch-success switch-label float-right mb-0" style={{marginLeft: '5px'}}>
            {!reports[i].lock ? <Input type="checkbox" className="switch-input" onChange={this.lockReport.bind(this, reports[i])} defaultChecked/> : 
              <Input type="checkbox" className="switch-input" onChange={this.lockReport.bind(this, reports[i])} />}
              <span className="switch-label" ></span>
                    <span className="switch-handle"></span>
                </Label> : ''}
                  </NavLink>
                </NavItem>);
      }
    }
    if (this.state.CanCreate) {
      render.push(<NavItem key={60000}>
        <NavLink
          onClick={() => { this.toggleModal(); }}
        >
          +
        </NavLink>
      </NavItem>);
    }
    return render;
  }

  onUpdateLayout(pos, report) {
    if (this.state.Reports && this.state.Reports.length > pos && this.state.Reports[pos]) {
      report.adminUsers = this.state.Reports[pos].adminUsers;
      this.state.Reports[pos] = report;
    }
  }

  getTabContent(reports) {
    const render = [];
    const userId = getUserId();
    if (reports != undefined) {
      for (let i = 0; i < reports.length; i += 1) {
        if (this.state.ActReport === i) {
          let isAdmin = false;
          if (reports[i].adminUsers.some(u => u._id === userId)) isAdmin = true;
          render.push(<TabPane tabId={i} key={i}>
            <Report report={reports[i]} id={reports[i]._id} key={this.state.ActReport === (i) ? 999 : i} onUpdateLayout={this.onUpdateLayout.bind(this, i)}
                      isVisible={this.state.ActReport === (i)} ref={el => (this[`componentRef${reports[i]._id}`] = el)} isLocked={!isAdmin || !this.state.CanEdit || reports[i].lock}/>
            <Row>
              <Col xs="12" md={ this.state.CanEdit && isAdmin ? 4 : 12 }>
                <button type="button" className="btn btn-block btn-primary" onClick={this.printDocument.bind(this, reports[i])}>{this.t('Export')}</button>
              </Col>
              { this.state.CanEdit && isAdmin ? <Col xs="12" md="4">
                <button type="button" className="btn btn-block btn-primary" onClick={this.toggleModal.bind(this, reports[i])}>{this.t('Edit')}</button>
              </Col> : '' }
              { this.state.CanRemove && isAdmin ? <Col xs="12" md="4">
                <button type="button" className="btn btn-block btn-danger" onClick={this.removeReport.bind(this, reports[i])}>{this.t('Delete')}</button>
              </Col> : '' }
            </Row>
          </TabPane>);
        }
      }
    }
    return render;
  }

  removeReport(report) {
    var url = Conf.BaseApi + 'reports/remove';
    const r = window.confirm('Etes vous sur de vouloir supprimer le rapport : "' + report.name + '" ?');
    if (r) {
      return postRequest(url, {
        reportId: report._id
      }, (result) => {
        if (result.success === true) {
          this.getReports();
        }
      });
    }
  }

  createReport(report, template) {
    var url = Conf.BaseApi + 'reports/add';
    let options = {
      report: report
    }
    if (template) {
      url = Conf.BaseApi + 'templateReports/generate';
      options.templateId = template.templateId;
      options.realVar = template.realVar;
    }
      return postRequest(url, options, (result) => {
        if (result.success === true) {
          this.toggleModal();
          this.getReports();
        }
      });
  }

  editReport(report) {
    var url = Conf.BaseApi + 'reports/update';
    const reportToSent = {};
    reportToSent._id = report._id;
    reportToSent.adminUsers = report.adminUsers;
    reportToSent.simpleUsers = report.simpleUsers;
    reportToSent.shared = report.shared;
    reportToSent.id = report.id;
    reportToSent.password = report.password;
    reportToSent.name = report.name;
    reportToSent.usersToSend = report.usersToSend;
    reportToSent.sendReport = report.sendReport;
    reportToSent.sendSchedule = report.sendSchedule;
    reportToSent.useGlobal = report.useGlobal;
    reportToSent.from = report.from;
    reportToSent.to = report.to;
    reportToSent.days = report.days;

      return postRequest(url, {
        report: reportToSent
      }, (result) => {
        if (result.success === true) {
          window.location.reload();
        }
      });
  }

  render() {
    return (
        <Col xs="12" md="12" className="mb-12">
            <Nav tabs>
              {this.genTabHeader(this.state.Reports)}
            </Nav>
            <TabContent activeTab={this.state.ActReport}>
              {this.getTabContent(this.state.Reports)}
            </TabContent>
        <Modal isOpen={this.state.Modal} className={'modal-md ' + this.props.className}>
                    <ModalHeader>
                        <Row style={{paddingLeft:'15px', paddingRight:'15px'}}>
                            <h1>{this.state.ReportToEdit === undefined ? this.t('Report.Create') :  this.t('Report.Edit')}</h1>
                            <i className="icon-close color-danger" onClick={this.toggleModal.bind(this)}
                                style={{position:'absolute', textAlign: 'right', right:'15px', cursor:'pointer'}}></i>
                        </Row>
                    </ModalHeader>
          <ModalBody>
            <CreateReport callback={this.state.ReportToEdit === undefined ? this.createReport.bind(this) : this.editReport.bind(this)} report={this.state.ReportToEdit}/>
          </ModalBody>
        </Modal>
          </Col>
    )
  }

  /************************* PDF functions */
  printDocument(report) {
    const defaultScale = 1;

    const inputs = $(`#${report._id}`).find('.grid-element');
    let pdf = new jsPDF({
      orientation: 'landscape', 
      size: "a4"});

    // Fix Graphics Output by scaling PDF and html2canvas output to 2
    pdf.scaleFactor = defaultScale;
    pdf.setFontSize(10);
    pdf.page = 1;
    this.addPageToPdf(report, pdf);
    let actualX = 5;
    let actualY = 15;
    const totalWidth = $(`#${report._id}`).width();
    const pdfWidth = 260;
    const lineHeigth = 50;
    const sizeRatio = 270 / totalWidth;
    const margin = 15 * sizeRatio;
    let yToRemove = 0;
    // Create a new promise with the loop body
    let addPages = new Promise((resolve,reject)=>{
      inputs.sort((a, b) => {
        const pElemA = a.parentNode;
        const pElemB = b.parentNode;
        const compoA = report.components.find(c => c._id === pElemA.id);
        const compoB = report.components.find(c => c._id === pElemB.id);
        if (compoA && compoB) {
          if (compoA.layout.y === compoB.layout.y) {
            return compoA.layout.x - compoB.layout.x;
          }
          else {
            return compoA.layout.y - compoB.layout.y;
          }
        }
        return 0;
      });
      let idx = 0;
      const nextStep = () => {
        if(idx >= inputs.length) {
          resolve();
          return console.log("Reached last page, completing");
        };
        const elem = inputs[idx];
        const gauge = $(`#${elem.parentNode.id}`).find(".gauge-class");
        if (gauge) {
          gauge.css('margin-top', '0px');
        }
        html2canvas(elem,
          { 
            ignoreElements: (e => {
              if (e.classList.contains('btn-group')) return true;
                return false;
            })}).then((canvas) => {
              
              if (gauge) {
                gauge.css('margin-top', '-25px');
              }

              const pElem = elem.parentNode;
              const compo = report.components.find(c => c._id === pElem.id);
                if (compo) {
                  const { layout } = compo;
                      const width = pdfWidth * layout.w / 6 + ((layout.w - 1) * margin);
                      const height = lineHeigth * layout.h * sizeRatio + ((layout.h - 1) * margin);
                      actualY = 15 + ((layout.y - yToRemove) * (lineHeigth + 15) * sizeRatio);
                      if(actualY !== 15 && actualY + height > 200 || elem.classList.contains('page-break')){
                        pdf.page++;
                        pdf.addPage();
                        this.addPageToPdf(report, pdf);
                        yToRemove = layout.y;
                        actualY = 15;
                      }
                      actualX = 5 + (layout.x * (pdfWidth / 6 + margin));
                      pdf.addImage(canvas.toDataURL("image/jpeg", 8), actualX, actualY, width, height);
                    idx++;
                    nextStep();
                } else {
                  idx++;
                  nextStep();
                }
              }).catch(err => console.log(err));
      }
      nextStep();
  });

    addPages.finally(()=>{
      pdf.save(report.name + '.pdf');
    });
  }

  addPageToPdf(report, pdf) {
    var img = new Image(2000, 650);
    img.src = `${Conf.AppURL}/img/ebee.png`;

    // pdf.addImage(img, 'PNG', 1, 1, 40, img.height * 40 / img.width);

    img.onload = function(){
      pdf.addImage(img, 'PNG', 1, 1, 40, img.height * 40 / img.width, 'logo', 'FAST');
    };

    pdf.text(280 - (report.name.length * 1.5), 205, report.name + ' - ' + pdf.page);
  }

  customSetState(state) {
    this.setState(state, () => localStorage.setItem('Reports', this.state.ActReport));
  }
}

export default translate(Reports);
