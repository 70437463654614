import React, { Component } from 'react';
import Conf from '../../../utils/Conf';
import $ from 'jquery';
import { getRequest, postRequest } from '../../../utils/WebServicesManager';
import { Col, Row } from 'reactstrap';
import Report from '../Report';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { translate } from '../../../utils/ReactMultiLang';


class Reports extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Report: {}
    };
    const params = new URLSearchParams(props.location.search); 
    this.reportId = params.get('reportId');
    this.getReport(this.reportId);
    this.t = props.t;
    setTimeout(this.printDocument.bind(this), 40000);
  }

  getReport(reportId) {
    var url = Conf.BaseApi + 'reports/get/' + reportId;
      return getRequest(url, (result) => {
        if (result.success === true) {
          this.setState({ Report: result.result });
        }
      });
  }

  getTabContent(report) {
    const render = [];
          let isAdmin = false;
          render.push(
            <Report report={report} id={report._id}
                      isVisible={true} ref={el => (this[`componentRef${report._id}`] = el)} isLocked={true}/>
            );
            
    return render;
  }


  render() {
    return (
        <Col xs="12" md="12" className="mb-12">
        <div id="pdfContent" className="hidden"></div>
          {this.getTabContent(this.state.Report)}
        </Col>
    )
  }

  /************************* PDF functions */
  printDocument() {
    const report = this.state.Report;

    const defaultScale = 1;

    const inputs = $(`#${report._id}`).find('.grid-element');
    let pdf = new jsPDF({
      orientation: 'landscape', 
      size: "a4"});

    // Fix Graphics Output by scaling PDF and html2canvas output to 2
    pdf.scaleFactor = defaultScale;
    pdf.setFontSize(10);
    pdf.page = 1;
    this.addPageToPdf(report, pdf);
    let actualX = 5;
    let actualY = 15;
    const totalWidth = $(`#${report._id}`).width();
    const pdfWidth = 260;
    const lineHeigth = 50;
    const sizeRatio = 270 / totalWidth;
    const margin = 15 * sizeRatio;
    let yToRemove = 0;
    // Create a new promise with the loop body
    let addPages = new Promise((resolve,reject)=>{
      inputs.sort((a, b) => {
        const pElemA = a.parentNode;
        const pElemB = b.parentNode;
        const compoA = report.components.find(c => c._id === pElemA.id);
        const compoB = report.components.find(c => c._id === pElemB.id);
        if (compoA && compoB) {
          if (compoA.layout.y === compoB.layout.y) {
            return compoA.layout.x - compoB.layout.x;
          }
          else {
            return compoA.layout.y - compoB.layout.y;
          }
        }
        return 0;
      });
      let idx = 0;
      const nextStep = () => {
        if(idx >= inputs.length) {
          resolve();
          return console.log("Reached last page, completing");
        };
        const elem = inputs[idx];
        const gauge = $(`#${elem.parentNode.id}`).find(".gauge-class");
        if (gauge) {
          gauge.css('margin-top', '0px');
        }
        var rect = elem.getBoundingClientRect();
        html2canvas(elem,
          { 
            ignoreElements: (e => {
              if (e.classList.contains('btn-group')) return true;
                return false;
            }),
            x: rect.left - 50
          }).then((canvas) => {
              
              if (gauge) {
                gauge.css('margin-top', '-25px');
              }

              const pElem = elem.parentNode;
              const compo = report.components.find(c => c._id === pElem.id);
                if (compo) {
                  const { layout } = compo;
                      const width = pdfWidth * layout.w / 6 + ((layout.w - 1) * margin);
                      const height = lineHeigth * layout.h * sizeRatio + ((layout.h - 1) * margin);
                      actualY = 15 + ((layout.y - yToRemove) * (lineHeigth + 15) * sizeRatio);
                      if(actualY !== 15 && actualY + height > 200 || elem.classList.contains('page-break')){
                        pdf.page++;
                        pdf.addPage();
                        this.addPageToPdf(report, pdf);
                        yToRemove = layout.y;
                        actualY = 15;
                      }
                      actualX = 5 + (layout.x * (pdfWidth / 6 + margin));
                      pdf.addImage(canvas.toDataURL("image/jpeg", 8), actualX, actualY, width, height);
                    idx++;
                    nextStep();
                } else {
                  idx++;
                  nextStep();
                }
              }).catch(err => console.log(err));
      }
      nextStep();
  });

    addPages.finally(()=>{
      const buffer = pdf.output('datauristring');
      //document.location = buffer;
      //window.location = buffer;
      
    /*var url = Conf.BaseApi + 'reports/export/pdf/add';
    return postRequest(url, { reportId: this.reportId, pdf: buffer},(result) => {});*/
      $('#pdfContent').html(buffer);
//      pdf.save(report.name + '.pdf');
       //document.location.href ='data:application/pdf;base64,' + buffer;
      //pdf.save(report.name + '.pdf');
    });
  }

  addPageToPdf(report, pdf) {
    var img = new Image();
    img.src = '/img/ebee.png';

    pdf.addImage(img, 'PNG', 1, 1, 40, img.height * 40 / img.width, 'logo', 'FAST');
    pdf.text(270 - (report.name.length * 1.5), 205, report.name + ' - ' + pdf.page);
  }

}

export default translate(Reports);
