import React, { Component } from "react";
import $ from 'jquery';
import { DiagramEngine, NodeModel, Toolkit, BaseWidget, BaseWidgetProps, DefaultPortLabel } from "storm-react-diagrams";


class OperatorNodeWidget extends BaseWidget {
	constructor(props) {
		super("srd-node", props);
		this.state = {};
	}

	generatePort(port) {
		return <DefaultPortLabel model={port} key={port.id} />;
	}

	updateOperator(props) {
		props.node.setOperator($(`#${props.node.id}operator`).val());
	}

	render() {
		const {picto} = this.props.node;
		return (
			<div {...this.getProps()}  className={"complex-node node-operator " + this.props.node.color}>
				<div className={this.bem("__title")}>
					<img src={`./img/picto/${picto}.png`} />
					<div className={this.bem("__name")} style={{display:"inline-block"}}>{this.props.node.name}:{' '}
					<select style={{display:"inline-block"}} id={this.props.node.id + 'operator'} 
						onChange={this.updateOperator.bind(this, this.props)} defaultValue={this.props.node.getOperator()}>
						<option value="OR">OU</option>
						<option value="AND">ET</option>
					</select></div>
				</div>
				<div className={this.bem("__ports")}>
					<div className={this.bem("__in")}>
						{this.props.node.getPortsIn().map((port) => this.generatePort(port))}
					</div>
					<div className={this.bem("__out")}>
						{this.props.node.getPortsOut().map((port) => this.generatePort(port))}
					</div>
				</div>
			</div>
		);
	}
}

export default OperatorNodeWidget;