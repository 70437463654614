import L from 'leaflet';
import {divIcon} from "leaflet";

export const marker = new L.icon({
    iconUrl: require('./img/pins.png'),
    iconSize:     [60, 65], // size of the icon
    iconAnchor:   [23, 65], // point of the icon which will correspond to marker's location
    popupAnchor:  [0, -53],// point from which the popup should open relative to the iconAnchor
});

export const marker_warning = L.icon({
    iconUrl: require('./img/pins_warning.png'),
    iconSize:     [60, 65], // size of the icon
    iconAnchor:   [23, 65], // point of the icon which will correspond to marker's location
    popupAnchor:  [0, -53],// point from which the popup should open relative to the iconAnchor
});

export const marker_alert = L.icon({
    iconUrl: require('./img/pins_alert.png'),
    iconSize:     [60, 65], // size of the icon
    iconAnchor:   [23, 65], // point of the icon which will correspond to marker's location
    popupAnchor:  [0, -53],// point from which the popup should open relative to the iconAnchor
});

export const sensorMarker = new L.icon({
    iconUrl: require('./img/dataPins.png'),
    iconSize:     [20, 22], // size of the icon
    iconAnchor:   [8, 22], // point of the icon which will correspond to marker's location
    popupAnchor:  [0, -18],// point from which the popup should open relative to the iconAnchor
});
export function generateSensorCircle(color) {
    return new L.icon({
        iconUrl: require(`./img/${color}_circle.png`),
        iconSize:     [14, 14], // size of the icon
        iconAnchor:   [7, 7], // point of the icon which will correspond to marker's location
        popupAnchor:  [0, -7],// point from which the popup should open relative to the iconAnchor
    });
}

export function generateMarker(html) {
    return divIcon({
        iconSize:     [60, 65], // size of the icon
        iconAnchor:   [23, 65], // point of the icon which will correspond to marker's location
        popupAnchor:  [0, -53],// point from which the popup should open relative to the iconAnchor
        html
    });
    /*return new L.icon({
        iconUrl: require(`./img/${image}`),
        iconSize:     [60, 65], // size of the icon
        iconAnchor:   [23, 65], // point of the icon which will correspond to marker's location
        popupAnchor:  [0, -53],// point from which the popup should open relative to the iconAnchor
    });*/
}

export function generateValueMaker(value) {
    return new L.DivIcon({
        iconUrl: '',
        iconSize:     [50, 54], // size of the icon
        iconAnchor:   [19, 54], // point of the icon which will correspond to marker's location
        popupAnchor:  [0, -47],// point from which the popup should open relative to the iconAnchor
        html: `<div class='pins-data'><img class="my-div-image" src="${require('./img/dataPins.png')}" width='50' height='54'/>`+
              '<span class="my-div-span">' + value + '</span></div>'
    });
}