import React, { Component } from 'react';
import PropTypes from 'prop-types';
import createClass from 'create-react-class';
import Conf from '../../../utils/Conf';
import Select from 'react-select';
import { getRequest } from '../../../utils/WebServicesManager';
import fetch from 'isomorphic-fetch';


class SelectSensors extends Component {
	
	constructor(props) {
		super(props);
		this.displayName = 'SelectSensors';
		let opt = [];
		if (props.options !== undefined && props.options.length > 0 && props.options[0] !== undefined)  {
			for (let i = 0; i < props.options.length; i += 1) {
				if (props.options[i] !== undefined) {
					opt.push({value: props.options[i]._id, label: props.options[i].name});
				}
			}
		}
		this.state = {
			multiValue: props.values ? props.values : [],
			options: opt,
			backspaceRemoves: true,
			multi: !props.notMulti,
			value: props.value,
			group: props.group,
			changeBySelection: false
		};
		this.deviceRoute = 'get';
		if (props.deviceRoute !== undefined) {
			this.deviceRoute = 'getNoInfra';
		}
		this.entity = props.entity;
		if (props.options === undefined) {
			this.getDevices();
		}
		this.returnSelection = props.updateSelection;
		
	}
	componentWillMount() {
		if (this.props.values && this.props.values !== undefined) {
			let vals = this.props.values;
			if (typeof vals[0] === 'object') {
				vals = vals.map((obj) => obj._id);
			}
			this.setState({multiValue: vals});
		}
	}
	
    componentWillReceiveProps(nextProps) {
        if (nextProps.values !== this.props.values && !this.state.changeBySelection) {
			this.setState({multiValue: nextProps.values});
			if (this.deviceRoute === 'getNoInfra') {
				this.getSelectedDevices();
			}
		}
		if (nextProps.group !== this.props.group) {
			this.setState({group: nextProps.group}, this.getDevices.bind(this));
        }
	  }
	getInitialState () {
		return {
			multiValue: [],
			options: [],
			backspaceRemoves: true,
			multi: true,
			value: undefined
		};
	}
	handleOnChange (value) {
		const { multi } = this.state;
		if (multi) {
			this.setState({ multiValue: value });
		} else {
			this.setState({ value });
		}
		if (this.returnSelection) {
			this.setState({changeBySelection: true}, () => this.returnSelection(value, this.state.models));
			
		}
	}
	getDevices() {
		const url = Conf.BaseApi + this.entity + '/' + this.deviceRoute;
		return getRequest(url, (body) => {
			if (body.success) {
				const data = body.result;
				const options = [];
				const models = {};
				for (let i = 0; i < data.length; i += 1) {
					if (this.state.group && data[i].group == this.state.group) {
						options.push({value: data[i]._id, label: data[i].name});
						models[data[i]._id] = data[i];
					} else if (!this.state.group) {
						options.push({value: data[i]._id, label: data[i].name});
						models[data[i]._id] = data[i];
					}
				}
				this.setState({ options, models });
				if (this.deviceRoute === 'getNoInfra') {
					this.getSelectedDevices();
				}
			}
		  });
	}

	getSelectedDevices() {
		this.state.multiValue.forEach(val => {
			const url = Conf.BaseApi + this.entity + '/getById/' + val;
			getRequest(url, (body) => {
				if (body.success) {
					const data = body.result;
					const options = this.state.options;
					options.push({value: data._id, label: data.name});
					this.setState({ options });
				}
			  });
		});
	}

	toggleBackspaceRemoves () {
		this.setState({
			backspaceRemoves: !this.state.backspaceRemoves
		});
	}
	render () {
		const { multi, multiValue, value } = this.state;
		const { values, propId } = this.props;
    console.log(value)
		return (
			<div className="section">
				<Select 
					multi={this.state.multi}
					value={multi ? this.state.multiValue : value}
					options={this.state.options}
					onChange={this.handleOnChange.bind(this)}
					backspaceRemoves={this.state.backspaceRemoves}
					id={propId ? propId : "sensorsName"}
					name={propId ? propId : "sensorsName"}
					/>
			</div>
		);
		}

}

SelectSensors.defaultProps = {
	notMulti: false     
}

export default SelectSensors;