import React, { Component } from 'react';
import Conf from '../../../utils/Conf';
import { Dropdown, DropdownMenu, DropdownItem, DropdownToggle, Progress } from 'reactstrap';
import $ from 'jquery';
import { getRequest, postRequest, reportGetRequest, reportPostRequest } from '../../../utils/WebServicesManager';
import { Chart, Line } from 'react-chartjs-2';
import { Min, Max, getDateStr } from '../../../utils/Utils';
import { translate } from '../../../utils/ReactMultiLang';
import { VerticalLine } from '../../../utils/ChartPlugins'
import * as ChartAnnotation from 'chartjs-plugin-annotation';

class LineChats extends Component {
  constructor(props) {
    super(props);
    this.datas = {};
    this.options = {};
    this.actualData = 0;
    this.state = {
      dropdownOpen: false,
      width: 300,
      height: 300,
      ChartKey: 0,
      Messages: props.messages ? props.messages : [],
      SortedData: props.sortedData ? props.sortedData : [],
      isPublic: props.isPublic,
      isLocked: props.isLocked,
      Alerts: [],
    };
    this.getRequest = getRequest;
    this.postRequest = postRequest;
    if (props.isPublic) {
      this.getRequest = reportGetRequest;
      this.postRequest = reportPostRequest;
    }
    this.t = props.t;
    this.minTimeBetPoints = 10 * 60; // secondes
    this.minTime = this.minTimeBetPoints * 10;
    if (!props.messages && props.sortedData) {
      this.getChartData(props.sortedData, props.chartDatas);
    } else if (!props.messages) {
      this.getMessage(props.chartDatas, props.component);
    } else {
      this.getMessagesList(this.state.Messages, props.chartDatas);
    }
  }

  getAlerts(id, from, to) {
    var url = `${Conf.BaseApi}alerts/getBetweenFromSensor`;

    const options = {
      number: 20,
      start: from,
      end: to,
      sensor: id,
    }
    return postRequest(url, options, (data) => {
      if (data.success) {
        this.setState({ Alerts: data.result }, () => this.buildAlerts());
      }
    });
  }


  buildAlerts() {
    const { Alerts } = this.state;
    this.datas.lineAtIndex = [];
    let lastTime = 100000000000000000000000000;
    for (let i = 0; i < Alerts.length; i += 1) {
      const time = new Date(Alerts[i].time).getTime() / 1000;
      if (lastTime - time < this.minTime * 2)
        continue;
      this.datas.lineAtIndex.push({ index: new Date(Alerts[i].time).getTime(), label: Alerts[i].name });
      lastTime = time;
    }
  }

  getMessagesSince(id, since) {
    var url = Conf.BaseApi + `messages/getComputed/since/${id}/${since}`;
    return this.getRequest(url, (body) => {
      if (body.success) {
        const messages = body.result;
        messages.sort((a, b) => {
          if (a.time > b.time)
            return 1;
          if (a.time < b.time)
            return -1;
          return 0;
        });
        let tmpSensorMsg = this.state.Messages;
        tmpSensorMsg[id] = messages;
        this.setState({ Messages: tmpSensorMsg, ChartKey: this.state.ChartKey + 1 });
        this.getMessagesList(tmpSensorMsg, this.props.chartDatas);
        this.updateDimensions();
      }
    });
  }

  getMessagesStatic(id, from, to) {
    var url = Conf.BaseApi + `messages/getBetween/devices`;
    return this.postRequest(url, {
      devices: [id],
      from,
      to
    }, (body) => {
      if (body.success) {
        const messages = body.result[id];
        if (messages) {
          messages.sort((a, b) => {
            if (a.time > b.time)
              return 1;
            if (a.time < b.time)
              return -1;
            return 0;
          });
          let tmpSensorMsg = this.state.Messages;
          tmpSensorMsg[id] = messages;
          this.setState({ Messages: tmpSensorMsg, ChartKey: this.state.ChartKey + 1 });
          this.getMessagesList(tmpSensorMsg, this.props.chartDatas);
          this.updateDimensions();
        }
      }
    });
  }


  getMessage(chartDatas, component) {
    if (chartDatas && chartDatas !== undefined) {
      const usedDevEUI = [];
      for (let i = 0; i < chartDatas.length; i += 1) {
        if (!usedDevEUI.includes(chartDatas[i].sensor)) {

          if (component.from) {
            const offset = navigator.webdriver ? new Date().getTimezoneOffset() : 0;
            console.log(offset)
            let from = Date.parse(component.from) / 1000;
            let to = Date.parse(component.to) / 1000;
            if (component.timeStart) {
              const hStart = parseInt((component.timeStart / 100) + (offset / 60), 10);
              const mStart = component.timeStart % 100;
              const hEnd = parseInt((component.timeEnd / 100) + (offset / 60), 10);
              const mEnd = component.timeEnd % 100;
              from += hStart * 3600 + mStart * 60;
              to += hEnd * 3600 + mEnd * 60 - (3600 * 24);
            }
            this.getMessagesStatic(chartDatas[i].sensor, from, to);
          } else {
            usedDevEUI.push(chartDatas[i].sensor);
            const dateDays = (component.days ? component.days : 1) * 24 * 60 * 60; // in seconds
            const dateMinusDays = Math.floor(Date.now() / 1000) - dateDays;
            this.getMessagesSince(chartDatas[i].sensor, dateMinusDays);
          }
          if (chartDatas[i].showAlerts) {
            let from = new Date();
            let to = new Date();
            if (component.from) {
              from = component.from;
              to = component.to;
            } else {
              const d = new Date();
              d.setDate(d.getDate() - (component.days ? component.days : 1));
              from = d;
            }
            this.getAlerts(chartDatas[i].sensor, from, to);
          }
        }
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.component && nextProps.component.dataSets
      && this.props.component && this.props.component.dataSets && (
        nextProps.component.days !== this.props.component.days
        || nextProps.component.from !== this.props.component.from
        || nextProps.component.dataSets.length !== this.props.component.dataSets.length)) {
      this.getMessage(nextProps.chartDatas, nextProps.component);
    }
    if (this.props.isPublic !== nextProps.isPublic) {
      this.setState({
        isPublic: nextProps.isPublic
      });
      if (nextProps.isPublic) {
        this.getRequest = reportGetRequest;
        this.postRequest = reportPostRequest;
      }
    }
  }

  componentDidMount() {
    this.ChartKey += 1;
    this.updateDimensions();
  }


  updateDimensions() {
    if (this.refs.mainDiv.offsetWidth + 10 < this.state.width
      || this.refs.mainDiv.offsetWidth - 10 > this.state.width
      || (this.refs.mainDiv.offsetHeight - 50 !== this.state.height && !this.props.noReport)) {
      let width = this.refs.mainDiv.offsetWidth;
      let height = this.refs.mainDiv.offsetHeight - 50;
      this.setState({ width, height, ChartKey: this.state.ChartKey + 1 });
    }
  }
  componentDidUpdate() {
    this.updateDimensions();
  }
  getMessagesList(messagesArray, chartDatas) {
    var datasArray = [];
    var labelsArray = [];
    for (let lineNumber = 0; lineNumber < chartDatas.length; lineNumber += 1) {
      const { paramName, sensor } = chartDatas[lineNumber];
      const messages = messagesArray[sensor];
      if (!messages || !paramName) continue;
      const datas = [];
      let lastTime = 0;
      const labels = [];
      this.minTime = this.props.component && this.props.component.granularity ? this.props.component.granularity * 60 : this.minTimeBetPoints * (messages.length / 100);
      for (var i = 0; i < messages.length; i++) {
        if (!messages[i] || !messages[i].data || !messages[i].data[paramName])
          continue;
        if (messages[i].time - lastTime < this.minTime)
          continue;
        var d = new Date(messages[i].time * 1000);
        labels.push(d);
        datas.push({ x: d, y: Math.trunc(parseFloat(messages[i].data[paramName]) * 10) / 10 });
        lastTime = messages[i].time;
      }
      datasArray.push(datas);
      labelsArray.push(labels);
    }
    if (datasArray.length == 0) {
      datasArray = [[0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]];
      labelsArray = [[0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]];
    }
    let minLength = 999;
    for (let i = 0; i < datasArray.length; i += 1) {
      if (minLength > datasArray[i].length)
        minLength = datasArray[i].length;
    }
    const labels = labelsArray[0].slice(0, minLength);
    /*for (let i = 0; i < datasArray.length; i += 1) {
        datasArray[i] = datasArray[i].slice(0, minLength);
    }*/
    if (datasArray[0][datasArray[0].length - 1] !== undefined)
      this.actualData = datasArray[0][datasArray[0].length - 1].y;
    this.datas = {
      labels: labels,
      datasets: [
      ]
    };
    let min = 9999;
    let max = -9999;
    for (let i = 0; i < datasArray.length; i += 1) {
      const tmpMin = Min(datasArray[i].map((elem) => elem.y));
      const tmpMax = Max(datasArray[i].map((elem) => elem.y));
      if (tmpMin < min) min = tmpMin;
      if (tmpMax > max) max = tmpMax;
      this.datas.datasets.push({
        label: chartDatas[i].label + ' ' + `(${chartDatas[i].unit})`,
        fill: false,
        lineTension: 0.2,
        backgroundColor: chartDatas[i].color,
        borderColor: chartDatas[i].color,
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: 'rgba(190,190,190,1)',
        pointBackgroundColor: '#fff',
        pointBorderWidth: 0,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: chartDatas[i].color,
        pointHoverBorderColor: 'rgba(75,75,75,0)',
        pointHoverBorderWidth: 1,
        pointRadius: 1,
        pointHitRadius: 10,
        data: datasArray[i]
      });
    }
    this.options = this.getOptions(
      min,
      max,
      this.props.component ? this.props.component.showMin : undefined,
      this.props.component ? this.props.component.showMax : undefined,
      this.props.component ? this.props.component.min : undefined,
      this.props.component ? this.props.component.max : undefined
    );
    this.setState({ ChartKey: this.state.ChartKey + 1 });
  }

  getOptions(min, max, showMin = false, showMax = false, lineMin = 0, lineMax = 0) {
    min = !showMin || min < lineMin ? min : lineMin;
    max = !showMax || max > lineMax ? max : lineMax;
    const step = Math.round(max - min) / 10;
    this.buildAlerts();
    const options = {
      legend: {
        position: 'bottom',
        display: true
      },
      layout: {
        padding: {
          left: 20,
          right: 20,
          top: 20,
          bottom: 20
        }
      },
      hover: {
        animationDuration: 0,
        mode: 'point'
      },
      animation: {
        duration: 750,
        easing: 'easeInQuad'
      }, tooltips: {
        mode: 'point',
        callbacks: {
          label: function (tooltipItem, data) {
            var d = new Date(tooltipItem.xLabel);
            var tmplabel = d.getDate() + '/' + ((d.getMonth() + 1 > 9) ? d.getMonth() + 1 : '0' + (d.getMonth() + 1)) + ' ' + d.getHours() + 'h' + ((d.getMinutes() > 9) ? d.getMinutes() : '0' + d.getMinutes());

            return tmplabel + ': ' + tooltipItem.yLabel;
          }
        },
        backgroundColor: '#FFF',
        borderColor: '#000',
        titleFontSize: 12,
        titleFontColor: '#0066ff',
        bodyFontColor: '#000',
        bodyFontSize: 14
      },
      scales: {
        yAxes: [{
          ticks: {
            min:  min - step,
            max: max + step,
            stepSize: step
          }
        }],
        xAxes: [{
          ticks: {
            // Include a dollar sign in the ticks
            source: 'auto'
          },
          type: 'time',
          position: 'bottom',
          time: {
            displayFormats: {
              hour: 'DD/MM HH:mm',
              millisecond: 'DD/MM HH:mm',
              second: 'DD/MM HH:mm',
              minute: 'DD/MM HH:mm',
              hour: 'DD/MM HH:mm',
              day: 'DD/MM HH:mm',
              week: 'DD/MM HH:mm',
              month: 'DD/MM HH:mm',
              quarter: 'DD/MM HH:mm',
              year: 'DD/MM HH:mm'
            }
          }
        }]
      },
      maintainAspectRatio: false,
    };
    if (showMin) {
      options.annotation = {
        annotations: [
          {
            type: 'line',
            mode: 'horizontal',
            scaleID: 'y-axis-0',
            value: lineMin,
            label: {
              content: `${lineMin}`,
              backgroundColor: 'rgb(255, 99, 132)',
              position: 'left',
              fontSize: 10,
              enabled: true
            },
            borderColor: 'rgb(255, 99, 132)',
            borderWidth: 2,
          }
        ]
      }
    }
    if (showMax) {
      if (!options.annotation) options.annotation = { annotations: [] };

      options.annotation.annotations.push({
        type: 'line',
        mode: 'horizontal',
        scaleID: 'y-axis-0',
        value: lineMax,
        label: {
          content: `${lineMax}`,
          backgroundColor: 'rgb(255, 99, 132)',
          position: 'left',
          fontSize: 10,
          enabled: true
        },
        borderColor: 'rgb(255, 99, 132)',
        borderWidth: 2,
      });
    }
    console.log(options);
    return options;
  }

  getChartData(sortedData, chartDatas) {
    if (!sortedData || Object.keys(sortedData).length < 1) {
      return 1;
    }
    const key = Object.keys(sortedData)[0];
    var datasArray = [];
    var labelsArray = [];
    if (datasArray.length == 0) {
      const tmpData = [];
      for (let i = 0; i < sortedData[key][0].length; i += 1) {
        var d = new Date(sortedData[key][1][i] * 1000);
        tmpData.push({ x: d, y: Math.trunc(parseFloat(sortedData[key][0][i]) * 10) / 10 });
      }
      datasArray.push(tmpData)
      labelsArray = sortedData[key][1].map(t => new Date(t * 1000));
    }
    this.datas = {
      labels: labelsArray,
      datasets: [
      ]
    };
    let min = Min(datasArray[0].map((elem) => elem.y));
    let max = Max(datasArray[0].map((elem) => elem.y));

    this.datas.datasets.push({
      label: key,
      fill: false,
      lineTension: 0.2,
      backgroundColor: chartDatas[0].color,
      borderColor: chartDatas[0].color,
      borderCapStyle: 'butt',
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: 'miter',
      pointBorderColor: 'rgba(190,190,190,1)',
      pointBackgroundColor: '#fff',
      pointBorderWidth: 0,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: chartDatas[0].color,
      pointHoverBorderColor: 'rgba(75,75,75,0)',
      pointHoverBorderWidth: 1,
      pointRadius: 1,
      pointHitRadius: 10,
      data: datasArray[0]
    });
    this.options = this.getOptions(min, max);
  }

  render() {

    const { chartDatas, changeSensor, sensorList, unit, subtitle, editComponent, deleteComponent, noButton, addLine } = this.props;
    if (this.state.Messages.length > 0) {
      this.getMessagesList(this.state.Messages, chartDatas);
    } else if (this.state.SortedData) {
      this.getChartData(this.state.SortedData, chartDatas);
    }

    const chartStyle = {
      width: this.state.width,
      height: this.state.height
    };
    return (
      <div className={"card grid-element"} ref='mainDiv' style={{ height: '100%' }}>
        <div className="card-block pb-0">
          {this.state.isPublic || this.state.isLocked ? '' : <div className="btn-group float-right">
            <Dropdown isOpen={this.state.card1} toggle={() => { this.setState({ card1: !this.state.card1 }); }}>
              <DropdownToggle onClick={() => { this.setState({ card1: !this.state.card1 }); }} className="btn active dropdown-toggle p-0" data-toggle="dropdown" aria-haspopup={true} aria-expanded={this.state.card1}>
                <i className="icon-settings"></i>
              </DropdownToggle>
              {!noButton ?
                <DropdownMenu right>
                  <DropdownItem onClick={() => { editComponent() }}><i className='icon-pencil' style={{ color: "#212121" }}></i>{this.t('Edit')}</DropdownItem>
                  <DropdownItem onClick={() => { addLine() }}><i className='icon-graph' style={{ color: "#212121" }}></i>{this.t('Component.AddCurve')}</DropdownItem>
                  <DropdownItem onClick={() => { deleteComponent() }}><i className='icon-trash' style={{ color: "#212121" }}></i>{this.t('Delete')}</DropdownItem>
                </DropdownMenu>
                : <DropdownMenu right>
                  {this.getSensorList(sensorList, changeSensor)}
                </DropdownMenu>}
            </Dropdown>
          </div>}
          {this.state.Messages.length === 1 ?
            <h4 className="mb-0">{this.actualData}{unit}</h4> : ''}
          {this.state.Messages.length !== 1 ? <h4>{subtitle}</h4> : <p>{subtitle}</p>}
        </div>
        <div className="chart-wrapper px-3"
          style={chartStyle}>
          <Line data={this.datas}
            plugins={[VerticalLine, ChartAnnotation]}
            key={this.state.ChartKey} height={this.state.height}
            options={this.options}
          />
        </div>
      </div>
    )
  }
}

export default translate(LineChats);
