import React, { Component } from 'react';
import $ from 'jquery';
import Conf from '../../../../utils/Conf';
import { getRequest, postRequest } from '../../../../utils/WebServicesManager';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import SelectGroup from '../../../../components/Selects/SelectGroup';
import SelectSensors from '../../../../components/Selects/SelectSensors';
import { translate } from '../../../../utils/ReactMultiLang';
import ImagePicker from '../../../../components/react-image-picker/react-image-picker';
import '../../../../components/react-image-picker/index.css';
import SelectAlarmModels from '../../../../components/Selects/SelectAlarmModel';
import { getUserId } from '../../../../utils/AuthServices';
import ReactTooltip from 'react-tooltip';

const imageList = ['pins-building.png', 
                    'pins-leaf.png',
                    'pins-road.png',
                    'pins-site.png',
                    'pins-water.png',
                    'pins-health.png',
                    'pins-office.png',
                    'pins-garbage.png',
                    'pins-light.png',
                    'pins-park.png'];

class AddBuilding extends Component {
  constructor(props) {
    super(props);
    this.state = {
      UserGroups: [],
      image: null,
      groupKey: 0,
      selectedGroup: props.building !== undefined ? props.building.group : undefined
    };
    this.getUserGroups();

    this.lat = props.latlng ? props.latlng.lat : 46.184347;
    this.lng = props.latlng ? props.latlng.lng : 6.125858;
    this.building = props.building;
    this.t = props.t;
    this.onPick = this.onPick.bind(this);
  }

  onPick(image) {
    this.setState({image})
  }

  getUserGroups() {
      var url = Conf.BaseApi + 'groups/getAll';
      return getRequest(url, (data) => {
        if (data.success) {
          this.setState({ UserGroups: data.result,
            selectedGroup: data.result[0] && !this.state.selectedGroup ? data.result[0]._id : this.state.selectedGroup,
            groupKey: this.state.groupKey + 1 });
        }
      });
    }

  addBuilding() {
    let imageToSend = 'pins.png';
    if (this.state.image) {
      imageToSend = this.state.image.value;
    }
    var url = Conf.BaseApi + Conf.AddBuilding;
    let groups = [];
    const inputs = $("input[name=groupsName]");
    for (let i = 0; i < inputs.length; i += 1) {
      groups.push(inputs[i].value);
    }
    let sensors = [];
    const inputsSensors = $("input[name=sensorsName]");
    for (let i = 0; i < inputsSensors.length; i += 1) {
      sensors.push(inputsSensors[i].value);
    }

    let models = [];
    const inputsModels = $("input[name=alarmModels]");
    for (let i = 0; i < inputsModels.length; i += 1) {
      models.push(inputsModels[i].value);
    }
    const building = {};
    building.name = $("#builName").val();
    building.comment = $("#builComment").val();
    const address = {};
    address.gps = [this.lng, this.lat];
    address.street = $("#builStreet").val();
    address.city = $("#builCity").val();
    address.postcode = $("#builPostcode").val();
    address.number = $("#builNumber").val();
    building.address = address;
    building.group = $("#userGroup").val();
    building.tags = groups;
    building.icon = imageToSend;
    building.sensors = sensors;
    building.alarmModels = models;
    return postRequest(url, {building}, (data) => {
      if (data.success || data.success == true) {
        if (this.props.callback) {
          this.props.callback();
        } else {
          window.location.reload();
        }
      } else {
        $("#error-window").text(data.message);
      }
    });
  }
  editBuilding(builid) {
    var url = Conf.BaseApi + Conf.EditBuilding;
    let imageToSend = 'pins.png';
    if (this.state.image) {
      imageToSend = this.state.image.value;
    }
    let groups = [];
    const inputs = $("input[name=groupsName]");
    for (let i = 0; i < inputs.length; i += 1) {
      groups.push(inputs[i].value);
    }
    let sensors = [];
    const inputsSensors = $("input[name=sensorsName]");
    for (let i = 0; i < inputsSensors.length; i += 1) {
      sensors.push(inputsSensors[i].value);
    }

    let models = [];
    const inputsModels = $("input[name=alarmModels]");
    for (let i = 0; i < inputsModels.length; i += 1) {
      models.push(inputsModels[i].value);
    }
    const building = {};
    building.id = builid;
    building.name = $("#builName").val();
    building.comment = $("#builComment").val();
    const address = {};
    address.gps = [this.lng, this.lat];
    address.street = $("#builStreet").val();
    address.city = $("#builCity").val();
    address.postcode = $("#builPostcode").val();
    address.number = $("#builNumber").val();
    building.address = address;
    building.group = $("#userGroup").val();
    building.tags = groups;
    building.sensors = sensors;
    building.alarmModels = models;
    building.icon = imageToSend;
    return postRequest(url, {building}, (data) => {
      if (data.success || data.success == true) {
        if (this.props.callback) {
          this.props.callback(builid);
        } else {
          window.location.reload();
        }
      } else {
        $("#error-window").text(data.message);
      }
    });
  }

renderUserGroup(UserGroups, building) {
    var toRender = [];
    
    for (var i = 0; i < UserGroups.length; i++) {
      if (i === 0 && !building) {
        toRender.push(
          <option key={i} value={UserGroups[i]._id} selected="selected">{UserGroups[i].name}({UserGroups[i].provider})</option>
        );
      } else {
        toRender.push(
          <option key={i} value={UserGroups[i]._id} selected={building !== undefined && building.group == UserGroups[i]._id  ? "selected" : ""}>{UserGroups[i].name}({UserGroups[i].provider})</option>
        );
      }
    }
    return toRender;
  }
  
  render() {
    const { UserGroups, building } = this.props;
    
    return (
        <ModalBody>
        <ReactTooltip multiline={true}  type='info' delayShow={250} />
        <div className="flex-row align-items-center">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-12">
                <div className="card mx-12">
                  <div className="card-block p-12">
                    <div className="input-group mb-3" style={{ color: "red" }} id="error-window">

                    </div>
                    <div className="form-group mb-3">
                      <label htmlFor="userGroup">{this.t('UserGroup')}</label>
                      <select className="form-control" key={this.state.groupKey} onChange={e => this.setState({selectedGroup: e.target.value})} id="userGroup" defaultValue={building !== undefined ? building.group : ""}>
                        {this.renderUserGroup(this.state.UserGroups, building)}
                      </select>
                    </div>
                    <div className="input-group mb-3">
                      <span className="input-group-addon"><i className="icon-tag"></i></span>
                      <input type="text" className="form-control" placeholder={this.t('Name')} id="builName"
                      defaultValue={building !== undefined ? building.name : ""}/>
                    </div>
                    <div className="input-group mb-3">
                      <span className="input-group-addon"><i className="icon-flag"></i></span>
                      <input type="text" className="form-control" placeholder={this.t('Description')} id="builComment"
                      defaultValue={building !== undefined ? building.comment : ""}/>
                    </div>
                    <div className="input-group mb-3">
                      <span className="input-group-addon"><i className="icon-wrench"></i></span>
                      <input type="text" className="form-control" placeholder={this.t('Street')} id="builStreet"
                      defaultValue={building !== undefined && building.address !== undefined ? building.address.street : ""}/>
                    </div>
                    <div className="input-group mb-3">
                      <span className="input-group-addon"><i className="icon-wrench"></i></span>
                      <input type="text" className="form-control" placeholder={this.t('StreetNumber')} id="builNumber"
                      defaultValue={building && building.address ? building.address.number : ""}/>
                    </div>
                    <div className="input-group mb-3">
                      <span className="input-group-addon"><i className="icon-wrench"></i></span>
                      <input type="text" className="form-control" placeholder={this.t('Postcode')} id="builPostcode"
                      defaultValue={building && building.address ? building.address.postcode : ""}/>
                    </div>
                    <ImagePicker 
          images={imageList.map((image, i) => ({src: `img/pins/${image}`, value: image}))}
          onPick={this.onPick} defaultSelected={building !== undefined ? building.icon : ""}
        /><br/>
                    <div className="input-group mb-3">
                      <span className="input-group-addon"><i className="icon-city"></i></span>
                      <input type="text" className="form-control" placeholder={this.t('City')} id="builCity"
                      defaultValue={building && building.address ? building.address.city : ""}/>
                    </div>
                    <label htmlFor="alarmModel">Models d'alarme</label>
                            <SelectAlarmModels model={this.state.selectedModel}
                             values={building !== undefined ? building.alarmModels : ""}/>
                      <label htmlFor="userGroup">{this.t('Tags')}<span className='tooltype-btn' data-tip={this.t('Tooltips.Infra.Tag')}>?</span></label>
                            <SelectGroup entity='buildings'
                      values={building !== undefined ? building.tags : ""}/>
                      <label htmlFor="userGroup">{this.t('Sensors')}</label>
                            <SelectSensors key={this.state.selectedGroup} entity='devices' values={building !== undefined ? building.sensors : ""} 
                              group={this.state.selectedGroup} deviceRoute='true'/>
                    <button type="button" className="btn btn-block btn-success" onClick={building === undefined ? this.addBuilding.bind(this) : this.editBuilding.bind(this, building._id)}>
                      {building === undefined ? this.t('Add') : this.t('Update')}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ModalBody>
    )
  }

}

export default translate(AddBuilding);