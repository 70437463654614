import React, { Component } from 'react';
import { Map, TileLayer, Marker, Popup } from 'react-leaflet';
import CustomMarker from './Marker/CustomMarker';
import Search from './Search/Search.js'
import { marker, marker_alert, marker_warning, generateValueMaker, generateMarker } from './Marker/Marker.js'
import { Sidebar, Tab } from './Sidebar/Sidebar';
import Conf from '../../../../utils/Conf';
import { getDateStr } from '../../../../utils/Utils';
import { getRequest, postRequest, getRequestNoCache } from '../../../../utils/WebServicesManager';
import { Modal, Row, ModalHeader } from 'reactstrap';
import { translate } from '../../../../utils/ReactMultiLang';
import { getUserId } from '../../../../utils/AuthServices';
import { check } from '../../../../utils/Can';
import AddBuilding from '../AddBuilding/AddBuilding.js';

class CustomMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      latlng: [46.200074, 6.143137],
      zoom: 12,
      collapsed: false,
      selected: 'Buildings',
      Buildings: props.buildings ? props.buildings : [],
      FilteredBuilding: props.buildings ? props.buildings : [],
      ModalAddBuild: false,
      ModalEditBuild: false,
      Event: undefined,
      Building: {},
      Tags: [],
      Groups: [],
      SelectedTag: '',
      Alerts: [],
      SelectedGroup: '',
      MapData: {},
      CanCreate: check('infra:create')
    }
    this.cpt = 0;
    const state = this.getInitialState();
    if (state.latlng) {
      this.state.latlng = state.latlng;
    } if (state.zoom) {
      this.state.zoom = state.zoom;
    }
    this.selectBuilding = props.selectBuilding;
    this.t = props.t;
    this.getTags();
    this.getAllUserGroups();
    this.myMap = React.createRef();
    if (!props.buildings) {
      this.getBuildings();
    }
    if (!props.alert) {
      this.getAlerts();
    }
    const tag = localStorage.getItem('Map-SelectedTag');
    if (tag !== '') {
      this.state.SelectedTag = tag;
    }
    const group = JSON.parse(localStorage.getItem('Map-SelectedGroup') || '{}');
    if (group !== {}) {
      this.state.SelectedGroup = group;
    }
  }

  getInitialState() {
    const state = JSON.parse(localStorage.getItem('Map') || '{}');
    if (this.state !== {}) {
      if (!state.latlng) {
        state.latlng = [46.200074, 6.143137];
      } if (!state.zoom) {
        state.zoom = 12;
      }
    }
    return state;
  }

  getAllUserGroups() {
    var url = Conf.BaseApi + 'groups/getAll';
    return getRequest(url, (data) => {
      if (data.success) {
        const userId = getUserId();
        data.result.forEach(element => {
          const user = element.users.find(e => e.id && e.id._id === userId);
        });
        this.setState({ Groups: data.result });
      }
    });
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.buildings !== nextProps.buildings || this.props.alerts !== nextProps.alerts) {
      if (nextProps.buildings) {
        this.setState({
          Buildings: nextProps.buildings, FilteredBuilding: nextProps.buildings
        });
      }
      if (nextProps.alerts) {
        this.setState({
          Alerts: nextProps.alerts
        });
      }
    }
    if (nextProps.mapData && this.props.mapData !== nextProps.mapData) {
      var url = Conf.BaseApi + 'buildings/get/data';
      postRequest(url, {
        buildings: this.props.buildings.map((b) => b._id),
        dataName: nextProps.mapData
      }, (body) => {
        if (body.success) {
          this.setState({ MapData: body.result });
        }
      });
    }
    if (nextProps.createInfra) {
      this.setState({ ModalAddBuild: true });
    }
  }
  toggle(e) {
    this.setState({ ModalAddBuild: !this.state.ModalAddBuild, Event: e });
    if (this.props.closePopup) this.props.closePopup();
  }
  toggleEdit(building) {
    this.setState({ ModalEditBuild: !this.state.ModalEditBuild, Building: building });
  }


  getTags() {
    var url = Conf.BaseApi + 'buildings/tags'
    return getRequest(url, (body) => {
      if (body.success) {
        this.setState({ Tags: body.result });
      }
    });
  }

  getBuildings() {
    let url = Conf.BaseApi + 'buildings/get';
    return getRequestNoCache(url, (data) => {
      if (data.success) {
        this.setState({ Buildings: data.result });
      }
    });
  }
  getAlerts() {
    let url = Conf.BaseApi + 'alerts/get/actives';
    return getRequest(url, (data) => {
      if (data.success && data.result.length > 0) {
        this.setState({ Alerts: data.result });
      }
    });
  }

  handleBatClick(building) {
    if (building.address !== undefined) {
      this.setState({ zoom: 18, latlng: [building.address.lat, building.address.lng] });
      if (this.selectBuilding !== undefined) {
        this.selectBuilding(building._id, building.sensors);
      }
    }
  }

  mapHandleClick = (e) => {
  }

  onClose() {
    this.setState({ collapsed: true });
  }

  onOpen(id) {
    this.setState({
      collapsed: false,
      selected: id,
    })
  }

  selectTag(e) {
    localStorage.setItem('Map-SelectedTag', e.target.value);
    this.state.SelectedTag = e.target.value;
    this.filterBuilding();
  }

  removeBuilding(building) {
    var r = window.confirm(`${this.t('Popup.Delete.Building')} ${building.name} ?`);
    if (r == true) {
      var url = Conf.BaseApi + Conf.RemoveBuilding;
      postRequest(url, {
        buildId: building._id,
        groupId: building.group
      }, (data) => {
        if (data.success && data.success == true) {
          this.getBuildings();
        }
      });
    }
  }

  editBuilding(building) {
    this.toggleEdit(building);
  }

  generateBuildingContent() {
    const toRender = [];

    //console.log(this.state.Buildings.length);
    for (let i = 0; i < this.state.FilteredBuilding.length; i++) {
      //console.log(this.state.Buildings[i]);
      if (this.state.SelectedTag === '' || (this.state.FilteredBuilding[i].tags && this.state.FilteredBuilding[i].tags.indexOf(this.state.SelectedTag) !== -1)) {
        toRender.push(<li key={i} className="buildingItem" onClick={this.handleBatClick.bind(this, this.state.FilteredBuilding[i])}>

          <div className="row">
            <div className="col-lg-12 col-md-12 col-xs-12 buildingItem" >
              <div className="desc-building">
                <div className="title">{this.state.FilteredBuilding[i].name}</div>
                <small>{this.state.FilteredBuilding[i].address.street}{" "}
                  {this.state.FilteredBuilding[i].address.city}</small>
              </div>
              <div className="value-lg">
                {this.state.FilteredBuilding[i].isAdmin ?
                  <button type="button" onClick={this.editBuilding.bind(this, this.state.FilteredBuilding[i])}
                    className="btn btn-lg btn-link text-success"><i className="icon-pencil" value={this.state.FilteredBuilding[i]._id}></i>
                  </button> : ""}
              </div>
              <div className="value-lg-end">
                {this.state.FilteredBuilding[i].isAdmin ?
                  <button type="button" onClick={this.removeBuilding.bind(this, this.state.FilteredBuilding[i])}
                    className="btn btn-lg btn-link text-danger"><i className="icon-trash" value={this.state.FilteredBuilding[i]._id}></i>
                  </button> : ""}
              </div>
            </div>
          </div>
          <hr />
        </li>);
      }
    }
    return toRender;
  }

  addBuilding(e) {
    const { readOnly } = this.props;
    if (!readOnly && this.state.CanCreate) {
      this.toggle(e);
    }
  }

  genSelection(tags) {
    const toRender = [];

    toRender.push(
      <option value='' key={99999}>-- {this.t('Tags')} --</option>
    );
    for (let i = 0; i < tags.length; i += 1) {
      toRender.push(
        <option value={tags[i]} key={i}>{tags[i]}</option>
      );
    }

    return toRender;
  }

  buildingContainAlert(building) {
    if (building && building !== undefined && this.state.Alerts.length > 0 &&
      building.sensors && building.sensors !== undefined) {
      for (let j = 0; j < this.state.Alerts.length; j += 1) {
        if (this.state.Alerts[j].infra === building._id) {
          return this.state.Alerts[j];
        }
      }
    }
    return undefined;
  }
  userGroupChange(UserGroups, event) {
    for (let i = 0; i < UserGroups.length; i += 1) {
      if (UserGroups[i]._id === event.target.value) {
        this.state.SelectedGroup = UserGroups[i];
        localStorage.setItem('InfraQuickView-SelectedGroup', JSON.stringify(UserGroups[i]));
        this.filterBuilding();
        return;
      }
    }
    localStorage.setItem('InfraQuickView-SelectedGroup', '');
    this.state.SelectedGroup = '';
    this.filterBuilding();
  }

  filterBuilding() {
    let buildings = this.state.Buildings;
    buildings = buildings.filter((b) => {
      if ((this.state.SelectedGroup === '' || b.group === this.state.SelectedGroup._id)
        && (this.state.SelectedTag === '' || (b.tags && b.tags.indexOf(this.state.SelectedTag) !== -1))) {
        return true;
      }
      return false;
    });
    this.setState({ FilteredBuilding: buildings });
  }

  renderUserGroup(UserGroups) {
    var toRender = [];
    toRender.push(
      <option key={-1} value='' selected>-- {this.t('Group')} --</option>
    );
    for (var i = 0; i < UserGroups.length; i++) {
      if (this.state.SelectedGroup._id === UserGroups[i]._id) {
        toRender.push(
          <option key={i} value={UserGroups[i]._id} selected>{UserGroups[i].name}({UserGroups[i].provider})</option>
        );
      } else {
        toRender.push(
          <option key={i} value={UserGroups[i]._id}>{UserGroups[i].name}({UserGroups[i].provider})</option>
        );
      }
    }
    return toRender;
  }

  textBuildingFilter(filter) {
    let buildings = this.state.Buildings;
    buildings = buildings.filter((b) => {
      if ((this.state.SelectedGroup === '' || b.group === this.state.SelectedGroup._id)
        && (this.state.SelectedTag === '' || (b.tags && b.tags.indexOf(this.state.SelectedTag) !== -1))) {
        return true;
      }
      return false;
    });
    this.setState({ FilteredBuilding: buildings });
  }

  render() {
    const { readOnly, mapData } = this.props;

    return (
      <div>
        <Map
          className="sidebar-map"
          center={this.state.latlng}
          zoom={this.state.zoom}
          zoomControl={false}
          onclick={this.mapHandleClick.bind(this)}
          ondblclick={this.addBuilding.bind(this)}
          doubleClickZoom={false}
          onMoveend={(e) => {
            const { viewport } = this.myMap.current;
            this.customSetState({ zoom: viewport.zoom, latlng: viewport.center });
          }}
          ref={this.myMap}>

          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          {this.state.FilteredBuilding.map((build, idx) => {
            let alert = undefined;
            alert = this.buildingContainAlert(build);
            return (build && build.address && build.address.gps ?
              <CustomMarker idx={idx} build={build} alert={alert} selectBuilding={this.selectBuilding.bind(this)}><p>Toto</p></CustomMarker> : '');
          })}
        </Map>

        <Modal isOpen={this.state.ModalAddBuild} className={'modal-md ' + this.props.className}>
          <ModalHeader>
            <Row style={{ paddingLeft: '15px', paddingRight: '15px' }}>
              <h1>{this.t('Popup.Add.Buil')}</h1>
              <i className="icon-close color-danger" onClick={this.toggle.bind(this)}
                style={{ position: 'absolute', textAlign: 'right', right: '15px', cursor: 'pointer' }}></i>
            </Row>
          </ModalHeader>
          <AddBuilding latlng={this.state.Event !== undefined ? this.state.Event.latlng : { lat: 0, lng: 0 }} onRefresh={this.getBuildings.bind(this)} />
        </Modal>

        <Modal isOpen={this.state.ModalEditBuild} className={'modal-md ' + this.props.className}>
          <ModalHeader>
            <Row style={{ paddingLeft: '15px', paddingRight: '15px' }}>
              <h1>{this.t('Popup.Edit.Buil')}</h1>
              <i className="icon-close color-danger" onClick={this.toggleEdit.bind(this)}
                style={{ position: 'absolute', textAlign: 'right', right: '15px', cursor: 'pointer' }}></i>
            </Row>
          </ModalHeader>
          <AddBuilding building={this.state.Building}
            latlng={this.state.Building !== undefined && this.state.Building.address !== undefined ?
              { lat: this.state.Building.address.lat, lng: this.state.Building.address.lng }
              : { lat: 0, lng: 0 }} />
        </Modal>
      </div>
    )
  }

  customSetState(state) {
    if ((state.zoom !== this.state.zoom || state.latlng !== this.state.latlng) && this.cpt === 0) {
      this.cpt += 1;
      setTimeout(() => { this.cpt = 0 }, 500);
      this.setState(state, () => localStorage.setItem('Map', JSON.stringify(state)));
    }
  }
}

export default translate(CustomMap);

// "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
// "https://maps.wikimedia.org/osm-intl/{z}/{x}/{y}.png"

/*


                {readOnly ? '' :
            <Sidebar id="sidebar" collapsed={this.state.collapsed} selected={this.state.selected}
                onOpen={this.onOpen.bind(this)} onClose={this.onClose.bind(this)} position='right'
                closeIcon="fa fa-close">
                <Tab id="Buildings" header={''} icon="fa fa-home">
                <div id='searchBar'>
                    <Row className='remove-margin'>
                        <Col md={6} sm={12}>
                            <select className="right-marg" id="userGroup" onChange={this.userGroupChange.bind(this, this.state.Groups)}>
                                {this.renderUserGroup(this.state.Groups)}
                            </select>
                        </Col>
                        <Col md={6} sm={12}>
                            <select onChange={this.selectTag.bind(this)} className='left-marg'>
                                {this.genSelection(this.state.Tags)}
                            </select>
                        </Col>
                    </Row>
                    <Row className='remove-margin'>
                        <Col md={12} sm={12}>
                            <input type='text'></input>
                        </Col>
                    </Row>
                </div>

                <div id='map-tabContent'>
                    <ul className='icons-list-big'>
                    {this.generateBuildingContent()}
                    </ul>
                </div>
                </Tab>
            </Sidebar>
                }

*/

/*
<Marker key={`marker-${idx}`} zIndexOffset={alert === undefined ? 300 : alert.level === 1 ? 901 : 1302}
                                icon={
                                    mapData ?
                                    generateValueMaker(this.state.MapData[build._id] !== undefined ?
                                        Math.round(this.state.MapData[build._id] * 10) / 10 : ''
                                    )
                                    :
                                    alert === undefined ?
                                        build.icon !== undefined ? generateMarker(build.icon) : marker
                                        : alert.level === 1 ? marker_warning : marker_alert
                                }
                                position={[build.address.gps[1], build.address.gps[0]]}>

                            <Popup onOpen={this.selectBuilding.bind(this, build._id, build.sensors)} className='map-popup'>
                                <span>
                                    {build.name}
                                    <br/>
                                    {build.comment}
                                    {
                                        alert !== undefined ?
                                        <ul className="icons-list">
                                        <li><br/>
                                            <small>{getDateStr(alert.time)}</small>
                                            <br/>
                                            <i className="icon-bell bg-danger"></i>
                                            {alert.name}
                                            <br/>
                                            {alert.desc}
                                            </li>
                                            <br/>
                                            <br/>
                                            <br/>
                                            </ul>
                                        :
                                            ''

                                    }
                                </span>
                            </Popup>
                            </Marker>

*/