import React, { Component } from 'react';
import { FloatingMenu as Menu, MainButton, ChildButton } from 'react-floating-button-menu';
import { translate } from '../../utils/ReactMultiLang';
import ReactTooltip from 'react-tooltip';
import { check } from '../../utils/Can';


class FloatingMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.t = props.t;
    this.AccessEnergy = check('report:energy');
  }


  render() {
    const { callbackCreate, dashboard } = this.props;

    const menuContent = [];
    let key = 0;
    menuContent.push(<ChildButton className='child-button icon-graph' key={key++}
      icon=''
      onClick={() => callbackCreate("INT-CHART")}
      data-tip={this.t('Component.Charts')}
      size={50}
      backgroundColor="white" />);
    menuContent.push(
      <ChildButton className='child-button icon-size-actual' key={key++}
        icon=''
        onClick={() => callbackCreate("INT-AVERAGE")}
        data-tip={this.t('Component.Average')}
        size={50}
        backgroundColor="white" />);
    if (!dashboard) {
      menuContent.push(<ChildButton className='child-button icon-pencil' key={key++}
        icon=''
        onClick={() => callbackCreate("TITLE")}
        data-tip={this.t('Component.Text')}
        size={50}
        backgroundColor="white" />);
      menuContent.push(<ChildButton className='child-button icon-chart' key={key++}
        icon=''
        onClick={() => callbackCreate("BAR-CHART")}
        data-tip={this.t('Component.BarCharts')}
        size={50}
        backgroundColor="white" />);
      menuContent.push(<ChildButton className='child-button icon-emotsmile' key={key++}
        icon=''
        onClick={() => callbackCreate("IMAGE")}
        data-tip={this.t('Component.Picture')}
        size={50}
        backgroundColor="white" />);
      menuContent.push(<ChildButton className='child-button icon-speedometer' key={key++}
        icon=''
        onClick={() => callbackCreate("GAUGE")}
        data-tip={this.t('Component.Gauge')}
        size={50}
        backgroundColor="white" />);
      menuContent.push(<ChildButton className='child-button icon-power' key={key++}
        icon=''
        onClick={() => callbackCreate("BOOLEAN")}
        data-tip={this.t('Component.Boolean')}
        size={50}
        backgroundColor="white" />);
      menuContent.push(<ChildButton className='child-button icon-layers' key={key++}
        icon=''
        onClick={() => callbackCreate("BOOL-LEVEL")}
        data-tip={this.t('Component.BoolLevel')}
        size={50}
        backgroundColor="white" />);
      menuContent.push(<ChildButton className='child-button icon-bell' key={key++}
        icon=''
        onClick={() => callbackCreate("ALERTS")}
        data-tip={this.t('Component.Alerts')}
        size={50}
        backgroundColor="white" />);
        if (this.AccessEnergy) {
          menuContent.push(<ChildButton className='child-button icon-power' key={key++}
              icon=''
              onClick={() => callbackCreate("ENERGY")}
              data-tip={this.t('Component.Energy')}
              size={50}
              backgroundColor="white" />);
        }
    }
    return (
      <Menu
        className="floating-menu no-print"
        slideSpeed={500}
        direction="up"
        spacing={8}
        isOpen={this.state.isOpen}
      >
        <ReactTooltip multiline={false} type='dark' effect='solid' delayShow={50} place='left' />
        <MainButton className='main-button'
          iconResting="+"
          iconActive="x"
          onClick={() => this.setState({ isOpen: !this.state.isOpen })}
          size={56}
        />
        {menuContent}
      </Menu>
    );
  }

}

export default translate(FloatingMenu);

/*

        <ChildButton
          onClick={() => callbackCreate("DOUGNUTS")}
          icon="icon-clock"
          label={this.t('Component.Donuts')}
          style={{color:"#f1f1f1"}} />
*/