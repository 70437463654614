import React, { Component } from 'react';
import { translate } from '../../../../../utils/ReactMultiLang';
import Conf from '../../../../../utils/Conf';
import { postRequest } from '../../../../../utils/WebServicesManager';
import { Popover, PopoverHeader, PopoverBody, Button } from 'reactstrap';


class Place extends Component {
  constructor(props) {
		super(props);

		let img = `img/sensors/empty.png`;
		if (props.asset.sensors) {
			let occ = props.asset.sensors.find(s => s.type.includes('OCCUPATION'));
			switch (props.asset.status) {
				case "USED":
					img = `img/sensors/toClean.png` ;
					break;
				case "DIRTY":
					img = `img/sensors/toClean.png`;
					break;
				default:
					img = `img/sensors/empty.png`;
					break;
			}
			if (occ && occ.lastMessage && occ.lastMessage.data['occupancy'] && !(occ.lastMessage.data['occupancy'] == 0 || occ.lastMessage.data['occupancy'] == "0")) {
				img = `img/sensors/inUse.png`;
			}
		}
		this.state = {
			asset: props.asset,
			position: props.position,
			popoverOpen: false,
			img
		};
		
		this.t = props.t;
	}


	dragStart(ev) {
		ev.dataTransfer.setData("application/assetId", ev.currentTarget.id.substring(4));
		ev.dataTransfer.setData("application/assetType",'assets');
		var img = document.createElement('img');
		img.src = this.state.img;
		img.width = '50';
		var div = document.createElement('div');
		div.appendChild(img);
		document.querySelector('body').appendChild(div);
		ev.dataTransfer.setDragImage(div, 25, 25);
		ev.dataTransfer.dropEffect = "move";
	}

	render() {
		let { style, className, notAssigned } = this.props;

		if (!style) {
			style = { }
		}
		return (
			<div class={`sensorBox ${className}`} id={`BTN_${this.state.asset._id}`} style={style} draggable={true} onDragStart={this.dragStart.bind(this)} >
				<img src={this.state.img} onClick={this.toggle}/>
				<Popover placement="top" isOpen={this.state.popoverOpen} target={`BTN_${this.state.asset._id}`} toggle={this.toggle}>
					<PopoverHeader>
						<a>{this.state.asset.name}</a>
					</PopoverHeader>
					<PopoverBody>
						<Button color="warning" onClick={() => this.updateStatus('DIRTY')}>{this.t('Dirty')}</Button>
						<Button color="primary" onClick={() => this.updateStatus('CLEAN')}>{this.t('Cleaned')}</Button>	
					</PopoverBody>				
				
			</Popover>

				
			</div>
		)
	}

	updateStatus(status) {
      var url = Conf.BaseURL + 'assets/editStatus';

			postRequest(url, {status, assetId: this.state.asset._id});
			this.setState({img: status === 'DIRTY' ? 'img/sensors/toClean.png' : 'img/sensors/empty.png'  });
	}
	
	toggle = () => this.setState({popoverOpen: !this.state.popoverOpen});
}

export default translate(Place);