import { NodeModel, PortModel, Toolkit, DefaultPortModel } from 'storm-react-diagrams';
import { WSAEHOSTUNREACH } from 'constants';

class ComparatorNodeModel extends NodeModel {
    constructor(name, color, picto) {
        super('comparator');
        this.addPort(new DefaultPortModel(false, Toolkit.UID(), 'sortie'));
        this.addPort(new DefaultPortModel(true, Toolkit.UID(), 'entrée 1'));
        this.addPort(new DefaultPortModel(true, Toolkit.UID(), 'entrée 2'));
        this.name = name;
        this.color = color;
        this.comparator = 'gt';
        this.picto = picto;
    }


    deSerialize(object, engine) {
        let keys = Object.keys(this.getPorts());
        while (keys.length > 0) {
            this.removePort(this.getPorts()[keys[0]]);
            keys = Object.keys(this.getPorts());
        }
        super.deSerialize(object, engine);
        this.name = object.name;
        this.color = object.color;
        this.comparator = object.comparator;
        this.picto = object.picto;
    }

    serialize() {
        return Object.assign(super.serialize(), {
            name: this.name,
            color: this.color,
            comparator: this.comparator,
            picto: this.picto
        });
    }

    getPortsIn() {
        const keys = Object.keys(this.ports);
        const toReturn = [];
        for (let i = 0; i < keys.length; i += 1) {
            if (this.ports[keys[i]].in) {
                toReturn.push(this.ports[keys[i]]);
            }
        }
        return toReturn;
    }
    getPortsOut() {
        const keys = Object.keys(this.ports);
        const toReturn = [];
        for (let i = 0; i < keys.length; i += 1) {
            if (!this.ports[keys[i]].in) {
                toReturn.push(this.ports[keys[i]]);
            }
        }
        return toReturn;
    }
    setComparator(val) {
        this.comparator = val;
    }

    getComparator() {
        return this.comparator;
    }
}

export default ComparatorNodeModel;