import React, { Component } from 'react';
import moment from 'moment';
import $ from 'jquery';
import { TimePicker } from 'react-input-moment';
import Conf from '../../../utils/Conf';
import { postRequest } from '../../../utils/WebServicesManager';
import { ButtonToolbar, ToggleButtonGroup, ToggleButton } from 'react-bootstrap';
import ComplexAlarm from '../../ComplexAlarm';


class EditComplex extends Component {
  constructor(props) {
    super(props);
  }

  sendAlarm(t, alarmDiag) {
    const { alarm, callback } = this.props;

    alarm.diagramAlarm = alarmDiag.diagramAlarm;
    var url = Conf.BaseApi + Conf.EditAlarm;
    return postRequest(url, {
      alarm: alarm
    }, (data) => {
      if (data.success || data.success == true) {
        if (callback != undefined) {
          callback();
        }
      } else {
        $("#error-window").text(data.message);
      }
    });
  }

  render() {
    const { alarm, callback } = this.props;
    return (
    <div className="flex-row align-items-center">
          <ComplexAlarm nextCallback={this.sendAlarm.bind(this)} actualDiagram={alarm.diagramAlarm}/>
    </div>
    );
  }

}

export default EditComplex;