import React, { Component } from 'react';
import {
  Dropdown, DropdownMenu, DropdownItem,
  DropdownToggle, Progress
} from 'reactstrap';
import { translate } from '../../../utils/ReactMultiLang';
import Conf from '../../../utils/Conf';
import { postRequest } from '../../../utils/WebServicesManager';
import { getDateStr } from '../../../utils/Utils';

class Alerts extends Component {
  constructor(props) {
    super(props);
    this.datas = {};
    this.options = {};
    this.state = {
      Alerts: [],
      card1: false,
      isPublic: props.isPublic,
      isLocked: props.isLocked
    }
    this.getAlerts(props.component);
    this.t = props.t;
  }

  getAlerts(component) {
    var url = `${Conf.BaseApi}alerts/getBetweenFromSensor`;

    const today = new Date();
    let start = component.from;
    if (!start) {
      start = (new Date().setDate(today.getDate() - component.days));
      start = new Date(start);
    }
    const options = {
      number: component.granularity,
      start: start,
      end: component.to,
      sensor: component.dataSets[0].sensor,
    }
    return postRequest(url, options, (data) => {
      if (data.success) {
        this.setState({ Alerts: data.result });
      }
    });
  }

  render() {

    const { component, deleteComponent, editComponent } = this.props;

    return (
      <div className={`grid-element`} ref='mainDiv' style={{ height: '100%' }}>
        <div className="card-block pb-0">

          {this.state.isPublic || this.state.isLocked ? '' :
            <div className="btn-group float-right">
              <Dropdown isOpen={this.state.card1} toggle={() => { this.setState({ card1: !this.state.card1 }); }}>
                <DropdownToggle onClick={() => { this.setState({ card1: !this.state.card1 }); }} className="btn active dropdown-toggle p-0" data-toggle="dropdown" aria-haspopup={true} aria-expanded={this.state.card1}>
                  <i className="icon-settings"></i>
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem onClick={() => { editComponent() }}><i className='icon-pencil' style={{ color: "#212121" }}></i>{this.t('Edit')}</DropdownItem>
                  <DropdownItem onClick={() => { deleteComponent() }}><i className='icon-trash' style={{ color: "#212121" }}></i>{this.t('Delete')}</DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </div>}
            <div className="card-block">
              <ul className="icons-list">
          {
            this.state.Alerts.map((a, i) => {

              var d = new Date(a.time);
              var tmplabel = d.getDate() + '/' + ((d.getMonth() > 9) ? d.getMonth() + 1 : '0' + (d.getMonth() + 1)) + '/' + d.getFullYear() + ' ' + d.getHours() + 'h' + ((d.getMinutes() > 9) ? d.getMinutes() : '0' + d.getMinutes());

              return (
              <li key={i}>
              <i className={`icon-bell bg-danger`}></i>
              <div className="desc sensorListItem">
                <div className="title">{a.name}</div>
                <small>{tmplabel} - {a.closedAt ? getDateStr(new Date(a.closedAt).getTime()) : ''}</small>
              </div>
                <div className="value">
                  <div><small>{this.t('Alarm.UserToPrevent')}:</small> {a.users.map((u, i) => <small>{u.name} {u.firstName}{i < a.users.length - 1 ? ' - ' : ''}</small>)}</div>
                  <div><small>{this.t('Sensor')}:</small> {a.sensor ? <small>{a.sensor.name} ({a.sensor.usage})</small> : ''}</div>
                </div>
            </li>);
            })
          }
          </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default translate(Alerts);
