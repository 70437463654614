import React, { Component } from 'react';
import Conf from '../../../utils/Conf';
import $ from 'jquery';
import { getRequest, postRequest } from '../../../utils/WebServicesManager';
import { Line } from 'react-chartjs-2';
import { Min, Max } from '../../../utils/Utils';

class PlotPredictionsCharts extends Component {
    constructor(props) {
        super(props);
        this.datas = {};
        this.options = {};
        this.actualData = 0; 
    }

    getPredictionList(predictions) {
        var datasArray = [];
        for (let k = 0; k < 3; k += 1)
            datasArray.push([]);
        var labels = [];
        var lastTime = 0;

        let error = 0;
        for (let i = 0; i < predictions.original.length; i += 2) {
            labels.push('' + i);
            datasArray[0].push(Math.trunc(parseFloat(predictions.original[i]) * 10) / 10);
            datasArray[1].push(Math.trunc(parseFloat(predictions.reconstruct[i]) * 10) / 10);
            datasArray[2].push(Math.abs(Math.trunc(parseFloat(predictions.error[i]) * 10) / 10));
            error += Math.abs(Math.trunc(parseFloat(predictions.error[i]) * 10) / 10);
        }
        var sum = 0;
        for( var i = 0; i < datasArray[2].length; i++ ){
            sum += datasArray[2][i];
        }
        var avg = sum/datasArray[2].length;
        var sumReal = 0;
        for( var i = 0; i < datasArray[0].length; i++ ){
            sumReal += datasArray[0][i];
        }
        var avgReal = sumReal/datasArray[0].length;
        datasArray[2] = this.parseError(datasArray[2], avg, avgReal);
        error = error / predictions.original.length;
        if (datasArray[0].length == 0) {
            datasArray = [ [0],[0],[0] ];
            labels = [0];
        }
        this.actualData = datasArray[0][datasArray[0].length - 1];
        this.datas = {
            labels: labels,
            datasets: []
        };
        for (let j = 0; j < 3; j += 1) {
            this.datas.datasets.push({
                label: 'Original',
                fill: false,
                lineTension: 0.2,
                backgroundColor: 'rgba(240,240,240,0.4)',
                borderColor: 'rgba(40,40,40,1)',
                borderCapStyle: 'butt',
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: 'miter',
                pointBorderColor: 'rgba(190,190,190,1)',
                pointBackgroundColor: '#fff',
                pointBorderWidth: 0,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: 'rgba(220,220,220,1)',
                pointHoverBorderColor: 'rgba(75,75,75,1)',
                pointHoverBorderWidth: 1,
                pointRadius: 1,
                yAxisID: 'A',
                pointHitRadius: 10,
                data: datasArray[j]
            });
        }
        this.datas.datasets[1].label = 'Reconstruct';
        this.datas.datasets[1].borderColor = 'rgba(240,120,0,1)';
        this.datas.datasets[2].label = 'error';
        this.datas.datasets[2].yAxisID = 'B';
        this.datas.datasets[2].borderColor = 'rgba(240,0,0,1)';
        this.options = {
            layout: {
                padding: {
                    left: 20,
                    right: 20,
                    top: 20,
                    bottom: 20
                }
            },
            hover: {
                animationDuration: 0
            },
            animation: {
                duration: 750,
                easing: 'easeInQuad',
                onComplete: function () {
                    var chartInstance = this.chart,
                        ctx = chartInstance.ctx;

                    ctx.textAlign = 'center';
                    ctx.textBaseline = 'bottom';
                    
                    this.data.datasets.forEach(function (dataset, i) {
                        var meta = chartInstance.controller.getDatasetMeta(i);
                        let lastDraw = -50;
                        meta.data.forEach(function (bar, index) {
                            if (bar._model.x - lastDraw > 50) {
                                var data = dataset.data[index];
                                ctx.globalCompositeOperation='destination-over';
                                ctx.fillText(data, bar._model.x, bar._model.y - 5);
                                ctx.globalCompositeOperation='source-over';
                                lastDraw = bar._model.x;
                            }
                        });
                    });
                }
            },
            legend: {
                display: false
            },
            maintainAspectRatio: false,
            scales: {
                yAxes: [{
                  id: 'A',
                  type: 'linear',
                  position: 'left',
                }, {
                  id: 'B',
                  type: 'linear',
                  position: 'right',
                  ticks: {
                    max: Math.max.apply(Math, datasArray[2]) + avgReal * 2,
                    min: 0
                  }
                }]
              }
        }
    }

    render() {
        
        const { predictions, unit, subtitle, color } = this.props;
        this.getPredictionList(predictions);
        return (
                <div className={"card animated fadeIn "}>
                    <div className="chart-wrapper px-3" style={{ height: "350px" }}>
                        <Line data={this.datas}
                            options={this.options}
                        />
                    </div>
                </div>
        );
    }

    parseError(errors, avg, avgReal) {

        const finalArray = [];
        const tmpArray = [];
        avg = avg + (avgReal / 20);

        for (let i = 0; i < errors.length; i += 1) {
            if (errors[i] < avg) {
                tmpArray.push(0);
            } else {
                tmpArray.push(Math.pow(((errors[i] - avg) - (avg / 10)), 2));
            }
        }
        /*const size = 10;
        let tmp = new Array(size).fill(0);
        for (let i = 0; i < tmpArray.length; i += 1) {
            tmp.shift();
            tmp.push(tmpArray[i]);
            var sum = 0;
            for(var j = 0; j < tmp.length; j++ ){
                sum += tmp[j];
            }
            finalArray.push(sum / size);
        }*/
        return (tmpArray)
    }
}

export default PlotPredictionsCharts;
