import React, { Component } from 'react';
import { translate } from '../../../../../utils/ReactMultiLang';
import $ from 'jquery';
import { Popover, PopoverHeader, PopoverBody } from 'reactstrap';


class Sensor extends Component {
  constructor(props) {
		super(props);

		let img = `img/sensors/icons.png`;

		this.state = {
			sensor: props.sensor,
			position: props.position,
			img,
      dataName: props.dataName
		};
		this.t = props.t;
	}

  componentWillReceiveProps(nextProps) {
    if (nextProps.dataName !== this.props.dataName) {
      this.setState({ dataName: nextProps.dataName });
    }
  }
	
	render() {
		let { style, className, sensor } = this.props;

		if (!style) {
			style = { }
		}


		return (
			<div class={`sensorBox ${className}`} id={`BTN_${this.state.sensor._id}`} style={style}>
				<img src={this.state.img} />
        {this.renderData(sensor.lastMessage)}
			</div>
		)
	}

	renderData(lastMessage) {
    return <span style={{margin: "-30px", color: "white", fontWeight: "700", fontSize: "1em"}}>{Math.round(lastMessage.data[this.state.dataName] * 10) / 10}</span>
	}
}

export default translate(Sensor);