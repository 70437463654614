import React, { Component } from 'react';
import $ from 'jquery';
import Conf from '../../../utils/Conf';
import { getRequest, postRequest } from '../../../utils/WebServicesManager';
import { Col, ModalBody, Row } from 'reactstrap';
import { translate } from '../../../utils/ReactMultiLang';


class AddDashboard extends Component {
  constructor(props) {
    super(props);
    this.t = props.t;

    this.state = {
      FieldList: [],
      isShared: props.dashboard ? props.dashboard.shared : false
    }
    this.getFieldList(this.props.sensorList);
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.dashboard && nextProps.dashboard.shared !== this.props.dashboard.shared) {
      this.setState({isShared: nextProps.dashboard.shared}); 
    } 
    if (this.props.sensorList !== nextProps.sensorList) {
      this.getFieldList(nextProps.sensorList);
    }
  }

  getFieldList(sensorList) {
    let models = [];
    if (sensorList && sensorList.length > 0) {
      models = sensorList.map(s => s.model);
    } else {
      return 0;
    }
    
      var url = Conf.BaseApi + `helpers/getModelsData/all`;
      return postRequest(url, {models}, (result) => {
        if (result && result.success) {
          this.setState({ FieldList: result.result });
        }
      });
  }

  addDash(buildId) {
    var url = Conf.BaseApi + 'building/dashboard/add';

    const dash = {
      shared: $('#sharedField').is(':checked'),
      id: $("#idField").val(),
      password: $("#passwordField").val(),
      lowLevel: $("#lowLevelField").val(),
      midLevel: $("#midLevelField").val(),
      alarmLevel: $("#alarmLevelField").val(),
      name: $("#nameField").val(),
      data: $("#dataField").val(),
      unit: $("#unitField").val(),
      building: buildId
    };
    return postRequest(url, dash, (data) => {
      if (data.success || data.success == true) {
        if (this.props.callback) {
          this.props.callback();
        } else {
          window.location.reload();
        }
      } else {
        $("#error-window-url").text(data.message);
      }
    });
  }
  editDash(dashId) {
    var url = Conf.BaseApi + 'building/dashboard/edit';

    const dash = {
      shared: $('#sharedField').is(':checked'),
      id: $("#idField").val(),
      password: $("#passwordField").val(),
      lowLevel: $("#lowLevelField").val(),
      midLevel: $("#midLevelField").val(),
      alarmLevel: $("#alarmLevelField").val(),
      name: $("#nameField").val(),
      data: $("#dataField").val(),
      unit: $("#unitField").val(),
      _id: dashId
    };
    return postRequest(url, dash, (data) => {
      if (data.success || data.success == true) {
        if (this.props.callback) {
          this.props.callback();
        } else {
          window.location.reload();
        }
      } else {
        $("#error-window-url").text(data.message);
      }
    });
  }

  renderFields(fields, dashboard) {
    var toRender = [];
    for (var i = 0; i < fields.length; i++) {
      if (dashboard && dashboard.data === fields[i]) {
        toRender.push(
          <option value={fields[i]} key={i} selected>{this.t(`SensorData.${fields[i]}`)}</option>
        );
      } else {
        toRender.push(
          <option value={fields[i]} key={i}>{this.t(`SensorData.${fields[i]}`)}</option>
        );
      }
    }
    return toRender;
  }

  SharedChange() {
    this.setState({isShared: $("#sharedField").is(':checked')});
  }
  
  render() {
    const { dashboard, buildingId } = this.props;

    return (
    <ModalBody>
      <div className="flex-row align-items-center">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12">
              <div className="card mx-12">
                <div className="card-block p-12">
                  <p className="text-muted">{this.t('BuilDash.Add')}</p>
                  <div className="input-group mb-3" style={{ color: "red" }} id="error-window-url">

                  </div>
                  <div className="input-group mb-3">
                    <span className="input-group-addon"><i className="icon-usergroup"></i></span>
                    <input type="text" className="form-control" placeholder={this.t('Name')} id="nameField" defaultValue={dashboard !== undefined ? dashboard.name : '' }/>
                  </div>
                  <Row>
                    <Col md='4' sm='12'>
                      <label htmlFor="lowLevelField">{this.t('AlertUnder')}</label>
                      <div className="input-group mb-3">
                        <input type="number" className="form-control" id="lowLevelField" defaultValue={dashboard !== undefined ? dashboard.lowLevel : 17 }/>
                      </div>
                    </Col>
                    <Col md='4' sm='12'>
                      <label htmlFor="midLevelField">{this.t('WarningUpper')}</label>
                      <div className="input-group mb-3">
                        <input type="number" className="form-control" id="midLevelField" defaultValue={dashboard !== undefined ? dashboard.midLevel : 28 }/>
                      </div>
                    </Col>
                    <Col md='4' sm='12'>
                      <label htmlFor="alarmLevelField">{this.t('AlertUpper')}</label>
                      <div className="input-group mb-3">
                        <input type="number" className="form-control" id="alarmLevelField" defaultValue={dashboard !== undefined ? dashboard.alarmLevel : 30 }/>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md='6' sm='12'>
                      <label htmlFor="alarmLevelField">{this.t('Unit')}</label>
                      <div className="input-group mb-3">
                        <input type="text" className="form-control" placeholder={this.t('Unit')} id="unitField" defaultValue={dashboard !== undefined ? dashboard.unit : '' }/>
                      </div>
                    </Col>
                    <Col md='6' sm='12'>
                      <label htmlFor="dataField">{this.t('Dataname')}</label>
                    <select className="form-control" id="dataField" defaultValue={dashboard !== undefined ? dashboard.data : ''}>
                      {this.renderFields(this.state.FieldList, dashboard)}
                    </select>
                    </Col>
                  </Row>
                  <div className="input-group mb-3">
                    <div className="form-check form-check-inline">
                      <label className="form-check-label">
                        <input className="form-check-input" type="checkbox" id="sharedField" value="true" onChange={() => this.SharedChange()}
                          defaultChecked={dashboard !== undefined ? dashboard.shared : false}/> 
                        {this.t('Dashboard.Shared')}
                      </label>
                    </div>
                  </div>
                  { this.state.isShared ?    
                  <Row>
                    <Col md='6' sm='12'>
                        <label htmlFor="alarmLevelField">{this.t('Dashboard.Id')}</label>
                      <div className="input-group mb-3">
                        <input type="text" className="form-control" placeholder={this.t('Dashboard.Id')} id="idField" defaultValue={dashboard !== undefined ? dashboard.id : '' }/>
                      </div>
                    </Col>
                    <Col md='6' sm='12'>
                        <label htmlFor="alarmLevelField">{this.t('Password')}</label>
                      <div className="input-group mb-3">
                        <input type="text" className="form-control" placeholder={this.t('Password')} id="passwordField" defaultValue={dashboard !== undefined ? dashboard.password : '' }/>
                      </div>
                    </Col>
                  </Row>
                  : ''}

                  <button type="button" className="btn btn-block btn-success" 
                    onClick={dashboard !== undefined ? this.editDash.bind(this, dashboard._id) : this.addDash.bind(this, buildingId)}>
                      {dashboard !== undefined ? this.t('Edit') : this.t('Add')}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ModalBody>
    )
  }

}

export default translate(AddDashboard);