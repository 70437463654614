import React, { Component } from 'react';
import Conf from '../../../utils/Conf';
import { getRequest, postRequest, reportGetRequest } from '../../../utils/WebServicesManager';
import LineChats from '../../../components/Charts/LineChats';
import BarChats from '../../../components/Charts/BarChats';
import GPSMap from '../../../components/Charts/GPSMap';
import Dougnuts from '../../../components/Charts/Dognuts';
import Gauge from '../../../components/Charts/Gauge';
import OpenClose from '../../../components/Charts/OpenClose';
import BoolLevel from '../../../components/Charts/BoolLevel';
import LineAverage from '../../../components/Charts/LineAverage';
import Title from '../../../components/Charts/Title';
import Image from '../../../components/Charts/Image';
import FloatingMenu from '../../../components/FloatingMenu';
import EditComponent from '../../../components/ReportModals/EditComponent';
import CreatComponent from '../../../components/ReportModals/CreatComponent';
import CreateImage from '../../../components/ReportModals/CreateImage';
import AddDataSet from '../../../components/ReportModals/AddDataSet';
import { Modal, ModalHeader, ModalBody, ModalFooter, Row } from 'reactstrap';
import { withSize } from 'react-sizeme';
import ResponsiveLayout from './ResponsiveLayout';
import { translate } from '../../../utils/ReactMultiLang';
import { getDateStr } from '../../../utils/Utils';
import Alerts from '../../../components/Charts/Alerts/Alerts';
import Energy from '../../../components/Charts/Energy/Energy';

const withSizeHOC = withSize()
const ResponsiveGridLayout = withSizeHOC(ResponsiveLayout);

class Report extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Devices: [],
      SensorMessages: {},
      Report: props.report,
      ModalComponent: false,
      ModalCreate: false,
      ModalAddDataSet: false,
      CompoType: "",
      ActComponent: {},
      key: 0,
      isVisible: props.isVisible,
      isPublic: props.isPublic,
      isLocked: props.isLocked,
      ReportKey: 0,
      isTimed: props.report && props.report.useGlobal,
      StartTime: props.report ? props.report.from : null,
      EndTime: props.report ? props.report.to : null,
      Duration: props.report ? props.report.days : 0,
    };

    this.getRequest = getRequest;
    if (props.isPublic) {
      this.getRequest = reportGetRequest;
    }
    this.updateData(props.report);
    this.t = props.t;
  }

  componentDidMount() {
    this.setState({ key: this.state.key + 1 })
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.isVisible !== nextProps.isVisible) {
      this.setState({
        isVisible: nextProps.isVisible
      });
    }
    if (this.props.isPublic !== nextProps.isPublic) {
      this.setState({
        isPublic: nextProps.isPublic
      });
      if (nextProps.isPublic) {
        this.getRequest = reportGetRequest;
      }
    }
    if (this.props.isLocked !== nextProps.isLocked) {
      this.setState({
        isLocked: nextProps.isLocked
      });
    }
    this.setState({
      Report: nextProps.report,
      isTimed: nextProps.report && nextProps.report.useGlobal,
      StartTime: nextProps.report ? nextProps.report.from : null,
      EndTime: nextProps.report ? nextProps.report.to : null,
      Duration: nextProps.report ? nextProps.report.days : 0,
    });
  }

  toggleEditComponent(col) {
    if (!col) col = {};
    this.state.ActComponent = col;
    this.setState({
      ModalComponent: !this.state.ModalComponent
    });
  }
  toggleAddDataSet(col) {
    if (col === undefined) col = {};
    this.setState({
      ActComponent: col,
      ModalAddDataSet: !this.state.ModalAddDataSet
    });
  }
  toggleCreateComponent(type) {
    this.setState({
      CompoType: type,
      ModalCreate: !this.state.ModalCreate
    });
  }

  getMessagesList(cpId, id, since) {
    var url = Conf.BaseApi + `messages/getComputed/since/${id}/${since}`;
    return this.getRequest(url, (body) => {
      if (body.success) {
        const messages = body.result;
        messages.sort((a, b) => {
          if (a.time > b.time)
            return 1;
          if (a.time < b.time)
            return -1;
          return 0;
        });
        let tmpSensorMsg = this.state.SensorMessages;
        tmpSensorMsg[cpId + id] = messages;
        this.setState({ SensorMessages: tmpSensorMsg });
      }
    });
  }

  updateData(report) {
    if (report && report !== undefined && report.components) {
      const cpId = [];
      const sensors = [];
      const nbMessage = [];
      for (let i = 0; i < report.components.length; i += 1) {
        if (report.components.type !== 'GAUGE'
          && report.components.type !== 'BOOLEAN'
          && report.components.type !== 'INT-AVERAGE'
          && report.components.type !== 'INT-CHART'
          && report.components.type !== 'BAR-CHART') {
          for (let j = 0; j < report.components[i].dataSets.length; j += 1) {
            cpId.push(report.components[i]._id);
            sensors.push(report.components[i].dataSets[j].sensor);
            nbMessage.push(report.components[i].days ? report.components[i].days : 1);
          }
        }
      }
      for (let i = 0; i < sensors.length; i += 1) {
        const dateDays = nbMessage[i] * 24 * 60 * 60; // in seconds
        const dateMinusDays = Math.floor(Date.now() / 1000) - dateDays;
        this.getMessagesList(cpId[i], sensors[i], dateMinusDays)
      }
    }
  }

  renderBarChart(col, dataSet) {
    const toRender = [];
    col.layout.i = col._id;
    toRender.push(
      <div key={col._id} id={col._id} data-grid={col.layout}>
        <BarChats isLocked={this.state.isLocked} isPublic={this.state.isPublic} component={col} chartDatas={col.dataSets}
          key={this.state.ReportKey}
          subtitle={col.name}
          unit={dataSet.unit}
          addLine={this.toggleAddDataSet.bind(this, col)}
          editComponent={this.toggleEditComponent.bind(this, col)}
          deleteComponent={this.deleteComponent.bind(this, col)}
          noButton={false} />
      </div>);
    return toRender;
  }

  renderChart(col, dataSet) {
    const toRender = [];
    col.layout.i = col._id;
    toRender.push(
      <div key={col._id} id={col._id} data-grid={col.layout}>
        <LineChats isLocked={this.state.isLocked} isPublic={this.state.isPublic} component={col} chartDatas={col.dataSets}
          key={this.state.ReportKey}
          subtitle={col.name}
          unit={dataSet.unit}
          addLine={this.toggleAddDataSet.bind(this, col)}
          editComponent={this.toggleEditComponent.bind(this, col)}
          deleteComponent={this.deleteComponent.bind(this, col)}
          noButton={false} />
      </div>);
    return toRender;
  }

  renderAverage(col, dataSet) {
    const toRender = [];
    col.layout.i = col._id;
    toRender.push(
      <div key={col._id} id={col._id} data-grid={col.layout}>
        <LineAverage isLocked={this.state.isLocked} isPublic={this.state.isPublic}
          chartDatas={col.dataSets}
          paramName={dataSet.paramName}
          component={col}
          color={dataSet.color}
          maxData="45"
          title={dataSet.label}
          dataSigle={dataSet.unit}
          editComponent={this.toggleEditComponent.bind(this, col)}
          deleteComponent={this.deleteComponent.bind(this, col)} />
      </div>);
    return toRender;
  }

  renderEnergy(col, dataSet) {
    const toRender = [];
    col.layout.i = col._id;
    toRender.push(
      <div key={col._id} id={col._id} data-grid={col.layout}>
        <Energy isLocked={this.state.isLocked} isPublic={this.state.isPublic}
          chartDatas={col.dataSets}
          component={col}
          editComponent={this.toggleEditComponent.bind(this, col)}
          deleteComponent={this.deleteComponent.bind(this, col)} />
      </div>);
    return toRender;
  }

  renderAlerts(col, dataSet) {
    const toRender = [];
    col.layout.i = col._id;
    toRender.push(
      <div key={col._id} id={col._id} data-grid={col.layout}>
        <Alerts
          component={col}
          editComponent={this.toggleEditComponent.bind(this, col)}
          deleteComponent={this.deleteComponent.bind(this, col)} />
      </div>);
    return toRender;
  }

  renderDougnuts(col, dataSet) {
    const toRender = [];
    if (this.state.SensorMessages[col._id + dataSet.sensor] !== undefined) {
      col.layout.i = col._id;
      toRender.push(
        <div key={col._id} id={col._id} data-grid={col.layout}>
          <Dougnuts
            title={dataSet.label}
            deleteComponent={this.deleteComponent.bind(this, col)} />
        </div>);
    }
    return toRender;
  }
  renderGauge(col, dataSet) {
    const toRender = [];
    col.layout.i = col._id;
    toRender.push(
      <div key={col._id} id={col._id} data-grid={col.layout}>
        <Gauge isLocked={this.state.isLocked} isPublic={this.state.isPublic} sensor={dataSet.sensor}
          layout={col.layout}
          gId={col._id}
          color={col.color}
          title={col.name}
          colors={col.colors}
          unit={dataSet.unit}
          min={col.min}
          max={col.max}
          dataName={dataSet.paramName}
          editComponent={this.toggleEditComponent.bind(this, col)}
          deleteComponent={this.deleteComponent.bind(this, col)} />
      </div>);
    return toRender;
  }
  renderOpenClose(col, dataSet) {
    const toRender = [];
    col.layout.i = col._id;
    toRender.push(
      <div key={col._id} id={col._id} data-grid={col.layout}>
        <OpenClose isLocked={this.state.isLocked} isPublic={this.state.isPublic} sensor={dataSet.sensor}
          gId={col._id}
          title={col.name}
          dataName={dataSet.paramName}
          editComponent={this.toggleEditComponent.bind(this, col)}
          deleteComponent={this.deleteComponent.bind(this, col)} />
      </div>);
    return toRender;
  }
  renderMap(col, dataSet) {
    const toRender = [];
    if (this.state.SensorMessages[col._id + dataSet.sensor] !== undefined) {
      for (let i = 0; i < col.dataSets.length; i += 1) {
        if (this.state.SensorMessages[col._id + col.dataSets[i].sensor] !== undefined) {
          col.dataSets[i].datas = this.state.SensorMessages[col._id + col.dataSets[i].sensor];
        }
      }
      col.layout.i = col._id;
      toRender.push(
        <div key={col._id} id={col._id} data-grid={col.layout}>
          <GPSMap isLocked={this.state.isLocked} networks={false} isPublic={this.state.isPublic} DataSets={col.dataSets} readyToRender={this.state.isVisible} />
        </div>);
    }
    return toRender;
  }
  renderTitle(col, ispublic) {
    const toRender = [];
    col.layout.i = col._id;
    toRender.push(
      <div key={col._id} id={col._id} data-grid={col.layout}>
        <Title isLocked={this.state.isLocked} isPublic={ispublic || this.state.isPublic} size={col.days} color={col.color} title={col.name}
          editComponent={this.toggleEditComponent.bind(this, col)} printBreaker={col.printBreaker}
          deleteComponent={this.deleteComponent.bind(this, col)} />
      </div>);
    return toRender;
  }

  renderImage(col) {
    const toRender = [];
    let buffer = null;
    if (col.image) {
      buffer = new Buffer(col.image.data ? col.image.data : col.image);
      col.layout.i = col._id;
      toRender.push(
        <div key={col._id} id={col._id} data-grid={col.layout}>
          <Image isLocked={this.state.isLocked} isPublic={this.state.isPublic} buffer={buffer}
            deleteComponent={this.deleteComponent.bind(this, col)} />
        </div>);
    }
    return toRender;
  }
  renderBoolLevel(col, dataSets) {
    const toRender = [];
    col.layout.i = col._id;
    toRender.push(
      <div key={col._id} id={col._id} data-grid={col.layout}>
        <BoolLevel isLocked={this.state.isLocked} isPublic={this.state.isPublic} sensor={dataSets[0].sensor}
          gId={col._id}
          color={col.color}
          title={col.name}
          dataSets={dataSets}
          editComponent={this.toggleEditComponent.bind(this, col)}
          deleteComponent={this.deleteComponent.bind(this, col)} />
      </div>);
    return toRender;
  }

  renderDynamicComponent(report) {
    var toRender = [];
    if (!report || report === undefined || !report.components)
      return toRender;


    if (this.state.isTimed) {
      const timeTitle = {
        _id: "UseGlobalReport",
        layout: {
          w: 6,
          h: 1,
          x: 0,
          y: 0
        },
        name: "Time",
        color: "#000",
        days: 20
      };
      if (this.state.StartTime && this.state.EndTime) {
        const start = new Date(this.state.StartTime);
        const end = new Date(this.state.EndTime);
        timeTitle.name = `${this.t('Report.On')} ${this.t('Period')}: ${getDateStr(start.getTime(), true)} - ${getDateStr(end.getTime(), true)}`;
      } else {
        timeTitle.name = `${this.t('Report.On')} ${this.state.Duration} ${this.t('Days')}.`;
      }
      toRender.push(this.renderTitle(timeTitle, true));
    }
    for (let i = 0; i < report.components.length; i += 1) {
      if (report.components[i] && report.components[i].type !== undefined
        && ((report.components[i].dataSets && report.components[i].dataSets.length > 0) || report.components[i].type == "IMAGE")) {

        if (this.state.isTimed &&
          (report.components[i].type === "BAR-CHART" || report.components[i].type === "INT-AVERAGE" || report.components[i].type === "INT-CHART")) {
          report.components[i].from = this.state.StartTime;
          report.components[i].to = this.state.EndTime;
          report.components[i].days = this.state.Duration;
        }
        switch (report.components[i].type) {
          case "GAUGE":
            toRender.push(this.renderGauge(report.components[i], report.components[i].dataSets[0]));
            break;
          case "BOOLEAN":
            toRender.push(this.renderOpenClose(report.components[i], report.components[i].dataSets[0]));
            break;
          case "BOOL-LEVEL":
            toRender.push(this.renderBoolLevel(report.components[i], report.components[i].dataSets));
            break;
          case "IMAGE":
            toRender.push(this.renderImage(report.components[i]));
            break;
          case "TITLE":
            toRender.push(this.renderTitle(report.components[i]));
            break;
          case "DOUGNUTS":
            toRender.push(this.renderDougnuts(report.components[i], report.components[i].dataSets[0]));
            break;
          case "INT-AVERAGE":
            toRender.push(this.renderAverage(report.components[i], report.components[i].dataSets[0]));
            break;
          case "INT-CHART":
            toRender.push(this.renderChart(report.components[i], report.components[i].dataSets[0]));
            break;
          case "BAR-CHART":
            toRender.push(this.renderBarChart(report.components[i], report.components[i].dataSets[0]));
            break;
          case "MAP":
            toRender.push(this.renderMap(report.components[i], report.components[i].dataSets[0]));
            break;
          case "ALERTS":
            toRender.push(this.renderAlerts(report.components[i], report.components[i].dataSets[0]));
            break;
            case "ENERGY":
              toRender.push(this.renderEnergy(report.components[i]));
              break;
          default:
            toRender.push(this.renderChart(report.components[i], report.components[i].dataSets[0]));
            break;

        }
      }
    }
    return toRender;
  }

  updateComponent(component) {
    var url = Conf.BaseApi + 'reports/components/update';
    return postRequest(url, {
      reportId: this.state.Report._id,
      component: component
    }, (result) => {
      if (result.success === true) {
        this.setState({ Report: result.result, ReportKey: this.state.ReportKey + 1 });
        this.updateData(result.result);
        this.toggleEditComponent().bind(this);
      }
    });
  }
  addDataSet(componentId, dataSet) {
    var url = Conf.BaseApi + 'reports/components/add/dataSet';
    return postRequest(url, {
      reportId: this.state.Report._id,
      colId: componentId,
      dataSet
    }, (result) => {
      if (result.success === true) {
        this.setState({ Report: result.result });
        this.updateData(result.result);
        this.toggleAddDataSet();
      }
    });
  }

  createComponent(component) {
    var url = Conf.BaseApi + 'reports/components/add';
    return postRequest(url, {
      reportId: this.state.Report._id,
      component: component
    }, (result) => {
      if (result.success === true) {
        this.setState({ Report: result.result });
        this.updateData(result.result);
        this.toggleCreateComponent();
      }
    });
  }


  deleteComponent(component) {
    var url = Conf.BaseApi + 'reports/components/delete';
    const r = window.confirm(this.t('Popup.Delete.Component') + component.label + ' ?');
    if (r) {
      return postRequest(url, {
        reportId: this.state.Report._id,
        componentId: component._id
      }, (result) => {
        if (result.success === true) {
          this.setState({ Report: result.result });
          this.forceUpdate();
        }
      });
    }
  }

  onResize(layouts) {
    var url = Conf.BaseApi + 'reports/components/update/layout';
    
    return postRequest(url, {
      reportId: this.state.Report._id,
      layout: layouts
    }, (result) => {
      if (result.success === true) {
        if (this.props.onUpdateLayout) {
          this.props.onUpdateLayout(result.result);
        }
        this.setState({ Report: result.result });
      }
    });
  }

  render() {
    const { id, width } = this.props;
    return (
      <div id={id} style={{ width }}>
        {!this.state.isPublic && !this.state.isLocked ? <FloatingMenu callbackCreate={this.toggleCreateComponent.bind(this)} /> : ''}
        {!this.state.isPublic && !this.state.isLocked ? <ResponsiveGridLayout
          onResize={this.onResize.bind(this)}
          key={this.state.key}
          layoutContent={this.renderDynamicComponent(this.state.Report)} />
          : <ResponsiveGridLayout isPublic key={this.state.ReportKey}
            layoutContent={this.renderDynamicComponent(this.state.Report)} />
        }
        <Modal isOpen={this.state.ModalComponent} className={'modal-md ' + this.props.className}>
          <ModalHeader>
            <Row style={{ paddingLeft: '15px', paddingRight: '15px' }}>
              <h1>{this.t('Component.Edit')}</h1>
              <i className="icon-close color-danger" onClick={this.toggleEditComponent.bind(this)}
                style={{ position: 'absolute', textAlign: 'right', right: '15px', cursor: 'pointer' }}></i>
            </Row>
          </ModalHeader>
          <ModalBody>
            <EditComponent component={this.state.ActComponent} callback={this.updateComponent.bind(this)} />
          </ModalBody>
        </Modal>

        <Modal isOpen={this.state.ModalCreate} className={'modal-md ' + this.props.className}>
          <ModalHeader>
            <Row style={{ paddingLeft: '15px', paddingRight: '15px' }}>
              <h1>{this.t('Component.Add')}</h1>
              <i className="icon-close color-danger" onClick={this.toggleCreateComponent.bind(this)}
                style={{ position: 'absolute', textAlign: 'right', right: '15px', cursor: 'pointer' }}></i>
            </Row>
          </ModalHeader>
          <ModalBody>
            {this.state.CompoType === "IMAGE" ?
              <CreateImage callback={this.createComponent.bind(this)} /> :
              <CreatComponent callback={this.createComponent.bind(this)} type={this.state.CompoType} />
            }
          </ModalBody>
        </Modal>

        <Modal isOpen={this.state.ModalAddDataSet} className={'modal-md ' + this.props.className}>
          <ModalHeader>
            <Row style={{ paddingLeft: '15px', paddingRight: '15px' }}>
              <h1>{this.t('Component.AddDataSet')}</h1>
              <i className="icon-close color-danger" onClick={this.toggleAddDataSet.bind(this)}
                style={{ position: 'absolute', textAlign: 'right', right: '15px', cursor: 'pointer' }}></i>
            </Row>
          </ModalHeader>
          <ModalBody>
            <AddDataSet callback={this.addDataSet.bind(this, this.state.ActComponent._id)} />
          </ModalBody>
        </Modal>
      </div>
    )
  }
}

export default translate(Report);
