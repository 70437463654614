import React, { Component } from 'react';
import Conf from '../../../../../utils/Conf';
import { getRequest } from '../../../../../utils/WebServicesManager';
import { Modal, Col, Card, Row } from 'reactstrap';
import { translate } from '../../../../../utils/ReactMultiLang';
import { Line } from 'react-chartjs-2';
import { Min, Max, getDateStr } from '../../../../../utils/Utils';

class Room extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      selectedData: undefined,
      dataList: []
    };
    this.t = props.t;
    this.getData(props);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.room._id !== this.props.room._id) {
      this.setState({data: {}, selectedData: undefined});
      this.getData(nextProps);
    }
  }

  selectData(selectedData) {
    if (selectedData) {
      this.setState({ selectedData }, this.getDataList.bind(this));
    } else {
      this.setState({ selectedData });
    }
  }

  getDataList() {
    const id = this.props.room._id;

    const url = Conf.BaseApi + 'rooms/getSelectedData/' + id + '/' + this.state.selectedData +'/10';

    getRequest(url, (data) => {
      if (data.success) {
        this.setState({dataList: data.result});
      }
    });
  }

  getData(props) {
    const id = props.room._id;

    const url = Conf.BaseApi + 'rooms/getData/' + id;

    getRequest(url, (data) => {
      if (data.success) {
        this.setState({data: data.result});
      }
    });
  }

  render() {
    const { room, editRoom, removeRoom, isAdmin } = this.props;

    return (
    <Col md='4'>
    <Col md='12' className="room-cell">
      <p><a href={`#/room/detail?roomId=${room._id}`}>{room.name}</a>{'      '}
      <div>{isAdmin ?
            <button type="button" onClick={editRoom}
              className="btn btn-lg btn-link text-success"><i className="icon-pencil"></i>
            </button> : ""}
          {isAdmin ?
            <button type="button" onClick={removeRoom}
              className="btn btn-lg btn-link text-danger"><i className="icon-trash"></i>
            </button> : ""}</div>
      </p>
      <p><small>{room.sensors ? room.sensors.length : '0'} Capteurs</small></p>
      <div className='room-data-div'>
        { this.state.selectedData ?
          this.generateGraph(this.state.dataList) : this.generateRows(this.state.data) }
      </div>
      </Col>
      </Col>

    )
  }

  generateGraph(dataList) {
    const toRender = [];

    let data = undefined;
    if (dataList && dataList.length > 0) {
      dataList = dataList.reverse();
      const datasArray = dataList.map(d => parseInt(d.data * 100, 10) / 100);
      const labelsArray = dataList.map(d => getDateStr(d.time));
      data = {
        labels: labelsArray,
        datasets: [
          {
            label: 'My First dataset',
            fill: false,
            lineTension: 0.4,
            backgroundColor: 'rgba(75,192,192,0.4)',
            borderColor: 'rgba(75,192,192,1)',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: 'rgba(75,192,192,1)',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: 'rgba(75,192,192,1)',
            pointHoverBorderColor: 'rgba(220,220,220,1)',
            pointHoverBorderWidth: 2,
            pointRadius: 2,
            pointHitRadius: 10,
            data: datasArray
          }
        ]
      };
    }
    const options = {
      layout: {
          padding: {
              left: 0,
              right: 10,
              top: 10,
              bottom: 0
          }
      },
      hover: {
          animationDuration: 0,
          mode: 'point'
      },
      tooltips: {
        enabled: false
      },
      animation: {
          duration: 750,
          easing: 'easeInQuad',
          onComplete: function () {
            var chartInstance = this.chart,
                ctx = chartInstance.ctx;

            ctx.textAlign = 'center';
            ctx.textBaseline = 'bottom';
            ctx.fillStyle = '#777';
            
            this.data.datasets.forEach(function (dataset, i) {
                var meta = chartInstance.controller.getDatasetMeta(i);
                meta.data.forEach(function (bar, index) {
                  var data = dataset.data[index];
                  if (index % 3 == 0) {
                    ctx.fillText(data, bar._model.x, bar._model.y - 5);
                  } else {
                  ctx.fillText(data, bar._model.x, bar._model.y + 20);
                  }
                });
            });
        }
      },
      legend: {
          display: false
      },
      scales: {
          yAxes: [{
            gridLines: {
              drawBorder: false,
                display:false
            },
              ticks: {
                display: false
              }
          }],
          xAxes: [{
            gridLines: {
              drawBorder: false,
                display:false
            },
              ticks: {
                display: false
              }
          }]
      },
      maintainAspectRatio: false
    };

    toRender.push(<Col md={12} className="data-graph">
      <p>{this.t(`SensorData.${this.state.selectedData}`)}</p>
      { data ? <Line data={data} options={options} className='data-line'></Line> : ''}
      { data ? <i className="icon-close color-danger" onClick={this.selectData.bind(this, undefined)}
        style={{ position: 'absolute', right: '0', cursor: 'pointer', zIndex: '10' }}></i> : ''}
    </Col>);

    return toRender;
  }

  generateRows(datas) {
    const toRender = [];

    let tmpRender = [];
    let addSize = false;
    let addPlaces = false;
    
    const keys = Object.keys(datas);
    for (let i = 0; i < keys.length; i += 1) {
      tmpRender.push(<Col md='6' className='content' onClick={this.selectData.bind(this, keys[i])}><p>
        {datas[keys[i]]}</p></Col>);
      if ((i + 1) % 2 === 0) {
        toRender.push(<Row>{tmpRender}</Row>);
        tmpRender = [];
      }
    }
    while(tmpRender.length < 2) {
      if (!addSize && this.props.room.size) {
        tmpRender.push(<Col md='6' className='content'><p>{this.props.room.size} m²</p></Col>);
        addSize = true;
      } else if (!addPlaces && this.props.room.nbPlaces) {
      tmpRender.push(<Col md='6' className='content'><p>{this.props.room.nbPlaces} p</p></Col>);
        addPlaces = true;
      } else tmpRender.push(<Col md='6'></Col>);
    }
    toRender.push(<Row>{tmpRender}</Row>);
    if (toRender.length < 2) {
      tmpRender = [];
      while(tmpRender.length < 2) {
        if (!addSize && this.props.room.size) {
          tmpRender.push(<Col md='6' className='content'><p>{this.props.room.size} m²</p></Col>);
          addSize = true;
        } else if (!addPlaces && this.props.room.nbPlaces) {
          tmpRender.push(<Col md='6' className='content'><p>{this.props.room.nbPlaces} p</p></Col>);
          addPlaces = true;
        } else tmpRender.push(<Col md='6'></Col>);
      }
      toRender.push(<Row>{tmpRender}</Row>);
    }
    return toRender;
  }
}

export default translate(Room);