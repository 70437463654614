import * as SRD from "storm-react-diagrams";
import SensorNodeWidget from "./SensorNodeWidget";
import SensorNodeModel from "./SensorNodeModel";
import * as React from "react";

export class SensorNodeFactory extends SRD.AbstractNodeFactory {
	constructor() {
		super("sensor");
	}

	generateReactWidget(diagramEngine, node) {
		return <SensorNodeWidget node={node} diagramEngine={diagramEngine}/>;
	}

	getNewInstance() {
		return new SensorNodeModel();
	}
}

export default SensorNodeFactory;