import React from "react";
import { BaseWidget, DefaultPortLabel } from "storm-react-diagrams";
import Conf from '../../../../utils/Conf';
import { Modal, Button } from 'reactstrap';
import { getRequest } from '../../../../utils/WebServicesManager';
import { translate } from '../../../../utils/ReactMultiLang';
import SensorSelector from '../../../Modals/SensorSelector'


class SensorNodeWidget extends BaseWidget {
	constructor(props) {
		super("srd-node", props);
		const DevEUI = props.node.getSensorId();
		this.state = {
			SelectedSensor: {},
			SelectedField: "",
			FieldList: [],
			Modal: DevEUI === ''
		};
		this.toggle = this.toggle.bind(this);
		this.t = props.t;
		if (!this.state.Modal) {
			this.getFieldList(DevEUI);
		}
	}

	toggle() {
		this.setState({Modal: !this.state.Modal});
	}

  getFieldList(DevEUI) {
    if (DevEUI !== undefined) {
			let url = Conf.BaseApi + `devices/get/${DevEUI}`;
			getRequest(url, (body) => {
				if (body && body.success) {
					let result = body.result;
					this.setState({ SelectedSensor: result });
				}
			});
      url = Conf.BaseApi + `helpers/fields/${DevEUI}`;
      return getRequest(url, (result) => {
        if (result && result.success) {
          this.setState({ FieldList: result.result });
        }
      });
    }
  }

generateFieldsDropDown(fields) {
	const toRender = [];
	
	for (var i = 0; i < fields.length; i++) {
		toRender.push(
		  <option value={fields[i]}>{this.t(`SensorData.${fields[i]}`)}</option>
		);
	  }

	return toRender;
}

sensorSelected(sensors) {
	if (sensors.length > 0) {
		const { DevEUI, _id } = sensors[0];
		this.props.node.setSensorId(DevEUI);
		this.props.node.setMongoId(_id);
		var url = Conf.BaseApi + `helpers/fields/${DevEUI}`;
		return getRequest(url, (result) => {
		  if (result && result.success) {
				this.setState({ SelectedSensor: sensors[0], FieldList: result.result, SelectedField: result.result[0] });
				this.props.node.setDataName(result.result[0]);
				this.toggle();
		  }
		});
	}
}

selectField(event) {
	if (event.target.value !== "") {
		this.props.node.setDataName(event.target.value);
		this.setState({SelectedField: event.target.value});
	}
}

	generatePort(port) {
		return <DefaultPortLabel model={port} key={port.id} />;
	}

	render() {
		const {picto} = this.props.node;
		return (
			<div {...this.getProps()}  className={"complex-node node-sensor " + this.props.node.color}>
				<div className={this.bem("__title")}>
					<div className={this.bem("__name")}>
					<img src={`./img/picto/${picto}.png`} />{" "}{this.props.node.name}<br/><br/>
					{this.state.SelectedSensor.name ? this.state.SelectedSensor.name : this.t(`Sensor.NoSensor`)}{' '}
					<Button className="btn-xs" onClick={() => this.toggle()}>{this.t(`Sensor.Change`)}</Button>
					<br/>
					<select id={this.props.node.id + 'sensorData'} onChange={this.selectField.bind(this)}
						value={`${this.props.node.getDataName()}`}>
						{this.generateFieldsDropDown(this.state.FieldList)}
					</select>
					</div>
				</div>
				<div className={this.bem("__ports")}>
					<div className={this.bem("__out")}>
						{Object.keys(this.props.node.getPorts()).map((key) => this.generatePort(this.props.node.getPorts()[key]))}
					</div>
				</div>
        <Modal isOpen={this.state.Modal} toggle={this.toggle} className={'modal-lg ' + this.props.className}>
           <SensorSelector callbackAddSensor={this.sensorSelected.bind(this)} />
        </Modal>
			</div>
		);
	}
}

export default translate(SensorNodeWidget);