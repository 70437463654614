import React, { Component } from 'react';
import $ from 'jquery';
import Conf from '../../../../utils/Conf';
import { getRequest } from '../../../../utils/WebServicesManager';
import ColorPicker from '../../ColorPicker';
import { translate } from '../../../../utils/ReactMultiLang';
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from 'react-sortable-hoc';
import arrayMove from 'array-move';
import { Modal, ModalHeader, ModalBody, Col, Row } from 'reactstrap';


class CreateLevel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Sensors: [],
      FieldList: [],
      dataSets: props.component ? props.component.dataSets : [],
      cpColor: '#000',
      ModalAddBool: false
    };
    this.getSensors();
    this.t = props.t;
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.component !== this.props.component) {
      this.setState({dataSets: nextProps.component.dataSets});
    }
}
  toggleAddBool = () => {
    this.setState({ModalAddBool: !this.state.ModalAddBool});
  }

  getSensors() {
    if (this.state.FieldList.length !== 0)
      return;
    var url = Conf.BaseApi + `devices/get`;
    return getRequest(url, (body) => {
      if (body && body.success) {
        let result = body.result;
        this.setState({ Sensors: result });
        if (result.length > 0) {
          const event = {};
          event.target = {}
          event.target.value = result[0].DevEUI;
          this.getFieldList(event);
        }
      }
    });
  }

  handleCpColorChange = (color) => {
    this.setState({ cpColor: color });
  };

  getFieldList(event) {
    if (event.target.value !== "") {
      var url = Conf.BaseApi + `helpers/fields/${event.target.value}`;
      return getRequest(url, (result) => {
        if (result && result.success) {
          this.setState({ FieldList: result.result });
        }
      });
    }
  }
  
  renderSensors(sensors, component) {
    var toRender = [];
    for (var i = 0; i < sensors.length; i++) {
      if (component && component.dataSets[0].sensor === sensors[i].DevEUI) {
        toRender.push(
          <option value={sensors[i].DevEUI} selected='selected'>{sensors[i].name}</option>
        );
      } else {
        toRender.push(
          <option value={sensors[i].DevEUI}>{sensors[i].name}</option>
        );  
      }
    }
    return toRender;
  }
  

  renderDeviceFields(fields) {
    var toRender = [];
    for (var i = 0; i < fields.length; i++) {
      toRender.push(
        <option value={fields[i]}>{this.t(`SensorData.${fields[i]}`)}</option>
      );
    }
    return toRender;
  }

  createComponent(callback, type) {
    const component = {};
    component.name = $("#createCpName").val();
    component.color = this.state.lineColor;
    component.type = type;
    component.dataSets = this.state.dataSets;
    component.color = "#fff";
    callback(component);
  }
  editComponent(callback, component) {
    component.name = $("#createCpName").val();
    component.color = this.state.lineColor;
    component.dataSets = this.state.dataSets;
    callback(component);
  }

  addDataSet() {
    const dSets = this.state.dataSets;
    const sensor = $("#createCpSensor").val();
    const paramName = $("#createCpDataName").val();
    const label = $("#createCpLabel").val();
    const color = this.state.cpColor;
    dSets.push({
      sensor, label, paramName, color
    });
    this.setState({dataSets: dSets});
  }

  onSortEnd = ({oldIndex, newIndex}) => {
    this.setState(({dataSets}) => ({
      dataSets: arrayMove(dataSets, oldIndex, newIndex),
    }));
  };
  
  render() {
    const { callback, type, component } = this.props;

    return (
      <div className="flex-row align-items-center">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12">
              <div className="card mx-12">
                {this.getBoolLevel(callback, type, component)}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  getBoolLevel(callback, type, component) {
    return (
      <div className="card-block p-12">
        <div className="input-group mb-3" style={{ color: "red" }} id="error-window">
        </div>
        <div className="input-group mb-3">
          <span className="input-group-addon"><i className="icon-user"></i></span>
          <input type="text" className="form-control" placeholder={this.t('Component.Name')} id="createCpName" defaultValue={component ? component.name : ''}/>
        </div>
        <div className="form-group mb-3">
          <div><label htmlFor="createCpSensor">{this.t('Sensor')}:</label></div>

          <select className="form-control" id="createCpSensor" onChange={this.getFieldList.bind(this)}>
            {this.renderSensors(this.state.Sensors, component)}
          </select>
        </div>

        <div className="form-group mb-3">
          <label htmlFor="createCpDataSets">{this.t('Level')}:</label>
          {this.getDataSets(this.state.dataSets)}
          <button type="button" className="btn btn-primary" onClick={this.toggleAddBool}>{this.t('Component.AddBool')}</button>
        </div>
        <br />
        <button type="button" className="btn btn-block btn-success"
          onClick={component ? this.editComponent.bind(this, callback, component) : this.createComponent.bind(this, callback, type)} >
          {this.t(component ? 'Update': 'Add')}</button>
        
        <Modal isOpen={this.state.ModalAddBool} toggle={this.toggleAddBool} className={'modal-md ' + this.props.className}>
          <ModalHeader>
            {this.t('Component.AddBool')}
          </ModalHeader>
          <ModalBody>
            <div className="input-group mb-3">
              <span className="input-group-addon"><i className="icon-user"></i></span>
              <input type="text" className="form-control" placeholder={this.t('Component.DataSetName')} id="createCpLabel" />
            </div>
            <div className="form-group mb-3">
              <label htmlFor="createCpColor">{this.t('Color')}:</label>
              <ColorPicker callback={this.handleCpColorChange} />
            </div>
            <div className="form-group mb-3">
              <div><label htmlFor="createCpDataName">{this.t('Component.DataToUse')}:</label></div>

              <select className="form-control" id="createCpDataName">
                {this.renderDeviceFields(this.state.FieldList)}
              </select>
            </div>
            <button type="button" className="btn btn-block btn-success" onClick={this.addDataSet.bind(this)}>{this.t('Add')}</button>
          </ModalBody>
        </Modal>
      </div>
    );
  }

  getDataSets = (dSets) => {
    return (
      <SortableContainer onSortEnd={this.onSortEnd} useDragHandle>
        {dSets.map((dataSet, index) => (
          <SortableItem key={`item-${index}`} index={index} dataSet={dataSet} deleteDataSet={this.deleteDataSet.bind(this, index)}/>
        ))}
      </SortableContainer>);
  }

  deleteDataSet(index) {
    const arr = this.state.dataSets;
    arr.splice(index,1);
    this.setState({dataSets: arr})
  }
}


const DragHandle = sortableHandle(() => <i className="icon-layers" style={{cursor:'pointer', padding: '5px', display:'inline-block'}}></i>);

const SortableItem = sortableElement(({dataSet, deleteDataSet}) => (
  <li>
    <Row>
        <Col md='2'>
          <DragHandle />
        </Col>
        <Col md='8'>
          <div style={{color: dataSet.color, display:'inline-block'}}>{dataSet.label} : {dataSet.paramName}</div>
        </Col>
        <Col md='2' style={{cursor:'pointer'}} onClick={deleteDataSet}>
          X
        </Col>
    </Row>
  </li>
));

const SortableContainer = sortableContainer(({children}) => {
  return <ul>{children}</ul>;
});

export default translate(CreateLevel);