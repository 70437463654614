import React, { Component } from 'react';
import $ from 'jquery';
import Conf from '../../../utils/Conf';
import { getRequest, postRequest } from '../../../utils/WebServicesManager';
import { Button, Modal, ModalHeader, ModalBody, Row, Col } from 'reactstrap';
import { translate } from '../../../utils/ReactMultiLang';
import { check } from '../../../utils/Can';
import ProviderModal from '../../../components/Modals/Providers';
import AssignProvider from '../../../components/Modals/Providers/AssignProvider';

class Manage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      UserGroups: [],
      ActualGroup: {},
      UserSimpleGroups: [],
      Modal: false,
      ModalURL: false,
      ModalProvider: false,
      ModalAssignProvider: false,
      User: {},
      basicAuth: false,
      actualURL: undefined,
      CanCreate: check('group:create'),
      CanEdit: check('group:edit'),
      CanRemove: check('group:remove'),
      CanViewProvider: check('provider:read'),
      CanCreateProvider: check('provider:create'),
      CanEditProvider: check('provider:edit'),
      CanRemoveProvider: check('provider:remove'),
      SelectedProvider:undefined,
      ProviderKey: 0
    };
    this.getUserGroups();
    this.toggle = this.toggle.bind(this);
    this.t = props.t;
  }

  getUserGroups() {
    let url = Conf.BaseApi + 'groups/get/simple';
    getRequest(url, (data) => {
      if (data.success)
        this.setState({ UserSimpleGroups: data.result });
    });
    url = Conf.BaseApi + 'user';
    getRequest(url, (data) => {
      if (data.success)
        this.setState({ User: data.result });
    });
    url = Conf.BaseApi + 'groups/get';
    return getRequest(url, (data) => {
      if (data.success)
        this.setState({ UserGroups: data.result});
    });
  }

  getGroupsDetails(group) {
    let gId = group;
    if (group._id) gId = group._id;
    let url = `${Conf.BaseApi}groups/getDetails/${gId}`;
    getRequest(url, (data) => {
      if (data.success)
        this.setState({ ActualGroup: data.result });
      else
        this.setState({ ActualGroup: group });
    });
  }

  renderGroupsList() {
    var toRender = [];
    for (var i = 0; i < this.state.UserGroups.length; i++) {
      if (this.state.UserGroups[i] !== undefined) {
        toRender.push(
          <li key={i}>
            <div className="row">
              <div className="col-lg-12 col-md-12 col-xs-12 sensorListItem" onClick={this.getGroupsDetails.bind(this, this.state.UserGroups[i])}>
                <i className="icon-people bg-success"></i>
                <div className="desc">
                  <div className="title">{this.state.UserGroups[i].name}</div>
                  <small>{this.state.UserGroups[i].providers ? this.state.UserGroups[i].providers.length : ''}</small>
                </div>
                {this.state.CanRemove ?
                  <div className="actions">
                    <button type="button" onClick={this.deleteGroup.bind(this, this.state.UserGroups[i]._id)} className="btn btn-link text-danger">
                      <i className="icon-close" value={this.state.UserGroups[i]._id}></i>
                    </button>
                  </div> : ''}
              </div>
            </div>
          </li>
        );
      }
    }
    return toRender;
  }
  renderGroupsSimpleList() {
    var toRender = [];
    for (var i = 0; i < this.state.UserSimpleGroups.length; i++) {
      if (this.state.UserSimpleGroups[i] !== undefined) {
        toRender.push(
          <li>
            <i className="icon-people bg-primary"></i>
            <div className="desc">
              <div className="title">{this.state.UserSimpleGroups[i].name}</div>
              <small>{this.state.UserSimpleGroups[i].provider}</small>
            </div>
          </li>
        );
      }
    }
    return toRender;
  }

  toggle() {
    this.setState({
      Modal: !this.state.Modal
    });
  }
  toggleEditURL(url) {
    if (url) {
      this.setState({
        ModalURL: true,
        basicAuth: url.basicAuth,
        actualURL: url
      });
    }
  }
  toggleURL() {
    this.setState({
      ModalURL: !this.state.ModalURL, actualURL: undefined
    });
  }

  quitGroup(id) {
    var url = Conf.BaseApi + "groups/quit/" + id;
    getRequest(url, (data) => {
      if (data.success && data.success == true) {
        this.getUserGroups();
      }
    });
  }

  deleteGroup(id) {
    const pwd = window.prompt(`You will not be able to undo this action.
    \nDo you still want to remove this group ?
    \nPlease confirm your choice by entering your password.`);
    if (pwd) {
      var url = Conf.BaseApi + "groups/delete";
      const opt = {
        groupId: id,
        password: pwd
      }
      postRequest(url, opt, (data) => {
        if (data.success && data.success == true) {
          this.getUserGroups();
        }
      });
    }
  }

  addGroup() {
    var url = Conf.BaseApi + 'groups/add';
    return postRequest(url, {
      name: $("#groupName").val(),
      provider: $("#providerName").val()
    }, (data) => {
      if (data.success || data.success == true) {
        this.toggle();
        this.getUserGroups();
      } else {
        $("#error-window").text(data.message);
      }
    });
  }

  addURLForward() {
    var url = Conf.BaseApi + 'groups/addURL';
    const options = {
      groupId: this.state.ActualGroup._id,
      name: $("#forwardName").val(),
      url: $("#forwardURL").val(),
      basicAuth: $("#authField").is(':checked'),
      login: $("#loginField").val(),
      password: $("#passwordField").val()
    }
    if (this.state.actualURL) {
      url = Conf.BaseApi + 'groups/editURL';
      options.pushId = this.state.actualURL._id;
    }
    return postRequest(url, options, (data) => {
      if (data.success || data.success == true) {
        this.toggleURL();
        this.getGroupsDetails(this.state.ActualGroup);
      } else {
        $("#error-window-url").text(data.message);
      }
    });
  }
  deletePushURL(groupId, id) {
    var r = window.confirm(`${this.t('Popup.Delete.PushURL')}`);
    if (r == true) {
      var url = Conf.BaseApi + "groups/deleteURL";
      postRequest(url, {
        groupId,
        id
      }, (data) => {
        if (data.success && data.success == true) {
          this.getGroupsDetails({ _id: groupId });
        }
      });
    }
  }
  deleteProvider(id, groupId) {
    var r = window.confirm(`${this.t('Popup.Delete.Provider')}`);
    if (r == true) {
      var url = Conf.BaseApi + "providers/remove/" + id;
      getRequest(url, (data) => {
        if (data.success && data.success == true) {
          this.getGroupsDetails({ _id: groupId });
        }
      });
    }
  }

  toggleProvider(provider) {
    this.setState({ ModalProvider: !this.state.ModalProvider, SelectedProvider: provider && provider.name ? provider : undefined, ProviderKey: this.state.ProviderKey + 1 });

  }

  toggleProviderAndRefresh(provider, group) {
    this.setState({ ModalProvider: !this.state.ModalProvider, SelectedProvider: provider && provider.name ? provider : undefined, ProviderKey: this.state.ProviderKey + 1 });
    this.getGroupsDetails(group);
  }

  toggleAssignProvider(p, group) {
    this.setState({ ModalAssignProvider: !this.state.ModalAssignProvider });
    if (group) this.getGroupsDetails(group);
  }

  renderGroupDetails(group) {
    const toRender = [];
    if (group.name !== undefined) {
      toRender.push(
        <div>
          <h2>{group.name}</h2>
        </div>
      );
      toRender.push(<hr />);
      if (this.state.CanViewProvider) {
        toRender.push(<span><b>{this.t('Provider')}:</b></span>);
        for (let i = 0; i < group.providers.length; i += 1) {
          const provider = group.providers[i];
          if (provider) {
            toRender.push(
              <li key={i + provider._id}>
                <Row><div className="col-lg-12 col-md-12 col-xs-12 sensorListItem">
                  <i className="icon-user bg-success"></i>
                  <div className="desc">
                    <div className="title">{provider.name}</div>
                    <small>{provider.operator}</small>
                  </div>
                  <div className="value">
                  { this.state.CanEditProvider ? 
                    <button type="button" onClick={this.toggleProvider.bind(this, provider)} className="btn btn-link text-success">
                      <i className="icon-pencil" ></i>
                    </button> : '' }
                  { this.state.CanRemoveProvider ? 
                    <button type="button" onClick={this.deleteProvider.bind(this, provider._id, group._id)} className="btn btn-link text-danger">
                      <i className="icon-close" ></i>
                    </button> : '' }
                  </div>
                </div>
                </Row>
              </li>
            );
          }
        }
        toRender.push(
          <div>
            <br/>
            {this.state.CanCreateProvider ? <button type="button" onClick={this.toggleProvider.bind(this)} className="btn btn-primary btn-sm" >{this.t('Group.AddProvider')}</button> : ""}
            {' '}
            {this.state.CanEdit ? <button type="button" onClick={this.toggleAssignProvider.bind(this)} className="btn btn-primary btn-sm" >{this.t('Group.AssignProvider')}</button> : ""}
          </div>);
      }
      toRender.push(<br/>);
      toRender.push(<hr />);
      if (group.users != undefined) {
        toRender.push(<span><b>{this.t('Users')}:</b></span>);
        for (let i = 0; i < group.users.length; i += 1) {
          const user = group.users[i];
          if (user) {
            toRender.push(
              <li key={i + user._id}>
                <Row><div className="col-lg-12 col-md-12 col-xs-12 sensorListItem">
                  <i className="icon-user bg-success"></i>
                  <div className="desc">
                    <div className="title">{user.firstName} {user.name}</div>
                    <small>{user.email}</small>
                  </div>
                </div>
                </Row>
              </li>
            );
          }
        }
      }
      if (group.pushURL != undefined) {
        for (let i = 0; i < group.pushURL.length; i += 1) {
          toRender.push(

            <li>
              <i className="icon-share-alt bg-warning"></i>
              <div className="desc">
                <div className="title">{group.pushURL[i].name}{group.pushURL[i].basicAuth ? ` - ${group.pushURL[i].login}` : ''}</div>
                <small>{group.pushURL[i].url}</small>
              </div>
              <div className="value">
                <button type="button" onClick={this.toggleEditURL.bind(this, group.pushURL[i])} className="btn btn-link text-success">
                  <i className="icon-pencil" ></i>
                </button>
                <button type="button" onClick={this.deletePushURL.bind(this, group._id, group.pushURL[i]._id)} className="btn btn-link text-danger">
                  <i className="icon-close" value={group.pushURL[i]._id}></i>
                </button>
              </div>
            </li>
          );
        }
      }
      toRender.push(<br />);
      toRender.push(
        <div>
          {this.state.UserGroups.length > 0 && this.state.CanEdit ? <button type="button" onClick={this.toggleURL.bind(this)} className="btn btn-primary btn-sm" >{this.t('Group.AddURL')}</button> : ""}
        </div>
      );
    }
    return toRender;
  }

  AuthChange() {
    this.setState({ basicAuth: $("#authField").is(':checked') });
  }

  render() {
    const divStyle = {
      width: '60%'
    };

    return (
      <div className="row">
        <div className="col-lg-6 col-md-12">
          <div className="row ">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-header">
                  <i className="fa fa-align-justify"></i> {this.t('Admin')}
                </div>
                <div className="card-block">
                  <ul className="icons-list">
                    {this.renderGroupsList()}
                  </ul>
                </div>
                {this.state.CanCreate ?
                  <button type="button" onClick={this.toggle} className="btn btn-primary btn-lg" >{this.t('Group.AddNewGroup')}</button> : ''}
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-12">
          <div className="row ">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-header">
                  <i className="fa fa-align-justify"></i> {this.t('Group.GroupSettings')}
                </div>
                <div className="card-block">
                  <ul className="icons-list">
                    {this.renderGroupDetails(this.state.ActualGroup)}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal isOpen={this.state.ModalProvider} key={this.state.ProviderKey} className={'modal-md ' + this.props.className}>
          <ModalHeader>
            <Row style={{ paddingLeft: '15px', paddingRight: '15px' }}>
              <h1>{this.t('Group.AddProvider')}</h1>
              <i className="icon-close color-danger" onClick={this.toggleProvider.bind(this)}
                style={{ position: 'absolute', textAlign: 'right', right: '15px', cursor: 'pointer' }}></i>
            </Row>
          </ModalHeader>
          <ProviderModal callback={this.toggleProviderAndRefresh.bind(this)} groupId={this.state.ActualGroup._id} provider={this.state.SelectedProvider}/>
        </Modal>
        
        <Modal isOpen={this.state.ModalAssignProvider} className={'modal-md ' + this.props.className}>
          <ModalHeader>
            <Row style={{ paddingLeft: '15px', paddingRight: '15px' }}>
              <h1>{this.t('Group.AssignProvider')}</h1>
              <i className="icon-close color-danger" onClick={this.toggleAssignProvider.bind(this)}
                style={{ position: 'absolute', textAlign: 'right', right: '15px', cursor: 'pointer' }}></i>
            </Row>
          </ModalHeader>
          <AssignProvider callback={this.toggleAssignProvider.bind(this)} groupId={this.state.ActualGroup._id}/>
        </Modal>

        <Modal isOpen={this.state.Modal} className={'modal-md ' + this.props.className}>
          <ModalHeader>
            <Row style={{ paddingLeft: '15px', paddingRight: '15px' }}>
              <h1>{this.t('Group.AddNewGroup')}</h1>
              <i className="icon-close color-danger" onClick={this.toggle.bind(this)}
                style={{ position: 'absolute', textAlign: 'right', right: '15px', cursor: 'pointer' }}></i>
            </Row>
          </ModalHeader>
          <ModalBody>
            <div className="flex-row align-items-center">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-md-12">
                    <div className="card mx-12">
                      <div className="card-block p-12">
                        <div className="input-group mb-3" style={{ color: "red" }} id="error-window">

                        </div>
                        <div className="input-group mb-3">
                          <span className="input-group-addon"><i className="icon-usergroup"></i></span>
                          <input type="text" className="form-control" placeholder={this.t('Name')} id="groupName" />
                        </div>
                        <div className="input-group mb-3">
                          <select className="form-control" id="providerName" name="providerName">
                            {this.renderProviderName()}
                          </select>
                        </div>
                        <button type="button" className="btn btn-block btn-success" onClick={this.addGroup.bind(this)}>{this.t('Add')}</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
        <Modal isOpen={this.state.ModalURL} className={'modal-md ' + this.props.className}>
          <ModalHeader>
            <Row style={{ paddingLeft: '15px', paddingRight: '15px' }}>
              <h1>{this.t('Group.AddURL')}</h1>
              <i className="icon-close color-danger" onClick={this.toggleURL.bind(this)}
                style={{ position: 'absolute', textAlign: 'right', right: '15px', cursor: 'pointer' }}></i>
            </Row>
          </ModalHeader>
          <ModalBody>
            <div className="flex-row align-items-center">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-md-12">
                    <div className="card mx-12">
                      <div className="card-block p-12">
                        <div className="input-group mb-3" style={{ color: "red" }} id="error-window-url">

                        </div>
                        <div className="input-group mb-3">
                          <span className="input-group-addon"><i className="icon-usergroup"></i></span>
                          <input type="text" className="form-control" placeholder={this.t('Name')} id="forwardName"
                            defaultValue={this.state.actualURL ? this.state.actualURL.name : ''} />
                        </div>
                        <div className="input-group mb-3">
                          <span className="input-group-addon"><i className="icon-usergroup"></i></span>
                          <input type="text" className="form-control" placeholder="URL" id="forwardURL"
                            defaultValue={this.state.actualURL ? this.state.actualURL.url : ''} />
                        </div>
                        <div className="input-group mb-3">
                          <div className="form-check form-check-inline">
                            <label className="form-check-label">
                              <input className="form-check-input" type="checkbox" id="authField" value="true" onChange={() => this.AuthChange()}
                                defaultChecked={this.state.actualURL ? this.state.actualURL.basicAuth : false} />
                              {' '}{this.t('basicAuth')}
                            </label>
                          </div>
                        </div>
                        {this.state.basicAuth ?
                          <Row>
                            <Col md='6' sm='12'>
                              <label htmlFor="alarmLevelField">{this.t('Login')}</label>
                              <div className="input-group mb-3">
                                <input type="text" className="form-control" placeholder={this.t('Login')} id="loginField"
                                  defaultValue={this.state.actualURL ? this.state.actualURL.login : ''} />
                              </div>
                            </Col>
                            <Col md='6' sm='12'>
                              <label htmlFor="alarmLevelField">{this.t('Password')}</label>
                              <div className="input-group mb-3">
                                <input type="text" className="form-control" placeholder={this.t('Password')} id="passwordField"
                                  defaultValue={this.state.actualURL ? this.state.actualURL.password : ''} />
                              </div>
                            </Col>
                          </Row>
                          : ''}
                        <button type="button" className="btn btn-block btn-success" onClick={this.addURLForward.bind(this)}>{this.t('Group.AddURL')}</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </div>

    )
  }

  renderProviderName() {

    var toRender = [];
    for (var i = 0; i < Conf.Providers.length; i++) {
      toRender.push(
        <option key={i} value={Conf.Providers[i]}>{Conf.Providers[i]}</option>
      );
    }
    return toRender;
  }
}

export default translate(Manage);
