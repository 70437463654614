import React, { Component } from 'react';
import createClass from 'create-react-class';
import Conf from '../../../utils/Conf';
import Select from 'react-select';
import { getRequest } from '../../../utils/WebServicesManager';
import fetch from 'isomorphic-fetch';


class SelectSensorsType extends Component {
	constructor(props) {
		super(props);
		this.displayName = 'SelectSensorsType';
		const opt = [];
		if (props.options !== undefined && props.options.length > 0 && props.options[0] !== undefined)  {
			for (let i = 0; i < props.options.length; i += 1) {
				if (props.options[i] !== undefined) {
					opt.push({value: props.options[i]._id, label: props.options[i].name});
				}
			}
		}
		this.state = {
			multiValue: [],
			options: opt,
			backspaceRemoves: true,
			multi: true,
			value: undefined
		};
		this.onChangeCallback = props.onChangeCallback;
		this.entity = props.entity;
		if (props.options === undefined) {
			this.getSensorModelList();
		}
	}
	getInitialState () {
		return {
			multiValue: [],
			options: [],
			backspaceRemoves: true,
			multi: true,
			value: undefined
		};
	}
	handleOnChange (value) {
		const { multi } = this.state;
		if (multi) {
			this.setState({ multiValue: value });
			if (this.onChangeCallback !== undefined) {
				this.onChangeCallback(value);
			}
		} else {
			this.setState({ value });
		}
	}

	getSensorModelList() {
		var url = Conf.BaseApi + `helpers/getModels`;
		return getRequest(url, (body) => {
		  if (body && body.success) {
			const data = body.result;
			const options = [];
			for (let i = 0; i < data.length; i += 1) {
				options.push({value: data[i], label: data[i]});
			}
			this.setState({ options });
		  }
		});
	  }

	toggleBackspaceRemoves () {
		this.setState({
			backspaceRemoves: !this.state.backspaceRemoves
		});
	}
	render () {
		const { multi, multiValue, value } = this.state;
		const { values } = this.props;

		if (multiValue && multiValue !== undefined && multiValue.length === 0 && values && values !== undefined && values.length > 0)
			this.setState({multiValue: values});
		return (
			<div>
				<Select 
					multi={this.state.multi}
					value={multi ? multiValue : value}
					options={this.state.options}
					onChange={this.handleOnChange.bind(this)}
					backspaceRemoves={this.state.backspaceRemoves}
					id="sensorModels"
					name="sensorModels"
					/>
			</div>
		);
	}
}

export default SelectSensorsType;