import React, { Component } from 'react';
import $ from 'jquery';
import Conf from '../../../utils/Conf';
import { getDateStr } from '../../../utils/Utils';
import DashSingleValue from '../../../components/Charts/DashSingleValue';
import { getRequest, postRequest, dashGetRequest } from '../../../utils/WebServicesManager';
import { Modal,  Row, Card, Col, CardBody } from 'reactstrap';
import { translate } from '../../../utils/ReactMultiLang';

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPublic: props.isPublic,
      Dashboard: {},
      SensorList: [],
      LastMessageList: []
    };

    if (!props.isPublic) {
      const params = new URLSearchParams(props.location.search); 
      this.dashboardId = params.get('dashboard');
      if (!this.dashboardId) {
        window.location = '#/sensors/manage';
      }
    }
    this.t = props.t;
    this.time = 10 * 60 * 1000;
    this.getDashboard(this.dashboardId);
  }

  getDashboard(dashboardId) {
    let url = Conf.BaseApi + 'building/dashboard/get/' + dashboardId;

    let requester = getRequest;
    if (this.state.isPublic) {
      url = Conf.BaseURL + 'building/dashboard/get';
      requester = dashGetRequest;
    }

    return requester(url, (data) => {
      if (data.success) {
        const { dashboard, sensors } = data.result;
        this.setState({ Dashboard: dashboard, SensorList: sensors });
        if (this.state.isPublic) {
          this.getPublicLastMessageForSensors();
        } else {
          this.getLastMessageForSensors();
        }
      }
    });
  }

  getPublicLastMessageForSensors() {
    if (this.state.SensorList) {
      const url = Conf.BaseURL + 'building/dashboard/getLastMessage';
      dashGetRequest(url, (dataDevice) => {
        if (dataDevice.success) {
          this.setState({ LastMessageList: dataDevice.result });
        }
      });
    }
    setTimeout(this.getPublicLastMessageForSensors.bind(this), this.time);
  }

  getLastMessageForSensors() {
    if (this.state.SensorList) {
      const devList = this.state.SensorList.map(d => d.DevEUI);
      const url = Conf.BaseApi + 'messages/getLastFrom/devices';
      postRequest(url, { devices: devList, dataName: this.state.Dashboard.data }, (dataDevice) => {
        if (dataDevice.success) {
          this.setState({ LastMessageList: dataDevice.result });
        }
      });
    }
    setTimeout(this.getLastMessageForSensors.bind(this), this.time);
  }
  
  renderSensorList(sensorList) {
    const toReturn = [];
    
    if (sensorList && sensorList.length > 0) {
      for (let i = 0; i < sensorList.length; i += 1) {
        let message = {};
        if (this.state.LastMessageList) {
          message = this.state.LastMessageList[sensorList[i].DevEUI];
        }
        toReturn.push(
          <Col xl='3' lg='4' md='6'>
            <DashSingleValue
              title={sensorList[i].name}
              lastSeen={sensorList[i].lastSeen}
              desc={sensorList[i].usage}
              value={message ? message.data[this.state.Dashboard.data]: 'XX'}
              unit={this.state.Dashboard.unit}
              minData={this.state.Dashboard.lowLevel}
              maxData={this.state.Dashboard.alarmLevel}
              midData={this.state.Dashboard.midLevel} />
          </Col>
        );
      }
    }

    return toReturn;
  }

  render() {
    return (
      <Row>
        {this.renderSensorList(this.state.SensorList)}
      </Row>
    )
  }
}

export default translate(Dashboard);