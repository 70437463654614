import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Conf from '../../../utils/Conf';
import Select from 'react-select';
import { getRequest } from '../../../utils/WebServicesManager';
import fetch from 'isomorphic-fetch';


class SelectUser extends Component {
	constructor(props) {
		super(props);
		this.scope = 'all';
		this.displayName = 'SelectUser';
		this.state = {
			doNotUse: props.doNotUse,
			backspaceRemoves: true,
			multi: props.multi !== undefined ? props.multi : true
		};

		this.filterOptions = this.filterOptions.bind(this);
	}
	
	componentWillMount() {
		if (this.props.values !== undefined) {
			this.setState({value: this.props.values});
		}
	}

    componentWillReceiveProps(nextProps) {
        if (this.props.doNotUse && nextProps.doNotUse && this.props.doNotUse.length !== nextProps.doNotUse.length) {
          this.setState({
            doNotUse: nextProps.doNotUse
          });
        }
      }
	getInitialState () {
		return {
			backspaceRemoves: true,
			multi: true
		};
	}
	onChange (value) {
		this.setState({
			value: value,
		});
		if (this.props.updateSelection) {
			this.props.updateSelection(value);
		}
	}
	switchToMulti () {
		this.setState({
			multi: true,
			value: [this.state.value],
		});
	}
	switchToSingle () {
		this.setState({
			multi: false,
			value: this.state.value ? this.state.value[0] : null
		});
	}
	getUsers (input, callback) {
		if (!input) {
			return Promise.resolve({ options: [] });
		}
		var url = Conf.BaseApi + `users/find/${this.scope}/${input}`
		return getRequest(url, (data) => {
			if (data.success) {
				callback(null, {
					options: data.result,
					complete: true,
				});
			}
		  });
	}
	toggleBackspaceRemoves () {
		this.setState({
			backspaceRemoves: !this.state.backspaceRemoves
		});
	}

	filterOptions(options, filter, currentValues) {
		for (let i = 0; i < options.length; i += 1) {
			if (currentValues.some(v => v._id === options[i]._id)
				|| (this.state.doNotUse && this.state.doNotUse.some(v => v._id === options[i]._id))) {
				options.splice(i, 1);
				i -= 1;
			}
		}
		return options;
	}

	toggleCreatable () {
		this.setState({
			creatable: !this.state.creatable
		});
	}
	render () {
		const { scopeParam, selectId } = this.props;
		this.scope = scopeParam;
		const AsyncComponent = this.state.creatable
			? Select.AsyncCreatable
			: Select.Async;
		return (
			<div className="section">
				<AsyncComponent 
				 autoComplete="off"
					filterOptions={this.filterOptions}
					multi={this.state.multi}
					value={this.state.value}
					onChange={this.onChange.bind(this)}
					valueKey="_id"
					labelKey="login"
					loadOptions={this.getUsers.bind(this)}
					backspaceRemoves={this.state.backspaceRemoves}
					id={selectId !== undefined ? selectId : "userId" }
					name={selectId !== undefined ? selectId : "userId" }
					 />
			</div>
		);
	}
}


export default SelectUser;