import React, { Component } from 'react';
import { translate } from '../../../../../utils/ReactMultiLang';
import Place from '../Place/Place';
import PlaceOccupancy from '../../../../../components/Charts/PlaceOccupancy';
import Conf from '../../../../../utils/Conf';
import { getRequest, } from '../../../../../utils/WebServicesManager';
import { Row } from 'reactstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';


class PlaceStat extends Component {
  constructor(props) {
    super(props); 
    const d = new Date().getDay();
    this.state = {
      from: moment().subtract(1, "years"),
      to: moment(),
      assetsUrl: props.assetsUrl,
      Assets: [],
      SelectedDay: d,
      Key: 0,
      KeyPlace: 0
    }
    this.getPlaces(props.assetsUrl);
    this.t = props.t;
  }

  getPlaces(assetsUrl) {
      let url = Conf.BaseApi + assetsUrl;
      getRequest(url, (data) => {
        if (data.success) {
          const assets = data.result;
          this.setState({ Assets: assets });
        } else {
          this.setState({ Assets: [] });
        }
      });
  }


  componentWillReceiveProps(nextProps) {
    if (this.props.assetsUrl !== nextProps.assetsUrl) {
      this.setState({assetsUrl: nextProps.assetsUrl});
    }
  }

  generateAssets(assets, rooms, from, to) {
    const toRender = [];
      for (let i = 0; i < assets.length; i += 1) {
            toRender.push(<PlaceOccupancy asset={assets[i]} day={this.state.SelectedDay} from={from} to={to} key={this.state.KeyPlace + i}/>);
          }
    return <Row>{toRender}</Row>;
  }


  handleChangeFrom(from) {
    this.setState({from, KeyPlace: this.state.KeyPlace + 1});
  }
  handleChangeTo(to) {
    this.setState({to, KeyPlace: this.state.KeyPlace + 1});
  }

  render() {
    return (
      <div key={this.state.Key}>
        <div style={{textAlign: "center", marginTop: '10px'}}>
          <select onChange={(e) => {
            const val = parseInt(e.target.value);
            this.setState({SelectedDay: val < 6 ? val + 1 : 0})
          }}>
            <option value={7}>{this.t('Everyday')}</option>
            <option value={8}>{this.t('Openday')}</option>
            {Conf.WeekDay.map((d, index) => {
              const day = this.state.SelectedDay > 0 ? this.state.SelectedDay - 1 : 6;
              if (day === index) {
                return (<option value={index} selected="selected">{this.t(d)}</option>);
              } else {
                return (<option value={index}>{this.t(d)}</option>);
              }
            })}
          </select>
          <div style={{display:'inline-block', marginLeft:'25px', zIndex:'9000'}}>
                    <DatePicker
                      selected={this.state.from}
                      onChange={this.handleChangeFrom.bind(this)}
                      dateFormat="DD MMMM YYYY"
                      maxDate={this.state.to ? this.state.to.toDate() : new Date()}
                      minDate={0}
                  />
                  </div>
                  <div style={{display:'inline-block', marginLeft:'25px', zIndex:'9000'}}>
                  <DatePicker
                    selected={this.state.to}
                    onChange={this.handleChangeTo.bind(this)}
                    dateFormat="DD MMMM YYYY"
                    minDate={this.state.from ? this.state.from : 0}
                    maxDate={new Date()}
                  />
                  </div>
        </div>
        {this.generateAssets(this.state.Assets, this.state.rooms, this.state.from, this.state.to)}
      </div>
      );
  }
}

export default translate(PlaceStat);