import React from 'react';
import AvatarEditor from 'react-avatar-editor';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import { Button } from 'reactstrap';
import { postRequest } from '../../utils/WebServicesManager';
import Conf from '../../utils/Conf';

class AvatarEdition extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

  onClickSave = () => {
    if (this.editor) {
      // This returns a HTMLCanvasElement, it can be made into a data URL or a blob,
      // drawn on another canvas, or added to the DOM.
      const canvas = this.editor.getImageScaledToCanvas().toDataURL();
                var url = Conf.BaseApi + 'users/avatar/update';
                  return postRequest(url, {
                    blobAvatar: canvas
                  }, (result) => {
                    if (result.success === true) {
                      window.location.reload();
                    }
                  });
    }
  }
  
  handleDrop = dropped => {
    this.setState({ image: dropped[0] })
  }
 
  setEditorRef = (editor) => this.editor = editor
 
  render () {
    const { width, height, borderRadius, scale} = this.props;

    return (
        <div>
        <Dropzone
            onDrop={this.handleDrop}
            disableClick
            style={{ width: `${width}px`, height: `${height*1.5}px` }}
        >
            <AvatarEditor
            ref={this.setEditorRef}
            image={this.state.image}
            width={width}
            height={height}
            borderRadius={borderRadius}
            scale={scale}
            />
        </Dropzone>
        <Button color="primary" className="btn btn-danger btn-block" onClick={this.onClickSave}>
                    <i className="fa fa-credit-card-alt"></i>&nbsp; Envoyer
            </Button>
        </div>
    )
  }
}
AvatarEdition.defaultProps = {
    width: 150,
    height: 150,
    borderRadius: 75,
    scale: 1.2
};

export default AvatarEdition