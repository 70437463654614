import React, { Component } from 'react';
import { Bar, Line } from 'react-chartjs-2';
import AlarmDetails from './AlarmDetails';
import Conf from '../../utils/Conf';
import { getRequest, postRequest } from '../../utils/WebServicesManager';
import { translate } from '../../utils/ReactMultiLang';

class AlarmsStats extends Component {
    constructor(props) {
        super(props);
        this.state = {
          Alarms: []
        };
        this.getAlarmsList();
        this.t = props.t;
      }
    getAlarmsList() {
        var url = Conf.BaseApi + 'alarms';
        return getRequest(url, (data) => {
            if (data.success)
             this.setState({ Alarms: data.result });
        });
      }
    
      renderAlarmsList() { // TODO
        var toRender = [];
        for (var i = 0; i < this.state.Alarms.length; i += 2) {
            if (this.state.Alarms.length > i + 1) {
                toRender.push(
                    <div className="row" key={i}>
                        <div className="col-sm-12 col-lg-6">
                            <AlarmDetails alarm={this.state.Alarms[i]} />
                        </div>
                    <hr className="mt-4" />
                        <div className="col-sm-12 col-lg-6">
                            <AlarmDetails alarm={this.state.Alarms[i+1]}/>
                        </div>
                    </div>
                );
            } else {
                toRender.push(
                    <div className="row" key={i}>
                        <div className="col-sm-12 col-lg-6">
                            <AlarmDetails alarm={this.state.Alarms[i]} />
                        </div>
                    </div>
                );
            }
        }
        return toRender;
      }


    render() {
        return (
            <div className="card">
                <div className="card-header">
                    {this.t('Alarm.Status')}
              </div>
                <div className="card-block">
                    <div className="row">
                        <div className="col-sm-12 col-lg-12">
                            {this.renderAlarmsList()}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default translate(AlarmsStats);


