import React, { Component } from 'react';
import { Circle } from 'rc-progress';
import { translate } from '../../../utils/ReactMultiLang';
import { getDateStr } from '../../../utils/Utils';

class DashSingleValue extends Component {
  constructor(props) {
    super(props);
    this.t = props.t;
  }

  render() {
    let { title, desc, value, icon, unit, minData, maxData, midData, lastSeen } = this.props;

    let classColor = 'backcolor-success';
    if (value === "XX") {
      classColor = 'backcolor-danger';
    } else if (value < minData) {
      classColor = 'backcolor-primary';
    } else if (value > maxData) {
      classColor = 'backcolor-danger';
    } else if (value > midData) {
      classColor = 'backcolor-warning';
    }
    
    let x = 25 - minData;
    let y = (75 - x - minData) / (midData - minData);
    let z = (100 - x - minData - ((midData - minData) * y)) / (maxData - midData);
    let finalValue = 0;
    if (value === "XX") {
      finalValue = 100;
    } if (value <= minData) {
      finalValue = value + x;
    } else if (value > minData && value <= midData) {
      finalValue = ((value - minData) * y) + minData + x;
    } else if (value > midData && value <= maxData) {
      finalValue = ((value - midData) * z) + ((value - (value - midData) - minData) * y) + minData + x;
    } else {
      finalValue = 100;
    }

    return (
          <div className={`card card-inverse animated fadeIn ${classColor}`}>
              <div className="card-block">
                <div style={{position:"relative"}}>
                  <div className="float-right" style={{textAlign:'right', width:'100%'}}>
                    <h2>{value !== 'XX' ? parseInt(value * 10, 10) / 10 : value}</h2>
                    <h5 style={{marginTop:'-16px'}}>{unit}</h5>
                    <p style={{marginBottom:'0px'}} className='text-one-line'>{title}<br/>
                    <small>{desc}</small><br/>
                    <small>{getDateStr(Date.parse(lastSeen))}</small>
                    </p>
                  </div>
                </div>
                <div style={{width:'30%',  maxWidth:'100px', position:'absolute'}}>
                  <Circle percent={Math.round(finalValue)} strokeWidth="10" trailColor="#0000004F"
                    trailWidth='8' strokeColor="#F8F8F8E0" className='circle-cp'
                    style={{ height:'100px'}}/>
                </div>
              </div>
            </div>
    )
  }
}

export default translate(DashSingleValue);
