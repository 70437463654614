import * as SRD from "storm-react-diagrams";
import ValueNodeWidget from "./ValueNodeWidget";
import ValueNodeModel from "./ValueNodeModel";
import * as React from "react";

export class ValueNodeFactory extends SRD.AbstractNodeFactory {
	constructor() {
		super("value");
	}

	generateReactWidget(diagramEngine, node) {
		return <ValueNodeWidget node={node} diagramEngine={diagramEngine}/>;
	}

	getNewInstance() {
		return new ValueNodeModel();
	}
}

export default ValueNodeFactory;