import React, { Component } from 'react';
import { Bar, Line } from 'react-chartjs-2';
import Conf from '../../../../utils/Conf';
import { getRequest, postRequest } from '../../../../utils/WebServicesManager';
import { translate } from '../../../../utils/ReactMultiLang';

class AlarmSensor extends Component {
    constructor(props) {
        super(props);
        this.state = { 
          lastMessage: []
        };
        this.t = props.t;
      }

      geLastMessage(device) {
          if (this.state.lastMessage.length === 0) {
            var url = Conf.BaseApi + 'messages/get/1/' + device.DevEUI;
            return getRequest(url, (data) => {
              if (data.success)
                this.setState({ lastMessage: data.result });
            });
          }
      }

    render() {
        const { device } = this.props;
        this.geLastMessage(device);
        var serial = device.serial;
        var type = device.type;
        var batteryLevel = -100;
        var lastSeenDay = 0;
        var lastSeen = 0;
        var lastSeenHour = 0;
        var lastSeenMin = 0;
        var lastSeenMin = 0;
        let low_battery = 0;
        if (this.state.lastMessage[0] != undefined) {
            if (this.state.lastMessage[0].data != undefined && this.state.lastMessage[0].data.battery_level)
                batteryLevel = this.state.lastMessage[0].data.battery_level;
            if (this.state.lastMessage[0].data != undefined && this.state.lastMessage[0].data.low_battery != undefined) {
                low_battery = this.state.lastMessage[0].data.low_battery ? 1 : 2;
            }
            var now = new Date();
            var lastMsgTime = new Date(this.state.lastMessage[0].time * 1000);
            lastSeen = (now - lastMsgTime);
            lastSeenMin = Math.round(((lastSeen % 86400000) % 3600000) / 60000);
            lastSeenHour = Math.floor((lastSeen % 86400000) / 3600000);
            lastSeenDay = Math.floor(lastSeen / 86400000);
        }
        return (
            <div className="row">
                    <div className="col-lg-1 col-xs-2 col-sm-1">
                        <div className="avatar">
                            <img src={type.includes(Conf.SensorType.magnetic) ? 'img/sensors/door.png' : type.includes(Conf.SensorType.motion) ? 'img/sensors/motion.png' : 'img/sensors/temperature.png'} className="img-avatar"/>
                            
                        </div>
                    </div>
                    <div className="col-xs-8 col-sm-6 col-lg-6">
                        <div>{device.name}</div>
                        <div className="small text-muted">
                        {device.usage}
                                </div>
                    </div>
                    <div className="col-xs-2 col-sm-1 col-lg-1 text-center middle-status">
                        {low_battery === 1 ? <img src={'img/half-battery.png'} alt="India" style={{ height: 24 + 'px' }} /> : (low_battery === 2 ? <img src={'img/battery-red.png'} alt="India" style={{ height: 24 + 'px' }} />: <img src={'img/battery-grey.png'} alt="India" style={{ height: 24 + 'px' }} />) }

                    </div>
                    <div className="col-xs-4 col-sm-2 col-lg-2 middle-status">
                        <i className={device.status === "ACTIF" ? "bg-success" : "bg-danger"} style={{ fontSize: 10 + 'px', padding: "4px" }}>{this.t(device.status)}</i>
                    </div>
                    <div className="col-xs-8 col-sm-2 col-lg-2 top-seen">
                        <div className="small text-muted">{this.t('LastSeen')}</div>
                        <strong>{lastSeenDay != 0 ? lastSeenDay + ` ${this.t('Short.Days')}` : lastSeenHour != 0 ? lastSeenHour + ` ${this.t('Short.Hours')}` : lastSeenMin +  ` ${this.t('Short.Minutes')}`}</strong>
                    </div>
                </div>
        )
    }
}

export default translate(AlarmSensor);


