import React, { Component } from 'react';
import moment from 'moment';
import $ from 'jquery';
import { TimePicker } from 'react-input-moment';
import Conf from '../../../utils/Conf';
import { getRequest, postRequest } from '../../../utils/WebServicesManager';
import SelectUser from '../../Selects/SelectUser';
import ReactDaytime from '../../react-daytime';
import { ButtonToolbar, ToggleButtonGroup, ToggleButton } from 'react-bootstrap';
import { Modal, ModalBody } from 'reactstrap';
import { translate } from '../../../utils/ReactMultiLang';
import { getUserId } from '../../../utils/AuthServices';
import ComplexAlarm from '../../ComplexAlarm/ComplexAlarm';


class SaveAlarm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      start: moment(),
      UserGroups: [],
      FieldList: [],
      end: moment(),
      doNotCreateAlert: false,
      Modal: false,
      Diagram: null || props.alarm ? props.alarm.diagramAlarm : null
    };
    this.theme = {
      cell: {
          backgroundColor: [ '#f2f2f2', '#3de3e6' ]
      },
      header: {
          color: [ '#000000', '#ffffff' ],
          backgroundColor: [ '#97e0c0', '#4f806a' ],
          fontFamily: '"Roboto Condensed", sans-serif'
      },
      border: {
          color: '#fff'
      }
    };

    this.callback = props.callback;
    this.getUserGroups();
    this.t = props.t;
    this.toggle = this.toggle.bind(this);
  }

  toggle(diagram) {
    this.setState({Diagram: diagram && diagram.cancelable ? null || this.state.Diagram : diagram, Modal: !this.state.Modal});
  }

  createAlerteChange() {
    this.setState({doNotCreateAlert: $("#doNotCreateAlert").is(':checked')});
  }

  renderUserGroup() {
    var toRender = [];
    for (var i = 0; i < this.state.UserGroups.length; i++) {
      toRender.push(<option value={this.state.UserGroups[i]._id}>{this.state.UserGroups[i].name}</option>);
    }
    return toRender;
  }
  getUserGroups() {
    var url = Conf.BaseApi + 'groups/getAll';
    return getRequest(url, (data) => {
      if (data.success) {
        this.setState({ UserGroups: data.result });
      }
    });
  }

  sendAlarm() {
    let alarm = {};
    if (this.props.alarm) {
      alarm = Object.create(this.props.alarm);
    }

    let ids = [];
    const inputs = $("input[name=userId]");
    for (let i = 0; i < inputs.length; i += 1) {
      ids.push(inputs[i].value);
    }
    alarm.active = "true";
    alarm.name = $("#alarmName").val();
    alarm.desc = $("#alarmMessage").val();
    alarm.group = $("#userGroup").val();
    alarm.alarmType = "complex";

    alarm.level = $('#levelSelector input:radio:checked').val();
    alarm.doNotCreateAlert = $("#doNotCreateAlert").is(':checked');
    alarm.alertNotif = $("#alertNotif").is(':checked');
    alarm.alertSms = $("#alertSms").is(':checked');
    alarm.alertMail = $("#alertMail").is(':checked');

    alarm.from = this.state.start.hour() + ":" + this.state.start.minute();
    alarm.to = this.state.end.hour() + ":" + this.state.end.minute();
    alarm.users = ids;
    alarm.diagramAlarm = this.state.Diagram;
    var url = Conf.BaseApi + 'alarms/add';
    if (this.props.alarm) {
      var url = Conf.BaseApi + 'alarms/edit';
    }
    return postRequest(url, {
      alarm
    }, (data) => {
      if (data.success || data.success == true) {
        if (this.callback != undefined) {
          this.callback();
        }
      } else {
        $("#error-window").text(data.message);
      }
    });
  }

  handleChangeStart = start => {
    this.setState({ start: start });
  };

  handleChangeEnd = end => {
    this.setState({ end: end });
  };
  
  renderDeviceFields(fields) {
    var toRender = [];
    for (var i = 0; i < fields.length; i++) {
      toRender.push(
        <option value={fields[i]}>{this.t(`SensorData.${fields[i]}`)}</option>
      );
    }
    return toRender;
  }
  
  handleChange(selectedRange) {
    console.log('Selected time frames: ', selectedRange);
  }

  render() {
    const { alarm, callback } = this.props;

    return (
      <div className="flex-row align-items-center">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12">
              <div className="card mx-12">
                <div className="card-block p-12">
                  <h2>{this.t('Alarm.Complex')}
                    <i className="icon-close color-danger" onClick={callback}
                      style={{ position: 'absolute', textAlign: 'right', right: '15px', cursor: 'pointer' }}></i></h2>
                  <div className="input-group mb-3" style={{ color: "red" }} id="error-window">

                  </div>
                  <div className="input-group mb-3">
                    <span className="input-group-addon"><i className="icon-tag"></i></span>
                    <input type="text" className="form-control" placeholder={this.t('Name')} id="alarmName" defaultValue={alarm !== undefined ? alarm.name : ""} />
                  </div>
                  <div className="input-group mb-3">
                    <span className="input-group-addon"><i className="icon-note"></i></span>
                    <input type="text" className="form-control" placeholder={this.t('Alarm.AlarmMessage')} id="alarmMessage" defaultValue={alarm !== undefined ? alarm.desc : ""} />
                  </div>

                  <div className="form-group mb-3">
                                    <label htmlFor="sensorType">{this.t('UserGroup')}</label>
                                    <select className="form-control" id="userGroup" value={alarm !== undefined ? alarm.group : ""} disabled={alarm !== undefined ? true : false}>
                                      {this.renderUserGroup()}
                                    </select>
                                </div>
                  <div className="input-group mb-3">
                  </div>
                  <br/>
                  {this.t('Alarm.UserToPrevent')}
                  <SelectUser scopeParam="linked" values={alarm !== undefined ? alarm.users : undefined}/>
                  
                  {this.t('Alarm.Level')}
                  <ButtonToolbar>
                      <ToggleButtonGroup type="radio" name="levelSelector" id="levelSelector" defaultValue={alarm !== undefined ? `${alarm.level}` : '2'}>
                        <ToggleButton value="1" bsStyle="warning">{this.t('Warning')}</ToggleButton>
                        <ToggleButton value="2" bsStyle="danger">{this.t('Danger')}</ToggleButton>
                      </ToggleButtonGroup>
                    </ButtonToolbar>
                  <br/>
                     
                  <div className="input-group mb-3">
                    <div className="form-check form-check-inline">
                      <label className="form-check-label">
                        <input className="form-check-input" type="checkbox" id="doNotCreateAlert"
                          value="true" onChange={() => this.createAlerteChange()} defaultChecked={alarm !== undefined ? alarm.doNotCreateAlert : false} /> 
                        {this.t('Alarm.DoNotDisable')}
                      </label>
                    </div>
                  </div>
                  {this.t('Alert.Type')}
                  { !this.state.doNotCreateAlert ?    
                  <div className="input-group mb-3">
                    <div className="form-check form-check-inline">
                      <label className="form-check-label">
                        <input className="form-check-input" type="checkbox" id="alertNotif" value="true"
                        defaultChecked={alarm !== undefined ? alarm.alertNotif : false} /> {this.t('Notification')}
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <label className="form-check-label">
                        <input className="form-check-input" type="checkbox" id="alertSms" value="true"
                        defaultChecked={alarm !== undefined ? alarm.alertSms : false}/> {this.t('Sms')}
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <label className="form-check-label">
                        <input className="form-check-input" type="checkbox" id="alertMail" value="true"
                        defaultChecked={alarm !== undefined ? alarm.alertMail : false} /> {this.t('Mail')}
                      </label>
                    </div>
                  </div> :
                  <div className="input-group mb-3">
                  <div className="form-check form-check-inline">
                    <label className="form-check-label">
                      <input className="form-check-input" type="checkbox" id="alertNotif" checked="checked" disabled/> {this.t('Notification')}
                    </label>
                  </div>
                  </div>
                  }
                  <button type="button" className="btn btn-block btn-primary"
                    onClick={this.toggle}>{alarm || (this.state.Diagram && this.state.Diagram != '') ? this.t('Alarm.EditDiagram') : this.t('Alarm.CreateDiagram')}</button>
                  <button type="button" className="btn btn-block btn-success" onClick={this.sendAlarm.bind(this)}>{alarm ? this.t('Update') : this.t('Create')}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal isOpen={this.state.Modal} className={'modal-lg ' + this.props.className}>
          <ModalBody>
            {this.state.Diagram && this.state.Diagram != '' ?
            <ComplexAlarm alarm={this.state.AlarmDetails} callback={this.toggle} actualDiagram={this.state.Diagram} /> :
            <ComplexAlarm alarm={this.state.AlarmDetails} callback={this.toggle} /> }
          </ModalBody>
        </Modal>
      </div>
    )
  }
}

/*

                  <div className="input-group">
                    <div className="wrapper-left col-md-6">

                      <div className="card-header">
                        Heure de début
                      </div>
                      <TimePicker
                        moment={this.state.start}
                        onChange={this.handleChangeStart}
                        locale="en"
                      />
                    </div>
                    <div className="wrapper-right col-md-6">
                      <div className="card-header">
                        Heure de fin
                      </div>
                      <TimePicker
                        moment={this.state.end}
                        onChange={this.handleChangeEnd}
                        locale="en"
                      />
                    </div>
                  </div>
*/

export default translate(SaveAlarm);