import React, { Component } from 'react';
import Conf from '../../../utils/Conf';
import { Doughnut } from 'react-chartjs-2';
import { Dropdown, DropdownMenu, DropdownItem, 
    DropdownToggle, Progress } from 'reactstrap';
import { getRequest } from '../../../utils/WebServicesManager';
import { translate } from '../../../utils/ReactMultiLang';

class Dognuts extends Component {
    constructor(props) {
        super(props);
        this.datas = {};
        this.options = {};
        this.state = {
            width:300,
            height:300,
            Sensors: [],
            card1: false
        }
        this.getSensors();
        this.t = props.t;
    }

    
  getSensors() {
    var url = Conf.BaseApi + `devices/get`;
    return getRequest(url, (body) => {
      if (body && body.success) {
        let result = body.result;
        this.setState({ Sensors: result });
      }
    });
  }

    updateDimensions(){
        if (this.refs.mainDiv.offsetWidth !== this.state.width 
            || this.refs.mainDiv.offsetHeight - 80 !== this.state.height) {
            let width = this.refs.mainDiv.offsetWidth;
            let height= this.refs.mainDiv.offsetHeight - 80;
            this.setState({width, height});
        }
      }
      componentDidMount(){
        this.updateDimensions(this);
      }
      componentDidUpdate() {
        this.updateDimensions(this);
      }

    generateData(sensors) {
        if (sensors && sensors !== undefined) {
            let datas = [];
            const labels = [];
            for (let i = 0; i < sensors.length; i += 1) {
                for (let j = 0; j < sensors[i].type.length; j += 1) {
                    if (!labels.includes(sensors[i].type[j])) {
                        labels.push(sensors[i].type[j]);
                        datas.push(1);
                    } else {
                        const index = labels.indexOf(sensors[i].type[j]);
                        datas[index] += 1;
                    }
                }
            }
            this.datas = {
                labels,
                datasets: [{
                    data: datas,
                    backgroundColor: [
                    '#FF6384',
                    '#36A2EB',
                    '#FFCE56',
                    '#1FCE56',
                    '#FFCEF6'
                    ],
                    hoverBackgroundColor: [
                    '#FF6384',
                    '#36A2EB',
                    '#FFCE56',
                    '#1FCE56',
                    '#FFCEF6'
                    ]
                }]
            };
        }
    }

    render() {
        
        const { title, deleteComponent } = this.props;
        this.generateData(this.state.Sensors);
        const chartStyle = {
            width: this.state.width,
            height: this.state.height
          };
        return (
                <div className={"card animated fadeIn "}  ref='mainDiv' style={{height:'100%'}}>
                    <div className="card-block pb-0">
                    
                    <div className="btn-group float-right">
                    <Dropdown isOpen={this.state.card1} toggle={() => { this.setState({ card1: !this.state.card1 }); }}>
                    <DropdownToggle onClick={() => { this.setState({ card1: !this.state.card1 }); }} className="btn active dropdown-toggle p-0" data-toggle="dropdown" aria-haspopup="true" aria-expanded={this.state.card1}>
                      <i className="icon-settings"></i>
                    </DropdownToggle>
                    <DropdownMenu right>
                        <DropdownItem onClick={() => {deleteComponent()} }><i className='icon-trash' style={{color: "#212121"}}></i>{this.t('Delete')}</DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                    </div>
                        <h4>{title}</h4>
                    </div>
                    <div className="chart-wrapper px-3" style={chartStyle}>
                        <Doughnut data={this.datas}
                        />
                    </div>
                </div>
        );
    }
}

export default translate(Dognuts);
