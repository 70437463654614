import React, { Component } from 'react';
import $ from 'jquery';
import Conf from '../../../utils/Conf';
import { getRequest, postRequest } from '../../../utils/WebServicesManager';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter,
  FormGroup, Row, Input, Label } from 'reactstrap';
import SelectUser from '../../../components/Selects/SelectUser';
import EditUser from '../../../components/EditUser';
import { translate } from '../../../utils/ReactMultiLang';
import { check } from '../../../utils/Can';

class Manage extends Component {
  constructor(props) {
    super(props);
    this.t = props.t;
    this.state = {
      Groups: [],
      MainUser: {},
      Modal: false,
      ApiKey: undefined,
      ModalEdit: false,
      CanEdit: check('user:edit')
    };
    this.getUserDetails();
    this.getUserGroups();
    this.getAPIKey();
    this.toggle = this.toggle.bind(this);
  }

  getUserGroups() {
    var url = Conf.BaseApi + 'groups/get';
    return getRequest(url, (data) => {
      if (data.success)
        this.setState({ Groups: data.result });
    });
  }
  getAPIKey() {
    var url = Conf.BaseApi + 'users/getApiKey';
    return getRequest(url, (data) => {
      if (data.success)
        this.setState({ ApiKey: data.result });
    });
  }

  getUserDetails() {
    var url = Conf.BaseApi + 'user';
    getRequest(url, (data) => {
      if (data.success) {
        this.setState({ MainUser: data.result });
        this.getUserList();
      }
    });
  }

  generateApiKey() {
    var url = Conf.BaseApi + "users/generateApiKey";
    getRequest(url, (data) => {
      if (data.success && data.success == true) {
        this.setState({ ApiKey: data.result });
      }
    });
  }

  renderUsersList() {
    var toRender = [];
    for (var i = 0; i < this.state.Groups.length; i++) {
      toRender.push(<li key={this.state.Groups[i]._id}><h5>{this.state.Groups[i].name}<small>({this.state.Groups[i].provider})</small></h5></li>);
    }
    return toRender;
  }

  renderMainUser() {
    var toRender = [];
    if (this.state.MainUser._id != undefined) {
      toRender.push(<div className="row" key={this.state.MainUser._id}>
        <div className="col-lg-6">
          <div className="descCustom">
            <div className="title">{this.t('Surname')}</div>
            <small>{this.state.MainUser.name}</small>
          </div>
          <div className="descCustom">
            <div className="title">{this.t('Email')}</div>
            <small>{this.state.MainUser.email}</small>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="descCustom">
            <div className="title">{this.t('Firstname')}</div>
            <small>{this.state.MainUser.firstName}</small>
          </div>
          <div className="descCustom">
            <div className="title">{this.t('Phone')}</div>
            <small>+{this.state.MainUser.phone}</small>
          </div>
        </div>
      </div>);
    }
    return toRender;
  }

  renderApiToken() {
    var toRender = [];
    if (this.state.ApiKey != undefined) {
      toRender.push(
          <div className="descCustom">
            <small>{this.state.ApiKey}</small>
          </div>);
    } else {      
      toRender.push(<button type="button" onClick={this.generateApiKey.bind(this)} className="btn btn-primary btn-lg" >{this.t('Account.GenerateKey')}</button>);
    }
    return toRender;
  }

  toggle() {
    this.setState({
      Modal: !this.state.Modal
    });
  }
  toggleEdit() {
    this.setState({
      ModalEdit: !this.state.ModalEdit
    });
    this.getUserDetails();
  }

  addUser() {
    var url = Conf.BaseApi + 'subusers/add';
    let ids = [];
    const inputs = $("input[name=userId]");
    for (let i = 0; i < inputs.length; i += 1) {
      ids.push(inputs[i].value);
    }
    const permission = {
      devices: $('input[name=devices]:checked', '#userRigth').val(),
      alarms: $('input[name=alarms]:checked', '#userRigth').val(),
      models: $('input[name=models]:checked', '#userRigth').val(),
      infras: $('input[name=infras]:checked', '#userRigth').val(),
      reports: $('input[name=reports]:checked', '#userRigth').val()
    };
    return postRequest(url, {
      usersId: ids,
      isAdmin:$("#isAdmin").val(),
      userGroup:$("#userGroup").val(),
      permission
    }, (data) => {
      if (data.success || data.success == true) {
        this.toggle();
        //this.toggleMessage();
        this.getUserList();
      } else {
        $("#error-window").text(data.message);
      }
    });
  }

  renderUserGroup() {
    var toRender = [];
    for (var i = 0; i < this.state.UserGroups.length; i++) {
        toRender.push(
          <option key={i} value={this.state.UserGroups[i]._id}>{this.state.UserGroups[i].name}</option>
        );
    }
    return toRender;
  }

  render() {
    const divStyle = {
      width: '60%'
    };

    return (
      <div className="row">
         <div className="col-lg-6 col-md-12">
          <div className="row ">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-header">
                  <i className="fa fa-align-justify"></i> {this.t('Account.AccDetails')}
                </div>
                <div className="card-block">
                  {this.renderMainUser()}
                  {this.state.CanEdit ?
                  <button type="button" onClick={this.toggleEdit.bind(this)} className="btn btn-primary btn-lg" >{this.t('Account.UpdateProfile')}</button>
                  : '' }
                </div>
              </div>
            </div>
          </div>
          { this.state.CanEdit ?
          <div className="row ">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-header">
                  <i className="fa fa-align-justify"></i> {this.t('Account.APIKey')}
                </div>
                <div className="card-block">
                  {this.renderApiToken()}
                </div>
              </div>
            </div>
          </div> : '' }
        </div>
        <div className="col-lg-6 col-md-12">
          <div className="card">
            <div className="card-header">
              <i className="fa fa-align-justify"></i> {this.t('Groups')}
            </div>
            <div className="card-block">
              <ul className="icons-list">
                {this.renderUsersList()}
              </ul>
            </div>
          </div>
        </div>
       
        <Modal isOpen={this.state.ModalEdit} className={'modal-md ' + this.props.className}>
                    <ModalHeader>
                        <Row style={{paddingLeft:'15px', paddingRight:'15px'}}>
                            <h1>{this.t('Account.ProfilEdit')}</h1>
                            <i className="icon-close color-danger" onClick={this.toggleEdit.bind(this)}
                                style={{position:'absolute', textAlign: 'right', right:'15px', cursor:'pointer'}}></i>
                        </Row>
                    </ModalHeader>
          <ModalBody>
            <EditUser user={this.state.MainUser} callback={this.toggleEdit.bind(this)} />
          </ModalBody>
        </Modal>
      </div>

    )
  }
}

export default translate(Manage);
