import React, { Component } from 'react';
import moment from 'moment';
import $ from 'jquery';
import { TimePicker } from 'react-input-moment';
import Conf from '../../../utils/Conf';
import { getRequest, postRequest } from '../../../utils/WebServicesManager';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import SelectGroup from '../../Selects/SelectGroup';
import SelectSensors from '../../Selects/SelectSensors';
import SelectAlarmModels from '../../Selects/SelectAlarmModel';
import { translate } from '../../../utils/ReactMultiLang';


class AddSigFox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      FieldList: [],
      DeviceModels: [],
      selectedModel: undefined
    };
    this.getDeviceModels();
    this.t = props.t;
  }
  
  renderSensorModels() {
    var toRender = [];
    for (var i = 0; i < this.state.DeviceModels.length; i++) {
      toRender.push(
        <option key={i} value={this.state.DeviceModels[i]}>{this.state.DeviceModels[i]}</option>
      );
    }
    return toRender;
  }


  getDeviceModels() {
    var url = Conf.BaseApi + 'helpers/getModels';
    return getRequest(url, (data) => {
      if (data.success)
        this.setState({ DeviceModels: data.result, selectedModel: data.result[0] });
    });
  }

  addSensor(callback) {
    var url = Conf.BaseApi + Conf.AddSensor;
    let groups = [];
    const inputs = $("input[name=groupsName]");
    for (let i = 0; i < inputs.length; i += 1) {
      groups.push(inputs[i].value);
    }
    // Building but comment is named sensors
    let sensors = [];
    const inputsSensors = $("input[name=sensorsName]");
    for (let i = 0; i < inputsSensors.length; i += 1) {
      sensors.push(inputsSensors[i].value);
    }

    let models = [];
    const inputsModels = $("input[name=alarmModels]");
    for (let i = 0; i < inputsModels.length; i += 1) {
      models.push(inputsModels[i].value);
    }

    return postRequest(url, {
      sensorName: $("#sensorName").val(),
      sensorUsage: $("#sensorUsage").val(),
      sensorDevEUI: $("#sensorDevEUI").val(),
      sensorModel: $("#sensorModel").val(),
      sensorAppEUI: $("#sensorAppEUI").val(),
      userGroup: $("#userGroup").val(),
      providerId: $("#provider").val(),
      sensorGroups: groups,
      building: sensors,
      alarmModels: models
    }, (data) => {
      if (data.success || data.success == true) {
        callback();
      } else {
        $("#error-window").text(data.message);
      }
    });
  }

  renderUserGroup(UserGroups) {
    var toRender = [];
    for (var i = 0; i < UserGroups.length; i++) {
            toRender.push(
                <option key={i} value={UserGroups[i]._id}>{UserGroups[i].name}</option>
            );
    }
    return toRender;
  }

  renderProviders(Providers, selectedProvider) {
    var toRender = [];
    for (var i = 0; i < Providers.length; i++) {
        if (selectedProvider._id === Providers[i]._id) {
            toRender.push(
                <option key={i} value={Providers[i]._id} selected>{Providers[i].name}({Providers[i].operator})</option>
            );
        } else {
            toRender.push(
                <option key={i} value={Providers[i]._id}>{Providers[i].name}({Providers[i].operator})</option>
            );
        }
    }
    return toRender;
  }

  renderSensorGroups(SensorGroups) {
    var toRender = [];
    for (var i = 0; i < SensorGroups.length; i++) {
      toRender.push(
        <option key={i} value={SensorGroups[i]}>{SensorGroups[i]}</option>
      );
    }
    return toRender;

  }

  providerChange(updateProvider, Providers, event) {
    let grp = {};
    for (let i = 0; i < Providers.length; i += 1) {
        if (Providers[i]._id === event.target.value) {
            grp = Providers[i];
            break ;
        }
    }
    updateProvider(grp);
  }

  userGroupChange(updateActGroup, UserGroups, event) {
    let grp = {};
    for (let i = 0; i < UserGroups.length; i += 1) {
        if (UserGroups[i]._id === event.target.value) {
            grp = UserGroups[i];
            break ;
        }
    }
    updateActGroup(grp);
  }

  selectModel(event) {
    this.setState({selectedModel: event.target.value});
  }

  render() {
    const { UserGroups, SensorGroups, callback, updateActProvider, selectedProvider, Providers, updateActGroup, selectedGroup } = this.props;
    return (
        <ModalBody>
        <div className="flex-row align-items-center">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-12">
                <div className="card mx-12">
                  <div className="card-block p-12">
                    <p className="text-muted">{this.t('Sensor.Sigfox')}</p>
                    <div className="input-group mb-3" style={{ color: "red" }} id="error-window">

                    </div>
                    <div className="form-group mb-3">
                      <label htmlFor="sensorType">{this.t('UserGroup')}</label>
                      <select className="form-control" id="userGroup" onChange={this.userGroupChange.bind(this, updateActGroup, UserGroups)}>
                        {this.renderUserGroup(UserGroups, selectedGroup)}
                      </select>
                    </div>
                    <div className="form-group mb-3">
                      <label htmlFor="sensorType">{this.t('Provider')}</label>
                      <select className="form-control" id="provider" onChange={this.providerChange.bind(this, updateActProvider, Providers)}>
                        {this.renderProviders(Providers, selectedProvider)}
                      </select>
                    </div>
                    <div className="input-group mb-3">
                      <span className="input-group-addon"><i className="icon-tag"></i></span>
                      <input type="text" className="form-control" placeholder={this.t('Name')} id="sensorName" />
                    </div>
                    <div className="input-group mb-3">
                      <select className="form-control" id="sensorModel" onChange={this.selectModel.bind(this)}>
                        {this.renderSensorModels()}
                      </select>
                    </div>
                    <label htmlFor="userGroup">{this.t('Model.Model')}</label>
                            <SelectAlarmModels model={this.state.selectedModel}/>
                    <div className="input-group mb-3">
                      <span className="input-group-addon"><i className="icon-wrench"></i></span>
                      <input type="text" className="form-control" placeholder="Id" id="sensorDevEUI" />
                    </div>
                    <div className="input-group mb-3">
                      <span className="input-group-addon"><i className="icon-wrench"></i></span>
                      <input type="text" className="form-control" placeholder="PAC" id="sensorAppEUI" />
                    </div>
                    <div className="input-group mb-3">
                      <span className="input-group-addon"><i className="icon-lock"></i></span>
                      <input type="text" className="form-control" placeholder={this.t('Usage')} id="sensorUsage" />
                    </div>
                    <label htmlFor="userGroup">{this.t('Tags')}</label>
                            <SelectGroup entity='devices'/>
                      <label htmlFor="userGroup">{this.t('Infrastructures')}</label>
                            <SelectSensors multi={false} entity='buildings'/>
                    <button type="button" className="btn btn-block btn-success" onClick={this.addSensor.bind(this, callback)}>{this.t('Add')}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ModalBody>
    )
  }

}

export default translate(AddSigFox);