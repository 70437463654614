import React, { Component } from 'react';
import Conf from '../../../utils/Conf';
import { Dropdown, DropdownMenu, DropdownItem, DropdownToggle, Progress } from 'reactstrap';
import $ from 'jquery';
import { getRequest, postRequest, reportGetRequest, reportPostRequest } from '../../../utils/WebServicesManager';
import { Bar } from 'react-chartjs-2';
import { Min, Max, getMonthName, getWeek } from '../../../utils/Utils';
import toPromise from '../../../utils/to';
import { translate } from '../../../utils/ReactMultiLang';

class BarChats extends Component {
  constructor(props) {
    super(props);
    this.datas = {};
    this.options = {};
    this.actualData = 0;
    this.state = {
      dropdownOpen: false,
      width: 300,
      height: 300,
      Messages: [],
      isPublic: props.isPublic,
      isLocked: props.isLocked
    };
    this.getRequest = getRequest;
    this.postRequest = postRequest;
    if (props.isPublic) {
      this.getRequest = reportGetRequest;
      this.postRequest = reportPostRequest;
    }
    this.ChartKey = 0;
    this.t = props.t;
    this.minTimeBetPoints = 10 * 60; // secondes
    this.getMessage(props.chartDatas, props.component);
  }

  async getMessagesSince(id, since) {
    var url = Conf.BaseApi + `messages/getComputed/since/${id}/${since}`;
    return this.getRequest(url, (body) => {
      if (body.success) {
        const messages = body.result;
        messages.sort((a, b) => {
          if (a.time > b.time)
            return 1;
          if (a.time < b.time)
            return -1;
          return 0;
        });
        let tmpSensorMsg = this.state.Messages;
        tmpSensorMsg[id] = messages;
        this.setState({ Messages: tmpSensorMsg });
        this.getMessagesList(tmpSensorMsg, this.props.chartDatas);
        this.updateDimensions();
      }
    });
  }

  async getMessagesStatic(id, from, to) {
    var url = Conf.BaseApi + `messages/getBetween/devices`;
    return this.postRequest(url, {
      devices: [id],
      from,
      to
    }, (body) => {
      if (body.success) {
        const messages = body.result[id];
        if (messages) {
          messages.sort((a, b) => {
            if (a.time > b.time)
              return 1;
            if (a.time < b.time)
              return -1;
            return 0;
          });
          let tmpSensorMsg = this.state.Messages;
          tmpSensorMsg[id] = messages;
          this.setState({ Messages: tmpSensorMsg });
          this.getMessagesList(tmpSensorMsg, this.props.chartDatas);
          this.updateDimensions();
        }
      }
    });
  }

  getMessage(chartDatas, component) {
    if (chartDatas && chartDatas !== undefined) {
      const usedDevEUI = [];
      for (let i = 0; i < chartDatas.length; i += 1) {
        if (!usedDevEUI.includes(chartDatas[i].sensor)) {
          if (component.from) {
            const from = Date.parse(component.from) / 1000;
            const to = Date.parse(component.to) / 1000;
            this.getMessagesStatic(chartDatas[i].sensor, from, to);
          } else {
            usedDevEUI.push(chartDatas[i].sensor);
            const dateDays = (component.days ? component.days : 1) * 24 * 60 * 60; // in seconds
            const dateMinusDays = Math.floor(Date.now() / 1000) - dateDays;
            this.getMessagesSince(chartDatas[i].sensor, dateMinusDays);
          }
        }
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.component && nextProps.component.dataSets
      && this.props.component && this.props.component.dataSets && (
        nextProps.component.days !== this.props.component.days
        || nextProps.component.from !== this.props.component.from
        || nextProps.component.dataSets.length !== this.props.component.dataSets.length)) {
      this.ChartKey += 1;
      this.getMessage(nextProps.chartDatas, nextProps.component);
    }
    if (this.props.isPublic !== nextProps.isPublic) {
      this.setState({
        isPublic: nextProps.isPublic
      });
      if (nextProps.isPublic) {
        this.getRequest = reportGetRequest;
        this.postRequest = reportPostRequest;
      }
    }
  }

  componentDidMount() {
    this.ChartKey += 1;
    this.updateDimensions();
  }


  updateDimensions() {
    if (this.refs.mainDiv) {
      if (this.refs.mainDiv.offsetWidth + 10 < this.state.width
        || this.refs.mainDiv.offsetWidth - 10 > this.state.width
        || this.refs.mainDiv.offsetHeight - 50 !== this.state.height) {
        let width = this.refs.mainDiv.offsetWidth;
        let height = this.refs.mainDiv.offsetHeight - 50;
        this.setState({ width, height });
        this.ChartKey += 1;
      }
    }
  }
  componentDidUpdate() {
    this.updateDimensions();
  }

  getMessageDiff(level, messages, paramName) {
    let lastMonth = -1;
    let lastVal = -1;
    const label = [];
    const values = [];
    let pos = 1;
    let first = 0;
    for (var i = 0; i < messages.length; i++) {
      if (messages[i].data[paramName]) {
        var d = new Date(messages[i].time * 1000);
        let month = d.getMonth();
        switch (level) {
          case 0: //days
            month = d.getDay();
            break;
          case 1: //week
            const getWeekTmp = getWeek.bind(d)
            month = getWeekTmp();
            break;
          case 3: //hours
            month = d.getHours();
            break;
          default: //month
            break;
        }
        if (lastMonth === -1) {
          lastMonth = month;
          lastVal = Math.trunc(parseFloat(messages[i].data[paramName]) * 10) / 10;
        }
        if (month === lastMonth && i < messages.length - 1) {
          continue;
        } else {
          switch (level) {
            case 0: //days
              d.setDate(d.getDate() - 1);
              label.push(d.getDate() + '/' + ((d.getMonth() + 1 > 9) ? d.getMonth() + 1 : '0' + (d.getMonth() + 1)));
              break;
            case 1: //week
              label.push(lastMonth);
              break;
            case 3: //hours
              label.push(d.getDate() + '/' + ((d.getMonth() + 1 > 9) ? d.getMonth() + 1 : '0' + (d.getMonth() + 1)) + ' ' + d.getHours() + 'h');
              break;
            default: //month
              label.push(this.t(getMonthName(lastMonth)));
              break;
          }
          const nVal = Math.trunc(parseFloat(messages[i].data[paramName]) * 10) / 10;
          values.push({ x: pos, y: Math.trunc((nVal - lastVal) * 10) / 10 });
          pos++;
          lastMonth = month;
          lastVal = nVal;
        }
      }
    }
    if (values && values.length > 0) {
      values.shift();
      label.shift();
    }
    return [values, label];
  }
  getMessageAverage(level, messages, paramName) {
    let lastMonth = -1;
    let lastVal = 0;
    let nbVal = 1;
    const label = [];
    const values = [];
    let pos = 1;
    for (var i = 0; i < messages.length; i++) {
      if (messages[i].data[paramName]) {
        var d = new Date(messages[i].time * 1000);
        let month = d.getMonth();
        switch (level) {
          case 0: //days
            month = d.getDay();
            break;
          case 1: //week
            const getWeekTmp = getWeek.bind(d)
            month = getWeekTmp();
            break;
          case 3: //hours
            month = d.getHours();
            break;
          default: //month
            month = d.getMonth();
            break;
        }
        if (lastMonth === -1) {
          lastMonth = month;
          lastVal = Math.trunc(parseFloat(messages[i].data[paramName]) * 10) / 10;
        } else if (month === lastMonth) {
          lastVal += Math.trunc(parseFloat(messages[i].data[paramName]) * 10) / 10;
          nbVal += 1;
          continue;
        } else {
          switch (level) {
            case 0: //days
              d.setDate(d.getDate() - 1);
              label.push(d.getDate() + '/' + ((d.getMonth() + 1 > 9) ? d.getMonth() + 1 : '0' + (d.getMonth() + 1)));
              break;
            case 1: //week
              label.push(month);
              break;
            case 3: //hours
              label.push(d.getDate() + '/' + ((d.getMonth() + 1 > 9) ? d.getMonth() + 1 : '0' + (d.getMonth() + 1)) + ' ' + d.getHours() + 'h');
              break;
            default: //month
              label.push(this.t(getMonthName(lastMonth)));
              break;
          }
          values.push({ x: pos, y: Math.trunc(lastVal / nbVal * 10) / 10 });
          nbVal = 1;
          pos += 1;
          lastMonth = month;
          lastVal = Math.trunc(parseFloat(messages[i].data[paramName]) * 10) / 10;
        }
      }
    }
    return [values, label];
  }

  getMessagesList(messagesArray, component) {
    const chartDatas = component.dataSets;
    const { level, barMode } = component; // Level | 0: Day - 1: Week - 2: Month // barMode | true: concat - false: debut - fin
    var datasArray = [];
    var labelsArray = [];
    if (!chartDatas) return 0;
    for (let lineNumber = 0; lineNumber < chartDatas.length; lineNumber += 1) {
      const { paramName, sensor } = chartDatas[lineNumber];
      const messages = messagesArray[sensor];
      if (!messages || !paramName) continue;

      let [datas, labels] = [[], []];
      switch (barMode) {
        case 0:
          [datas, labels] = this.getMessageDiff(level, messages, paramName);
          break;
        case 1:
          [datas, labels] = this.getMessageDiff(level, messages, paramName);
          break;
        default:
          [datas, labels] = this.getMessageAverage(level, messages, paramName);
          break;

      }

      datasArray.push(datas);
      labelsArray.push(labels);
    }
    if (datasArray.length == 0) {
      const state = JSON.parse(localStorage.getItem(`BarChart_${this.props.component ? this.props.component._id : '123456'}`) || '{}');
      datasArray = state.datas || [[0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]];
      labelsArray = state.labels || [[0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]];
    } else {
      this.customSetState({ datas: datasArray, labels: labelsArray });
    }
    let minLength = 999;
    for (let i = 0; i < datasArray.length; i += 1) {
      if (minLength > datasArray[i].length)
        minLength = datasArray[i].length;
    }
    const labels = labelsArray[0].slice(0, minLength);
    for (let i = 0; i < datasArray.length; i += 1) {
      datasArray[i] = datasArray[i].slice(0, minLength);
    }
    if (datasArray[0][datasArray[0].length - 1] !== undefined)
      this.actualData = datasArray[0][datasArray[0].length - 1].y;
    this.datas = {
      labels: labels,
      datasets: [
      ]
    };
    let min = 9999;
    let max = -9999;
    for (let i = 0; i < datasArray.length; i += 1) {
      const tmpMin = Min(datasArray[i].map((elem) => elem.y));
      const tmpMax = Max(datasArray[i].map((elem) => elem.y));
      if (tmpMin < min) min = tmpMin;
      if (tmpMax > max) max = tmpMax;
      this.datas.datasets.push({
        label: chartDatas[i].label + ' ' + `(${chartDatas[i].unit})`,
        fill: false,
        lineTension: 0.2,
        backgroundColor: chartDatas[i].color,
        borderColor: chartDatas[i].color,
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: 'rgba(190,190,190,1)',
        pointBackgroundColor: '#fff',
        pointBorderWidth: 0,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: chartDatas[i].color,
        pointHoverBorderColor: 'rgba(75,75,75,0)',
        pointHoverBorderWidth: 1,
        pointRadius: 1,
        pointHitRadius: 10,
        data: datasArray[i]
      });
    }
    const step = (max - min) / 10;
    this.options = {
      layout: {
        padding: {
          left: 20,
          right: 20,
          top: 20,
          bottom: 20
        }
      },
      hover: {
        animationDuration: 0,
        mode: 'point'
      },
      animation: {
        duration: 750,
        easing: 'easeInQuad'
      }, tooltips: {
        mode: 'point',
        callbacks: {
          label: function (tooltipItem, data) {
            return tooltipItem.yLabel;
          }
        },
        backgroundColor: '#FFF',
        borderColor: '#000',
        titleFontSize: 12,
        titleFontColor: '#0066ff',
        bodyFontColor: '#000',
        bodyFontSize: 14
      },
      legend: {
        display: true
      },
      scales: {
        yAxes: [{
          ticks: {
            min: min - step,
            max: max + step,
            stepSize: step
          }
        }]
      },
      maintainAspectRatio: false
    };
  }

  render() {

    const { component, changeSensor, sensorList, unit, subtitle, editComponent, deleteComponent, noButton, addLine } = this.props;

    this.getMessagesList(this.state.Messages, component);

    const chartStyle = {
      width: this.state.width,
      height: this.state.height
    };
    return (
      <div className={"card grid-element"} ref='mainDiv' style={{ height: '100%' }}>
        <div className="card-block pb-0">
          {this.state.isPublic || this.state.isLocked ? '' : <div className="btn-group float-right">
            <Dropdown isOpen={this.state.card1} toggle={() => { this.setState({ card1: !this.state.card1 }); }}>
              <DropdownToggle onClick={() => { this.setState({ card1: !this.state.card1 }); }} className="btn active dropdown-toggle p-0" data-toggle="dropdown" aria-haspopup={true} aria-expanded={this.state.card1}>
                <i className="icon-settings"></i>
              </DropdownToggle>
              {!noButton ?
                <DropdownMenu right>
                  <DropdownItem onClick={() => { editComponent() }}><i className='icon-pencil' style={{ color: "#212121" }}></i>{this.t('Edit')}</DropdownItem>
                  <DropdownItem onClick={() => { addLine() }}><i className='icon-graph' style={{ color: "#212121" }}></i>{this.t('Component.AddCurve')}</DropdownItem>
                  <DropdownItem onClick={() => { deleteComponent() }}><i className='icon-trash' style={{ color: "#212121" }}></i>{this.t('Delete')}</DropdownItem>
                </DropdownMenu>
                : <DropdownMenu right>
                  {this.getSensorList(sensorList, changeSensor)}
                </DropdownMenu>}
            </Dropdown>
          </div>}
          {this.state.Messages.length === 1 ?
            <h4 className="mb-0">{this.actualData}{unit}</h4> : ''}
          {this.state.Messages.length !== 1 ? <h4>{subtitle}</h4> : <p>{subtitle}</p>}
        </div>
        <div className="chart-wrapper px-3"
          style={chartStyle}>
          <Bar data={this.datas}
            key={this.ChartKey}
            options={this.options}
          />
        </div>
      </div>
    )
  }

  customSetState(state) {
    localStorage.setItem(`BarChart_${this.props.component ? this.props.component._id : '123456'}`, JSON.stringify(state));
  }
}

export default translate(BarChats);
