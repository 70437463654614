import React, { Component } from "react";
import PropTypes from 'prop-types';

class TrayItemWidget extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		const { color, picto } = this.props;
		return (
			<div
				draggable={true}
				onDragStart={event => {
					event.dataTransfer.setData("storm-diagram-node", JSON.stringify(this.props.model));
				}}
				className={"tray-item " + color}
			>
				<img src={`./img/picto/${picto}.png`} width='20px'/>
				{this.props.name}
			</div>
		);
	}
}


TrayItemWidget.propTypes = {
    model: PropTypes.object.isRequired,
    name: PropTypes.string.isRequired,
    color: PropTypes.string
};
  
  export default TrayItemWidget;