import React, { Component } from 'react';
import $ from 'jquery';
import Conf from '../../../utils/Conf';
import { getRequest, postRequest } from '../../../utils/WebServicesManager';
import { Modal, ModalHeader, ModalBody, Col, Row } from 'reactstrap';
import { translate } from '../../../utils/ReactMultiLang';
import Select from 'react-select';


class EditAlertSensor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Sensors: props.sensors
    }
    this.t = props.t;
  }
  
  componentWillReceiveProps(nextProps) {
    if (nextProps.sensor !== this.props.sensor) {
      this.setState({Sensor: nextProps.sensor, Customs: nextProps.sensor.customFields});
    }
  }

  updateUserToSend(users) {
    this.setState({ usersToSend: users });
  }

  editSensor(callback) {
    var url = Conf.BaseApi + Conf.ToggleAlert;
    
    let users = [];
    let inputsAlert = $("input[name=usersToSend]");
    for (let i = 0; i < inputsAlert.length; i += 1) {
      users.push(inputsAlert[i].value);
    }
    const inactiveAlert = {};
    inactiveAlert.duration = $('#timeBeforeInactive').val();
    const sensorIds = this.state.Sensors;
    const alertIfUnseen = $('#alertIfUnseen').is(':checked');

    return postRequest(url, {
      sensorIds, users, alertIfUnseen, inactiveAlert
    }, (data) => {
      if (data.success || data.success == true) {
        callback();
      } else {
        $("#error-window").text(data.message);
      }
    });
  }



  render() {
    const { callback  } = this.props;

    return (
        <ModalBody>
        <div className="flex-row align-items-center">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-12">
                <div className="card mx-12">
                  <div className="card-block p-12">
                    <div className="input-group mb-3" style={{ color: "red" }} id="error-window">

                    </div>
                      <p>{this.t('Sensor.Number')} : {this.state.Sensors.length}</p>
                      
                      {this.t('Sensor.IsAlert')} :{' '}
                    <label className="switch switch-text switch-pill switch-success middle-status">
                      <input type="checkbox" className="switch-input" id="alertIfUnseen" />
                        <span className="switch-label" data-on="On" data-off="Off"></span>
                        <span className="switch-handle" ></span>
                      </label>

                    <Col md='12'>
                          {this.t('Users')} :
                            <Select.Creatable 
                              multi={true}
                              value={this.state.usersToSend}
                              onChange={this.updateUserToSend.bind(this)}
                              backspaceRemoves={this.state.backspaceRemoves}
                              id="usersToSend"
                              name="usersToSend"
                              />
                        </Col>
                        <br />
                    <Col md='12'>
                        <div className="input-group mb-3">
                          <span className="input-group-addon">{this.t('Duration')}({this.t('Hours')})</span>
                          <input type="number" className="form-control" placeholder={this.t('TimeBeforeInactive')} defaultValue={24} id="timeBeforeInactive" />
                        </div>
                        </Col>
<br/>
                    
                    <button type="button" className="btn btn-block btn-success" onClick={this.editSensor.bind(this, callback)}>{this.t('Send')}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ModalBody>
    )
  }

}

export default translate(EditAlertSensor);