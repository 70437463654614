import React, { Component } from 'react';
import $ from 'jquery';
import Conf from '../../../utils/Conf';
import { getDateStr } from '../../../utils/Utils';
import { getRequest, postRequest } from '../../../utils/WebServicesManager';
import { Row, Modal, ModalHeader, ModalBody, Col } from 'reactstrap';
import CreateAlarm from '../../../components/CreateAlarm';
import SaveAlarm from '../../../components/CreateAlarm/SaveAlarm';
import EditComplex from '../../../components/EditAlarm/EditComplex';
import { translate } from '../../../utils/ReactMultiLang';
import { getUserId } from '../../../utils/AuthServices';
import { check } from '../../../utils/Can';

class Manage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Alarms: [],
      Modal: false,
      ModalEdit: false,
      ModalComplex: false,
      ActualPage: 0,
      AlarmDetails: {},
      AlertList: [],
      CanCreate: check('alarm:create'),
      CanEdit: check('alarm:edit'),
      CanRemove: check('alarm:remove')
    };
    this.getAlarmsList();
    this.toggle = this.toggle.bind(this);
    this.toggleComplex = this.toggleComplex.bind(this);
    this.t = props.t;
  }

  getAlarmeDetails(id) {
    this.state.ActualPage = 0;
    var url = Conf.BaseApi + "alarms/getOne/" + id;
    getRequest(url, (data) => {
      if (data.success)
        this.setState({ AlarmDetails: data.result });
    });
    url = Conf.BaseApi + 'alerts/getfrom/alarm/' + id;
    getRequest(url, (data) => {
      if (data.success)
        this.setState({ AlertList: data.result });
    });
  }
  getAlarmsList() {
    var url = Conf.BaseApi + 'alarms';
    return getRequest(url, (data) => {
      if (data.success)
        this.setState({ Alarms: data.result });
    });
  }

  toggleAlarm(id, event) {
    var url = Conf.BaseApi + 'alarms/toggle';
    return postRequest(url, { alarmId: id, active: event.target.checked }, (data) => {
    });
  }

  renderAlarmsList() { // TODO
    var toRender = [];
    for (var i = 0; i < this.state.Alarms.length; i++) {
      var deviceEUI = this.state.Alarms[i].device;

      toRender.push(
        <li key={i + this.state.Alarms[i]._id}>
          <i className={this.state.Alarms[i].alarmType && this.state.Alarms[i].alarmType === 'complex' ? `icon-settings bg-warning` : `icon-bell bg-danger`} onClick={this.getAlarmeDetails.bind(this, this.state.Alarms[i]._id)}></i>
          <div className="desc sensorListItem" onClick={this.getAlarmeDetails.bind(this, this.state.Alarms[i]._id)}>
            <div className="title">{this.state.Alarms[i].name}</div>
            <small>{this.state.Alarms[i].data} {this.state.Alarms[i].type}  {this.state.Alarms[i].dailyRestart ? this.t('Alarm.DailyRestart') : ''}</small>
          </div>
          <div className="value">
            {this.state.CanEdit ?
              <label className="switch switch-text switch-pill switch-success middle-status">
                {this.state.Alarms[i].active ? <input type="checkbox" className="switch-input" onChange={this.toggleAlarm.bind(this, this.state.Alarms[i]._id)} defaultChecked /> :
                  <input type="checkbox" className="switch-input" onChange={this.toggleAlarm.bind(this, this.state.Alarms[i]._id)} />}
                <span className="switch-label" data-on="On" data-off="Off"></span>
                <span className="switch-handle" ></span>
              </label> : ''}
          </div>
          <div className="actions">
            {this.state.CanRemove
              ? <button value={this.state.Alarms[i]._id} onClick={this.removeAlarm.bind(this, this.state.Alarms[i]._id)} type="button" className="btn btn-link text-danger"><i className="icon-close"></i></button> : ""}
          </div>
        </li>
      );
    }
    return toRender;
  }

  toggle() {
    this.setState({
      Modal: !this.state.Modal
    });
    this.getAlarmsList();
  }
  toggleComplex() {
    this.setState({
      ModalComplex: !this.state.ModalComplex
    });
    this.getAlarmsList();
  }
  toggleEdit() {
    this.setState({
      ModalEdit: !this.state.ModalEdit
    });
    this.getAlarmeDetails(this.state.AlarmDetails._id);
  }

  removeAlarm(alarmId) { // TODO
    var r = window.confirm(this.t('Popup.Delete.Alarm'));
    if (r == true) {
      var url = Conf.BaseApi + Conf.RemoveAlarm + alarmId;
      getRequest(url, (data) => {
        if (data.success && data.success == true) {
          this.getAlarmsList();
        }
      });
    }
  }

  sortAlarmName(desc) {
    const { Alarms } = this.state;
    Alarms.sort((a, b) => (a.name < b.name && desc) || (a.name > b.name && !desc) ? -1 : 1);
    this.setState({Alarms: Alarms});
  }
  sortAlarmStatus(desc) {
    const { Alarms } = this.state;
    Alarms.sort((a, b) => (a.active && desc) || (!a.active && !desc) ? -1 : 1);
    this.setState({Alarms: Alarms});
  }

  render() {
    const divStyle = {
      width: '60%'
    };

    return (
      <div className="row">
        <div className="col-lg-6 col-md-12">
          <div className="card">
            <div className="card-header">
              <i className="fa fa-align-justify"></i> {this.t('Alarm.List')}
            </div>
            <div className="card-block">

              <ul className="icons-list">
                <li >
                  <i></i>
                  <div className="desc sensorListItem arrow">
                    <div class="up-arrow" onClick={() => this.sortAlarmName(true)}>

                    </div>
                    <div class="down-arrow" onClick={() => this.sortAlarmName(false)}>

                    </div>
                  </div>
                  <div className="value">
                    <div class="up-arrow"  onClick={() => this.sortAlarmStatus(true)}>

                    </div>
                    <div class="down-arrow" onClick={() => this.sortAlarmStatus(false)}>

                    </div>
                  </div>
                </li>
                {this.renderAlarmsList()}
              </ul>
              <br />
              {this.state.CanCreate ? <Row>
                <Col lg={6}>
                  <button type="button" onClick={this.toggle} className="btn btn-primary btn-block" >{this.t('Alarm.Add')}</button>
                </Col>
                <Col lg={6}>
                  <button type="button" onClick={this.toggleComplex} className="btn btn-success btn-block" >{this.t('Alarm.Complex')}</button>
                </Col>
              </Row> : ""}
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-12">
          <div className="row ">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-header">
                  <i className="fa fa-align-justify"></i> {this.t('Alarm.Details')}
                </div>
                <div className="card-block">
                  {this.renderAlarmDatails()}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-header">
                  <i className="fa fa-align-justify"></i> {this.t('Alerts')}
                </div>
                <div className="card-block">
                  <ul className="pagination">
                    <li className="page-item page-link" onClick={this.changePageCount.bind(this, -1)}>{this.t('Prev')}</li>
                    <li className="page-item page-link" onClick={this.changePageCount.bind(this, 1)}>{this.t('Next')}</li>
                  </ul>
                  {this.renderAlertList()}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal isOpen={this.state.Modal} className={'modal-lg ' + this.props.className}>
          <ModalBody>
            <CreateAlarm callback={this.toggle.bind(this)} />
          </ModalBody>
        </Modal>
        <Modal isOpen={this.state.ModalComplex} className={'modal-md ' + this.props.className}>
          <ModalBody>
            <SaveAlarm callback={this.toggleComplex.bind(this)} />
          </ModalBody>
        </Modal>
        <Modal isOpen={this.state.ModalEdit} className={'modal-lg ' + this.props.className}>
          <ModalBody>
            {this.state.AlarmDetails.alarmType !== 'complex' ?
              <CreateAlarm alarm={this.state.AlarmDetails} callback={this.toggleEdit.bind(this)} />
              : <SaveAlarm alarm={this.state.AlarmDetails} callback={this.toggleEdit.bind(this)} />}
          </ModalBody>
        </Modal>
      </div >
    )
  }

  renderAlertList() {
    var toRender = [];
    for (var i = 10 * this.state.ActualPage; i < (10 * this.state.ActualPage + 10); i++) {
      if (i >= this.state.AlertList.length)
        break;
      var d = new Date(this.state.AlertList[i].time);
      var tmplabel = d.getDate() + '/' + ((d.getMonth() > 9) ? d.getMonth() + 1 : '0' + (d.getMonth() + 1)) + '/' + d.getFullYear() + ' ' + d.getHours() + 'h' + ((d.getMinutes() > 9) ? d.getMinutes() : '0' + d.getMinutes());

      toRender.push(
        <div className="descCustom" key={i}>
          <span className="title">{tmplabel}&nbsp;&nbsp;
            {this.state.AlertList[i].name} : <small>{this.state.AlertList[i].desc}</small> {this.state.AlertList[i].closedAt ? ` (${getDateStr(new Date(this.state.AlertList[i].closedAt).getTime())})` : ''}</span>
        </div>);
    }
    return toRender;
  }

  renderAlarmDatails() {
    const alarm = this.state.AlarmDetails;
    const useTime = alarm && (alarm.from !== 0 || alarm.to !== 0) ? true : false;

    var toRender = [];
    if (this.state.AlarmDetails._id != undefined) {
      toRender.push(<div className="row" key={this.state.AlarmDetails._id}>
        <div className="col-lg-4">
          <div className="descCustom">
            <div className="title">{this.t('Name')}</div>
            <small>{this.state.AlarmDetails.name}</small>
          </div>
          <div className="descCustom">
            <div className="title">{this.t('Alarm.AlarmMessage')}</div>
            <small>{this.state.AlarmDetails.desc}</small>
          </div>
          <div className="descCustom">
            <div className="title">{this.t('Alarm.Type')}</div>
            <small>{this.state.AlarmDetails.alarmType}</small>
          </div>
          <div className="descCustom">
            <div className="title">{this.t('Alarm.Value')}</div>
            <small>
              {alarm.comparator >= 3 ? alarm.value : ''}
              {alarm.comparator == 3 ? ' > ' : ''}
              {alarm.comparator == 4 ? ' < ' : ''}
              {this.t(`SensorData.${alarm.dataName}`)}
              {alarm.comparator == 0 ? ' > ' : ''}
              {alarm.comparator == 1 ? ' < ' : ''}
              {alarm.comparator == 2 ? ' = ' : ''}
              {alarm.comparator == 3 ? ' > ' : ''}
              {alarm.comparator == 4 ? ' < ' : ''}
              {alarm.comparator >= 3 ? alarm.maxValue : alarm.value}
            </small>
          </div>
          <div className="descCustom">
            <div className="title">{this.t('Alert.Type')}</div>
            <small>{this.state.AlarmDetails.alertNotif ? this.t('Notification') : ''}</small>
            <small>{this.state.AlarmDetails.alertSms ? this.t('Sms') : ''}</small>
            <small>{this.state.AlarmDetails.alertMail ? this.t('Mail') : ''}</small>
            <small>{this.state.AlarmDetails.alertCall ? this.t('Call') : ''}</small>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="descCustom">
            <div className="title">{this.t('Alarm.Sensors')}</div>
            {this.renderDeviceList(this.state.AlarmDetails.sensors)}
          </div>
          {useTime ?
            <div className="descCustom">
              <div className="title">{this.t('Alarm.ActiveTime')}</div>
              {this.t('From')} {alarm && alarm.from ?
                (parseInt(alarm.from / 100, 10) < 10 ? '0' : '') + parseInt(alarm.from / 100, 10) + ':' +
                (alarm.from % 100 < 10 ? '0' : '') + alarm.from % 100 : '00:00'} {this.t('To')}  {alarm && alarm.to ?
                  (parseInt(alarm.to / 100, 10) < 10 ? '0' : '') + parseInt(alarm.to / 100, 10) + ':' +
                  (alarm.to % 100 < 10 ? '0' : '') + alarm.to % 100 : '00:00'}
              <br />
              {alarm.days ? Conf.WeekDay.map((w, i) => alarm.days[w.toLowerCase()] ? ` ${this.t(w)}` : '') : ''}
            </div>
            : ''}
        </div>
        <div className="col-lg-4">
          <div className="descCustom">
            <div className="title">{this.t('Alarm.UserToPrevent')}</div>
            {this.renderUsersList(this.state.AlarmDetails.users)}
          </div>
        </div>
        <div className="col-lg-8">
          <div className="descCustom">
            {this.state.CanEdit
              ? <button type="button" onClick={this.toggleEdit.bind(this)} className="btn btn-primary btn-lg" >{this.t('Alarm.Edit')}</button> : ""}
          </div>
        </div>
      </div>);
    }
    return toRender;
  }

  renderUsersList(users) {
    var toRender = [];
    for (let i = 0; i < users.length; i += 1) {
      if (users[i] != undefined)
        toRender.push(<small key={i}>{users[i].name} {users[i].firstName}<br /></small>);
    }
    return toRender;
  }
  renderDeviceList(sensors) {
    var toRender = [];
    for (let i = 0; i < sensors.length; i += 1) {
      if (sensors[i].id != undefined)
        toRender.push(<small key={i}>{sensors[i].id.name}<br /></small>);
    }
    return toRender;
  }

  changePageCount(changePage) {
    var actPage = this.state.ActualPage + changePage;
    if (actPage < 0 || actPage > this.state.AlertList.length / 10)
      return;
    this.setState({ ActualPage: actPage });
  }
}

export default translate(Manage);
