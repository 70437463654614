import React, { Component } from 'react';
import Heatmap from '../../../../components/Heatmap/Heatmap';
import { translate } from '../../../../utils/ReactMultiLang';
import Conf from '../../../../utils/Conf';
import { getRequest, promiseGetRequest, postRequest } from '../../../../utils/WebServicesManager';
import { TorusGeometry } from 'three';
import SensorDraggable from '../../Plans/TabPlan/Sensor';
import Sensor from './Sensor/Sensor';
import moment from 'moment';
import to from '../../../../utils/to';

class SvgHeatmap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      position: [],
      sensors: [],
      datas: [],
      rooms: props.rooms,
      dataName: props.dataName,
      showValues: props.showValues,
      days: props.days || [ true, true, true, true, true, true, true ],
      dateFrom: props.dateFrom || moment(),
      dateTo: props.dateTo || moment(),
      useHours: props.useHours,
      hourFrom: props.hourFrom,
      hourTo: props.hourTo,
    };
    this.t = props.t;
    this.updateDatas(props.rooms);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.rooms !== this.props.rooms) {

      const { rooms } = nextProps;
      if (rooms) {
        for (let i = 0; i < rooms.length; i += 1) {
          this.getSensorData(rooms[i]);
        }
      }
    } if (nextProps.showValues !== this.props.showValues) {
      this.setState({ showValues: nextProps.showValues }, () => this.updateDatas(nextProps.rooms));
    } if (nextProps.dataName !== this.props.dataName) {
      this.setState({ dataName: nextProps.dataName }, () => this.updateDatas(nextProps.rooms));
    } if (nextProps.dateFrom !== this.props.dateFrom || nextProps.dateTo !== this.props.dateTo) {
      this.setState({ dateFrom: nextProps.dateFrom, dateTo: nextProps.dateTo }, () => this.updateDatas(nextProps.rooms));
    } if (nextProps.days !== this.props.days || nextProps.useHours !== this.props.useHours || nextProps.hourFrom !== this.props.hourFrom || nextProps.hourTo !== this.props.hourTo) {
      this.setState({
        days: nextProps.days,
        useHours: nextProps.useHours,
        hourFrom: nextProps.hourFrom,
        hourTo: nextProps.hourTo }, () => this.updateDatas(nextProps.rooms));
    }
  }

  updateDatas(rooms) {
    if (rooms) {
      if (this.props.showValues) {
        for (let i = 0; i < rooms.length; i += 1) {
          this.getSensorData(rooms[i]);
        }
      } else {
        this.getMultipleDatas(rooms);
      }
    }
  }

  getSensorData(room) {
    if (room && room.room) {
      var url = `${Conf.BaseApi}rooms/getOne/${room.room._id}`;

      getRequest(url, (data) => {
        if (data.success) {
          const { datas, rooms } = this.state;
          const { sensors } = data.result;
          const dataToAdd = [];
          if (sensors) {
            for (let i = 0; i < sensors.length; i += 1) {
              const { planPosition, lastMessage } = sensors[i];
              dataToAdd.push([planPosition[0] / 100 * 600, planPosition[1] / 100 * 1000, lastMessage.data[this.state.dataName]]);
            }
          }
          datas[room._id] = dataToAdd;
          const idx = rooms.findIndex(r => r._id === data.result._id);
          if (idx !== -1) {
            rooms[idx] = data.result;
          } else {
            rooms.push(data.result);
          }
          this.setState({ datas, rooms }, () => this.onImgLoad())
        }
      });
    }
  }

  async getMultipleDatas(rooms) {
    if (rooms && rooms.length > 0) {
      // Step 1: Request all sensors in an array of promise
      const getSensorPromises = [];
      for (let i = 0; i < rooms.length; i += 1) {

        if (rooms[i] && rooms[i].room && rooms[i].room.sensors) {
          const { sensors } = rooms[i].room;
          for (let j = 0; j < sensors.length; j += 1) {
            const url = `${Conf.BaseApi}devices/getById/${sensors[j]}`;
            getSensorPromises.push(to(promiseGetRequest(url)));
          }
        }
      }

      // Step 2: Resolve get sensors request to create the sensors array
      const allResults = await Promise.all(getSensorPromises);
      const finalList = [];
      const sensorList = {};
      for (let i = 0; i < allResults.length; i += 1) {
        let [error, response] = allResults[i];
        if (error) {
          console.error(error);
          return 0;
        }
        if (response.success) {
          finalList.push(response.result.DevEUI);
          sensorList[response.result.DevEUI] = response.result;
        }

      }

      // Step 3: Get sensors data
      const url = Conf.BaseApi + 'messages/getBetween/devices';
      postRequest(url, {
        devices: finalList,
        from: this.state.dateFrom.unix(),
        to: this.state.dateTo.unix()
      }, (data) => {
        if (data.success) {
          const { result } = data;
          const { rooms } = this.state;
          // Step 4: Filter sensors data
          if (this.props.useHours) {
            const keys = Object.keys(result);
            for (let i = 0; i < keys.length; i += 1) {
              const messages = result[keys[i]];
              result[keys[i]] = messages.filter(m => {
                const date = new Date(m.time * 1000);
                return this.state.days[date.getDay()];
              });
            }
            const strFrom = this.props.hourFrom.split(':');
            const strTo = this.props.hourTo.split(':'); 
            const hourF = parseInt(strFrom[0]);
            const hourT = parseInt(strTo[0]);
            if (hourF != 0 || hourT != 0) {
              // filter message by hours
              for (let i = 0; i < keys.length; i += 1) {
                const messages = result[keys[i]];
                result[keys[i]] = messages.filter(m => {
                  const date = new Date(m.time * 1000);
                  const h = date.getHours();
                  return (hourF < hourT && (h > hourF && (h < hourT || h === 0))) || (hourF > hourT && (h > hourF || h < hourT));
                });
              }
            }
          }
          // Step 5: Agregate and save sensor data
          const keys = Object.keys(result);
          const dataToAdd = [];
          for (let i = 0; i < keys.length; i += 1) {
            const messages = result[keys[i]];
            const roomIndex = rooms.findIndex(r => {
              return r.room && r.room.sensors && r.room.sensors.includes(sensorList[keys[i]]._id)
            });
            if (roomIndex !== -1) {
              const roomId = rooms[roomIndex]._id;
              const { planPosition } = sensorList[keys[i]];
              const sum = messages.reduce((a, b) => b.data && b.data[this.props.dataName] ? a + (parseFloat(b.data[this.props.dataName]) || 0) : a, 0);
              if (!dataToAdd[roomId]) {
                dataToAdd[roomId] = [];
              }
              dataToAdd[roomId].push([planPosition[0] / 100 * 600, planPosition[1] / 100 * 1000, sum / messages.length]);
            }
          }
          this.setState({datas: dataToAdd}, () => this.onImgLoad());
        }
      });


    }
  }

  getGradient(palette) {
    let gradient = [];

    if (palette && palette.length > 0) {
      for (let i = 0; i < palette.length; i += 1) {
        gradient[palette[i].offset] = palette[i].color;
      }
    }
    return gradient;
  }

  onImgLoad() {
    const { rooms, palette } = this.props;

    if (rooms) {
      for (let i = 0; i < rooms.length; i += 1) {
        const heat = new Heatmap(`myHeatmap_${rooms[i]._id}`);
        heat.radius(8, 9);
        heat.min(this.props.min);
        heat.max(this.props.max);
        const gradient = this.getGradient(palette);
        heat.gradient(gradient);
        if (this.state.datas && this.state.datas[rooms[i]._id] && this.state.datas[rooms[i]._id].length > 0) {
          var data = this.getData(this.state.datas[rooms[i]._id]);
          heat.data(data);
          heat.draw(0.3);
        }
      }
    }
  }

  getData(datas) {
    const nDatas = [];
    const reducer = (previousValue, currentValue) => previousValue + currentValue;

    const pointDist = 10;
    for (let x = 20; x < 1000; x += pointDist) {
      for (let y = 20; y < 1000; y += pointDist) {
        const dist = [];
        let exactMatch = false;
        for (let i = 0; i < datas.length; i += 1) {
          dist.push(Math.sqrt(Math.pow(x - datas[i][0], 2) + Math.pow(y - datas[i][1], 2)));
          if (x === datas[i][0] && y === datas[i][1]) {
            exactMatch = true;
            nDatas.push(datas[i]);
            break;
          }
        }
        if (exactMatch) continue
        const total = dist.reduce(reducer);
        let nValue = 0;
        for (let i = 0; i < datas.length; i += 1) {
          nValue += (datas[i][2] / (total / (total - (dist[i] * (datas.length - 1)))));
        }
        nDatas.push([x, y, nValue]);
      }
    }

    return nDatas;
  }

  getPolygon(room) {

    let polygon = [];
    const { position } = room;

    if (position) {
      polygon = position.reduce(function (result, value, index, array) {
        if (index % 2 === 0) {
          let sliced = array.slice(index, index + 2);
          sliced = sliced.map(s => `${s}px`);
          result.push(sliced.join(' '));
        }
        return result;
      }, []);
    }
    return polygon;
  }

  render() {
    const { rooms, buffer } = this.props;
    const { showValues } = this.state;

    return (
      <div style={{ position: 'relative', top: '0', maxWidth: "100%", maxHeight: "100%", margin: "0 auto" }}>
        {rooms ?
          rooms.map((r) => {
            const polygon = this.getPolygon(r);
            return (<div style={{ position: 'absolute', top: '0', width: "100%", height: "100%", zIndex: "6" }}>
              <canvas id="canvas" width="1000" height="1000" id={`myHeatmap_${r._id}`} style={{ clipPath: `polygon(${polygon.join(', ')})` }}></canvas>
            </div>);
          })
          : ''}
        <img src={buffer} style={{}} onLoad={this.onImgLoad.bind(this)} />
        {showValues ? this.generateSensors(this.state.rooms) : ''}
      </div>
    )
  }


  // Function used to show sensor on plan if needed
  generateSensors(rooms) {
    const toRender = [];
    for (let i = 0; i < rooms.length; i += 1) {
      if (rooms[i] && rooms[i].sensors) {
        const { sensors } = rooms[i];
        for (let j = 0; j < sensors.length; j += 1) {
          const sensor = sensors[j];
          const style = {
            width: "55px",
            height: "35px",
            zIndex: 20,
            position: "absolute",
            left: `${parseInt(sensor.planPosition[0])}%`,
            top: `${parseInt(sensor.planPosition[1])}%`
          }
          toRender.push(<Sensor sensor={sensor} key={sensor._id} style={style} dataName={this.state.dataName} />);

        }
      }
    }
    return (toRender);
  }
}

export default translate(SvgHeatmap);