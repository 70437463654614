import React from 'react';
import {divIcon} from "leaflet";
import PropTypes from 'prop-types';
import {Marker, Popup} from "react-leaflet";
import { getDateStr } from '../../../../../utils/Utils';
import Conf from '../../../../../utils/Conf';
import {marker, marker_alert, marker_warning, generateValueMaker, generateMarker } from './Marker.js'
import { getRequest } from '../../../../../utils/WebServicesManager';

class JsxMarkerContent extends React.Component {

    onRef(ref) {

        if (ref) {
            const html = ref.innerHTML;
            if (html !== this.previousHtml) {
                this.props.onRender(html);
                this.previousHtml = html;
            }

        }
    }

    render() {
        return (
            <div className="jsx-marker" style={{ zIndex: 1 }} ref={this.onRef.bind(this)}>
                {this.props.children}
            </div>
        );
    }

}

class JsxMarker extends React.Component {

    static propTypes = {
        position: PropTypes.object,
        className: PropTypes.string
    };

    constructor(props) {
        super(props);
        this.state = {
            html: null,
            UnseenCount: 0,
            LowBatCount: 0,
            Unseen: [],
            LowBat: [],
            Key: 0
        };
        this.GetCount(props.build);
    }

    componentWillReceiveProps(nextProps) {
      if (this.props.build !== nextProps.build ) {
        this.GetCount(nextProps.build);
      }
    }
    
    GetCount (build) {
      if (build) {
        var url = Conf.BaseApi + 'buildings/sensors/getUnseenSensorsCount/' + build._id
        getRequest(url,  (body) => {
          if (body.success) {
            this.setState({ Unseen: body.result, UnseenCount: body.result.length, Key: this.state.Key + 1 });
          }
        });
        url = Conf.BaseApi + 'buildings/sensors/getLowBattery/' + build._id
        return getRequest(url,  (body) => {
          if (body.success) {
            this.setState({ LowBat: body.result, LowBatCount: body.result.length, Key: this.state.Key + 1 });
          }
        });
      }
    }

    onInsideRender(html) {
        // Set it
        this.setState({ html });
    }

    buildHtml(image, nbUnseen, nbLowBat, showMarkers) {
      return `<img src='img/pins/${image}' style='width: 100%;height:100%'/> ${showMarkers ?
        `<style>
        .container-pins {
          position: relative;
          text-align: center;
          color: white;
          width: 40%;
          height:40%;
          padding: 0px;
        }
        
        /* Centered text */
        .centered {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
        </style>
        ${nbUnseen ? `
        <div class="container-pins" style='margin-top: -115%; margin-left: 40%'>
          <img src="img/pins/unseen.png" alt="Snow" style="width:100%;">
          <div class="centered">${nbUnseen}</div>
        </div>` : '' }
        ${nbLowBat ? `
        <div class="container-pins" style='${nbUnseen ? 'margin-top: -42%; margin-left: -8%' : 'margin-top: -115%; margin-left: 40%'}'>
          <img src="img/pins/battery.png" alt="Snow" style="width:100%;">
          <div class="centered">${nbLowBat}</div>
        </div>` : '' }` : '' }`
    }

    render() {

        const { build, alert, idx, selectBuilding } = this.props;
        let marker = false;
            marker = <Marker key={`marker-${idx}-${this.state.Key}`} zIndexOffset={alert === undefined ? 300 : alert.level === 1 ? 901 : 1302}
            icon={
                alert === undefined ?
                    build.icon !== undefined ? generateMarker(this.buildHtml(build.icon, this.state.UnseenCount, this.state.LowBatCount, true)) : marker
                    : alert.level === 1 ? generateMarker(this.buildHtml('pins_warning.png', this.state.UnseenCount, this.state.LowBatCount, true))
                    : generateMarker(this.buildHtml('pins_alert.png', this.state.UnseenCount, this.state.LowBatCount, true))
            }
            position={[build.address.gps[1], build.address.gps[0]]}>
          
          <Popup onOpen={() => selectBuilding(build._id, build.sensors, true)} className='map-popup'>
              <span>
                  {build.name}
                  <br/>
                  {build.comment}
                  {
                      this.state.UnseenCount ?
                      this.state.Unseen.map((sensor, idx) => {
                        return (<ul className="icons-list small">
                        <li><br/>
                            <i className="icon-ghost bg-primary"></i>
                            <a href={`#/sensors/detail?deveui=${sensor.DevEUI}`}>{sensor.name}</a>
                            <br/>
                            <small>{sensor.lastSeen ? getDateStr(sensor.lastSeen) : '2019-08-23 9h04'}</small>
                            </li>
                            { idx === this.state.Unseen.length - 1 ? <br/> : ''}
                            </ul>)

                  }) : '' }
                  {
                      this.state.LowBatCount ?
                      this.state.LowBat.map((sensor, idx) => {
                        return (<ul className="icons-list small">
                        <li><br/>
                            <i className="icon-energy bg-warning"></i>
                            <a href={`#/sensors/detail?deveui=${sensor.DevEUI}`}>{sensor.name}</a>
                            <br/>
                            <small>{sensor.lastSeen ? getDateStr(sensor.lastSeen) : '2019-08-23 9h04'}</small>
                            </li>
                            { idx === this.state.LowBat.length - 1 ? <br/> : ''}
                            </ul>)

                  }) : '' }
                  {
                      alert !== undefined ?
                      <ul className="icons-list">
                      <li><br/>
                          <small>{getDateStr(alert.time)}</small>
                          <br/>
                          <i className="icon-bell bg-danger"></i>
                          {alert.name}
                          <br/>
                          {alert.desc}
                          </li>
                          <br/>
                          <br/>
                          <br/>
                          </ul>
                      :
                          ''

                  }
              </span>
          </Popup>
        </Marker>
        

//  {marker}
        return (<React.Fragment>


            {marker}
        </React.Fragment>);

    }

}

export default JsxMarker;