import React, { Component } from 'react';
import $ from 'jquery';
import Conf from '../../../utils/Conf';
import { getRequest, postRequest } from '../../../utils/WebServicesManager';
import { Modal, ModalHeader, ModalBody, Col, Row } from 'reactstrap';
import { translate } from '../../../utils/ReactMultiLang';
import Select from 'react-select';
import { ThemeProvider } from 'styled-components';


class Providers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      groupId: props.groupId,
      Providers: [],
      SelectedProvider: undefined
    }
    this.callback = props.callback;
    this.t = props.t;
    this.getProviders();
  }
  
  componentWillReceiveProps(nextProps) {
    if (nextProps.groupId !== this.props.groupId) {
      this.setState({groupId: nextProps.groupId});
    }
  }

  getProviders() {
    var url = Conf.BaseApi + "providers/get";
    
    return getRequest(url, (data) => {
      if (data.success || data.success == true) {
        this.setState({Providers: data.result, SelectedProvider: data.result.length > 0 ? data.result[0]._id : undefined});
      }
    });
  }

  assignProvider() {
    var url = Conf.BaseApi + "groups/assignProvider";
    
    const provider = {
      providerId: this.state.SelectedProvider,
      groupId: this.state.groupId
    };

    return postRequest(url, provider, (data) => {
      if (data.success || data.success == true) {
        this.callback(null, this.state.groupId);
      } else {
        $("#error-window-provider").text(data.message);
      }
    });
  }

  changeProvider(evt) {
    this.setState({ SelectedProvider: evt.target.value });
  }

  render() {
    return (
      <ModalBody>
      <div className="flex-row align-items-center">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12">
              <div className="card mx-12">
                <div className="card-block p-12">
                  <div className="input-group mb-3" style={{ color: "red" }} id="error-window-provider">

                  </div>
                  <div className="input-group mb-3">
                    <span className="input-group-addon"><i className="icon-usergroup"></i></span>
                    <select className="form-control" id="provider" onChange={this.changeProvider.bind(this)} >
                      {this.state.Providers.map(p => <option value={p._id}>{p.name}({p.provider})</option>)}
                    </select>
                  </div>
                  <button type="button" className="btn btn-block btn-success" onClick={this.assignProvider.bind(this)}>{this.t('Assign')}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ModalBody>
    )
  }

}

export default translate(Providers);