import React, { Component } from 'react';
import $ from 'jquery';
import { postRequestJSON } from '../../../utils/WebServicesManager';
import Conf from '../../../utils/Conf';
import { translate } from '../../../utils/ReactMultiLang';
import { Row, Col } from 'reactstrap';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import Select from 'react-select';

class SendDelivryForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      UserGroups: [],
      SelectedSensor: props.delivry.sensor,
      groupKey: 0,
      Modal: false,
      date: moment()
    };
    this.t = props.t;
  }

  updateUserToSend(users) {
    this.setState({ usersToSend: users });
  }

  toggle() {
    this.setState({ Modal: !this.state.Modal });
  }

  sendDelivery() {
    var url = Conf.BackenBase + 'delivery/test';

    let usersToSend = [];
    let inputsUsers = $("input[name=usersToSend]");
    for (let i = 0; i < inputsUsers.length; i += 1) {
      usersToSend.push(inputsUsers[i].value);
    }
    if (usersToSend && usersToSend.length === 1) {
      const sendData = {
        sensorId: this.state.SelectedSensor._id,
        email: usersToSend[0],
        date: this.state.date.unix() * 1000,
      };
      return postRequestJSON(url, sendData, (data) => {
        if (data.success || data.success == true) {
          this.props.callback();
        } else {
          $("#error-window").text(data.message);
        }
      });
    } else {
      $("#error-window").text('One user to send should be selected');
    }
  }
  handleChangeDate(date) {
    this.setState({ date });
  }

  render() {
    const { delivry, callback } = this.props;

    return (
      <div className="flex-row align-items-center">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12">
              <div className="card mx-12">
                <div className="card-block p-12">
                  <div className="input-group mb-3" style={{ color: "red" }} id="error-window">

                  </div>
                  <Row>
                    <Col md='12'>
                      {this.t('Delivry.toSend')} :
                          <Select.Creatable
                        multi={false}
                        value={this.state.usersToSend}
                        onChange={this.updateUserToSend.bind(this)}
                        backspaceRemoves={this.state.backspaceRemoves}
                        id="usersToSend"
                        name="usersToSend"
                      />
                    </Col>
                  </Row>
                  <br />

                  <Row>
                    <Col md='12'>
                      {this.t('Date')}: <small>(La date doit être entre le début et la fin de la semaine désirée)</small><DatePicker
                        selected={this.state.date}
                        onChange={this.handleChangeDate.bind(this)}
                        dateFormat="DD MMMM YYYY"
                      />
                    </Col>
                  </Row>
                  <br />
                  <button type="button" className="btn btn-block btn-success"
                    onClick={this.sendDelivery.bind(this, callback)}>
                    {this.t('Send')}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>)
  }
}

export default translate(SendDelivryForm);