import React, { Component } from 'react';
import moment from 'moment';
import $ from 'jquery';
import { TimePicker } from 'react-input-moment';
import Conf from '../../utils/Conf';
import { getRequest, postRequest } from '../../utils/WebServicesManager';
import SelectUser from '../Selects/SelectUser';
import SelectSensorsType from '../Selects/SelectSensorsType';
import ReactDaytime from '../react-daytime';
import { ButtonToolbar, ToggleButtonGroup, ToggleButton } from 'react-bootstrap';
import { Col, Row } from 'reactstrap';
import { translate } from '../../utils/ReactMultiLang';
import { getUserId } from '../../utils/AuthServices';

class CreateModel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      start: moment(),
      UserGroups: [],
      FieldList: [],
      useDuration: props.alarm && props.alarm.useDuration ? true : false,
      isRange: props.alarm && props.alarm.comparator > 2 ? true : false,
      SensorModels: [],
      end: moment(),
      doNotCreateAlert: false,
      isDownlink: props.alarm && props.alarm.isDownlink ? true : false,
      isUnder: props.alarm ? props.alarm.comparator : -1,
    };

    this.callback = props.callback;
    if (props.alarm && props.alarm.sensorModels) {
      this.getFieldList(props.alarm.sensorModels.map(k => {return ({value: k}); }));
    } else {
      this.getFieldList();
    }
    this.getUserGroups();
    this.t = props.t;
  }

  getFieldList(values) {
    let models = [];
    if (values !== undefined) {
      for (let i = 0; i < values.length; i += 1) {
        models.push(values[i].value);
      }
    }
      var url = Conf.BaseApi + `helpers/getModelsData`;
      return postRequest(url, {models}, (result) => {
        if (result && result.success) {
          this.setState({ FieldList: result.result });
        }
      });
  }

  createAlerteChange() {
    this.setState({doNotCreateAlert: $("#doNotCreateAlert").is(':checked')});
  }
  isDownlinkChange() {
    this.setState({isDownlink: $("#isDownlink").is(':checked')});
  }

  renderUserGroup() {
    var toRender = [];
    for (var i = 0; i < this.state.UserGroups.length; i++) {
        toRender.push(
          <option value={this.state.UserGroups[i]._id} key={i}>{this.state.UserGroups[i].name}</option>
        );
    }
    return toRender;
  }
  getUserGroups() {
    var url = Conf.BaseApi + 'groups/getAll';
    return getRequest(url, (data) => {
      if (data.success) {
        this.setState({ UserGroups: data.result });
      }
    });
  }

  sendModel(callback) {
    let model = {};
    if (this.props.alarm) {
      model = Object.create(this.props.alarm);
    }
    let ids = [];
    const inputs = $("input[name=userId]");
    for (let i = 0; i < inputs.length; i += 1) {
      ids.push(inputs[i].value);
    }
    let models = [];
    const inputsModels = $("input[name=sensorModels]");
    for (let i = 0; i < inputsModels.length; i += 1) {
      models.push(inputsModels[i].value);
    }
    model.name = $("#alarmName").val();
    model.desc = $("#alarmMessage").val();
    model.group = $("#userGroup").val();
    model.dataName = $("#dataName").val();
    model.value = $("#alarmValue").val();

    if (this.state.isRange) {
      model.maxValue = $("#alarmMaxValue").val();
    }

    model.level = $('#levelSelector input:radio:checked').val();
    model.doNotCreateAlert = $("#doNotCreateAlert").is(':checked');
    model.alertNotif = $("#alertNotif").is(':checked');
    model.alertSms = $("#alertSms").is(':checked');
    model.alertMail = $("#alertMail").is(':checked');
    model.comparator = this.state.isUnder;
    model.isDownlink = $("#isDownlink").is(':checked');
    model.port = $("#alarmPort").val();

    model.useDuration = this.state.useDuration;
    if (this.state.useDuration) {
      model.durationType = $('#durationType input:radio:checked').val();
      model.duration = $("#duration").val();
    }
    model.from = this.state.start.hour() + ":" + this.state.start.minute();
    model.to = this.state.end.hour() + ":" + this.state.end.minute();
    model.users = ids;
    model.sensorModels = models;
    var url = Conf.BaseApi + 'alarmModels/add';
    if (this.props.alarm) {
      var url = Conf.BaseApi + Conf.EditModel;
    }
    return postRequest(url, {
      model
    }, (data) => {
      if (data.success || data.success == true) {
        if (this.callback != undefined) {
          this.callback();
        }
      } else {
        $("#error-window").text(data.message);
      }
    });
  }

  handleChangeStart = start => {
    this.setState({ start: start });
  };

  handleChangeEnd = end => {
    this.setState({ end: end });
  };
  
  renderFields(fields) {
    var toRender = [];
    for (var i = 0; i < fields.length; i++) {
      toRender.push(
        <option value={fields[i]} key={i}>{this.t(`SensorData.${fields[i]}`)}</option>
      );
    }
    return toRender;
  }
  
  renderModels(models) {
    var toRender = [];
    toRender.push(
      <option value=''>Tout les models</option>
    );
    for (var i = 0; i < models.length; i++) {
      toRender.push(
        <option value={models[i]} key={i}>{models[i]}</option>
      );
    }
    return toRender;
  }
  
  handleChange(selectedRange) {
    console.log('Selected time frames: ', selectedRange);
  }
  useDurationChange() {
    this.setState({ useDuration: $("#useDuration").is(':checked') });
  }

  onAlarmTypeChange() {
    const type = $('#isUnder input:radio:checked').val();
    if (type == 3 || type == 4) {
      this.setState({isRange: true, isUnder: type});
    } else {
      this.setState({isRange: false, isUnder: type});
    }
  }
  render() {
    const {alarm, callback} = this.props;
    
    return (
      <div className="flex-row align-items-center">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12">
              <div className="card mx-12">
                <div className="card-block p-12">
                  <h2>{this.t('Model.Model')}
                            <i className="icon-close color-danger" onClick={callback}
                                style={{position:'absolute', textAlign: 'right', right:'15px', cursor:'pointer'}}></i></h2>
                  <p className="text-muted">{this.t('Model.Add')}</p>
                  <div className="input-group mb-3" style={{ color: "red" }} id="error-window">

                  </div>
                  <Row>
                    <Col md='6'>
                  <div className="input-group mb-3">
                    <span className="input-group-addon"><i className="icon-tag"></i></span>
                    <input type="text" className="form-control" placeholder={this.t('Name')} id="alarmName"
                    defaultValue={alarm !== undefined ? alarm.name : ""}/>
                  </div>
                    </Col>
                    <Col md='6'>
                                    <label htmlFor="sensorType">{this.t('UserGroup')}</label>
                                    {this.state.UserGroups.length > 0 ?
                                    <select className="form-control" id="userGroup" defaultValue={alarm !== undefined ? alarm.group : ""}>
                                      {this.renderUserGroup()}
                                    </select> : ''}
                                    <br/>
                    </Col>
                  </Row>
                  <div className="input-group mb-3">
                    <span className="input-group-addon"><i className="icon-note"></i></span>
                    <input type="text" className="form-control" placeholder="Message" id="alarmMessage"
                    defaultValue={alarm !== undefined ? alarm.desc : ""}/>
                  </div>

                  <div className="form-group mb-3">
                                </div>
                    <Row>
                      <Col sm='6'>
                      {this.t('Model.SensorType')}
                  <br/>
                  <SelectSensorsType scopeParam="linked" onChangeCallback={this.getFieldList.bind(this)}
                    values={alarm !== undefined ? alarm.sensorModels : undefined}/>
                    </Col>
                      <Col sm='6'>
                      {this.t('Model.DataToTest')}
                  <br/>
                                    {this.state.FieldList.length > 0 ?
                    <select className="form-control" id="dataName" defaultValue={alarm !== undefined ? alarm.dataName : ''}>
                      {this.renderFields(this.state.FieldList)}
                    </select> : '' }
                    </Col>
                    </Row>
                  <br />
                  <Row>
                    <Col md='12'>
                      <ButtonToolbar>
                        <ToggleButtonGroup type="radio" name="options" id="isUnder" defaultValue={alarm !== undefined ? alarm.comparator : -1}>
                          <ToggleButton value={0} bsStyle="success" onChange={this.onAlarmTypeChange.bind(this)}>{this.t('Alarm.Upper')}</ToggleButton>
                          <ToggleButton value={1} bsStyle="success" onChange={this.onAlarmTypeChange.bind(this)}>{this.t('Alarm.Under')}</ToggleButton>
                          <ToggleButton value={2} bsStyle="success" onChange={this.onAlarmTypeChange.bind(this)}>{this.t('Alarm.Equal')}</ToggleButton>
                          <ToggleButton value={3} bsStyle="success" onChange={this.onAlarmTypeChange.bind(this)}>{this.t('Alarm.Range')}</ToggleButton>
                          <ToggleButton value={4} bsStyle="success" onChange={this.onAlarmTypeChange.bind(this)}>{this.t('Alarm.InRange')}</ToggleButton>
                        </ToggleButtonGroup>
                      </ButtonToolbar>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col md='6'>
                      <div className="input-group mb-6">
                        <span className="input-group-addon"><i className="icon-speedometer"></i></span>
                        <input type="number" step="0.5" className="form-control" placeholder={this.state.isRange ? `Min ${this.t('Value')}` : this.t('Value') } id="alarmValue"
                          defaultValue={alarm !== undefined ? alarm.value : ''} />
                      </div>
                    </Col>
                    { this.state.isRange ? 
                    <Col md='6'>
                      <div className="input-group mb-6">
                        <span className="input-group-addon"><i className="icon-speedometer"></i></span>
                        <input type="number" step="0.5" className="form-control" placeholder={`Max ${this.t('Value')}`} id="alarmMaxValue"
                          defaultValue={alarm !== undefined && alarm.maxValue ? alarm.maxValue : ''} />
                      </div>
                    </Col> : ''}
                  </Row>
                  <br />
                  <Col md='6'>
                  <div className="input-group mb-3">
                        <div className="form-check form-check-inline">
                          <label className="form-check-label">
                            <input className="form-check-input" type="checkbox" id="useDuration" value="true" onChange={() => this.useDurationChange()}
                              defaultChecked={alarm !== undefined ? alarm.useDuration : false} />
                            {" "}{this.t('Alarm.UseDuration')}
                          </label>
                        </div>
                      </div>
                    </Col>
                  {this.state.useDuration ? 
                    <Row>
                      <Col md='6'>
                        <div className="input-group mb-6">
                          <span className="input-group-addon"><i className="icon-calendar"></i></span>
                          <input type="number" step="0.5" className="form-control" placeholder={this.t('Duration (hour)')} id="duration"
                            defaultValue={alarm !== undefined && alarm.duration ? alarm.duration : ''} />
                        </div>
                      </Col>
                      <Col md='6'>
                        <ButtonToolbar>
                          <ToggleButtonGroup type="radio" name="options" id="durationType" defaultValue={alarm !== undefined ? alarm.durationType : 0}>
                            <ToggleButton value={0} bsStyle="success" onChange={this.onAlarmTypeChange.bind(this)}>{this.t('Average')}</ToggleButton>
                            <ToggleButton value={1} bsStyle="success" onChange={this.onAlarmTypeChange.bind(this)}>{this.t('Sum')}</ToggleButton>
                          </ToggleButtonGroup>
                        </ButtonToolbar>
                      </Col>
                    </Row>
                  : ''}

                  <br />
                  <Row>
                    <Col md='6'>
                  <div className="input-group mb-3">
                        <div className="form-check form-check-inline">
                          <label className="form-check-label">
                            <input className="form-check-input" type="checkbox" id="isDownlink" value="true" onChange={() => this.isDownlinkChange()}
                              defaultChecked={alarm !== undefined ? alarm.isDownlink : false} />
                            {" "}{this.t('Alarm.isDownlink')}
                          </label>
                        </div>
                      </div>
                    </Col>
                    { this.state.isDownlink ? 
                    <Col md='6'>
                      <div className="input-group mb-6">
                        <span className="input-group-addon">Port </span>
                        <input type="number" step="0.5" className="form-control" id="alarmPort"
                          defaultValue={alarm !== undefined && alarm.port ? alarm.port : 0} />
                      </div>
                    </Col> : ''}
                  </Row>

                    { !this.state.isDownlink ? 
                    <div>
                  {this.t('Alarm.UserToPrevent')}
                  <SelectUser scopeParam="linked" values={alarm !== undefined ? alarm.users : undefined}/>
                  
                  <Row>
                    <Col md='6'>
                    {this.t('Alarm.Level')}
                  <ButtonToolbar>
                      <ToggleButtonGroup type="radio" name="levelSelector" id="levelSelector" defaultValue={alarm !== undefined ? `${alarm.level}` : '2'}>
                        <ToggleButton value="1" bsStyle="warning">{this.t('Warning')}</ToggleButton>
                        <ToggleButton value="2" bsStyle="danger">{this.t('Danger')}</ToggleButton>
                      </ToggleButtonGroup>
                    </ButtonToolbar>
                    </Col>
                     
                    <Col md='6'>
                  <div className="input-group mb-3">
                    <div className="form-check form-check-inline">
                      <label className="form-check-label">
                        <input className="form-check-input" type="checkbox" id="doNotCreateAlert" value="true" onChange={() => this.createAlerteChange()}
                          defaultChecked={alarm !== undefined ? alarm.doNotCreateAlert : false}/> 
                        {this.t('Alarm.DoNotDisable')}
                      </label>
                    </div>
                  </div>
                  {this.t('Alert.Type')} :
                  { !this.state.doNotCreateAlert ?    
                  <div className="input-group mb-3">
                    <div className="form-check form-check-inline">
                      <label className="form-check-label">
                        <input className="form-check-input" type="checkbox" id="alertNotif" value="true"
                          defaultChecked={alarm !== undefined ? alarm.alertNotif : false}/> {this.t('Notification')}
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <label className="form-check-label">
                        <input className="form-check-input" type="checkbox" id="alertSms" value="true"
                          defaultChecked={alarm !== undefined ? alarm.alertSms : false}/> {this.t('Sms')}
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <label className="form-check-label">
                        <input className="form-check-input" type="checkbox" id="alertMail" value="true"
                          defaultChecked={alarm !== undefined ? alarm.alertMail : false}/> {this.t('Mail')}
                      </label>
                    </div>
                  </div> :
                  <div className="input-group mb-3">
                  <div className="form-check form-check-inline">
                    <label className="form-check-label">
                      <input className="form-check-input" type="checkbox" id="alertNotif" checked="checked" disabled/> {this.t('Notification')}
                    </label>
                  </div>
                  </div>
                  }
                  </Col>
                  </Row>
                  </div> : '' }
                  <button type="button" className="btn btn-block btn-success" onClick={this.sendModel.bind(this)}>{alarm !== undefined ? this.t('Edit') : this.t('Create')}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}


export default translate(CreateModel);