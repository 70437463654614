import React, { Component } from 'react';
import $ from 'jquery';
import Conf from '../../../utils/Conf';
import { getRequest, postRequest } from '../../../utils/WebServicesManager';
import { Modal, ModalHeader, ModalBody, Col, Row } from 'reactstrap';
import { translate } from '../../../utils/ReactMultiLang';
import DownlinkBuilder from './DownlinkBuilder/DownlinkBuilder';


class SendDownlink extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Sensors: props.sensors,
      downlinksFormat: null
    }
    this.getHelper(props.sensors[0]);
    this.t = props.t;
  }

  
  componentWillReceiveProps(nextProps) {
    if (nextProps.sensors !== this.props.sensors) {
      this.setState({Sensors: nextProps.sensors});
      this.getHelper(nextProps.sensors[0]);
    }
  }
  getHelper(sensorEUI) {
    var url = Conf.BaseApi + `helpers/downlinks/${sensorEUI}`;
    return getRequest(url, (body) => {
        if (body.success && body.result) {
          this.setState({downlinksFormat: body.result});
        }
    });
  }


  postDownlink(payload, deviceIds, port, index, callback) {
    if (index >= deviceIds.length) { 
      return callback(null);
    }
    var url = Conf.BaseApi + 'downlinks/send';
    return postRequest(url, {
      payload,
      deviceId: deviceIds[index],
      port
    }, (data) => {
      if (data.success) {
        callback({deviceId: deviceIds[index], result: 'Success'});
      } else {
        callback({deviceId: deviceIds[index], result: 'Fail'});
      }
      if (index < deviceIds.length) {
        this.postDownlink(payload, deviceIds, port, index + 1, callback);
      }
    });
  }

  sendDownlink(sensors, callback) {
    const payload = $("#payloadField").val();
    const deviceIds = sensors;
    const port = $("#portField").val();

    const result = [];

    this.postDownlink(payload, deviceIds, port, 0, (oneResult) => {
      if (oneResult) {
        result.push(oneResult)
      } else {
        let strResult = '';
        result.forEach(r => strResult += `${r.deviceId}: ${r.result}\n`);
        alert(strResult);
        callback();
      }
    });
  }

  render() {
    const { callback } = this.props;


    return (
        <ModalBody>
        <div className="flex-row align-items-center">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-12">
                <div className="card mx-12">
                  { this.state.downlinksFormat ? 
                    <DownlinkBuilder downlinks={this.state.downlinksFormat} callback={callback} sensors={this.state.Sensors}/>
                  : 
                  <div className="card-block p-12">
                    <div className="input-group mb-3" style={{ color: "red" }} id="error-window">

                    </div>
                    <div className="input-group mb-3">
                      <span className="input-group-addon"><i className="icon-lock"></i></span>
                      <input type="text" className="form-control" placeholder={this.t('Payload')} id="payloadField"/>
                    </div>
                    <div className="input-group mb-3">
                      <span className="input-group-addon"><i className="icon-lock"></i></span>
                      <input type="number" className="form-control" placeholder={this.t('Port')} id="portField"/>
                    </div>
                    
                    <button type="button" className="btn btn-block btn-success" onClick={this.sendDownlink.bind(this, this.state.Sensors, callback)}>{this.t('Send')}</button>
                  </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </ModalBody>
    )
  }

}

export default translate(SendDownlink);