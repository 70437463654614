import React, { Component } from 'react';
import { NavLink } from 'react-router-dom'
import Dashboard from '../../views/Dashboard/';
import $ from 'jquery';
import Conf from '../../utils/Conf';
import { getRequest, postRequest, notLoggedPostRequest } from '../../utils/WebServicesManager';
import { setIdToken, setAccessToken, isLoggedIn } from '../../utils/AuthServices';
import { Button, Modal } from 'reactstrap';

class RetreivePassword extends Component {

  constructor(props) {
   super(props);
   this.state = { 
    user: {}
  };
   const token = this.props.match.params.token;
   setAccessToken(token);
   setIdToken(token);
   this.getUser();
  }

  getUser() {
    var url = Conf.BaseApi + 'user';
      return getRequest(url, (result) => {
        if (result.success === true) {
          this.setState({ user: result.result });
        }
      });
  }

    updatePassword() {
        var url = Conf.BaseApi + Conf.UpdatePassword;
        return postRequest(url, {
          password: $("#password").val(),
          confPassword:$("#confPassword").val()
        }, (data) => {
            if (data.success || data.success == true) {
                setAccessToken(data.token);
                setIdToken(data.token);
                window.location.href = "/";
            } else {
                $("#error-login").text(data.message);
            }
        });
    }

  handleKeyPress(event) {
    if (event.key === 'Enter') {
      this.updatePassword();
    }
  }

  render() {

    return (
    <div className="neal-page">

<div className="app flex-row align-items-center">
<div className="container">
  <div className="row justify-content-center">
    <div className="col-md-8">
      <div className="card-group mb-0">
        <div className="card p-4">
          <div className="card-block">
            <h3>{this.state.user.firstName} {this.state.user.name}</h3>
            <p className="text-muted">Create a new password</p>
            <div className="input-group mb-3" style={{color: "red"}} id="error-login">
              
            </div>
            <div className="input-group mb-3">
              <span className="input-group-addon"><i className="icon-lock"></i></span>
              <input type="password" className="form-control" placeholder="Password" id="password"/>
            </div>
            <div className="input-group mb-4">
              <span className="input-group-addon"><i className="icon-lock"></i></span>
              <input type="password" className="form-control" placeholder="Password Confirmation" id="confPassword"  onKeyPress={this.handleKeyPress.bind(this)}/>
            </div>
            <div className="row">
              <div className="col-6">
                <button type="button" className="btn btn-primary px-4" onClick={this.updatePassword}>Mettre à jour</button>
              </div>
            </div>
          </div>
        </div>
        <div className="card card-inverse py-5 d-md-down-none" style={{ width: 44 + '%' }}>
          <div className="card-block text-center">
            <div>
              <img src="img/ebeesafe.png" width="200px" style={{marginTop:'-20px'}} />
            </div>
            <div>
              <img src="img/ebeewan.png" width="350px" style={{marginTop:'-25px'}}/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</div>
    </div>
    )
  }
}
export default RetreivePassword;
