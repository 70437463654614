import React, { Component } from 'react';
import { translate } from '../../../utils/ReactMultiLang';
import { Modal, ModalBody, FormGroup, Input, Label, Row, Col, ModalHeader } from 'reactstrap';
import $ from 'jquery';
import Conf from '../../../utils/Conf';
import { getAccessRight } from '../../../utils/AuthServices';
import { postRequest } from '../../../utils/WebServicesManager';


class AddUserModal extends Component {
    constructor(props) {
        super(props);
        this.t = props.t;
        this.toggle = props.toggle;
    }

    signin() {
        if (this.props.userToEdit === undefined) { //create
            var url = Conf.SigninURL; 
            return postRequest(url, {
                login: $("#loginForm").val(),
                email: $("#mailForm").val(),
                password: $("#passForm").val(),
                firstName: $("#firstName").val(),
                name: $("#name").val(),
                phone: $("#phoneForm").val(),
                verifPwd: $("#passConfForm").val(),
                lang: $("#lang").val(),
                role: $("#userRole").val(),
                group: $("#userGroup").val()
            }, (data) => {
                if (data.success || data.success == true) {
                    this.toggle();
                    window.location.reload();
                } else {
                    $("#error-window").text(data.message);
                }
            });
        } else { //edit
            var url = Conf.BaseApi + "users/subuser/edit";
            return postRequest(url, { user: {
                _id: this.props.userToEdit._id,
                email: $("#mailForm").val(),
                firstName: $("#firstName").val(),
                name: $("#name").val(),
                role: $("#userRole").val(),
                phone: $("#phoneForm").val()
            }}, (data) => {
                if (data.success || data.success == true) {
                    this.toggle();
                } else {
                    $("#error-window").text(data.message);
                }
            });
        }
    }


    renderUserGroup(userGroups) {
        var toRender = [];
        for (var i = 0; i < userGroups.length; i++) {
            toRender.push(
              <option key={i} value={userGroups[i]._id}>{userGroups[i].name}</option>
            );
        }
        return toRender;
      }
      renderUserRole() {
          var toRender = [];
          const accessRight = getAccessRight();
          const keys = Object.keys(accessRight);
          for (var i = 0; i < keys.length; i++) {
              toRender.push(
                <option key={i} value={keys[i]}>{keys[i]}</option>
              );
          }
          return toRender;
        }

    render() {
        const { userGroups, userToEdit } = this.props;
        
        return (
            <Modal isOpen={this.props.isOpen} className={'modal-md ' + this.props.className}>
            <ModalHeader>
                <Row style={{paddingLeft:'15px', paddingRight:'15px'}}>
                    <h1>{this.t('Popup.Add.User')}</h1>
                    <i className="icon-close color-danger" onClick={this.props.toggle}
                        style={{position:'absolute', textAlign: 'right', right:'15px', cursor:'pointer'}}></i>
                </Row>
            </ModalHeader>
                <ModalBody>
                    <div className="flex-row align-items-center">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-md-12">
                                    <div className="card col-md-12">
                                        <div className="card-block p-12">
                                            <div className="input-group mb-3" style={{ color: "red" }} id="error-window">

                                            </div>
                                            <div className="input-group mb-3">
                                                <span className="input-group-addon"><i className="icon-user"></i></span>
                                                <input type="text" className="form-control" placeholder={this.t('Firstname')} id="firstName" 
                                                    defaultValue={userToEdit !== undefined ? userToEdit.firstName : ""}/>
                                            </div>
                                            <div className="input-group mb-3">
                                                <span className="input-group-addon"><i className="icon-pin"></i></span>
                                                <input type="text" className="form-control" placeholder={this.t('Surname')} id="name" 
                                                    defaultValue={userToEdit !== undefined ? userToEdit.name : ""}/>
                                            </div>
                                            {userToEdit !== undefined ? '' : 
                                            <div className="input-group mb-3">
                                                <span className="input-group-addon"><i className="icon-user-follow"></i></span>
                                                <input type="text" className="form-control" placeholder="Login" id="loginForm" 
                                                    defaultValue={userToEdit !== undefined ? userToEdit.login : ""}/>
                                            </div>
                                            }
                                            <div className="input-group mb-3">
                                                <span className="input-group-addon"><i className="icon-phone"></i></span>
                                                <input type="text" className="form-control" placeholder={this.t('Phone')} id="phoneForm" 
                                                    defaultValue={userToEdit !== undefined ? userToEdit.phone : ""}/>
                                            </div>
                                            <div className="input-group mb-3">
                                                <span className="input-group-addon">@</span>
                                                <input type="text" className="form-control" placeholder={this.t('Email')} id="mailForm" 
                                                    defaultValue={userToEdit !== undefined ? userToEdit.email : ""}/>
                                            </div>
                                            {userToEdit !== undefined ? '' : 
                                            <div>
                                                <div className="input-group mb-3">
                                                    <span className="input-group-addon"><i className="icon-lock"></i></span>
                                                    <input type="password" className="form-control" placeholder={this.t('Password')} id="passForm" />
                                                </div>
                                                <div className="input-group mb-4">
                                                    <span className="input-group-addon"><i className="icon-lock"></i></span>
                                                    <input type="password" className="form-control" placeholder={this.t('RPassword')} id="passConfForm" />
                                                </div>
                                                <div className="form-group mb-3">
                                                    <label htmlFor="sensorType">{this.t('UserGroup')}</label>
                                                    <select className="form-control" id='userGroup'>
                                                    {this.renderUserGroup(userGroups)}
                                                    </select>
                                                </div>
                                            <div className="form-group mb-3">
                                                <select className="form-control" id="lang">
                                                    <option value='en'>{this.t('Languages.English')}</option>
                                                    <option value='fr'>{this.t('Languages.French')}</option>
                                                </select>
                                            </div>
                                                </div>
                                            }
                                            <div className="form-group mb-3">
                                                <label htmlFor="userRole">{this.t('Role')}</label>
                                                <select className="form-control" id='userRole' defaultValue={userToEdit !== undefined ? userToEdit.role : ""}>
                                                {this.renderUserRole()}
                                                </select>
                                            </div>
                                            <button type="button" className="btn btn-block btn-success" onClick={this.signin.bind(this)}>{userToEdit !== undefined ? this.t('Update') : this.t('Create')}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        )
    }

}

export default translate(AddUserModal);