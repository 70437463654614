import React, { Component } from 'react';
import $ from 'jquery';
import Conf from '../../../utils/Conf';
import { getRequest, postRequest } from '../../../utils/WebServicesManager';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import CreateModel from '../../../components/CreateModel';
import { translate } from '../../../utils/ReactMultiLang';
import { getUserId } from '../../../utils/AuthServices';
import { check } from '../../../utils/Can';

class Models extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Models: [],
      Modal: false,
      ModalEdit: false,
      ActualPage: 0,
      ModelDetails: {},
      Buildings: [],
      Sensors: [],
      CanCreate: check('sensor:create'),
      CanEdit: check('sensor:edit'),
      CanRemove: check('sensor:remove')
    };
    this.getModelsList();
    this.toggle = this.toggle.bind(this);
    this.t = props.t;
  }

  getModelDetails(model) {
    this.setState({ ModelDetails: model });
    this.getLinkedEntity(model._id);
  }
  getModelsList() {
    var url = Conf.BaseApi + 'alarmModels/get';
    return getRequest(url, (data) => {
      if (data.success)
        this.setState({ Models: data.result });
    });
  }
  getLinkedEntity(modelId) {
    var url = Conf.BaseApi + 'alarmModels/getLinkedEntity/' + modelId;
    return getRequest(url, (data) => {
      if (data.success)
        this.setState({
          Buildings: data.result.buildings,
          Sensors: data.result.sensors
        });
    });
  }

  renderModelsList(models) { // TODO
    var toRender = [];
    for (var i = 0; i < models.length; i++) {
      toRender.push(
        <li key={i}>
          <i className={models[i].level === 2 ? 'icon-puzzle bg-danger' : 'icon-puzzle bg-warning'} onClick={this.getModelDetails.bind(this, models[i])}></i>
          <div className="desc sensorListItem" onClick={this.getModelDetails.bind(this, models[i])}>
            <div className="title">{models[i].name}</div>
            <small>{models[i].dataName}
            {models[i].comparator === 0 ? '>' : models[i].comparator === 1 ? '<' : '='}
            {models[i].value}</small>
          </div>
          <div className="actions">
          {this.state.CanRemove ? <button value={models[i]._id} onClick={this.removeModel.bind(this,models[i]._id)}  type="button" className="btn btn-link text-danger"><i className="icon-close"></i></button> : ""}
          </div>
        </li>
      );
    }
    return toRender;
  }

  toggle() {
    this.setState({
      Modal: !this.state.Modal
    });
    this.getModelsList();
  }
  toggleEdit() {
    if (this.state.ModalEdit) {
      this.getModelsList();
      this.setState({
        Buildings: [],
        Sensors: [],
        ModelDetails: undefined,
        ModalEdit: !this.state.ModalEdit
      });
    } else {
      this.setState({
        ModalEdit: !this.state.ModalEdit
      });
    }
  }

  removeModel(alarmId) { // TODO
    var r = window.confirm(this.t('Popup.Delete.Model'));
    if (r == true) {
      var url = Conf.BaseApi + Conf.RemoveModel + alarmId;
      getRequest(url, (data) => {
        if (data.success && data.success == true) {
          this.getModelsList();
        }
      });
    }
  }

  render() {
    const divStyle = {
      width: '60%'
    };
    
    return (
      <div className="row">
        <div className="col-lg-6 col-md-12">
          <div className="card">
            <div className="card-header">
              <i className="fa fa-align-justify"></i> {this.t('Model.List')}
              </div>
            <div className="card-block">
              <ul className="icons-list">
                {this.renderModelsList(this.state.Models)}
              </ul>
              <br />
              {this.state.CanCreate ? <button type="button" onClick={this.toggle} className="btn btn-primary btn-lg" >{this.t('Model.Add')}</button> : "" }
            </div>
          </div>
              </div>
          <div className="col-lg-6 col-md-12">
            <div className="row ">
          <div className="col-lg-12">
              <div className="card">
                <div className="card-header">
                  <i className="fa fa-align-justify"></i> {this.t('Model.Details')}
                </div>
                <div className="card-block">
                    {this.renderModelDatails()}
                </div>
              </div>
              </div>
          <div className="col-lg-12">
              <div className="card">
                <div className="card-header">
                  <i className="fa fa-align-justify"></i> {this.t('Model.LinkedEntity')}
                </div>
                <div className="card-block">
                    {this.renderLinkedEntity()}
                </div>
              </div>
              </div>
            </div>
        </div>
        <Modal isOpen={this.state.Modal} className={'modal-lg ' + this.props.className}>
          <ModalBody>
            <CreateModel callback={this.toggle.bind(this)}/>
          </ModalBody>
        </Modal>
        <Modal isOpen={this.state.ModalEdit} className={'modal-lg ' + this.props.className}>
          <ModalBody>
            <CreateModel alarm={this.state.ModelDetails} callback={this.toggleEdit.bind(this)} />
          </ModalBody>
        </Modal>
      </div >
    )
  }

  renderLinkedEntity() {
    var toRender = [];
    if (this.state.Buildings && this.state.Buildings !== undefined
      && this.state.Buildings.length > 0) {
      toRender.push(<h4>{this.t('Infrastructures')}</h4>);
      this.state.Buildings.forEach(buil => {
        toRender.push(<div>
          {buil.name}{' '}<small>{buil.comment}</small>
        </div>);
      });
      toRender.push(<hr/>);
    }
    if (this.state.Sensors && this.state.Sensors !== undefined
      && this.state.Sensors.length > 0) {
      toRender.push(<h4>{this.t('Sensors')}</h4>);
      this.state.Sensors.forEach(sensor => {
        toRender.push(<div>
          {sensor.name}{' '}<small>{sensor.usage}</small>
        </div>);
      });
    }
    return toRender;
  }

  renderModelDatails() {
    var toRender = [];
      if (this.state.Models && this.state.Models.length > 0 && this.state.ModelDetails && this.state.ModelDetails !== undefined
        && this.state.ModelDetails._id !== undefined) {
        toRender.push(<div className="row" key={this.state.ModelDetails._id}>
        <div className="col-lg-4">
          <div className="descCustom">
            <div className="title">{this.t('Alarm.Name')}</div>
            <small>{this.state.ModelDetails.name}</small>
          </div>
          <div className="descCustom">
            <div className="title">{this.t('Alarm.AlarmMessage')}</div>
            <small>{this.state.ModelDetails.desc}</small>
          </div>
          <div className="descCustom">
            <div className="title">{this.t('Alarm.Type')}</div>
            <small>{this.state.ModelDetails.dataName}
            {this.state.ModelDetails.comparator === 0 ? '>' : this.state.ModelDetails.comparator === 1 ? '<' : '='}
            {this.state.ModelDetails.value}</small>
          </div>
          <div className="descCustom">
            <div className="title">{this.t('Alert.Type')}</div>
            <small>{this.state.ModelDetails.alertNotif ? this.t('Notification') : ''}</small>
            <small>{this.state.ModelDetails.alertSms ? this.t('Sms') : ''}</small>
            <small>{this.state.ModelDetails.alertMail ? this.t('Mail') : ''}</small>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="descCustom">
            <div className="title">{this.t('Sensor.Type')}</div>
            {this.renderSensorsTypeList(this.state.ModelDetails.sensorModels)}
          </div>
          </div>
        <div className="col-lg-4">
          <div className="descCustom">
            <div className="title">{this.t('Alarm.UserToPrevent')}</div>
            {this.renderUsersList(this.state.ModelDetails.users)}
          </div>
        </div>
        <div className="col-lg-8">
          <div className="descCustom">
          {this.state.CanEdit? <button type="button" onClick={this.toggleEdit.bind(this)} className="btn btn-primary btn-lg" >{this.t('Model.Edit')}</button> :""}
          </div>
        </div>
        </div>);
    }
    return toRender;
  } 
  renderUsersList(users) {
    var toRender = [];
    for (let i = 0; i < users.length; i += 1) {
      if (users[i] != undefined)
      toRender.push(<small key={i}>{users[i].name} {users[i].firstName}<br/></small>);
    }
    return toRender;
  }
  renderSensorsTypeList(sensorTypes) {
    var toRender = [];
    for (let i = 0; i < sensorTypes.length; i += 1) {
      toRender.push(<small key={i}>{sensorTypes[i]}<br/></small>);
    }
    return toRender;
  }
}

export default translate(Models);
