import { NodeModel, PortModel, Toolkit, DefaultPortModel } from 'storm-react-diagrams';

class ValueNodeModel extends NodeModel {
    constructor(name, color, picto) {
        super('value');
        this.addPort(new DefaultPortModel(false, Toolkit.UID(), 'Sortie'));
        this.name = name;
        this.color = color;
        this.picto = picto;
        this.value = 0;
    }


    deSerialize(object, engine) {
        let keys = Object.keys(this.getPorts());
        while (keys.length > 0) {
            this.removePort(this.getPorts()[keys[0]]);
            keys = Object.keys(this.getPorts());
        }
        super.deSerialize(object, engine);
        this.name = object.name;
        this.color = object.color;
        this.value = object.value;
        this.picto = object.picto;
    }

    serialize() {
        return Object.assign(super.serialize(), {
            name: this.name,
            color: this.color,
            value: this.value,
            picto: this.picto
        });
    }

    getPorts() {
        return this.ports;
    }

    setValue(val) {
        this.value = val;
    }

    getValue() {
        return this.value;
    }
}

export default ValueNodeModel;