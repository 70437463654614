import React, { Component } from 'react';
import reactCSS from 'reactcss';
import moment from 'moment';
import $ from 'jquery';
import { TimePicker } from 'react-input-moment';
import Conf from '../../../utils/Conf';
import { getRequest } from '../../../utils/WebServicesManager';
import { Modal, Button } from 'reactstrap';
import ColorPicker from '../ColorPicker';
import { translate } from '../../../utils/ReactMultiLang';
import SensorSelector from '../../Modals/SensorSelector';


class AddDataSet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Sensors: [],
      FieldList: [],
      SelectedSensor: {},
      Modal: false,
      lineColor: '#f00'
    };
    this.getSensors();
    this.t = props.t;
    
    if (props.actDataSet) {
      const event = {};
      event.target = {}
      event.target.value = props.actDataSet.sensor;
      this.getFieldList(event);
    }
  }
  
	toggle() {
		this.setState({Modal: !this.state.Modal});
  }
  componentWillReceiveProps(nextProps) {
      if (this.props.actDataSet !== nextProps.actDataSet && nextProps.actDataSet) {
        const event = {};
        event.target = {}
        event.target.value = nextProps.actDataSet.sensor;
        this.getFieldList(event);
      }
  }

  getSensors() {
    if (this.state.FieldList.length !== 0)
      return;
    var url = Conf.BaseApi + `devices/get`;
    return getRequest(url, (body) => {
      if (body && body.success) {
        let result = body.result;
        this.setState({ Sensors: result });
        if (result.length > 0 && !this.props.actDataSet) {
          const event = {};
          event.target = {}
          event.target.value = result[0].DevEUI;
          this.getFieldList(event);
        }
      }
    });
  }
  handleColorChange = (color) => {
    this.setState({ lineColor: color });
  };

  sensorSelected(sensors) {
    if (sensors.length > 0) {
      const { DevEUI, _id } = sensors[0];
      var url = Conf.BaseApi + `helpers/fields/${DevEUI}`;
      return getRequest(url, (result) => {
        if (result && result.success) {
          this.setState({ SelectedSensor: sensors[0], FieldList: result.result, SelectedField: result.result[0] });
          this.toggle();
        }
      });
    }
  }
  getFieldList(event) {
    if (event.target.value !== "") {
      const DevEUI = event.target.value;
			let url = Conf.BaseApi + `devices/get/${DevEUI}`;
			getRequest(url, (body) => {
				if (body && body.success) {
					let result = body.result;
					this.setState({ SelectedSensor: result });
				}
			});
      url = Conf.BaseApi + `helpers/fields/${DevEUI}`;
      return getRequest(url, (result) => {
        if (result && result.success) {
          this.setState({ FieldList: result.result, DevEUI });
        }
      });
    }
  }
  
  renderSensors(sensors, actDataSet) {
    var toRender = [];
    for (var i = 0; i < sensors.length; i++) {
      if (actDataSet && actDataSet.sensor === sensors[i].DevEUI) {
        toRender.push(
          <option value={sensors[i].DevEUI} selected>{sensors[i].name}</option>
        );
      } else {
        toRender.push(
          <option value={sensors[i].DevEUI}>{sensors[i].name}</option>
        );
      }
    }
    return toRender;
  }
  

  renderDeviceFields(fields, actDataSet) {
    var toRender = [];
    for (var i = 0; i < fields.length; i++) {
      if (actDataSet && actDataSet.paramName === fields[i]) {
        toRender.push(
          <option value={fields[i]} selected>{this.t(`SensorData.${fields[i]}`)}</option>
        );
      } else {
        toRender.push(
          <option value={fields[i]}>{this.t(`SensorData.${fields[i]}`)}</option>
        );
      }
    }
    return toRender;
  }

  createDataSet(callback, type) {
    const dataSets = {};
    dataSets._id = this.props.actDataSet ? this.props.actDataSet._id : undefined;
    dataSets.sensor = this.state.SelectedSensor ? this.state.SelectedSensor.DevEUI : '';
    dataSets.label = $("#createCpLabel").val();
    dataSets.unit = $('#createCpUnit').val();
    dataSets.paramName = $("#createCpDataName").val();
    dataSets.color = this.state.lineColor;
    if (type === "INT-CHART") {
      dataSets.showAlerts = $("#showAlerts").is(':checked');
    }

    callback(dataSets);
  }

  render() {
    const { callback, actDataSet, type } = this.props;
    const styles = reactCSS({
      'default': {
        color: {
          width: '36px',
          height: '14px',
          borderRadius: '2px',
          background: `${ this.state.lineColor }`,
        },
        swatch: {
          padding: '5px',
          background: '#fff',
          borderRadius: '1px',
          boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
          display: 'inline-block',
          cursor: 'pointer',
        },
        popover: {
          position: 'absolute',
          zIndex: '2',
        },
        cover: {
          position: 'fixed',
          top: '0px',
          right: '0px',
          bottom: '0px',
          left: '0px',
        },
      },
    });
    return (
      <div className="flex-row align-items-center">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12">
              <div className="card mx-12">
                <div className="card-block p-12">
                  <div className="input-group mb-3" style={{ color: "red" }} id="error-window">

                  </div>
                  <div className="input-group mb-3">
                    <span className="input-group-addon"><i className="icon-user"></i></span>
                    <input type="text" className="form-control" placeholder={this.t('Label')} defaultValue={actDataSet ? actDataSet.label : ''} id="createCpLabel" />
                  </div>
                  <div className="input-group mb-3">
                    <span className="input-group-addon"><i className="icon-user"></i></span>
                    <input type="text" className="form-control" placeholder={this.t('Unit')} defaultValue={actDataSet ? actDataSet.unit : ''} id="createCpUnit" />
                  </div>
                  
                  <div className="form-group mb-3">
                    <label htmlFor="createCpColor">{this.t('Color')}</label>
                    <ColorPicker color={actDataSet ? actDataSet.color : ''} callback={this.handleColorChange} />
                  </div>
                  <div className="form-group mb-3">
                    <div><label htmlFor="createCpSensor">{this.t('Sensor')}</label></div>
					<b>{this.state.SelectedSensor.name ? this.state.SelectedSensor.name : this.t(`Sensor.NoSensor`)}{' '}</b>
					<Button className="btn-secondary" onClick={() => this.toggle()}>{this.t(`Sensor.Change`)}</Button>
                  </div>
                  <div className="form-group mb-3">
                    <div><label htmlFor="createCpDataName">{this.t('Component.DataToUse')}</label></div>

                    <select className="form-control" id="createCpDataName" >
                      {this.renderDeviceFields(this.state.FieldList, actDataSet)}
                    </select>
                  </div>
        { type === "INT-CHART" ?
          <div className="input-group mb-1">
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input className="form-check-input" type="checkbox" id="showAlerts" value="true" />
              {this.t('ShowAlerts')}
            </label>
          </div>
          </div>
          : ''}
                  <br />
                  <button type="button" className="btn btn-block btn-success" onClick={this.createDataSet.bind(this, callback)}>{actDataSet ? this.t('Edit') : this.t('Add')}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal isOpen={this.state.Modal} className={'modal-lg ' + this.props.className}>
           <SensorSelector callbackAddSensor={this.sensorSelected.bind(this)} />
        </Modal>
      </div>
    )
  }

}

export default translate(AddDataSet);