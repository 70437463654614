import React, { Component } from 'react';
import {Map} from 'immutable';
import { Progress } from 'reactstrap';
import Conf from '../../utils/Conf';
import $ from 'jquery';
import { getRequest, postRequest } from '../../utils/WebServicesManager';
import TemperatureChart from '../Sensors/Statistics/TemperatureChart';
import AverageBoolean from '../Sensors/Statistics/AverageBoolean';
import AverageData from '../Sensors/Statistics/AverageData';
import AlarmsStats from '../../components/AlarmsStats';
import FloatingMenu from '../../components/FloatingMenu';
import EditComponent from '../../components/DashboardModals/EditComponent';
import CreatComponent from '../../components/DashboardModals/CreatComponent';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { translate } from '../../utils/ReactMultiLang';
import { check } from '../../utils/Can';
/*
import {
  Models as PlannerModels,
  reducer as PlannerReducer,
  ReactPlanner,
  Plugins as PlannerPlugins,
} from '../../components/planner/src';
*/


class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = { Devices: [], 
      SensorMessages: {},
      user: {},
      plan: {},
      ModalComponent: false,
      ModalCreate: false,
      CompoType: "",
      ActComponent: {},
      CanReadAlarm: check("alarm:read")
    };
    this.getUser();
    this.t = props.t;
  }

  toggleEditComponent(col) {
    this.setState({
      ActComponent: col,
      ModalComponent: !this.state.ModalComponent
    });
  }
  toggleCreateComponent(type) {
    this.setState({
      CompoType: type,
      ModalCreate: !this.state.ModalCreate
    });
  }

  getMessagesList(id, nbMessage) {
    var url = Conf.BaseApi + `messages/getComputed/number/${nbMessage}/` + id;
    return getRequest(url, (body) => {
        if (body.success) {
          const messages = body.result;
          messages.sort((a, b) => {
            if (a.time > b.time)
              return 1;
            if (a.time < b.time)
              return -1;
            return 0;
          });
          let tmpSensorMsg = this.state.SensorMessages;
          tmpSensorMsg[id] = messages;
          this.setState({SensorMessages: tmpSensorMsg});
        }
    });
  }

  getUser() {
    var url = Conf.BaseApi + 'user';
    if (this.state.user !== {}) {
      return getRequest(url, (result) => {
        if (result.success === true) {
          this.setState({ user: result.result, SensorMessages: [] });
          this.updateUser(result.result);
        }
      });
    }
  }

  changeSensor(id) {
    if (id && id != undefined)
      this.getTempMessagesList(id);
  }

  renderChart(colClass, col) {
    const toRender = [];
    if (this.state.SensorMessages[col.sensor] !== undefined) {
      toRender.push(
        <div className={colClass} key={col._id}>
          <TemperatureChart messages={this.state.SensorMessages[col.sensor]} 
                              paramName={col.paramName}
                              subtitle={col.label}
                              color={col.color}
                              unit={col.unit}
                              editComponent={this.toggleEditComponent.bind(this, col)}
                              deleteComponent={this.deleteComponent.bind(this, col)}
                              noButton={false} />
        </div>);
    }
    return toRender;
  }

  renderAverage(colClass, col) {
    const toRender = [];
    if (this.state.SensorMessages[col.sensor] !== undefined) {
      toRender.push(
      <div className={colClass} key={col._id}>
        <AverageData messages={this.state.SensorMessages[col.sensor]} 
          paramName={col.paramName}
          color={col.color}
          maxData="45" 
          title={col.label}
          dataSigle={col.unit}
          editComponent={this.toggleEditComponent.bind(this, col)}
          deleteComponent={this.deleteComponent.bind(this, col)}/>
      </div>);
    }
    return toRender;
  }

  renderDynamicComponent(user) {
    const dashboard = user.dashboard;
    var toRender = [];
    if (!dashboard || dashboard === undefined)
      return toRender;
    for (let nbRow = 0; nbRow < dashboard.rows.length; nbRow += 1) {
      const cols = dashboard.rows[nbRow].row;
      let colsComponents = [];
      for (let nbCol = 0; nbCol < cols.length; nbCol += 1) {
        let colClass = `col-lg-${cols[nbCol].size}`
        if (cols[nbCol].size >= 6) {
          colClass = `col-sm-12 col-lg-${cols[nbCol].size}`
        } else {
          colClass = `col-sm-${cols[nbCol].size * 2} col-lg-${cols[nbCol].size}`
        }
        if (cols[nbCol].type !== undefined && cols[nbCol].type.indexOf("CHART") !== -1) {
          colsComponents.push(this.renderChart(colClass, cols[nbCol]));
        } else if (cols[nbCol].type !== undefined) {
          colsComponents.push(this.renderAverage(colClass, cols[nbCol]));
        }
      }
      toRender.push(<div className="row" key={nbRow}>{colsComponents}</div>);
    }
    return toRender;
  }

  updateComponent(component) {
    var url = Conf.BaseApi + 'users/component/update';
      return postRequest(url, {
        component: component
      }, (result) => {
        if (result.success === true) {
          this.setState({ user: result.result });
          this.updateUser(result.result)
          this.toggleEditComponent().bind(this);
        }
      });
  }
  
    createComponent(component) {
      var url = Conf.BaseApi + 'users/component/add';
        return postRequest(url, {
          component: component
        }, (result) => {
          if (result.success === true) {
            this.setState({ user: result.result });
            this.updateUser(result.result)
            this.toggleCreateComponent().bind(this);
          }
        });
    }

    updateUser(user) {
      const sensors = [];
      const nbMessage = [];
      const rows = user.dashboard.rows;
      for (let nbRow = 0; nbRow < rows.length; nbRow += 1) {
        const cols = rows[nbRow].row;
        for (let nbCol = 0; nbCol < cols.length; nbCol += 1) {
          if (sensors.indexOf(cols[nbCol].sensor) === -1) {
            sensors.push(cols[nbCol].sensor);
            nbMessage.push(cols[nbCol].days * 24);
          }
        }
      }
      for (let i = 0; i < sensors.length; i += 1) {
        this.getMessagesList(sensors[i], nbMessage[i])
      }
    }

    deleteComponent(component) {
      var url = Conf.BaseApi + 'users/component/delete/' + component._id;
      const r = window.confirm(this.t('Popup.Delete.Component') + component.label + ' ?');
      if (r) {
        return getRequest(url, (result) => {
          if (result.success === true) {
            this.setState({ user: result.result, ActComponent: {} });
            this.updateUser(result.result);
          }
        });
      }
    }

  render() {

    const effect = 'zoomin',
          pos = 'br',
          method = 'hover';

    return (
      <div className="animated fadeIn">
        <FloatingMenu callbackCreate={this.toggleCreateComponent.bind(this)} dashboard={true} />
        {this.renderDynamicComponent(this.state.user)}
        { this.state.CanReadAlarm ?
        <div className="row">
          <div className="col-sm-12 col-lg-12">
                <AlarmsStats />
          </div>
        </div> : '' }
        
        <Modal isOpen={this.state.ModalComponent} toggle={this.toggleEditComponent.bind(this)} className={'modal-md ' + this.props.className}>
          <ModalHeader>
            {this.t('Component.Edit')}
          </ModalHeader>
          <ModalBody>
            <EditComponent component={this.state.ActComponent} callback={this.updateComponent.bind(this)}/>
          </ModalBody>
        </Modal>

        <Modal isOpen={this.state.ModalCreate} toggle={this.toggleCreateComponent.bind(this)} className={'modal-md ' + this.props.className}>
          <ModalHeader>
                {this.t('Component.Add')}
          </ModalHeader>
          <ModalBody>
            <CreatComponent callback={this.createComponent.bind(this)} type={this.state.CompoType}/>
          </ModalBody>
        </Modal>
      </div>
    )
  }
}

export default translate(Dashboard);
