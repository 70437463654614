import React, { Component } from 'react';
import Conf from '../../../utils/Conf';
import { renderMessageData } from '../../../utils/RenderUtils';
import { getRequest, postRequest } from '../../../utils/WebServicesManager';
import { getDateStr } from '../../../utils/Utils';
import {
  Button
} from 'reactstrap';
import { translate } from '../../../utils/ReactMultiLang';
import ReactTable from "react-table";
import 'react-table/react-table.css';
import diff from 'deep-diff';
import {
  Row, Col
} from 'reactstrap';

class SensorTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
        Key: 0,
        data: [],
        pages: 10,
        loading: false,
        selection: [],
        selectAll: false,
        dataParam: {},
        colList: props.colList,
        deviceUrl: props.deviceUrl,
    };
    this.getDevices(this.state.deviceUrl);
    this.fetchData = this.fetchData.bind(this);
    this.t = props.t;
    this.cols = [
      { id: 'name', accessor: d => <div>
          {d.alertIfUnseen && d.usersToMail.length > 0 ? <img src="img/camera.png" width="15px" style={{marginTop: "-10px", marginRight: "5px"}} /> : '' }
          <a href={`#/sensors/detail?deveui=${d.DevEUI}`}>{d.name}</a>
          </div>, Header: this.t('Name') }, 
        { id: "type", Header: this.t('Type'), accessor: d => {
          const toRender = [];
          d.type.forEach(element => {
            toRender.push(<img src={`img/sensorIcones/${element}.png`} alt='' width={25}/>);
          });
          return (toRender);
        }, maxWidth: 80, filterMethod: (filter, sensor) => {
          const str = sensor._original.type.join();
          if (str.includes(filter.value))
            return (true);
          return false;
        } }, 
        { id: "usage", accessor: d => d.usage ? d.usage : '', Header: this.t('Usage') }, 
        { Header: this.t('Tags'), id: "groups", accessor: d => d.groups ? d.groups.join(', ') : '' }, 
        { id: "lastSeen", 
          maxWidth: 150,
          accessor: d => d.lastSeen ? getDateStr(new Date(d.lastSeen).getTime()) : '',
          Header: this.t('Message.Time') 
        }, { id: 'status', 
          accessor: d => {
            if (d.status) {
            return (<div>
                <span className={(d.status === 'ACTIF') ? 'badge badge-success' : (d.status === 'NOSIGNAL') ? 'badge badge-danger' : 'badge badge-default'}>{this.t(d.status)}</span>
                
            </div>) 
            }
            },
          Header: this.t('Status'), maxWidth: 100 }
    ];
  }
  customSetState(state) {
    this.setState(state, () => localStorage.setItem('InfraSensorTable', JSON.stringify(this.state)));
  }
  
  componentWillReceiveProps(nextProps) {
    if (nextProps.deviceUrl !== this.props.deviceUrl) {
      this.customSetState({dataParam: {}});
      this.getDevices(nextProps.deviceUrl);
    }
  }


  getDevices(deviceUrl) {
    if (!deviceUrl) {
      return [];
    }
    let url = Conf.BaseApi + deviceUrl;
    getRequest(url, (dataS) => {
      if (dataS.success) {
        const sensors = dataS.result;
        this.customSetState({ Sensors: sensors, data: sensors.slice(0, 10), pages: Math.floor(sensors.length / 10) + (sensors.length % 10 ? 1 : 0) });
      } else {
        this.customSetState({ data: [] });
      }
      this.customSetState({ ModalEditBuild: false, ModalAddFloor: false, ModalAddRoom: false });
    });
  }

  renderSensor(data, customs) {
    const toRender = [];
    if (data) {
      const keys = Object.keys(data);
      if (keys.length > 0) {
        toRender.push(
              <div className="buildingItem" >
                  <ul className="message-list">{renderMessageData(data, customs, this.t)}</ul>
              </div>
        );
      }
    }
    return toRender;
  }

  fetchData(state, instance) {
    if (this.state.Sensors) {
        let data = this.state.Sensors.slice(0);

        for (let i = 0; i < state.filtered.length; i += 1) {
            data = data.filter((d) =>
                ('' + d[state.filtered[i].id]).includes(state.filtered[i].value)
            );
        }

        if (state.sorted.length > 0) {
            data.sort((a, b) =>
                !state.sorted[0].desc ?
                ('' + a[state.sorted[0].id]).localeCompare('' +b[state.sorted[0].id]) :
                ('' + b[state.sorted[0].id]).localeCompare('' +a[state.sorted[0].id])
            );
        }

        this.customSetState({
          dataParam: state,
          data: data.slice(state.page * state.pageSize, (state.page + 1) * state.pageSize),
          pages: Math.floor(data.length / state.pageSize) + (data.length % state.pageSize ? 1 : 0)
        });
    }
  }

  render() {
    const { pages, loading } = this.state;
    const columns = this.getColumns(this.state.colList);
    
    return (
      <div className="row" key={this.state.Key}>
        <div className="col-lg-12 col-md-12">
            { this.state.data ? 
            <ReactTable key={this.state.buildingId}
              filterable={true}
              defaultFilterMethod={(filter, row) =>
                String(row[filter.id]).includes(filter.value)}
              data={this.state.data}
              manual // Forces table not to paginate or sort automatically, so we can handle it server-side
              pages={pages} // Display the total number of pages
              loading={loading}
              ref={r => (this.checkboxTable = r)}
              onFetchData={this.fetchData}
              columns={columns}
              building={this.state.buildingId}
              defaultPageSize={10}
              className="-striped -highlight"
              onPageChange={(pageIndex) => {
                this.state.dataParam.page = pageIndex;
                this.customSetState({dataParam: this.state.dataParam});
              }} // Called when the page index is changed by the user
              onPageSizeChange={(pageSize, pageIndex) => {
                this.state.dataParam.pageSize = pageSize;
                this.state.dataParam.page = pageIndex;
                this.customSetState({dataParam: this.state.dataParam});
              }} // Called when the pageSize is changed by the user. The resolve page is also sent to maintain approximate position in the data
              onSortedChange={(newSorted, column, shiftKey) => {
                this.state.dataParam.sorted = newSorted;
                this.customSetState({dataParam: this.state.dataParam});
              }} // Called when a sortable column header is clicked with the column itself and if the shiftkey was held. If the column is a pivoted column, `column` will be an array of columns
              
              onFilteredChange={(filtered, column) => {
                this.state.dataParam.filtered = filtered;
                this.customSetState({dataParam: this.state.dataParam});
              }} // Called when a user enters a value into a filter input field or the value passed to the onFiltersChange handler by the Filter option.
              onResizedChange={(newResized, event) => {
                this.state.dataParam.resized = newResized;
                this.customSetState({dataParam: this.state.dataParam});
              }} // Called when a user clicks on a resizing component (the right edge of a column header)            
              page={this.state.dataParam.page ? this.state.dataParam.page : 0}
              defaultSorted={this.state.dataParam.sorted ? this.state.dataParam.sorted : []}
              defaultFiltered={this.state.dataParam.filtered ? this.state.dataParam.filtered : []}
              defaultResized={this.state.dataParam.resized ? this.state.dataParam.resized : []}
              
            /> : '' }
        </div>
      </div>
    );
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (diff(nextState, this.state) ? true : false);
  }


  /** React-table */
  
  getColumns(colList) {
    return colList.map(c => this.cols[c]);
  }

}

export default translate(SensorTable);