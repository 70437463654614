import React, { Component } from 'react';
import moment from 'moment';
import $ from 'jquery';
import { TimePicker } from 'react-input-moment';
import Conf from '../../utils/Conf';
import { getRequest, postRequest } from '../../utils/WebServicesManager';
import SelectUser from '../Selects/SelectUser';
import SelectSensorsType from '../Selects/SelectSensorsType';
import ReactDaytime from '../react-daytime';
import { ButtonToolbar, ToggleButtonGroup, ToggleButton } from 'react-bootstrap';
import { Col, Row } from 'reactstrap';
import { translate } from '../../utils/ReactMultiLang';
import { getUserId } from '../../utils/AuthServices';
import SelectSensors from '../Selects/SelectSensors';
import MapSelector from './MapSelector/MapSelector';
import TimeField from 'react-simple-timefield';
import ReactTooltip from 'react-tooltip';

class CreateAlarm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      start: moment(),
      groupKey: 0,
      UserGroups: [],
      FieldList: [],
      SensorModels: [],
      isRange: props.alarm && props.alarm.comparator > 2 ? true : false,
      end: moment(),
      doNotCreateAlert: props.alarm ? props.alarm.doNotCreateAlert : false,
      useDuration: props.alarm && props.alarm.useDuration ? true : false,
      dailyRestart: props.alarm && props.alarm.dailyRestart ? true : false,
      SelectedGroup: undefined,
      comparator: props.alarm ? props.alarm.comparator : 0,
      durationType: props.alarm ? props.alarm.durationType : 0,
      SelectedSensors: props.alarm && props.alarm.sensors ? props.alarm.sensors.map(s => s.id ? {id: s.id._id, DevEUI: s.DevEUI } : '') : [],
      UseMap: props.alarm && props.alarm.alarmType === 'gps' ? true : false,
      GPSPos: [],
      useTime: props.alarm && (props.alarm.from !== 0 || props.alarm.to !== 0)  ? true : false,
      timeFrom: props.alarm && props.alarm.from ?
        (parseInt(props.alarm.from / 100, 10) < 10 ? '0' : '') + parseInt(props.alarm.from / 100, 10) + ':' + 
        (props.alarm.from % 100 < 10 ? '0' : '') + props.alarm.from % 100 : '00:00',
      timeTo: props.alarm && props.alarm.to ?
      (parseInt(props.alarm.to / 100, 10) < 10 ? '0' : '') + parseInt(props.alarm.to / 100, 10) + ':' + 
      (props.alarm.to % 100 < 10 ? '0' : '') + props.alarm.to % 100 : '00:00',
    };

    this.callback = props.callback;
    if (props.alarm && props.alarm.sensorModels) {
      this.getFieldList(props.alarm.sensorModels.map(k => { return ({ value: k }); }));
    } else {
      this.getFieldList();
    }
    this.getUserGroups();
    this.t = props.t;
  }

  getFieldList(values, modelsList) {
    let models = [];
    let sensors = [];
    if (values !== undefined) {
      for (let i = 0; i < values.length; i += 1) {
        models.push(modelsList[values[i].value].model);
        const sensor = modelsList[values[i].value];
        let tmp = {};
        tmp.id = sensor._id;
        tmp.DevEUI = sensor.DevEUI;
        sensors.push(tmp);
      }
    }
    this.setState({ SelectedSensors: sensors });
    var url = Conf.BaseApi + `helpers/getModelsData`;
    return postRequest(url, { models }, (result) => {
      if (result && result.success) {
        this.setState({ FieldList: result.result });
      }
    });
  }

  selectGroup(event) {
    const group = event.target.value;
    this.setState({SelectedGroup: group});
  }

  createAlerteChange() {
    this.setState({ doNotCreateAlert: $("#doNotCreateAlert").is(':checked') });
  }
  useDurationChange() {
    this.setState({ useDuration: $("#useDuration").is(':checked') });
  }
  useTimeChange() {
    this.setState({ useTime: $("#useTime").is(':checked') });
  }

  updateGPS (pos) {
    this.setState({GPSPos: pos});
  }

  renderUserGroup() {
    var toRender = [];
    for (var i = 0; i < this.state.UserGroups.length; i++) {
      toRender.push(
        <option value={this.state.UserGroups[i]._id} key={i}>{this.state.UserGroups[i].name}</option>
      );
    }
    return toRender;
  }
  getUserGroups() {
    var url = Conf.BaseApi + 'groups/getAll';
    return getRequest(url, (data) => {
      if (data.success) {
          this.setState({ groupKey: this.state.groupKey + 1, UserGroups: data.result, SelectedGroup: this.props.alarm ? this.props.alarm.group : data.result[0]._id });
      }
    });
  }

  sendAlarm() {
    let alarm = {};
    if (this.props.alarm) {
      alarm = Object.create(this.props.alarm);
    }
    let ids = [];
    const inputs = $("input[name=userId]");
    for (let i = 0; i < inputs.length; i += 1) {
      ids.push(inputs[i].value);
    }
    alarm.name = $("#alarmName").val();
    alarm.desc = $("#alarmMessage").val();
    alarm.group = $("#userGroup").val();
    alarm.dataName = $("#dataName").val();
    alarm.value = $("#alarmValue").val();
    if (this.state.isRange) {
      alarm.maxValue = $("#alarmMaxValue").val();
    }
    alarm.useDuration = this.state.useDuration;
    if (this.state.useDuration) {
      alarm.durationType = this.state.durationType;
      alarm.duration = $("#duration").val();
    }
    alarm.alarmType = "simple";
    if (this.state.UseMap)
      alarm.alarmType = "gps";
    alarm.active= true;
    alarm.level = $('#levelSelector input:radio:checked').val();
    alarm.doNotCreateAlert = $("#doNotCreateAlert").is(':checked');
    alarm.alertNotif = $("#alertNotif").is(':checked');
    alarm.alertSms = $("#alertSms").is(':checked');
    alarm.alertMail = $("#alertMail").is(':checked');
    alarm.alertCall = $("#alertCall").is(':checked');

    alarm.dailyRestart = $("#dailyRestart").is(':checked');

    alarm.comparator = this.state.comparator;
    alarm.from = 0;
    alarm.to = 0;
    alarm.users = ids;
    alarm.sensors = this.state.SelectedSensors;
    alarm.gpsLocation = this.state.GPSPos;
    if (this.state.useTime) {
      const froms = this.state.timeFrom.split(':');
      const tos = this.state.timeTo.split(':');
      alarm.from = parseInt(froms[0]) * 100 + parseInt(froms[1]);
      alarm.to = parseInt(tos[0]) * 100 + parseInt(tos[1]);
      alarm.days = {
        sunday: $("#sunday").is(':checked'),
        monday: $("#monday").is(':checked'),
        tuesday: $("#tuesday").is(':checked'),
        wednesday: $("#wednesday").is(':checked'),
        thursday: $("#thursday").is(':checked'),
        friday: $("#friday").is(':checked'),
        saturday: $("#saturday").is(':checked'),
      }
    }
    var url = Conf.BaseApi + 'alarms/add';
    if (this.props.alarm) {
      var url = Conf.BaseApi + 'alarms/edit';
    }
    return postRequest(url, {
      alarm
    }, (data) => {
      if (data.success || data.success == true) {
        if (this.callback != undefined) {
          this.callback();
        }
      } else {
        $("#error-window").text(data.message);
      }
    });
  }

  handleChangeStart = start => {
    this.setState({ start: start });
  };

  handleChangeEnd = end => {
    this.setState({ end: end });
  };

  renderFields(fields) {
    var toRender = [];
    for (var i = 0; i < fields.length; i++) {
      toRender.push(
        <option value={fields[i]} key={i}>{this.t(`SensorData.${fields[i]}`)}</option>
      );
    }
    return toRender;
  }

  renderModels(models) {
    var toRender = [];
    toRender.push(
      <option value=''>Tout les models</option>
    );
    for (var i = 0; i < models.length; i++) {
      toRender.push(
        <option value={models[i]} key={i}>{models[i]}</option>
      );
    }
    return toRender;
  }

  handleChange(selectedRange) {
    console.log('Selected time frames: ', selectedRange);
  }

  onDurationTypeChange(evt) {
    const type = evt.target.value;

      this.setState({durationType: type});
  }

  onAlarmTypeChange(evt) {
    const type = evt.target.value;

    if (type == 3 || type == 4) {
      this.setState({isRange: true, comparator: type});
    } else {
      this.setState({isRange: false, comparator: type});
    }
  }

  render() {
    const { alarm, callback } = this.props;

    return (
      <div className="flex-row align-items-center">
        <ReactTooltip multiline={true}  type='info' delayShow={250} />
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12">
              <div className="card mx-12">
                <div className="card-block p-12">
                  <h2>{this.t('Alarm.Alarm')}
                    <i className="icon-close color-danger" onClick={callback}
                      style={{ position: 'absolute', textAlign: 'right', right: '15px', cursor: 'pointer' }}></i></h2>
                  <p className="text-muted">{this.t('Alarm.Add')}</p>
                  <div className="input-group mb-3" style={{ color: "red" }} id="error-window">

                  </div>
                  <Row>
                    <Col md='6'>
                      <div className="input-group mb-3">
                        <span className="input-group-addon"><i className="icon-tag"></i></span>
                        <input type="text" className="form-control" placeholder={this.t('Name')} id="alarmName"
                          defaultValue={alarm !== undefined ? alarm.name : ""} />
                      </div>
                    </Col>
                    <Col md='6'>
                      <label htmlFor="sensorType">{this.t('UserGroup')}</label>
                      {this.state.UserGroups.length > 0 ?
                        <select className="form-control" id="userGroup" key={this.state.groupKey} defaultValue={alarm !== undefined ? alarm.group : ""} onChange={this.selectGroup.bind(this)}>
                          {this.renderUserGroup()}
                        </select> : ''}
                      <br />
                    </Col>
                  </Row>
                  <div className="input-group mb-3">
                    <span className="input-group-addon"><i className="icon-note"></i></span>
                    <input type="text" className="form-control" placeholder="Message" id="alarmMessage"
                      defaultValue={alarm !== undefined ? alarm.desc : ""} />
                  </div>

                  <div className="form-group mb-3">
                  </div>
                  <Row>
                    <Col md='12'>
                      <label htmlFor="userGroup">{this.t('Sensors')}</label>
                      <SelectSensors
                        entity='devices' values={alarm && alarm.sensors ? alarm.sensors.map(s => s.id ? {value: s.id._id, label: s.id.name} : undefined) : ""}
                        group={this.state.SelectedGroup} updateSelection={this.getFieldList.bind(this)}/>
                    </Col>
                    <Col sm='12'>
                      {this.t('Model.DataToTest')}
                      <br />
                      {this.state.FieldList.length > 0 ?
                        <select className="form-control" id="dataName" defaultValue={alarm !== undefined ? alarm.dataName : ''} onChange={this.selectField.bind(this)}>
                          {this.renderFields(this.state.FieldList)}
                        </select> : ''}
                    </Col>
                  </Row>
                  <br />
                  { !this.state.UseMap ? 
                  <div>
                  <Row>
                    <Col md='12'>
                      <ButtonToolbar>
                        <ToggleButtonGroup type="radio" name="options" id="comparator" defaultValue={alarm !== undefined ? alarm.comparator : -1}>
                          <ToggleButton value={0} bsStyle="success" onChange={this.onAlarmTypeChange.bind(this)}>{this.t('Alarm.Upper')}</ToggleButton>
                          <ToggleButton value={1} bsStyle="success" onChange={this.onAlarmTypeChange.bind(this)}>{this.t('Alarm.Under')}</ToggleButton>
                          <ToggleButton value={2} bsStyle="success" onChange={this.onAlarmTypeChange.bind(this)}>{this.t('Alarm.Equal')}</ToggleButton>
                          <ToggleButton value={3} bsStyle="success" onChange={this.onAlarmTypeChange.bind(this)}>{this.t('Alarm.Range')}</ToggleButton>
                          <ToggleButton value={4} bsStyle="success" onChange={this.onAlarmTypeChange.bind(this)}>{this.t('Alarm.InRange')}</ToggleButton>
                        </ToggleButtonGroup>
                      </ButtonToolbar>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col md='6'>
                      <div className="input-group mb-6">
                        <span className="input-group-addon"><i className="icon-speedometer"></i></span>
                        <input type="number" step="0.5" className="form-control" placeholder={this.state.isRange ? `Min ${this.t('Value')}` : this.t('Value') } id="alarmValue"
                          defaultValue={alarm !== undefined ? alarm.value : ''} />
                      </div>
                    </Col>
                    { this.state.isRange ? 
                    <Col md='6'>
                      <div className="input-group mb-6">
                        <span className="input-group-addon"><i className="icon-speedometer"></i></span>
                        <input type="number" step="0.5" className="form-control" placeholder={`Max ${this.t('Value')}`} id="alarmMaxValue"
                          defaultValue={alarm !== undefined && alarm.maxValue ? alarm.maxValue : ''} />
                      </div>
                    </Col> : ''}
                  </Row>
                  <br />
                  
                  <Col md='6'>
                  <div className="input-group mb-3">
                        <div className="form-check form-check-inline">
                          <label className="form-check-label">
                            <input className="form-check-input" type="checkbox" id="useDuration" value="true" onChange={() => this.useDurationChange()}
                              defaultChecked={alarm !== undefined ? alarm.useDuration : false} />
                            {" "}{this.t('Alarm.UseDuration')}
                          </label>
                        </div>
                      </div>
                    </Col>
                  {this.state.useDuration ? 
                    <Row>
                      <Col md='6'>
                        <div className="input-group mb-6">
                          <span className="input-group-addon"><i className="icon-calendar"></i></span>
                          <input type="number" step="0.5" className="form-control" placeholder={this.t('Duration (hour)')} id="duration"
                            defaultValue={alarm !== undefined && alarm.duration ? alarm.duration : ''} />
                        </div>
                      </Col>
                      <Col md='6'>
                        <ButtonToolbar>
                          <ToggleButtonGroup type="radio" name="options" id="durationType" defaultValue={alarm !== undefined ? alarm.durationType : 0}>
                            <ToggleButton value={0} bsStyle="success" onChange={this.onDurationTypeChange.bind(this)}>{this.t('Average')}</ToggleButton>
                            <ToggleButton value={1} bsStyle="success" onChange={this.onDurationTypeChange.bind(this)}>{this.t('Sum')}</ToggleButton>
                          </ToggleButtonGroup>
                        </ButtonToolbar>
                      </Col>
                    </Row>
                  : ''}

                  <br />
                  </div> : <div>
                    <MapSelector
                      updateGPS={this.updateGPS.bind(this)}
                      enterExit={this.onAlarmTypeChange.bind(this)}
                      defaultRange={alarm ? alarm.value : 0.5}
                      isExit={alarm ? alarm.comparator : 1}/><br/>
                  </div>
                  }
                  
                  <Col md='6'>
                  <div className="input-group mb-3">
                        <div className="form-check form-check-inline">
                          <label className="form-check-label">
                            <input className="form-check-input" type="checkbox" id="useTime" value="true" onChange={() => this.useTimeChange()}
                              defaultChecked={this.state.useTime} />
                            {" "}{this.t('Alarm.UseTime')}
                          </label>
                        </div>
                      </div>
                    </Col>
                  {this.state.useTime ? 
                    <Row>
                      <Col md='6'>
                        {this.t('From')}: {' '}
                        <TimeField
                        style={{
                          border: '1px solid #666',
                          fontSize: 18,
                          width: 200,
                          padding: '5px 8px',
                          color: '#333',
                          borderRadius: 3
                        }}
                            value={this.state.timeFrom}                     
                            onChange={(event, value) => {this.setState({timeFrom: value})}}                    
                        />
                      </Col>
                      <Col md='6'>
                        {this.t('To')}: {' '}
                        <TimeField
                        
                        style={{
                          border: '1px solid #666',
                          fontSize: 18,
                          width: 200,
                          padding: '5px 8px',
                          color: '#333',
                          borderRadius: 3
                        }}
                            value={this.state.timeTo}                     
                            onChange={(event, value) => {this.setState({timeTo: value})}}                    
                        />
                      </Col>
                    </Row>
                  : ''}
                  <br />
                  {this.state.useTime ? 
                    <Row>
                      <Col md='3'>
                        <div className="input-group mb-3">
                          <div className="form-check form-check-inline">
                            <label className="form-check-label">
                              <input className="form-check-input" type="checkbox" id="monday" value="true"
                                defaultChecked={alarm !== undefined && alarm.days ? alarm.days.monday : true} />
                              {" "}{this.t('Monday')}
                            </label>
                          </div>
                        </div>
                      </Col>
                      <Col md='3'>
                        <div className="input-group mb-3">
                          <div className="form-check form-check-inline">
                            <label className="form-check-label">
                              <input className="form-check-input" type="checkbox" id="tuesday" value="true"
                                defaultChecked={alarm !== undefined && alarm.days ? alarm.days.tuesday : true} />
                              {" "}{this.t('Tuesday')}
                            </label>
                          </div>
                        </div>
                      </Col>
                      <Col md='3'>
                        <div className="input-group mb-3">
                          <div className="form-check form-check-inline">
                            <label className="form-check-label">
                              <input className="form-check-input" type="checkbox" id="wednesday" value="true"
                                defaultChecked={alarm !== undefined && alarm.days ? alarm.days.wednesday : true} />
                              {" "}{this.t('Wednesday')}
                            </label>
                          </div>
                        </div>
                      </Col>
                      <Col md='3'>
                        <div className="input-group mb-3">
                          <div className="form-check form-check-inline">
                            <label className="form-check-label">
                              <input className="form-check-input" type="checkbox" id="thursday" value="true"
                                defaultChecked={alarm !== undefined && alarm.days ? alarm.days.thursday : true} />
                              {" "}{this.t('Thursday')}
                            </label>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  : ''}{this.state.useTime ? 
                    <Row>
                      <Col md='3'>
                        <div className="input-group mb-3">
                          <div className="form-check form-check-inline">
                            <label className="form-check-label">
                              <input className="form-check-input" type="checkbox" id="friday" value="true"
                                defaultChecked={alarm !== undefined && alarm.days ? alarm.days.friday : true} />
                              {" "}{this.t('Friday')}
                            </label>
                          </div>
                        </div>
                      </Col>
                      <Col md='3'>
                        <div className="input-group mb-3">
                          <div className="form-check form-check-inline">
                            <label className="form-check-label">
                              <input className="form-check-input" type="checkbox" id="saturday" value="true"
                                defaultChecked={alarm !== undefined && alarm.days ? alarm.days.saturday : true} />
                              {" "}{this.t('Saturday')}
                            </label>
                          </div>
                        </div>
                      </Col>
                      <Col md='3'>
                        <div className="input-group mb-3">
                          <div className="form-check form-check-inline">
                            <label className="form-check-label">
                              <input className="form-check-input" type="checkbox" id="sunday" value="true"
                                defaultChecked={alarm !== undefined && alarm.days ? alarm.days.sunday : true} />
                              {" "}{this.t('Sunday')}
                            </label>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  : ''}

                  {this.t('Alarm.UserToPrevent')}
                  <SelectUser scopeParam="linked" values={alarm !== undefined ? alarm.users : undefined} />

                  <Row>
                    <Col md='6'>
                      {this.t('Alarm.Level')}
                      <ButtonToolbar>
                        <ToggleButtonGroup type="radio" name="levelSelector" id="levelSelector" defaultValue={alarm !== undefined ? `${alarm.level}` : '2'}>
                          <ToggleButton value="1" bsStyle="warning">{this.t('Warning')}</ToggleButton>
                          <ToggleButton value="2" bsStyle="danger">{this.t('Danger')}</ToggleButton>
                        </ToggleButtonGroup>
                      </ButtonToolbar>
                    </Col>

                    <Col md='6'>
                      <div className="input-group mb-3">
                        <div className="form-check form-check-inline">
                          <label className="form-check-label">
                            <input className="form-check-input" type="checkbox" id="doNotCreateAlert" value="true" onChange={() => this.createAlerteChange()}
                              defaultChecked={alarm !== undefined ? alarm.doNotCreateAlert : false} />
                            {this.t('Alarm.DoNotDisable')}<span className='tooltype-btn' data-tip={this.t('Tooltips.Alarms.NoDisable')}>?</span>
                          </label>
                        </div>
                      </div>
                      {this.t('Alert.Type')} :
                  {!this.state.doNotCreateAlert ?
                        <div className="input-group mb-3">
                          <div className="form-check form-check-inline">
                            <label className="form-check-label">
                              <input className="form-check-input" type="checkbox" id="alertNotif" value="true"
                                defaultChecked={alarm !== undefined ? alarm.alertNotif : false} /> {this.t('Notification')}
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <label className="form-check-label">
                              <input className="form-check-input" type="checkbox" id="alertSms" value="true"
                                defaultChecked={alarm !== undefined ? alarm.alertSms : false} /> {this.t('Sms')}
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <label className="form-check-label">
                              <input className="form-check-input" type="checkbox" id="alertMail" value="true"
                                defaultChecked={alarm !== undefined ? alarm.alertMail : false} /> {this.t('Mail')}
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <label className="form-check-label">
                              <input className="form-check-input" type="checkbox" id="alertCall" value="true"
                                defaultChecked={alarm !== undefined ? alarm.alertCall : false} /> {this.t('Call')}
                            </label>
                          </div>
                        </div> :
                        <div className="input-group mb-3">
                          <div className="form-check form-check-inline">
                            <label className="form-check-label">
                              <input className="form-check-input" type="checkbox" id="alertNotif" checked="checked" disabled /> {this.t('Notification')}
                            </label>
                          </div>
                        </div>
                      }
                    </Col>
                  </Row>
                  <Row>
                  <Col md='12'>
                  <div className="input-group mb-3">
                        <div className="form-check form-check-inline">
                          <label className="form-check-label">
                            <input className="form-check-input" type="checkbox" id="dailyRestart" value="true"
                              defaultChecked={alarm !== undefined ? alarm.dailyRestart : false} />
                            {" "}{this.t('Alarm.DailyRestart')}
                          </label>
                        </div>
                      </div>
                    </Col>
                    </Row>
                  <button type="button" className="btn btn-block btn-success" onClick={this.sendAlarm.bind(this)}>{alarm !== undefined ? this.t('Edit') : this.t('Create')}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  selectField(evt) {
    const field = evt.target.value;
    let useMap = false;
    if (field === "lat" || field === "lng") {
      useMap = true;
    }
    this.setState({UseMap: useMap});
  }
}


export default translate(CreateAlarm);