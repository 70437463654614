import React, { Component } from 'react';
import { Bar, Line } from 'react-chartjs-2';
import Conf from '../../../utils/Conf';
import AlarmSensor from './AlarmSensor';
import { getRequest, postRequest } from '../../../utils/WebServicesManager';

class AlarmDetails extends Component {
    constructor(props) {
        super(props);
        this.state = { 
          alarmName: ""
        };
      }

      renderDevicesList(Devices) {
        var toRender = [];
        for (var i = 0; i < Devices.length; i++) {
            if (Devices[i].id) {
                var serial = Devices[i].id.serial;
                var type = Devices[i].id.type;
                var batteryLevel = Math.random() * 20 + 80;
                batteryLevel = parseInt(batteryLevel);
                var lastSeen = Math.random() * 60 + 1;
                lastSeen = parseInt(lastSeen);
                //<span className="avatar-status badge-warning"></span>
                toRender.push(
                    <AlarmSensor device={Devices[i].id}  key={Devices[i].id + '' + i}/>
                );
            }
        }
        return toRender;
      }

    render() {
        const { alarm } = this.props;

        return (
            <div>
                <div className="row">
                    <div className="col-lg-12">
                        <ul className="icons-list-big">
                            <li>
                                <i className={alarm.active ? "icon-bell bg-success" : "icon-bell bg-danger"}></i>
                                <div className="desc">
                                    <div className="title">{alarm.name}</div>
                                    <small>{alarm.desc}</small>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <br/>
                {this.renderDevicesList(alarm.sensors)}
                <br/> <br/>
            </div>
        )
    }
}

export default AlarmDetails;


