import React, { Component } from 'react';
import createClass from 'create-react-class';
import Conf from '../../../utils/Conf';
import Select from 'react-select';
import { getRequest } from '../../../utils/WebServicesManager';
import fetch from 'isomorphic-fetch';


class SelectGroup extends Component {
	constructor(props) {
		super(props);
		this.displayName = 'SelectGroup';
		this.state = {
			multiValue: [],
			options: [],
			backspaceRemoves: true,
			multi: true,
			value: undefined,
			isEdited: false
		};
		this.entity = props.entity;
		this.getGroups ();
	}
	getInitialState () {
		return {
			multiValue: [],
			options: [],
			backspaceRemoves: true,
			multi: true,
			value: undefined
		};
	}
	handleOnChange (value) {
		const { multi } = this.state;
		if (multi) {
			this.setState({ multiValue: value, isEdited: true });
		} else {
			this.setState({ value });
		}
	}
	getGroups () {
		var url = Conf.BaseApi + this.entity + '/groups';
		return getRequest(url, (body) => {
			if (body.success) {
				const data = body.result;
				const options = [];
				for (let i = 0; i < data.length; i += 1) {
					options.push({value: data[i], label: data[i]});
				}
				this.setState({ options });
			}
		  });
	}
	toggleBackspaceRemoves () {
		this.setState({
			backspaceRemoves: !this.state.backspaceRemoves
		});
	}
	toggleCreatable () {
		this.setState({
			creatable: !this.state.creatable
		});
	}
	render () {
		const { multi, multiValue, value, options } = this.state;
		const { values } = this.props;
		if (multiValue && multiValue !== undefined && multiValue.length === 0 && values && values !== undefined && values.length > 0 && !this.state.isEdited)
			this.setState({multiValue: values});
		return (
			<div className="section">
				<Select.Creatable 
					multi={this.state.multi}
					value={multi ? multiValue : value}
					options={this.state.options}
					onChange={this.handleOnChange.bind(this)}
					backspaceRemoves={this.state.backspaceRemoves}
					id="groupsName"
					name="groupsName"
					/>
			</div>
		);
	}
}


export default SelectGroup;