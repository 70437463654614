import React, { Component } from 'react';
import $ from 'jquery';
import Conf from '../../../utils/Conf';
import { getRequest, postRequest } from '../../../utils/WebServicesManager';
import { pagination } from '../../../utils/Utils';
import {
  Button, Row,
  Modal, ModalHeader, ModalBody, ModalFooter,
  Pagination, PaginationItem, PaginationLink,
  Label, Input
} from 'reactstrap';
import AddLoRa from '../../../components/AddSensor/AddLoRa';
import AddSigFox from '../../../components/AddSensor/AddSigFox';
import { translate } from '../../../utils/ReactMultiLang';
import DataSelection from '../../../components/Modals/DataSelection';
import { getUserId } from '../../../utils/AuthServices';

class QuickView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Devices: [],
      UserGroups: [],
      SensorGroups: [],
      DeviceMessages: [],
      DeviceDetails: {},
      Modal: false,
      ModalML: false,
      ActualPage: 0,
      ActualDevicePage: 1,
      ActualGroup: {},
      DevEUI: '',
      CanCreate: false
    };
    this.DevicePerPage = 12;
    this.SelectedDevice = 0;
    this.getDevicesList();
    this.getAllUserGroups();
    this.getSensorGroups();
    this.toggle = this.toggle.bind(this);
    this.toggleML = this.toggleML.bind(this);
    this.t = props.t;
  }

  getDevicesList() {
    var url = Conf.BaseApi + 'devices/get';
    return getRequest(url, (data) => {
      if (data.success) {
        this.setState({ Devices: data.result });
        if (this.state.Modal)
          this.toggle();
      }
    });
  }
  getDevicesListOfType(event) {
    if (event.target.value !== "") {
      $('#selectSensorGroup').val("");
      var url = Conf.BaseApi + 'devices/type/' + event.target.value;
      return getRequest(url, (data) => {
        if (data.success)
          this.setState({ Devices: data.result });
      });
    } else {
      this.getDevicesList();
    }
  }
  getDevicesListOfGroup(event) {
    if (event.target.value !== "") {
      $('#selectSensorType').val("");
      var url = Conf.BaseApi + 'devices/group/' + event.target.value;
      return getRequest(url, (data) => {
        if (data.success)
          this.setState({ Devices: data.result });
      });
    } else {
      this.getDevicesList();
    }
  }
  getAllUserGroups() {
    var url = Conf.BaseApi + 'groups/getAll';
    return getRequest(url, (data) => {
      if (data.success) {
        const userGroup = [];
        let canCreate = false;
        const userId = getUserId();
        data.result.forEach(element => {
          const user = element.users.find(e => e.id && e.id._id === userId);
          if (user && (user.isAdmin || (user.permission && user.permission.sensors === 2))) {
            canCreate = true;
            userGroup.push(element);
          }
        });
        this.setState({ CanCreate: canCreate, UserGroups: userGroup, ActualGroup: userGroup[0] });
      }
    });
  }

  getUserGroups() {
    var url = Conf.BaseApi + 'groups/get';
    return getRequest(url, (data) => {
      if (data.success)
        this.setState({ UserGroups: data.result, ActualGroup: data.result[0] });
    });
  }
  getSensorGroups() {
    var url = Conf.BaseApi + 'devices/groups';
    return getRequest(url, (data) => {
      if (data.success)
        this.setState({ SensorGroups: data.result });
    });
  }
  getDevicesDetails(serial, deveui, i) {
    this.SelectedDevice = i;
    this.state.ActualPage = 0;
    var url = Conf.BaseApi + Conf.GetSensor + serial;
    getRequest(url, (data) => {
      if (data.success)
        this.setState({ DeviceDetails: data.result });
    });
    url = Conf.BaseApi + 'messages/get/' + deveui;
    getRequest(url, (data) => {
      if (data.success)
        this.setState({ DeviceMessages: data.result });
    });
  }

  onStatusChange(DevEUI, event) {
    this.setState({DevEUI});
    const isActive = event.target.checked;
    var url = Conf.BaseApi + 'devices/toggleML';
    if (!isActive) {
      postRequest(url, {DevEUI, activeML: isActive}, (data) => {});
    } else {
      setTimeout(this.toggleML, 500);
    }
  }

  renderDevicesList() {
    var toRender = [];
    const startPos = (this.state.ActualDevicePage - 1) * this.DevicePerPage;
    for (var i = startPos ; i < startPos + this.DevicePerPage; i++) {
      if (i < this.state.Devices.length) {
        var serial = this.state.Devices[i].DevEUI;
        const type = this.state.Devices[i].type;
        toRender.push(
          <li key={i}>
            <div className="row">
              <div className="col-lg-12 col-md-12 col-xs-12 sensorListItem" onClick={this.getDevicesDetails.bind(this, serial, this.state.Devices[i].DevEUI, i)}>
                
                  <i><img width="100%" src={type.includes(Conf.SensorType.magnetic) ? 'img/sensors/door.png' : type.includes(Conf.SensorType.motion) ? 'img/sensors/motion.png' : 'img/sensors/temperature.png'} /></i>
              
                <div className="desc ">
                  <div className="title">{this.state.Devices[i].name}</div>
                  <small>{this.state.Devices[i].usage}</small>
                </div>
                {this.state.Devices[i].isAdmin &&
                <div className="value-one">
                  <Label className="switch switch-text switch-pill switch-success">
                    <Input type="checkbox" className="switch-input"
                      defaultChecked={this.state.Devices[i].activeML}
                      onChange={this.onStatusChange.bind(this, this.state.Devices[i].DevEUI)}/>
                    <span className="switch-label" data-on="On" data-off="Off"></span>
                    <span className="switch-handle"></span>
                  </Label>
                </div> }
                <div className="value">
                  <span className={(this.state.Devices[i].status === 'ACTIF') ? 'badge badge-success' : 'badge badge-default'}>{this.t(this.state.Devices[i].status)}</span>
                </div>
                <div className="actions">
                  {this.state.Devices[i].isAdmin ? <button type="button" onClick={this.removeSensor.bind(this, this.state.Devices[i].DevEUI)} className="btn btn-link text-danger"><i className="icon-close" value={this.state.Devices[i].DevEUI}></i></button> : ""}
                </div>
                <br />
              </div>
            </div>
          </li>
        );
      }
    }
    return toRender;
  }

  renderJson(json) {
    var toRender = [];
    for (var key in json) {
      var attrName = key;
      var attrValue = json[key];
      toRender.push(<span key={key}><b>{this.t(`SensorData.${attrName}`)}</b>: {attrValue} |</span>);
    }
    return toRender;
  }

  renderDeviceMessages() {
    var toRender = [];
    for (var i = 10 * this.state.ActualPage; i < (10 * this.state.ActualPage + 10); i++) {
      if (i >= this.state.DeviceMessages.length)
        break;
      var d = new Date(this.state.DeviceMessages[i].time * 1000);
      var tmplabel = d.getDate() + '/' + ((d.getMonth() > 9) ? d.getMonth() + 1 : '0' + (d.getMonth() + 1)) + '/' + d.getFullYear() + ' ' + d.getHours() + 'h' + ((d.getMinutes() > 9) ? d.getMinutes() : '0' + d.getMinutes());

      toRender.push(
        <div className="descCustom" key={i}>
          <span className="title">{tmplabel}&nbsp;&nbsp;
              {this.renderJson(this.state.DeviceMessages[i].data)}</span>
        </div>);
    }
    return toRender;
  }

  renderDevicesDetail() {
    var toRender = [];
    if (this.state.DeviceDetails != undefined) {
      toRender.push(<div className="row" key={this.state.DeviceDetails.serial}>
        <div className="col-lg-6">
          <div className="descCustom">
            <div className="title">{this.t('Name')}</div>
            <small>{this.state.DeviceDetails.name !== undefined ? this.state.DeviceDetails.name : ""}</small>
          </div>
          <div className="descCustom">
            <div className="title">{this.t('Serial')}</div>
            <small>{this.state.DeviceDetails.serial !== undefined ? this.state.DeviceDetails.serial : ""}</small>
          </div>
          <div className="descCustom">
            <div className="title">{this.t('Sensor.Type')}</div>
            
            <small>{this.state.DeviceDetails.type !== undefined ? this.state.DeviceDetails.type.join(' - ') : ""}</small>
          </div>
          <div className="descCustom">
            <div className="title">{this.t('Usage')}</div>
            <small>{this.state.DeviceDetails.usage !== undefined ? this.state.DeviceDetails.usage : ""}</small>
          </div>
          <div className="descCustom">
            <div className="title">{this.t('Sensor.Model')}</div>
            <small>{this.state.DeviceDetails.model !== undefined ? this.state.DeviceDetails.model : ""}</small>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="descCustom">
            <div className="title">DevEUI/SigID</div>
            <small>{this.state.DeviceDetails.DevEUI !== undefined ? this.state.DeviceDetails.DevEUI : ""}</small>
          </div>
          <div className="descCustom">
            <div className="title">AppEUI/PAC</div>
            <small>{this.state.DeviceDetails.AppEUI !== undefined ? this.state.DeviceDetails.AppEUI : ""}</small>
          </div>
          <div className="descCustom">
            <div className="title">AppKey</div>
            <small>{this.state.DeviceDetails.AppKey !== undefined ? this.state.DeviceDetails.AppKey : ""}</small>
          </div>
          <div className="descCustom">
            <div className="title">{this.t('Status')}</div>
            <small><span className={(this.state.DeviceDetails.status === 'ACTIF') ? 'badge badge-success' : 'badge badge-default'}>
              {this.state.DeviceDetails.status !== undefined ? this.t(this.state.DeviceDetails.status) : ''}
            </span></small>
          </div>
          <div className="descCustom">
            <div className="title">{this.t('Model.Model')}</div>
            <small>{this.renderAlarmModels(this.state.DeviceDetails.alarmModels)}</small>
          </div>
        </div>
      </div>);
    }
    return toRender;
  }

  renderAlarmModels(models) {
    var toRender = [];
    if (models != undefined) {
      for (let i = 0; i < models.length; i += 1) {
        toRender.push(
          <div>
            {models[i].name}<small>{models[i].desc}</small>
          </div>
        );
      }
    }
    return toRender;
  }

  toggleML() {
    this.setState({
      ModalML: !this.state.ModalML
    });
  }
  toggle() {
    this.setState({
      Modal: !this.state.Modal
    });
  }

  removeSensor(DevEUI) {
    var r = window.confirm(this.t('Popup.Delete.Sensor'));
    if (r == true) {
      var url = Conf.BaseApi + Conf.RemoveSensor + "/" + DevEUI;
      getRequest(url, (data) => {
        if (data.success && data.success == true) {
          this.getDevicesList();
        }
      });
    }
  }
  
  setActDevicePage(p) {
    if (typeof p === 'number') {
      this.setState({ActualDevicePage: p});
    }
  }


  changePageCount(changePage) {
    var actPage = this.state.ActualPage + changePage;
    if (actPage < 0 || actPage > this.state.DeviceMessages.length / 10)
      return;
    this.setState({ ActualPage: actPage });
  }

  renderSensorGroups() {
    var toRender = [];
    for (var i = 0; i < this.state.SensorGroups.length; i++) {
      toRender.push(
        <option key={i} value={this.state.SensorGroups[i]}>{this.state.SensorGroups[i]}</option>
      );
    }
    return toRender;
  }

  render() {
    const divStyle = {
      width: '60%'
    };

    return (
      <div className="row">
        <div className="col-lg-6 col-md-12">
          <div className="card">
            <div className="card-header">
              <div className="row">
                <div className="col-lg-6 vertical-center">
                  <i className="fa fa-align-justify"></i> {this.t('Sensor.List')}
                </div>
                <div className="col-lg-3">
                  <select className="form-control" id='selectSensorType' onChange={this.getDevicesListOfType.bind(this)}>
                    <option value="">{this.t('AllTypes')}</option>
                       <option value={Conf.SensorType.magnetic}>{this.t('Sensor.Magnetic')}</option>
                       <option value={Conf.SensorType.temperature}>{this.t('Sensor.Temperature')}</option>
                       <option value={Conf.SensorType.motion}>{this.t('Sensor.Motion')}</option>
                       <option value={Conf.SensorType.level}>{this.t('Sensor.Level')}</option>
                       <option value={Conf.SensorType.smoke}>{this.t('Sensor.Smoke')}</option>
                       <option value={Conf.SensorType.water}>{this.t('Sensor.Water')}</option>
                  </select>
                </div>
                <div className="col-lg-3">
                  <select className="form-control" id='selectSensorGroup' onChange={this.getDevicesListOfGroup.bind(this)}>
                    <option value="">{this.t('AllGroups')}</option>
                    {this.renderSensorGroups()}
                  </select>
                </div>
              </div>
            </div>
            <div className="card-block">
              <ul className="icons-list">
                {this.renderDevicesList()}
              </ul>
              <br />
                <Row className='pagination-center'>
                  <Pagination>
                    {this.generateDevicePagination(this.state.Devices.length)}
                  </Pagination>
                </Row>
              <br />
              {this.state.CanCreate ? <button type="button" onClick={this.toggle} className="btn btn-primary btn-lg" >{this.t('Sensor.Add')}</button> : ""}
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-12">
          <div className="row ">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-header">
                  <i className="fa fa-align-justify"></i> {this.t('Sensor.Details')}
                </div>
                <div className="card-block">
                  {this.renderDevicesDetail()}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-header">
                  <i className="fa fa-align-justify"></i> {this.t('Messages')}
                </div>
                <div className="card-block">
                  <ul className="pagination">
                    <li className="page-item page-link" onClick={this.changePageCount.bind(this, -1)}>{this.t('Prev')}</li>
                    <li className="page-item page-link" onClick={this.changePageCount.bind(this, 1)}>{this.t('Next')}</li>
                  </ul>
                  {this.renderDeviceMessages()}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal isOpen={this.state.Modal} toggle={this.toggle} className={'modal-md ' + this.props.className}>
           {this.getAddDeviceContent(this.state.ActualGroup)}
        </Modal>
        <Modal isOpen={this.state.ModalML} toggle={this.toggleML} className={'modal-lg ' + this.props.className}>
          <DataSelection DevEUI={this.state.DeviceDetails.DevEUI}/>
        </Modal>
      </div>

    )
  }

  updateActGroup(group) {
    this.setState({ActualGroup: group});
  }

  getAddDeviceContent(ActualGroup) {
    let toRender = [];
    if (ActualGroup != undefined && ActualGroup.provider === 'SIGFOX') {
        toRender.push(<AddSigFox updateActGroup={this.updateActGroup.bind(this)}
                                  UserGroups={this.state.UserGroups}
                                  SensorGroups={this.state.SensorGroups}
                                  callback={this.getDevicesList.bind(this)}
                                  selectedGroup={this.state.ActualGroup}/>);
    } else {
        toRender.push(<AddLoRa updateActGroup={this.updateActGroup.bind(this)}
                                UserGroups={this.state.UserGroups}
                                SensorGroups={this.state.SensorGroups}
                                callback={this.getDevicesList.bind(this)}
                                selectedGroup={this.state.ActualGroup}/>);
    }
    return toRender;
  }

  generateDevicePagination(total) {
    const toRender = [];
    if (total !== undefined && total > this.DevicePerPage) {
      const pages = parseInt(total / this.DevicePerPage + 1, 10);
      const pageArray = pagination(this.state.ActualDevicePage, pages);
      toRender.push(
        <PaginationItem>
        <PaginationLink previous tag="button" onClick={() => 
                    this.state.ActualDevicePage > 1 ? 
                    this.setActDevicePage(this.state.ActualDevicePage  - 1) :
                        ''} />
      </PaginationItem>
      );
      for (let i = 0; i < pageArray.length; i += 1) {
        
      toRender.push(
        <PaginationItem>
        <PaginationLink onClick={() => this.setActDevicePage(pageArray[i])} tag="button">
          {this.state.ActualDevicePage === pageArray[i] ? <b>{pageArray[i]}</b> : pageArray[i]}
        </PaginationLink>
      </PaginationItem>
      );
      }
      toRender.push(
        <PaginationItem>
        <PaginationLink next tag="button" onClick={() => 
                    this.state.ActualDevicePage < (this.state.Devices.length / this.DevicePerPage) ? 
                    this.setActDevicePage(this.state.ActualDevicePage  + 1) :
                        ''} />
      </PaginationItem>
      );
    }
    return toRender;
  }
}

export default translate(QuickView);