import React, { Component } from 'react';
import { Responsive } from 'react-grid-layout';

class ResponsiveLayout extends Component {
  constructor(props) {
    super(props);
    this.refResponsive = null;
  }

  componentWillUnmount() {
    console.log('componentWillUnmount');
  }

  render() {
    const { layoutContent, onResize, size, isPublic, key } = this.props;
    const { width, height } = size;

    const breakpoints = {lg: 1200, md: 996, sm: 768, xs: 0};
    const cols = {lg: 6, md: 6, sm: 6, xs: 6};

    const layoutTmp = layoutContent.map(lc => lc[0].props['data-grid']);
    let layout = JSON.parse(JSON.stringify(layoutTmp));
    
    return (
        <Responsive className="layout"
          ref={el => this.refResponsive = el}
          layout={layout}
          key={key}
          onLayoutChange={(a) => onResize ? onResize(a) : ''}
          onWidthChange={() => this.forceUpdate()}
          isDraggable={!isPublic}
          isResizable={!isPublic}
          width={width}
          rowHeight={50}
          breakpoints={breakpoints}
          cols={cols}>
            {layoutContent}
        </Responsive>
    )
  }
}

export default ResponsiveLayout;
