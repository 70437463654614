import React, { Component } from 'react';
import Conf from '../../../../utils/Conf';
import { Dropdown, DropdownMenu, DropdownItem, DropdownToggle, Progress } from 'reactstrap';
import $ from 'jquery';
import { getRequest, postRequest } from '../../../../utils/WebServicesManager';
import { Line } from 'react-chartjs-2';
import { Min, Max } from '../../../../utils/Utils';
import { translate } from '../../../../utils/ReactMultiLang';

class TemperatureChart extends Component {
    constructor(props) {
        super(props);
        this.datas = {};
        this.options = {};
        this.actualData = 0; 
        this.state = {
            dropdownOpen: false
        };
        this.t = props.t;
    }

    getMessagesList(messages, paramName, color) {
        var datas = [];
        var labels = [];
        var lastTime = 0;
        for (var i = 0; i < messages.length; i++) {
            if (messages[i].time - lastTime < 3000)
                continue ;
            var d = new Date(messages[i].time * 1000);
            var tmplabel = d.getHours() + 'h' + ((d.getMinutes() > 9) ? d.getMinutes() : '0' + d.getMinutes() ) + ' ' + d.getDate() + '/' + ((d.getMonth() + 1 > 9) ? d.getMonth() + 1 : '0' + (d.getMonth() + 1)) + '/' + d.getFullYear();
            
            labels.push(tmplabel);
            datas.push(Math.trunc(parseFloat(messages[i].data[paramName]) * 10) / 10);
            lastTime = messages[i].time;
        }
        if (datas.length == 0) {
            datas = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
            labels = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        }
        this.actualData = datas[datas.length - 1];
        this.datas = {
            labels: labels,
            datasets: [
                {
                    label: paramName,
                    fill: false,
                    lineTension: 0.2,
                    backgroundColor: 'rgba(240,240,240,0.4)',
                    borderColor: 'rgba(240,240,240,1)',
                    borderCapStyle: 'butt',
                    borderDash: [],
                    borderDashOffset: 0.0,
                    borderJoinStyle: 'miter',
                    pointBorderColor: 'rgba(190,190,190,1)',
                    pointBackgroundColor: '#fff',
                    pointBorderWidth: 0,
                    pointHoverRadius: 5,
                    pointHoverBackgroundColor: 'rgba(220,220,220,1)',
                    pointHoverBorderColor: 'rgba(75,75,75,1)',
                    pointHoverBorderWidth: 1,
                    pointRadius: 1,
                    pointHitRadius: 10,
                    data: datas
                }
            ]
        };
        this.options = {
            layout: {
                padding: {
                    left: 20,
                    right: 20,
                    top: 20,
                    bottom: 20
                }
            },
            hover: {
                animationDuration: 0
            },
            animation: {
                duration: 750,
                easing: 'easeInQuad',
                onComplete: function () {
                    var chartInstance = this.chart,
                        ctx = chartInstance.ctx;

                    ctx.textAlign = 'center';
                    ctx.textBaseline = 'bottom';
                    
                    this.data.datasets.forEach(function (dataset, i) {
                        var meta = chartInstance.controller.getDatasetMeta(i);
                        let lastDraw = -50;
                        meta.data.forEach(function (bar, index) {
                            if (bar._model.x - lastDraw > 50) {
                                var data = dataset.data[index];
                                ctx.globalCompositeOperation='destination-over';
                                ctx.fillText(data, bar._model.x, bar._model.y - 5);
                                ctx.globalCompositeOperation='source-over';
                                lastDraw = bar._model.x;
                            }
                        });
                    });
                }
            },
            legend: {
                display: false
            },
            scales: {
                yAxes: [{
                    display: false,
                    ticks: {
                        min: Min(datas),
                        max: Max(datas)
                    }
                }],
                xAxes: [{
                    display: false
                }]
            },
            maintainAspectRatio: false
        }
    }


    selectSensor(callback, id) {
        callback(id);
      }

    getSensorList(sensorList, changeSensor) {
        var toRender = [];
        if (sensorList && sensorList != undefined) {
            for (var i = 0; i < sensorList.length; i++) {
                toRender.push(<DropdownItem onClick={this.selectSensor.bind(this, changeSensor, sensorList[i].DevEUI)}>{sensorList[i].name}</DropdownItem>);
            }
        }
        return toRender;
    }

render() {
      
    const { messages, paramName, changeSensor, sensorList, unit, subtitle, color, editComponent, deleteComponent, noButton } = this.props;
    this.getMessagesList(messages, paramName, color);
    return (
            <div className={"card card-inverse animated fadeIn " + color}>
                <div className="card-block pb-0">
                    <div className="btn-group float-right">
                    <Dropdown isOpen={this.state.card1} toggle={() => { this.setState({ card1: !this.state.card1 }); }}>
                    <DropdownToggle onClick={() => { this.setState({ card1: !this.state.card1 }); }} className="btn btn-transparent active dropdown-toggle p-0" data-toggle="dropdown" aria-haspopup="true" aria-expanded={this.state.card1}>
                      <i className="icon-settings"></i>
                    </DropdownToggle>
                    {!noButton ? 
                    <DropdownMenu right>
                        <DropdownItem onClick={() => {editComponent()} }><i className='icon-pencil' style={{color: "#212121"}}></i>{this.t('Edit')}</DropdownItem>
                        <DropdownItem onClick={() => {deleteComponent()} }><i className='icon-trash' style={{color: "#212121"}}></i>{this.t('Delete')}</DropdownItem>
                    </DropdownMenu>
                    : <DropdownMenu right>
                    {this.getSensorList(sensorList, changeSensor)}
                    </DropdownMenu> }
                  </Dropdown>
                    </div>
                    <h4 className="mb-0">{this.actualData}{unit}</h4>
                    <p>{subtitle}</p>
                </div>
                <div className="chart-wrapper px-3" style={{ height: "140px" }}>
                    <Line data={this.datas}
                        options={this.options}
                    />
                </div>
            </div>
    )
}
}

export default translate(TemperatureChart);
