function Conf() {}

Conf.BaseURL =  process.env.REACT_APP_BASE_URL || 'http://localhost:3081/services/'; // dashboard.ebeewan.com
Conf.BackenBase = process.env.REACT_APP_BACKEND_BASE;
Conf.WSURL = process.env.REACT_APP_WSURL;
Conf.AppURL = process.env.REACT_APP_URL;
Conf.DirectSAML = process.env.REACT_APP_DIRECT_SAML === "true" ? true : false;
/*
const host = window.location.hostname;
if (host.indexOf('localhost') !== -1) {
    Conf.BaseURL =  'http://localhost:3081/services/';
    Conf.BackenBase = 'http://localhost:3080/';
    // Conf.BaseURL =  'https://analyse2.naxoo.ch/services/';
}
if (host.indexOf('dev.ebeewan') !== -1) {
    Conf.BaseURL =  'https://dev.ebeewan.com/services/';
    Conf.BackenBase = 'https://dev.ebeewan.com/backend';
}
if (host.indexOf('demo.ebeewan') !== -1) {
    Conf.BaseURL =  'https://demo.ebeewan.com/services/';
    Conf.BackenBase = 'https://demo.ebeewan.com/backend';
}
*/

Conf.BaseApi = Conf.BaseURL + 'api/';//"http://localhost:8888/";
Conf.LoginURL = Conf.BaseURL + 'login';
Conf.PublicDashURL = Conf.BaseURL + 'building/dashboard/getToken';
Conf.PublicReportURL = Conf.BaseURL + 'reports/getToken';
Conf.SigninURL = Conf.BaseApi + 'users/add';
Conf.RetreiveURL = Conf.BaseURL + 'users/retreivePassword';
Conf.GetSensor = 'devices/get/';
Conf.AddSensor = 'devices/add';
Conf.AddAlarm = 'alarms/add';
Conf.EditAlarm = 'alarms/edit';
Conf.RemoveAlarm = 'alarms/remove/';
Conf.EditModel = 'alarmModels/edit';
Conf.RemoveModel = 'alarmModels/remove/';
Conf.RemoveSensor = 'devices/remove';
Conf.RemoveVSensor = 'virtualDevices/remove';
Conf.EditSensor = 'devices/edit';
Conf.ToggleAlert = 'devices/toggleAlert';
Conf.SensorGetByGroups = 'devices/groups/';
Conf.SensorGroups = 'devices/get/groups';
Conf.AddUserPlan = 'users/plan/add';
Conf.GetUserPlan = 'users/plan/get';
Conf.EditUser = 'users/edit';
Conf.UpdatePassword = 'users/updatePassword';
Conf.AddBuilding = 'buildings/add';
Conf.EditBuilding = 'buildings/edit';
Conf.RemoveBuilding = 'buildings/remove';
Conf.RemoveSensorBuilding = 'buildings/sensors/remove';
Conf.EditACL = 'users/acl/edit';
//Conf.BaseApi = "http://163.172.64.72:8888/api/";

Conf.SensorType = {
    magnetic: 'MAGNETIC',
    temperature: 'TEMPERATURE',
    motion: 'INFRARED',
    level: 'LEVEL',
    smoke: 'SMOKE',
    water: 'WATER'
};
Conf.Providers = [
    //'TTN',
    //'SWISSCOM',
    //'OBJENIOUS',
    //'SIGFOX',
    'SIGGENEVA'
    //'ORBIWISE',
    //'SHERPA'
];
Conf.ProvidersParams = {
    'ACTILITY': [
        'deviceProfileId',
        'routingProfileId',
        'connectivityPlanId'
    ],
    'OBJENIOUS': [],
    'SIGFOX': [
        'SigDeviceType'
    ],
    'ORBIWISE': [
        'OrbiApp'
    ],
    'VM2M': [
        'VM2MCategorie',
        'VM2MServiceProvider',
        'VM2MVar',
        'VM2MContainerCategorie',
        'VM2MServiceProvider'
    ]
}

Conf.WeekDay = [
    "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday", 
]

Conf.Colors = [
    '0083F9',
    '0CF2B3',
    'FF7A00',
    'FF0042',
    '6E00FF',
    'FFEB00',
    '00F906',
];

Conf.EnergyColors = [
  { label: "A", color: "00FF00", textColor: "000000", min: 0, max: 100000 },
  { label: "B", color: "4DFF00", textColor: "000000", min: 100001, max: 180000 }, 
  { label: "C", color: "B3FF00", textColor: "000000", min: 180001, max: 220000 }, 
  { label: "D", color: "FFFF00", textColor: "000000", min: 220001, max: 264000 }, 
  { label: "E", color: "FFB300", textColor: "000000", min: 264001, max: 300000 }, 
  { label: "F", color: "FF4D00", textColor: "000000", min: 300001, max: 350000 }, 
  { label: "G", color: "FF0000", textColor: "FFFFFF", min: 350001, max: 400000 }
];

// 'TTN',
module.exports = Conf;