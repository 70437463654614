import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom';
import { createBrowserHistory } from 'history';

// Containers
import Full from './containers/Full/';
import PublicLogger from './containers/PublicLogger/';
import PublicDashboard from './containers/PublicDashboard/';
import PublicReport from './containers/PublicReport';
import FrontPage from './containers/FrontPage/';
import RetreivePassword from './containers/RetreivePassword/';
import { isLoggedIn } from './utils/AuthServices';
import { setTranslations, setDefaultLanguage } from './utils/ReactMultiLang';
import en from './translations/en';
import fr from './translations/fr';
import type {T} from './utils/ReactMultiLang';
import SamlCallback from './components/SamlCallback';

const history = createBrowserHistory();

setTranslations({fr, en});
setDefaultLanguage('fr');

type Props = {
  t: T
}

ReactDOM.render((
  <HashRouter history={history}>
    <Switch>
      <Route path="/saml/callback" name="Callback" component={SamlCallback}/>
      <Route path="/retreivePassword/:token" name="RetreivePassword" component={RetreivePassword}/>
      <Route path="/public/report" name="PublicReport" component={PublicReport}/>
      <Route path="/public/buildingDashboard" name="PublicDashboard" component={PublicDashboard}/>
      <Route path="/public" name="PublicLogger" component={PublicLogger}/>
      {( isLoggedIn() ) ? <Route path="/home" name="Home" component={FrontPage}/> : <Route path="/" name="Home" component={FrontPage}/>}
      {( isLoggedIn() ) ? <Route path="/" name="Dashboard" component={Full}/> : <Route path="/" name="Home" component={FrontPage}/>}
      <Redirect from="/public" to="/public/logger"/>
      <Redirect from="/" to="/"/>
    </Switch>
  </HashRouter>
), document.getElementById('root'));
