const routes = {
    '/': 'Home',
    '/dashboard': 'Dashboard',
    '/sensors': 'Sensors',
    '/sensors/statistics': 'Statistics',
    '/sensors/states': 'States',
    '/sensors/QuickView': 'Quick View',
    '/sensors/manage': 'Management',
    '/alarms/manage': 'Alarms',
    '/alarms/models': 'Models',
    '/planner': 'Planner',
    '/users/manage': 'Profil',
    '/groups/manage': 'Groups',
    '/buildings/manage': 'Building',
    '/buildings/view': 'Quick View',
    '/sensors/ai': 'Machine Learning',
    '/reports': 'Reports',
    '/networks': 'Networks',
    '/networks/map': 'Map',
    '/sensors/gps': 'GPS',
    '/rounds': 'Delivery Rounds',
};
export default routes;
