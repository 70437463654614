import React from 'react';
import PropTypes from 'prop-types';
import {Marker, Circle, Popup} from "react-leaflet";
import { DMStoDegree, getDateStr } from '../../../utils/Utils';
import Conf from '../../../utils/Conf';
import { getRequest } from '../../../utils/WebServicesManager';
import { sensorMarker, generateSensorCircle } from '../../../views/Buildings/Manage/Map/Marker/Marker';

class SensorMarker extends React.Component {

    static propTypes = {
        position: PropTypes.object,
        className: PropTypes.string
    };

    constructor(props) {
        super(props);
        this.state = {
            Key: 0,
            lat: 0,
            lng: 0,
            sensor: props.sensor,
            color: props.color || '#2B2',
            Messages: props.messages || [],
            ShowRange: props.showRange
        };
    }
    componentWillReceiveProps(nextProps) {
        if (this.props.messages !== nextProps.messages) {
            
                this.setState({
                    Messages: nextProps.messages
                });
        }
        if (this.props.color !== nextProps.color) {
            
            this.setState({
                color: nextProps.color
            });
        }
        if (this.props.sensor !== nextProps.sensor) {
            
            this.setState({
                sensor: nextProps.sensor
            });
        }
        if (this.props.showRange !== nextProps.showRange) {
                this.setState({
                    ShowRange: nextProps.showRange
                });
        }
      }

    GenerateMarker(message, isFirst) {
        if (message && message.data) {
            let lat = message.data.lat;
            let lng = message.data.lng;
            
            const popup = (
                <Popup className='map-popup'>
                    <span>
                        {this.state.sensor.name}
                    </span>
                    <br />
                    <span>
                        {getDateStr(message.time * 1000)}
                    </span>
                </Popup>);

            return (
                <div><Marker key={`marker-${this.state.Key}-${message._id}`} zIndexOffset={300}
                        icon={generateSensorCircle(this.state.color)}
                        position={[lat, lng]}>

                        {popup}
                    </Marker>{isFirst ? <Marker key={`marker-${this.state.Key}-${message._id}2`} zIndexOffset={300}
                icon={sensorMarker}
                position={[lat, lng]}>
                {popup}</Marker> : ''}
                    { message.data.estim_error && this.state.ShowRange ?
                    <Circle color={this.state.color} center={[lat, lng]} radius={message.data.estim_error * 2} opacity='0.3'/> : '' }
                </div>);
        }
        return ;
/*{isFirst ? <Marker key={`marker-${this.state.Key}-${message._id}`} zIndexOffset={300}
                        icon={sensorMarker}
                        position={[lat, lng]}>
                    </Marker> : <Marker key={`marker-${this.state.Key}-${message._id}`} zIndexOffset={300}
                        icon={generateSensorCircle(this.state.color)}
                        position={[lat, lng]}>
                    </Marker> }*/
    }

    render() {
        return (
            <div>
                {this.state.Messages && typeof this.state.Messages.map === 'function' ? this.state.Messages.map((m, idx) => this.GenerateMarker(m, idx === 0)) : ''}
            </div>);

    }

}

export default SensorMarker;