import React, { Component } from 'react';
import { Dropdown, DropdownMenu, DropdownItem, DropdownToggle, Progress } from 'reactstrap';
import { translate } from '../../../utils/ReactMultiLang';
import { getRequest, postRequest, reportGetRequest, reportPostRequest } from '../../../utils/WebServicesManager';
import Conf from '../../../utils/Conf';
import { getDateStr } from '../../../utils/Utils';

class LineAverage extends Component {
  constructor(props) {
    super(props);
    this.state = {
        dropdownOpen: false,
        Messages: [],
        Value: "XX",
        isPublic: props.isPublic,
        isLocked: props.isLocked
    }
    this.getRequest = getRequest;
    this.postRequest = postRequest;
    if (props.isPublic) {
      this.getRequest = reportGetRequest;
      this.postRequest = reportPostRequest;
    }
    this.t = props.t;
    this.getMessage(props.chartDatas, props.component);
  }

  getMessagesSince(id, since) {
    var url = Conf.BaseApi + `messages/getComputed/since/${id}/${since}`;
    return this.getRequest(url, (body) => {
        if (body.success) {
          const messages = body.result;
            let tmpSensorMsg = this.state.Messages;
            tmpSensorMsg[id] = messages;
            this.setState({Messages: tmpSensorMsg});
            this.getMessagesList(tmpSensorMsg, this.props.chartDatas);
            this.updateDimensions();
        }
    });
  }

  getMessagesStatic(id, from, to) {
      var url = Conf.BaseApi + `messages/getBetween/devices`;
      return this.postRequest(url, {
          devices: [id],
          from,
          to
      },(body) => {
          if (body.success) {
            const messages = body.result[id];
            if (messages) {
              let tmpSensorMsg = this.state.Messages;
              tmpSensorMsg[id] = messages;
              this.setState({Messages: tmpSensorMsg});
              this.getMessagesList(tmpSensorMsg, this.props.chartDatas);
              this.updateDimensions();
            }
          }
      });
    }

    getMessage(chartDatas, component) {
      if (chartDatas && chartDatas !== undefined) {
          const usedDevEUI = [];
        for (let i = 0; i < chartDatas.length; i += 1) {
          if (!usedDevEUI.includes(chartDatas[i].sensor)) {
            if (component.from) {
                const from = Date.parse(component.from) / 1000;
                const to = Date.parse(component.to) / 1000;
                this.getMessagesStatic(chartDatas[i].sensor, from, to);
            } else {
                usedDevEUI.push(chartDatas[i].sensor);
                const dateDays = (component.days ? component.days : 1) * 24 * 60 * 60; // in seconds
                const dateMinusDays = Math.floor(Date.now() / 1000) - dateDays;
                this.getMessagesSince(chartDatas[i].sensor, dateMinusDays);
            }
          }
        }
      }
    }
    
    componentWillReceiveProps(nextProps) {
      if (nextProps.component && nextProps.component.dataSets
          && this.props.component && this.props.component.dataSets && (
          nextProps.component.days !== this.props.component.days
          || nextProps.component.from !== this.props.component.from
          || nextProps.component.dataSets.length !== this.props.component.dataSets.length)) {
          this.ChartKey += 1;
          this.getMessage(nextProps.chartDatas, nextProps.component);
      }
    }

    computeValue(component, paramName, messages, devEUI) {
      var tempAverage = 0;
      if (messages[devEUI] && messages[devEUI].length > 0) {
        const messageList = messages[devEUI];
        switch (component.barMode) {
          case 0:
            if (paramName != undefined) {
              let counter = 0;
              for (var i = 0; i < messageList.length; i++) {
                if (messageList[i].data[paramName]) {
                  var actData = parseFloat(messageList[i].data[paramName]);
                  tempAverage = tempAverage + actData;
                  counter++;
                }
              }
              if (tempAverage != 0)
                tempAverage = tempAverage / counter;
              tempAverage = Math.round(tempAverage * 10) / 10;
            } 
            if (isNaN(tempAverage))
              tempAverage = 0;
            break;
            case 2:
              if (paramName != undefined) {
                  var actData = parseFloat(messageList[0].data[paramName]) - parseFloat(messageList[messageList.length - 1].data[paramName]);
                  tempAverage = actData;
              }
              if (isNaN(tempAverage))
                tempAverage = 0;
              break;
          default:
            if (paramName != undefined) {
                var actData = parseFloat(messageList[0].data[paramName]);
                tempAverage = actData;
            }
            if (isNaN(tempAverage))
              tempAverage = 0;
            break;
        }
      }
      return (parseInt(tempAverage * 10) / 10);
    }

  render() {
    const { paramName, color, component, dataSigle, maxData, data, editComponent, deleteComponent, chartDatas } = this.props;
    
    return (
          <div className={"card card-inverse grid-element"}  ref='mainDiv' style={{backgroundColor: color}}>
              <div className="card-block">
                
              {this.state.isPublic || this.state.isLocked ? '' : <div className="btn-group float-right">
                <Dropdown isOpen={this.state.card1} toggle={() => { this.setState({ card1: !this.state.card1 }); }}>
                <DropdownToggle onClick={() => { this.setState({ card1: !this.state.card1 }); }} className="btn btn-transparent active dropdown-toggle p-0" data-toggle="dropdown" aria-haspopup={true} aria-expanded={this.state.card1}>
                  <i className="icon-settings"></i>
                </DropdownToggle>
                <DropdownMenu right>
                    <DropdownItem onClick={() => {editComponent()} }><i className='icon-pencil' style={{color: "#212121"}}></i>{this.t('Edit')}</DropdownItem>
                    <DropdownItem onClick={() => {deleteComponent()} }><i className='icon-trash' style={{color: "#212121"}}></i>{this.t('Delete')}</DropdownItem>
                </DropdownMenu>
              </Dropdown>
                </div>}
                <div className="h4 mb-0">
                {this.computeValue(component, paramName, this.state.Messages, chartDatas && chartDatas.length > 0 ? chartDatas[0].sensor : '')}
                  {dataSigle}
                  </div>
                <small className="text-muted text-uppercase font-weight-bold">{this.props.title}</small>
                {component 
                  && component.barMode == 1
                  && chartDatas
                  && chartDatas.length > 0
                  && this.state.Messages[chartDatas[0].sensor]
                  && this.state.Messages[chartDatas[0].sensor].length > 0 ?
                  <small className="text-muted text-uppercase font-weight-bold"> - {getDateStr(this.state.Messages[chartDatas[0].sensor][0].time * 1000)}</small>
                  : ''}
                <Progress className="progress progress-white progress-xs mt-3 mb-0" 
                  value={this.computeValue(component, paramName, this.state.Messages, chartDatas && chartDatas.length > 0 ? chartDatas[0].sensor : '')} max={maxData} />
              </div>
            </div>
    )
  }
}

export default translate(LineAverage);
