import React, { Component } from 'react';
import Conf from '../../../utils/Conf';
import $ from 'jquery';
import { getRequest, postRequest } from '../../../utils/WebServicesManager';
import { Line } from 'react-chartjs-2';
import { Min, Max } from '../../../utils/Utils';

class PredictionsCharts extends Component {
    constructor(props) {
        super(props);
        this.datas = {};
        this.options = {};
        this.actualData = 0; 
    }

    getPredictionList(predictions, paramName, color) {
        var datasArray = [];
        for (let k = 0; k < 4; k += 1)
            datasArray.push([]);
        var labels = [];
        var lastTime = 0;
        let i = 0, additionner = 1;
        if (predictions.values.length > 200) {
            i = predictions.values.length - 200;
            additionner = 2;
        }
        for (0; i < predictions.values.length; i += additionner) {
            const d = new Date(predictions.values[i].time);
            const timeStamp = d.getTime() / 1000;
            if (timeStamp - lastTime < 3000)
                continue ;
            var tmplabel = d.getHours() + 'h' + ((d.getMinutes() > 9) ? d.getMinutes() : '0' + d.getMinutes() ) + ' ' + d.getDate() + '/' + ((d.getMonth() + 1 > 9) ? d.getMonth() + 1 : '0' + (d.getMonth() + 1)) + '/' + d.getFullYear();
            
            labels.push(tmplabel);
            datasArray[0].push(Math.trunc(parseFloat(predictions.values[i].value) * 10) / 10);
            datasArray[1].push(Math.trunc(parseFloat(predictions.lowAverage[i].value) * 10) / 10);
            datasArray[2].push(Math.trunc(parseFloat(predictions.highAverage[i].value) * 10) / 10);
            datasArray[3].push(Math.trunc(parseFloat(predictions.predictions[i].value) * 10) / 10);
            lastTime = timeStamp;
        }
        if (datasArray[0].length == 0) {
            datasArray = [ [0],[0],[0],[0] ];
            labels = [0];
        }
        this.actualData = datasArray[0][datasArray[0].length - 1];
        this.datas = {
            labels: labels,
            datasets: []
        };
        for (let j = 0; j < 4; j += 1) {
            this.datas.datasets.push({
                label: paramName,
                fill: false,
                lineTension: 0.2,
                backgroundColor: 'rgba(240,240,240,0.4)',
                borderColor: 'rgba(40,40,40,1)',
                borderCapStyle: 'butt',
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: 'miter',
                pointBorderColor: 'rgba(190,190,190,1)',
                pointBackgroundColor: '#fff',
                pointBorderWidth: 0,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: 'rgba(220,220,220,1)',
                pointHoverBorderColor: 'rgba(75,75,75,1)',
                pointHoverBorderWidth: 1,
                pointRadius: 1,
                pointHitRadius: 10,
                data: datasArray[j]
            });
        }
        this.datas.datasets[1].label = 'Min';
        this.datas.datasets[1].borderColor = 'rgba(240,0,0,1)';
        this.datas.datasets[2].label = 'Max';
        this.datas.datasets[2].borderColor = 'rgba(240,0,0,1)';
        this.datas.datasets[3].label = 'Prediction';
        this.datas.datasets[3].borderColor = 'rgba(240,120,0,1)';
        this.options = {
            layout: {
                padding: {
                    left: 20,
                    right: 20,
                    top: 20,
                    bottom: 20
                }
            },
            hover: {
                animationDuration: 0
            },
            animation: {
                duration: 750,
                easing: 'easeInQuad',
                onComplete: function () {
                    var chartInstance = this.chart,
                        ctx = chartInstance.ctx;

                    ctx.textAlign = 'center';
                    ctx.textBaseline = 'bottom';
                    
                    this.data.datasets.forEach(function (dataset, i) {
                        var meta = chartInstance.controller.getDatasetMeta(i);
                        let lastDraw = -50;
                        meta.data.forEach(function (bar, index) {
                            if (bar._model.x - lastDraw > 50) {
                                var data = dataset.data[index];
                                ctx.globalCompositeOperation='destination-over';
                                ctx.fillText(data, bar._model.x, bar._model.y - 5);
                                ctx.globalCompositeOperation='source-over';
                                lastDraw = bar._model.x;
                            }
                        });
                    });
                }
            },
            legend: {
                display: false
            },
            scales: { 
                yAxes: [{
                    ticks: {
                        min: Min(datasArray[1]) - 2,
                        max: Max(datasArray[2]) + 2
                    }
                }]
            },
            maintainAspectRatio: false
        }
    }

    render() {
        
        const { predictions, unit, subtitle, color } = this.props;
        this.getPredictionList(predictions, predictions.paramName, color);
        return (
                <div className={"card animated fadeIn "}>
                    <div className="card-block pb-0">
                        <h4>{this.actualData}{unit}</h4>
                        <p>{subtitle}</p>
                    </div>
                    <div className="chart-wrapper px-3" style={{ height: "450px" }}>
                        <Line data={this.datas}
                            options={this.options}
                        />
                    </div>
                </div>
        );
    }
}

export default PredictionsCharts;
